<div *ngIf="!hideOrderDetails">
    <div class="container parent-container">
        <div *ngIf="showIsReadyForCollection" class="row-container">
            <div class="row align-center">
                <div class="col-lg-2 col-md-3">
                    <div class="align-center">
                        <div>
                            <h3 class="primary">{{'send_receive.send_receive.step_three.ready_for_collection_one' |
                                translate}}</h3>
                            <span class="primary">
                                {{'send_receive.send_receive.step_three.ready_for_collection_two' | translate}}</span>
                        </div>
                        <label class="tooltipSource"><img class="information-icon"
                                src="/assets/icons/i_information@3x.png" />
                            <a tabindex="0"></a><span>{{'send_receive.send_receive.step_three.ready_for_collection_tooltip'
                                |
                                translate}}</span></label>
                    </div>
                </div>
                <div class="col-lg-1 col-md-2 align-center">
                    <label>
                        <span style="padding-right: 5px;">
                            <input type="radio" name="yes-no" value="yes" [(ngModel)]="collectionOption"
                                (change)="onSelectReadyForCollection($event)"
                                [checked]="existingOrderReadyForCollection">
                        </span>
                        <span class="checkmark"></span>
                        {{'send_receive.send_receive.step_three.yes' | translate}}
                    </label>
                </div>
                <div class="col-lg-1 col-md-2 align-center">
                    <label>
                        <span style="padding-right: 5px;">
                            <input type="radio" name="yes-no" value="no" [(ngModel)]="collectionOption"
                                (change)="onSelectNotReadyForCollection($event)"
                                [checked]="existingOrderNotReadyForCollection">
                        </span>
                        <span class="checkmark"></span>
                        {{'send_receive.send_receive.step_three.no' | translate}}
                    </label>
                </div>
                <div *ngIf="readyForCollection == false" class="date-container col-lg-3 col-md-4">
                    <mat-form-field class="fill-container form-field-date-container" floatLabel="never"
                        appearance="outline" [formGroup]="sendReceiveForm">
                        <input matInput
                            placeholder="{{'send_receive.send_receive.step_three.set_collection_date' | translate}}"
                            matInput readonly [min]="today" [matDatepicker]="picker" required id="collectionDate"
                            name="collectionDate" formControlName="collectionDate">
                        <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
                        <mat-datepicker #picker></mat-datepicker>
                        <img class="calendar-icon" src="/assets/icons/calendar@3x.png" (click)="picker.open()" />
                    </mat-form-field>
                </div>
                <div *ngIf="readyForCollection == null && showRequiredError" class="col-lg-3 col-md-4">
                    <app-field-error-message style="height: 40px; width: 100%;"
                        errorMessage="{{'error.required' | translate}}"></app-field-error-message>
                </div>
            </div>
        </div>
        <div *ngIf="showCustomsInformation1" class="row-container">
            <div class="row input-field-row-no-margin">
                <div class="col-lg-2 col-md-3 align-center">
                    <h3 class="primary">{{'send_receive.send_receive.step_three.definition_of_goods' | translate}}</h3>
                    <label class="tooltipSource"><img class="information-icon"
                            src="/assets/icons/i_information@3x.png" />
                        <a tabindex="0"></a><span>{{'send_receive.send_receive.step_three.definition_of_goods_tooltip' |
                            translate}}</span></label>
                </div>
                <mat-form-field class="col-lg-7 col-md-7 align-form-field" [formGroup]="sendReceiveForm"
                    floatLabel="never" appearance="outline">
                    <input matInput
                        placeholder="{{'send_receive.send_receive.step_three.description_of_content' | translate}}"
                        id="definitionOfGoods" name="definitionOfGoods" formControlName="definitionOfGoods" required>
                    <mat-error class="show-mobile" *ngIf="hasErrors('definitionOfGoods')">
                        <app-field-error-message errorMessage="{{getError('definitionOfGoods')}}">
                        </app-field-error-message>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="showTypeOfShipment || showUploadInvoice || showManagedCustomsClearance" class="row-container">
            <div class="row">
                <div class="col-lg-5 col-md-6">
                    <div class="row input-field-row-no-margin" style="padding-bottom: 20px">
                        <div *ngIf="showTypeOfShipment" class="col-lg-5 col-md-6 align-center">
                            <h3 class="primary">{{'send_receive.send_receive.step_three.customs_info' | translate}}</h3>
                            <label class="tooltipSource"><img class="information-icon"
                                    src="/assets/icons/i_information@3x.png" />
                                <a tabindex="0"></a><span>{{'send_receive.send_receive.step_three.customs_information_tooltip'
                                    |
                                    translate}}</span></label>
                        </div>
                        <div *ngIf="!showTypeOfShipment" class="col-lg-5 col-md-6 align-center">
                            <h3 class="primary">{{'send_receive.send_receive.step_three.file_upload' | translate}}</h3>
                            <label class="tooltipSource"><img class="information-icon"
                                    src="/assets/icons/i_information@3x.png" />
                                <a tabindex="0"></a><span>{{'send_receive.send_receive.step_three.file_upload_tooltip'
                                    |
                                    translate}}</span></label>
                        </div>
                        <div *ngIf="showTypeOfShipment" class="col-lg-4 col-md-5">
                            <h4 class="primary">{{'send_receive.send_receive.step_three.type_of_shipment' | translate}}
                            </h4>
                        </div>
                    </div>

                    <div *ngIf="showTypeOfShipment">
                        <div *ngFor="let shipmentType of shipmentTypes" class="row"
                        style="padding-bottom: 20px;">
                            <div class="col-lg-5 col-md-6 align-center">
                                <!-- offset-lg-5 offset-md-6 -->
                            </div>
                            <div class="col-lg-7 col-md-6">
                                <div>
                                    <label>
                                        <span style="padding-right: 5px;">
                                            <input type="radio" name="shipment-type"
                                                checked={{checkPurchaseCommercial(shipmentType)}}
                                                (change)="onSelectCommerical($event, shipmentType)">
                                        </span>
                                        <span class="checkmark"></span>
                                        {{getCustomsShipmentTypeName(shipmentType)}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="otherSelected" class="row input-field-row-no-margin">
                        <div class="col-lg-5 col-md-6 align-center">
                            <!-- offset-lg-5 offset-md-6 -->
                        </div>
                        <div class="col-lg-7 col-md-6">
                            <mat-form-field class="align-form-field" [formGroup]="sendReceiveForm"
                            floatLabel="never" appearance="outline" style="padding-top: 0px;">
                            <input matInput formControlName="shipmentTypeOther" required
                                placeholder="{{'send_receive.send_receive.step_three.if_other_specify' | translate}}">
                                <mat-error class="show-mobile" *ngIf="hasErrors('shipmentTypeOther')">
                                    <app-field-error-message errorMessage="{{getError('shipmentTypeOther')}}">
                                    </app-field-error-message>
                                </mat-error>
                        </mat-form-field>
                        </div>
                        
                    </div>
                </div>
                <div *ngIf="showUploadInvoice" class="col-lg-4 col-md-6">
                    <div class="upload-invoice-container">
                        <div class="align-center" style="padding-bottom: 20px">
                            <h4 class="upload-invoice-text primary">
                                {{'send_receive.send_receive.step_three.upload_invoice' | translate}}</h4>
                            <h4 class="italic">&nbsp;{{'send_receive.send_receive.step_three.required' |
                                translate}}</h4>
                        </div>
                        <div class="row no-margin" style="padding-bottom: 20px">
                            <button *ngIf="!!linkItem1"
                                class="secondary-button requirements-button col-lg-auto col-12 padded"
                                (click)="openRouteInNewWindow(linkItem1.Url)">{{linkItem1.Title}}</button>
                        </div>
                        <div class="row no-margin" style="padding-bottom: 20px">
                            <div class="col-lg-12 col-md-12 file-upload-button">
                                <app-file-upload [clearAllFiles]="clearFiles"
                                    [existingOrderInvoices]="existingOrderInvoices"
                                    [showRequiredError]="setShowRequiredError()"
                                    (uploadedFiles)="setUploadedFiles($event)" [orderDetailsForm]="sendReceiveForm"
                                    (filesToAdd)="setFilesToAdd($event)" (filesToRemove)="setFilesToRemove($event)">
                                </app-file-upload>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!!getManagedCustomCost() && showManagedCustomsClearance" class="col-lg-3 col-md-6">
                    <div class="customs-container">
                        <div class="align-center" style="padding-bottom: 20px">
                            <h4 class="upload-invoice-text white">
                                {{'send_receive.send_receive.step_three.managed_customs_clearance' | translate}}</h4>
                        </div>
                        <div style="padding-bottom: 20px; color: white">
                            {{'send_receive.send_receive.step_three.customs_clearance_info' | translate}}
                        </div>
                        <!-- <div class="row no-margin" style="padding-bottom: 10px">
                            <button class="col-12 feature-button">{{'send_receive.send_receive.step_three.more_info' |
                                translate}}</button>
                        </div> -->
                        <div class="row no-margin" style="padding-bottom: 10px">
                            <button *ngIf="!!linkItem2" class="col-12 secondary-button"
                                (click)="openRouteInNewWindow(linkItem2.Url)">{{linkItem2.Title}}</button>
                        </div>
                        <div class="align-center customs-clearance-amount">
                            <h2 class="white">{{getManagedCustomCost()}} DKK</h2>
                        </div>
                        <div class="align-center customs-clearance-text">
                            {{'send_receive.send_receive.step_three.duty_cost_not_included' | translate}}
                        </div>
                        <div class="align-center customs-clearance-amount" style="padding-bottom: 20px">
                            <a href=""
                                class="customs-clearance-text">{{'send_receive.send_receive.step_three.calculate_duty_cost'
                                | translate}}</a>
                        </div>
                        <div>
                            <label>
                                <div class="align-center">
                                    <input type="radio" style="margin-right: 5px;" name="add-service"
                                        class="white-radio" [checked]="managedCustomsClearanceChecked"
                                        (click)="setCustomsClearance()">
                                    <span style="color: white">{{'send_receive.send_receive.step_three.add_service' |
                                        translate}}</span>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showItemTypes" class="row-container">
            <div class="row">
                <div class="col-lg-2 col-md-2">
                </div>
                <div class="col-lg-7 col-md-7">
                    <app-buy-postage-item-types [(itemTypes)]="itemTypes"></app-buy-postage-item-types> 
                </div>
            </div>
        </div>

        <div *ngIf="!isDomestic()" class="row-container">
            <div class="row">
                <div class="col-lg-2 col-md-2">
                    <h3 class="primary">{{'send_receive.send_receive.step_three.comments' | translate}}</h3>
                </div>
                <div class="col-lg-7 col-md-7">
                    <mat-form-field floatLabel="never" [formGroup]="sendReceiveForm" class="text-area-container">
                        <textarea rows="5" matInput
                            placeholder="{{'send_receive.send_receive.step_three.enter_comments' | translate}}"
                            formControlName="comments"></textarea>
                            <mat-error *ngIf="hasErrors('comments')">
                                <app-field-error-message errorMessage="{{getError('comments')}}">
                                </app-field-error-message>
                            </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf="isDomestic()" class="row-container">
            <div class="row">
                <div class="col-lg-2 col-md-2">
                    <h3 class="primary">{{'send_receive.send_receive.step_three.domestic_contents' | translate}}</h3>
                </div>
                <div class="col-lg-7 col-md-7">
                    <mat-form-field floatLabel="never" [formGroup]="sendReceiveForm" class="text-area-container">
                        <textarea rows="5" matInput
                            placeholder="{{'send_receive.send_receive.step_three.enter_domestic_contents' | translate}}"
                            formControlName="domesticPackageContents"></textarea>
                            <mat-error *ngIf="hasErrors('domesticPackageContents')">
                                <app-field-error-message errorMessage="{{getError('domesticPackageContents')}}">
                                </app-field-error-message>
                            </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="row flex-end col-lg-12" style="padding-bottom: 15px;">
        <h1>{{'send_receive.send_receive.step_four.total' | translate}}: {{totalCost | currency: 'DKK':''}} DKK
        </h1>
    </div>

    <div class="row">
        <div class="col-lg-3 col-md-3 col-6 order-2 order-md-1 order-lg-1 mob-left-button">
            <button class="tertiary-button full-width" mat-button (click)="navigateToPreviousPage()">
                <div class="full-width">
                    <span class="back-icon-text">{{'send_receive.send_receive.step_three.back' | translate}}</span>
                    <img class="back-icon" src="/assets/icons/arrows/back_button_icon@3x.png" />
                </div>
            </button>
        </div>
        <div class="col-lg-3 col-md-3 col-6 order-3 order-md-2 order-lg-2 mob-right-button">
            <button class="tertiary-button full-width" (click)="clearAll()" mat-button>
                <div class="full-width">
                    <span class="clear-all-icon-text">{{'send_receive.send_receive.step_three.clear_all' |
                        translate}}</span>
                    <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
                </div>
            </button>
        </div>
        <div class="col-lg-3 col-md-3 col-0 order-md-3 order-lg-3" style="padding-left: 0px"></div>
        <div class="col-lg-3 col-md-3 col-12 mobile-no-padding-left order-1 order-md-4 order-lg-4 get-prices-button">
            <button class="primary-button full-width" [disabled]="checkProceedDisabled()" mat-button matStepperNext
                (click)="checkFilesUploaded()">{{'send_receive.send_receive.step_three.proceed' | translate}}</button>
        </div>
    </div>
</div>
<div *ngIf="hideOrderDetails">
    {{'send_receive.send_receive.step_three.step_not_required' | translate}}
</div>