import { Component } from '@angular/core';
import { MatDialogRef,} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../account/services/authentication.service';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>, private authService: AuthenticationService, private router: Router, private translateService: TranslateService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onProceed(): void {
    var loggedUserType = localStorage.getItem("loginUserType");
    if (!!loggedUserType && loggedUserType == "private")
    {
      this.authService.logout().subscribe(x => {
        this.authService.logoutShibboleth().subscribe(y => {
          this.routeToBusiness()},
          (error) => {
            this.routeToBusiness();
        });
      }, (error) => {
        this.routeToBusiness();
      })
    }
    else {
      this.authService.logout().subscribe(x => {
        this.routeToPrivate();
      }, (error) => {
        this.routeToPrivate();
      })
    }
  }

  private routeToBusiness()
  {
    this.router.navigate([`${this.translateService.currentLang}/business/home`])
    this.dialogRef.close();
  }

  private routeToPrivate()
  {
    this.router.navigate([`${this.translateService.currentLang}/private/home`])
    this.dialogRef.close();
  }

}
