import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomerShipment } from "../entities/CustomerShipment";
import { CustomerShipmentDetail } from "../entities/CustomerShipmentDetail";

@Injectable({
    providedIn: 'root'
})
export class WaybillService{
    constructor(private httpClient: HttpClient) {
    }

    getCustomerShipmentList(fromDate?: any, toDate?: any){
        if(fromDate && toDate){
            return this.httpClient.get(`${environment.apiUrl}/api/Waybill/shipments?fromDate=${fromDate}&toDate=${toDate}`) as Observable<CustomerShipment[]>;
        }
        else{
            return this.httpClient.get(`${environment.apiUrl}/api/Waybill/shipments`) as Observable<CustomerShipment[]>;
        }
    }

    getCustomerShipment(shipmentType: string, manifestNo: string, waybillNo: string){
        return this.httpClient.get(`${environment.apiUrl}/api/Waybill/shipmentdetail/${shipmentType}/${manifestNo}/${waybillNo}`) as Observable<CustomerShipmentDetail>;
    }

    downloadFile(linkId: number){
        return this.httpClient.get(`${environment.apiUrl}/api/Waybill/shipmentdocument/${linkId}`, {responseType: 'text'}) as Observable<string>;
    }
}