import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './../../../../account/services/authentication.service';
import { RoleService } from './../../../../account/services/role.service';
import { ErrorHelper } from './../../../../shared/helpers/error-helper';

@Component({
  selector: 'app-inline-login',
  templateUrl: './inline-login.component.html',
  styleUrls: ['./inline-login.component.scss']
})
export class InlineLoginComponent implements OnInit {

  @Input() buyPostageForm: FormGroup;
  @Output() onLoggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();
  isLoggedIn: boolean;
  
  isGuest: boolean;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private roleService: RoleService,
    private _formBuilder: FormBuilder,
    private errorHelper: ErrorHelper,
    private authenticationService: AuthenticationService,) { }

  ngOnInit(): void {
    this.roleService.roleSubject.subscribe(x => {
      this.role = x;
    });

    this.passwordForm = this._formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  public role: string;
  public passwordForm: FormGroup;

  public navigateToForgottenPassword()
  {
    this.router.navigate([`${this.translate.currentLang}/${this.role}/forgotten-password`]);
  }

  public hasErrors(field) {
    var errors = this.passwordForm.get(field).errors;
    return !!errors;
  }

  public getError(field) {
    var errors = this.passwordForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public login() {
    if (!this.passwordForm.valid) {
      this.passwordForm.markAllAsTouched();
      return;
    }

    if (this.role == "private") {
      this.authenticationService.login(this.passwordForm.get("email").value, this.passwordForm.get("password").value, 2, this.translate.currentLang).subscribe
        (x => {
          this.passwordForm.reset();
          this.onLoggedIn.emit(true);
          var userDetails = JSON.parse(x.toString());
          if (!userDetails.isVerified) {
            if (userDetails.isEmailVerificationMessageJustBeenSent)
            {
              this.router.navigate([`${this.translate.currentLang}/${this.role}/awaiting-verification/email-sent`]);
              return;
            }
            this.router.navigate([`${this.translate.currentLang}/${this.role}/awaiting-verification/`]);
            return;
          }
          if (!userDetails.isProfileComplete) {
            this.router.navigate([`${this.translate.currentLang}/${this.role}/update-profile`]);
            return;
          }
          this.isLoggedIn = true;
        }, (error) => {
          this.passwordForm.get("password").setErrors({ 'incorrectCredentials': true })
        })
    }

    if (this.role == "business") {
      this.authenticationService.login(this.passwordForm.get("email").value, this.passwordForm.get("password").value, 1, this.translate.currentLang).subscribe
        (x => {
          this.passwordForm.reset();
          this.onLoggedIn.emit(true);
          var userDetails = JSON.parse(x.toString());
          if (!userDetails.isProfileComplete) {
            this.router.navigate([`${this.translate.currentLang}/business/my-posta/update-profile`]);
            return;
          }
          this.isLoggedIn = true;
        }, (error) => {
          if (error.status == 403)
          {
            this.router.navigate([`${this.translate.currentLang}/business/awaiting-approval`]);
            return;
          }
          this.passwordForm.get("password").setErrors({ 'incorrectCredentials': true })
        });
    }
  }

}
