import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../../../../account/services/role.service';
import { GenericOrder } from '../../../entities/genericOrder';
import { StaticContentHelper } from '../../../helpers/static-content-helper';
import { ServiceType } from '../../../price-matrix/enums/ServiceType';
import { OrdersService } from '../../../services/orders.service';
import { StaticContentService } from '../../../services/static-content.service';

@Component({
  selector: 'app-orders-summary',
  templateUrl: './orders-summary.component.html',
  styleUrls: ['./orders-summary.component.scss']
})
export class OrdersSummaryComponent implements OnInit {

  completedOrders: Array<GenericOrder>;

  liveOrders: Array<GenericOrder>;

  public pageTitle: string;

  public pageModules: string;

  loadingLive: boolean = false;
  
  loadingCompleted: boolean = false;

  deliveredIds = [3,4,5];

  attemptedDeliveryIds = [6,7,14,34,35];

  returnToSenderIds = [8,10,85];

  inTransitLocationUpdateIds = [38,39,40,41,43,44,45,46,47,48,49,50,52,53,54,55,56,57,58,59,60,61,62,
  64,65,66,67,68,69,70,71,72,75,76,77,78,84,86,87,88,89,90,91,94,97,113,117];

  delayedNoActionIds = [12,13,15,22,23,24,63,73,108,114];

  unableToDeliveryIds = [9,11,16,17,18,20,21,25,26,28,29,32,33,51,98,99,104,105,107,112,118];

  delayedActionIds = [19,30,31,42,74,79,80,81,82,83,95,96,100,101,102,103,106,109,110,111,115,116];

  confirmedEventTypeIds = [1,92];

  readyToCollectIds = [27];

  cancelledEventTypeIds = [2, 93];

  receivedIds = [37];

  interval: any;

  constructor(private ordersService: OrdersService,
    private router: Router,
    private translate: TranslateService,
    private staticContentService: StaticContentService,
    private roleService: RoleService) { }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translate.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
    })

    this.loadingLive = true;
    this.loadingCompleted = true;

    this.ordersService.getOrderList("?status=1&skipCount=0&takeCount=5").subscribe(x => {
      this.liveOrders = x;
      this.loadingLive = false;
    }, (error => {
      this.loadingLive = false;
    }));

    this.ordersService.getOrderList("?status=2&skipCount=0&takeCount=5").subscribe(x => {
      this.completedOrders = x;
      this.loadingCompleted = false;
    }, (error => {
      this.loadingCompleted = false;
    }));
  }

  public routeToOrder(order: GenericOrder)
  {
    //send receive order
    if (order.serviceTypeId == 2 || order.serviceTypeId == 6 || order.serviceTypeId == 9)
    {
      return `../orders/send-receive/${order.orderReference}`;
    }
    else if (order.serviceTypeId == 3)
    {
      return `../../ccs/${order.itemId}`;
    }
    else if (order.serviceTypeId == 5)
    {
      return `../orders/buy-postage/${order.orderReference}`;
    }
    else
    {
      return null;
    }
  }

  public getPageTitle()
  {
    return this.staticContentService.getPageTitle();
  }

  public getIcon(order)
  {
    if (this.deliveredIds.includes(order.eventTypeId))
    {
      return "/assets/icons/track-trace/tt_11_delivered_120.png";
    }
    else if (this.attemptedDeliveryIds.includes(order.eventTypeId))
    {
      return "/assets/icons/track-trace/tt_09_attempted_delivery_120.png";
    }
    else if (this.returnToSenderIds.includes(order.eventTypeId))
    {
      return "/assets/icons/track-trace/tt_12_return_to_sender.png";
    }
    else if (this.inTransitLocationUpdateIds.includes(order.eventTypeId))
    {
      return "/assets/icons/track-trace/tt_05_location_120.png";
    }
    else if (this.delayedNoActionIds.includes(order.eventTypeId))
    {
      return "/assets/icons/track-trace/tt_07_delay_120.png";
    }
    else if (this.unableToDeliveryIds.includes(order.eventTypeId))
    {
      return "/assets/icons/track-trace/tt_13_unable_to_deliver.png";
    }
    else if (this.delayedActionIds.includes(order.eventTypeId))
    {
      return "/assets/icons/track-trace/tt_06_delay_action_rq_120.png";
    }
    else if (this.confirmedEventTypeIds.includes(order.eventTypeId))
    {
      return "/assets/icons/track-trace/tt_01_confirmed_120.png";
    }
    else if (this.readyToCollectIds.includes(order.eventTypeId))
    {
      return "/assets/icons/track-trace/tt_10_ready_to_collect_120.png";
    }
    else if (this.cancelledEventTypeIds.includes(order.eventTypeId))
    {
      return "/assets/icons/track-trace/tt_02_cancelled_120.png";
    }
    else if (this.receivedIds.includes(order.eventTypeId))
    {
      return "/assets/icons/track-trace/tt_03_received_120.png";
    }
  }

  public viewAllWithStatus(status: number) {

    let navigationExtras: NavigationExtras = {
      queryParams: {
          "status": status
      },
  };

    this.router.navigateByUrl(`/${this.translate.currentLang}/${this.roleService.role()}/my-posta/orders/view-all?status=${status}`);
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }

}

