import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { CustomsInput } from '../entities/CustomsInput';
import { CustomsEstimate } from '../entities/CustomsEstimate';
import { HsCodeLookup } from '../entities/hsCodeLookup';
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class CustomsService {

    constructor(private httpClient: HttpClient) {
    }

    public calculateEstimate(customsInput: CustomsInput): Observable<CustomsEstimate> {
        return this.httpClient.post(`${environment.apiUrl}/api/customs`, customsInput);
    }

    public getCategoryByHsNumber(hsNumber: string): Observable<HsCodeLookup> {
        return this.httpClient.get(`${environment.apiUrl}/api/customs/?tariffCode=${hsNumber}`) as Observable<HsCodeLookup>;
    }

    
}