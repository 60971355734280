<div class="container">
    <div class="title-container">
        <img class="shopping-cart-icon" src="/assets/icons/shopping-list/shopping_cart_blue.png">
        <h1 class="page-title">{{'bera.edit_lists.title' | translate}}</h1>
    </div>
    <div class="row" style="margin-bottom: 20px;">
        <div class="col-xl-2 col-lg-4 col-md-4">
            <button class="primary-button full-width shopping-list-button" routerLink="../offers-products">
                < {{'bera.edit_lists.products_offers' | translate}}</button>
        </div>
        <div class="col-xl-7 col-lg-8 col-md-8">
            <mat-form-field *ngIf="!isLoggedIn()" class="shopping-list-dropdown full-width" floatLabel="never" appearance="outline">
                <mat-select disableOptionCentering placeholder="{{'bera.edit_lists.filter_by_list' | translate}}" panelClass="select-panel"
                    (selectionChange)="updateActiveList($event)" [(ngModel)]="activeLoggedOutList">
                    <mat-option *ngFor="let shoppingList of allLoggedOutLists.shoppingLists"
                        [value]="shoppingList">{{shoppingList.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="isLoggedIn()" class="shopping-list-dropdown full-width" floatLabel="never" appearance="outline">
                <mat-select disableOptionCentering placeholder="{{'bera.edit_lists.filter_by_list' | translate}}" panelClass="select-panel"
                    (selectionChange)="updateActiveList($event)" [(ngModel)]="activeList">
                    <mat-option *ngFor="let shoppingList of allShoppingLists"
                        [value]="shoppingList">{{shoppingList.shoppingListName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-4">
            <button class="primary-button full-width new-shopping-list-button" (click)="addNewShoppingList()"><img class="add-list-icon"
                    src="/assets/icons/shopping-list/Add.png">{{'bera.edit_lists.add_new_list' | translate}}</button>
        </div>
    </div>
    <div *ngIf="allShoppingLists" class="grey-background-container">
        <div class="retailer-container" *ngFor="let shoppingList of allFilteredList">
            <div class="product-container">
                <div class="row">
                    <div class="col-lg-10 d-flex">
                        <div class="product-desc-container">
                            <h3 class="shopping-list-title" [routerLink]="['../view-shopping-list', shoppingList.shoppingListId]">{{shoppingList.shoppingListName}}</h3>
                            <br>
                            <span><span class="detail-blue">{{'bera.edit_lists.date_created' | translate}} </span><span
                                    class="detail-text">{{shoppingList.createdOn | date:'dd.MM.yy'}}</span></span>
                                    <br>
                            <button class="primary-button share-list-button" (click)="createShareLink(shoppingList)"><img class="share-icon"
                                src="/assets/icons/shopping-list/Share.png">{{'bera.edit_lists.share' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-2 product-desc-secondary-container">
                        <button class="remove-button" (click)="removeShoppingList(shoppingList.shoppingListId)"><img class="remove-icon"
                                src="/assets/icons/shopping-list/remove.png">{{'bera.edit_lists.delete' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="allLoggedOutLists" class="grey-background-container">
        <div class="retailer-container" *ngFor="let shoppingList of filteredListsLoggedOut.shoppingLists">
            <div class="product-container">
                <div class="row">
                    <div class="col-lg-10 d-flex">
                        <div class="product-desc-container">
                            <h3 class="shopping-list-title" (click)="navigateToViewList(shoppingList)">{{shoppingList.name}}</h3>
                        </div>
                    </div>
                    <div class="col-lg-2 product-desc-secondary-container">
                        <button class="remove-button" (click)="removeShoppingListLoggedOut(shoppingList)"><img class="remove-icon"
                                src="/assets/icons/shopping-list/remove.png">{{'bera.edit_lists.delete' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>