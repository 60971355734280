<div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
<div class="container">

   <div class="filter-container">
      <div class="row align-center filter-row">
         <div class="filter-radio-container">
            <label class="align-center filter-radio-button">
               <span class="radio-button-spacing">
                  <input class="vertical-align-radio-button" type="radio" id="all-time-radio-button" name="all-time" (change)="checkAllTime()"
                     checked>
               </span>
               <h4 class="radio-text">{{'all_orders_page.all_time' | translate}}</h4>
            </label>
         </div>
         <div class="filter-radio-container">
            <label class="align-center filter-radio-button">
               <span class="radio-button-spacing">
                  <input class="vertical-align-radio-button" type="radio" id="specific-date-radio-button" name="all-time"
                     (change)="filterBySpecificDate = true">
               </span>
               <h4 class="radio-text">{{'all_orders_page.specific_dates' | translate}}</h4>
            </label>
         </div>
         <div class="align-center form-field-date-group from-date-group">
            <h4 class="from-label">{{'all_orders_page.from' | translate}}</h4>
            <mat-form-field class="fill-container form-field-date-container" floatLabel="never" appearance="outline"
               [formGroup]="filterFormGroup">
               <input matInput placeholder="00/00/0000" matInput readonly [matDatepicker]="picker1" id="fromDate"
                  name="fromDate" formControlName="fromDate">
               <mat-datepicker #picker1></mat-datepicker>
               <img class="calendar-icon" src="/assets/icons/calendar@3x.png" (click)="picker1.open(); selectSpecificDate()" />
            </mat-form-field>
         </div>
         <div class="align-center form-field-date-group to-date-group">
            <h4 class="to-label">{{'all_orders_page.to' | translate}}:</h4>
            <mat-form-field class="fill-container form-field-date-container" floatLabel="never" appearance="outline"
               [formGroup]="filterFormGroup">
               <input matInput placeholder="00/00/0000" matInput readonly [matDatepicker]="picker2" id="toDate"
                  name="toDate" formControlName="toDate">
               <mat-datepicker #picker2></mat-datepicker>
               <img class="calendar-icon" src="/assets/icons/calendar@3x.png" (click)="picker2.open(); selectSpecificDate()" />
            </mat-form-field>
         </div>
         <div class="align-center search-field-rows">
            <mat-form-field class="search-input" floatLabel="never" appearance="outline" [formGroup]="filterFormGroup" style="height: 40px;">
               <input class="text-input" matInput placeholder="{{'all_orders_page.search_orders' | translate}}" type="text" name="search"
                  formControlName="search" autocomplete="off">
            </mat-form-field>
            <div class="address-menu-item">
               <button class="primary-button search-button" (click)="searchByFilter()">
                  <img class="search-icon" src="/assets/icons/address_book/search_white@3x.png" />
               </button>
            </div>
            <button class="secondary-button view-all-button" (click)="clearSearchFilters()">
               {{'all_orders_page.view_all' | translate}}
            </button>
         </div>
      </div>
   </div>


   <div class="results-container">
      <div class="row filter-by-status-row">
         <div class="col-md-6 offset-md-6 col-12 align-center">
            <div class="header-grey-title status-filter-label">{{'all_orders_page.filter_by_status' | translate}}</div>
            <mat-form-field class="filter-by-status-select" floatLabel="never" appearance="outline">
               <mat-select [(value)]="selectedStatus" (selectionChange)="getOrdersByStatus($event.value)"
                  disableOptionCentering panelClass="select-panel">
                  <mat-option *ngFor="let status of statuses" [value]="status">
                     {{status}}</mat-option>
               </mat-select>
            </mat-form-field>
         </div>
      </div>

      <!-- Use a table instead of bootstrap cols -->
      <div class="row table-header-row">
         <div class="col-md-2 col-4 align-center">
            <h5>{{'all_orders_page.posta_id' | translate}}</h5>
         </div>
         <div class="col-md-2 col-4 no-padding align-center">
            <h5>{{'all_orders_page.tracking_id' | translate}}</h5>
         </div>
         <div class="col-lg-1 col-md-1 col-2 no-padding align-center">
            <h5>{{'all_orders_page.status' | translate}}</h5>
         </div>
         <div class="col-lg-2 col-md-3 hide-mobile no-padding align-center">
            <h5>{{'all_orders_page.your_ref' | translate}}</h5>
         </div>
         <div class="col-lg-2 col-md-3 hide-mobile no-padding align-center">
            <h5>{{'all_orders_page.from_to' | translate}}</h5>
         </div>
         <div class="col-md-1 col-4 no-padding align-center" style="padding-right: 0px;">
            <h5>{{'all_orders_page.order_date' | translate}}</h5>
         </div>
         <div class="col-lg-1 align-center no-padding hide-tablet">
            <h5>{{'all_orders_page.delivery_date' | translate}}</h5>
         </div>
         <div class="col-lg-1 col-md-1 col-2 no-padding align-center">
            <h5>{{'all_orders_page.receipt' | translate}}</h5>
         </div>
      </div>
      <div *ngIf="!loading">
         <div *ngFor="let order of orders" class="row order-row">
            <div class="col-md-2 col-4 align-center" style="margin: 20px 0px 20px 0px;">
               <a [routerLink]="routeToOrder(order)">{{order.orderReference}}</a>
            </div>
            <div class="col-lg-2 col-md-3 hide-mobile no-padding align-center">
               <a [routerLink]="openTrackTrace(order.trackingCode)">{{order.trackingCode}}</a>
            </div>
            <div class="col-md-1 col-2 no-padding align-center">
               <img *ngIf="!!order.eventTypeId" class="status-icon" [src]="getIcon(order.eventTypeId)"/>
               <!-- <img class="status-icon" src="/assets/icons/track-trace/tt_05_location_120.png" (click)="openTrackTrace(order.orderReference)"/> -->
            </div>
            <div class="col-lg-2 col-md-3 hide-mobile no-padding align-center">
               {{order.customerReference}}
            </div>
            <div class="col-lg-2 col-md-3 hide-mobile no-padding align-center">
               <div>
                  <div class="truncate">{{order.fromName}}</div>
                  <div class="truncate">{{order.toName}}</div>
               </div>
            </div>
            <div class="col-md-1 col-4 no-padding align-center">
               {{order.createdDateTime | date:'dd/MM/yyyy'}}
            </div>
            <div class="col-lg-1 align-center no-padding hide-tablet">
               {{order.estimatedDeliveryDate | date:'dd/MM/yyyy'}}
            </div>
            <div *ngIf="order.isPaid" class="col-lg-1 col-md-2 col-2 no-padding align-center">
               <button class="download-button" (click)="downloadReceipt(order)"><img class="download-icon" src="/assets/icons/orders/DownloadReceipt.png" /></button>
            </div>
         </div>
      </div>
      <div *ngIf="!loading && showViewMoreButton" class="view-more-container">
         <button class="primary-button view-more-button" (click)="viewMore()">{{'all_orders_page.view_more' | translate}}</button>
      </div>
      <div *ngIf="loading" class="spinner-container">
         <mat-spinner class="loading-spinner" diameter="60"></mat-spinner>
      </div>
   </div>
</div>
<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>