import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FindServiceOptionDetails } from '../../../../shared/price-matrix/entities/FindServiceOptionDetails';
import { TranslationString } from '../../../../shared/price-matrix/entities/TranslationString';
import { TransportType } from '../../../../shared/price-matrix/entities/TransportType';
import { ProductType } from '../../../../shared/price-matrix/enums/ProductType';
import { WeightBoundary } from '../../entities/weightBoundary';
import { ServiceType } from '../../../../shared/price-matrix/enums/ServiceType';
import { PriceMatrixService } from '../../../../shared/price-matrix/services/price-matrix.service';
import { Country } from '../../../../account/entities/Country';
import { ServiceOption } from '../../../entities/serviceOption';
import { ErrorHelper } from '../../../../shared/helpers/error-helper'
import { TranslationHelper } from '../../../../shared/helpers/translation-helper';
import { StaticContentHelper } from '../../../../shared/helpers/static-content-helper';
import { StaticContentService } from '../../../../shared/services/static-content.service';
import { RoleService } from '../../../../account/services/role.service';
import { Role } from '../../../../shared/price-matrix/enums/Role';

@Component({
  selector: 'app-find-prices',
  templateUrl: './find-prices.component.html',
  styleUrls: ['./find-prices.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FindPricesComponent implements OnInit, AfterViewInit {
  vatCost: number = 0;
  observer: MutationObserver;

  constructor(private _formBuilder: FormBuilder,
    private priceMatrixService: PriceMatrixService,
    private route: ActivatedRoute,
    private translateHelper: TranslationHelper,
    private translateService: TranslateService,
    private errorHelper: ErrorHelper,
    private staticContentService: StaticContentService,
    private ref: ChangeDetectorRef,
    private roleService: RoleService,
    private elRef: ElementRef) { }

  findPriceFormGroup: FormGroup;

  countries: Array<Country> = new Array<Country>();

  public isParcelSelected: boolean = false;

  public isShipmentSelected: boolean = false;

  public isLetterSelected: boolean = false;

  public showPrices: boolean = false;

  public numberOfPalettesSelected: boolean = false;

  public serviceOptions: Array<ServiceOption> = new Array<ServiceOption>();

  public productType: ProductType;

  public weightOptions: Array<string> = new Array<string>();

  weightBoundariesRawValues: Array<number> = new Array<number>();

  weightBoundaries: Array<WeightBoundary> = new Array<WeightBoundary>();

  parcelWeightBoundariesRawValues: Array<number> = new Array<number>();

  parcelWeightBoundaries: Array<WeightBoundary> = new Array<WeightBoundary>();

  public letterSizes: Array<TransportType> = new Array<TransportType>();

  public selectedWeightIndex: Number;

  public currentFromCountryCode: string = "FO";

  public palleteSelected: boolean = false;

  public image: string;

  interval: any;

  pageTitle: string;

  pageModules: string;

  ngOnInit(): void {

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.image = this.staticContentService.getImageForPsModule();
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        if (!!this.image)
        {
          clearInterval(this.interval);
        }

        this.translateService.onLangChange.subscribe((x) => {
          this.image = this.staticContentService.getImageForPsModule();
          this.staticContentService.loadAllCssJs();
          this.pageTitle = this.getPageTitle();
          this.pageModules = this.getHtmlAfterPsModule();
        })
        this.ref.detectChanges();
      }
    }, 500);

    this.priceMatrixService.getLetterSizes().subscribe(x => {
      this.letterSizes = x;
    });

    this.priceMatrixService.getBuyPostageWeightBoundaries().subscribe(x => {
      for (let i = 0; i < x.length; i++) {

        //first item so prefix with 0
        if (i == 0) {
          var weightBoundary: WeightBoundary = {
            name: `0-${x[i]}g`,
            index: i
          }

          this.weightBoundaries.push(weightBoundary);
        }
        else {
          var weightBoundary: WeightBoundary = {
            name: `${x[i - 1] + 1}-${x[i]}g`,
            index: i
          }

          this.weightBoundaries.push(weightBoundary)
        }
      }
      this.weightBoundariesRawValues = x;
      this.weightBoundariesRawValues.unshift(0);
    })

    this.priceMatrixService.getBuyPostageWeightBoundaries().subscribe(x => {
      var weightInKgValues = x.map(function (x) { return x / 1000; });
      for (let i = 0; i < x.length; i++) {
        //first item so prefix with 0
        if (i == 0) {
          var weightBoundary: WeightBoundary = {
            name: `0-${weightInKgValues[i]}kg`,
            index: i
          }

          this.parcelWeightBoundaries.push(weightBoundary);
        }
        else {
          var weightBoundary: WeightBoundary = {
            name: `${(weightInKgValues[i - 1] + 0.01).toFixed(2)}-${weightInKgValues[i]}kg`,
            index: i
          }

          this.parcelWeightBoundaries.push(weightBoundary)
        }
      }
      this.parcelWeightBoundariesRawValues = x;
      this.parcelWeightBoundariesRawValues.unshift(0);
    })

    this.countries = this.route.snapshot.data.countries;
    switch (this.translateService.currentLang) {
      case "en":
        this.countries.sort((a, b) => a.countryNameEN.localeCompare(b.countryNameEN))
      case "fo":
        this.countries.sort((a, b) => a.countryNameFO.localeCompare(b.countryNameFO))
    }
    this.countries.sort(function (x, y) { return x.countryCode == "FO" ? -1 : y.countryCode == "FO" ? 1 : 0; });
    this.countries.sort(function (x, y) { return x.countryCode == "DK" ? -1 : y.countryCode == "DK" ? 1 : 0; });

    this.findPriceFormGroup = this._formBuilder.group({
      fromCountry: ['', Validators.required],
      toCountry: ['', Validators.required],
      parcel: ['', Validators.required],
      largeShipment: ['', Validators.required],
      letter: ['', Validators.required],
      // min validator removed due to dropdown input giving error when range starts at 0
      weight: ['', Validators.required],
      // volume: ['', Validators.required],
      numberOfPalettes: ['', Validators.required]
    });

    this.translateService.onLangChange.subscribe(x => {
      this.translateHelper.convertNumberField(this.findPriceFormGroup.get(`weight`));
    });

    this.findPriceFormGroup.get(`weight`).valueChanges.subscribe(newValue => {
      this.translateHelper.formatNumberField(this.findPriceFormGroup.get('weight'));
    });

    this.findPriceFormGroup.valueChanges.subscribe(value => {
      if (this.showPrices = true) {
        this.showPrices = false;
      }
    });

    this.findPriceFormGroup.get("numberOfPalettes").disable();
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));
      });
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

  public hasErrors(field) {
    var errors = this.findPriceFormGroup.get(field).errors;
    return !!errors;
  }

  public getError(field) {
    var errors = this.findPriceFormGroup.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public getLetterSizeNameForLanguage(letterName: TranslationString, description: TranslationString): string {
    switch (this.translateService.currentLang) {
      case "en":
        return `${letterName.EN} (${description.EN})`;
      case "fo":
        return `${letterName.FO} (${description.FO})`;
    }
  }

  public getPrices() {

    this.serviceOptions = new Array<ServiceOption>();

    const letterControl = this.findPriceFormGroup.get("letter");
    const parcelControl = this.findPriceFormGroup.get('parcel');
    const shipmentControl = this.findPriceFormGroup.get("largeShipment");

    this.findPriceFormGroup.markAllAsTouched();

    if (this.isParcelSelected) {
      letterControl.disable();
      shipmentControl.disable();
      parcelControl.setErrors(null);
      letterControl.markAsUntouched();
    }
    if (this.isShipmentSelected) {
      letterControl.disable();
      parcelControl.disable();
      shipmentControl.setErrors(null);
      letterControl.markAsUntouched();
    }
    if (this.isLetterSelected) {
      shipmentControl.disable();
      parcelControl.disable();
    }

    if (this.findPriceFormGroup.valid) {

      letterControl.enable();
      shipmentControl.enable();
      parcelControl.enable();
      this.findPriceFormGroup.markAsUntouched();
    }
    else {
      letterControl.enable();
      shipmentControl.enable();
      parcelControl.enable();
    }

    var letterSizeTransportId;
    var weight = this.translateHelper.getNumber(this.findPriceFormGroup.get("weight").value);
    const letterSizeFormControl = this.findPriceFormGroup.get("letter");
    if (!!letterSizeFormControl && !!letterSizeFormControl.value) {
      const letterSizeTransportType: TransportType = letterSizeFormControl.value;
      letterSizeTransportId = letterSizeTransportType.Key;
      weight = this.translateHelper.getNumber(this.weightBoundariesRawValues[this.findPriceFormGroup.get("weight").value.index + 1]);
    }
    else {
      weight = this.translateHelper.getNumber(this.parcelWeightBoundariesRawValues[this.findPriceFormGroup.get("weight").value.index + 1]);
    }
    //weight += 1;
    weight /= 1000;

    var serviceOptionDetails: FindServiceOptionDetails = {
      FromCountry: this.findPriceFormGroup.get("fromCountry").value.countryCode,
      ToCountry: this.findPriceFormGroup.get("toCountry").value.countryCode,
      Weight: weight,
      ProductType: this.productType,
      Value: 0,
      // Volume: Number(this.findPriceFormGroup.get('volume').value),
      LetterSizeTransportTypeId: letterSizeTransportId,
      IsOneParcel: true,
      Role: this.roleService.role()
    }

    this.priceMatrixService.getServiceOptions(serviceOptionDetails).subscribe(transportTypes => {
      //loop through transport types and set fields

      var i: number;
      for (i = 0; i < transportTypes.length; i++) {

        // var insuranceCost: number;
        // if (!!transportTypes[i].SpecialExtras) {

        //   var insuranceExtra = transportTypes[i].SpecialExtras.find(x => x.Key == "0");
        //   if (!!insuranceExtra) {
        //     insuranceCost = insuranceExtra.BasePrice;
        //   }
        // }

        var minDays = !!transportTypes[i].MinimumDeliveryDays ? transportTypes[i].MinimumDeliveryDays : transportTypes[i].CountryGroups[0].MinimumDeliveryDays;
        var maxDays = !!transportTypes[i].MaximumDeliveryDays ? transportTypes[i].MaximumDeliveryDays : transportTypes[i].CountryGroups[0].MaximumDeliveryDays;

        var serviceOption = new ServiceOption();
        serviceOption.IconPath = transportTypes[i].IconPath;
        serviceOption.Name = transportTypes[i].Name;
        serviceOption.Cost = this.getCostForTransportType(transportTypes[i]);
        serviceOption.DeliveryDays = `${minDays} - ${maxDays}`;
        serviceOption.Description = transportTypes[i].Description;
        serviceOption.Properties = transportTypes[i].Properties;
        serviceOption.Key = transportTypes[i].Key;
        serviceOption.MinDeliveryDays = minDays;
        serviceOption.ProductType = transportTypes[i].ProductTypeId;
        serviceOption.VATCost = this.vatCost;
        // serviceOption.InsuranceCost = insuranceCost;
        this.vatCost = 0;

        this.serviceOptions.push(serviceOption);
      }

      this.showPrices = true;
    });
  }

  private getCostForTransportType(transportType: TransportType): number {
    //From the backend the country group should only return one item so just look at the first item
    if (!!transportType.CountryGroups && transportType.CountryGroups.length > 0) {
      const countryGroup = transportType.CountryGroups[0];
      var totalCost: number = 0;
      //If has base price set it to that otherwise look for the specific parcel range and use the base cost override
      if (countryGroup.BasePriceWeight !== 0) {
        totalCost = countryGroup.BasePriceWeight
      }
      else if (countryGroup.BasePriceVolume !== 0) {
        totalCost = countryGroup.BasePriceVolume
      }

      var weightControl = this.findPriceFormGroup.get("weight").value;
      var weightValue;
      var indexValue = weightControl.index;
      if (this.isLetterSelected)
      {
        weightValue = this.weightBoundariesRawValues[indexValue];
      }
      else {
        weightValue = this.parcelWeightBoundariesRawValues[indexValue]
      }
      weightValue += 1;
      weightValue /= 1000;
      var inputWeight = this.translateHelper.getNumber(weightValue);

      //We can't take into account volume costs as we don't ask for them in find prices
      if (!!countryGroup.ParcelRanges) {
        var i: number;
        for (i = 0; i < countryGroup.ParcelRanges.length; i++) {
          const currentParcelRange = countryGroup.ParcelRanges[i];
          if (inputWeight >= currentParcelRange.MinWeight
            && inputWeight <= currentParcelRange.MaxWeight) {
            //Valid parcel range found, now assign the override cost if it's still 0
            if (currentParcelRange.BaseCostOverride > 0) {
              totalCost = currentParcelRange.BaseCostOverride;
            }

            //Add weight unit costs
            const weightRoundedUp = Math.ceil(inputWeight);
            const additionalWeightCost = weightRoundedUp * currentParcelRange.CostPerUnitWeight;
            totalCost += additionalWeightCost;
            break;
          }
        }

        //At this point we have the totalcost not including incoterms so now work out if VAT needs to be added on.
        //This needs to be done seperately to incoterms as they have VATIncluded as its own flag
        if (countryGroup.IncludeVAT || transportType.IncludeVAT)
        {
          this.vatCost += totalCost*transportType.VATPercentage;
          totalCost *= (1+transportType.VATPercentage);
        }
      }
    }
    else {
      //if no country groups then it's domestic transport or proxy shopping
      if (!!transportType.ParcelRanges) {
        var i: number;
        for (i = 0; i < transportType.ParcelRanges.length; i++) {
          const currentParcelRange = transportType.ParcelRanges[i];
          if (inputWeight >= currentParcelRange.MinWeight
            && inputWeight <= currentParcelRange.MaxWeight) {
            //Valid parcel range found, now assign the override cost if it's still 0
            if (totalCost == 0) {
              totalCost = currentParcelRange.BaseCostOverride;
            }

            //Add weight unit costs
            const weightRoundedUp = Math.ceil(inputWeight);
            const additionalWeightCost = weightRoundedUp * currentParcelRange.CostPerUnitWeight;
            totalCost += additionalWeightCost;
          }
        }

        if (transportType.IncludeVAT)
        {
          this.vatCost += transportType.VATPercentage*totalCost;
          totalCost *= (1+transportType.VATPercentage);
        }
      }
    }

    // if (!!insuranceCost) {
    //   totalCost += insuranceCost;
    // }
    return totalCost;
  }

  public isFromFO() {
    if ((this.isParcelSelected || this.isShipmentSelected) && this.currentFromCountryCode !== "FO") {
      return false;
    }
    if (!this.findPriceFormGroup.get("fromCountry").value) {
      return true;
    }

    if (this.findPriceFormGroup.get("fromCountry").value.countryCode == "FO") {
      return true;
    }
    else {
      this.isLetterSelected = false;
      this.findPriceFormGroup.get("letter").reset();
      return false;
    }
  }

  public isFromToCountryFO() {
    return (this.findPriceFormGroup.get("fromCountry").value.countryCode == "FO" && this.findPriceFormGroup.get("toCountry").value.countryCode == "FO")
  }

  public enableLetterOptionIfFO(event: Country) {
    this.currentFromCountryCode = event.countryCode;
  }

  public getCountryNameForLanguage(country: Country): string {
    switch (this.translateService.currentLang) {
      case "en":
        return country.countryNameEN;
      case "fo":
        return country.countryNameFO;
    }
  }

  public clearAll() {
    this.findPriceFormGroup.reset();
    this.isShipmentSelected = false;
    this.isParcelSelected = false;
    this.isLetterSelected = false;
  }

  public setParcelType(event: any) {
    this.isParcelSelected = true;
    this.isShipmentSelected = false;
    this.isLetterSelected = false;
    this.findPriceFormGroup.get('largeShipment').reset();
    this.findPriceFormGroup.get('letter').reset();
    this.productType = ProductType.Parcel;
  }

  public setShipmentType(event: any) {
    this.isParcelSelected = false;
    this.isShipmentSelected = true;
    this.isLetterSelected = false;
    this.findPriceFormGroup.get('parcel').reset();
    this.findPriceFormGroup.get('letter').reset();
    // this.productType = ServiceType.LargeShipment;
  }

  public setSizeValue(value: any) {
    this.findPriceFormGroup.get('letter').enable();
    this.findPriceFormGroup.get('letter').setValue(value.value);
    this.isParcelSelected = false;
    this.isShipmentSelected = false;
    this.isLetterSelected = true;
    this.findPriceFormGroup.get('parcel').reset();
    this.findPriceFormGroup.get('largeShipment').reset();
    this.productType = ProductType.Postage;

    //the volume will be the same no matter which country group or parcel range so just take the first of both.
    // this.findPriceFormGroup.get('volume').setValue((this.findPriceFormGroup.get('letter').value as TransportType).CountryGroups[0].ParcelRanges[0].MaxVolume)
  }

  public numberPalleteSelected() {
    this.findPriceFormGroup.get("numberOfPalettes").enable();
    this.palleteSelected = true;
  }

  public halfPaletteSelected() {
    this.findPriceFormGroup.get("numberOfPalettes").reset();
    this.findPriceFormGroup.get("numberOfPalettes").disable();
    this.palleteSelected = true;
  }

  public getPageTitle() {
    return this.staticContentService.getPageTitle();
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }

}
