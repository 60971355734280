import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorHelper } from '../../shared/helpers/error-helper';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;

  passwordSuccessfullyChanged: boolean = false;

  passwordsDoNotMatch: boolean = false;

  passwordChangeFailed: boolean = false;

  constructor(private route: ActivatedRoute,
     private _formBuilder: FormBuilder,
     private authService: AuthenticationService,
     private userService: UserService,
     private errorHelper: ErrorHelper) { }

  ngOnInit(): void {
    
    this.changePasswordForm = this._formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {validators: this.passwordMatchValidator});

  }

  public hasErrors(field) {
    var errors = this.changePasswordForm.get(field).errors;
    return !!errors;
  }

  public getError(field) {
    var errors = this.changePasswordForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  passwordMatchValidator(g: FormGroup) {
    if(g.get('newPassword').value === g.get('confirmPassword').value){
      g.get('confirmPassword').setErrors(null);
      return null;
    }
    else{
      g.get('confirmPassword').setErrors({'mismatch': true});
      return {'mismatch': true};
    }
  }

  submit() {

    if (!this.changePasswordForm.valid || (this.changePasswordForm.get('newPassword').value !== this.changePasswordForm.get('confirmPassword').value))
    {
      this.changePasswordForm.markAllAsTouched();
      return;
    }

    var changeRequest: any = {
      newPassword: this.changePasswordForm.get('newPassword').value,
      currentPassword: this.changePasswordForm.get('currentPassword').value
    }

    //call endpoint
    this.userService.changePassword(changeRequest).subscribe(x => {
      if (x)
      {
        this.passwordSuccessfullyChanged = true;
      }
      else {
        this.changePasswordForm.get("currentPassword").setErrors({'invalid_password': true})
      }
    }, error => {
      this.passwordChangeFailed = true;
    })
  }

}
