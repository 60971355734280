<div class="container">
    <div class="account-statement-container">
        <h2 class="account-statement-title">{{'account_statement.title' | translate}}</h2>
        <h4 *ngIf="showAccountSelect" style="padding-bottom: 10px">{{'account_statement.select_account' | translate}}</h4>
        <div *ngIf="showAccountSelect" class="form-field-padding">
            <mat-form-field class="no-error-wrap float-never weight-input" floatLabel="never"
                appearance="outline" [formGroup]="filterFormGroup">
                <mat-select placeholder="{{'account_statement.select_account_placeholder' | translate}}" [(ngModel)]="selectedAccount" disableOptionCentering panelClass="select-panel"
                    formControlName="account" required>
                    <!--(selectionChange)="setWeight($event)"-->
                    <mat-option *ngFor="let account of customerAccounts" [value]="account">
                        {{account.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <h4 style="padding-bottom: 10px; padding-top: 10px" class="to-label">{{'account_statement.from' | translate}}:</h4>
        <div class="form-field-padding">
            <mat-form-field class="fill-container form-field-date-container" floatLabel="never" appearance="outline"
               [formGroup]="filterFormGroup">
               <input matInput placeholder="00/00/0000" matInput readonly [matDatepicker]="picker1" id="fromDate"
                  name="fromDate" formControlName="fromDate">
               <mat-datepicker #picker1></mat-datepicker>
               <img class="calendar-icon" src="/assets/icons/calendar@3x.png" (click)="picker1.open()" />
            </mat-form-field>
        </div>
        <h4 style="padding-bottom: 10px; padding-top: 10px" class="to-label">{{'account_statement.to' | translate}}:</h4>
        <div class="form-field-padding">
            <mat-form-field class="fill-container form-field-date-container" floatLabel="never" appearance="outline"
               [formGroup]="filterFormGroup">
               <input matInput placeholder="00/00/0000" matInput readonly [matDatepicker]="picker2" id="toDate"
                  name="toDate" formControlName="toDate">
               <mat-datepicker #picker2></mat-datepicker>
               <img class="calendar-icon" src="/assets/icons/calendar@3x.png" (click)="picker2.open()" />
            </mat-form-field>
        </div>
        <div style="margin-top: 20px;">
            <app-error-message *ngIf="downloadFailed" errorMessage="{{'account_statement.download_error' | translate}}"></app-error-message>
        </div>
        <div class="submit-button-container">
            <button class="primary-button submit-button" (click)="downloadStatement()" [disabled]="downloading">
                <div class="spinner">
                    {{'account_statement.download' | translate}}
                    <mat-spinner *ngIf="downloading" class="mat-spin" diameter="15">
                    </mat-spinner>
                </div>
            </button>
        </div>
    </div>

</div>



<!--

    <div class="align-center form-field-date-group from-date-group">
            <h4 class="from-label">{{'all_orders_page.from' | translate}}</h4>
            <mat-form-field class="fill-container form-field-date-container" floatLabel="never" appearance="outline"
               [formGroup]="filterFormGroup">
               <input matInput placeholder="00/00/0000" matInput readonly [matDatepicker]="picker1" id="fromDate"
                  name="fromDate" formControlName="fromDate">
               <mat-datepicker #picker1></mat-datepicker>
               <img class="calendar-icon" src="/assets/icons/calendar@3x.png" (click)="picker1.open(); selectSpecificDate()" />
            </mat-form-field>
         </div>
         <div class="align-center form-field-date-group to-date-group">
            <h4 class="to-label">{{'all_orders_page.to' | translate}}:</h4>
            
         </div>


-->