import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../../environments/environment";
import { CompanyAndUser } from "../entities/companyAndUser";

@Injectable({ providedIn: 'root' })
export class RegisterService {

    constructor(
        private router: Router,
        private httpClient: HttpClient
    ) { }

    public registerCompanyAndUser(companyAndUser: CompanyAndUser): Observable<boolean> {
        return this.httpClient.post(`${environment.apiUrl}/api/user/bu_register`, companyAndUser) as Observable<boolean>;
    }

    public individualRegister(user: any): Observable<boolean> {
        return this.httpClient.post(`${environment.apiUrl}/api/user/iu_register`, user) as Observable<boolean>;
    }
}