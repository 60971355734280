import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { ServiceOption } from '../../../entities/serviceOption';
import { TranslateService } from '@ngx-translate/core';
import { TranslationString } from '../../../../shared/price-matrix/entities/TranslationString';
import { AuthenticationService } from '../../../../account/services/authentication.service';
import { StaticContentService } from '../../../../shared/services/static-content.service';
import { RoleService } from '../../../../account/services/role.service';
import { ProductType } from '../../../../shared/price-matrix/enums/ProductType';

@Component({
  selector: 'app-view-prices',
  templateUrl: './view-prices.component.html',
  styleUrls: ['./view-prices.component.scss']
})
export class ViewPricesComponent implements OnInit {

  @Input()
  findPriceFormGroup: FormGroup;

  @Input()
  isShipmentSelected: boolean = false;

  @Input()
  isParcelSelected: boolean = false;

  @Input()
  serviceOptions: Array<ServiceOption>;

  @Input()
  insuranceSelected: boolean;

  @Input()
  stepper: MatStepper

  @Input()
  toFromFO: boolean;

  @Output()
  chosenServiceOption: EventEmitter<ServiceOption> = new EventEmitter();

  isLoggedIn: boolean;

  public psModuleContent: any;

  public item1Link: any;

  public item2Link: any;
  public item3Link: any;
  public item4Link: any;

  interval: any;

  //add PriceMatrixService
  constructor(public router: Router,
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private staticContentService: StaticContentService,
    private roleService: RoleService) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
    console.log(this.serviceOptions);
    
    this.psModuleContent = this.staticContentService.getPsModuleContent('send-receive', 'send-receive/find-prices')
    if (!!this.psModuleContent)
    {
      this.item1Link = this.psModuleContent.Item1Link;
      this.item2Link = this.psModuleContent.Item2Link;
      this.item3Link = this.psModuleContent.Item3Link;
      this.item4Link = this.psModuleContent.Item4Link;
    }

    this.interval = setInterval(() => {
      var el = document.getElementsByClassName("key-background-container");
      if (!!el && el.length > 0)
      {
        el[0].scrollIntoView(true);
        clearInterval(this.interval);
      }
    }, 200);
  }

  public chooseServiceOption(serviceOption: ServiceOption) {
    this.chosenServiceOption.emit(serviceOption);
  }

  public navigatetoSendAndReceive() {

    let navigationExtras: NavigationExtras = {
      queryParams: {
          "fromCountry": this.findPriceFormGroup.get("fromCountry").value,
          "toCountry": this.findPriceFormGroup.get("toCountry").value,
          "isParcel": this.isParcelSelected,
          "isLargeShipment": this.isShipmentSelected,
          "letter": this.findPriceFormGroup.get("letter").value,
          "weight": this.findPriceFormGroup.get("weight").value,
          "numberOfPalettes": this.findPriceFormGroup.get("numberOfPalettes").value,
      },
  };

  if (this.isParcelSelected) {
    navigationExtras.queryParams
  }

    this.router.navigate([`../${this.translateService.currentLang}/${this.roleService.role()}/send-receive/send-receive`], navigationExtras);
  }

  public getNameByLanguage(transportTypeName: TranslationString): string {
    switch (this.translateService.currentLang) {
      case "en":
        return transportTypeName.EN;
      case "fo":
        return transportTypeName.FO;
    }
  }

  public getIconTextByLanguage(translationName: TranslationString)
  {
    switch (this.translateService.currentLang) {
      case "en":
        return translationName.EN;
      case "fo":
        return translationName.FO;
    }
  }

  public openRouteInNewWindow(route: string){
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  public showPostOfficeButton(productType: ProductType)
  {
    if (productType == ProductType.Air_Freight || productType == ProductType.Sea_Freight || productType == ProductType.DomesticTransport)
    {
      return false;
    }
    return true;
  }
}
