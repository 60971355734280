import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../services/authentication.service';
import { ErrorHelper } from '../../shared/helpers/error-helper';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;

  role: string;

  isPrivate: boolean = false;

  userTypeId: number;

  passwordResetRequested: boolean = false;

  isPostaApproved: boolean = true;

  constructor(private _formBuilder: FormBuilder,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private errorHelper: ErrorHelper) { }

  ngOnInit(): void {

    this.role = this.route.snapshot.paramMap.get('role');
    this.setRole();

    this.resetPasswordForm = this._formBuilder.group({
      email: ['', Validators.required]
    });
  }

  public submit()
  {
    if (!this.resetPasswordForm.valid)
    {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }

    var forgottenInput: any = {
      email: this.resetPasswordForm.get('email').value,
      languageCode: this.translateService.currentLang.toUpperCase(),
      userTypeId: this.userTypeId
    }

    //call endpoint
    this.authService.resetPassword(forgottenInput).subscribe(x => {
      this.passwordResetRequested = true;
    }, (error) => {
      if (!!error.error && error.error.isPostaApproved == false)
      {
        this.isPostaApproved = false;
      }
      this.passwordResetRequested = true;
    });
  }

  private setRole()
  {
    if (this.role == "private")
    {
      this.userTypeId = 2;
      this.isPrivate = true;
      return;
    }
    this.userTypeId = 1;
    this.isPrivate = false;
  }

  public hasErrors(field) {
    var errors = this.resetPasswordForm.get(field).errors;
    return !!errors;
  }

  public getError(field) {
    var errors = this.resetPasswordForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

}
