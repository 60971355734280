import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../entities/user";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { CookieService } from "../../shared/services/cookie.service";
import { TranslateService } from "@ngx-translate/core";
import { SamleikinSession } from "../entities/samleikinSession";
import { SamleikinCredentials } from "../entities/samleikinCredentials";
import { RoleService } from "./role.service";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService,
        private translateService: TranslateService,
        private roleService: RoleService
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public getUser(): User {
        return this.userSubject.value;
    }

    public isLoggedIn(): boolean {
        return !!(this.getUser() && this.cookieService.getCookie('token'));
    }

    login(username: string, password: string, userTypeId: number, languageCode: string) {
        return this.http.post(`${environment.apiUrl}/api/Authentication`, {
            "UserName": username,
            "Password": password,
            "UserTypeId": userTypeId,
            "LanguageCode": languageCode
        }, { responseType: 'json' })
            .pipe(map(userToken => {
                const user = new User;
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('loginUserType', this.getUserType(userTypeId));
                localStorage.setItem('userDetails', JSON.stringify(userToken));

                if(!!localStorage.getItem("isSamleikin"))
                {
                    localStorage.removeItem("isSamleikin");
                }
                this.userSubject.next(user);

                if (!!(userToken as any).token)
                {
                    this.cookieService.setCookie("token", (userToken as any).token, 1440);
                }
                this.roleService.setRole(this.getUserType(userTypeId));
                return JSON.stringify(userToken);
            }));
    }

    samleikinLogin(userCredentials: SamleikinCredentials): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/Authentication`, userCredentials, { responseType: 'json' })
            .pipe(map(userToken => {
                const user = new User;
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('loginUserType', "private");
                localStorage.setItem('userDetails', JSON.stringify(userToken));
                localStorage.setItem('isSamleikin', "true");
                this.userSubject.next(user);
                this.cookieService.setCookie("token", (userToken as any).token, 1440);
                this.roleService.setRole("private");
                return userToken;
            }));
    }

    logout() {
        return this.http.get<any>(`${environment.apiUrl}/api/Authentication`).pipe(map((response) => {
            this.stopRefreshTokenTimer();
            if (!!this.cookieService.getCookie('token')) {
                this.cookieService.deleteCookie('token');
            }
            localStorage.removeItem('user');
            localStorage.removeItem('userDetails');
            localStorage.removeItem("isSamleikin");
            localStorage.removeItem('loginUserType');

            this.userSubject.next(null);
            // this.router.navigate([`../${this.translateService.currentLang}/private/home`])
            this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/home`])
            return response
        }, (err) =>  {
            if (!!this.cookieService.getCookie('token')) {
                this.cookieService.deleteCookie('token');
            }
            localStorage.removeItem('user');
            localStorage.removeItem('userDetails');
            localStorage.removeItem("isSamleikin");
            localStorage.removeItem('loginUserType');
        }));
    }

    logoutShibboleth() {
        return this.http.get(`${window.location.href.split('/').slice(0, 3).join('/')}/Shibboleth.sso/Logout`);
    }

    validateToken() {
        return this.http.get<any>(`/ConsumerServiceV2.svc/GetDataAsync`)
            .pipe(map((response) => {
                return response;
            }, (err) => {
                if (!!this.cookieService.getCookie('token')) {
                    this.cookieService.deleteCookie('token');
                }
                localStorage.removeItem('user');
                localStorage.removeItem('userDetails');
                localStorage.removeItem("isSamleikin");
                localStorage.removeItem('loginUserType');
            }));
    }

    resetPassword(forgottenInput: any) {
        return this.http.post(`${environment.apiUrl}/api/Authentication/ForgottenPassword`, forgottenInput) as Observable<any>;
    }
    
    verifyAccount(input: any) {
          return this.http.put(`${environment.apiUrl}/api/Authentication/validatecodeandaction`, input);
    }

    resetPasswordRequest(input: any) {
        return this.http.put(`${environment.apiUrl}/api/Authentication/validatecodeandaction`, input);
    }

    getSessionData() {
        return this.http.get(`${window.location.href.split('/').slice(0, 3).join('/')}/Shibboleth.sso/Session`) as Observable<SamleikinSession>;
    }

    getPreAuthSamleikin() {
        return this.http.get(`${environment.apiUrl}/api/Authentication/PreAuthGuid`) as Observable<string>;
    }




    // helper methods

    private refreshTokenTimeout;

    // private startRefreshTokenTimer() {
    //     // parse json object from base64 encoded token
    //     const token = JSON.parse(atob(this.userValue().token.split('.')[1]));

    //     // set a timeout to refresh the token a minute before it expires
    //     const expires = new Date(token.exp * 1000);
    //     const timeout = expires.getTime() - Date.now() - (60 * 1000);
    //     this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    // }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    private getUserType(userTypeId: number)
    {
        if (userTypeId == 1)
        {
            return "business";
        }
        if (userTypeId == 2)
        {
            return "private";
        }
    }

}