import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaybillListComponent } from './waybill-list/waybill-list.component';
import { WaybillDetailComponent } from './waybill-detail/waybill-detail.component'

import { WaybillRoutingModule } from './waybill-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    WaybillListComponent,
    WaybillDetailComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    WaybillRoutingModule,
    MatFormFieldModule,
    SharedModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule
  ]
})
export class WaybillModule { }
