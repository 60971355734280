import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CustomShipmentType } from "../../../../shared/entities/customShipmentType";
import { CustomShipmentTypeService } from "../../../../shared/services/custom-shipment-types.service";

@Injectable({ providedIn: 'root' })
export class ShipmentTypesResolver implements Resolve<Array<CustomShipmentType>> {

    constructor(private shipmentTypeService: CustomShipmentTypeService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>| Promise<any>|any {
        return this.shipmentTypeService.getAll();
    }
}