import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SendAndReceiveComponent } from './new-order/send-and-receive/send-and-receive.component';
import { FindPricesComponent } from './new-order/find-prices/find-prices/find-prices.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { ProfileCompleteGuard } from '../shared/guards/profileComplete.guard';
import { OrderResolver } from './new-order/send-and-receive/services/order.resolver';
import { TaxResolver } from './new-order/send-and-receive/services/tax.resolver';
import { ViewOrderComponent } from './view-order/view-order.component';
import { ShipmentTypesResolver } from './new-order/send-and-receive/services/shipmentTypes.resolver';
import { CountryResolver } from './new-order/send-and-receive/services/country.resolver';
import { BuyPostageComponent } from './buy-postage/buy-postage/buy-postage.component';
import { BuyPostageLandingComponent } from './buy-postage/buy-postage-landing/buy-postage-landing.component';
import { TrackAndTraceComponent } from './track-and-trace/track-and-trace.component';
import { ViewPostageOrderComponent } from './view-order/view-postage-order/view-postage-order.component';
import { UserDetailsResolver } from './services/user-details.resolver';
import { BuyPostageOrderResolver } from './services/buy-postage-order.resolver';
import { TestComponent } from './test/test.component';

const routes: Routes = [
    { path: ':lang/:role/send-receive/track-trace', component: TrackAndTraceComponent},
    { path: ':lang/:role/send-receive/track-trace/:trackTraceId', component: TrackAndTraceComponent },
    { path: ':lang/:role/send-receive/send-receive', component: SendAndReceiveComponent,canActivate: [AuthGuard, ProfileCompleteGuard]},
    { path: ':lang/:role/my-posta/orders/send-receive/:orderReferenceId', component: ViewOrderComponent, resolve: { countries: CountryResolver, order: OrderResolver, shipmentTypes: ShipmentTypesResolver, taxPercentage: TaxResolver }},
    { path: ':lang/:role/send-receive/send-receive/:orderReferenceId', component: SendAndReceiveComponent, resolve: { order: OrderResolver }, canActivate: [AuthGuard, ProfileCompleteGuard]},
    { path: ':lang/send-receive/:findPriceFormGroup', component: SendAndReceiveComponent},
    { path: ':lang/:role/send-receive/find-prices', component: FindPricesComponent, resolve: { countries: CountryResolver} },
    { path: ':lang/:role/send-receive/buy-postage', component: BuyPostageComponent },
    { path: ':lang/:role/send-receive/buy-postage-home', component: BuyPostageLandingComponent },
    { path: ':lang/:role/my-posta/orders/buy-postage/:orderId', component: ViewPostageOrderComponent, resolve: { userDetails: UserDetailsResolver, order: BuyPostageOrderResolver } },
    { path: ':lang/:role/send-receive/test', component: TestComponent, canActivate: [AuthGuard]},
    {
      path: 'cn22',
      component: SendAndReceiveComponent,
      resolve: {
          url: 'externalUrlRedirectResolver'
      },
      data: {
          externalUrl: 'https://cdn.posta.fo/media/5s2n5wv0/cn22.pdf'
      }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
        provide: 'externalUrlRedirectResolver',
        useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        {
            window.location.href = (route.data as any).externalUrl;
        }
    }
]
})
export class SendReceiveRoutingModule { }
