import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-brochure-dialog',
  templateUrl: './brochure-dialog.component.html',
  styleUrls: ['./brochure-dialog.component.scss']
})
export class BrochureDialogComponent implements OnInit {
    @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BrochureDialogComponent>) {}

    ngOnInit() {
        // will log the entire data object
        console.log(this.data)
      }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public onLoad(event: any): void {
    if (this.data.pageIndex)
    {
        this.pdfViewerAutoLoad.page = this.data.pageIndex;
    }
  }
}
