import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from '../../../shared/services/cookie.service';
import { StaticContentService } from '../../../shared/services/static-content.service';
import { PostageConfirmation } from '../../entities/postageConfirmation';
import { BuyPostageService } from '../../services/buy-postage.service';

@Component({
  selector: 'app-buy-postage-confirmation',
  templateUrl: './buy-postage-confirmation.component.html',
  styleUrls: ['./buy-postage-confirmation.component.scss']
})
export class BuyPostageConfirmationComponent implements OnInit, OnDestroy {

  public psModuleContent: any;

  public item1Link: any;

  @Input()
  orderConfirmationDetails: PostageConfirmation;

  @Input()
  anonymousUserGuid: string;

  @Input()
  isGoods: boolean;

  email: string;

  cn22Email: string;

  emailSentTo: string;

  emailCN22SentTo: string;

  showEmailOrderSuccess: boolean = false;

  showEmailCN22Success: boolean = false;

  downloadingPdfOrder: boolean = false;

  downloadingCN22: boolean = false;

  sendingOrderEmail: boolean = false;

  sendingCN22Email: boolean = false;

  cdnPath: string;

  @Input()
  isDomestic: boolean;

  constructor(
    private buyPostageService: BuyPostageService, 
    private translationService: TranslateService, 
    private staticContentService: StaticContentService, 
    private cookieService: CookieService, 
    private router: Router) { }

  ngOnInit(): void {
    this.cdnPath = this.staticContentService.cdn();

    var mainWrapper = document.getElementsByClassName('main-content') as HTMLCollectionOf<HTMLElement>;

    if (mainWrapper.length != 0)
    {
      mainWrapper[0].style.background = "#F1F2F2";
    }

    this.psModuleContent = this.staticContentService.getPsModuleContent('send-receive', 'send-receive/buy-postage')
    if (!!this.psModuleContent)
    {
      this.item1Link = this.psModuleContent.Item1Link;
    }
  }

  public ngOnDestroy()
  {
    var mainWrapper = document.getElementsByClassName('main-content') as HTMLCollectionOf<HTMLElement>;

    if (mainWrapper.length != 0)
    {
      mainWrapper[0].style.background = "none";
    }
  }


  public downloadReceipt()
  {
    this.downloadingPdfOrder = true;
    // this.cookieService.setCookie('tempToken', this.anonymousUserGuid, 560);
    this.buyPostageService.downloadReceipt(this.orderConfirmationDetails.orderReference, this.translationService.currentLang, this.anonymousUserGuid).subscribe(x => {
      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    }, (error) => {
      this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    })
  }

  public downloadCN22()
  {
    this.downloadingCN22 = true;
    this.buyPostageService.downloadCDS(this.orderConfirmationDetails.barcode).subscribe(x => {
      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingCN22 = false;
    }, (error) => {
      this.downloadingCN22 = false;
    })
    // this.buyPostageService.downloadCN22(this.orderConfirmationDetails.orderReference).subscribe(x => {
    //   const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
    //   const blob = new Blob([byteArray], {type: 'application/pdf'});
    //   const url = window.URL.createObjectURL(blob);
    //   // window.open(url);
    //   var link = document.createElement('a');
    //   link.href = url;
    //   link.download = x.fileName;
    //   link.click();
    //   this.downloadingCN22 = false;
    //   // this.downloadingReceipt = false;
    // }, (error) => {
    //   // this.downloadingReceipt = false;
    //   this.downloadingCN22 = false;
    // })

    ////////////////

    //window.open("https://cdn.posta.fo/media/5s2n5wv0/cn22.pdf", '_blank').focus();
  }

  public sendEmail()
  {
    if (!!this.email)
    {
      this.sendingOrderEmail = true;
      this.buyPostageService.emailOrder(this.orderConfirmationDetails.orderReference, this.translationService.currentLang, this.email).subscribe(x => {
        this.emailSentTo = this.email;
        this.showEmailOrderSuccess = true;
        this.sendingOrderEmail = false;
      })
    }
  }

  public sendCN22Email()
  {
    if (!!this.cn22Email)
    {
      this.sendingCN22Email = true;
      this.buyPostageService.emailCN22(this.cn22Email).subscribe(x => {
        this.emailCN22SentTo = this.cn22Email;
        this.showEmailCN22Success = true;
        this.sendingCN22Email = false;
      }, (error) => {
        this.sendingCN22Email = false;
      })
    }
  }

  public openRouteInNewWindow(route: string){
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }
}
