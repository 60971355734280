import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class UserProfileResolver implements Resolve<any> {

    constructor (private userService: UserService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>| Promise<any>|any {
        
        var role = route.paramMap.get('role');
        if (!role || (role != "private" && role != "business"))
        {
            return;
        }

        if (role == "private")
        {
            return this.userService.getIndividualUserProfile().pipe(
                map((x) => ({x})),
                catchError((error) => {
                    console.log("No valid user found");
                  return of({ x: false});
                }),
            );
        }
        else{
            //We already done the check above so we know it'll be business if it's not private
            return this.userService.getBusinessUserProfile().pipe(
                map((x) => ({x})),
                catchError((error) => {
                    console.log("No valid user found");
                  return of({ x: false});
                }),
            );
        }

        // return this.authenticationService.verifyAccount(verifyInput).pipe(
        //     map((x) => ({x})),
        //     catchError((error) => {
        //         console.log("Invalid verification code provided");
        //       return of({ x: false});
        //     }),
        //   );
    }
}