<div mat-dialog-title>
    <div class="dialog-title">
        <button mat-icon-button class="close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
          </button>
    </div>
    <div class="d-flex align-center title-container">
        <h2>{{'bera.share.your_share_link' | translate}}</h2>
    </div>
  </div>
<mat-dialog-content class="mat-typography">
    <div *ngIf="data.link" class="d-flex align-center title-container">
        <mat-form-field disabled class="search-address-col" floatLabel="never" appearance="outline">
            <input readonly id="generatedLink" class="text-input" matInput placeholder="{{'bera.share.shopping_list_name_placeholder' | translate}}"
                type="text" name="shoppingListName" [(ngModel)]="shoppingListName" autocomplete="off">
        </mat-form-field>
        <div class="address-menu-item">
            <button class="primary-button add-button" [cdkCopyToClipboard]="shoppingListName" (click)="showCopiedMessage = true;">
                <img class="copy-icon" src="/assets/icons/shopping-list/copy_icon.png" />
            </button>
        </div>
    </div>
    <div *ngIf="showCopiedMessage" class="title-container"><h3>{{'bera.share.link_copied_to_clipboard' | translate}}</h3></div>
    <div *ngIf="data.plainText !== ''" class="plain-text-container">
        <h3>{{'bera.share.plain_text' | translate}}</h3>
        <textarea class="plain-text-textarea">{{data.plainText}}</textarea>
    </div>
</mat-dialog-content>