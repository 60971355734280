<div class="container">
    <div *ngIf="!passwordSucessfullyReset && !passwordResetFailed" class="reset-password-container">
        <h2 class="reset-password-title">{{'reset_password.reset_password_title' | translate}}</h2>
        <h4 class="primary" style="padding-bottom: 10px">{{'reset_password.specify_password' | translate}}</h4>
        <div class="row password-input-container">
            <mat-form-field class="col-lg-8 align-form-field" [formGroup]="resetPasswordForm" floatLabel="never"
                appearance="outline">
                <input type="password" matInput placeholder="{{'reset_password.specify_password_placeholder' | translate}}" id="specifyPassword" name="specifyPassword"
                    formControlName="specifyPassword" required>
            </mat-form-field>
        </div>
        <h4 class="primary" style="padding-bottom: 10px">{{'reset_password.confirm_password' | translate}}</h4>
        <div class="row password-input-container">
            <mat-form-field class="col-lg-8 align-form-field" [formGroup]="resetPasswordForm" floatLabel="never"
                appearance="outline">
                <input type="password" matInput placeholder="{{'reset_password.confirm_password_placeholder' | translate}}" id="confirmPassword"
                    name="confirmPassword" formControlName="confirmPassword" required>
            </mat-form-field>
        </div>

        <div class="submit-button-container">
            <button class="primary-button submit-button" (click)="submit()">
                {{'reset_password.submit' | translate}}
            </button>
        </div>
    </div>

    <div *ngIf="passwordResetFailed" class="reset-password-container">
        <h2 class="password-reset-requested-title">{{'reset_password.reset_failed_title' | translate}}</h2>
        <div>{{'reset_password.reset_failed_body' | translate}}</div>
        <div class="button-container">
            <div class="flex-item">
                <button class="primary-button go-home-button" [routerLink]="['../../private/forgotten-password']">
                    {{'reset_password.go_to_reset' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>