import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/internal/operators/first';
import { ErrorHelper } from '../../../shared/helpers/error-helper';
import { ScrollViewHelper } from '../../../shared/helpers/scroll-view-helper';
import { AuthenticationService } from '../../services/authentication.service';
import { RoleService } from '../../services/role.service';
import { StaticContentService } from '../../../shared/services/static-content.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateShoppingListsDialogComponent } from '../../../shopping-services/create-shopping-lists-dialog/create-shopping-lists-dialog.component';

@Component({
  selector: 'app-business-login',
  templateUrl: './business-login.component.html',
  styleUrls: ['./business-login.component.scss']
})

export class BusinessLoginComponent implements OnInit {

  passwordForm: FormGroup;

  loginFailed: boolean = false;

  loading: boolean = false;

  returnUrl: string;

  public pageTitle: string;

  public pageModules: string;

  interval: any;

  constructor(private _formBuilder: FormBuilder, private authService: AuthenticationService, private route: ActivatedRoute,
    private router: Router, private translateService: TranslateService, private errorHelper: ErrorHelper, private roleService: RoleService, private scrollViewHelper: ScrollViewHelper,
    private staticContentService: StaticContentService, public dialog: MatDialog) { }

  ngOnInit(): void {

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
    })

    this.passwordForm = this._formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || `${this.translateService.currentLang}/business/home`;
  }

  public getPageTitle()
  {
    var temp = this.staticContentService.getPageTitle();
    return temp;
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }

  public hasErrors(field) {
    var errors = this.passwordForm.get(field).errors;
    return !!errors;
  }

  public getError(field) {
    var errors = this.passwordForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public login() {
    if (this.passwordForm.invalid) {
      return;
    }


    this.loading = true;
    this.authService.login(this.passwordForm.get('email').value, this.passwordForm.get('password').value, 1, this.translateService.currentLang)
      .pipe(first())
      .subscribe(
        data => {
          this.loginFailed = false;
          var userDetails = JSON.parse(data.toString());
          if (!userDetails.isPostaApproved) {
            this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/awaiting-approval`]);
            return;
          }
          if (!userDetails.isProfileComplete) {
            this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/my-posta/update-profile`]);
            return;
          }

          this.createShoppingLists();

          this.router.navigate([this.returnUrl]);
        },
        error => {
          if (error.status == 401) {
            this.scrollViewHelper.scrollIntoViewAndCenter("login-error");
            this.loginFailed = true;
          }
          if (error.status === 403) {
            this.router.navigate([`${this.translateService.currentLang}/business/awaiting-approval`]);
            // this.router.navigateByUrl('../awaiting-approval')
          }
          // this.alertService.error(error);
          this.loading = false;
        });
  }

  public createShoppingLists()
  {
  var loggedOutLists = localStorage.getItem('shoppingLists');
    if (!!loggedOutLists) {
      var shoppingListsObj = JSON.parse(loggedOutLists);

      const dialogRef = this.dialog.open(CreateShoppingListsDialogComponent, {
        height: '240px',
        width: '580px',
        data: {
          lists: shoppingListsObj,
        }
      });
    }
  }

}
