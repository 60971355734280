import { AuthenticationService } from "./account/services/authentication.service";
import { CookieService } from "./shared/services/cookie.service";

export function appInitializer(authenticationService: AuthenticationService, cookieService: CookieService) {
    if (!cookieService.getCookie('token')) {
        return() => Promise.resolve();
    }
    return () => new Promise(resolve => {
        // attempt to refresh token on app start up to auto authenticate
        authenticationService.validateToken()
            .subscribe()
            .add(resolve);
    });
}