<div class="container header-container">
    <h1 class="new-order-title">{{'view_send_receive_order.order' | translate}}: {{order.orderReference}}&nbsp;</h1>
    <div class="print-label-button-container">
        <button *ngIf="!!order.orderReferenceOther && isDomestic()" class="print-label-button primary-button"
            [disabled]="downloadingPrintLabel" (click)="downloadPrintLabel()"
            mat-button>{{'send_receive.send_receive.confirmation.print_label' | translate}}</button>
        <button *ngIf="order.isCDSFiled && !!order.barcode" class="print-label-button primary-button"
            [disabled]="downloadingCDS" (click)="downloadCDS()"
            mat-button>{{'send_receive.send_receive.confirmation.download_cds' | translate}}</button>
    </div>
</div>

<div *ngIf="displayContactPosta; else displayOrder" class="container">
    <div class="paid-order-confirmation-container">
        <h2 class="posta-id">{{'send_receive.send_receive.confirmation.contact_posta_title' | translate}}:
            {{order.orderReference}}</h2>
        <div class="paid-order-body">
            {{'send_receive.send_receive.confirmation.contact_posta_body' | translate}}
        </div>
    </div>
</div>

<ng-template #displayOrder>
    <div class="container parent-container">
        <div class="row-container">
            <div class="row">
                <div *ngIf="!!userDetails" class="col-lg-3 col-md-6 col-12">
                    <h2 class="header">{{'send_receive.send_receive.step_four.your_details' | translate}}</h2>
                    <p>{{userDetails.title}} {{userDetails.firstName}} {{userDetails.lastName}}</p>
                    <p>{{userDetails.addressLine1}}</p>
                    <!-- <p>{{userDetails.addressLine2}}</p> -->
                    <p>{{userDetails.townCity}}</p>
                    <p>{{userDetails.postcode}}</p>
                    <p>{{getCountryName(userDetails.country)}}</p>
                    <span>{{userDetails.contactNumber}}</span>
                </div>
                <div *ngIf="serviceOptionName" class="col-lg-3 col-md-6 col-12">
                    <h2 class="header">{{'send_receive.send_receive.step_four.service' | translate}}</h2>
                    <div style="padding-bottom: 30px">
                        <p>{{serviceOptionName}}</p>
                        <!-- <button class="secondary-button padded button-max-width">View Instructions</button> -->
                    </div>
                    <h2 class="header">{{'send_receive.send_receive.step_four.tracking_id' | translate}}
                    </h2>
                    <p>{{order.trackingCode}}</p>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="row" style="padding-left: 15px">
                        <h2 class="header">{{'send_receive.send_receive.step_four.key_terms' | translate}}
                            <label class="tooltipSource"><img class="information-icon"
                                    src="/assets/icons/i_information@3x.png" />
                                <a tabindex="0"></a><span>{{'view_send_receive_order.keyterms_tooltip' |
                                    translate}}</span></label>
                        </h2>
                    </div>
                    <p style="padding-bottom: 30px">{{'send_receive.send_receive.step_four.description' | translate}}</p>
                    <!-- <button class="secondary-button padded button-max-width">View all terms</button> -->
                    <h2 *ngIf="!!order.paymentCodes && order.paymentCodes.length > 0" class="header">{{'send_receive.send_receive.step_four.parcel_code' | translate}}
                    </h2>
                    <p *ngIf="!!order.paymentCodes && order.paymentCodes.length > 0">{{order.paymentCodes[0]}}</p>
                </div>
                <div *ngIf="!!insuranceCost" class="col-lg-3 col-md-6 col-12">
                    <div class="row" style="padding-left: 15px">
                        <h2 class="header">{{'send_receive.send_receive.step_four.insurance' | translate}}
                            <label class="tooltipSource"><img class="information-icon"
                                    src="/assets/icons/i_information@3x.png" />
                                <a tabindex="0"></a><span>{{'view_send_receive_order.insurance_tooltip' |
                                    translate}}</span></label>
                        </h2>
                    </div>
                    <p>{{insuranceCost}} DKK</p>
                    <!-- <button *ngIf="!!insuranceCost" class="secondary-button padded button-max-width">View insurance
                        cover</button> -->
                </div>
            </div>
        </div>
        <div class="row-container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-12">
                    <h2 class="header">{{'send_receive.send_receive.step_four.your_ref' | translate}}</h2>
                    <div style="padding-bottom: 30px">{{order.customerReference}}</div>
                    <h2 class="header">{{'view_send_receive_order.incoterms' | translate}}</h2>
                    <p class="align-center">{{order.incoTermsCode}}</p>
                </div>
                <div *ngIf="!!order.estimatedDeliveryDate" class="col-lg-3 col-md-6 col-12">
                    <h2 *ngIf="!!order.collectionDate" class="header">
                        {{'send_receive.send_receive.step_four.collection_date' | translate}}</h2>
                    <div *ngIf="!!order.collectionDate" style="padding-bottom: 30px">{{order.collectionDate |
                        date:'dd/MM/yyyy'}}</div>
                    <h2 class="header">{{'send_receive.send_receive.step_four.estimated_delivery_date' | translate}}
                    </h2>
                    <p>{{order.estimatedDeliveryDate | date:'dd/MM/yyyy'}}</p>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div *ngIf="!!order.parcels">
                        <h2 class="header">{{'send_receive.send_receive.step_four.size' | translate}}</h2>
                        <div style="padding-bottom: 30px">{{order.parcels.length}} x
                            {{'send_receive.send_receive.step_four.item_size_description' | translate}}</div>
                        <div *ngIf="parcelsHasDimensions()" style="padding-bottom: 16px">
                            <div *ngFor="let item of order.parcels">
                                <p>L{{item.length}}cm W{{item.width}}cm H{{item.height}}cm</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!!letterName">
                        <h2 class="header">{{'send_receive.send_receive.step_four.size' | translate}}</h2>
                        <div style="padding-bottom: 16px">
                            <p>{{letterName}}</p>
                        </div>
                    </div>
                    <h2 class="header">{{'send_receive.send_receive.step_four.weight' | translate}}</h2>
                    <div style="padding-bottom: 30px">{{order.weight}} {{getWeightMeasurement()}}</div>
                    <div>
                        <h2 class="header">Volume</h2>
                        <span>{{order.volume}}m³</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <h2 class="header">{{'send_receive.send_receive.step_four.from' | translate}}</h2>
                    <p>{{order.fromTitle}} {{order.fromFirstName}} {{order.fromLastName}}</p>
                    <p>{{order.fromAddressLine1}}</p>
                    <!-- <p>{{order.fromAddressLine2}}</p> -->
                    <p>{{order.fromTownCity}}</p>
                    <p>{{order.fromPostcode}}</p>
                    <p>{{getCountryNameByCode(order.fromCountryCode)}}</p>
                    <p>{{order.fromContactNumber}}</p>
                    <!-- <p>{{addressForm.get('fromSpecialInstructions').value}}</p> -->
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <h2 class="header">{{'send_receive.send_receive.step_four.to' | translate}}</h2>
                    <p>{{order.toTitle}} {{order.toFirstName}} {{order.toLastName}}</p>
                    <p>{{order.toAddressLine1}}</p>
                    <!-- <p>{{order.toAddressLine2}}</p> -->
                    <p>{{order.toTownCity}}</p>
                    <p>{{order.toPostcode}}</p>
                    <p>{{getCountryNameByCode(order.toCountryCode)}}</p>
                    <p>{{order.toContactNumber}}</p>
                </div>
                <!-- <div *ngIf="toDepot" class="col-lg-6">
                    <h2 class="header">To</h2>
                    Depot
                </div> -->
            </div>
        </div>
        <div class="row-container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <h2 class="header">{{'send_receive.send_receive.step_four.comments' | translate}}</h2>
                    <p>{{order.comments}}</p>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div *ngIf="!!order.customsShipmentTypeId">
                        <h2 class="header">{{'send_receive.send_receive.step_four.shipment_type' | translate}}</h2>
                        <p>{{getShipmentTypeName()}}</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <div *ngIf="!!uploadedFiles">
                                <h2>{{'send_receive.send_receive.step_four.customs_uploads' | translate}}</h2>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7">
                            <div *ngIf="!!uploadedFiles">
                                <h2>{{'send_receive.send_receive.step_four.company_name_seller_of_goods' | translate}}
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!!uploadedFiles">
                        <div *ngFor="let file of uploadedFiles">
                            <div class="row">
                                <div class="col-lg-5 col-md-5 align-center">
                                    <span>{{file.filename}}</span>
                                </div>
                                <div class="col-lg-5 col-md-5 align-center">
                                    {{file.companyName}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <h2 class="header">{{'send_receive.send_receive.step_four.domestic_package_contents' | translate}}</h2>
                    <p>{{order.domesticPackageContents}}</p>
                </div>
            </div>
        </div>
        <div class="row flex-end col-lg-12 col-md-12 col-12">
            <h1>{{'send_receive.send_receive.step_four.total' | translate}}: {{order.totalCost | currency: 'DKK':''}}
                DKK</h1>
        </div>
        <div *ngIf="!!order.vatAmount && order.vatAmount > 0" class="row flex-end col-lg-12">
            <h2>{{'send_receive.send_receive.step_four.total_vat' | translate}}: {{order.vatAmount | currency: 'DKK':''}} DKK
            </h2>
        </div>
    </div>
</ng-template>