import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Address } from '../../../account/entities/address';
import { FormGroup } from '@angular/forms';
import { AddressService } from '../../services/address-service';
import { Country } from '../../../account/entities/Country';
import { CountryService } from '../../services/country.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHelper } from '../../helpers/error-helper';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent implements OnInit {

  constructor(private newAddressService: AddressService, private countryService: CountryService, private translate: TranslateService, private errorHelper: ErrorHelper) { }

  @Input()
  addressToEdit: Address;

  @Input()
  sendReceiveForm: FormGroup

  public addToFavourite: boolean;

  countries: Array<Country>;

  setInitialCountry: boolean = false;

  @Output()
  successfullySaved: EventEmitter<boolean> = new EventEmitter();

  ngOnInit(): void {
    this.countryService.getAll().subscribe(x => {this.countries = x;
      this.countries.sort(function(x,y){ return x.countryCode == "FO" ? -1 : y.countryCode == "FO" ? 1 : 0; });
      this.countries.sort(function(x,y){ return x.countryCode == "DK" ? -1 : y.countryCode == "DK" ? 1 : 0; });
      });

    this.sendReceiveForm.get("toTitle").setValue(this.addressToEdit.title);
    this.sendReceiveForm.get("toFirstName").setValue(this.addressToEdit.firstName);
    this.sendReceiveForm.get("toLastName").setValue(this.addressToEdit.lastName);
    this.sendReceiveForm.get("toOptionalCompany").setValue(this.addressToEdit.companyName);
    this.sendReceiveForm.get("toAddressLine1").setValue(this.addressToEdit.addressLine1);
    this.sendReceiveForm.get("toAddressLine2").setValue(this.addressToEdit.addressLine2);
    this.sendReceiveForm.get("toTownCity").setValue(this.addressToEdit.townCity);
    this.sendReceiveForm.get("toPostcode").setValue(this.addressToEdit.postcode);
    this.sendReceiveForm.get("toCountry").setValue(this.addressToEdit.country);
    this.sendReceiveForm.get("toContactNumber").setValue(this.addressToEdit.contactNumber);
    this.sendReceiveForm.get("toEmail").setValue(this.addressToEdit.contactEmail);
    this.sendReceiveForm.get("toSpecialInstructions").setValue(this.addressToEdit.specialInstructions);
    this.addToFavourite = this.addressToEdit.isFavourite;

    // this.countries.push("Faroe Islands");
    // this.countries.push("Denmark");
    // this.countries.push("United Kingdom");
  }

  public hasErrors(field){
    var errors = this.sendReceiveForm.get(field).errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.sendReceiveForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public updateEntryToAddressBook()
  {
    this.sendReceiveForm.markAllAsTouched();
    if (this.sendReceiveForm.valid) {
      var formCountryValue: Country = this.sendReceiveForm.get("toCountry").value;
      const selectedCountry = this.countries.find(x => x.countryNameEN === formCountryValue.countryNameEN)
      if (!selectedCountry)
      {
        return;
      }

      const newAddress = {
        addressId: this.addressToEdit.addressId,
        title: this.sendReceiveForm.get("toTitle").value,
        firstName: this.sendReceiveForm.get("toFirstName").value,
        lastName: this.sendReceiveForm.get("toLastName").value,
        companyName: this.sendReceiveForm.get("toOptionalCompany").value,
        addressLine1: this.sendReceiveForm.get("toAddressLine1").value,
        addressLine2: this.sendReceiveForm.get("toAddressLine2").value,
        townCity: this.sendReceiveForm.get("toTownCity").value,
        postcode: this.sendReceiveForm.get("toPostcode").value,
        countryCode: selectedCountry.countryCode,
        contactNumber: this.sendReceiveForm.get("toContactNumber").value,
        contactEmail: this.sendReceiveForm.get("toEmail").value,
        specialInstructions: this.sendReceiveForm.get("toSpecialInstructions").value,
        isFavourite: this.addToFavourite
      };
  
      this.newAddressService.update(newAddress).subscribe(
        () => {
          // this.snackbar.open("Successfully saved address");
          this.successfullySaved.emit(true);
        }, (error) =>
        console.log(error));
    }
    }

    public setFavourite() {
      this.addToFavourite = !this.addToFavourite;
    }

    public deleteAddress() {
      this.newAddressService.delete(this.addressToEdit.addressId).subscribe(
        () => {
          this.successfullySaved.emit(true);
        }, (error) =>
        console.log(error));
    }

    public getCountryNameForLanguage(country: Country): string
    {
       switch (this.translate.currentLang) {
         case "en":
           return country.countryNameEN;
         case "fo":
           return country.countryNameFO;
       }
    }

    public setCountryByCode(country: Country): Country {
      var countryControl: Country = this.sendReceiveForm.get("toCountry").value;
      var countryCodeByControl = countryControl.countryCode;

      if (!this.setInitialCountry && country.countryCode == countryCodeByControl)
      {
        this.setInitialCountry = true;
        this.sendReceiveForm.get("toCountry").setValue(country);
      }
      return country;
    }
}
