<div mat-dialog-title>
    <div class="dialog-title">
        <button mat-icon-button class="close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
          </button>
    </div>
    <div class="d-flex align-center title-container">
        <img class="shopping-cart-icon" src="/assets/icons/shopping-list/shopping_cart_blue.png">
        <h2 class="shopping-list-title">{{'bera.create_list.title' | translate}}</h2>
    </div>
  </div>
<mat-dialog-content class="mat-typography">
    <div class="d-flex align-center title-container">
        <mat-form-field class="search-address-col" floatLabel="never" appearance="outline">
            <input class="text-input" matInput placeholder="{{'bera.create_list.enter_name_placeholder' | translate}}"
                type="text" name="shoppingListName" [(ngModel)]="shoppingListName" autocomplete="off"
                (keyup.enter)="createNewShoppingList()">
        </mat-form-field>
        <div class="address-menu-item">
            <button class="primary-button add-button" (click)="createNewShoppingList()">
                <img class="add-icon" src="/assets/icons/address_book/add_address@3x.png" />
            </button>
        </div>
    </div>
</mat-dialog-content>