import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-awaiting-posta-approval',
  templateUrl: './awaiting-posta-approval.component.html',
  styleUrls: ['./awaiting-posta-approval.component.scss']
})
export class AwaitingPostaApprovalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
