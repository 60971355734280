import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../account/services/authentication.service';
import { User } from '../../../account/entities/user';
import { UserService } from '../../../account/services/user.service';
import { ErrorHelper } from '../../../shared/helpers/error-helper';

@Component({
  selector: 'app-user-confirmation',
  templateUrl: './user-confirmation.component.html',
  styleUrls: ['./user-confirmation.component.scss']
})
export class PayCustomsUserConfirmationComponent implements OnInit {

  originValue: any;

  @Input()
  userConfirmationFormGroup: FormGroup;

  showAgreeToUseEmailError: boolean = false;

  showDibsPaymentScreen: boolean = false;

  user: User;

  @Output()
  proceedToDetailsAndPayment: EventEmitter<boolean> = new EventEmitter();

  constructor(private authService: AuthenticationService, private userService: UserService, private errorHelper: ErrorHelper) { }

  ngOnInit(): void {
    // if (this.authService.isLoggedIn())
    // {
    //   this.userService.getUserDetails().subscribe(x => {
    //     this.user = x;

    //     this.userConfirmationFormGroup.get("email").setValue(this.user.email);
    //     this.userConfirmationFormGroup.get("noPostaAccount").disable();
    //     this.userConfirmationFormGroup.get("hasPostaAccount").disable();
    //   })
    // }
    // this.userConfirmationFormGroup.disable();
  }

  public hasErrors(field){
    var errors = this.userConfirmationFormGroup.get(field).errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.userConfirmationFormGroup.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public isTouched(field){
    return this.userConfirmationFormGroup.get(field).touched
  }

  public postaAccountToggled(hasAccount: boolean)
  {
    const passwordInput = this.userConfirmationFormGroup.get("password");

    if (hasAccount)
    {
      passwordInput.enable();
      passwordInput.setValidators(Validators.required);
      this.userConfirmationFormGroup.get("noPostaAccount").setValidators(null);
    }
    else
    {
      this.userConfirmationFormGroup.get("hasPostaAccount").setValidators(null);
      passwordInput.reset();
      passwordInput.disable();
    }
  }

  public openDibsPaymentScreen() {
    const form = this.userConfirmationFormGroup;

    if (!form.valid)
    {
      form.markAllAsTouched();

      if (form.get("agreeToUseEmail").invalid)
      {
        this.showAgreeToUseEmailError = true;
      }
      return;
    }

    this.proceedToDetailsAndPayment.emit(true);
  }

}
