import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Address } from '../../../../account/entities/address';
import { DefaultShipmentDimensions } from '../../../entities/DefaultShipmentDimensions';
import { Order } from '../../../entities/Order';
import { TrackTraceItem } from '../../../entities/trackTraceItem';
import { environment } from '../../../../../environments/environment'
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class TrackTraceService {

    constructor(private httpClient: HttpClient, private translateService: TranslateService) {
    }

    public getTrackTraceDetailsByCode(code: string): Observable<Array<TrackTraceItem>> {
        return this.httpClient.get(`${environment.apiUrl}/api/TrackTrace?trackTraceCode=${code}&languageCode=${this.translateService.currentLang}`) as Observable<Array<TrackTraceItem>>;
    }
}
