<div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
<div class="container parent-container">
    <!-- <h1 class="find-prices-title">{{'find_prices.header_title' | translate}}</h1>
    <h2 class="grey find-prices-secondary-title">{{'find_prices.sub_header_title' | translate}}</h2> -->

    <div class="row first-section-container">
        <div class="col-lg-6 col-md-12 image-container">
            <img class="image" [src]="image" />
            <img class="image-tablet" [src]="image" />
        </div>
        <div class="col-lg-6">
            <div class="from-to-container">
                <div class="row row-padding align-center" [formGroup]="findPriceFormGroup">
                    <div class="col-lg-2 offset-lg-0 offset-md-1 col-md-1 col-3">
                        <h3 class="margin-title mob-form-label">{{'find_prices.from' | translate}}</h3>
                    </div>
                    <mat-form-field class="mobile-padding col-lg-4 col-md-4 col-9 no-error-wrap " floatLabel="never"
                        appearance="outline" [formGroup]="findPriceFormGroup">
                        <mat-select placeholder="{{'find_prices.from_placeholder' | translate}}" (selectionChange)="enableLetterOptionIfFO($event.value)"
                            disableOptionCentering panelClass="select-panel" formControlName="fromCountry" required>
                            <mat-option *ngFor="let country of countries" [value]="country">
                                {{getCountryNameForLanguage(country)}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="hasErrors('fromCountry')"><app-field-error-message errorMessage="{{getError('fromCountry')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                    <div class="col-lg-2 offset-lg-0 offset-md-0 col-md-1 col-3">
                        <h3 class="margin-title align-end-no-mob mob-form-label">{{'find_prices.to' | translate}}</h3>
                    </div>
                    <mat-form-field class="mobile-padding col-lg-4 col-md-4 col-9 no-error-wrap" floatLabel="never"
                        appearance="outline" [formGroup]="findPriceFormGroup">
                        <mat-select placeholder="{{'find_prices.to_placeholder' | translate}}" disableOptionCentering panelClass="select-panel"
                            formControlName="toCountry" required>
                            <mat-option *ngFor="let country of countries" [value]="country">
                                {{getCountryNameForLanguage(country)}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasErrors('toCountry')"><app-field-error-message errorMessage="{{getError('toCountry')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="size-container">
                <div class="row show-mobile" style="padding-bottom: 25px;">
                    <div class="col-lg-3 offset-lg-0 offset-md-1 col-md-2 align-center">
                        <h3 class="primary mob-form-label">{{'find_prices.size' | translate}}</h3>
                        <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png"/>
                        <a tabindex="0"></a>
                        <span>{{'find_prices.size_tooltip' | translate}}</span></label>
                    </div>
                </div>
                <div *ngIf="isFromFO()" class="row size-container-row">
                    <div class="col-lg-3 col-md-3 align-center">
                    </div>
                    <div class="col-lg-9 col-md-8 icon-input-container">
                        <img class="size-icons size-icons-mobile" src="/assets/icons/find-prices/Letter_blue2@3x.png" />
                        <mat-form-field class="full-width no-error-wrap float-never" floatLabel="never"
                            appearance="outline" [formGroup]="findPriceFormGroup">
                            <mat-select placeholder="{{'find_prices.letter_placeholder' | translate}}" disableOptionCentering panelClass="select-panel"
                                formControlName="letter" required (selectionChange)="setSizeValue($event)">
                                <mat-option *ngFor="let letterSize of letterSizes" [value]="letterSize">
                                    {{getLetterSizeNameForLanguage(letterSize.Name, letterSize.Description)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row size-container-row">
                    <div class="col-lg-3 offset-lg-0 offset-md-1 col-md-2 align-center hide-mobile">
                        <h3 class="primary">{{'find_prices.size' | translate}}</h3>
                        <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                            <a tabindex="0"></a><span>{{'find_prices.size_tooltip' | translate}}</span></label>
                    </div>
                    <!-- <div class="col-lg-1 col-md-1 mobile-icon">
                        <img class="size-icons-mobile" src="/assets/icons/find-prices/Parcel_blue2@3x.png" />
                    </div> -->
                    <div class="col-lg-9 offset-lg-0 offset-md-0 col-md-8 icon-input-container">
                        <img class="size-icons size-icons-mobile" src="/assets/icons/find-prices/Parcel_blue2@3x.png" />
                        <div [formGroup]="findPriceFormGroup">
                            <label>
                                <span class="radio-button-spacing">
                                    <input class="vertical-align-radio-button" type="radio" formControlName="parcel"
                                        name="parcel" (change)="setParcelType($event)">
                                </span>
                                <span class="radio-text">{{'find_prices.parcels' | translate}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row size-container-row" style="padding-top: 3px;">
                    <div class="col-lg-3 col-md-3 align-center">
                    </div>
                    <div class="col-lg-9 col-md-8 icon-input-container">
                        <img class="size-icons size-icons-mobile"
                            src="/assets/icons/find-prices/Large_Shipment_blue2@3x.png" />
                        <div [formGroup]="findPriceFormGroup">
                            <label class="shipment-label align-center">
                                <span class="radio-button-spacing">
                                    <input class="vertical-align-radio-button" type="radio"
                                        formControlName="largeShipment" name="largeShipment"
                                        (change)="setShipmentType($event)">
                                </span>
                                <span class="radio-text">{{'find_prices.large_shipment' | translate}}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="weight-container">
                <div *ngIf="isParcelSelected || isShipmentSelected || isLetterSelected" class="row">
                    <div class="col-lg-3 offset-lg-0 offset-md-1 col-md-2 col-5 align-center" style="margin-top: 14px">
                        <h3 class="primary mob-form-label">{{'find_prices.weight' | translate}}</h3>
                        <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                            <a tabindex="0"></a><span>{{'find_prices.weight_tooltip' | translate}}</span></label>
                    </div>
                    <mat-form-field class="col-lg-3 col-md-4 col-7 no-error-wrap"
                        style="width: 100%; padding-left: 0px; margin-top: 14px" floatLabel="never" appearance="outline"
                        [formGroup]="findPriceFormGroup">
                        <mat-select *ngIf="isLetterSelected" placeholder="{{'find_prices.weight_placeholder_letter' | translate}}" disableOptionCentering
                            panelClass="select-panel" formControlName="weight" required>
                            <mat-option *ngFor="let weightOption of weightBoundaries" [value]="weightOption">
                                {{weightOption.name}}</mat-option>
                        </mat-select>
                        <mat-select *ngIf="!isLetterSelected" placeholder="{{'find_prices.weight_placeholder_kg' | translate}}" disableOptionCentering
                            panelClass="select-panel" formControlName="weight" required>
                            <mat-option *ngFor="let parcelWeightOption of parcelWeightBoundaries"
                                [value]="parcelWeightOption">
                                {{parcelWeightOption.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasErrors('weight')"><app-field-error-message errorMessage="{{getError('weight')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                    
                </div>
            </div>
            <div class="button-container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-0 offset-md-1 col-md-5 col-12 hide-mobile">
                        <button class="tertiary-button find-price-button-left" mat-button (click)="clearAll()">
                            <div class="full-width">
                                <span class="button-text">{{'send_receive.send_receive.step_one.clear_all' | translate}}</span>
                                <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
                            </div>
                        </button>
                    </div>
                    <div class="col-lg-6 col-md-5 col-12 button-padding">
                        <button class="primary-button find-price-button-right" (click)="getPrices()">{{'find_prices.view_prices' | translate}}</button>
                    </div>
                    <div class="col-lg-6 offset-lg-0 offset-md-1 col-md-5 col-12 show-mobile">
                        <button class="tertiary-button find-price-button-left" mat-button (click)="clearAll()">
                            <div class="full-width">
                                <span class="button-text">{{'send_receive.send_receive.step_one.clear_all' | translate}}</span>
                                <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showPrices">
    <app-view-prices [findPriceFormGroup]="findPriceFormGroup" [isParcelSelected]="isParcelSelected"
        [isShipmentSelected]="isShipmentSelected" [toFromFO]="isFromToCountryFO()" [insuranceSelected]="true" [serviceOptions]="serviceOptions">
    </app-view-prices>
</div>

<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>


<!-- TODO Replace with static content! -->
<!-- <div class="container parent-container">
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <div class="additional-insurance-container">
                <img class="find-price-image" src="../../../../../assets/images/additional_insurance_image.png">
                <div class="image-text-container mobile-align-center">
                    <h2 class="white semi-bold mobile-16-font">Additional Insurance</h2>
                    <br>
                    <h4 class="white mobile-hide-additional-text">Available to add when you order</h4>
                    <h4 class="white mobile-hide-additional-text">From Kr. 0000000 per additional Kr. 1,000 of cover
                        <strong>Register or
                            login to place order ></strong>
                    </h4>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6">
            <div class="additional-insurance-container">
                <img class="find-price-image" src="../../../../../assets/images/customs_clearance_image.png">
                <div class="image-text-container mobile-align-center">
                    <h2 class="white semi-bold mobile-16-font">Customs clearance</h2>
                    <br>
                    <h4 class="white mobile-hide-additional-text">Fully managed service <strong>Order online ></strong>
                    </h4>
                    <br>
                    <h4 class="white mobile-hide-additional-text">Fast-Track customs – Avoid delays by
                        uploading your documents <strong>Find out more ></strong></h4>
                </div>
            </div>
        </div>

        <div class="col-md-6 mobile-additional-text">
            <h4 class="">Available to add when you order</h4>
            <h4 class="">From Kr. 0000000 per additional Kr. 1,000 of cover
                <a routerLink="../login" class="ahref-link">Register or login to place order ></a>
            </h4>
        </div>

        <div class="col-md-6 mobile-additional-text">
            <h4 class="">Fully managed service <a routerLink="../new-order" class="ahref-link">Order online ></a></h4>
            <br>
            <h4 class="">Fast-Track customs – Avoid delays by uploading your documents <a routerLink="../new-order"
                    class="ahref-link">Find out more ></a></h4>
        </div>
    </div>
</div> -->