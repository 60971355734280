import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { PostageOrder } from "../entities/postageOrder";
import { BuyPostageService } from "./buy-postage.service";

@Injectable({ providedIn: 'root' })
export class BuyPostageOrderResolver implements Resolve<PostageOrder> {

    constructor (private buyPostageService: BuyPostageService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>| Promise<any>|any {
        return this.buyPostageService.getOrder(route.paramMap.get('orderId'));
    }
}