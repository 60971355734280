<div class="container">
    <div *ngIf="!showPaymentScreen" class="row first-section-container">
        <div class="col-lg-6 col-md-12 image-container">
            <img *ngIf="!!image" class="image" [src]="image" />
            <img *ngIf="!!image" class="image-tablet" [src]="image" />
        </div>
        <div class="col-lg-6 col-12 parent-input-container">
            <div class="input-container">
                <div class="row row-padding align-center">
                    <div class="col-lg-6 col-md-6 col-12">
                        <h3 class="input-title">{{numberInputFieldName}}</h3>
                        <mat-form-field class="align-form-field full-width-form-field" [formGroup]="customsFormGroup"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{inputFieldNamePlaceholder}}" id="invoicePoNumber"
                                name="invoicePoNumber" formControlName="invoicePoNumber" required>
                                <mat-error *ngIf="hasErrors('invoicePoNumber')"><app-field-error-message errorMessage="{{getError('invoicePoNumber')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12 mobile-padding">
                        <h3 class="input-title align-end">{{'pay_customs.reference_code' | translate}}</h3>
                        <mat-form-field class="align-form-field full-width-form-field" floatLabel="never"
                            [formGroup]="customsFormGroup" appearance="outline">
                            <input matInput placeholder="{{'pay_customs.enter_reference_code' | translate}}" id="refCode" name="refCode"
                                formControlName="refCode" required>
                                <mat-error *ngIf="hasErrors('refCode')"><app-field-error-message errorMessage="{{getError('refCode')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 offset-md-6 col-12 submit-col">
                        <button class="primary-button full-width submit-button" [disabled]="loading"
                            (click)="getCost()">
                            <div class="spinner">
                                <span [ngClass]="loading? 'margin-left' : ''">{{'pay_customs.submit' | translate}}</span>
                                <mat-spinner *ngIf="loading" style="margin-left: 15px;" diameter="15"></mat-spinner>
                            </div>
                        </button>
                    </div>
                    <div class="col-lg-12 col-12 warning-text">
                        {{'pay_customs.warning' | translate}}
                        <mat-error *ngIf="failedToGetAmount" id="no-record-found" style="padding-top: 20px">No record
                            found please check the input details are correct</mat-error>
                    </div>
                    <div *ngIf="customsRetrieved" class="col-lg-12 col-12 invoice-column">
                        <div class="row invoice-cost">
                            <h2 *ngIf="invoiceCustom.outstandingAmount > 0" >{{'pay_customs.total' | translate}}: {{invoiceCustom.outstandingAmount | currency: 'DKK':''}} DKK</h2>
                            <h2 *ngIf="invoiceCustom.outstandingAmount == 0 && serviceTypeId == 7 && !invoiceCustom.isPaidButUpdateFailed" style="padding-right: 15px;">{{'pay_customs.customs_already_paid' | translate}}</h2>
                            <h2 *ngIf="invoiceCustom.outstandingAmount == 0 && serviceTypeId == 8 && !invoiceCustom.isPaidButUpdateFailed" style="padding-right: 15px;">{{'pay_customs.invoice_already_paid' | translate}}</h2>
                            <h2 *ngIf="invoiceCustom.isPaidButUpdateFailed" style="padding-right: 15px;">{{'pay_customs.please_contact_posta' | translate}}</h2>
                            <div class="col-md-6 col-12">
                                <button *ngIf="invoiceCustom.outstandingAmount > 0 && !invoiceCustom.isPaidButUpdateFailed" class="primary-button full-width" [disabled]="loadingProceedToPayment"
                                    (click)="proceedToPayment()">
                                    <div class="spinner">
                                        <span [ngClass]="loadingProceedToPayment? 'margin-left' : ''">{{'pay_customs.proceed_to_payment' | translate}}</span>
                                        <mat-spinner *ngIf="loadingProceedToPayment" style="margin-left: 15px;" diameter="15">
                                        </mat-spinner>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <mat-horizontal-stepper *ngIf="showPaymentScreen" class="container" [linear]="true" #stepper>
        <mat-step [optional]="false" [stepControl]="userConfirmationFormGroup" #stepper>
            <form [formGroup]="userConfirmationFormGroup">
                <ng-template matStepLabel><span class="lg-view">1. {{'pay_customs.step_one_user_confirmation_page.stepper_title' | translate}}</span><span class="sm-view">1</span></ng-template>
                <app-user-confirmation [userConfirmationFormGroup]="userConfirmationFormGroup"
                    (proceedToDetailsAndPayment)="openDetailsAndPaymentStep()"></app-user-confirmation>
            </form>
        </mat-step>
        <mat-step [optional]="false" [stepControl]="paymentFormGroup" #stepper>
            <ng-template matStepLabel><span class="lg-view">2. {{'pay_customs.step_two.stepper_title' | translate}}</span><span class="sm-view">2</span></ng-template>
            <app-details-and-payment [invoiceCustomFieldName]="numberInputFieldName"
                [customsFormGroup]="customsFormGroup" [userConfirmationFormGroup]="userConfirmationFormGroup"
                [invoiceCustom]="invoiceCustom" (transactionRefOutput)="orderPaidForEvent($event)">
            </app-details-and-payment>
        </mat-step>
        <mat-step [optional]="false" #stepper>
            <ng-template matStepLabel><span class="lg-view">3. {{'pay_customs.step_three.stepper_title' | translate}}</span><span class="sm-view">3</span></ng-template>
            <app-customs-confirmation [transactionRef]="transactionRef" [serviceTypeId]="serviceTypeId" [customsFormGroup]="customsFormGroup"></app-customs-confirmation>
        </mat-step>
    </mat-horizontal-stepper>
</div>