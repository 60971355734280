<div class="company-info-container">
    <h3 class="primary company-info-title">{{'business_register.company_information' | translate}}</h3>
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="row">
                <div class="col-lg-12 customer-type-row align-center">
                    <h4 class="dark-blue">{{'business_register.customer_type' | translate}}</h4>
                    <label class="tooltipSource"><img class="information-icon"
                            src="/assets/icons/i_information@3x.png" />
                            <a tabindex="0"></a><span>{{'business_register.customer_type_tooltip' | translate}}</span></label>
                </div>
            </div>
            <div class="row new-existing-row">
                <div class="bullet-checkbox-container radio-button-no-button-padding new-company-checkbox">
                    <label class="align-center">
                        <span class="span-radio-align">
                            <input class="vertical-align-radio-button" type="radio" name="yes-no"
                                (click)="existingSelected=false;newSelected=true;enableCustomerAccountNumberFied(false)" [checked]="newSelected">
                        </span>
                        <h4 class="radio-text">{{'business_register.new_customer' | translate}}</h4>
                    </label>
                </div>
                <div class="bullet-checkbox-container existing-company-checkbox align-center">
                    <label class="align-center">
                        <span class="span-radio-align">
                            <input class="vertical-align-radio-button" type="radio" name="yes-no"
                                (click)="newSelected=false;existingSelected=true;enableCustomerAccountNumberFied(true)" [checked]="existingSelected">
                        </span>
                        <h4 class="radio-text">{{'business_register.existing_customer' | translate}}</h4>
                    </label>
                    <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                        <a tabindex="0"></a><span>{{'business_register.existing_customer_tooltip' | translate}}</span></label>
                </div>
                
            </div>
        </div>
        <div *ngIf="existingSelected" class="col-md-6 col-12">
            <h4 class="dark-blue title-mat-form-field">{{'business_register.customer_account_number' | translate}}</h4>
            <div class="row">
                <mat-form-field class="col-md-6 col-12 align-form-field" [formGroup]="businessFormGroup" floatLabel="never"
                    appearance="outline">
                    <input matInput placeholder="{{'business_register.enter_account_number' | translate}}" id="accountNumber" name="accountNumber"
                        formControlName="accountNumber" required>
                        <mat-error *ngIf="hasErrors('accountNumber')"><app-field-error-message errorMessage="{{getError('accountNumber')}}"></app-field-error-message></mat-error>
                </mat-form-field>
                <!-- <div class="my-custom-input-label-box align-center dont-know-account-number col-md-6 col-12">
                    <div class="align-center">
                        <input id="dont-know-account-number" (click)="toggleAccountNumber()" type="checkbox" name="dont-know-account-number" />
                        <label for="dont-know-account-number"></label>
                        <h4 class="checkbox-text">Don't know</h4>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div *ngIf="!existingSelected" class="secondary-container">
    <div class="row">
        <div class="col-md-6 col-12">
            <h4 class="dark-blue title-mat-form-field">{{'business_register.company_name' | translate}}</h4>
            <div class="row">
                <mat-form-field class="col-md-12 align-form-field" style="padding-right: 10px;"
                    [formGroup]="businessFormGroup" floatLabel="never" appearance="outline">
                    <input matInput placeholder="{{'business_register.enter_company_name' | translate}}" id="companyName" name="companyName" autocomplete="organization"
                        formControlName="companyName" required>
                        <mat-error *ngIf="hasErrors('companyName')"><app-field-error-message errorMessage="{{getError('companyName')}}"></app-field-error-message></mat-error>
                </mat-form-field>
            </div>
        </div>
        <!-- <div class="col-md-6" style="padding-left: 10px;">
            <div class="row"> -->
                <div class="company-tel-no phone-field-top-padding col-lg-3 col-md-6 col-12">
                    <h4 class="dark-blue title-mat-form-field">{{'business_register.company_tel_no' | translate}}</h4>
                    <mat-form-field class="country-code-form-field align-form-field" style="padding-right: 10px;"
                        [formGroup]="businessFormGroup" floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{'business_register.company_tel_country_code_placeholder' | translate}}" id="countryCode" name="countryCode" autocomplete="work tel-country-code"
                            formControlName="countryCode" required>
                            <mat-error *ngIf="hasErrors('countryCode')"><app-field-error-message></app-field-error-message></mat-error>
                    </mat-form-field>
                    <mat-form-field class="telephone-form-field align-form-field" [formGroup]="businessFormGroup"
                        floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{'business_register.company_tel_num_placeholder' | translate}}" id="telephoneNum" name="telephoneNum" autocomplete="work tel-national"
                            formControlName="telephoneNum" required>
                            <mat-error *ngIf="hasErrors('telephoneNum')"><app-field-error-message errorMessage="{{getError('telephoneNum')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                </div>
                <div class="tablet-field-top-padding company-postcode col-lg-3 col-md-6 col-12">
                    <h4 class="dark-blue title-mat-form-field">{{'business_register.company_postcode' | translate}}</h4>
                    <mat-form-field class="postcode-dropdown" floatLabel="never" appearance="outline"
                        [formGroup]="businessFormGroup">
                        <mat-select placeholder="{{'business_register.company_postcode_placeholder' | translate}}" formControlName="postcode" autocomplete="postal-code" required>
                            <mat-option *ngFor="let postcode of postcodes" [value]="postcode">{{postcode.postcode}}, {{postcode.city}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasErrors('postcode')"><app-field-error-message errorMessage="{{getError('postcode')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                </div>
            <!-- </div> -->
        <!-- </div> -->
        <div class="field-top-padding col-md-6 col-12">
            <h4 class="dark-blue title-mat-form-field">{{'business_register.vtal_number' | translate}}</h4>
            <div class="row">
                <mat-form-field class="col-md-12 align-form-field" style="padding-right: 10px;"
                    [formGroup]="businessFormGroup" floatLabel="never" appearance="outline">
                    <input matInput placeholder="{{'business_register.vtal_number_placeholder' | translate}}" id="vtalNumber" name="vtalNumber"
                        formControlName="vtalNumber" required>
                        <mat-error *ngIf="hasErrors('vtalNumber')"><app-field-error-message errorMessage="{{getError('vtalNumber')}}"></app-field-error-message></mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="company-email field-top-padding col-md-6 col-12">
            <h4 class="dark-blue title-mat-form-field">{{'business_register.company_email' | translate}}</h4>
            <div class="row">
                <mat-form-field class="col-md-12 align-form-field" [formGroup]="businessFormGroup" floatLabel="never"
                    appearance="outline">
                    <input matInput placeholder="{{'business_register.company_email_placeholder' | translate}}" id="companyEmail" name="companyEmail" autocomplete="work email"
                        formControlName="companyEmail" required>
                        <mat-error *ngIf="hasErrors('companyEmail')"><app-field-error-message errorMessage="{{getError('companyEmail')}}"></app-field-error-message></mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- <div class="row" style="padding-top: 30px;"> -->
        
    <!-- </div> -->
</div>

<div *ngIf="!existingSelected" class="secondary-container">
    <h3 class="primary company-info-title">{{'business_register.invoice_address' | translate}}</h3>
    <div class="row">
        <div class="col-12" style="padding-right: 10px;">
            <div class="row">
                <mat-form-field class="col-lg-6 col-md-8 col-12 align-form-field address-field" [formGroup]="businessFormGroup" floatLabel="never"
                    appearance="outline">
                    <input matInput placeholder="{{'business_register.address_line1_placeholder' | translate}}" id="addressLine1" name="addressLine1" autocomplete="address-line1"
                        formControlName="addressLine1" required>
                        <mat-error class="show-mobile" *ngIf="hasErrors('addressLine1')"><app-field-error-message errorMessage="{{getError('addressLine1')}}"></app-field-error-message></mat-error>
                </mat-form-field>
                <app-field-error-message *ngIf="hasErrors('addressLine1') && isTouched('addressLine1')" class="col-lg-6 col-md-4 address-error" errorMessage="{{getError('addressLine1')}}"></app-field-error-message>
            </div>
            <div class="row form-field-padding">
                <mat-form-field class="col-lg-6 col-md-8 col-12 align-form-field address-field" [formGroup]="businessFormGroup" floatLabel="never"
                    appearance="outline">
                    <input matInput placeholder="{{'business_register.address_line2_placeholder' | translate}}" id="addressLine2" name="addressLine2" autocomplete="address-line2"
                        formControlName="addressLine2">
                </mat-form-field>
            </div>
            <div class="row form-field-padding">
                <mat-form-field class="col-lg-6 col-md-8 col-12 align-form-field address-field" [formGroup]="businessFormGroup" floatLabel="never"
                    appearance="outline">
                    <input matInput placeholder="{{'business_register.town_city_placeholder' | translate}}" id="townCity" name="townCity" formControlName="townCity" autocomplete="address-level2"
                        required>
                        <mat-error class="show-mobile" *ngIf="hasErrors('townCity')"><app-field-error-message errorMessage="{{getError('townCity')}}"></app-field-error-message></mat-error>
                </mat-form-field>
                <app-field-error-message *ngIf="hasErrors('townCity') && isTouched('townCity')" class="col-lg-6 col-md-4 address-error" errorMessage="{{getError('townCity')}}"></app-field-error-message>
            </div>
            <div class="row form-field-padding">
                <mat-form-field class="col-lg-6 col-md-8 col-12 align-form-field address-field" [formGroup]="businessFormGroup" floatLabel="never"
                    appearance="outline">
                    <input matInput placeholder="{{'business_register.postcode_placeholder' | translate}}" id="invoicePostcode" name="invoicePostcode" autocomplete="postal-code"
                        formControlName="invoicePostcode" required>
                        <mat-error class="show-mobile" *ngIf="hasErrors('invoicePostcode')"><app-field-error-message errorMessage="{{getError('invoicePostcode')}}"></app-field-error-message></mat-error>
                </mat-form-field>
                <app-field-error-message *ngIf="hasErrors('invoicePostcode') && isTouched('invoicePostcode')" class="col-lg-6 col-md-4 address-error" errorMessage="{{getError('invoicePostcode')}}"></app-field-error-message>
            </div>
            <div class="row form-field-padding">
                <mat-form-field class="col-lg-6 col-md-8 col-12 address-field" floatLabel="never" appearance="outline"
                    [formGroup]="businessFormGroup">
                    <mat-select placeholder="{{'business_register.country_placeholder' | translate}}" formControlName="country" required>
                        <mat-option *ngFor="let country of countries" [value]="country">{{getCountryNameForLanguage(country)}}</mat-option>
                    </mat-select>
                    <mat-error class="show-mobile" *ngIf="hasErrors('country')"><app-field-error-message errorMessage="{{getError('country')}}"></app-field-error-message></mat-error>
                </mat-form-field>
                <app-field-error-message *ngIf="hasErrors('country') && isTouched('country')" class="col-lg-6 col-md-4 address-error" errorMessage="{{getError('country')}}"></app-field-error-message>
            </div>
            <div class="row form-field-padding">
                <mat-form-field [formGroup]="businessFormGroup" floatLabel="never"
                    class="col-lg-6 col-md-8 col-12 align-form-field special-instructions-field">
                    <textarea class="text-area" rows="8" matInput
                        placeholder="{{'business_register.special_instructions_placeholder' | translate}}"
                        id="specialInstructions" name="specialInstructions"
                        formControlName="specialInstructions"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<div class="secondary-container">
    <h3 class="primary company-info-title">{{'business_register.your_details_title' | translate}}</h3>
    <div class="row">
        <div class="col-lg-6 col-12">
            <div class="row">
                <div class="first-name-field col-md-6 col-12">
                    <h4 class="dark-blue title-mat-form-field">{{'business_register.first_name' | translate}}</h4>
                    <mat-form-field class="name-input-field align-form-field"
                    [formGroup]="businessFormGroup" floatLabel="never" appearance="outline">
                    <input matInput placeholder="{{'business_register.first_name_placeholder' | translate}}" id="firstName" name="firstName" autocomplete="given-name"
                        formControlName="firstName" required>
                        <mat-error *ngIf="hasErrors('firstName')"><app-field-error-message errorMessage="{{getError('firstName')}}"></app-field-error-message></mat-error>
                </mat-form-field>
                </div>
                <div class="phone-field-top-padding last-name-field col-md-6 col-12">
                    <h4 class="dark-blue title-mat-form-field">{{'business_register.last_name' | translate}}</h4>
                    <mat-form-field class="name-input-field align-form-field"
                    [formGroup]="businessFormGroup" floatLabel="never" appearance="outline">
                    <input matInput placeholder="{{'business_register.last_name_placeholder' | translate}}" id="lastName" name="lastName" autocomplete="family-name"
                        formControlName="lastName" required>
                        <mat-error *ngIf="hasErrors('lastName')"><app-field-error-message errorMessage="{{getError('lastName')}}"></app-field-error-message></mat-error>
                </mat-form-field>
                </div>
            </div>
        </div>
        <div class="tablet-field-top-padding job-title-field col-md-6 col-12">
            <h4 class="dark-blue title-mat-form-field">{{'business_register.job_title' | translate}}</h4>
            <mat-form-field class="name-input-field align-form-field" [formGroup]="businessFormGroup" floatLabel="never"
                    appearance="outline">
                    <input matInput placeholder="{{'business_register.job_title_placeholder' | translate}}" id="jobTitle" name="jobTitle" autocomplete="organization-title"
                        formControlName="jobTitle" required>
                        <mat-error *ngIf="hasErrors('jobTitle')"><app-field-error-message errorMessage="{{getError('jobTitle')}}"></app-field-error-message></mat-error>
                </mat-form-field>
        </div>
    <!-- </div> -->

    <!-- <div class="row" style="padding-top: 30px;"> -->
        <div class="col-md-6 col-12 user-email-field field-top-padding">
            <div class="align-center">
                <h4 class="dark-blue title-mat-form-field">{{'business_register.user_email' | translate}}</h4>
                <div *ngIf="!existingSelected" class="my-custom-input-label-box as-above-checkbox align-center title-mat-form-field">
                    <div class="align-center" [formGroup]="businessFormGroup">
                        <input id="as-above" type="checkbox" name="as-above" formControlName="asAbove" />
                        <label for="as-above"></label>
                        <h4 class="checkbox-text">{{'business_register.user_email_as_above' | translate}}</h4>
                    </div>
                </div>
            </div>
            <mat-form-field class="align-form-field full-width" [formGroup]="businessFormGroup" floatLabel="never"
                    appearance="outline">
                    <input matInput placeholder="{{'business_register.user_email_placeholder' | translate}}" id="emailAddress" name="emailAddress" autocomplete="email"
                        formControlName="emailAddress" required>
                        <mat-error *ngIf="hasErrors('emailAddress')"><app-field-error-message errorMessage="{{getError('emailAddress')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 col-12 user-mobile-field field-top-padding">
            <h4 class="dark-blue title-mat-form-field">{{'business_register.user_mobile_phone' | translate}}</h4>
            <mat-form-field class="country-code-form-field align-form-field" style="margin-right: 10px;"
                [formGroup]="businessFormGroup" floatLabel="never" appearance="outline">
                <input matInput placeholder="{{'business_register.user_mobile_country_code_placeholder' | translate}}" id="mobileCountryCode" name="mobileCountryCode" formControlName="mobileCountryCode" autocomplete="tel-country-code"
                    required>
                    <mat-error *ngIf="hasErrors('mobileCountryCode')"><app-field-error-message></app-field-error-message></mat-error>
            </mat-form-field>
            <mat-form-field class="mobile-number-form-field align-form-field" [formGroup]="businessFormGroup"
                floatLabel="never" appearance="outline">
                <input matInput placeholder="{{'business_register.user_mobile_number_placeholder' | translate}}" id="mobileNum" name="mobileNum" autocomplete="tel-national"
                    formControlName="mobileNum" required>
                    <mat-error *ngIf="hasErrors('mobileNum')"><app-field-error-message errorMessage="{{getError('mobileNum')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>
    </div>
</div>

<div class="secondary-container">
    <div class="align-center">
        <h3 class="primary company-info-title">{{'business_register.create_password_title' | translate}}</h3>
        <label class="tooltipSource tooltip-title"><img class="information-icon"
                src="/assets/icons/i_information@3x.png" /><span>{{'business_register.password_tooltip' | translate}}</span></label>
    </div>
    <div class="row">
        <div class="col-md-6 col-12">
            <h4 class="dark-blue title-mat-form-field">{{'business_register.specify_password' | translate}}</h4>
            <div class="row">
                <mat-form-field class="col-lg-12 align-form-field" style="padding-right: 10px;"
                    [formGroup]="businessFormGroup" floatLabel="never" appearance="outline">
                    <input type="password" matInput placeholder="{{'business_register.specify_password_placeholder' | translate}}" id="specifyPassword" name="specifyPassword" autocomplete="new-password"
                        formControlName="specifyPassword" required>
                        <mat-error *ngIf="hasErrors('specifyPassword')"><app-field-error-message errorMessage="{{getError('specifyPassword')}}"></app-field-error-message></mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-6 col-12 phone-field-top-padding" style="padding-left: 10px">
            <h4 class="dark-blue title-mat-form-field">{{'business_register.confirm_password' | translate}}</h4>
            <div class="row">
                <mat-form-field class="col-lg-12 align-form-field" [formGroup]="businessFormGroup" floatLabel="never"
                    appearance="outline">
                    <input type="password" matInput placeholder="{{'business_register.confirm_password_placeholder' | translate}}" id="confirmPassword" name="confirmPassword" autocomplete="off"
                        formControlName="confirmPassword" required>
                        <mat-error *ngIf="hasErrors('confirmPassword')"><app-field-error-message errorMessage="{{getError('confirmPassword')}}"></app-field-error-message></mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<div class="secondary-container">
    <h3 class="primary company-info-title">{{'business_register.notification_preferences' | translate}}</h3>
    <div class="row">
        <div class="col-12 my-custom-input-label-box align-center">
            <div class="align-center">
                <input id="email-checkbox" (click)="preferEmail=!preferEmail" type="checkbox" name="email-checkbox" />
                <label for="email-checkbox"></label>
                <h4 class="checkbox-text">{{'business_register.notication_email' | translate}}</h4>
            </div>
        </div>
        <div class="col-12 my-custom-input-label-box align-center" style="padding-top: 30px;">
            <div class="align-center">
                <input id="sms-checkbox" (click)="preferSMS=!preferSMS" type="checkbox" name="sms-checkbox" />
                <label for="sms-checkbox"></label>
                <h4 class="checkbox-text">{{'business_register.notification_sms' | translate}}</h4>
            </div>
        </div>
    </div>
</div>
<app-error-message *ngIf="showFormError" errorMessage="{{'business_register.account_creation_warning' | translate}}">
</app-error-message>
<app-error-message *ngIf="failedToCreate" errorMessage="{{'business_register.failed_to_create_user' | translate}}">
</app-error-message>

<div class="row clear-all-proceed-row">
    <div class="col-12 mob-proceed-button">
        <button mat-button class="primary-button full-width" (click)="proceed()">{{'business_register.proceed' | translate}}</button>
    </div>
    <div class="col-lg-3 col-md-4 col-12 clear-button">
        <button class="tertiary-button full-width" (click)="clearAll()" mat-button>
            <div class="full-width">
                <span class="clear-all-icon-text">{{'business_register.clear_all' | translate}}</span>
                <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
            </div>
        </button>
    </div>
    <div class="col-lg-3 offset-lg-6 col-md-4 offset-md-4 col-12 proceed-button">
        <button mat-button class="primary-button full-width" (click)="proceed()" [disabled]="saving">
            <div class="spinner">
                {{'business_register.proceed' | translate}}
                <mat-spinner *ngIf="saving" class="mat-spin" diameter="15" style="margin-left: 10px;">
                </mat-spinner>
            </div>
        </button>
    </div>
</div>