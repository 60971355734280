import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from '../../shared/services/country.service';
import { Country } from '../entities/Country';
import { ErrorHelper } from '../../shared/helpers/error-helper';
import { UserService } from '../services/user.service';
import { CompanyAndUser } from '../entities/companyAndUser';
import { CompanyUser } from '../entities/companyUser';
import { User } from '../entities/user';
import { environment } from '../../../environments/environment';
import { TranslationHelper } from '../../shared/helpers/translation-helper';
import { Title } from '@angular/platform-browser';
import { StaticContentService } from '../../shared/services/static-content.service';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {

  samleikinMigrateUrl: string = `/migrate/samleikin`

  detailsForm: FormGroup;

  countries: Array<Country> = new Array<Country>();

  success: Boolean = false;

  userProfile: any;

  isPrivateUser: boolean = false;

  role: string;

  isSamleikin: boolean = false;

  saving: boolean = false;
  failed: boolean = false;
  interval: NodeJS.Timeout;

  constructor(private router: Router, private route: ActivatedRoute,
    private _formBuilder: FormBuilder, private translateService: TranslateService,
    private countryService: CountryService, private errorHelper: ErrorHelper, private userService: UserService, private translateHelper: TranslationHelper,
    private titleService: Title, private staticContentService: StaticContentService, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {

    if (!!localStorage.getItem("isSamleikin"))
    {
      this.isSamleikin = true;
    }

    this.userProfile = this.route.snapshot.data.userProfile.x;
    if (!this.userProfile && !this.userProfile.companyUser)
    {
      console.log("No user found, not loading anything");
    }

    this.role = this.route.snapshot.paramMap.get('role');
    if (!!this.role)
    {
      if (this.role == "business")
      {
        this.isPrivateUser = false;
        this.detailsForm = this._formBuilder.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          mobileCountryCode: ['', [Validators.required, ErrorHelper.countryCodeValidator]],
          mobileNum: ['', [Validators.required, ErrorHelper.integerOnlyValidator]],
          instructions: [''],
          registerForSMS: [false],
          registerForEmail: [false],
          email: [{value:`${this.formatFieldValue(this.userProfile.companyUser.email)}`, disabled: true}],
          company_name: [{value:`${this.formatFieldValue(this.userProfile.company.companyName)}`, disabled: true}],
          company_vtal: [{value:`${this.formatFieldValue(this.userProfile.company.vtal)}`, disabled: true}],
          company_postcode: [{value:`${this.formatFieldValue(this.userProfile.company.postcode)}`, disabled: true}],
          company_email: [{value:`${this.formatFieldValue(this.userProfile.company.email)}`, disabled: true}],
          company_address1: [{value:`${this.formatFieldValue(this.userProfile.company.addressLine1)}`, disabled: true}],
          company_address2: [{value:`${this.formatFieldValue(this.userProfile.company.addressLine2)}`, disabled: true}],
          company_town_city: [{value:`${this.formatFieldValue(this.userProfile.company.townCity)}`, disabled: true}],
          company_country: [{value:`${this.formatFieldValue(this.getCountryNameForLanguage(this.setCountryByCode(this.userProfile.company.countryCode)))}`, disabled: true}],
          company_phone_country_code: [{value:`${this.formatFieldValue(this.userProfile.company.phoneCountryCode)}`, disabled: true}, [Validators.required, ErrorHelper.countryCodeValidator]],
          company_phone_number: [{value:`${this.formatFieldValue(this.userProfile.company.phoneNo)}`, disabled: true}, [Validators.required, ErrorHelper.integerOnlyValidator]],
        });
        this.detailsForm.get(`company_phone_country_code`).valueChanges.subscribe(newValue => {
          this.translateHelper.prependPlus(this.detailsForm.get('company_phone_country_code'));
        });
        this.detailsForm.get(`mobileCountryCode`).valueChanges.subscribe(newValue => {
          this.translateHelper.prependPlus(this.detailsForm.get('mobileCountryCode'));
        });

        this.detailsForm.get('firstName').setValue(this.userProfile.companyUser.firstName);
        this.detailsForm.get('lastName').setValue(this.userProfile.companyUser.lastName);
        this.detailsForm.get('mobileCountryCode').setValue(this.userProfile.companyUser.mobileCountryCode);
        this.detailsForm.get('mobileNum').setValue(this.userProfile.companyUser.mobileNo);
        this.detailsForm.get('instructions').setValue(this.userProfile.companyUser.specialInstructions);
        this.detailsForm.get('instructions').setValue(this.userProfile.companyUser.specialInstructions);
        this.detailsForm.get('registerForSMS').setValue(this.userProfile.companyUser.preferSMS);
        this.detailsForm.get('registerForEmail').setValue(this.userProfile.companyUser.preferEmail);

        // this.detailsForm.get('lastName').setValue(this.userProfile.companyUser.lastName);
      }

      if (this.role =="private")
      {
        this.isPrivateUser = true;
        this.detailsForm = this._formBuilder.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          addressLine1: ['', Validators.required],
          addressLine2: [''],
          townCity: ['', Validators.required],
          postcode: ['', Validators.required],
          country: ['', Validators.required],
          // phone: [''],
          mobileCountryCode: ['', [Validators.required, ErrorHelper.countryCodeValidator]],
          mobileNum: ['', [Validators.required, ErrorHelper.integerOnlyValidator]],
          instructions: [''],
          registerForSMS: [false],
          registerForEmail: [false],
          email: ['', Validators.required],
        });
        this.detailsForm.get(`mobileCountryCode`).valueChanges.subscribe(newValue => {
          this.translateHelper.prependPlus(this.detailsForm.get('mobileCountryCode'));
        });

        this.detailsForm.get('email').setValue(this.userProfile.email);
        if (!!this.userProfile.email)
        {
          this.detailsForm.get("email").disable();
        }

        this.detailsForm.get('firstName').setValue(this.userProfile.firstName);
        this.detailsForm.get('lastName').setValue(this.userProfile.lastName);
        this.detailsForm.get('addressLine1').setValue(this.userProfile.addressLine1);
        this.detailsForm.get('addressLine2').setValue(this.userProfile.addressLine2);
        this.detailsForm.get('townCity').setValue(this.userProfile.townCity);
        this.detailsForm.get('postcode').setValue(this.userProfile.postcode);
        this.detailsForm.get('mobileCountryCode').setValue(this.userProfile.mobileCountryCode);
        this.detailsForm.get('mobileNum').setValue(this.userProfile.mobileNo);
        this.detailsForm.get('instructions').setValue(this.userProfile.specialInstructions);
        this.detailsForm.get('registerForSMS').setValue(this.userProfile.preferSMS);
        this.detailsForm.get('registerForEmail').setValue(this.userProfile.preferEmail);
      }

      this.countryService.getAll().subscribe(x => {this.countries = x;
        this.countries.sort(function(x,y){ return x.countryCode == "FO" ? -1 : y.countryCode == "FO" ? 1 : 0; });
        this.countries.sort(function(x,y){ return x.countryCode == "DK" ? -1 : y.countryCode == "DK" ? 1 : 0; });
        if (!!this.detailsForm.get('country'))
        {
          this.detailsForm.get('country').setValue(this.setCountryByCode(this.userProfile.countryCode));
        }
        
        if (!!this.detailsForm.get('company_country'))
        {
          var country = this.setCountryByCode(this.userProfile.company.countryCode);
          this.detailsForm.get('company_country').setValue(this.getCountryNameForLanguage(country));
        }
      });
    }

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        var tabTitle = this.staticContentService.getPageTabTitle();
        this.titleService.setTitle(tabTitle);

        this.translateService.onLangChange.subscribe((x) => {
          var tabTitle = this.staticContentService.getPageTabTitle();
          this.titleService.setTitle(tabTitle);
        })
        this.ref.detectChanges();
      }
    }, 500);


  }

  private formatFieldValue(field: any) {
    if (!!field)
    {
      return field;
    }
    return '';
  }

  public isBusinessUser() {
    if (!!localStorage.getItem("loginUserType") && localStorage.getItem("loginUserType") == "business")
    {
      return true;
    }
    return false;
  }

  public profileComplete() {
    var userDetails = localStorage.getItem("userDetails");
    var userDetailsJson = JSON.parse(userDetails);
    if (!!userDetailsJson && userDetailsJson.isProfileComplete)
    {
      return true;
    }
    return false;
  }

  public hasErrors(field){
    var errors = this.detailsForm.get(field).errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.detailsForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public getCountryNameForLanguage(country: Country): string
  {
    if (!country)
    {
      return;
    }

     switch (this.translateService.currentLang) {
       case "en":
         return country.countryNameEN;
       case "fo":
         return country.countryNameFO;
     }
  }

  public setCountryByCode(countryCode: string): Country {
    return this.countries.find(x => x.countryCode == countryCode);
  }

  public save()
  {
    if (!this.detailsForm.valid)
    {
      this.detailsForm.markAllAsTouched();
      return;
    }

    this.saving = true;

    var profileWillBeComplete: boolean = false;

    if (this.role =="business")
    {
      var companyUser: CompanyUser = {
        firstName: this.detailsForm.get('firstName').value,
        lastName: this.detailsForm.get('lastName').value,
        mobileCountryCode: this.detailsForm.get('mobileCountryCode').value.substring(1),
        mobileNo: this.detailsForm.get('mobileNum').value,
        specialInstructions: this.detailsForm.get('instructions').value,
        languageCode: this.translateService.currentLang,
        preferSms: this.detailsForm.get('registerForSMS').value,
        preferEmail: this.detailsForm.get('registerForEmail').value,
      }
  
      var companyAndUser: CompanyAndUser = {
        company: this.userProfile.company,
        companyUser: companyUser
      }
  
      this.userService.updateBusinessProfile(companyAndUser).subscribe((x) => {
        this.failed = false;
        this.success = true;
        this.saving = false;
          var userDetails = localStorage.getItem("userDetails");
          var userDetailsJson = JSON.parse(userDetails);
          userDetailsJson.isProfileComplete = true;
          localStorage.setItem('userDetails', JSON.stringify(userDetailsJson));
      }, (error) => {
        this.saving = false;
        this.failed = true;
      });
    }

    if (this.role == "private")
    {
      var privateUser: User = {
        firstName: this.detailsForm.get('firstName').value,
        lastName: this.detailsForm.get('lastName').value,
        addressLine1: this.detailsForm.get('addressLine1').value,
        addressLine2: this.detailsForm.get('addressLine2').value,
        townCity: this.detailsForm.get('townCity').value,
        postcode: this.detailsForm.get('postcode').value,
        countryCode: this.detailsForm.get('country').value.countryCode,
        mobileCountryCode: this.detailsForm.get('mobileCountryCode').value.substring(1),
        mobileNo: this.detailsForm.get('mobileNum').value,
        specialInstructions: this.detailsForm.get('instructions').value,
        isSamleikin: this.isSamleikin,
        languageCode: this.translateService.currentLang,
        preferSms: this.detailsForm.get('registerForSMS').value,
        preferEmail: this.detailsForm.get('registerForEmail').value,
        email: this.detailsForm.get("email").value
      }

      this.userService.updateIndividualProfile(privateUser).subscribe((x) => {
        this.failed = false;
        this.success = true;
        this.saving = false;
        var userDetails = localStorage.getItem("userDetails");
        var userDetailsJson = JSON.parse(userDetails);
        userDetailsJson.isProfileComplete = true;
        localStorage.setItem('userDetails', JSON.stringify(userDetailsJson));
      }, (error) => {
        this.saving = false;
        this.failed = true;
      });
    }
  }


}
