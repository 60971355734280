<div class="container">
    <div class="title-container section">
        <h2 class="primary">{{'buy_postage.postage_one' | translate}}</h2>
    </div>
    <div class="from-to-container section row">
        <div class="col-lg-2 col-md-2 noPadding">
            <h3>{{'buy_postage.from_to' | translate}}</h3>
        </div>
        <div class="col-10 noPadding">
            {{'buy_postage.international' | translate}}
            <div class="from-to-inner-container row noMargin">
                <div class="col-md-4 col-12 noPadding">
                    <h4 class="primary">{{'buy_postage.from' | translate}}</h4>
                    <div>{{getAddressValue('fromContactName')}}</div>
                    <div>{{getAddressValue('fromAddressLine1')}}</div>
                    <br *ngIf="getAddressValue('toAddressLine2')">
                    <div>{{getAddressValue('fromTownCity')}}</div>
                    <div>{{getAddressValue('fromPostcode')}}</div>
                    <div>{{'buy_postage.faroe_islands' | translate}}</div>
                    <div>{{getAddressValue('fromContactNum')}}</div>
                </div>
                <div class="col-md-4 col-12 noPadding">
                    <h4 class="primary">{{'buy_postage.to' | translate}}</h4>
                    <div>{{getAddressValue('toContactName')}}</div>
                    <div>{{getAddressValue('toAddressLine1')}}</div>
                    <div *ngIf="getAddressValue('toAddressLine2')">{{getAddressValue('toAddressLine2')}}</div>
                    <div>{{getAddressValue('toTownCity')}}</div>
                    <div>{{getAddressValue('toPostcode')}}</div>
                    <div>{{getAddressValue('toCountry')}}</div>
                    <div>{{getAddressValue('toContactNum')}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="contents-container section">
        <div class="row noMargin">
            <div class="col-lg-2 col-md-2 noPadding">
                <h3>{{'buy_postage.contents' | translate}}</h3>
            </div>
            <div class="col-10 noPadding">
                <div class="row noMargin">
                    <div class="col-md-4 col-12 noPadding">
                        <h4 class="primary">{{'buy_postage.type' | translate}}</h4>
                        <div>{{'buy_postage.goods' | translate}}</div>
                    </div>
                    <div class="col-md-4 col-12 noPadding">
                        <h4 class="primary">{{'buy_postage.weight' | translate}}</h4>
                        <div>{{postageItem.weight}}</div>
                    </div>
                    <div class="col-md-4 col-12 noPadding">
                        <h4 class="primary">{{'buy_postage.size' | translate}}</h4>
                        <div><strong>{{postageItem.sizeName}}</strong>: {{postageItem.sizeDescription}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contents-inner-container row noMargin">
            <div class="col-lg-2 col-md-2 noPadding">
                <h3>{{'buy_postage.secondary_page.price' | translate}}</h3>
            </div>
            <div class="col-10 noPadding">
                {{postageItem.price | currency: 'DKK':''}} DKK
            </div>
        </div>
        <div class="contents-inner-container row noMargin">
            <div class="col-lg-2 col-md-2 align-center noPadding">
                <h3>{{'buy_postage.secondary_page.your_ref' | translate}}</h3>
            </div>
            <div class="col-lg-4 noPadding">
                <mat-form-field class="full-width" floatLabel="never" appearance="outline">
                    <input matInput placeholder="{{'buy_postage.secondary_page.your_ref_placeholder' | translate}}" id="reference" name="reference"
                        [(ngModel)]="postageItem.reference" required>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="customs-container section">
        <div class="row noMargin">
            <div class="col-lg-2 col-md-2 noPadding">
                <h3 style="display: inline-block;">{{'buy_postage.customs_information' | translate}}</h3>
                <label class="tooltipSource"><img class="information-icon"
                        src="/assets/icons/i_information@3x.png" />
                        <a tabindex="0"></a><span>{{'buy_postage.customs_information_tooltip' | translate}}</span></label>
            </div>
            <div class="col-10 noPadding">
                <h4 class="primary" style="padding-bottom: 20px;">{{'buy_postage.type_of_shipment' | translate}}</h4>
                <div *ngFor="let shipmentType of shipmentTypes" class="row noMargin" style="padding-bottom: 20px;">
                    <div>
                        <label>
                            <span style="padding-right: 5px;">
                                <input type="radio" name="shipment-type"
                                checked={{checkPurchaseCommercial(shipmentType)}}
                                (change)="onSelectCommerical($event, shipmentType)">
                                <!--  -->
                            </span>
                            <span class="checkmark"></span>
                            {{getCustomsShipmentTypeName(shipmentType)}}
                        </label>
                    </div>
                </div>
                <mat-form-field *ngIf="otherSelected" class="align-form-field" [formGroup]="buyPostageCustomsForm"
                    floatLabel="never" appearance="outline" style="padding-top: 0px; padding-bottom: 44px;">
                    <input matInput formControlName="shipmentTypeOther" required
                        placeholder="{{'send_receive.send_receive.step_three.if_other_specify' | translate}}">
                    <mat-error *ngIf="hasErrors('shipmentTypeOther')">
                        <app-field-error-message errorMessage="{{getError('shipmentTypeOther')}}"></app-field-error-message>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row noMargin">
            <div class="col-lg-2 col-md-2 noPadding"></div>
            <div class="col-lg-8 col-md-8 col-12 noPadding">
                <app-buy-postage-item-types [(itemTypes)]="itemTypes"></app-buy-postage-item-types> 
            </div>
        </div>
    </div>
    <div class="totals-container section">
        <div class="row noMargin">
            <div class="col-lg-2"></div>
            <div class="col-lg-8 col-12 noPadding">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <div class="row totals-container-column">
                            <div class="col-md-auto col-12">
                                {{'buy_postage.total_gross_weight' | translate}}
                            </div>
                            <div class="col" style="padding-right: 0px;">
                                <mat-form-field class="full-width" floatLabel="never" appearance="outline" >
                                    <input matInput value="{{getTotalWeightText()}}" disabled>
                                </mat-form-field>
                            </div>
                            <div class="col-auto" style="padding-left: 0px;">
                                <div class="dark-blue-button" style="padding:8px 10px">kg</div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6 col-12 total-value-container">
                        <div class="row totals-container-column">
                            <div class="col-md-auto col-12">
                                {{'buy_postage.total_value' | translate}}
                            </div>
                            <div class="col">
                                <mat-form-field class="full-width" floatLabel="never" appearance="outline" >
                                    <input matInput value="{{getTotalValueText()}}" disabled>
                                </mat-form-field>
                            </div>
                            <div class="col-auto">
                                DKK
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <app-error-message *ngIf="weightTooHigh()" errorMessage="{{'buy_postage.total_weight_too_high' | translate}}"></app-error-message>
    <div class="price-container section">
        <div style="justify-content: flex-end; display: flex;">
            <h1 class="header-grey">{{'buy_postage.secondary_page.total' | translate}} {{postageItem.price | currency: 'DKK':''}} DKK</h1>
        </div>
    </div>
        
    <div class="proceed-container section">
        <div class="row">
            <div class="col-lg-3 col-3 hide-mobile">
                <button class="tertiary-button back-button" (click)="goToPreviousPage()">
                    <div class="full-width">
                        <img class="back-icon" src="/assets/icons/arrows/back_button_icon@3x.png" />
                        <span>{{'buy_postage.secondary_page.back' | translate}}</span>
                    </div>
                </button>
            </div>
            <div class="col-lg-3 col-3 hide-mobile">
                <button class="tertiary-button clear-all-button" (click)="clearAll()">
                    <div class="full-width">
                        <span class="clear-all-icon-text">{{'buy_postage.clear_all' | translate}}</span>
                        <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
                    </div>
                </button>
            </div>
            <div class="col-lg-3 offset-lg-3 col-sm-3 offset-sm-3 col-12 proceed-button-div" style="display: flex;">
                <button class="primary-button" (click)="goToNextPage()">{{'buy_postage.secondary_page.proceed' | translate}}</button>
            </div>
            <div class="col-12 show-mobile" style="padding-top: 20px;">
                <button class="tertiary-button back-button" (click)="goToPreviousPage()">
                    <div class="full-width">
                        <img class="back-icon" src="/assets/icons/arrows/back_button_icon@3x.png" />
                        <span>{{'buy_postage.secondary_page.back' | translate}}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>