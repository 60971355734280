import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShoppingListService } from '../services/shopping-list-service';

@Component({
  selector: 'app-create-shopping-lists-dialog',
  templateUrl: './create-shopping-lists-dialog.component.html',
  styleUrls: ['./create-shopping-lists-dialog.component.scss']
})
export class CreateShoppingListsDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public shoppingListService: ShoppingListService,
    public dialogRef: MatDialogRef<CreateShoppingListsDialogComponent>) { }

  ngOnInit(): void {
  }

  public createShoppingLists() {
    console.log(this.data.lists);
    this.data.lists.shoppingLists.forEach(x => {
      this.shoppingListService.create(x.name).subscribe(y => {
        console.log(y);

        x.products?.forEach(z => {
          //Can simplify but keep like this in case we need to do anything additional in the future.
          if (z.productId) {
            const addToListBody = {
              "ShoppingListId": y,
              "ProductId": z.productId,
              "ItemName": z.productName
            }
            this.shoppingListService.addToShoppingList(addToListBody).subscribe(a => {
            });
          }
          else {
            const body = {
              "ShoppingListId": y,
              "ProductId": null,
              "ItemName": z.productName
            }
            this.shoppingListService.addToShoppingList(body).subscribe(a => {
            });
          }
        })
      })
    })
    this.dialogRef.close();
    if (!!localStorage.getItem('shoppingLists'))
    {
      localStorage.removeItem('shoppingLists');
    }
  }

  public onNo() {
    this.dialogRef.close();
  }

}
