import { Pipe, PipeTransform } from '@angular/core';
import { TranslationHelper } from '../helpers/translation-helper'

@Pipe({
  name: 'numberLocalization'
})

export class NumberLocalizationPipe implements PipeTransform {
    constructor(private translateHelper: TranslationHelper) {}
    transform(value: any): any {
            return this.translateHelper.getNumberString(value);
    }

}