import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../../account/services/role.service';
import { GeneratedCodeDialogComponent } from '../generated-code-dialog/generated-code-dialog/generated-code-dialog.component';
import { ManualItemDialogComponent } from '../manual-item-dialog/manual-item-dialog.component';
import { ProductService } from '../services/product.service';
import { ShoppingListService } from '../services/shopping-list-service';
import { ShoppingList } from '../models/ShoppingList';
import { AuthenticationService } from '../../account/services/authentication.service';
import { ShoppingListsLoggedOut } from '../models/ShoppingListLoggedOut';
import { ShoppingListProduct } from '../models/ShoppingListProduct';

@Component({
  selector: 'app-view-shopping-list',
  templateUrl: './view-shopping-list.component.html',
  styleUrls: ['./view-shopping-list.component.scss']
})
export class ViewShoppingListComponent implements OnInit {

  public shoppingListId: any;
  public shoppingLists: Array<any>;
  public groupedProductsByRetailer: Array<any> = []
  public activeList: any;
  public boughtItems: Array<any>;
  public loggedOutList: ShoppingList;
  public allLoggedOutLists: ShoppingListsLoggedOut;
  
  constructor(private _route: ActivatedRoute, private shoppingListService: ShoppingListService,
    private productService: ProductService, public translateService: TranslateService, public authService: AuthenticationService,
    public roleService: RoleService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.loggedOutList = JSON.parse(this._route.snapshot.paramMap.get('list'));

    if (this.loggedOutList)
    {
      this.getProductsForLoggedOutUser();
    } else
    {
      this.shoppingListId = this._route.snapshot.paramMap.get('shoppingListId');

      this.shoppingListService.getAll().subscribe(z => {
        this.shoppingLists = z;
        if (this.shoppingListId) {
          this.activeList = this.shoppingLists.find(y => String(y.shoppingListId) === this.shoppingListId);
          this.shoppingListService.getProductsForListWithImage(this.shoppingListId).subscribe(x => {
            x.forEach(element => {
              if (element.imageData) {
                element.imageUrl = 'data:image/jpeg;base64,' + element.imageData;
                let img = new Image();
  
                img.src = element.imageUrl;
                img.onload = () => {
                  var ratio = Math.min(80 / img.naturalWidth, 106 / img.naturalHeight);
                  element.width = img.naturalWidth * ratio;
                  element.height = img.naturalHeight * ratio;
                }
              }
              else {
                element.imageUrl = "/assets/icons/shopping-list/No-Image-Placeholder.png";
              }
            });
            this.activeList.productItemNames = x.map(a => a.productItemName);
            this.activeList.productIds = x.map(a => a.productId);
            this.boughtItems = x.filter(x => x.isBought);
            const itemsToFilter = x.filter(x => !x.isBought);
    
            const grouped = this.groupBy(itemsToFilter, 'retailerName');
            for (let retailer of Object.entries(grouped)) {
              this.groupedProductsByRetailer.push(retailer);
            }
            console.log(this.groupedProductsByRetailer);
          });
        }
      });
    }
  }

  private getProductsForLoggedOutUser() {
    this.groupedProductsByRetailer = [];
    var loggedOutLists = localStorage.getItem('shoppingLists');
    this.allLoggedOutLists = JSON.parse(loggedOutLists);

    this.loggedOutList = this.allLoggedOutLists.shoppingLists.find(y => y.name === this.loggedOutList.name);

    this.loggedOutList.products.forEach(product => {
      this.shoppingListService.getProductImage(product.productId).subscribe(x => {
        if (x) {
          product.imageUrl = 'data:image/jpeg;base64,' + x;
          let img = new Image();

          img.src = product.imageUrl;
          img.onload = () => {
            var ratio = Math.min(80 / img.naturalWidth, 106 / img.naturalHeight);
            product.width = img.naturalWidth * ratio;
            product.height = img.naturalHeight * ratio;
          };
        }
      });
    });

    const grouped = this.groupBy(this.loggedOutList.products, 'retailerName');
    for (let retailer of Object.entries(grouped)) {
      this.groupedProductsByRetailer.push(retailer);
    }
  }

  public updateActiveList(event: any): void {
    if (this.isLoggedIn())
    {
      this.getProductsForList(event.value);
    }
    else {
      this.loggedOutList = event.value;
      this.getProductsForLoggedOutUser();
    }
  }

  public setProductImgWidth(product: any) {
    let img = new Image();

    img.src = product.imageUrl;

    var ratio = Math.min(80 / img.naturalWidth, 106 / img.naturalHeight);
    return (img.naturalWidth * ratio);
  }

  public setProductImgHeight(product: any) {
    let img = new Image();

    if (!product.imageUrl) {
      product.imageUrl = "/assets/icons/shopping-list/No-Image-Placeholder.png"
    }

    img.src = product.imageUrl;
    var ratio = Math.min(80 / img.naturalWidth, 106 / img.naturalHeight);
    return (img.naturalHeight * ratio);
  }


  private groupBy(xs, prop) {
    var grouped = {};
    for (var i = 0; i < xs.length; i++) {
      var p = xs[i][prop];
      if (!grouped[p]) { grouped[p] = []; }
      grouped[p].push(xs[i]);
    }
    return grouped;
  }

  public createShareLink(): void {
    var stringBuilder = "";
    if (this.activeList && this.activeList.productItemNames.length > 0) {
      stringBuilder = stringBuilder.concat(`${this.activeList.shoppingListName}:\n`);
      this.activeList.productItemNames.forEach(x => stringBuilder = stringBuilder.concat(`${x}\n`));
    }
    else if (this.loggedOutList && this.loggedOutList.products)
    {
      stringBuilder = stringBuilder.concat(`${this.loggedOutList.name}:\n`);
      this.loggedOutList.products.map(z => z.productName).forEach(x => stringBuilder = stringBuilder.concat(`${x}\n`));
    }

    if (this.isLoggedIn()) {
      const shareFilter = {
        "LanguageCode": `${this.translateService.currentLang}`,
        "Role": `${this.roleService.role()}`,
        "ShoppingListId": this.activeList.shoppingListId
      }

      this.shoppingListService.shareList(shareFilter).subscribe(x => {
        const shareDialogRef = this.dialog.open(GeneratedCodeDialogComponent, {
          height: '600px',
          width: '730px',
          data: {
            link: x,
            plainText: stringBuilder
          }
        });
      });
    }
    else if (this.loggedOutList && stringBuilder !== "")
      {
        const shareDialogRef = this.dialog.open(GeneratedCodeDialogComponent, {
          height: '600px',
          width: '730px',
          data: {
            link: null,
            plainText: stringBuilder
          }
        })
      }
  }

  public removeProduct(product: any): void {
    if (this.isLoggedIn())
    {
      this.shoppingListService.removeItemFromShoppingList(this.activeList.shoppingListId, product.shoppingListItemId).subscribe(x => {
        this.getProductsForList(this.activeList);
      });
    }
    else {
      this.loggedOutList.products = this.loggedOutList.products.filter(x => x !== product);
      var loggedOutLists = localStorage.getItem('shoppingLists');
      if (!!loggedOutLists) {
        var shoppingListsObj = JSON.parse(loggedOutLists);
        var indexToUpdate = shoppingListsObj.shoppingLists.findIndex(x => x.name == this.loggedOutList.name);
        this.allLoggedOutLists.shoppingLists[indexToUpdate] = this.loggedOutList;
        localStorage.setItem("shoppingLists", JSON.stringify(this.allLoggedOutLists));
      }
      this.getProductsForLoggedOutUser();

      console.log(this.loggedOutList.products);
    }
  }

  public toggleProductBought(product: any): void {
    const filter = {
      "ShoppingListItemId": product.shoppingListItemId,
      "IsBought": !product.isBought
  }

    this.productService.setIsBought(filter).subscribe(b => {
      this.boughtItems = [];
      this.shoppingListService.getAll().subscribe(z => {
        this.shoppingLists = z;
        if (this.shoppingListId) {
          this.activeList = this.shoppingLists.find(y => String(y.shoppingListId) === this.shoppingListId);
          this.shoppingListService.getProductsForListWithImage(this.shoppingListId).subscribe(x => {
            x.forEach(element => {
              if (element.imageData) {
                element.imageUrl = 'data:image/jpeg;base64,' + element.imageData;
                let img = new Image();
  
                img.src = element.imageUrl;
                img.onload = () => {
                  var ratio = Math.min(80 / img.naturalWidth, 106 / img.naturalHeight);
                  element.width = img.naturalWidth * ratio;
                  element.height = img.naturalHeight * ratio;
                }
              }
              else {
                element.imageUrl = "/assets/icons/shopping-list/No-Image-Placeholder.png";
              }
            });
            this.activeList.productItemNames = x.map(a => a.productItemName);
            this.activeList.productIds = x.map(a => a.productId);
            this.boughtItems = x.filter(x => x.isBought);
            const itemsToFilter = x.filter(x => !x.isBought);
    
            const grouped = this.groupBy(itemsToFilter, 'retailerName');
            this.groupedProductsByRetailer = [];
            for (let retailer of Object.entries(grouped)) {
              this.groupedProductsByRetailer.push(retailer);
            }
          });
        }
      });
    });
  }

  public addManualItem(): void {
    if (this.isLoggedIn())
    {
      const newItemDialog = this.dialog.open(ManualItemDialogComponent, {
        height: '170px',
        width: '580px',
        data: {
          id: this.activeList.shoppingListId
        }
      });
  
      newItemDialog.afterClosed().subscribe(result => {
        this.shoppingListService.getAll().subscribe(x => {
          this.shoppingLists = x;
          const activeList = x.find(y => y.shoppingListId == this.activeList.shoppingListId);
          this.getProductsForList(activeList);
        })
      });
    }
    else {
      const newItemDialog = this.dialog.open(ManualItemDialogComponent, {
        height: '170px',
        width: '580px',
        data: {
          name: this.loggedOutList.name
        }
      });
  
      newItemDialog.afterClosed().subscribe(result => {
        this.getProductsForLoggedOutUser();
      });
    }
  }

  private getProductsForList(list: any): void {
    this.shoppingListService.setActiveList(list.shoppingListId).subscribe(y => {
      this.activeList = list;
      this.shoppingListService.getProductsForListWithImage(list.shoppingListId).subscribe(x => {
        x.forEach(element => {
          if (element.imageData) {
            element.imageUrl = 'data:image/jpeg;base64,' + element.imageData;
            let img = new Image();

            img.src = element.imageUrl;
            img.onload = () => {
              var ratio = Math.min(80 / img.naturalWidth, 106 / img.naturalHeight);
              element.width = img.naturalWidth * ratio;
              element.height = img.naturalHeight * ratio;
            }
          }
          else {
            element.imageUrl = "/assets/icons/shopping-list/No-Image-Placeholder.png";
          }
        });
        this.activeList.productItemNames = x.map(a => a.productItemName);
        this.activeList.productIds = x.map(a => a.productId);
        this.boughtItems = x.filter(x => x.isBought);
        const itemsToFilter = x.filter(x => !x.isBought);

        const grouped = this.groupBy(itemsToFilter, 'retailerName');
        this.groupedProductsByRetailer = [];
        for (let retailer of Object.entries(grouped)) {
          this.groupedProductsByRetailer.push(retailer);
        }
        console.log(this.groupedProductsByRetailer);
      });
    })
  }

  public isLoggedIn(): any {
    return this.authService.isLoggedIn();
  }
}
