<div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
<div class="container">


    <div *ngIf="!showUploadDocumentScreen" class="row first-section-container">
        <div class="col-lg-6 col-12 image-container">
            <img *ngIf="!!image" class="image" [src]="image" />
            <img *ngIf="!!image" class="image-tablet" [src]="image" />
        </div>
        <div class="col-lg-6 col-12 parent-input-container">
            <div class="input-container">
                <div class="row row-padding align-center">
                    <div class="col-lg-6 col-md-6 col-12">
                        <h3 class="input-title">{{'upload_missing_documentation.posta_id' | translate}}</h3>
                        <mat-form-field class="align-form-field full-width-form-field" [formGroup]="customsFormGroup"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{'upload_missing_documentation.enter_posta_id' | translate}}"
                                id="invoicePoNumber" name="invoicePoNumber" formControlName="invoicePoNumber" required>
                            <mat-error *ngIf="hasErrors('invoicePoNumber')"><app-field-error-message errorMessage="{{getError('invoicePoNumber')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12 mobile-padding">
                        <h3 class="input-title align-end">{{'upload_missing_documentation.reference_number' |
                            translate}}</h3>
                        <mat-form-field class="align-form-field full-width-form-field" floatLabel="never"
                            [formGroup]="customsFormGroup" appearance="outline">
                            <input matInput
                                placeholder="{{'upload_missing_documentation.enter_reference_code' | translate}}"
                                id="refCode" name="refCode" formControlName="refCode" required>
                                
                            <mat-error *ngIf="hasErrors('refCode')"><app-field-error-message errorMessage="{{getError('refCode')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 offset-md-6 col-12 submit-col">
                        <button class="primary-button full-width submit-button" [disabled]="loading"
                            (click)="getOrder()">
                            <div class="spinner">
                                <span [ngClass]="loading? 'margin-left' : ''">{{'upload_missing_documentation.submit' |
                                    translate}}</span>
                                <mat-spinner *ngIf="loading" style="margin-left: 15px;" diameter="15"></mat-spinner>
                            </div>
                        </button>
                    </div>
                    <div class="col-lg-12 col-12 warning-text">
                        {{'upload_missing_documentation.order_via_my_posta' | translate}}
                        <a routerLink="../../my-posta/orders"
                            class="">{{'upload_missing_documentation.order_via_my_posta_link' | translate}}</a>
                        <mat-error *ngIf="failedToGetOrder" id="no-record-found" style="padding-top: 20px">
                            {{'upload_missing_documentation.no_record_found' | translate}}</mat-error>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12">
                <mat-horizontal-stepper *ngIf="showUploadDocumentScreen" [linear]="true" #stepper>
                    <mat-step [optional]="false" [completed]="showConfirmationScreen"
                        [stepControl]="uploadedDocumentsFormGroup" #stepper>
                        <div>
                            <form [formGroup]="uploadedDocumentsFormGroup">
                            <ng-template matStepLabel><span
                                    class="lg-view">{{'upload_missing_documentation.step_one_title' | translate}}</span><span class="sm-view">1</span></ng-template>
                            <app-file-upload-missing-documents (successfullyUploaded)="showConfirmation($event)"
                                [customsFormGroup]="customsFormGroup"
                                [uploadedDocumentsFormGroup]="uploadedDocumentsFormGroup">
                            </app-file-upload-missing-documents>
                        </form>
                        </div>
                        
                    </mat-step>
                    <mat-step [optional]="false" #stepper>
                        <ng-template matStepLabel><span class="lg-view">{{'upload_missing_documentation.step_two_title' | translate}}</span><span class="sm-view">2</span></ng-template>
                        <div class="container">
                            <div class="confirmation-container">
                                <h2 class="confirmation-body-title">{{'upload_missing_documentation.thank_you_for_your_documents' | translate}}</h2>
                                <div class="confirmation-body">
                                    {{'upload_missing_documentation.confirmation_body' | translate}}
                                </div>
                            </div>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>