<div class="container">
    <div class="login-container" *ngIf="!isLoggedIn">
        <app-inline-login [buyPostageForm]="emailForm" (onLoggedIn)="onLoggedIn($event)"></app-inline-login>
    </div>
    <div class="proceed-container">
        <div class="row">
            <div class="col-lg-3 col-3 hide-mobile">
                <button class="tertiary-button back-button" (click)="goBack()">
                    <div class="full-width">
                        <img class="back-icon" src="/assets/icons/arrows/back_button_icon@3x.png" />
                        <span>{{'buy_postage.secondary_page.back' | translate}}</span>
                    </div>
                </button>
            </div>
            <div class="col-lg-3 offset-lg-6 col-sm-3 offset-sm-6 col-12 proceed-button-div" style="display: flex;">
                <button class="primary-button" [disabled]="!emailForm?.valid" (click)="proceedToNextPage()">{{'buy_postage.secondary_page.proceed' | translate}}</button>
            </div>
            <div class="col-12 show-mobile" style="padding-top: 20px;">
                <button class="tertiary-button back-button" (click)="goBack()">
                    <div class="full-width">
                        <img class="back-icon" src="/assets/icons/arrows/back_button_icon@3x.png" />
                        <span>{{'buy_postage.secondary_page.back' | translate}}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>