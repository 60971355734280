import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from '../shared/shared-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CustomsCalculatorComponent } from './customs-calculator/customs-calculator.component';
import { ImportServicesRoutingModule } from './import-services-routing.module';
import { BasePayCustomsComponent } from './pay-customs/base-pay-customs.component';
import { PayCustomsComponent } from './pay-customs/pay-customs.component';
import { PayInvoiceComponent } from './pay-customs/pay-invoice.component';
import { PayCustomsUserConfirmationComponent } from './pay-customs/user-confirmation/user-confirmation.component';
import { DetailsAndPaymentComponent } from './pay-customs/details-and-payment/details-and-payment.component';
import { CustomsConfirmationComponent } from './pay-customs/customs-confirmation/customs-confirmation.component';
import { SharedModule } from '../shared/shared.module';
import { UploadMissingDocumentationComponent } from './upload-missing-documentation/upload-missing-documentation.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';



@NgModule({
  declarations: [
    CustomsCalculatorComponent,
    BasePayCustomsComponent,
    PayCustomsComponent,
    PayInvoiceComponent,
    PayCustomsUserConfirmationComponent,
    DetailsAndPaymentComponent,
    CustomsConfirmationComponent,
    UploadMissingDocumentationComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    SharedModule,
    ImportServicesRoutingModule,
    TranslateModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatProgressBarModule,
    MatCardModule,
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    Ng2SearchPipeModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatOptionModule,
    MatStepperModule
  ],
  exports: [
  ]
})
export class ImportServicesModule { }
