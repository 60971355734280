import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { CCSDetailComponent } from './ccsdetail/ccsdetail.component';
import { CCSOrderResolver } from './services/ccs-order.resolver';


const routes: Routes = [
  { path: ':lang/:role/ccs/:ccsId', component: CCSDetailComponent, resolve: { ccsSubmission: CCSOrderResolver }, canActivate: [AuthGuard]  },];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CcsRoutingModule { }
