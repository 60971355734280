<div class="container">
    <div *ngIf="!passwordSuccessfullyChanged && !passwordChangeFailed" class="reset-password-container">
        <h2 class="reset-password-title">{{'change_password.change_password_title' | translate}}</h2>
        <div class="row password-input-container">
            <h4 class="primary col-lg-3 change-password-field">{{'change_password.password_header' | translate}}</h4>
            <mat-form-field class="col-lg-8 align-form-field change-password-field" [formGroup]="changePasswordForm" floatLabel="never"
                appearance="outline">
                <input type="password" matInput placeholder="{{'change_password.password_input_placeholder' | translate}}" id="currentPassword" name="currentPassword"
                    formControlName="currentPassword" required>
                    <mat-error *ngIf="hasErrors('currentPassword')"><app-field-error-message errorMessage="{{getError('currentPassword')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>
        <div class="row password-input-container">
            <h4 class="primary col-lg-3 change-password-field">{{'change_password.new_password_header' | translate}}</h4>
            <mat-form-field class="col-lg-8 align-form-field change-password-field" [formGroup]="changePasswordForm" floatLabel="never"
                appearance="outline">
                <input type="password" matInput placeholder="{{'change_password.new_password_input_placeholder' | translate}}" id="newPassword" name="newPassword"
                    formControlName="newPassword" required>
                    <mat-error *ngIf="hasErrors('newPassword')"><app-field-error-message errorMessage="{{getError('newPassword')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>
        <div class="row password-input-container">
            <h4 class="primary col-lg-3 change-password-field">{{'change_password.confirm_password_header' | translate}}</h4>
            <mat-form-field class="col-lg-8 align-form-field change-password-field" [formGroup]="changePasswordForm" floatLabel="never"
                appearance="outline">
                <input type="password" matInput placeholder="{{'change_password.confirm_password_placeholder' | translate}}" id="confirmPassword"
                    name="confirmPassword" formControlName="confirmPassword" required>
                    <mat-error *ngIf="hasErrors('confirmPassword')"><app-field-error-message errorMessage="{{getError('confirmPassword')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="submit-button-container">
            <button class="primary-button submit-button" (click)="submit()">
                {{'change_password.save' | translate}}
            </button>
        </div>
    </div>


    <div *ngIf="passwordSuccessfullyChanged" class="reset-password-container">
        <h2 class="password-reset-requested-title">{{'change_password.successfully_reset_title' | translate}}</h2>
        <div>{{'change_password.sucessfully_reset_body' | translate}}</div>
    </div>

    <div *ngIf="passwordChangeFailed" class="reset-password-container">
        <h2 class="password-reset-requested-title">{{'change_password.change_failed_title' | translate}}</h2>
        <div>{{'change_password.change_failed_body' | translate}}</div>
    </div>
</div>