<div mat-dialog-title>
    <div class="dialog-title">
        <button mat-icon-button class="close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
          </button>
    </div>
    <div class="d-flex align-center title-container">
        <h2>{{'bera.edit_list.add_manual_item_title' | translate}}</h2>
    </div>
  </div>
<mat-dialog-content class="mat-typography">
    <div class="d-flex align-center title-container">
        <mat-form-field class="search-address-col" floatLabel="never" appearance="outline">
            <input class="text-input" matInput placeholder="{{'bera.edit_list.manual_item_name_placeholder' | translate}}"
                type="text" name="manualItemName" [(ngModel)]="manualItemName" autocomplete="off"
                (keyup.enter)="createNewItem()">
        </mat-form-field>
        <div class="address-menu-item">
            <button class="primary-button add-button" (click)="createNewItem()">
                <img class="add-icon" src="/assets/icons/address_book/add_address@3x.png" />
            </button>
        </div>
    </div>
</mat-dialog-content>