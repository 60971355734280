import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CCSSubmission } from "../entities/CCSSubmission";
import { CCSService } from "./ccs.service";

@Injectable({ providedIn: 'root' })
export class CCSOrderResolver implements Resolve<CCSSubmission> {

    constructor (private ccsService: CCSService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>| Promise<any>|any {
        return this.ccsService.getCCSSubmission(route.paramMap.get('ccsId'), route.paramMap.get('lang'));
    }
}