import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../account/entities/user';
import { PriceMatrixService } from '../../shared/price-matrix/services/price-matrix.service';
import { StaticContentService } from '../../shared/services/static-content.service';
import { CustomerShipmentDetail } from '../entities/CustomerShipmentDetail';
import { CustomerShipmentDocuments } from '../entities/CustomerShipmentDocuments';
import { WaybillService } from '../services/waybill.service';

@Component({
  selector: 'app-waybill-detail',
  templateUrl: './waybill-detail.component.html',
  styleUrls: ['./waybill-detail.component.scss']
})
export class WaybillDetailComponent implements OnInit {

  waybill: CustomerShipmentDetail;

  userDetails: User;

  totalAmount: number;

  countryName: string;

  public pageTitle: string;

  interval: any;
  downloadingCDS: boolean = false;

  constructor(private route: ActivatedRoute,
    private translateService: TranslateService,
    private staticContentService: StaticContentService,
    private waybillService: WaybillService) { }

  ngOnInit(): void {

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.staticContentService.getPageTitle();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.staticContentService.getPageTitle();
    })

    this.waybill = this.route.snapshot.data.waybill;

    this.userDetails = this.route.snapshot.data.userDetails;

    this.getCountryName();
  }

  public getCountryName() {
    switch (this.translateService.currentLang) {
      case "en":
        this.countryName = `${this.userDetails.country.countryNameEN}`;
        break;
      case "fo":
        this.countryName = `${this.userDetails.country.countryNameFO}`;
        break;
    }
  }

  public downloadDocument(file: CustomerShipmentDocuments){
    file.isDownloading = true;
    this.waybillService.downloadFile(file.linkId).subscribe(x => {
      const byteArray = new Uint8Array(atob(x).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = file.fileName;
      link.click();
      file.isDownloading = false;
    }, (error) => {
      file.isDownloading = false;
    })
  }

  public downloadCDS() {
    this.downloadingCDS = true;
    // this.buyPostageService.downloadCDS(this.order.barcode).subscribe(x => {
    //   const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
    //   const blob = new Blob([byteArray], { type: 'application/pdf' });
    //   const url = window.URL.createObjectURL(blob);
    //   // window.open(url);
    //   var link = document.createElement('a');
    //   link.href = url;
    //   link.download = x.fileName;
    //   link.click();
    //   this.downloadingCDS = false;

    //   // this.downloadingPdfOrder = false;
    //   // this.downloadingReceipt = false;
    // }, (error) => {
    //   this.downloadingCDS = false;
    //   // this.downloadingPdfOrder = false;
    //   // this.downloadingReceipt = false;
    // })
  }

}
