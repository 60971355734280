import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthenticationService } from '../../../account/services/authentication.service';

@Component({
  selector: 'app-buy-postage-login',
  templateUrl: './buy-postage-login.component.html',
  styleUrls: ['./buy-postage-login.component.scss']
})
export class BuyPostageLoginComponent implements OnInit {

  @Input() emailForm: FormGroup;
  isLoggedIn: boolean;

  @Output()
  backButtonSelected: EventEmitter<any> = new EventEmitter();

  @Output()
  proceedButtonSelected: EventEmitter<any> = new EventEmitter();

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  public goBack()
  {
    this.backButtonSelected.emit(true);
  }

  public proceedToNextPage(){
    this.proceedButtonSelected.emit(true);
  }

  public onLoggedIn(status: boolean){
    if(status){
      //check logged in state
      this.isLoggedIn = this.authService.isLoggedIn();
      if(this.emailForm){
        this.emailForm.get('email').disable();
      }
      this.proceedToNextPage();
      
    }
  }

}
