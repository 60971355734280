import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { interval, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { InvoiceCustom } from '../../entities/InvoiceCustom';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment'
import { PaymentService } from '../../../send-receive/services/payment.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-details-and-payment',
  templateUrl: './details-and-payment.component.html',
  styleUrls: ['./details-and-payment.component.scss']
})
export class DetailsAndPaymentComponent implements OnInit {

  @ViewChild('paymentFrame') iframe: ElementRef;

  @Input()
  userConfirmationFormGroup: FormGroup;

  @Input()
  customsFormGroup: FormGroup;

  @Input()
  invoiceCustom: InvoiceCustom;

  public intervalSet: boolean = false;

  private stopPolling = new Subject();

  public paymentSuccess: boolean = false;

  public isPaying: boolean = false;

  public loadingPayment: boolean = false;

  @Input()
  invoiceCustomFieldName: string;

  @Output()
  transactionRefOutput: EventEmitter<string> = new EventEmitter<string>();
  iframeURL: any;
  paymentCancelledOrFailed: boolean = false;

  constructor(private paymentService: PaymentService, private translateService: TranslateService, private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.stopPolling.next();
  }

  public payNow() {
    this.isPaying = true;
    this.loadingPayment = true;

    this.pay();
  }

  private pay() {
    this.paymentService.GetPaymentInfo(this.invoiceCustom.serviceTypeId, this.invoiceCustom.invoiceCustomNumber, this.invoiceCustom.referenceCode).subscribe(x => {

      if (x.outstandingAmount > 0) {
        this.paymentService.dibsPay(`${environment.dibsUrl}/payment/WebPay?locale=${this.translateService.currentLang}&systemType=5&serviceType=${this.invoiceCustom.serviceTypeId}&orderId=${this.invoiceCustom.invoiceCustomNumber}&amount=${this.invoiceCustom.outstandingAmount}&paidByEmail=${this.invoiceCustom.email}&referenceCode=${this.invoiceCustom.referenceCode}`).subscribe(
          y => {
            this.loadingPayment = false;
            this.iframeURL = this._sanitizer.bypassSecurityTrustResourceUrl(y)
            this.onFrameLoad();
          })
      }

    }, (error) => {
      console.log("Invoice no longer requires payment");
    });
  }


  public onFrameLoad() {
    if (!this.intervalSet) {
      this.intervalSet = true;
      interval(5000).pipe(
        startWith(0),
        switchMap(() => this.paymentService.getReceipt(this.invoiceCustom.serviceTypeId, this.invoiceCustom.invoiceCustomNumber)),
        takeUntil(this.stopPolling)).subscribe(x => {
          if (!!x)
          {
            if (!!x && !!x.paymentRequestHeader && (x.paymentRequestHeader.isPaid || x.paymentRequestHeader.isPaidButUpdateFailed)) {
              this.stopPolling.next();
              document.getElementById("paymentFrame").remove();
              this.paymentSuccess = true;
              if (!!x.payments && x.payments.length > 0)
                this.transactionRefOutput.emit(x.payments[0].transactionRef);
              this.isPaying = false;
              this.userConfirmationFormGroup.disable();
              return;
            }
            if (x.paymentRequestHeader.attemptedPaymentYN)
            {
              this.stopPolling.next();
              document.getElementById("paymentFrame").remove();
              this.paymentSuccess = true;
              this.paymentCancelledOrFailed = true;
              this.paymentService.updatePaymentStatus({serviceTypeId: +this.invoiceCustom.serviceTypeId, orderReference: this.invoiceCustom.invoiceCustomNumber}).subscribe();
              return;
            }
          }
        }), (err) => { 
          this.isPaying = false;
          document.getElementById("paymentFrame").remove();
          console.log("Error getting order after payment: " + err) 
        };
    }
  }
}
