<div class="item-type-container">
    <div class="row" style="height: 38px">
        <div class="item-type-header col-auto">
            <h5 class="white">{{'buy_postage.item_type' | translate}} {{index + 1}}</h5>
        </div>
        <div class="col"></div>
        <div class="col-auto">
            <button *ngIf="index > 0" class="close-category" (click)="deleteType()" style="border: 0px">
                <img class="close-icon" src="/assets/icons/arrows/close@3x.png" style="object-fit:contain; height: 20px" />
            </button>
        </div>
    </div>
    
    <div class="description-of-content-container"><strong>{{'buy_postage.description_of_content' | translate}}</strong>
        <label class="tooltipSource"><img class="information-icon"
                src="/assets/icons/i_information@3x.png" />
                <a tabindex="0"></a><span>{{'buy_postage.description_of_content_tooltip' | translate}}</span></label></div>
    <div class="row">
        <div class="col-lg-6 col-12">
            <mat-form-field [formGroup]="itemTypeForm" class="multiline-field" floatLabel="never">
                <textarea matInput rows="5" placeholder="{{'buy_postage.enter_description' | translate}}" id="description" name="description"
                    formControlName="description" required></textarea>
                <mat-error *ngIf="hasErrors('description')"><app-field-error-message errorMessage="{{getError('description')}}"></app-field-error-message></mat-error>
            
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-12 noPadding">
            <div class="row noPadding no-margin item-type-field">
                <div class="col-4 noPaddingLg"><strong>{{'buy_postage.quantity' | translate}}</strong>
                    <label class="tooltipSource"><img class="information-icon"
                            src="/assets/icons/i_information@3x.png" />
                            <a tabindex="0"></a><span>{{'buy_postage.quantity_tooltip' | translate}}</span></label></div>
                <div class="col-lg-4 col-5 noPaddingLg" style="padding-right: 0px;">
                    <mat-form-field [formGroup]="itemTypeForm" class="full-width" floatLabel="never" appearance="outline">
                        <input matInput type="number" placeholder="0" id="quantity" name="quantity"
                            formControlName="quantity" required>
                    </mat-form-field>
                </div>
            </div>
            <div class="row noPadding no-margin item-type-field">
                <div class="col-4 noPaddingLg"><strong>{{'buy_postage.net_weight' | translate}}</strong>
                    <label class="tooltipSource"><img class="information-icon"
                            src="/assets/icons/i_information@3x.png" />
                            <a tabindex="0"></a><span>{{'buy_postage.weight_tooltip' | translate}}</span></label></div>
                <div class="col-lg-4 col-5 noPaddingLg" style="padding-right: 0px;">
                    <mat-form-field [formGroup]="itemTypeForm" class="full-width" floatLabel="never" appearance="outline">
                        <input matInput placeholder="0" id="weight" name="weight"
                        formControlName="weight" required>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-3 noPadding">
                    <button mat-button [ngClass]="itemType.isKg ? 'dark-blue-button weight-button' : 'grey-button weight-button'" (click)="toggleKg(true)">kg</button>
                    <button mat-button [ngClass]="!itemType.isKg ? 'dark-blue-button weight-button' : 'grey-button weight-button'" (click)="toggleKg(false)">gr</button>
                </div>
            </div>
            <div class="row noPadding no-margin item-type-field">
                <div class="col-4 noPaddingLg"><strong>{{'buy_postage.value' | translate}}</strong>
                    <label class="tooltipSource"><img class="information-icon"
                            src="/assets/icons/i_information@3x.png" />
                            <a tabindex="0"></a><span>{{'buy_postage.value_tooltip' | translate}}</span></label></div>
                <div class="col-lg-4 col-5 noPaddingLg" style="padding-right: 0px;">
                    <mat-form-field [formGroup]="itemTypeForm" class="full-width" floatLabel="never" appearance="outline">
                        <input matInput placeholder="0" id="value" name="value"
                        formControlName="value" required>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-3 noPaddingLg">
                    DKK
                </div>
            </div>
        </div>
    </div>
    <div class="row country-tariff-section">
        <div class="col-lg-6 col-12">
            <strong>{{'buy_postage.country_of_origin' | translate}}</strong>
            <label class="tooltipSource"><img class="information-icon"
                    src="/assets/icons/i_information@3x.png" />
                    <a tabindex="0"></a><span>{{'buy_postage.country_of_origin_tooltip' | translate}}</span></label>
            <mat-form-field [formGroup]="itemTypeForm" class="full-width" floatLabel="never" appearance="outline">
                <mat-select disableOptionCentering panelClass="select-panel" formControlName="country" placeholder="{{'address_book.address_details.country' | translate}}" >
                    <mat-option *ngFor="let country of countries" [value]="country.countryCode">{{getCountryNameForLanguage(country)}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-12 noPaddingLg tariff-no-container">
            <strong>{{'buy_postage.tariff_no' | translate}}</strong>
            <label class="tooltipSource"><img class="information-icon"
                    src="/assets/icons/i_information@3x.png" />
                    <a tabindex="0"></a><span>{{'buy_postage.tariff_no_tooltip' | translate}}</span></label>
            <mat-form-field [formGroup]="itemTypeForm" class="full-width" floatLabel="never" appearance="outline">
                <input matInput placeholder="00000000" id="value" name="value"
                formControlName="tariffNo">
            </mat-form-field>
        </div>
    </div>
</div>