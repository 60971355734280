import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IncoTermsReceive } from '../entities/IncoTermsReceive';
import { IncoTermsInput } from '../entities/IncoTermsInput';
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class IncoTermsService {

    constructor(private httpClient: HttpClient) {
    }

    public GetIncoTerm(incoTermsInput: IncoTermsInput): Observable<IncoTermsReceive> {
        return this.httpClient.get(`${environment.apiUrl}/api/SendReceiveOrder/IncoTerms?isFromCountryFO=${incoTermsInput.isFromCountryFO}
        &isFromPostaDepot=${incoTermsInput.isFromPostaDepot}&isToCountryFO=${incoTermsInput.isToCountryFO}`) as Observable<IncoTermsReceive>;
    }
}