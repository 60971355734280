import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomsCalculatorComponent } from './customs-calculator/customs-calculator.component';
import { CategoryResolver } from './resolvers/category.resolver';
import { ValidateOrderResolver } from './resolvers/validate-order.resolver';
import { UploadMissingDocumentationComponent } from './upload-missing-documentation/upload-missing-documentation.component';
import { PayCustomsComponent } from './pay-customs/pay-customs.component';
import { PayInvoiceComponent } from './pay-customs/pay-invoice.component';

const routes: Routes = [
    { path: ':lang/:role:/import/customs-calculator', component: CustomsCalculatorComponent, resolve: { customCalculator: CategoryResolver }},
    { path: ':lang/:role/import/pay-customs', component: PayCustomsComponent },
    { path: ':lang/:role/import/pay-invoice', component: PayInvoiceComponent },
    // { path: ':lang/:role:/import/upload-missing-documentation', component: UploadMissingDocumentationComponent},
    // { path: ':lang/:role:/import/upload-missing-documentation/:orderId/:refCode', component: UploadMissingDocumentationComponent, resolve: { validOrder: ValidateOrderResolver }}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImportServicesRoutingModule { 
    
}
