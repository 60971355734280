import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AddressService } from '../../services/address-service';
import { CountryService } from '../../services/country.service';
import { Country } from '../../../account/entities/Country';
import { TranslateService } from '@ngx-translate/core';
import { Order } from '../../../send-receive/entities/Order';
import { map, startWith } from 'rxjs/operators';
import { ErrorHelper } from '../../../shared/helpers/error-helper';
import { Address } from '../../../account/entities/address';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-from-new-address',
  templateUrl: './from-new-address.component.html',
  styleUrls: ['./from-new-address.component.scss']
})
export class FromNewAddressComponent implements OnInit  {

  @Input()
  sendReceiveForm: FormGroup

  @Input()
  specificationForm: FormGroup

  countries: Array<Country>;

  @Input()
  newAddressBookEntry: boolean;

  @Output()
  successfullySaved: EventEmitter<boolean> = new EventEmitter();

  @Input()
  order: Order;

  @Input()
  postcodes: any;

  fromAddressControl = new FormControl();

  public fromFilteredAddresses: Observable<Array<Address>>;

  public addresses: Array<Address> = new Array<Address>();

  public fromAddressSelected: Address;

  public postcodeSelected: any;

  public fromFilteredPostcodes: any;

  public addToFavourite: boolean = false;
  saveDisabled: boolean = false;
  savedAddressForm: FormGroup;

  public fromFO: boolean;
  public fromFODisabled: boolean;

  constructor(private newAddressService: AddressService, private countryService: CountryService, private translate: TranslateService, private errorHelper: ErrorHelper, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.fromFilteredPostcodes = this.sendReceiveForm.get("fromPostcode").valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : `${value.postcode}, ${value.city}`, this.cdr.detectChanges()),
      map(postcode => postcode ? this._filterPostcode(postcode) : this.postcodes.slice(), this.cdr.detectChanges()),
    );

    this.sendReceiveForm.get("fromCountry").valueChanges.subscribe(
      value => {
        if(this.fromFO == false && value.countryCode == "FO"){
          // this.sendReceiveForm.get("fromPostcode").setValue(null);
          // this.sendReceiveForm.get("fromTownCity").setValue(null);
          this.fromFO = true;
        }
      }
    );

    this.countryService.getAll().subscribe(x => {
      this.countries = x;
      switch (this.translate.currentLang) {
        case "en":
          this.countries.sort((a, b) => a.countryNameEN.localeCompare(b.countryNameEN))
        case "fo":
          this.countries.sort((a, b) => a.countryNameFO.localeCompare(b.countryNameFO))
      }
      this.countries.sort(function(x,y){ return x.countryCode == "FO" ? -1 : y.countryCode == "FO" ? 1 : 0; });
      this.countries.sort(function(x,y){ return x.countryCode == "DK" ? -1 : y.countryCode == "DK" ? 1 : 0; });
      
      //If viewing/editing order then set the country formcontrol to the matching country
      if (!!this.order)
      {
        this.sendReceiveForm.get("fromCountry").setValue(this.countries.find(x => x.countryCode == this.order.fromCountryCode));
      }
   });

    if (this.newAddressBookEntry)
    {
      this.sendReceiveForm.get("fromTitle").reset();
      this.sendReceiveForm.get("fromFirstName").reset();
      this.sendReceiveForm.get("fromLastName").reset();
      this.sendReceiveForm.get("fromOptionalCompany").reset();
      this.sendReceiveForm.get("fromAddressLine1").reset();
      this.sendReceiveForm.get("fromAddressLine2").reset();
      this.sendReceiveForm.get("fromTownCity").reset();
      this.sendReceiveForm.get("fromPostcode").reset();
      this.sendReceiveForm.get("fromContactNumber").reset();
      this.sendReceiveForm.get("fromEmail").reset();
      this.sendReceiveForm.get("fromSpecialInstructions").reset();
    }

        //Set address details from order if exists
        if (!!this.order)
        {
              this.sendReceiveForm.get("fromTitle").setValue(this.order.fromTitle);
              this.sendReceiveForm.get("fromFirstName").setValue(this.order.fromFirstName);
              this.sendReceiveForm.get("fromLastName").setValue(this.order.fromLastName);
              this.sendReceiveForm.get("fromOptionalCompany").setValue(this.order.fromCompanyName);
              this.sendReceiveForm.get("fromAddressLine1").setValue(this.order.fromAddressLine1);
              this.sendReceiveForm.get("fromAddressLine2").setValue(this.order.fromAddressLine2);
              this.sendReceiveForm.get("fromTownCity").setValue(this.order.fromTownCity);
              this.sendReceiveForm.get("fromPostcode").setValue(this.order.fromPostcode);
              //this.sendReceiveForm.get("fromCountry").setValue(this.order.toCountryCode);
              this.sendReceiveForm.get("fromContactNumber").setValue(this.order.fromContactNumber);
              this.sendReceiveForm.get("fromEmail").setValue(this.order.fromContactEmail);
              this.sendReceiveForm.get("fromSpecialInstructions").setValue(this.order.fromSpecialInstructions);
        }
        this.cdr.detectChanges();

        this.sendReceiveForm.get("fromTitle").valueChanges.subscribe(x => {
          this.saveDisabled = false;
        })
        this.sendReceiveForm.get("fromFirstName").valueChanges.subscribe(x => {
          this.saveDisabled = false;
        })
        this.sendReceiveForm.get("fromLastName").valueChanges.subscribe(x => {
          this.saveDisabled = false;
        })
        this.sendReceiveForm.get("fromOptionalCompany").valueChanges.subscribe(x => {
          this.saveDisabled = false;
        })
        this.sendReceiveForm.get("fromAddressLine1").valueChanges.subscribe(x => {
          this.saveDisabled = false;
        })
        this.sendReceiveForm.get("fromAddressLine2").valueChanges.subscribe(x => {
          this.saveDisabled = false;
        })
        this.sendReceiveForm.get("fromTownCity").valueChanges.subscribe(x => {
          this.saveDisabled = false;
        })
        this.sendReceiveForm.get("fromPostcode").valueChanges.subscribe(x => {
          this.saveDisabled = false;
        })
        this.sendReceiveForm.get("fromContactNumber").valueChanges.subscribe(x => {
          this.saveDisabled = false;
        })
        this.sendReceiveForm.get("fromEmail").valueChanges.subscribe(x => {
          this.saveDisabled = false;
        })
        this.sendReceiveForm.get("fromSpecialInstructions").valueChanges.subscribe(x => {
          this.saveDisabled = false;
        })
  }

public addressFieldClick() {
  this.newAddressService.getAll().subscribe((x) => {
    this.addresses = x;

    this.fromFilteredAddresses = this.fromAddressControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
      map(firstName => firstName ? this._filter(firstName) : this.addresses.slice()),
    )  
  });
}

// public getAddresses() {
//   this.newAddressService.getAll().subscribe((x) => {
//     this.addresses = x

//     this.fromFilteredAddresses = this.fromAddressControl.valueChanges
//     .pipe(
//       startWith(''),
//       map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
//       map(firstName => firstName ? this._filter(firstName) : this.addresses.slice()),
//     )
//   });
// }

public setFromSelectedAddress(address) {
  this.fromAddressSelected = address;
}

public setFromAddressFields() {
  if (!!this.fromAddressSelected) {
    if(this.fromAddressSelected.country.countryCode == "FO"){
      this.fromFO = true;
      var selectedPostcode = this.postcodes.find(pc => pc.postcode == this.fromAddressSelected.postcode || pc.city == this.fromAddressSelected.townCity)
      if (!!selectedPostcode)
      {
        this.sendReceiveForm.get("fromTownCity").setValue(selectedPostcode.city);
        this.sendReceiveForm.get("fromPostcode").setValue(selectedPostcode.postcode);
      }
    }
    else{
      this.fromFO = false;
      this.sendReceiveForm.get("fromTownCity").setValue(this.fromAddressSelected.townCity);
      this.sendReceiveForm.get("fromPostcode").setValue(this.fromAddressSelected.postcode);
    }
    this.sendReceiveForm.get("fromTitle").setValue(this.fromAddressSelected.title);
    this.sendReceiveForm.get("fromFirstName").setValue(this.fromAddressSelected.firstName);
    this.sendReceiveForm.get("fromLastName").setValue(this.fromAddressSelected.lastName);
    this.sendReceiveForm.get("fromOptionalCompany").setValue(this.fromAddressSelected.companyName);
    this.sendReceiveForm.get("fromAddressLine1").setValue(this.fromAddressSelected.addressLine1);
    this.sendReceiveForm.get("fromAddressLine2").setValue(this.fromAddressSelected.addressLine2);
    this.sendReceiveForm.get("fromCountry").setValue(this.fromAddressSelected.country);
    this.sendReceiveForm.get("fromContactNumber").setValue(this.fromAddressSelected.contactNumber);
    this.sendReceiveForm.get("fromEmail").setValue(this.fromAddressSelected.contactEmail);
    this.sendReceiveForm.get("fromSpecialInstructions").setValue(this.fromAddressSelected.specialInstructions);
  }
}

public onSelectFromFO(event){
  if(event.returnValue){
    var fo = this.countries.find(country => country.countryCode == "FO")
    this.sendReceiveForm.get("fromCountry").setValue(fo);
  }
}

scrollToTop() {
  (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
  })();
}

// onKey(value) { 
//   this.countries = this.search(value);
//   }

//   search(value: string) { 
//     let filter = value.toLowerCase();
//     return this.countries.filter(option => this.getCountryNameForLanguage(option).toLowerCase().startsWith(filter));
//   }

  public hasErrors(field){
    var errors = this.sendReceiveForm.get(field).errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.sendReceiveForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  private _filterPostcode(value: string): Array<any> {
    const filterValue = value.toLowerCase();

    return this.postcodes.filter(option => `${option.postcode}, ${option.city}`.toLowerCase().includes(filterValue));
  }

  private _filter(value: string): Array<Address> {
    const filterValue = value.toLowerCase();

    return this.addresses.filter(option => `${option.firstName} ${option.lastName}, ${option.postcode}`.toLowerCase().includes(filterValue));
  }

  // displayFn(postcode: any): string {
  //   return postcode && `${postcode.postcode}, ${postcode.city}` ? `${postcode.postcode}, ${postcode.city}` : '';
  // }

  displayFn(postcode: any): string {
    return postcode && `${postcode}` ? `${postcode}` : '';
  }

  displayAddress(address: Address): string {
    return address && `${address.firstName} ${address.lastName}, ${address.postcode}` ? `${address.firstName} ${address.lastName}, ${address.postcode}` : '';
  }

  public setFavouriteChecked(checked: boolean) {
    this.addToFavourite = checked;
  }

  public saveEntryToAddressBook()
  {
    this.saveDisabled = false;

    var formValid = this.sendReceiveForm.get("fromTitle").valid
    && this.sendReceiveForm.get("fromFirstName").valid
    && this.sendReceiveForm.get("fromLastName").valid
    && this.sendReceiveForm.get("fromOptionalCompany").valid
    && this.sendReceiveForm.get("fromAddressLine1").valid
    && this.sendReceiveForm.get("fromAddressLine2").valid
    && this.sendReceiveForm.get("fromTownCity").valid
    && this.sendReceiveForm.get("fromPostcode").valid
    && this.sendReceiveForm.get("fromContactNumber").valid
    && this.sendReceiveForm.get("fromEmail").valid
    && this.sendReceiveForm.get("fromSpecialInstructions").valid

    this.sendReceiveForm.markAllAsTouched();
    if (formValid && !this.saveDisabled) {
      var postcodeValue = this.sendReceiveForm.get("fromPostcode").value;
      var townCityValue = this.sendReceiveForm.get("fromTownCity").value;

      const newAddress = {
        title: this.sendReceiveForm.get("fromTitle").value,
        firstName: this.sendReceiveForm.get("fromFirstName").value,
        lastName: this.sendReceiveForm.get("fromLastName").value,
        company: this.sendReceiveForm.get("fromOptionalCompany").value,
        addressLine1: this.sendReceiveForm.get("fromAddressLine1").value,
        addressLine2: this.sendReceiveForm.get("fromAddressLine2").value,
        townCity: this.sendReceiveForm.get("fromTownCity").value,
        postcode: this.sendReceiveForm.get("fromPostcode").value,
        country: this.sendReceiveForm.get("fromCountry").value,
        countryCode: this.sendReceiveForm.get("fromCountry").value.countryCode,
        contactNumber: this.sendReceiveForm.get("fromContactNumber").value,
        email: this.sendReceiveForm.get("fromEmail").value,
        specialInstructions: this.sendReceiveForm.get("fromSpecialInstructions").value,
        isFavourite: this.addToFavourite
      };
  
      this.newAddressService.saveToAddressBook(newAddress).subscribe(
        () => {
          // this.snackbar.open("Successfully saved address");
          this.saveDisabled = true;
          this.successfullySaved.emit(true);
        }, (error) =>
        console.log(error));
    }
    }

    public formTouchedAndNoCountrySelected() {
      if (this.sendReceiveForm.get("fromCountry").touched && !this.sendReceiveForm.get("fromCountry").value)
      {
        return true;
      }
      return false;
    }

    public hasSelectedCountry(){
      if(this.sendReceiveForm.get("fromCountry").valid)
      {
        return true;
      }
      return false;
    }

    public getCountryNameForLanguage(country: Country): string
    {
       switch (this.translate.currentLang) {
         case "en":
           return country.countryNameEN;
         case "fo":
           return country.countryNameFO;
       }
    }

    public setCountryByCode(country: Country): Country {
      var countryControl: Country = this.sendReceiveForm.get("fromCountry").value;
      if (!!countryControl)
      {
        var countryCodeByControl = countryControl.countryCode;

        if (country.countryCode == countryCodeByControl)
        {
          this.sendReceiveForm.get("fromCountry").setValue(country, {emitEvent: false});
          if(country.countryCode == "FO")
          {
            this.fromFO = true;
          }
          else{
            this.fromFO = false;
          }
        }
      }
      return country;
    }

    public updateSpecificationForm(event: any)
    {
      if (!!event && event.value.countryCode != "FO")
      {
        if (this.fromFO)
        {
          var previousPostcode = this.sendReceiveForm.get("fromPostcode").value;
          if(!!previousPostcode){
            this.sendReceiveForm.get("fromPostcode").setValue(null);
            this.sendReceiveForm.get("fromTownCity").setValue(null);
          }
        }
        this.specificationForm.get('letterSize').reset();
        this.fromFO = false;
      }
      else{
        this.fromFO = true;
        var previousPostcode = this.sendReceiveForm.get("fromPostcode").value;
        var previousCity = this.sendReceiveForm.get("fromTownCity").value;
        var selectedPostcode = this.postcodes.find(pc => pc.postcode == previousPostcode || pc.city == previousCity)
        if(!!selectedPostcode)
        {
          this.sendReceiveForm.get("fromTownCity").setValue(selectedPostcode.city);
          this.sendReceiveForm.get("fromPostcode").setValue(selectedPostcode.postcode);
        }
      }
    }

    public setFromSelectedPostcode(postcode)
    {
      this.postcodeSelected = postcode;
      // this.sendReceiveForm.get("fromPostcode").setValue(postcode.postcode);
    }
    public setSelectedPostcodeFO(event)
    {
      var sourceControlName = event.source.ngControl.name;
      if(sourceControlName == "fromTownCity"){
        var selectedPostcode = this.postcodes.find(pc => pc.city == event.value)
        this.sendReceiveForm.get("fromPostcode").setValue(selectedPostcode.postcode);
      }
      else if(sourceControlName == "fromPostcode"){
        var selectedPostcode = this.postcodes.find(pc => pc.postcode == event.value)
        this.sendReceiveForm.get("fromTownCity").setValue(selectedPostcode.city);
      }
    }

    public toggleSave()
    { 
      var saveAddressControl = this.sendReceiveForm.get("fromSaveAddress");
      !!saveAddressControl.value ? saveAddressControl.reset() : saveAddressControl.setValue("true");
    }

    public toggleFavourite()
    {
      var favouriteControl = this.sendReceiveForm.get("fromIsFavouriteAddress")
      !!favouriteControl.value ? favouriteControl.reset() : favouriteControl.setValue("true");
    }

    public getFavouriteState(){
      var favouriteControl = this.sendReceiveForm.get("fromIsFavouriteAddress")
      return favouriteControl.value;
    }
}
