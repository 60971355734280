import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CountryService } from "../../../../shared/services/country.service";
import { Country } from "../../../../account/entities/Country";

@Injectable({ providedIn: 'root' })
export class CountryResolver implements Resolve<Array<Country>> {

    constructor(private countryService: CountryService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>| Promise<any>|any {
        return this.countryService.getAll();
    }
}