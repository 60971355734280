<div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
<div class="container">

    <div class="row align-center goods-value-origin-container">
        <div class="col-lg-6 col-md-12 goods-value-row align-center">
            <div class="align-center value-header">
                <h3 class="primary">{{'customs_calculator.value' | translate}}</h3>
                <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                    <a tabindex="0"></a><span>{{'customs_calculator.value_tooltip' | translate}}</span></label>
            </div>
            <mat-form-field class="goods-value-input align-form-field" [formGroup]="customsCalculatorForm"
                floatLabel="never" appearance="outline">
                <input matInput class="align-right" placeholder="{{'customs_calculator.value_placeholder' | translate}}" id="goodsValue" name="goodsValue"
                    formControlName="goodsValue" required>
                <mat-error *ngIf="hasErrors('goodsValue')"><app-field-error-message errorMessage="{{getError('goodsValue')}}"></app-field-error-message></mat-error>
            </mat-form-field>
            <span class="value-currency">DKK</span>
        </div>
        <div class="col-lg-6 col-md-12 origin-row">
            <div class="origin-row-contents" style="margin-top: 8px">
                <div class="align-center">
                    <h3 class="primary">{{'customs_calculator.origin' | translate}}</h3>
                    <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                        <a tabindex="0"></a><span>{{'customs_calculator.origin_tooltip' | translate}}</span></label>
                </div>
                <div class="origin-radio-buttons">
                    <div class="radio-button-spacing" [formGroup]="customsCalculatorForm">
                        <label>
                            <span class="span-radio-align">
                                <input class="vertical-align-radio-button" type="radio" name="eu" id="1"
                                    [(ngModel)]="originValue" formControlName="eu" [value]="0" (change)="euSelected($event)">
                            </span>
                            <span class="radio-text">{{'customs_calculator.eu_turkey' | translate}}</span>
                        </label>
                    </div>
                    <div class="radio-button-spacing" [formGroup]="customsCalculatorForm">
                        <label>
                            <span class="span-radio-align">
                                <input class="vertical-align-radio-button" type="radio" name="restOfTheWorld" id="restOfTheWorld"
                                    [(ngModel)]="originValue" formControlName="restOfTheWorld" [value]="1"
                                    (change)="restOfTheWorldSelected($event)">
                            </span>
                            <span class="radio-text">{{'customs_calculator.rest_of_world' | translate}}</span>
                        </label>
                    </div>
                </div>
                
            </div>
            <mat-error *ngIf="showOriginError" style="position: absolute; bottom: 0; width: 100%;" id="firstNameRequired"><app-field-error-message errorMessage="{{'customs_calculator.select_country_error' | translate}}"></app-field-error-message></mat-error>
        </div>
    </div>

    <div class="row align-center type-of-goods-row">
        <div class="col-lg-12 no-padding align-center category-parent-col">
            <div class="category-header-container">
                <div class="align-center">
                    <h3 class="primary">{{'customs_calculator.type_of_goods' | translate}}</h3>
                    <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                        <a tabindex="0"></a><span>{{'customs_calculator.type_of_goods_tooltip' | translate}}</span></label>
                    <h4 class="grey-text no-wrap category-header-subtitle">{{'customs_calculator.select_icon' | translate}}</h4>
                </div>
                <br class="hide-tablet">
                <div class="align-center hide-tablet">
                    <h4 class="grey-text no-wrap">{{'customs_calculator.select_icon' | translate}}</h4>
                </div>
            </div>
            <div class="category-button-container" *ngIf="!typeOfGoodSelected && !otherSelected">
                <button *ngFor="let typeOfGood of typeOfGoods" class="type-of-goods-button"
                    (click)="showSubCategoriesForSelectedGood(typeOfGood)">
                    <img class="category-icon" [src]="typeOfGood.IconPath"/>
                    <h4 class="category-button-text primary">{{getCurrentTranslation(typeOfGood.Name)}}</h4>
                </button>
            </div>
            <div class="category-selected-button-container" *ngIf="typeOfGoodSelected">
                <div class="row">
                    <div class="col-lg-12 mob-no-padding">
                        <div class="type-of-goods-selected-row">
                            <button class="type-of-goods-selected-button">
                                <button class="close-category" (click)="resetTypeOfGoods()">
                                    <img class="close-icon" src="/assets/icons/arrows/close_button_white.png" />
                                </button>
                                <div class="selected-button-padding">
                                    <img class="category-icon" [src]="selectedTypeOfGood.IconPath" style="pointer-events: none;"/>
                                    <h4 class="selected-category-button-text primary">{{getCurrentTranslation(selectedTypeOfGood.Name)}}</h4>
                                </div>
                            </button>
                            <div class="select-category-container">
                                <h4 class="grey-text select-category-title">{{'customs_calculator.select_category' | translate}}</h4>
                                <div class="triangle"></div>
                            </div>
                            <div class="row" style="width:100%">
                                <div class="col-xl-5 subcategory-radio-button"
                                    *ngFor="let subcategory of selectedTypeOfGood.Subcategories; let i=index">
                                    <label>
                                        <span class="radio-button-spacing radio-no-mobile-padding span-radio-align">
                                            <input class="vertical-align-radio-button" type="radio" name="subcategory" [id]="subcategory.TariffNo"
                                                (change)="showEstimatesSection(subcategory)">
                                        </span>
                                        <span class="radio-text">{{getCurrentTranslation(subcategory.Name)}}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="otherSelected">
                <div class="row selected-other-button">
                    <button class="type-of-goods-selected-button">
                        <button class="close-category" (click)="resetTypeOfGoods()">
                            <img class="close-icon" src="/assets/icons/arrows/close_button_white.png" />
                        </button>
                        <div class="selected-button-padding">
                            <h4 class="selected-category-button-text primary">{{getCurrentTranslation(selectedTypeOfGood.Name)}}</h4>
                        </div>
                    </button>
                    <div class="col-lg-4 col-md-4 align-center">
                        <h3 class="primary vertically-center">{{'customs_calculator.other_categories' | translate}}<a
                                href="https://voruskra.taks.fo/en/Sections" style="font-size: 18px;">{{'customs_calculator.taks_website' | translate}}</a>
                                {{'customs_calculator.enter_code' | translate}}</h3>
                    </div>
                    <div class="col-lg-6 col-md-6 vertically-center taks-field-container">
                        <div class="align-center">
                            <h3 class="taks-code-title">{{'customs_calculator.taks_code' | translate}}</h3>
                            <mat-form-field class="align-form-field taks-code-input" [formGroup]="customsCalculatorForm"
                                floatLabel="never" appearance="outline">
                                <input matInput class="align-right" placeholder="{{'customs_calculator.taks_code_placeholder' | translate}}" id="taksCode" name="taksCode"
                                    formControlName="taksCode" required>
                                    <mat-error *ngIf="hasErrors('taksCode')"><app-field-error-message errorMessage="{{getError('taksCode')}}"></app-field-error-message></mat-error>
                            </mat-form-field>
                            <button class="primary-button submit-taks-code-button padded"
                                (click)="getCategoryFromTaksCode()">{{'customs_calculator.submit' | translate}}</button>
                        </div>
                        <h3 *ngIf="languageIsFO()" class="category-name-from-taks">{{'customs_calculator.category' | translate}}: {{categoryFromTaksCode}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div *ngIf="enterWeight" class="col-lg-12 col-md-12 col-12 no-padding-left align-center weight-row">
            <h3 class="primary">{{'customs_calculator.quantity' | translate}}</h3>
            <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                <a tabindex="0"></a><span>{{'customs_calculator.quantity_tooltip' | translate}}</span></label>
            <mat-form-field class="weight-input" [formGroup]="customsCalculatorForm" floatLabel="never"
                appearance="outline">
                <input matInput class="align-right" placeholder="{{'customs_calculator.quantity_placeholder' | translate}}" id="weight" name="weight" formControlName="weight"
                    required>
            </mat-form-field>
            <h3 *ngIf="!otherSelected" class="primary kg">{{getUnitOfMeasurement()}}</h3>
            <h3 *ngIf="otherSelected" class="primary kg">Kg/L</h3>
        </div>
    </div>

    <div *ngIf="showCalculateEstimateButtons" class="row estimate-buttons-row">
        <div class="col-xl-3 col-lg-4 col-md-5 col-12 no-padding-left clear-all-parent">
            <button class="tertiary-button button-width clear-all-button" (click)="clearAll()">
                {{'customs_calculator.clear_all' | translate}}
            </button>
        </div>
        <div class="col-xl-3 offset-xl-6 col-lg-4 offset-lg-4 col-md-5 col-12 offset-md-2 calc-estimate-parent">
            <button class="primary-button button-width calc-estimate-button" (click)="calculateEstimate()">
                {{'customs_calculator.calculate_estimate' | translate}}
            </button>
        </div>
    </div>

    <div class="estimate-result-container" *ngIf="showEstimate">
        <div class="row">
            <div class="col-lg-9 offset-lg-3 col-12 estimate-result-sub-container">
                <div class="row">
                    <div class="col-md-6 col-12 estimate-disclaimer-col">
                        <h2 class="primary" style="margin-bottom: 10px">{{'customs_calculator.customs_estimate' | translate}}</h2>
                        <div class="">
                            {{'customs_calculator.estimate_warning' | translate}}<a href="https://voruskra.taks.fo/en/Sections"
                                target="_blank">{{'customs_calculator.estimate_warning_link' | translate}}</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 result-col">
                        <div class="result-text">
                            <h2 class="result-text-line">{{'customs_calculator.vat' | translate}}{{ customsEstimate.vatPercent }}% = {{ customsEstimate.vat | currency: 'DKK':''}} DKK</h2>
                            <h2 class="result-text-line">{{'customs_calculator.duty' | translate}} = {{ getDuty() | currency: 'DKK':''}} DKK</h2>
                            <h2 class="result-text-line total">{{'customs_calculator.total' | translate}} = {{ getDuty() + customsEstimate.vat  | currency: 'DKK':''}} DKK</h2>
                            <h2 class="result-text-line" style="white-space: nowrap;">{{'customs_calculator.duty_percent_freight_cost' | translate}} </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 offset-lg-3 col-12">
                <div class="row">
                    <div class="col-md-6 offset-md-6 col-12">
                        <button [routerLink]="['../../send-receive/send-receive']" class="primary-button book-freight-button">{{'customs_calculator.book_freight' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <h2 class="primary duty-costs-title">{{'customs_calculator.typical_duty_costs' | translate}}</h2>

    <div class="duty-cost-example-container row">
        <div *ngFor="let example of examples" class="col-3-padded col-lg-3 col-md-6 example" (click)="inputFieldsForExample(example)">
            <img class="image" [src]="example.ImagePath" />
            <div>
                <div class="border">
                    <h2 class="white category-title-margin">{{getStringForLanguage(example.Name)}}</h2>
                    <div *ngIf="example.VatPercent >= 0" [innerHTML]="getNestedStringForLanguage(example) | safeHtml" style="white-space: pre-line"></div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="duty-costs-body">
        <div class="left-align-text">
            {{'customs_calculator.gatt_exempty_notice_one' | translate}}<br><br>
            {{'customs_calculator.gatt_exempty_notice_two' | translate}}
        </div>
    </div> -->

    <!-- <div class="duty-cost-button-container">
        <div class="row button-container">
            <button class="col-md-6 col-sm-12 primary-button button-width taks-button" (click)="openTaksSite()">
                {{'customs_calculator.visit_taks_website' | translate}}
            </button>
            <button class="col-md-6 col-sm-12 secondary-button button-width customs-instructions-button">
                {{'customs_calculator.posta_customs_instructions' | translate}}
            </button>
        </div>
    </div> -->
</div>
<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>