import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { Address } from '../../../../account/entities/address';
import { DefaultShipmentDimensions } from '../../../entities/DefaultShipmentDimensions';
import { Order } from '../../../entities/Order';
import { environment } from '../../../../../environments/environment'
import { DownloadOutput } from '../../../entities/DownloadOutput'
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class SendReceiveService {

    constructor(private httpClient: HttpClient, private translateService: TranslateService) {
    }

    public GetDefaultShipmentDimensions(): Observable<DefaultShipmentDimensions> {
        return this.httpClient.get(`${environment.apiUrl}/api/SendReceiveOrder/DefaultShipmentDimensions`) as Observable<DefaultShipmentDimensions>;
    }

    public CreateOrder(order: Order): Observable<string> {
        return this.httpClient.post(`${environment.apiUrl}/api/SendReceiveOrder`, order, { responseType: 'text' }) as Observable<string>;
    }

    public GetOrder(orderReference: string): Observable<Order> {
        return this.httpClient.get(`${environment.apiUrl}/api/SendReceiveOrder?orderReference=${orderReference}&languageCode=${this.translateService.currentLang}`) as Observable<Order>;
    }

    public UpdateOrder(order: Order): Observable<string> {
        return this.httpClient.put(`${environment.apiUrl}/api/SendReceiveOrder`, order) as Observable<string>;
    }

    public dibsPay(url: string): Observable<any> {
        return this.httpClient.get(url) as Observable<string>;
    }

    public getDepotAddress(isSea: boolean): Observable<Address> {
        return this.httpClient.get(`${environment.apiUrl}/api/SendReceiveOrder/DepotAddress?isSea=${isSea}`) as Observable<Address>;
    }

    public getPaymentCodes(serviceTypeId: number, orderRef: string): Observable<string> {
        return this.httpClient.get(`${environment.apiUrl}/api/SendReceiveOrder/PaymentCodes?serviceId=${serviceTypeId}&orderRef=${orderRef}`) as Observable<string>;
    }

    public downloadReceipt(serviceTypeId: number, orderRef: string, langCode: string): Observable<DownloadOutput>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/SendReceiveOrder/DownloadReceipt?serviceType=${serviceTypeId}&orderReference=${orderRef}&languageCode=${langCode}`) as Observable<DownloadOutput>;
    }

    public downloadPrintLabel(orderRef: string): Observable<DownloadOutput>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/SendReceiveOrder/DownloadPrintLabel?orderRef=${orderRef}`) as Observable<DownloadOutput>;
    }

    public downloadCDS(orderBarcode: string): Observable<DownloadOutput>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/SendReceiveOrder/CDSPrint?barCode=${orderBarcode}`) as Observable<DownloadOutput>;
    }
}
