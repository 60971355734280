<div class="container parent-container">
    <div class="row-container">
        <div class="row mobile-margin">
            <div *ngIf="!!userDetails" class="col-lg-3 col-md-6 col-12">
                <h2 class="header">{{'send_receive.send_receive.step_four.your_details' | translate}}</h2>
                <p>{{userDetails.title}} {{userDetails.firstName}} {{userDetails.lastName}}</p>
                <p>{{userDetails.addressLine1}}</p>
                <!-- <p>{{userDetails.addressLine2}}</p> -->
                <p>{{userDetails.townCity}}</p>
                <p>{{userDetails.postcode}}</p>
                <p>{{getCountryNameByCode(userDetails.country.countryCode)}}</p>
                <span>{{userDetails.contactNumber}}</span>
            </div>
            <div class="col-lg-3 col-md-6 col-12 mobile-padding-top">
                <h2 class="header">{{'send_receive.send_receive.step_four.service' | translate}}</h2>
                <div style="padding-bottom: 30px" *ngIf="!!serviceOption">
                    <p>{{getServiceOptionName()}}</p>
                    <button *ngIf="!!item1Link" class="secondary-button padded button-max-width"
                        (click)="openRouteInNewWindow(item1Link.Url)">{{item1Link.Title}}</button>
                </div>
                <!-- <h2 class="header">{{'send_receive.send_receive.step_four.tracking_id' | translate}}
                </h2>
                <p>{{userDetails.trackingCode}}</p> -->
            </div>
            <div *ngIf="!fromDepot" class="col-lg-3 col-md-6 col-12">
                <h2 class="header">{{'send_receive.send_receive.step_four.from' | translate}}</h2>
                <p>{{getFromName()}}</p>
                <p>{{addressForm.get('fromAddressLine1').value}}</p>
                <!-- <p>{{addressForm.get('fromAddressLine2').value}}</p> -->
                <p>{{addressForm.get('fromTownCity').value}}</p>
                <p>{{addressForm.get('fromPostcode').value}}</p>
                <p>{{getCountryName(addressForm.get('fromCountry').value)}}</p>
                <p>{{addressForm.get('fromContactNumber').value}}</p>
                <!-- <p>{{addressForm.get('fromSpecialInstructions').value}}</p> -->
            </div>
            <div *ngIf="fromDepot && !!depotAddress" class="col-lg-3 col-md-6 col-12 mobile-padding-top">
                <h2 class="header">{{'send_receive.send_receive.step_four.from' | translate}}</h2>
                <p>{{depotAddress.firstName}}</p>
                <p>{{depotAddress.addressLine1}}</p>
                <p>{{depotAddress.townCity}}</p>
                <p>{{depotAddress.postcode}}</p>
                <p>{{getCountryNameByCode(depotAddress.countryCode)}}</p>
                <p>{{depotAddress.contactNumber}}</p>
            </div>
            <div *ngIf="!toDepot" class="col-lg-3 col-md-6 col-12 mobile-padding-top">
                <h2 class="header">{{'send_receive.send_receive.step_four.to' | translate}}</h2>
                <p>{{addressForm.get('toFirstName').value}}
                    {{addressForm.get('toLastName').value}}
                </p>
                <p>{{addressForm.get('toAddressLine1').value}}</p>
                <!-- <p>{{addressForm.get('toAddressLine2').value}}</p> -->
                <p>{{addressForm.get('toTownCity').value}}</p>
                <p>{{addressForm.get('toPostcode').value}}</p>
                <p>{{getCountryName(addressForm.get('toCountry').value)}}</p>
                <p>{{addressForm.get('toContactNumber').value}}</p>
            </div>
            <div *ngIf="toDepot" class="col-lg-3 col-md-6 col-12 mobile-padding-top">
                <h2 class="header">{{'send_receive.send_receive.step_four.to' | translate}}</h2>
                {{'send_receive.send_receive.step_four.depot' | translate}}
            </div>
        </div>
    </div>
    <div class="row-container">
        <div class="row mobile-margin">
            <div class="col-lg-3 col-md-4 col-12">
                <h2 class="header">{{'send_receive.send_receive.step_four.your_ref' | translate}}</h2>
                <div style="padding-bottom: 30px">{{specificationForm.get('referenceNumber').value}}</div>
            </div>
            <div class="col-lg-3 col-md-4 col-12 mobile-padding-top">
                <div *ngIf="!!serviceOption && serviceOption.ProductType != 4 && serviceOption.ProductType !=8  && serviceOption.ProductType != 10">
                    <h2 class="header">{{'send_receive.send_receive.step_four.collection_date' | translate}}</h2>
                    <div style="padding-bottom: 30px">{{getCollectionDate()}}</div>
                </div>
                <h2 class="header">{{'send_receive.send_receive.step_four.estimated_delivery_date' | translate}}
                </h2>
                <p>{{getEstimatedDeliveryDate()}}</p>
            </div>
            <div class="col-lg-3 col-md-4 col-12 mobile-padding-top">
                <div *ngIf="!!items">
                    <h2 class="header">{{'send_receive.send_receive.step_four.size' | translate}}</h2>
                    <div style="padding-bottom: 30px">{{items.length}} x {{'send_receive.send_receive.step_four.parcels'
                        | translate}} <span *ngIf="isDomestic">({{getCostPerItem()}}
                            {{'send_receive.send_receive.service_options.price_per_unit_text' |
                            translate}})</span></div>
                    <div *ngIf="!this.specificationForm.get('totalParcelVolume')" style="padding-bottom: 16px">
                        <div *ngFor="let item of items">
                            <p>L{{item.length}}cm W{{item.width}}cm H{{item.height}}cm</p>
                        </div>
                    </div>
                    <div *ngIf="!!this.specificationForm.get('totalParcelVolume')" style="padding-bottom: 16px">
                        <h2 class="header">{{'send_receive.send_receive.step_four.volume' | translate}}</h2>
                        {{this.specificationForm.get('totalParcelVolume').value}}m³
                    </div>
                </div>
                <h2 class="header">{{'send_receive.send_receive.step_four.weight' | translate}}</h2>
                <div style="padding-bottom: 30px">{{specificationForm.get('totalWeight').value}}
                    {{getWeightMeasurement()}}</div>
            </div>
            <div class="col-lg-3 col-md-4 col-12 mobile-padding-top">
                <div *ngIf="customsClearanceSelected">
                    <h2 class="break-all">{{'send_receive.send_receive.step_four.managed_customs_clearance' |
                        translate}}</h2>
                    <p class="break-all">{{'send_receive.send_receive.step_four.managed_customs_clearance_included' |
                        translate}} - {{serviceOption.ManagedCustomCost}} DKK</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row-container">
        <div class="row mobile-margin">
            <div class="col-lg-3 col-md-4 col-12 mobile-padding-top">
                <div class="row">
                    <h2 class="header left-padding-15">{{'send_receive.send_receive.step_four.key_terms' | translate}}
                    </h2>
                    <label class="tooltipSource"><img class="information-icon"
                            src="/assets/icons/i_information@3x.png" />
                        <a tabindex="0"></a><span>{{'send_receive.send_receive.step_four.key_terms_tooltip' |
                            translate}}</span></label>
                </div>
                <p>{{'send_receive.send_receive.step_four.description' | translate}}</p>
                <button *ngIf="!!item2Link" class="secondary-button padded button-max-width"
                    (click)="openRouteInNewWindow(item2Link.Url)">{{item2Link.Title}}</button>
            </div>
            <div class="col-lg-3 col-md-4 col-12 mobile-padding-top">
                <div class="row mobile-margin left-padding-15">
                    <h2 class="header">{{'send_receive.send_receive.step_four.insurance' | translate}}</h2> <label
                        class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                        <a tabindex="0"></a><span
                            class="insurance-tooltip">{{'send_receive.send_receive.step_four.insurance_tooltip' |
                            translate}}</span></label>
                </div>
                <p *ngIf="!!serviceOption && insuranceSelected && serviceOption.InsuranceCost >= 0;else nocover">
                    {{this.serviceOption.InsuranceCost}} DKK</p>
                <ng-template #nocover>
                    <p>{{'send_receive.send_receive.step_four.no_insurance_cover' | translate}}</p>
                </ng-template>
                <button *ngIf="insuranceSelected && !!item3Link" class="secondary-button padded button-max-width"
                    (click)="openRouteInNewWindow(item3Link.Url)">{{item3Link.Title}}</button>
            </div>
        </div>
    </div>
    <div class="row-container">
        <div class="row mobile-margin">
            <div class="col-lg-6 col-md-12">
                <h2 class="header">{{'send_receive.send_receive.step_four.comments' | translate}}</h2>
                <p>{{orderDetailsForm.get('comments').value}}</p>
            </div>
            <div class="col-lg-6 col-md-12 mobile-padding-top">
                <!-- <h2 class="header">{{'send_receive.send_receive.step_four.country_of_origin' | translate}}</h2>
                <div style="padding-bottom: 30px">Country name</div> -->
                <h2 class="header">{{'send_receive.send_receive.step_four.shipment_type' | translate}}</h2>
                <div *ngIf="!!shipmentType" style="padding-bottom: 30px">{{getCustomsShipmentTypeName(shipmentType)}}
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5">
                        <div *ngIf="!!uploadedFiles">
                            <h2>{{'send_receive.send_receive.step_four.customs_uploads' | translate}}</h2>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <div *ngIf="!!uploadedFiles">
                            <h2>{{'send_receive.send_receive.step_four.company_name_seller_of_goods' | translate}}</h2>
                        </div>
                    </div>
                </div>

                <div *ngIf="!!uploadedFiles">
                    <div *ngFor="let file of uploadedFiles">
                        <div class="row">
                            <div class="col-lg-5 col-md-5 align-center">
                                <span>{{file.fileName}}</span>
                            </div>
                            <div class="col-lg-5 col-md-5 align-center">
                                {{file.companyName}}
                            </div>
                            <div class="col-lg-2 col-md-2 align-center delete-button-container">
                                <button class="delete-file-button" (click)="deleteFile(file)">
                                    <img class="delete-icon" src="/assets/icons/arrows/close@3x.png" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row flex-end col-lg-12">
        <h1>{{'send_receive.send_receive.step_four.total' | translate}}: {{totalCost | currency: 'DKK':''}} DKK
        </h1>
    </div>
    <div *ngIf="!!serviceOption && serviceOption.VATCost > 0" class="row flex-end col-lg-12">
        <h2>{{'send_receive.send_receive.step_four.total_vat' | translate}}: {{getVATCost() | currency: 'DKK':''}} DKK
        </h2>
    </div>
    <div class="row flex-end col-lg-12" [formGroup]="confirmationForm">
        <mat-checkbox formControlName="registerForNotifications">
            <div class="tickCheckbox">{{'send_receive.send_receive.step_four.register_notifications' | translate}}</div>
        </mat-checkbox>
    </div>
    <div class="row flex-end col-lg-12" [formGroup]="confirmationForm">
        <mat-checkbox formControlName="agreeToTermsAndConditions">
            <div class="tickCheckbox">{{'send_receive.send_receive.step_four.agree_terms_and_conditions' | translate}}
            </div>
        </mat-checkbox>
    </div>
</div>