import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../../account/services/role.service';
import { GeneratedCodeDialogComponent } from '../generated-code-dialog/generated-code-dialog/generated-code-dialog.component';
import { NewShoppingListDialogComponent } from '../new-shopping-list-dialog/new-shopping-list-dialog.component';
import { ShoppingListService } from '../services/shopping-list-service';
import { AuthenticationService } from '../../account/services/authentication.service';
import { ShoppingListsLoggedOut } from '../models/ShoppingListLoggedOut';
import * as _ from "lodash";
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-all-shopping-lists',
  templateUrl: './view-all-shopping-lists.component.html',
  styleUrls: ['./view-all-shopping-lists.component.scss']
})
export class ViewAllShoppingListsComponent implements OnInit {

  public allShoppingLists: Array<any>;
  public activeList: any;
  public allFilteredList: Array<any>;
  public allLoggedOutLists: ShoppingListsLoggedOut;
  public filteredListsLoggedOut: ShoppingListsLoggedOut;
  public activeLoggedOutList: any;

  constructor(private authService: AuthenticationService, private shoppingListService: ShoppingListService, public translateService: TranslateService, public roleService: RoleService, public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    if (this.isLoggedIn())
    {
      this.getAllShoppingListsInit();
    }
    else {
      var loggedOutLists = localStorage.getItem('shoppingLists');
      if (!!loggedOutLists) {
        this.allLoggedOutLists = JSON.parse(loggedOutLists);
        this.filteredListsLoggedOut = this.allLoggedOutLists;
        console.log(this.allLoggedOutLists)
        //var indexToUpdate = shoppingListsObj.shoppingLists.findIndex(x => x.name == this.loggedOutList.name);
        // this.allLoggedOutLists.shoppingLists[indexToUpdate] = this.loggedOutList;
        // localStorage.setItem("shoppingLists", JSON.stringify(this.allLoggedOutLists));
      }
    }
  }

  public updateActiveList(event: any): void {
    if (this.isLoggedIn())
    {
      if (event.value.shoppingListName === "All")
      {
        this.getAllShoppingLists();
        return;
      }
      else {
        this.shoppingListService.getAll().subscribe(x => {
          this.allFilteredList = x;
          this.allFilteredList.forEach(y => {
            this.shoppingListService.getProductsForListNoImage(y.shoppingListId).subscribe(z => {
              y.productItemNames = z.map(a => a.productItemName);
              y.productIds = z.map(a => a.productId);
            });
          });
          this.allFilteredList = this.allFilteredList.filter(x => x.shoppingListId === event.value.shoppingListId);
          console.log(this.allFilteredList);
          this.getProductsForList(this.activeList);
        });
      }
    }
    else {
      this.activeLoggedOutList = event.value;
      console.log(this.filteredListsLoggedOut);
      console.log(this.allLoggedOutLists);
      this.filteredListsLoggedOut = _.cloneDeep(this.allLoggedOutLists);
      this.filteredListsLoggedOut.shoppingLists = this.filteredListsLoggedOut.shoppingLists.filter(x => x.name == event.value.name);
      console.log(this.filteredListsLoggedOut);
      console.log(this.allLoggedOutLists);
    }
  }

  public removeShoppingList(listId: any): void {
    this.shoppingListService.deleteShoppingList(listId).subscribe(x => {
      this.getAllShoppingListsInit();
    });
  }

  public removeShoppingListLoggedOut(list: any) {
    var loggedOutLists = localStorage.getItem('shoppingLists');
    if (!!loggedOutLists) {
      var shoppingListsObj = JSON.parse(loggedOutLists);
      shoppingListsObj.shoppingLists.forEach( (item, index) => {
        if(item.name === list.name) shoppingListsObj.shoppingLists.splice(index,1);
      });
      localStorage.setItem("shoppingLists", JSON.stringify(shoppingListsObj));
      if (shoppingListsObj.shoppingLists.length === 0)
      {
        localStorage.removeItem("shoppingLists");
      }
      this.allLoggedOutLists = shoppingListsObj;
      this.filteredListsLoggedOut = shoppingListsObj;
    }
  }

  // public createShareLink(list: any): void {
  //   var stringBuilder = "";
  //   if (list && list.productItemNames.length > 0)
  //   {
  //     stringBuilder = stringBuilder.concat(`${list.shoppingListName}:\n`);
  //     list.productItemNames.forEach(x => stringBuilder = stringBuilder.concat(`${x}\n`));
  //   }

  //   const shareFilter = {
  //     "LanguageCode": `${this.translateService.currentLang}`,
  //     "Role": `${this.roleService.role()}`,
  //     "ShoppingListId": list.shoppingListId
  // }

  //   this.shoppingListService.shareList(shareFilter).subscribe(x => {
  //     console.log(x);
  //     const shareDialogRef = this.dialog.open(GeneratedCodeDialogComponent, {
  //       height: '600px',
  //       width: '730px',
  //       data: {
  //         link: x,
  //         plainText: stringBuilder
  //       }
  //     });
  //   });
  // }

  public createShareLink(list: any): void {
    var stringBuilder = "";
    if (this.isLoggedIn() && list && list.productItemNames.length > 0)
    {
      stringBuilder = stringBuilder.concat(`${list.shoppingListName}:\n`);
      list.productItemNames.forEach(x => stringBuilder = stringBuilder.concat(`${x}\n`));
    }
    else
    {
      stringBuilder = stringBuilder.concat(`${list.name}:\n`);
      list.products.map(z => z.productName).forEach(x => stringBuilder = stringBuilder.concat(`${x}\n`));
    }

    if (this.isLoggedIn()) {
      const shareFilter = {
        "LanguageCode": `${this.translateService.currentLang}`,
        "Role": `${this.roleService.role()}`,
        "ShoppingListId": this.activeList.shoppingListId
      }

      this.shoppingListService.shareList(shareFilter).subscribe(x => {
        const shareDialogRef = this.dialog.open(GeneratedCodeDialogComponent, {
          height: '600px',
          width: '730px',
          data: {
            link: x,
            plainText: stringBuilder
          }
        });
      });
    }
    else if (list && stringBuilder !== "")
      {
        const shareDialogRef = this.dialog.open(GeneratedCodeDialogComponent, {
          height: '600px',
          width: '730px',
          data: {
            link: null,
            plainText: stringBuilder
          }
        })
      }
  }

  // public addNewShoppingList(): any {
  //   const shoppingDialogref = this.dialog.open(NewShoppingListDialogComponent, {
  //     height: '170px',
  //     width: '580px'
  //   });

  //   shoppingDialogref.afterClosed().subscribe(result => {
  //     if (result)
  //     {
  //       this.getAllShoppingListsInit();
  //     }
  //   });
  // }

  public addNewShoppingList(): any {
    const shoppingDialogref = this.dialog.open(NewShoppingListDialogComponent, {
      height: '170px',
      width: '580px'
    });

    shoppingDialogref.afterClosed().subscribe(result => {
      if (result) {
        if (this.isLoggedIn()) {
          this.getAllShoppingListsInit();
        }
        else {
          var loggedOutLists = localStorage.getItem('shoppingLists');
          if (!!loggedOutLists) {
            this.allLoggedOutLists = JSON.parse(loggedOutLists);
            this.filteredListsLoggedOut = this.allLoggedOutLists;
            console.log(this.allLoggedOutLists)
        }
      }
    }
    });

  }

  private getAllShoppingLists(): void {
    this.shoppingListService.getAll().subscribe(x => {
      this.allFilteredList = x;
      this.allFilteredList.forEach(y => {
        this.shoppingListService.getProductsForListNoImage(y.shoppingListId).subscribe(z => {
          y.productItemNames = z.map(a => a.productItemName);
          y.productIds = z.map(a => a.productId);
        });
      });
      console.log(this.allFilteredList);
      this.activeList = this.allShoppingLists[0];
    });
  }

  private getAllShoppingListsInit(): void {
    this.shoppingListService.getAll().subscribe(x => {
      this.allFilteredList = x;
      this.allFilteredList.forEach(y => {
        this.shoppingListService.getProductsForListNoImage(y.shoppingListId).subscribe(z => {
          y.productItemNames = z.map(a => a.productItemName);
          y.productIds = z.map(a => a.productId);
        });
      });
      console.log(this.allFilteredList);
      this.allShoppingLists = [{shoppingListName: "All"}];
      this.allShoppingLists = this.allShoppingLists.concat(x);
      this.activeList = this.allShoppingLists[0];
    });
  }

  private getProductsForList(list: any): void {
    this.shoppingListService.setActiveList(list.shoppingListId).subscribe(y => {
    })
  }

  public navigateToViewList(list: any): void {
    var id = this.isLoggedIn() ? list.shoppingListId : null
    var loggedOutList = this.isLoggedIn() ? null : list;
    if (!id && !loggedOutList)
    {
      return;
    }

    this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/shopping-services/view-shopping-list/${id}`, { list: JSON.stringify(loggedOutList) }]);
  }

  public isLoggedIn() {
    return this.authService.isLoggedIn();
  }
}
