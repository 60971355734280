import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from '../../send-receive/services/payment.service';
import { ServiceType } from '../../shared/price-matrix/enums/ServiceType';
import { CCSSubmission } from '../entities/CCSSubmission';

@Component({
  selector: 'app-ccsconfirmation',
  templateUrl: './ccsconfirmation.component.html',
  styleUrls: ['./ccsconfirmation.component.scss']
})
export class CCSConfirmationComponent implements OnInit {

  @Input()
  transactionRef: string;

  @Input()
  ccsSubmission: CCSSubmission;

  downloadingInvoice: boolean = false;

  downloadingReceipt: boolean = false;

  constructor(private paymentService: PaymentService, private translateService: TranslateService) { }

  invoiceError: string;

  ngOnInit(): void {
  }

  public downloadInvoice()
  {
    this.invoiceError = undefined;
    this.downloadingInvoice = true;
    this.paymentService.downloadInvoice(ServiceType.Customs, this.ccsSubmission.wisePONumber, " ").subscribe(x => {

      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingInvoice = false;
    }, (y) => {
      this.downloadingInvoice = false;
      this.invoiceError = y.error;
    })
  }

  public downloadReceipt() {
    this.downloadingReceipt = true;
    //JT 2023 - CCS id is passed here to resolve an issue, despite passing PO number elsewhere
    this.paymentService.downloadReceipt(ServiceType.Customs, this.ccsSubmission.ccsId, " ", this.translateService.currentLang).subscribe(x => {

      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingReceipt = false;
    }, (error) => {
      this.downloadingReceipt = false;
    })
  }

}
