<!-- Ignore title as this is going to be injected via static content -->
<div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>

<div class="container login-container">
    <div class="row">
        <div class="col-lg-8 col-12 login-column">
            <h3 class="primary main-sub-header">{{ 'private_login.existing_users' | translate }}</h3>
            <div class="login-body full-width">{{ 'private_login.login_below' | translate }}</div>
            <div class="row">
                <!-- <div class="col-md-6 col-12 samleikin-col">
                    <h4 class="dark-blue sub-header">{{ 'private_login.login_samleikin' | translate }}</h4> -->
                    <!-- <button class="samleikin-button" (click)="samleikinLogin()"><img
                            class="samleikin-img" src="/assets/icons/login/samleikin_logo.png" /></button> -->
                <!-- </div> -->
                <div class="col-md-8 col-12 login-col">
                    <h4 class="dark-blue sub-header">{{ 'private_login.login_with_email_title' | translate }}</h4>
                    <mat-form-field class="full-width align-form-field" [formGroup]="passwordForm" floatLabel="never"
                        appearance="outline">
                        <input matInput placeholder="{{ 'private_login.enter_email_placeholder' | translate }}" id="email" name="email"
                            formControlName="email" required>
                        <mat-error *ngIf="hasErrors('email')"><app-field-error-message errorMessage="{{getError('email')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                    <h4 class="dark-blue password-sub-header sub-header">{{ 'private_login.password_title' | translate }}</h4>
                    <mat-form-field class="full-width align-form-field" [formGroup]="passwordForm" floatLabel="never"
                        appearance="outline">
                        <input type="password" matInput placeholder="{{ 'private_login.password_placeholder' | translate }}" (keyup.enter)="login()" id="password" name="password"
                            formControlName="password" required>
                        <mat-error *ngIf="hasErrors('password')"><app-field-error-message errorMessage="{{getError('password')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                    <button class="primary-button login-button" [disabled]="loading" type=submit (click)="login()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Login</button>
                    <a routerLink="../forgotten-password" class="forgotten-password">{{ 'private_login.forgotten_password' | translate }}</a>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-12 new-user-column">
           <h3 class="primary main-sub-header">{{ 'private_login.new_users_register' | translate }}</h3>
           <div class="login-body full-width">{{ 'private_login.additional_text' | translate }}</div>
           <h4 class="dark-blue sub-header">{{ 'private_login.not_registered' | translate }}</h4>
           <div class="row no-indent-row">
                <div class="col-lg-12 col-md-6 register-button-container">
                    <button class="primary-button register-button" [routerLink]="['../register']">{{ 'private_login.register_as_personal_user' | translate }}</button>
                </div>
           </div>
           <h3 class="primary business-user-sub-header sub-header">{{ 'private_login.business_user' | translate }}</h3>
           <div class="row no-indent-row">
               <div class="col-lg-12 col-md-6 business-login-button-container">
                <button class="tertiary-button business-login-button" [routerLink]="['../../business/login']">{{ 'private_login.business_login' | translate }}</button>
               </div>
               <div class="col-lg-12 col-md-6 business-register-button-container">
                <button class="tertiary-button full-width" [routerLink]="['../../business/register']">{{ 'private_login.business_register' | translate }}</button>
               </div>
           </div>
        </div>
    </div>
</div>
<div class="container">
    <app-error-message *ngIf="loginFailed" errorMessage="{{'error.invalid_username_password' | translate}}"></app-error-message>
</div>

<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>