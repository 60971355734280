import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from './account/services/authentication.service';
import { User } from './account/entities/user';
import { StaticContentService } from './shared/services/static-content.service';
import { filter } from 'rxjs/operators';
import { RoleService } from './account/services/role.service';
import { CookieService } from './shared/services/cookie.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy {

  title = 'PostaWeb';

  user: User;

  showHeaderFooter: boolean = false;

  currentLang: any;

  interval: any;

  @Input()
  loading: boolean

  // setContent: boolean = false;

  role: string;

  constructor(private translate: TranslateService,
    private _router: Router,
    private accountService: AuthenticationService,
    private roleService: RoleService,
    private staticContentService: StaticContentService,
    private ref: ChangeDetectorRef,
    private cookieService: CookieService) {

    // this.translate.addLangs(['en', 'fo']);

    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fo/) ? browserLang : 'en');

    this._router.events.pipe(
      filter(e => e instanceof NavigationEnd)).subscribe((val) => {
        //Don't set role if logged in and has role set
        if (this.accountService.isLoggedIn() && !!localStorage.getItem('role')) {
          return;
        }

        var splitPaths = this._router.url.split('/');
        if (!!splitPaths && splitPaths.length > 1) {
          //default to private if the path isn't known
          if (splitPaths[2] == "private" || splitPaths[2] == "business") {
            this.roleService.setRole(splitPaths[2]);
          }
          else {
            this.roleService.setRole("private")
          }
        }
        else {
          this.roleService.setRole("private");
        }

        // this.isLoggedIn = !!currentUser && !!this.cookieService.getCookie('token')
      });

    if (localStorage.getItem('language')) {
      this.translate.setDefaultLang(localStorage.getItem('language'));
      this.translate.use(localStorage.getItem('language'));
    } else {
      this.translate.setDefaultLang('fo');
      this.translate.use('fo');
      localStorage.setItem("language", "fo");
    }

    this.accountService.user.subscribe(x => this.user = x);

    // on route change to '/login', set the variable showHead to false
    // _router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     if (event['url'].indexOf('/login') >= 0) {
    //       this.showHeaderFooter = false;
    //     } else {
    //       this.showHeaderFooter = true;
    //     }
    //   }
    // });

    this.currentLang = this.translate.currentLang;
  }

  public ngOnInit() {

    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {

        //if role doesn't match then redirect to correct role
        var urlSplit = this._router.url.split('/');
        var loggedInUserType = localStorage.getItem("loginUserType");
        var loggedIn = !!this.cookieService.getCookie('token');

        if (loggedIn && !!urlSplit && urlSplit.length > 2 && loggedInUserType != urlSplit[2]) {
          //reconstruct the url with the current logged in role

          var url = `${urlSplit[1]}/${loggedInUserType}/`;
          for (let i = 3; i < urlSplit.length; i++) {
            url = url.concat(`${urlSplit[i]}/`);
          }
          this._router.navigate([`${url}`]);
        }

        if (!this.staticContentService.staticContent) {
          this.setStaticContent();
        }
        else {
          this.staticContentService.getHash().subscribe(x => {
            if (this.staticContentService.staticContentHash != x) {
              this.setStaticContent();
            }
          });
        }
        this._router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    //this.setStaticContent();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      var path = location.pathname;
      const newLang = this.translate.currentLang;

      //Replace below with regExp in the future (i'm doing a demo the next day and I forgot how to regex!)
      if (path.length > 4 && this.currentLang != event.lang) {
        const pathSubString = path.substring(0, 4);

        if (pathSubString == "/en/" || pathSubString == "/fo/" || pathSubString == "/gl/" || pathSubString == "/da/") {
          path = path.replace(/^.{4}/g, `/${newLang}/`)

          window.history.pushState("", "", path);
          this.currentLang = event.lang;
        }
      }
      else if (path.length > 4 && this.currentLang == event.lang) {
        var pathSubString = path.substring(0, 4);
        pathSubString = pathSubString.substring(1, pathSubString.length - 1);

        if (pathSubString == "en" || pathSubString == "fo" || pathSubString == "gl" || pathSubString == "da") {
          this.translate.use(pathSubString);
        }
      }
    });
  }

  public ngOnDestroy() {
    clearInterval(this.interval);
  }

  public setStaticContent() {

    // this.interval = setInterval(() => {
    var staticContent = this.staticContentService.staticContent;

    if (!!staticContent) // && !this.setContent
    {
      var axdFiles = staticContent.GlobalCss.split(";");
      axdFiles.forEach(x => {
        this.staticContentService.loadjscssfile(x, "css");
        this.ref.detectChanges();
      });

      var jsAxdFiles = staticContent.GlobalJs.split(";");
      jsAxdFiles.forEach(x => {
        this.staticContentService.loadjscssfile(x, "js");
        this.ref.detectChanges();
      });
      // clearInterval(this.interval);
      // this.interval = undefined;
      //this.setContent = true;
      //   }
      // }, 500);
    }
  }

  logout() {
    this.accountService.logout();
  }

}

