import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Country } from '../../account/entities/Country';
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class CountryService {

    countries: Array<string> = new Array<string>();

    constructor(private httpClient: HttpClient) {
    }

    public getAll(): Observable<Array<Country>> {
        return this.httpClient.get(`${environment.apiUrl}/api/AddressBook/Countries`) as Observable<Array<Country>>;
    }
}