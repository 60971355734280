import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { PriceMatrixService } from '../../../../shared/price-matrix/services/price-matrix.service';

@Injectable({ providedIn: 'root' })
export class TaxResolver implements Resolve<number> {

    constructor(private priceMatrixService: PriceMatrixService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>| Promise<any>|any {
        return this.priceMatrixService.getTaxPercentage();
    }
}