<div class="specification-container">
    <div class="row align-center height-container-100">
        <div class="col-lg-2 col-md-3 col-12 mobile-margin-top">
            <h3 class="primary row-title">{{'send_receive.send_receive.step_two.your_ref' | translate}}</h3>
        </div>
        <mat-form-field class="col-lg-5 col-md-9 col-12 align-form-field full-width-form-field error-field"
            [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
            <input matInput placeholder="{{'send_receive.send_receive.step_two.your_ref_placeholder' | translate}}"
                id="referenceNumber" name="referenceNumber" formControlName="referenceNumber">
                <mat-error *ngIf="hasErrors('referenceNumber')">
                    <app-field-error-message errorMessage="{{getError('referenceNumber')}}"></app-field-error-message>
                </mat-error>
        </mat-form-field>
    </div>
</div>

<div class="specification-container">
    <div class="row align-center height-container-100">
        <div class="col-lg-2 col-md-3 col-12 align-center mobile-margin-top">
            <h3 class="primary row-title">{{'send_receive.send_receive.step_two.total_weight' | translate}}</h3>
            <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                <a tabindex="0"></a><span>{{'send_receive.send_receive.step_two.weight_tooltip' |
                    translate}}</span></label>
        </div>
        <div class="col-lg-5 col-md-9 col-12 align-center error-field">
            <mat-form-field class="align-form-field weight-input" [formGroup]="sendReceiveForm"
            floatLabel="never" appearance="outline">
            <input matInput class="align-right" id="totalWeight" name="totalWeight" formControlName="totalWeight"
                matInput placeholder="0" required>
            <mat-error *ngIf="hasErrors('totalWeight')">
                <app-field-error-message errorMessage="{{getError('totalWeight')}}"></app-field-error-message>
            </mat-error>
        </mat-form-field>
        <button mat-button [ngClass]="isKg ? 'dark-blue-button weight-button' : 'grey-button weight-button'" (click)="toggleIsKg(true)">kg</button>
        <button mat-button [ngClass]="!isKg ? 'dark-blue-button weight-button' : 'grey-button weight-button'" (click)="toggleIsKg(false)">gr</button>
        </div>
    </div>
</div>

<div class="specification-size-options-container">
    <!-- <div *ngIf="isFromCountryFO()" class="row" style="padding-bottom: 20px;">
        <div class="col-lg-2 col-md-3 col-12 align-center">
            <h3 class="primary">{{'send_receive.send_receive.step_two.size' | translate}}</h3>
            <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                <a tabindex="0"></a><span>{{'send_receive.send_receive.step_two.size_tooltip' |
                    translate}}</span></label>
        </div>
        <div class="col-lg-5 col-md-9 col-12 icon-input-container full-width-form-field" [formGroup]="sendReceiveForm">
            <img class="size-icons" src="/assets/icons/new-order/letter.png" />
            <mat-form-field class="select-container float-never" appearance="outline" [formGroup]="sendReceiveForm">
                <mat-label class="mat-select-value-text">{{'send_receive.send_receive.step_two.letter_placeholder' |
                    translate}}</mat-label>
                <mat-select formControlName="letterSize" disableOptionCentering panelClass="select-panel" required
                    (selectionChange)="setSizeValue($event)">
                    <mat-option *ngFor="let size of letterSizes" [value]="size">
                        {{getLetterSizeNameForLanguage(size.Name, size.Description)}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div> -->

    <div class="row align-center" style="padding-bottom: 30px;">
        <h3 class="primary col-lg-2 col-md-3 col-12 mobile-margin-top size-title">{{'send_receive.send_receive.step_two.size' | translate}}</h3>
        <div class="col-lg-10 col-md-9 col-12 icon-input-container align-center">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="row align-center" style="padding-left: 20px;">
                    <mat-form-field class="align-center align-form-field parcel-input"
                        [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                        <input matInput class="align-right" placeholder="1" id="noOfItems" name="noOfItems"
                            formControlName="noOfItems" [formControl]="sendReceiveForm.get('noOfItems')"
                            oninput="validity.valid||(value='');" required>
                        <mat-error *ngIf="hasErrors('noOfItems')">
                            <!-- (ngModelChange)="onChange($event)" -->
                            <app-field-error-message errorMessage="{{getError('noOfItems')}}">
                            </app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                    <button mat-button [ngClass]="isItems ? 'dark-blue-button items-button' : 'grey-button items-button'" (click)="toggleIsItems(true)">{{'send_receive.send_receive.step_two.items' | translate}}</button>
                    <button *ngIf="!isFromToCountryFO()" mat-button [ngClass]="!isItems ? 'dark-blue-button items-button' : 'grey-button items-button'" (click)="toggleIsItems(false)">{{'send_receive.send_receive.step_two.volume' | translate}}</button>
                    <div class="bullet-checkbox-container parcel-input-dont-know">
                        <label>
                            <span class="span-radio-align">
                                <input class="vertical-align-radio-button" type="radio" name="dont-know-item-number"
                                    [checked]="itemNumberUnknown"
                                    (click)="setItemNumberUnknown()">
                            </span>
                            <span class="radio-text">{{'send_receive.send_receive.step_two.dont_know' |
                                translate}}</span>
                        </label>
                    </div>
                    <!-- <div *ngIf="parcels.length > 1" class="align-center col-lg-8 col-12 multiple-parcel-warning">
                        <strong class="align-center">{{'send_receive.send_receive.step_two.post_parcels_quantity_notice'
                            | translate}}<label class="tooltipSource"><img class="information-icon"
                                    src="/assets/icons/i_information@3x.png" />
                                <a tabindex="0"></a><span>{{'send_receive.send_receive.step_two.multiple_parcels_tooltip'
                                    | translate}}</span></label></strong>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div *ngFor="let parcel of parcels; let i = index">
        <div class="row parcel-row">
            <div class="col-lg-12 col-md-12 col-12 parcel-col">
                <div class="row child-parcel-row align-center">
                    <h4 *ngIf="itemNumberUnknown && parcels.length == 1" class="row primary index unknown-quantity-info-mobile">
                        {{'send_receive.send_receive.step_two.total' | translate}}</h4>
                    <div class="index-container">
                        <h4 *ngIf="!itemNumberUnknown || parcels.length > 1" class="primary index" style="font-weight: bold; font-size: 18px;">{{i + 1}}</h4>
                        <h4 *ngIf="itemNumberUnknown && parcels.length == 1" class="primary index unknown-quantity-info">
                            {{'send_receive.send_receive.step_two.total' | translate}}</h4>
                        <label class="same-as-previous-desktop" *ngIf="i > 0">
                            <span class="span-radio-align">
                                <input class="vertical-align-radio-button" type="radio" id="same-as-previous"
                                    [checked]="parcel.sameAsPrevious" (change)="setSameAsPreviousParcel($event, i)"
                                    (click)="changeSetAsPrevious(i)">
                            </span>
                            <span class="radio-text"
                                style="padding-right: 5px;">{{'send_receive.send_receive.step_two.parcel_same_as_previous'
                                | translate}}</span>
                        </label>
                    </div>
                    <h4 class="col-md-auto col-7 enter-size-text primary">{{'send_receive.send_receive.step_two.enter_size' | translate}}
                    </h4>
                    <h4 class="col-md-auto col-sm-7 col-3 dimension-text dimension-text-mobile">{{'send_receive.send_receive.step_two.length' | translate}}
                    </h4>
                    <mat-form-field class="no-padding dimensions-input"
                        [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                        <input matInput pattern="[0-9]+([,\.][0-9]+)?" class="align-right" placeholder="0"
                            [(ngModel)]="parcel.length" formControlName="parcelLength{{i}}" required>
                        <mat-error *ngIf="hasErrors('parcelLength'+i)">
                            <app-field-error-message errorMessage="{{getError('parcelLength'+i)}}">
                            </app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                    <h4 class="cm-container unit-text">cm</h4>
                    <h4 class="col-md-auto col-sm-7 col-3 dimension-text">{{'send_receive.send_receive.step_two.width' | translate}}
                    </h4>
                    <mat-form-field class="no-padding dimensions-input dimensions-input-mobile"
                        [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                        <input matInput pattern="[0-9]+([,\.][0-9]+)?" class="align-right" placeholder="0"
                            [(ngModel)]="parcel.width" formControlName="parcelWidth{{i}}" required>
                        <mat-error *ngIf="hasErrors('parcelWidth'+i)">
                            <app-field-error-message errorMessage="{{getError('parcelWidth'+i)}}">
                            </app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                    <h4 class="cm-container unit-text">cm</h4>
                    <h4 class="col-md-auto col-sm-7 col-3 dimension-text">{{'send_receive.send_receive.step_two.height' | translate}}
                    </h4>
                    <mat-form-field class="no-padding dimensions-input dimensions-input-mobile"
                        [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                        <input matInput pattern="[0-9]+([,\.][0-9]+)?" class="align-right" placeholder="0"
                            [(ngModel)]="parcel.height" formControlName="parcelHeight{{i}}" required>
                        <mat-error *ngIf="hasErrors('parcelHeight'+i)">
                            <app-field-error-message errorMessage="{{getError('parcelHeight'+i)}}">
                            </app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                    <h4 class="cm-container unit-text">cm</h4>
                    <label class="parcel-row-dont-know">
                        <span class="span-radio-align">
                            <input class="vertical-align-radio-button" type="radio" id="unknownDimensions"
                                [checked]="parcel.unknownDimensions"
                                (change)="setParcelDimensionsKnownUnknown($event, i)"
                                (click)="changeSetAsKnownUnknown(i)">
                        </span>
                        <span class="radio-text">{{'send_receive.send_receive.step_two.dont_know' | translate}}</span>
                    </label>
                    <label class="same-as-previous-mobile" *ngIf="i > 0">
                        <span class="span-radio-align">
                            <input class="vertical-align-radio-button" type="radio" id="same-as-previous"
                                [checked]="parcel.sameAsPrevious" (change)="setSameAsPreviousParcel($event, i)"
                                (click)="changeSetAsPrevious(i)">
                        </span>
                        <span class="radio-text"
                            style="padding-right: 5px;">{{'send_receive.send_receive.step_two.parcel_same_as_previous'
                            | translate}}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="unknownParcelDimensions" class="row unknown-dimensions-row">
        <div class="col-lg-2 col-md-2 align-center">
        </div>
        <div class="col-lg-10 col-md-10 col-12 row align-center">
            <div class="child-parcel-row align-center parcel-dont-know-container">
                {{'send_receive.send_receive.step_two.parcel_unknown_dimensions' | translate}}
            </div>
            <h4 class="total-volume-text">{{'send_receive.send_receive.step_two.total_volume' | translate}}
            </h4>
            <mat-form-field class="volume-field dimensions-input"
                [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input matInput class="align-right" id="parcelVolume" name="parcelVolume"
                    formControlName="totalParcelVolume" matInput placeholder="0">
                <mat-error *ngIf="hasErrors('totalParcelVolume')">
                    <app-field-error-message errorMessage="{{getError('totalParcelVolume')}}"></app-field-error-message>
                </mat-error>
            </mat-form-field>
            <div class="align-center kg parcel-volume-unit unit-text">M³</div>
            <label class="dont-know-volume">
                <span class="span-radio-align">
                    <input class="vertical-align-radio-button" type="radio" name="parcelVolumeUnkown"
                        [checked]="unknownVolume" (click)="setUnknownVolume()">
                </span>
                <!-- <span class="span-radio-align">
                    <input class="vertical-align-radio-button" type="radio" id="unknownDimensions"
                        [checked]="parcel.unknownDimensions"
                        (change)="setParcelDimensionsKnownUnknown($event, i)"
                        (click)="changeSetAsKnownUnknown(i)">
                </span> -->
                <span class="radio-text">{{'send_receive.send_receive.step_two.dont_know' | translate}}</span>
            </label>
            <!-- <div class="col-lg-2 col-md-3 col-12">
                <button class="primary-button dont-know-buttons" mat-button>{{'send_receive.send_receive.step_two.view_examples' |
                    translate}}</button>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
                <button class="primary-button dont-know-buttons calc-volume-button" mat-button>{{'send_receive.send_receive.step_two.calculate_volume' |
                    translate}}</button>
            </div> -->
        </div>
    </div>
    <div *ngIf="parcelTotalVolumeUnknown">
            <div class="align-center" style="padding: 0px; width: 100%;">
                <app-error-message style="width: 100%"
                    errorMessage="{{'send_receive.send_receive.step_two.parcel_all_unknown' | translate}}">
                </app-error-message>
            </div>
    </div>

    <!-- <div class="row" style="padding-top: 10px;">
        <div class="col-lg-2 col-md-3 align-center">
        </div>
        <div class="col-lg-10 col-md-9 icon-input-container">
            <img class="size-icons" src="/assets/icons/new-order/large_shipment.png" />
            <div class="shipment-bullet-container bullet-checkbox-container">
                <label class="shipment-label align-center" [formGroup]="sendReceiveForm">
                    <span class="span-radio-align">
                        <input class="vertical-align-radio-button" type="radio" name="shipment" id="2"
                            [(ngModel)]="parcelValue" formControlName="shipment"
                            (change)="shipmentSelected($event, false)" [value]="1">
                    </span>
                    <span class="radio-text">{{'send_receive.send_receive.step_two.large_shipment_title' |
                        translate}}</span>
                </label>
                <div *ngIf="isShipmentSelected" class="row">
                    <h4 class="col-lg-2 col-md-3 col-auto align-center primary">{{'send_receive.send_receive.step_two.palletes' |
                        translate}}</h4>
                    <label class="align-center col-lg-3 col-md-2 col-12">
                        <span class="span-radio-align">
                            <input class="vertical-align-radio-button" type="radio" name="pallete-amount" [value]="0"
                                [(ngModel)]="paletteRadioValue" (click)="setHalfPallete()">
                        </span>
                        <span class="radio-text">1/2</span>
                    </label>
                    <label class="align-center col-lg-4 col-md-4 col-7">
                        <span class="span-radio-align">
                            <input class="vertical-align-radio-button" type="radio" name="pallete-amount" [value]="1"
                                [checked]="palleteInputEnabled" [(ngModel)]="paletteRadioValue"
                                (click)="togglePalleteInput()">
                        </span>
                        <span class="radio-text">{{'send_receive.send_receive.step_two.no_of_palletes' |
                            translate}}</span>
                    </label>
                    <mat-form-field class="col-lg-2 col-md-3 col-5" [formGroup]="sendReceiveForm"
                        floatLabel="never" appearance="outline">
                        <input matInput class="align-right" type="number" id="totalWeight" name="totalWeight"
                            formControlName="numberOfPalettes" matInput placeholder="0">
                        <mat-error *ngIf="hasErrors('numberOfPalettes')"><app-field-error-message errorMessage="{{getError('numberOfPalettes')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf="isShipmentSelected" class="row col-lg-12 col-md-12 col-12 total-volume-padding">
            <label class="align-center col-lg-2 col-md-2 col-7">
                <span class="span-radio-align">
                    <input class="vertical-align-radio-button" type="radio" name="pallete-volume-amount"
                        [checked]="shipmentVolumeChecked" (change)="shipmentVolumeSelected($event, false)">
                </span>
                <h4 class="radio-text primary">{{'send_receive.send_receive.step_two.large_shipment_total_volume' |
                    translate}}</h4>
            </label>
            <mat-form-field class="col-lg-1 col-md-1 col-3 volume-field" [formGroup]="sendReceiveForm"
                floatLabel="never" appearance="outline">
                <input matInput class="align-right" id="volume" name="volume" formControlName="shipmentVolume" matInput
                    placeholder="0">
                    <mat-error *ngIf="hasErrors('shipmentVolume')"><app-field-error-message errorMessage="{{getError('shipmentVolume')}}"></app-field-error-message></mat-error>
            </mat-form-field>
            <div class="align-center unit-text">m³</div>
            <h4 class="align-center or-text primary">{{'send_receive.send_receive.step_two.or' | translate}}</h4>
            <label class="align-center col-md-auto col-7 no-padding">
                <span class="span-radio-align">
                    <input class="vertical-align-radio-button" type="radio" name="pallete-volume-amount"
                        (change)="shipmentLengthSelected($event, false)" [checked]="shipmentLwhChecked">
                </span>
                <h4 class="radio-text primary" style="padding-right: 0px;">{{'send_receive.send_receive.step_two.length'
                    | translate}}</h4>
            </label>
            <mat-form-field class="col-lg-1 col-md-1 col-3 no-padding" [formGroup]="sendReceiveForm"
                floatLabel="never" appearance="outline">
                <input matInput class="align-right" placeholder="0" formControlName="shipmentLength" required>
                <mat-error *ngIf="hasErrors('shipmentLength')"><app-field-error-message errorMessage="{{getError('shipmentLength')}}"></app-field-error-message></mat-error>
            </mat-form-field>
            <h4 class="primary cm-container align-center unit-text align-form-field">cm</h4>
            <h4 class="primary align-center primary no-padding col-md-auto col-7 align-form-field mob-30-left">{{'send_receive.send_receive.step_two.width' |
                translate}}</h4>
            <mat-form-field class="col-lg-1 col-md-1 col-3 no-padding align-form-field" [formGroup]="sendReceiveForm"
                floatLabel="never" appearance="outline">
                <input matInput class="align-right" placeholder="0" formControlName="shipmentWidth" required>
                <mat-error *ngIf="hasErrors('shipmentWidth')"><app-field-error-message errorMessage="{{getError('shipmentWidth')}}"></app-field-error-message></mat-error>
            </mat-form-field>
            <h4 class="primary cm-container align-center unit-text align-form-field">cm</h4>
            <h4 class="primary align-center primary no-padding col-md-auto col-7 align-form-field mob-30-left">{{'send_receive.send_receive.step_two.height' |
                translate}}</h4>
            <mat-form-field class="col-lg-1 col-md-1 col-3 no-padding align-form-field" [formGroup]="sendReceiveForm"
                floatLabel="never" appearance="outline">
                <input matInput class="align-right" placeholder="0" formControlName="shipmentHeight" required>
                <mat-error *ngIf="hasErrors('shipmentHeight')"><app-field-error-message errorMessage="{{getError('shipmentHeight')}}"></app-field-error-message></mat-error>
            </mat-form-field>
            <h4 class="primary cm-container align-center unit-text align-form-field">cm</h4>
        </div>
    </div> -->
</div>

<div *ngIf="!isFromToCountryFO()" class="specification-container no-border">
    <div class="row align-center height-container-100 value-insurance-container">
        <div class="col-lg-2 col-md-3 col-12 align-center mobile-margin-top">
            <h3 class="primary row-title">{{'send_receive.send_receive.step_two.value' | translate}}</h3>
        </div>
        <mat-form-field class="col-lg-2 col-md-3 col-4 align-form-field error-field parcel-input" [formGroup]="sendReceiveForm"
            floatLabel="never" appearance="outline">
            <input class="align-right" matInput placeholder="0" id="valueOfGoods" name="valueOfGoods"
                formControlName="valueOfGoods" required>
            <mat-error *ngIf="hasErrors('valueOfGoods')">
                <app-field-error-message errorMessage="{{getError('valueOfGoods')}}"></app-field-error-message>
            </mat-error>
        </mat-form-field>
        <span class="value-of-goods-currency-text">DKK</span>
        <!-- <div class="col-lg-0 col-md-4"></div> -->
        <!-- <div class="col-lg-3 col-md-3 col-12 offset-lg-0 offset-md-0 align-center insurance-mobile-margin-bottom">
            <h3 class="primary row-title">{{'send_receive.send_receive.step_two.insurance_title' | translate}}</h3>
            <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                <a tabindex="0"></a><span>{{'send_receive.send_receive.step_two.insurance_tooltip' |
                    translate}}</span></label>
        </div> -->
        <!-- <div
            class="col-lg-1 col-md-1 col-3 bullet-checkbox-container radio-button-no-button-padding insurance-mobile-margin-bottom no-insurance-bullet-checkbox-container-mobile">
            <label>
                <span class="span-radio-align">
                    <input class="vertical-align-radio-button" type="radio" name="yes-no" (click)="setInsuranceFalse()"
                        [checked]="insuranceNotSelected">
                </span>
                <span class="radio-text">{{'send_receive.send_receive.step_two.no_insurance' | translate}}</span>
            </label>
        </div>
        <div class="col-lg-1 col-md-2 col-3 bullet-checkbox-container insurance-mobile-margin-bottom">
            <label>
                <span class="span-radio-align">
                    <input class="vertical-align-radio-button" type="radio" name="yes-no" (click)="setInsuranceTrue()">
                </span>
                <span class="radio-text">{{'send_receive.send_receive.step_two.yes_insurance' | translate}}</span>
            </label>
        </div> -->
    </div>
</div>
<div class="container">
    <div class="row button-margin-top">
        <div class="col-lg-3 col-md-3 col-6 order-2 order-md-1 order-lg-1 mob-left-button">
            <button class="tertiary-button button-width mobile-full-width sm-full-width" mat-button
                (click)="navigateToAddresses()">
                <div class="full-width">
                    <span class="back-icon-text">{{'send_receive.send_receive.step_two.back' | translate}}</span>
                    <img class="back-icon" src="/assets/icons/arrows/back_button_icon@3x.png" />
                </div>
            </button>
        </div>
        <div class="col-lg-3 col-md-3 col-6 order-3 order-md-2 order-lg-2 mob-right-button" style="padding-left: 0px">
            <button class="tertiary-button button-width mobile-full-width sm-full-width" (click)="clearAll()"
                mat-button>
                <div class="full-width">
                    <span class="clear-all-icon-text">{{'send_receive.send_receive.step_two.clear_all' |
                        translate}}</span>
                    <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
                </div>
            </button>
        </div>
        <div class="col-lg-3 col-md-3 col-0 order-md-3 order-lg-3" style="padding-left: 0px"></div>
        <div class="col-lg-3 col-md-3 col-12 mobile-no-padding-left order-1 order-md-4 order-lg-4 get-prices-button"
            style="padding-left: 0px">
            <button class="primary-button button-width mobile-full-width sm-full-width" mat-button
                (click)="getPrices()">{{'send_receive.send_receive.step_two.get_prices' | translate}}</button>
        </div>
    </div>
</div>