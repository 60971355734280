import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceType } from '../../../shared/price-matrix/enums/ServiceType';
import { IncoTermsInput } from '../../../shared/entities/IncoTermsInput';
import { Country } from '../../../account/entities/Country';
import { IncoTermsService } from '../../../shared/services/inco-terms.service';
import { Order } from '../../entities/Order';
import { CustomShipmentType } from '../../../shared/entities/customShipmentType';
import { SendReceiveService } from './services/send-receive.service';
import { Invoice } from '../../../shared/entities/Invoice';
import { DomSanitizer, SafeHtml, SafeResourceUrl, Title } from '@angular/platform-browser';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { UploadFileService } from '../../../shared/services/upload-file.service';
import { interval, Subject, timer } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Parcel } from '../../entities/parcel';
import { ServiceOption } from '../../entities/serviceOption';
import { OrderLetter } from '../../entities/OrderLetter';
import { OrderService } from '../../entities/OrderService';
import { OrderState } from '../../enums/OrderState';
import { InvoiceLine } from '../../entities/InvoiceLine';
import { environment } from '../../../../environments/environment'
import { ProductType } from '../../../shared/price-matrix/enums/ProductType';
import { TranslationHelper } from '../../../shared/helpers/translation-helper';
import { ErrorHelper } from '../../../shared/helpers/error-helper';
import { AddressService } from '../../../shared/services/address-service';
import { StaticContentService } from '../../../shared/services/static-content.service';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { PaymentService } from '../../services/payment.service';
import { CustomsItemType } from '../../entities/CustomsItemType';
import { BuyPostageService } from '../../services/buy-postage.service';

@Component({
  selector: 'app-send-and-receive',
  templateUrl: './send-and-receive.component.html',
  styleUrls: ['./send-and-receive.component.scss']
})
export class SendAndReceiveComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(OrderDetailsComponent) child: OrderDetailsComponent;
  @ViewChild('paymentFrame') iframe: ElementRef;

  toFromAddressFormGroup: FormGroup;
  specificationsFormGroup: FormGroup;
  orderDetailsFormGroup: FormGroup;
  confirmationFormGroup: FormGroup;

  public itemTypes: CustomsItemType[] = [];

  public showPrices: boolean = false;

  public serviceOption: ServiceOption;

  public parcels: Array<Parcel>;

  public shipmentType: CustomShipmentType;

  public uploadedFiles: Array<Invoice>;

  public insurance: boolean;

  public downloadingReceipt: boolean = false;

  public productType: ProductType;

  public totalVolume: number;

  public fromDepot: boolean = false;

  public toDepot: boolean = false;

  public fromAccountAddress: boolean = false;

  public toAccountAddress: boolean = false;

  //This is only used for routing from Find prices
  public findPricesisParcel: boolean = false;

  public resetAddress: boolean = false;

  public numberOfPalettes: number;

  public incoTerms: string;

  public order: Order = new Order();

  public orderReference: string;

  public customsClearanceSelected: boolean;

  public orderReferenceCallBackId: string;

  public orderBarCode: string;
  public isCDSFiled: boolean;

  public isPaying: boolean = false;

  public htmlSrc: SafeHtml;

  public intervalSet: boolean = false;

  public paymentSuccess: boolean = false;

  public paymentFailed: boolean = false;

  public quoteId: string;

  private stopPolling = new Subject();

  public loading: boolean = false;

  public displayScreen: boolean = false;

  public paymentCodes: string;

  public hideOrderDetails: boolean = false;

  public pageTitle: string;

  public pageModules: string;

  public psModule: string;

  interval: any;

  // public paymentUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/payment/WebPay?locale=en&systemType=5&serviceType=2&orderId=SR10000049&amount=25&test=true`);

  public paymentUrl: SafeResourceUrl;
  downloadingPrintLabel: boolean;
  downloadingCDS: boolean;
  orderReferenceOther: string;
  previousVolume: number = 0;
  contactPostaScreen: boolean = false;
  createOrderFailed: boolean = false;
  iframeURL: any;
  showCustomsRequirements: any;
  downloadingCN22: boolean;
  cn22Email: any;
  sendingCN22Email: boolean;
  emailCN22SentTo: any;
  showEmailCN22Success: boolean;

  constructor(private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private incoTermsService: IncoTermsService,
    private sendReceiveService: SendReceiveService,
    private uploadFileService: UploadFileService,
    private router: Router,
    private translateService: TranslateService,
    private translateHelper: TranslationHelper,
    private staticContentService: StaticContentService,
    private _sanitizer: DomSanitizer,
    private paymentService: PaymentService,
    private buyPostageService: BuyPostageService) { }

  ngOnDestroy() {
    this.stopPolling.next();
  }

  ngOnInit(): void {

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        this.psModule = this.staticContentService.getPsModuleContent('send-receive', 'send-receive/send-receive');
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
      this.psModule = this.staticContentService.getPsModuleContent('send-receive', 'send-receive/send-receive');
    })

    this.order = this._route.snapshot.data.order;
    if(this.order){
      this.itemTypes = this.order.customsItemTypes;
    }

    if (!!this.order && this.order.isPaid) {
      this.router.navigate([`orders/${this.order.orderReference}`]);
    }

    this.toFromAddressFormGroup = this._formBuilder.group({
      toTitle: ['', Validators.maxLength(10)],
      toFirstName: ['', [Validators.required, Validators.maxLength(50)]],
      toLastName: ['', [Validators.required, Validators.maxLength(50)]],
      toOptionalCompany: ['', Validators.maxLength(50)],
      toAddressLine1: ['', [Validators.required, Validators.maxLength(100)]],
      toAddressLine2: ['', Validators.maxLength(100)],
      toTownCity: ['', [Validators.required, Validators.maxLength(50)]],
      toPostcode: ['', [Validators.required, Validators.maxLength(20)]],
      toContactNumber: ['', Validators.maxLength(20)],
      toEmail: ['', ErrorHelper.nullableEmailValidator],
      toCountry: ['', Validators.required],
      toSpecialInstructions: ['', Validators.maxLength(255)],
      toSaveAddress: [''],
      toIsFavouriteAddress: [''],
      fromTitle: ['', Validators.maxLength(10)],
      fromFirstName: ['', [Validators.required, Validators.maxLength(50)]],
      fromLastName: ['', [Validators.required, Validators.maxLength(50)]],
      fromOptionalCompany: ['', Validators.maxLength(50)],
      fromAddressLine1: ['', [Validators.required, Validators.maxLength(100)]],
      fromAddressLine2: ['', Validators.maxLength(100)],
      fromTownCity: ['', [Validators.required, Validators.maxLength(50)]],
      fromPostcode: ['', [Validators.required, Validators.maxLength(20)]],
      fromCountry: ['', Validators.required],
      fromContactNumber: ['', Validators.maxLength(20)],
      fromEmail: ['', ErrorHelper.nullableEmailValidator],
      fromSpecialInstructions: ['', Validators.maxLength(255)],
      fromSaveAddress: [''],
      fromIsFavouriteAddress: [''],
      logisticsSenderName: ['', Validators.required]
    });

    this.specificationsFormGroup = this._formBuilder.group({
      referenceNumber: ['', Validators.maxLength(100)],
      noOfItems: ['', [Validators.required, ErrorHelper.integerOnlyValidator]],
      totalWeight: ['', [Validators.required, ErrorHelper.numberOnlyValidator, ErrorHelper.greaterThanZeroValidator]],
      parcel: [''],
      valueOfGoods: ['', [Validators.required, ErrorHelper.numberOnlyValidator, ErrorHelper.greaterThanZeroValidator]],
      isKg: [''],
      isItems: ['']
    });

    this.orderDetailsFormGroup = this._formBuilder.group({
      definitionOfGoods: ['', Validators.maxLength(255)],
      shipmentTypeOther: ['', [Validators.required]],
      uploadedFiles: ['', Validators.required],
      collectionDate: ['', Validators.required],
      comments: ['', Validators.maxLength(255)],
      domesticPackageContents: ['', [Validators.required, Validators.maxLength(255)]]
    });

    this.confirmationFormGroup = this._formBuilder.group({
      agreeToTermsAndConditions: [false, Validators.requiredTrue],
      registerForNotifications: [false]
    });

    this.translateService.onLangChange.subscribe(x => {
      this.translateHelper.convertNumberField(this.specificationsFormGroup.get(`totalWeight`));
      this.translateHelper.convertNumberField(this.specificationsFormGroup.get(`valueOfGoods`));
    });

    this.specificationsFormGroup.get(`totalWeight`).valueChanges.subscribe(newValue => {
      this.translateHelper.formatNumberField(this.specificationsFormGroup.get('totalWeight'));
    });
    this.specificationsFormGroup.get(`valueOfGoods`).valueChanges.subscribe(newValue => {
      this.translateHelper.formatNumberField(this.specificationsFormGroup.get('valueOfGoods'));
    });

    this._route.queryParams.subscribe(params => {

      this.setFindPriceFields(params);

      const id = this._route.snapshot.paramMap.get('orderReferenceId');
      if (!!id) {
        this.orderReference = id;
      }
    });

    this.displayScreen = true;
  }

  reset($event?: any) {
    this.toFromAddressFormGroup.reset();
    this.specificationsFormGroup.reset();
    this.orderDetailsFormGroup.reset();
    this.confirmationFormGroup.reset();
  }

  getOrderWeight(){
    var weightInKg = +this.translateHelper.getNumber(this.specificationsFormGroup.get("totalWeight").value);
    return this.specificationsFormGroup.get('isKg').value == 'true' ? weightInKg : weightInKg/1000
  }

  private setFindPriceFields(params) {
    const isParcel = params['isParcel'];
    const numberOfPalettes = params['numberOfPalettes'];

    if (!!isParcel && isParcel) {
      this.findPricesisParcel = isParcel;
    }
    if (!!numberOfPalettes && numberOfPalettes > 0) {
      this.numberOfPalettes = +numberOfPalettes;
    }
  }

  public isDomestic() {
    if (this.fromDepot || this.toDepot) {
      //Depot is DK, so won't be from FO
      return false;
    }

    var fromCountryControl = this.toFromAddressFormGroup.get('fromCountry');
    var toCountryControl = this.toFromAddressFormGroup.get('toCountry');

    if ((!!fromCountryControl && !!fromCountryControl.value && (fromCountryControl.value.countryCode == "FO"
      || fromCountryControl.value == "Faroe Islands")) && (!!toCountryControl && !!toCountryControl.value && (toCountryControl.value.countryCode == "FO"
        || toCountryControl.value == "Faroe Islands"))) {
      if (!this.specificationsFormGroup.get("valueOfGoods").disabled) {
        this.specificationsFormGroup.get("valueOfGoods").disable();
      }
    }
    else {
      if (this.specificationsFormGroup.get("valueOfGoods").disabled) {
        this.specificationsFormGroup.get("valueOfGoods").enable();
      }
    }

    if (!!fromCountryControl && !!fromCountryControl.value && !!toCountryControl && !!toCountryControl.value && fromCountryControl.value.countryCode == toCountryControl.value.countryCode) {
      return true
    }

    return false;
  }

  public onStepChange(event: any, stepper: MatStepper) {
    this.showPrices = false;

    if (stepper.selectedIndex != 1) {
      this.getIncoTerms();
    }
  }

  private getIncoTerms() {
    var incoTermsInput: IncoTermsInput = {
      isFromPostaDepot: this.fromDepot,
      isToCountryFO: false,
      isFromCountryFO: false
    }

    const toCountryFormControl = this.toFromAddressFormGroup.get("toCountry");
    if (!!toCountryFormControl && !!toCountryFormControl.value) {
      if ((toCountryFormControl.value as Country).countryCode == "FO") {
        incoTermsInput.isToCountryFO = true;
      }
    }

    const fromCountryFormControl = this.toFromAddressFormGroup.get("fromCountry");
    if (!!fromCountryFormControl && !!fromCountryFormControl.value) {
      if (((fromCountryFormControl.value as Country).countryCode == "FO" || fromCountryFormControl.value == "Faroe Islands") && !this.fromDepot) {
        incoTermsInput.isFromCountryFO = true;
      }
    }

    this.incoTermsService.GetIncoTerm(incoTermsInput).subscribe(x => this.incoTerms = x.incoTermsTypeCode);
  }

  public setCustomsClearanceSelected(isSelected: boolean) {
    this.customsClearanceSelected = isSelected;
  }

  public setServiceOption(serviceOption: ServiceOption) {
    if(!serviceOption){
      this.productType = undefined;
    }
    this.serviceOption = serviceOption;
    this.productType = this.serviceOption.ProductType;
    this.child.showHideFields(this.serviceOption);
  }

  public proceedToOrderDetailsScreen(event: boolean) {
    if (event) {
      if(this.itemTypes && this.itemTypes.length == 0){
        var firstItemType = new CustomsItemType();
        firstItemType.netWeight = this.getOrderWeight();
        firstItemType.isKg = this.specificationsFormGroup.get('isKg').value;
        this.itemTypes.push(firstItemType);
      }
      this.stepper.selected.completed = event;
      this.stepper.selectedIndex = 2;
      this.hideOrderDetails = false;
    }
  }

  public proceedToConfirmationPage(event: boolean) {
    if (event) {
      this.stepper.selected.completed = event;
      this.orderDetailsFormGroup.disable();
      this.stepper.selectedIndex = 2;
      this.stepper.selectedIndex = 3;
      this.hideOrderDetails = true;
    }
  }

  public setFromDepot(fromDepot: boolean) {
    this.fromDepot = fromDepot;
  }

  public setToDepot(toDepot: boolean) {
    this.toDepot = toDepot;
  }

  public setFromAccountAddress(fromAccount: boolean) {
    this.fromAccountAddress = fromAccount
  }

  public setToAccountAddress(toAccount: boolean) {
    this.toAccountAddress = toAccount
  }

  public setParcels(items: Array<Parcel>) {
    this.showPrices = false;
    var currentVolume = this.specificationsFormGroup.get("noOfItems").value;
    if (!!items && items.length > 0 && this.totalVolume > 0) {
      this.previousVolume = currentVolume;
      this.showPrices = true;
      this.parcels = items;
    }
    else {
      this.parcels = null;
      this.showPrices = false;
      this.productType = null;
    }
  }

  public setTotalVolume(totalVolume: number) {
    if (!!totalVolume && totalVolume > 0) {
      this.showPrices = true;
      this.totalVolume = totalVolume;
    }
    else {
      this.totalVolume = totalVolume;
      this.showPrices = false;
    }
  }

  public setServiceType(productType: ProductType) {
    this.productType = productType;
  }

  public setSelectedShipmentType(shipmentType: CustomShipmentType) {
    this.shipmentType = shipmentType;
  }

  public setUploadedFiles(uploadedFiles: any) {
    this.uploadedFiles = uploadedFiles;
  }

  public setInsurance(setInsurance: boolean) {
    this.insurance = setInsurance;
  }

  public addressNextClicked(clicked: boolean) {
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.selectedIndex = 1;
  }


  public navigateToPreviousPage(event: any) {
    this.stepper.selectedIndex = this.stepper.selectedIndex - 1;
  }

  public wholeFormValid(): boolean {
    if (this.confirmationFormGroup.valid && this.toFromAddressFormGroup.valid && this.specificationsFormGroup.valid && (this.hideOrderDetails || this.orderDetailsFormGroup.valid) && !this.loading) {
      return true;
    }
    return false;
  }

  public CreateUpdateOrder() {
    var orderService: OrderService = {
      transportTypeId: this.serviceOption.Key,
      transportTypeJson: JSON.stringify(this.serviceOption)
    }

    var collectionDate: Date
    const collectionDateFormControl = this.orderDetailsFormGroup.get("collectionDate");
    if (!!collectionDateFormControl && !!collectionDateFormControl.value) {
      collectionDate = new Date(collectionDateFormControl.value);
    }

    var comments: string;
    const commentsFormControl = this.orderDetailsFormGroup.get("comments");
    if (!!commentsFormControl && !!commentsFormControl.value) {
      comments = commentsFormControl.value
    }

    var domesticPackageContents: string;
    const domesticContentsFormControl = this.orderDetailsFormGroup.get("domesticPackageContents");
    if (!!domesticContentsFormControl && !!domesticContentsFormControl.value) {
      domesticPackageContents = domesticContentsFormControl.value
    }
    else{
      const shipmentTypeOtherField = this.orderDetailsFormGroup.get('shipmentTypeOther');
      if(!!shipmentTypeOtherField && !!shipmentTypeOtherField.value){
        domesticPackageContents = shipmentTypeOtherField.value
      }
    }

    var estimatedDate: Date
    if (!collectionDate) {
      estimatedDate = new Date();
    }
    else {
      const collectionDateClone = new Date(collectionDate);
      estimatedDate = collectionDateClone;
    }
    estimatedDate.setDate(estimatedDate.getDate() + this.serviceOption.MinDeliveryDays);

    var isInvoiceRequired: boolean = true;
    if (!!this.uploadedFiles && this.uploadedFiles.length > 0) {
      isInvoiceRequired = false;
    }

    var managedCustomsCost: number = 0;
    var managedCustomsJSON: string;
    if (!!this.customsClearanceSelected) {
      managedCustomsCost = this.serviceOption.ManagedCustomCost;
      managedCustomsJSON = this.serviceOption.ManagedCustomCostJson;
    }

    var letter: OrderLetter
    var letters = new Array<OrderLetter>();
    if (this.serviceOption.ProductType == ProductType.Postage || this.serviceOption.ProductType == ProductType.Postage_Plus) {
      var productType = this.productType;
      this.specificationsFormGroup.get("valueOfGoods").setValue(undefined);
      this.productType = productType;
      if (!!this.parcels && this.parcels.length > 0)
      {
        for (let i = 0; i < this.parcels.length; i++) {
          letter = {
            transportTypeId: this.serviceOption.Key,
            transportTypeJson: JSON.stringify(this.serviceOption),
            transportTypeName: this.serviceOption.Name.EN
          }
          letters.push(letter);
        }
      }
      else {
        letter = {
          transportTypeId: this.serviceOption.Key,
          transportTypeJson: JSON.stringify(this.serviceOption),
          transportTypeName: this.serviceOption.Name.EN
        }
        letters.push(letter);
      }
      this.parcels = null;
    }

    //In the future this may be more than one but for now it's just one
    var invoiceLine: InvoiceLine = {
      sequenceNo: 0,
      quantity: !!this.parcels && this.parcels.length > 0 ? this.parcels.length : 1,
      unitPrice: this.serviceOption.Cost,
      grossAmount: this.isDomestic() == true ? this.getCostTotalCost(letters, false) : this.serviceOption.Cost + managedCustomsCost,
      netAmount: this.isDomestic() == true ? this.getCostTotalCost(letters, true) : this.serviceOption.Cost-this.serviceOption.VATCost,
    }


    var parcelNumericValues = new Array<Parcel>();

    if (!!this.parcels) {
      for (i = 0; i < this.parcels.length; i++) {
        var parcel: Parcel = {
          length: +this.parcels[i].length,
          width: +this.parcels[i].width,
          height: +this.parcels[i].height,
          parcelNo: +this.parcels[i].parcelNo
        }
        parcelNumericValues.push(parcel);
      }
    }

    var weightInKg = +this.translateHelper.getNumber(this.specificationsFormGroup.get("totalWeight").value);

    var order: Order = {
      orderStateId: OrderState.Order as number,
      customsShipmentTypeId: !!this.shipmentType ? this.shipmentType.customsShipmentTypeId : undefined,
      productTypeId: this.productType,
      fromTitle: this.toFromAddressFormGroup.get("fromTitle").value,
      fromFirstName: this.toFromAddressFormGroup.get("fromFirstName").value,
      fromLastName: this.toFromAddressFormGroup.get("fromLastName").value,
      fromCompanyName: this.toFromAddressFormGroup.get("fromOptionalCompany").value,
      fromAddressLine1: this.toFromAddressFormGroup.get("fromAddressLine1").value,
      fromAddressLine2: this.toFromAddressFormGroup.get("fromAddressLine2").value,
      fromTownCity: this.toFromAddressFormGroup.get("fromTownCity").value,
      fromPostcode: this.toFromAddressFormGroup.get("fromPostcode").value,
      fromCountryCode: this.fromDepot ? 'DK' : (this.toFromAddressFormGroup.get("fromCountry").value as Country).countryCode,
      fromContactNumber: this.toFromAddressFormGroup.get("fromContactNumber").value,
      fromContactEmail: this.toFromAddressFormGroup.get("fromEmail").value,
      fromSpecialInstructions: this.toFromAddressFormGroup.get("fromSpecialInstructions").value,
      toTitle: this.toFromAddressFormGroup.get("toTitle").value,
      toFirstName: this.toFromAddressFormGroup.get("toFirstName").value,
      toLastName: this.toFromAddressFormGroup.get("toLastName").value,
      toCompanyName: this.toFromAddressFormGroup.get("toOptionalCompany").value,
      toAddressLine1: this.toFromAddressFormGroup.get("toAddressLine1").value,
      toAddressLine2: this.toFromAddressFormGroup.get("toAddressLine2").value,
      toTownCity: this.toFromAddressFormGroup.get("toTownCity").value,
      toPostcode: this.toFromAddressFormGroup.get("toPostcode").value,
      toCountryCode: this.toDepot ? 'DK' : (this.toFromAddressFormGroup.get("toCountry").value as Country).countryCode,
      toContactNumber: this.toFromAddressFormGroup.get("toContactNumber").value,
      toContactEmail: this.toFromAddressFormGroup.get("toEmail").value,
      toSpecialInstructions: this.toFromAddressFormGroup.get("toSpecialInstructions").value,
      senderNamePostaDepot: this.toFromAddressFormGroup.get("logisticsSenderName").value,
      isFromPostaDepot: this.fromDepot,
      isToPostaDepot: this.toDepot,
      isFromAccountAddress: this.fromAccountAddress,
      isToAccountAddress: this.toAccountAddress,
      insuranceSelected: this.insurance,
      customerReference: this.specificationsFormGroup.get("referenceNumber").value,
      weight: this.specificationsFormGroup.get('isKg').value == 'true' ? weightInKg : weightInKg/1000,
      valueOfGoods: this.translateHelper.getNumber(this.specificationsFormGroup.get("valueOfGoods").value),
      volume: this.translateHelper.getNumber(this.totalVolume),
      collectionDate: collectionDate,
      definitionOfGoods: this.orderDetailsFormGroup.get("definitionOfGoods").value,
      comments: comments,
      domesticPackageContents: domesticPackageContents,
      estimatedDeliveryDate: estimatedDate,
      service: orderService,
      isInvoiceRequired: isInvoiceRequired,
      customsClearanceExtraJson: managedCustomsJSON,
      totalCost: this.isDomestic() == true ? this.getCostForDomestic(letters, managedCustomsCost, false) : this.serviceOption.Cost + managedCustomsCost,
      incoTermsCode: this.incoTerms,
      parcels: !!parcelNumericValues && parcelNumericValues.length > 0 ? parcelNumericValues : null,
      letters: !!letter ? letters : null,
      orderReference: this.orderReference,
      attemptedPaymentYN: false,
      invoiceLines: [invoiceLine],
      barcodePrefix: this.serviceOption.BarcodePrefix,
      isCreateTrackTraceRegistration: this.confirmationFormGroup.get('registerForNotifications').value,
      isVatIncluded: this.serviceOption.isVatIncluded,
      languageCode: this.translateService.currentLang,
      customsItemTypes: null
    }

    if(!!this.itemTypes && !!this.itemTypes.length){
      if(this.itemTypes.length > 1){
        order.customsItemTypes = this.itemTypes;
      }
      else{
        if(this.itemTypes[0].description){
          order.customsItemTypes = this.itemTypes;
        }
      }
    }

    if (this.loading) {
      // Call in progress, do not try another
      return;
    }

    if (!this.order) {
      this.loading = true;
      order.invoices = this.uploadedFiles;
      this.sendReceiveService.CreateOrder(order).subscribe(x => {
        this.isPaying = true;
        this.orderReferenceCallBackId = x;
        if (localStorage.getItem("role") !== "private") {
              this.poll();
        }
        else {
          //this.sendReceiveService.dibsPay(`${environment.dibsUrl}/payment/WebPay?locale=${this.translateService.currentLang}&systemType=5&serviceType=6&orderId=${this.orderReferenceCallBackId}&amount=${this.getCostTotalCost(letters, false)}&test=true`).subscribe(
          this.sendReceiveService.dibsPay(`${environment.dibsUrl}/payment/WebPay?locale=${this.translateService.currentLang}&systemType=5&serviceType=6&orderId=${this.orderReferenceCallBackId}&amount=${this.getCostTotalCost(letters, false)}`).subscribe(
            y => {
              this.iframeURL = this._sanitizer.bypassSecurityTrustResourceUrl(y)
              this.onFrameLoad();
            })
        }
      }, (error) => {
        if (error.status == 501) {
          this.contactPostaScreen = true;
          this.orderReferenceCallBackId = error.error;
        }
        else {
          this.createOrderFailed = true;
        }
      })
      //this.loading = false;
    }
    else {
      var filesToRemove = this.child.getFilesToRemove();
      var filesToAdd = this.child.getFilesToAdd();

      //use a forkjoin or something to wait for invoice calls to finish before updating the order
      if (!!filesToRemove && filesToRemove.length > 0) {
        for (var i = 0; i < filesToRemove.length; i++) {
          this.uploadFileService.removeInvoice(filesToRemove[i], order.orderReference).subscribe();
        }
      }
      if (!!filesToAdd && filesToAdd.length > 0) {
        for (var i = 0; i < filesToAdd.length; i++) {
          this.uploadFileService.addInvoice(filesToAdd[i], order.orderReference).subscribe();
        }
      }
      this.sendReceiveService.UpdateOrder(order).subscribe(x => {
        this.orderReferenceCallBackId = order.orderReference;
        this.isPaying = true;
        this.loading = true;
        if (localStorage.getItem("role") === "private") {
          this.sendReceiveService.dibsPay(`${environment.dibsUrl}/payment/WebPay?locale=${this.translateService.currentLang}&systemType=5&serviceType=6&orderId=${this.orderReferenceCallBackId}&amount=${this.getCostTotalCost(letters, false)}`).subscribe(
            y => {
              this.iframeURL = this._sanitizer.bypassSecurityTrustResourceUrl(y)
              this.onFrameLoad();
            })
        }
        else {
          this.onFrameLoad();
        }
      });
      this.loading = false;
    }
  }

  getCostTotalCost(letters: Array<OrderLetter>, calculateNet: boolean) {
    var customCost = 0;
    if (!!this.customsClearanceSelected) {
      customCost = this.serviceOption.ManagedCustomCost;
    }

    if (this.isDomestic()) {
      return this.getCostForDomestic(letters, customCost, calculateNet);
    }
    return (this.serviceOption.Cost + customCost);
  }

  public getCostForDomestic(letters: Array<OrderLetter>, managedCustomsCost: number, calculateNet: boolean) {
    if (!!letters && letters.length > 0) {
      if (calculateNet)
      {
        return (this.serviceOption.Cost * letters.length) + managedCustomsCost - (this.serviceOption.VATCost * letters.length);
      }
      return (this.serviceOption.Cost * letters.length) + managedCustomsCost;
    }
    if (calculateNet)
    {
      return ((this.serviceOption.Cost) * this.parcels.length) + managedCustomsCost - (this.serviceOption.VATCost * this.parcels.length);
    }
    return (this.serviceOption.Cost * this.parcels.length) + managedCustomsCost;
  }

  public getTotalCost(): number {
    if (!!this.serviceOption && !!this.parcels) {
      if (!!this.serviceOption.ManagedCustomCost && this.customsClearanceSelected) {
        if (this.isDomestic()) {
          return (this.serviceOption.ManagedCustomCost + (this.serviceOption.Cost * this.parcels.length));
        }
        return (this.serviceOption.ManagedCustomCost + this.serviceOption.Cost);
      }


      if (this.isDomestic()) {
        return this.serviceOption.Cost * this.parcels.length;
      }
      return this.serviceOption.Cost
    }
    return 0;
  }

  public onFrameLoad() {
    if (!this.intervalSet) {
      this.intervalSet = true;
      interval(5000).pipe(
        startWith(0),
        switchMap(() => this.sendReceiveService.GetOrder(this.orderReferenceCallBackId)),
        takeUntil(this.stopPolling)).subscribe(x => {
          if (x.isPaid) {
            this.stopPolling.next();
            document.getElementById("paymentFrame").remove();

            this.isCDSFiled = x.isCDSFiled;
            this.orderBarCode = x.barcode;

            this.sendReceiveService.getPaymentCodes(x.productTypeId, this.orderReferenceCallBackId).subscribe(y => {
              //Only show parcel codes for postage/parcel
              if (x.productTypeId == 4 || x.productTypeId == 10) {
                this.paymentCodes = y;
              }
              this.paymentSuccess = true;
              if(this.productType == ProductType.Postage && !this.isDomestic()){
                this.showCustomsRequirements = true;
              }
              else{
                this.showCustomsRequirements = false;
              }
              this.orderReferenceOther = x.orderReferenceOther;
            })
            //this.orderBarCode = x.
            return;
          }
          if (x.attemptedPaymentYN) {
            this.paymentFailed = true;
            document.getElementById("paymentFrame").remove();
            this.paymentService.updatePaymentStatus({serviceTypeId: 6, orderReference: this.orderReferenceCallBackId}).subscribe();
            this.stopPolling.next();
            return;
          }
        }), (err) => { console.log("Error getting order after payment: " + err) };
    }
  }

  public poll() {
    if (!this.intervalSet) {
      this.intervalSet = true;
      interval(5000).pipe(
        startWith(0),
        switchMap(() => this.sendReceiveService.GetOrder(this.orderReferenceCallBackId)),
        takeUntil(this.stopPolling)).subscribe(x => {
          if (!!document.getElementById("paymentFrame"))
          {
            document.getElementById("paymentFrame").remove();
          }

          if (x.isPaid) {
            this.stopPolling.next();
            this.isCDSFiled = x.isCDSFiled;
            this.orderBarCode = x.barcode;
            this.sendReceiveService.getPaymentCodes(x.productTypeId, this.orderReferenceCallBackId).subscribe(y => {
              //Only show parcel codes for postage/parcel
              if (x.productTypeId == 4 || x.productTypeId == 10) {
                this.paymentCodes = y;
              }
              this.paymentSuccess = true;
              if(this.productType == ProductType.Postage && !this.isDomestic()){
                this.showCustomsRequirements = true;
              }
              else{
                this.showCustomsRequirements = false;
              }
              this.orderReferenceOther = x.orderReferenceOther;
            })
            return;
          }
          if (x.attemptedPaymentYN) {
            this.paymentFailed = true;
            this.stopPolling.next();
            this.paymentService.updatePaymentStatus({serviceTypeId: 6, orderReference: this.orderReferenceCallBackId}).subscribe();
            return;
          }
        }), (err) => { console.log("Error getting order after payment: " + err) };
    }
  }

  public downloadReceipt() {
    // this.downloadingPdfOrder = true;
    this.downloadingReceipt = true;
    if (this.productType == 4) {
      this.productType = ProductType.Postage
    }

    this.sendReceiveService.downloadReceipt(this.productType, this.orderReferenceCallBackId, this.translateService.currentLang).subscribe(x => {
      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingReceipt = false;

      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    }, (error) => {
      this.downloadingReceipt = false;
      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    })
  }

  public downloadPrintLabel() {
    this.downloadingPrintLabel = true;
    this.sendReceiveService.downloadPrintLabel(this.orderReferenceCallBackId).subscribe(x => {
      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingPrintLabel = false;

      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    }, (error) => {
      this.downloadingPrintLabel = false;
      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    })
  }

  public downloadCDS() {
    this.downloadingCDS = true;
    this.sendReceiveService.downloadCDS(this.orderBarCode).subscribe(x => {
      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingCDS = false;

      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    }, (error) => {
      this.downloadingCDS = false;
      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    })
  }

  public getPageTitle() {
    var temp = this.staticContentService.getPageTitle();
    return temp;
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }

  public quoteSaved(event: string) {
    this.quoteId = event;
  }

  public downloadCN22()
  {
    this.downloadingCN22 = true;
    // this.buyPostageService.downloadCN22(this.orderConfirmationDetails.orderReference).subscribe(x => {
    //   const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
    //   const blob = new Blob([byteArray], {type: 'application/pdf'});
    //   const url = window.URL.createObjectURL(blob);
    //   // window.open(url);
    //   var link = document.createElement('a');
    //   link.href = url;
    //   link.download = x.fileName;
    //   link.click();
    //   this.downloadingCN22 = false;
    //   // this.downloadingReceipt = false;
    // }, (error) => {
    //   // this.downloadingReceipt = false;
    //   this.downloadingCN22 = false;
    // })
    window.open("https://cdn.posta.fo/media/5s2n5wv0/cn22.pdf", '_blank').focus();
    this.downloadingCN22 = false;
  }

  public sendCN22Email()
  {
    if (!!this.cn22Email)
    {
      this.sendingCN22Email = true;
      this.buyPostageService.emailCN22(this.cn22Email).subscribe(x => {
        this.emailCN22SentTo = this.cn22Email;
        this.showEmailCN22Success = true;
        this.sendingCN22Email = false;
      }, (error) => {
        this.sendingCN22Email = false;
      })
    }
  }
}
