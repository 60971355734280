        <div class="col-lg-8 email-container">
            <h3 class="input-title">{{'pay_customs.step_one_user_confirmation_page.email' | translate}}</h3>
            <div class="align-center">
                <mat-form-field class="align-form-field email-input" [formGroup]="userConfirmationFormGroup" floatLabel="never" appearance="outline">
                    <input matInput placeholder="{{'pay_customs.step_one_user_confirmation_page.email_placeholder' | translate}}" id="email" name="email" formControlName="email" required>
                    <mat-error class="show-mobile" *ngIf="hasErrors('email')"><app-field-error-message errorMessage="{{getError('email')}}"></app-field-error-message></mat-error>
                </mat-form-field>
                <app-field-error-message *ngIf="hasErrors('email') && isTouched('email')" class="side-error hide-mobile" errorMessage="{{getError('email')}}"></app-field-error-message>
            </div>
            <div [formGroup]="userConfirmationFormGroup" class="check-box-container">
                <mat-checkbox formControlName="agreeToUseEmail">
                    <div>{{'pay_customs.step_one_user_confirmation_page.confirm_permission' | translate}}</div>
                    <!-- <div *ngIf="" class="tickCheckbox">I agree to terms and conditions</div> -->
                </mat-checkbox>
            </div>
        </div>
    <!-- <div *ngIf="!user" class="col-lg-8 posta-account-container">
            <h3>{{'pay_customs.step_one_user_confirmation_page.have_myposta_account' | translate}}</h3>
            <div class="posta-radio-container" [formGroup]="userConfirmationFormGroup">
                <label class="align-radio-button-text">
                    <span class="span-radio-align">
                        <input class="vertical-align-radio-button" type="radio" name="noPostaAccount" id="noPostaAccount"
                        [(ngModel)]="originValue" formControlName="noPostaAccount" [value]="0" (change)="postaAccountToggled(false)">
                    </span>
                    <span class="radio-text">{{'pay_customs.step_one_user_confirmation_page.no' | translate}}</span>
                </label>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="posta-radio-container" [formGroup]="userConfirmationFormGroup">
                        <label class="align-radio-button-text">
                            <span class="span-radio-align">
                                <input class="vertical-align-radio-button" type="radio" name="hasPostaAccount" id="hasPostaAccount"
                                [(ngModel)]="originValue" formControlName="hasPostaAccount" [value]="1"
                                    (change)="postaAccountToggled(true)">
                            </span>
                            <span class="radio-text">{{'pay_customs.step_one_user_confirmation_page.yes' | translate}}</span>
                        </label>
                        <div class="password-container">
                            <span class="password-text">{{'pay_customs.step_one_user_confirmation_page.password' | translate}}</span>
                            <div class="align-center">
                                <mat-form-field class="align-form-field password-field" [formGroup]="userConfirmationFormGroup" floatLabel="never" appearance="outline">
                                    <input type="password" matInput placeholder="Enter password" id="password" name="password" formControlName="password" required>
                                    <mat-error class="show-mobile" *ngIf="hasErrors('password')"><app-field-error-message errorMessage="{{getError('password')}}"></app-field-error-message></mat-error>
                                </mat-form-field>
                                <app-field-error-message *ngIf="hasErrors('password') && isTouched('password')" class="side-error hide-mobile" errorMessage="{{getError('password')}}"></app-field-error-message>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            </div> -->
            <button class="primary-button proceed-button" (click)="openDibsPaymentScreen()">{{'pay_customs.step_one_user_confirmation_page.proceed_to_payment' | translate}}</button>