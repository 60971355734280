<!-- TODO breadcrumbs -->
<div *ngIf="displayScreen">
    <div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>

  <!-- <button class="primary-button proceed-button"
  [routerLink]="['../send-receive']">{{'buy_postage.order_online' | translate}}</button> -->
  
  <mat-horizontal-stepper *ngIf="!isPaying && !quoteId && !contactPostaScreen && !createOrderFailed" class="container" [linear]="true" #stepper
    (selectionChange)="onStepChange($event, stepper)">
    <mat-step [optional]="false" [stepControl]="toFromAddressFormGroup" #stepper>
      <form [formGroup]="toFromAddressFormGroup">
        <ng-template matStepLabel><span class="lg-view">1. {{'send_receive.send_receive.step_one.step_one_title' | translate}}</span><span class="sm-view">1</span></ng-template>
        <app-send-and-receive-addresses (nextClicked)="addressNextClicked($event)"
          [sendReceiveForm]="toFromAddressFormGroup" [specificationForm]="specificationsFormGroup" [order]="order" (fromDepot)="setFromDepot($event)"
          (toDepot)="setToDepot($event)" (fromAccountAddress)="setFromAccountAddress($event)"
          (toAccountAddress)="setToAccountAddress($event)" [psModule]="psModule">
        </app-send-and-receive-addresses>
      </form>
    </mat-step>
    <mat-step [optional]="false" [completed]="productType">
      <form [formGroup]="specificationsFormGroup">
        <ng-template matStepLabel><span class="lg-view">2. {{'send_receive.send_receive.step_two.step_two_title' | translate}}</span><span class="sm-view">2</span>
        </ng-template>
        <div class="stepper-component-container">
          <app-send-and-receive-specification [fromCountryControl]="toFromAddressFormGroup.get('fromCountry')" [toCountryControl]="toFromAddressFormGroup.get('toCountry')"
            [sendReceiveForm]="specificationsFormGroup" [order]="order"
            [isParcel]="findPricesisParcel" [fromDepot]="fromDepot" [toDepot]="toDepot" (items)="setParcels($event)" (goBackToAddress)="navigateToPreviousPage($event)"
            (onClear)="stepper.reset();reset()" (serviceType)="setServiceType($event)" (totalVolume)="setTotalVolume($event)"
            (insurance)="setInsurance($event)">
          </app-send-and-receive-specification>
        </div>
      </form>
    </mat-step>
    <mat-step [optional]="false" [stepControl]="orderDetailsFormGroup">
      <form [formGroup]="orderDetailsFormGroup">
        <ng-template matStepLabel><span class="lg-view">3. {{'send_receive.send_receive.step_three.step_three_title' | translate}}</span><span class="sm-view">3</span>
        </ng-template>
        <app-order-details [sendReceiveForm]="orderDetailsFormGroup" [toFromAddressFormGroup]="toFromAddressFormGroup" [fromDepot]="fromDepot" [toDepot]="toDepot" [serviceOption]="serviceOption" [order]="order" [hideOrderDetails]="hideOrderDetails"
          [stepper]="stepper" [totalCost]="getTotalCost()" [itemTypes]="itemTypes"
          (uploadedFiles)="setUploadedFiles($event)" (selectedShipmentType)="setSelectedShipmentType($event)" (onClear)="stepper.reset();reset()" (customsClearanceSelected)="setCustomsClearanceSelected($event)" [psModule]="psModule">
        </app-order-details>
      </form>
    </mat-step>
    <mat-step [optional]="false">
      <ng-template matStepLabel><span class="lg-view">4. {{'send_receive.send_receive.step_four.step_four_title' | translate}}</span><span class="sm-view">4</span></ng-template>
      <app-send-receive-confirmation [confirmationForm]="confirmationFormGroup" [addressForm]="toFromAddressFormGroup"
        [specificationForm]="specificationsFormGroup" [isDomestic]="isDomestic()" [orderDetailsForm]="orderDetailsFormGroup"
        [serviceOption]="serviceOption" [totalCost]="getTotalCost()" [items]="parcels" [parcelVolume]="totalVolume"
        [shipmentType]="shipmentType" [uploadedFiles]="uploadedFiles" [insuranceSelected]="insurance"
        [customsClearanceSelected]="customsClearanceSelected" [fromDepot]="fromDepot" [toDepot]="toDepot"
        [fromAccountAddress]="fromAccountAddress" [toAccountAddress]="toAccountAddress"
        [incoTerms]="incoTerms" [psModule]="psModule">
      </app-send-receive-confirmation>
      <div class="row">
        <div class="col-lg-3 col-md-3 col-6 order-2 order-md-1 order-lg-1 mob-left-button button-container">
          <button class="tertiary-button" mat-button (click)="navigateToPreviousPage(null)">{{'send_receive.send_receive.back' | translate}}</button>
        </div>
        <div class="col-lg-3 col-md-3 col-6 order-3 order-md-2 order-lg-2 mob-right-button button-container">
          <button mat-button class="tertiary-button" (click)="stepper.reset();reset()">{{'send_receive.send_receive.reset' | translate}}</button>
        </div>
        <div class="col-lg-3 col-md-3 col-0 order-md-3 order-lg-3" style="padding-left: 0px"></div>
        <div class="col-lg-3 col-md-3 col-12 mobile-no-padding-left order-1 order-md-4 order-lg-4 get-prices-button">
          <button class="primary-button" mat-button (click)="CreateUpdateOrder()" [disabled]="!wholeFormValid()">
            <div class="spinner">
              <span>{{'send_receive.send_receive.confirm_and_proceed' | translate}}</span>
              <mat-spinner *ngIf="loading" style="margin-left: 15px;" diameter="15"></mat-spinner>
            </div>
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
  
  <div *ngIf="showPrices && !quoteId">
    <app-send-receive-prices [order]="order" [parcels]="parcels" [fromDepot]="fromDepot"
      [toDepot]="toDepot" [incoTerms]="incoTerms" [fromAccountAddress]="fromAccountAddress" [toAccountAddress]="toAccountAddress"
      [productType]="productType" [isDomestic]="isDomestic()" [totalVolume]="totalVolume" [addressForm]="toFromAddressFormGroup"
      [sendReceiveForm]="specificationsFormGroup" (chosenServiceOption)="setServiceOption($event)"
      (proceedToOrderDetailsScreen)="proceedToOrderDetailsScreen($event)" (proceedToConfirmationPage)="proceedToConfirmationPage($event)" (quoteId)="quoteSaved($event)" [insuranceSelected]="insurance">
    </app-send-receive-prices>
  </div>
  
  <!-- <div *ngIf="isPaying" class="container">
    <iframe id="paymentFrame" #paymentFrame
      sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts"
      class="full-width frame"></iframe>
  </div> -->

  <div *ngIf="isPaying" class="container">
    <iframe id="paymentFrame" #paymentFrame
      sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts" [src]="iframeURL"
      class="full-width frame"></iframe>
    <!-- [srcdoc]="htmlSrc" (load)="onFrameLoad() [src]="paymentUrl" -->
  </div>
  
  <div *ngIf="paymentSuccess" class="container">
    <div class="paid-order-confirmation-container">
      <div class="paid-order-confirmation-row">
        <h2 class="posta-id">{{'send_receive.send_receive.confirmation.posta_id' | translate}}: {{orderReferenceCallBackId}}</h2>
      </div>
      <div class="paid-order-confirmation-row">
        <div class="paid-order-body">
        {{'send_receive.send_receive.confirmation.confirmation_body' | translate}}
        </div>
        <div *ngIf="!!paymentCodes && paymentCodes.length > 0" class="paid-order-codes">
          {{'send_receive.send_receive.confirmation.payment_codes_title' | translate}}:
          <ul>
            <li>{{paymentCodes}}</li>
          </ul>
        </div>
      </div>
      <div *ngIf="showCustomsRequirements && isCDSFiled" class="paid-order-confirmation-row share-cn22-container">
        <div class="align-text-center" style="padding-bottom: 30px;">
            <h3 class="white" style="font-weight: 600;">{{'buy_postage.confirmation_page.custom_requirements' | translate}}</h3>
        </div>
        <h4 class="white" style="padding-bottom: 30px">{{'buy_postage.confirmation_page.need_custom_forms' | translate}}</h4>
      </div>
      <div class="paid-order-confirmation-row"><div class="paid-order-button-container">
        <div class="flex-item">
          <button [disabled]="downloadingReceipt" class="primary-button paid-order-button" (click)="downloadReceipt()" mat-button>{{'send_receive.send_receive.confirmation.download_order_pdf_button' | translate}}</button>
        </div>
        <div *ngIf="!!orderReferenceOther && isDomestic()" class="flex-item">
          <button [disabled]="downloadingPrintLabel" class="primary-button paid-order-button" (click)="downloadPrintLabel()" mat-button>{{'send_receive.send_receive.confirmation.print_label' | translate}}</button>
        </div>
        <div *ngIf="!!orderBarCode && isCDSFiled" class="flex-item">
          <button [disabled]="downloadingCDS" class="primary-button paid-order-button" (click)="downloadCDS()" mat-button>{{'send_receive.send_receive.confirmation.download_cds' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="paymentFailed" class="container">
  <div class="paid-order-confirmation-container">
    <h2 class="posta-id">{{'send_receive.send_receive.confirmation.payment_failed' | translate}}: {{orderReferenceCallBackId}}</h2>
    <div class="paid-order-body">
      {{'send_receive.send_receive.confirmation.payment_failed_body' | translate}}
    </div>
  </div>
</div>

<div *ngIf="contactPostaScreen" class="container">
  <div class="paid-order-confirmation-container">
    <h2 class="posta-id">{{'send_receive.send_receive.confirmation.contact_posta_title' | translate}}: {{orderReferenceCallBackId}}</h2>
    <div class="paid-order-body">
      {{'send_receive.send_receive.confirmation.contact_posta_body' | translate}}
    </div>
  </div>
</div>

<div *ngIf="createOrderFailed" class="container">
  <div class="paid-order-confirmation-container">
    <h2 class="posta-id">{{'send_receive.send_receive.confirmation.create_order_failed_title' | translate}}</h2>
    <div class="paid-order-body">
      {{'send_receive.send_receive.confirmation.create_order_failed_body' | translate}}
    </div>
  </div>
</div>

<div *ngIf="!!quoteId" class="container">
  <div class="paid-order-confirmation-container">
    <h2 class="posta-id">{{'send_receive.send_receive.quote.posta_id' | translate}}: {{quoteId}}</h2>
    <div class="paid-order-body">
      {{'send_receive.send_receive.quote.quote_body' | translate}}
    </div>
    <!-- <div class="paid-order-codes">
      {{'send_receive.send_receive.confirmation.payment_codes_title' | translate}}:
      <ul>
        <li>{{paymentCodes}}</li>
      </ul>
    </div> -->
    <!-- <div class="paid-order-button-container">
      <div class="flex-item">
        <button class="primary-button paid-order-button" (click)="downloadReceipt()" mat-button>{{'send_receive.send_receive.confirmation.download_order_pdf_button' | translate}}</button>
    </div>
    <div class="flex-item">
        <button class="primary-button paid-order-button" mat-button>{{'send_receive.send_receive.confirmation.view_instructions_button' | translate}}</button>
    </div>
    </div> -->
  </div>
</div>

<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>