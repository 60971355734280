<div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
<div class="container">

    <div class="all-orders-container">
        <div class="row parent-row">
            <div class="col-lg-6 col-12 orders-type-row">
                <div class="row">
                    <div class="col-md-7 col-7 align-center">
                        <img class="hidden-icon" src="/assets/icons/track-trace/Item_Expanded.png">
                        <h2>{{'orders_summary.completed_orders' | translate}}</h2>
                    </div>
                    <div class="col-md-5 col-5 view-all-button-container">
                        <button class="view-all-button" (click)="viewAllWithStatus(2)">{{'orders_summary.view_all' |
                            translate}}</button>
                    </div>
                </div>

                <div class="row table-header-row">
                    <div class="col-xl-2 col-lg-3 col-md-2 col-4 align-center">
                        <h5>{{'orders_summary.posta_id' | translate}}</h5>
                    </div>
                    <div class="col-md-3 align-center hide-mobile hide-small-desktop">
                        <h5>{{'all_orders_page.tracking_id' | translate}}</h5>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-3 col-4 align-center">
                        <h5>{{'orders_summary.from_to' | translate}}</h5>
                    </div>
                    <div class="col-md-3 col-4 align-center">
                        <h5>{{'orders_summary.date_requested' | translate}}</h5>
                    </div>
                    <div class="col-xl-1 col-lg-2 col-md-1 align-center hide-mobile" style="padding: 0px;">
                        <h5>{{'orders_summary.status' | translate}}</h5>
                    </div>
                </div>
                <div *ngIf="!loadingCompleted">
                    <div *ngFor="let order of completedOrders" class="row order-row">
                        <div class="col-xl-2 col-lg-3 col-md-2 col-4 align-center" style="margin: 20px 0px 20px 0px;">
                            <a [routerLink]="routeToOrder(order)">{{order.orderReference}}</a>
                        </div>
                        <div class="col-md-3 hide-mobile hide-small-desktop align-center" style="width: 100%;">
                            <div class="truncate">{{order.trackingCode}}</div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-3 col-4 align-center">
                            <div style="width: 100%;">
                                <div class="truncate">{{order.fromName}}</div>
                                <div class="truncate">{{order.toName}}</div>
                            </div>
                        </div>
                        <div class="col-md-3 col-4 align-center">
                            {{order.estimatedDeliveryDate | date:'dd/MM/yyyy'}}
                        </div>
                        <div class="col-xl-1 col-lg-2 col-md-1 hide-mobile align-center" style="padding: 0px;">
                            <img *ngIf="(!!order.eventTypeId) && (order.serviceTypeId != 3)" class="status-icon" [src]="getIcon(order)" />
                            <h2 class="errorIconText" *ngIf="order.eventTypeId == 3 && order.serviceTypeId == 3">!</h2>
                        </div>
                    </div>
                </div>
                <div *ngIf="loadingCompleted" class="loading-container">
                    <mat-spinner class="loading-spinner" diameter="60"></mat-spinner>
                </div>

            </div>
            <div class="col-lg-6 col-12 orders-type-row">
                <div class="row">
                    <div class="col-7 align-center">
                        <img class="hidden-icon" src="/assets/icons/track-trace/Item_Expanded.png">
                        <h2>{{'orders_summary.live_orders' | translate}}</h2>
                    </div>
                    <div class="col-5 view-all-button-container">
                        <button class="view-all-button" (click)="viewAllWithStatus(1)">{{'orders_summary.view_all' |
                            translate}}</button>
                    </div>
                </div>

                <div class="row table-header-row">
                    <div class="col-xl-2 col-lg-3 col-md-2 col-4 align-center">
                        <h5>{{'orders_summary.posta_id' | translate}}</h5>
                    </div>
                    <div class="col-md-3 hide-mobile hide-small-desktop align-center">
                        <h5>{{'all_orders_page.tracking_id' | translate}}</h5>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-3 hide-mobile align-center">
                        <h5>{{'orders_summary.from_to' | translate}}</h5>
                    </div>
                    <div class="col-md-3 col-4 align-center">
                        <h5>{{'orders_summary.date_requested' | translate}}</h5>
                    </div>
                    <div class="col-xl-1 col-lg-2 col-md-1 col-4 align-center" style="padding: 0px;">
                        <h5>{{'orders_summary.status' | translate}}</h5>
                    </div>
                </div>
                <div *ngIf="!loadingLive">
                    <div *ngFor="let order of liveOrders" class="row order-row">
                        <div class="col-xl-2 col-lg-3 col-md-2 col-4 align-center" style="margin: 20px 0px 20px 0px;">
                            <a [routerLink]="routeToOrder(order)">{{order.orderReference}}</a>
                        </div>
                        <div class="col-md-3 hide-mobile align-center hide-small-desktop" style="width: 100%;">
                            <div class="truncate">{{order.trackingCode}}</div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-3 hide-mobile align-center">
                            <div style="width: 100%;">
                                <div class="truncate">{{order.fromName}}</div>
                                <div class="truncate">{{order.toName}}</div>
                            </div>
                        </div>
                        <div class="col-md-3 col-4 align-center">
                            {{order.estimatedDeliveryDate | date:'dd/MM/yyyy'}}
                        </div>
                        <div class="col-xl-1 col-lg-2 col-md-1 col-4 align-center" style="padding: 0px;">
                            <img *ngIf="(!!order.eventTypeId) && (order.serviceTypeId != 3)" class="status-icon" [src]="getIcon(order)" />
                            <h2 class="errorIconText" *ngIf="order.eventTypeId == 3 && order.serviceTypeId == 3">!</h2>
                        </div>
                    </div>
                </div>
                <div *ngIf="loadingLive" class="loading-container">
                    <mat-spinner class="loading-spinner" diameter="60"></mat-spinner>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>