import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AddressService } from '../../shared/services/address-service';
import { CountryService } from '../../shared/services/country.service';
import { Country } from '../entities/Country';
import { RegisterService } from '../services/register.service';
import { ErrorHelper } from '../../shared/helpers/error-helper';
import { TranslationHelper } from '../../shared/helpers/translation-helper';
import { StaticContentService } from '../../shared/services/static-content.service';

@Component({
  selector: 'app-private-register',
  templateUrl: './private-register.component.html',
  styleUrls: ['./private-register.component.scss']
})
export class PrivateRegisterComponent implements OnInit {

  privateFormGroup: FormGroup;

  noSamleikin: boolean = true;
  
  postcodes: Array<string> = new Array<string>();

  countries: Array<Country> = new Array<Country>();

  preferEmail: boolean = false;

  pageTitle: string;

  interval: any;

  preferSMS: boolean = false;

  failedToCreate: boolean = false;

  saving: boolean = false;

  @Output()
  successfullyCreatedUser: EventEmitter<boolean> = new EventEmitter();

  constructor(private _formBuilder: FormBuilder,
    private translateService: TranslateService,
    private registerService: RegisterService,
    private addressService: AddressService,
    private countryService: CountryService,
    private errorHelper: ErrorHelper,
    private translateHelper: TranslationHelper) { }

  ngOnInit(): void {

    this.getPostcodes();

    this.privateFormGroup = this._formBuilder.group({
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      townCity: ['', Validators.required],
      postcode: [''],
      country: ['', Validators.required],
      specialInstructions: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      jobTitle: [''],
      emailAddress: ['', [Validators.required, ErrorHelper.emailValidator]],
      mobileCountryCode: ['', [Validators.required, ErrorHelper.countryCodeValidator]],
      mobileNum: ['', [Validators.required, ErrorHelper.integerOnlyValidator]],
      specifyPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {validators: this.passwordMatchValidator});

    this.privateFormGroup.get(`mobileCountryCode`).valueChanges.subscribe(newValue => {
      this.translateHelper.prependPlus(this.privateFormGroup.get('mobileCountryCode'));
    });

    this.privateFormGroup.get('mobileCountryCode').setValue('+298');

    this.countryService.getAll().subscribe(x => {this.countries = x;
      this.countries.sort(function(x,y){ return x.countryCode == "FO" ? -1 : y.countryCode == "FO" ? 1 : 0; });
      this.countries.sort(function(x,y){ return x.countryCode == "DK" ? -1 : y.countryCode == "DK" ? 1 : 0; });

      var countryCode = "FO"; //this.translateService.currentLang
      // if (this.translateService.currentLang == "en")
      // {
      //   countryCode = "gb";
      // }
      var country = this.countries.find(y => y.countryCode == countryCode.toUpperCase());
      this.privateFormGroup.get("country").setValue(country);

      // this.translateService.onLangChange.subscribe(x => {
      //   var countryCode = this.translateService.currentLang
      //   if (this.translateService.currentLang == "en")
      //   {
      //     countryCode = "gb";
      //   }
      //   var country = this.countries.find(y => y.countryCode == countryCode.toUpperCase());
      //   this.privateFormGroup.get("country").setValue(country);
      // })
    });
  }

  public getPostcodes() {
    this.addressService.getPostcodes().subscribe((x) => {
      this.postcodes = x
    });
  }

  public getCountryNameForLanguage(country: Country): string
  {
     switch (this.translateService.currentLang) {
       case "en":
         return country.countryNameEN;
       case "fo":
         return country.countryNameFO;
     }
  }

  public clearAll() {
    this.privateFormGroup.reset();
  }

  public isTouched(field){
    return this.privateFormGroup.get(field).touched
  }

  public hasErrors(field){
    var errors = this.privateFormGroup.get(field).errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.privateFormGroup.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }
  
  passwordMatchValidator(g: FormGroup) {
    if(g.get('specifyPassword').value === g.get('confirmPassword').value){
      g.get('confirmPassword').setErrors(null);
      return null;
    }
    else{
      g.get('confirmPassword').setErrors({'mismatch': true});
      return {'mismatch': true};
    }
  }

  public proceed()
  {
    if (!this.privateFormGroup.valid)
    {
      this.privateFormGroup.markAllAsTouched();
      return;
    }

    if (this.privateFormGroup.get('specifyPassword').value !== this.privateFormGroup.get('confirmPassword').value)
    {
      //TODO raise password not matching error
      return;
    }

    this.saving = true;

    var user: any = {
      languageCode: this.translateService.currentLang.toUpperCase(),
      userTypeId: 2,
      firstName: this.privateFormGroup.get('firstName').value,
      lastName: this.privateFormGroup.get('lastName').value,
      jobTitle: this.privateFormGroup.get('jobTitle').value,
      email: this.privateFormGroup.get('emailAddress').value,
      mobileCountryCode: this.privateFormGroup.get('mobileCountryCode').value.replace('+', ''),
      mobileNo: this.privateFormGroup.get('mobileNum').value,
      password: this.privateFormGroup.get('specifyPassword').value,
      preferEmail: this.preferEmail,
      preferSms: this.preferSMS,
      addressLine1: this.privateFormGroup.get('addressLine1').value,
      addressLine2: this.privateFormGroup.get('addressLine2').value,
      townCity: this.privateFormGroup.get('townCity').value,
      postcode: this.privateFormGroup.get('postcode').value,
      countryCode: this.privateFormGroup.get('country').value.countryCode,
      specialInstructions: this.privateFormGroup.get('specialInstructions').value,
    }

    this.registerService.individualRegister(user).subscribe(x => {
      this.successfullyCreatedUser.emit(true);
      this.failedToCreate = false;
      this.saving = false;
      window.scrollTo(0, 0);
    }, (error) => {
      if (error.status === 419)
      {
        this.saving = false;

        if (!!error.error)
        {
          if (error.error.userEmailExists)
          {
            this.privateFormGroup.get('emailAddress').setErrors({emailTaken: true});
          }
          if (error.error.userMobileExists)
          {
            this.privateFormGroup.get('mobileNum').setErrors({mobileTaken: true});
          }
        }
      }
      this.failedToCreate = true;
    });
  }
}
