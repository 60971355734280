import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { OffersProductsComponent } from './offers-products/offers-products.component';
import { ViewAllShoppingListsComponent } from './view-all-shopping-lists/view-all-shopping-lists.component';
import { ViewShoppingListComponent } from './view-shopping-list/view-shopping-list.component';
import { ViewSharedShoppingListComponent } from './view-shared-shopping-list/view-shared-shopping-list.component';

const routes: Routes = [
    { path: ':lang/:role/shopping-services/offers-products', component: OffersProductsComponent},
    { path: ':lang/:role/shopping-services/offers-products/:mode', component: OffersProductsComponent},
    { path: ':lang/:role/shopping-services/view-shopping-list/:shoppingListId', component: ViewShoppingListComponent},
    { path: ':lang/:role/shopping-services/view-all-shopping-lists', component: ViewAllShoppingListsComponent},
    { path: ':lang/:role/shoppinglist/shared/:sharedId', component: ViewSharedShoppingListComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
        provide: 'externalUrlRedirectResolver',
        useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        {
            window.location.href = (route.data as any).externalUrl;
        }
    }
]
})
export class ShoppingServicesRoutingModule { }
