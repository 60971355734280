import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateCompiler, TranslateService } from '@ngx-translate/core';
import { TrackTraceService } from '../../send-receive/new-order/send-and-receive/services/track-trace.service'
import { StaticContentHelper } from '../../shared/helpers/static-content-helper';
import { StaticContentService } from '../../shared/services/static-content.service';
import { TrackTraceItem } from '../entities/trackTraceItem';
import { TrackTraceItemArray } from '../entities/trackTraceItemArray';

@Component({
  selector: 'app-track-and-trace',
  templateUrl: './track-and-trace.component.html',
  styleUrls: ['./track-and-trace.component.scss']
})
export class TrackAndTraceComponent implements OnInit, AfterViewInit {

//TODO - Move the IDs into a config JSON

  deliveredIds = [3,4,5,121];

  attemptedDeliveryIds = [6,7,14,34,35,83];

  returnToSenderIds = [8,10,85];

  inTransitLocationUpdateIds = [38,39,40,41,43,44,45,46,47,48,49,50,52,53,54,55,56,57,58,59,60,61,62,
  64,65,66,67,68,69,70,71,72,75,76,77,78,84,86,87,88,89,90,91,94,97,113,117,120,124];

  delayedNoActionIds = [12,13,15,22,23,24,63,73,108,114];

  unableToDeliveryIds = [9,11,16,17,18,20,21,25,26,28,29,32,33,51,98,99,104,105,107,112,118];

  delayedActionIds = [19,30,31,42,74,79,80,81,82,95,96,100,101,102,103,106,109,110,111,115,116];

  confirmedEventTypeIds = [1,92];

  readyToCollectIds = [27];

  cancelledEventTypeIds = [2, 93];

  receivedIds = [37];

  transactionForm: FormGroup;

  trackTraceItems: any;
  transitItems: Array<TrackTraceItem>;

  dispatchedItem: TrackTraceItem;

  orderConfirmedItem: TrackTraceItem;

  orderReceivedItems: Array<TrackTraceItem>;

  loadingOrder: boolean = false;

  showInvalidTrackingCode: boolean = false;

  currentShownItemIndex: number = 0;

  public pageTitle: string;

  public pageModules: string;

  interval: any;
  observer: MutationObserver;

  constructor(private formBuilder: FormBuilder,
    private trackTraceService: TrackTraceService,
    private route: ActivatedRoute,
    private staticContentService: StaticContentService,
    private translateService: TranslateService,
    private elRef: ElementRef) { }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
    })

    this.transactionForm = this.formBuilder.group({
      transactionId: ['', Validators.required]
    });

    var trackTraceId = this.route.snapshot.paramMap.get('trackTraceId');

    if (!!trackTraceId) {
      this.transactionForm.get("transactionId").setValue(trackTraceId);
      this.getOrderDetails();
    }
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));                               
      });   
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

  public getOrderDetails() {
    this.showInvalidTrackingCode = false;
    this.trackTraceItems = null;
    this.transitItems = null;
    // this.deliveredItem = null;
    this.dispatchedItem = null;
    this.orderConfirmedItem = null;
    this.orderReceivedItems = null;

    if (this.transactionForm.valid) {
      this.loadingOrder = true;
      this.trackTraceService.getTrackTraceDetailsByCode(this.transactionForm.get("transactionId").value).subscribe(x => {
        this.loadingOrder = false;

        if (x == null) {
          this.showInvalidTrackingCode = true;
          return;
        }

        //Find the highest parcel sequence number to find the number of items
        var highestParcelSequenceNum = Math.max.apply(Math, x.map(function(o) { return o.parcelSequenceNo; }));
        let parent: Array<TrackTraceItemArray> = new Array<TrackTraceItemArray>();

        //TODO get the order of sequence numbers and ignore any duplicates e.g 3,3,2,1,2,1 should return 3,2,1
        //This then filters the whole data set first by the sequence number before iterating.

        //put track trace arrays into their correct item
        for (let z=0; z < highestParcelSequenceNum; z++)
        {
          var j: number = 0;
          let res: TrackTraceItemArray = new TrackTraceItemArray();
          for (let i = 0; i < x.length; i++) {
  
            if (i == 0) {
              var array = [x[i]];
              res.items.push(array)
            }
            else if (this.eventTypeIdSameGroup(x[i].eventTypeId, x[i - 1].eventTypeId)) {
              res.items[j].push(x[i]);
            }
            else {
              j++;
              var array = [x[i]];
              res.items.push(array);
            }

          }
          if (z == 0)
          {
            res.showItem = true;
          }
          parent.push(res);
        }


        this.trackTraceItems = parent;

      }, (error) => {
        this.loadingOrder = false;
        this.showInvalidTrackingCode = true;
      })

    }
  }

  public eventTypeIdSameGroup(currentId, previousId)
  {
    var allArrays = [ this.confirmedEventTypeIds, this.cancelledEventTypeIds, this.deliveredIds, this.attemptedDeliveryIds,
      this.returnToSenderIds, this.unableToDeliveryIds, this.delayedNoActionIds, this.delayedActionIds, this.readyToCollectIds, this.receivedIds, this.inTransitLocationUpdateIds];

    for (let i=0; i<allArrays.length;i++)
    {
      var currentArray = allArrays[i];
      if (currentArray.includes(currentId) && currentArray.includes(previousId))
      {
        return true;
      }
    }
    return false;
  }

  public isDeliveredItem(eventTypeId: number)
  {
    return this.deliveredIds.includes(eventTypeId);
  }

  public isTransitLocationUpdateItem(eventTypeId: number)
  {
    return this.inTransitLocationUpdateIds.includes(eventTypeId);
  }

  public isOrderReceivedItem(eventTypeId: number)
  {
    return this.receivedIds.includes(eventTypeId);
  }

  public isDelayedNoActionRequired(eventTypeId: number)
  {
    return this.delayedNoActionIds.includes(eventTypeId);
  }

  public isDelayedActionRequired(eventTypeId: number)
  {
    return this.delayedActionIds.includes(eventTypeId);
  }

  public isConfirmed(eventTypeId: number)
  {
    return this.confirmedEventTypeIds.includes(eventTypeId);
  }

  public isCancelled(eventTypeId: number)
  {
    return this.cancelledEventTypeIds.includes(eventTypeId);
  }

  public isAtemmptedDelivery(eventTypeId: number)
  {
    return this.attemptedDeliveryIds.includes(eventTypeId);
  }

  public isReturnToSender(eventTypeId: number)
  {
    return this.returnToSenderIds.includes(eventTypeId);
  }
  
  public isUnableToDeliver(eventTypeId: number)
  {
    return this.unableToDeliveryIds.includes(eventTypeId);
  }

  public isReadyToCollect(eventTypeId: number)
  {
    return this.readyToCollectIds.includes(eventTypeId);
  }


  public getTransitItemLocationName(locationName: string): string {
    if (!!locationName) {
      return `(${locationName})`;
    }
    return;
  }

  public getPageTitle()
  {
    return this.staticContentService.getPageTitle();
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }
}
