import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Invoice } from '../../shared/entities/Invoice';
import { StaticContentHelper } from '../../shared/helpers/static-content-helper';
import { StaticContentService } from '../../shared/services/static-content.service';
import { InvoiceCustom } from '../entities/InvoiceCustom';

@Component({
  selector: 'app-pay-customs',
  templateUrl: './pay-customs.component.html',
  styleUrls: ['./base-pay-customs.component.scss']
})
export class PayCustomsComponent implements OnInit {

  public image: string;

  interval: any;

  public pageTitle: string;

  public pageModules: string;

  constructor(private staticContentService: StaticContentService, private translateService: TranslateService) { }

  ngOnInit(): void {

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        this.image = this.staticContentService.getImageForPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.image = this.staticContentService.getImageForPsModule();
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule(); 
    })
  }

  public getPageTitle()
  {
    return this.staticContentService.getPageTitle();
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }

}
