<div mat-dialog-title>
    <div class="dialog-title">
        <button mat-icon-button class="close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
          </button>
    </div>
    <div class="d-flex align-center title-container">
        <h2>{{'bera.guest_login.add_lists_to_user' | translate}}</h2>
    </div>
  </div>
<mat-dialog-content class="mat-typography">
    <div class="title-container">
        <!-- <mat-form-field class="search-address-col" floatLabel="never" appearance="outline">
            <input class="text-input" matInput placeholder="Enter item name here"
                type="text" name="manualItemName" [(ngModel)]="manualItemName" autocomplete="off"
                (keyup.enter)="createNewItem()">
        </mat-form-field> -->
        <div class="row" style="padding-left: 20px;">
            <span>{{'bera.guest_login.add_lists_to_user_body' | translate}}</span>
        </div>
        <br>
        <div class="row" style="padding-left: 20px;">
            <span>{{'bera.guest_login.add_lists_to_user_body_line_2' | translate}}</span>
        </div>
        <div class="row">
            <div class="address-menu-item">
                <button class="primary-button add-button" (click)="createShoppingLists()">
                    {{'bera.guest_login.yes' | translate}}
                </button>
                <button class="secondary-button add-button" style="margin-left: 20px;" (click)="onNo()">
                    {{'bera.guest_login.no' | translate}}
                </button>
            </div>
        </div>
    </div>
</mat-dialog-content>