import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslationHelper } from '../../../shared/helpers/translation-helper';
import { CountryGroup } from '../../../shared/price-matrix/entities/CountryGroup';
import { TransportType } from '../../../shared/price-matrix/entities/TransportType';
import { Postage } from '../../entities/postage';

@Component({
  selector: 'app-buy-postage-secondary',
  templateUrl: './buy-postage-secondary.component.html',
  styleUrls: ['./buy-postage-secondary.component.scss']
})
export class BuyPostageSecondaryComponent implements OnInit {

  @Input()
  buyPostageForm: FormGroup;

  @Input()
  fromTo: CountryGroup;

  @Input()
  size: TransportType;

  @Input()
  price: number;

  totalPrice: number = 0;

  @Input()
  postageItems: Array<Postage> = new Array<Postage>();

  @Output()
  paymentEvent: EventEmitter<Array<Postage>> = new EventEmitter();

  @Output()
  backButtonSelected: EventEmitter<any> = new EventEmitter();

  loadingPayment: boolean = false;

  @Input()
  selectedOptionIndex: number;

  domestic: boolean;

  constructor(private translateHelper: TranslationHelper) { }

  ngOnInit(): void {

    if (this.selectedOptionIndex === 0)
    {
      this.domestic = true;
      this.buyPostageForm.get('agreeToTermsConditions').clearValidators();
    }
    else {
      this.buyPostageForm.get('agreeToTermsConditions').setValidators([Validators.requiredTrue]);
    }

    if (!!this.buyPostageForm.get('itemCount')) {
      for (let i = 0; i < this.buyPostageForm.get('itemCount').value; i++) {

        var newPostageItem: Postage = {
          fromTo: this.translateHelper.getTranslation(this.fromTo.Name),
          weight: this.buyPostageForm.get('weight').value,
          sizeName: this.translateHelper.getTranslation(this.size.Name),
          sizeDescription: this.translateHelper.getTranslation(this.size.Description),
          price: this.price,
          reference: ""
        }

        this.totalPrice += newPostageItem.price;
        this.postageItems.push(newPostageItem);
      }
    }
  }

  public deleteItem(postageItem: Postage) {
    const index = this.postageItems.indexOf(postageItem, 0);
    if (index > -1) {
      this.postageItems.splice(index, 1);
      this.totalPrice -= postageItem.price;
      this.buyPostageForm.get("itemCount")?.setValue(this.postageItems.length);
    }
  }

  public logItems()
  {
    console.log(this.postageItems);
  }

  public proceedToPayment()
  {
    this.paymentEvent.emit(this.postageItems);
  }

  public goToPreviousPage()
  {
    this.backButtonSelected.emit(true);
  }

  public incrementItemCount() {
    var itemCount = this.buyPostageForm.get("itemCount").value;
    this.buyPostageForm.get("itemCount").setValue(Number(itemCount) + 1);
    var newPostageItem: Postage = {
      fromTo: this.translateHelper.getTranslation(this.fromTo.Name),
      weight: this.buyPostageForm.get('weight').value,
      sizeName: this.translateHelper.getTranslation(this.size.Name),
      sizeDescription: this.translateHelper.getTranslation(this.size.Description),
      price: this.price,
      reference: ""
    }

    this.totalPrice += newPostageItem.price;
    this.postageItems.push(newPostageItem);
  }

  public decreaseItemCount() {
    var itemCount = this.buyPostageForm.get("itemCount").value;
    this.buyPostageForm.get("itemCount").setValue(Number(itemCount) - 1);
    var lastPostageItem = this.postageItems[this.postageItems.length - 1];
    this.totalPrice -= lastPostageItem.price;
    this.postageItems.pop();
  }

  public itemCountChange(){
    var itemCount = this.buyPostageForm.get("itemCount").value;
    var currentItems = this.postageItems.length;
    if(itemCount > currentItems){
      var itemsToAdd = itemCount - currentItems;
      for (let i = 0; i < itemsToAdd; i++) {

        var newPostageItem: Postage = {
          fromTo: this.translateHelper.getTranslation(this.fromTo.Name),
          weight: this.buyPostageForm.get('weight').value,
          sizeName: this.translateHelper.getTranslation(this.size.Name),
          sizeDescription: this.translateHelper.getTranslation(this.size.Description),
          price: this.price,
          reference: ""
        }

        this.totalPrice += newPostageItem.price;
        this.postageItems.push(newPostageItem);
      }
    }
    else if(itemCount < currentItems){
      var itemsToRemove = currentItems - itemCount;
      for (let i = 0; i < itemsToRemove; i++) {
        this.postageItems.pop();
      }
    }
  }

}