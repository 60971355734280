import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Country } from '../../../../account/entities/Country';
import { ErrorHelper } from '../../../../shared/helpers/error-helper';
import { AddressService } from '../../../../shared/services/address-service';
import { CountryService } from '../../../../shared/services/country.service';
import { AuthenticationService } from '../../../../account/services/authentication.service';
import { RoleService } from '../../../../account/services/role.service';
import { CookieService } from '../../../../shared/services/cookie.service';
import { Observable } from 'rxjs';
import { Address } from '../../../../account/entities/address';
import { map, startWith } from 'rxjs/operators';
import { User } from '../../../../account/entities/user';
import { UserService } from '../../../../account/services/user.service';

@Component({
  selector: 'app-buy-postage-addresses',
  templateUrl: './buy-postage-addresses.component.html',
  styleUrls: ['./buy-postage-addresses.component.scss']
})
export class BuyPostageAddressesComponent implements OnInit {


  public addresses: Array<Address> = new Array<Address>();
  public fromFilteredAddresses: Observable<Array<Address>>;
  public toFilteredAddresses: Observable<Array<Address>>;

  @Input()
  buyPostageForm: FormGroup;
  @Input()
  buyPostageAddressesForm: FormGroup;
  @Input() emailForm: FormGroup;

  @Output()
  backButtonSelected: EventEmitter<any> = new EventEmitter();

  @Output()
  proceedButtonSelected: EventEmitter<any> = new EventEmitter();

  postcodes: any[];

  countries: Array<Country>;
  isLoggedIn: boolean;
  isAccountAddress: boolean = true;
  isNewAddress: boolean = true;

  fromAddressControl = new FormControl();
  toAddressControl = new FormControl();
  user: User;

  constructor(
    private errorHelper: ErrorHelper, 
    public translate: TranslateService, 
    private countryService: CountryService,
    private addressService: AddressService,
    private cookieService: CookieService,
    private authService: AuthenticationService,
    private router: Router,
    public roleService: RoleService,
    public userAccountService: UserService) 
    {
      this.addressService.getPostcodes().subscribe((x) => {
        this.postcodes = x;
        this.userAccountService.getUserDetails().subscribe(x => {
          this.user = x;
          if(this.isLoggedIn && this.user){
            this.accountAddressClick();
          }
        });
      });
      
    }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();

    this.countryService.getAll().subscribe(x => {
      this.countries = x;
      switch (this.translate.currentLang) {
        case "en":
          this.countries.sort((a, b) => a.countryNameEN.localeCompare(b.countryNameEN))
        case "fo":
          this.countries.sort((a, b) => a.countryNameFO.localeCompare(b.countryNameFO))
      }
      //International, so never sending to FO here
      var foIndex = this.countries.findIndex(x => x.countryCode == "FO");
      this.countries.splice(foIndex, 1);

      this.countries.sort(function(x,y){ return x.countryCode == "DK" ? -1 : y.countryCode == "DK" ? 1 : 0; });
   });
  }
  
  public hasErrors(field){
    var errors = this.buyPostageAddressesForm.get(field)?.errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.buyPostageAddressesForm.get(field)?.errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public setSelectedPostcodeFO(event)
  {
    if(event.source.id == "fromTownCity"){
      var selectedPostcode = this.postcodes.find(pc => pc.city == event.value);
      this.buyPostageAddressesForm.get("fromPostcode").setValue(selectedPostcode.postcode, { emitEvent: false });
    }
    else{
      var selectedPostcode = this.postcodes.find(pc => pc.postcode == event.value);
      this.buyPostageAddressesForm.get("fromTownCity").setValue(selectedPostcode.city, { emitEvent: false });
    }
  }
  
  public getCountryNameForLanguage(country: Country): string
  {
     switch (this.translate.currentLang) {
       case "en":
         return country.countryNameEN;
       case "fo":
         return country.countryNameFO;
     }
  }

  public setCountryByCode(country: Country): Country {
    var countryControl: Country = this.buyPostageAddressesForm.get("toCountry").value;
    if (!!countryControl)
    {
      var countryCodeByControl = countryControl.countryCode;

      if (country.countryCode == countryCodeByControl)
      {
        this.buyPostageAddressesForm.get("toCountry").setValue(country, {emitEvent: false});
      }
    }
    return country;
  }

  public goToPreviousPage()
  {
    this.backButtonSelected.emit(true);
  }

  public goToNextPage(){
    if(this.checkProceedDisabled()){
      this.buyPostageAddressesForm.markAllAsTouched();
      this.attemptedToProgress = true;
      return;
    }
    this.proceedButtonSelected.emit(true);
  }

  public clearAll(){
    this.buyPostageAddressesForm.reset();
  }

  public onLoggedIn(status: boolean){
    if(status){
      //check logged in state
      this.isLoggedIn = this.authService.isLoggedIn();
      if(this.emailForm){
        this.emailForm.get('email').disable();
      }
      this.isAccountAddress = true;
      
      this.userAccountService.getUserDetails().subscribe(x => {
        this.user = x;
        this.accountAddressClick();
      });
    }
  }

  navigateTo(target: string){
    this.router.navigateByUrl(`/${this.translate.currentLang}/${this.roleService.role()}/${target}`);
  }

  getPrivacyLink(){
    return ["/", this.translate.currentLang, this.roleService.role(), `help-information`,`privacy-cookies`];
  }

  checkProceedDisabled(){
    if(!this.buyPostageAddressesForm.valid){
      return true;
    }
    if(this.isLoggedIn){
      // No need to check guest email valid if logged in
      return false;
    }
    if(this.emailForm && !this.emailForm.valid){
      return true;
    }
    return false;
  }

  setFromAddressFields(){
    this.buyPostageAddressesForm.get('fromFirstName').setValue(this.selectedFromAddress.firstName);
    this.buyPostageAddressesForm.get('fromLastName').setValue(this.selectedFromAddress.lastName);
    this.buyPostageAddressesForm.get('fromContactCountryCode').reset();
    this.buyPostageAddressesForm.get('fromContactNum').setValue(this.selectedFromAddress.contactNumber);
    this.buyPostageAddressesForm.get('fromAddressLine1').setValue(this.selectedFromAddress.addressLine1);

    var postcode = this.postcodes.find(pc => pc.postcode == this.selectedFromAddress.postcode);
    if(postcode){
      this.buyPostageAddressesForm.get('fromTownCity').setValue(postcode.city, { emitEvent: false });
      this.buyPostageAddressesForm.get('fromPostcode').setValue(postcode.postcode, { emitEvent: false });
    }
  }

  setToAddressFields(){
    this.buyPostageAddressesForm.get('toFirstName').setValue(this.selectedToAddress.firstName);
    this.buyPostageAddressesForm.get('toLastName').setValue(this.selectedToAddress.lastName);
    this.buyPostageAddressesForm.get('toContactCountryCode').reset();
    this.buyPostageAddressesForm.get('toContactNum').setValue(this.selectedToAddress.contactNumber);
    this.buyPostageAddressesForm.get('toAddressLine1').setValue(this.selectedToAddress.addressLine1);
    this.buyPostageAddressesForm.get('toAddressLine2').setValue(this.selectedToAddress.addressLine2);
    var country = this.countries.find(c => c.countryCode == this.selectedToAddress.countryCode);
    this.buyPostageAddressesForm.get('toCountry').setValue(country);
    this.buyPostageAddressesForm.get('toPostcode').setValue(this.selectedToAddress.postcode);
    this.buyPostageAddressesForm.get('toTownCity').setValue(this.selectedToAddress.townCity);
  }

  selectedFromAddress: Address;
  setFromSelectedAddress(address: Address){
    this.selectedFromAddress = address;
  }

  selectedToAddress: Address;
  setToSelectedAddress(address: Address){
    this.selectedToAddress = address;
  }

  accountAddressClick(){
    if(this.isAccountAddress){
      //Set from account address
      this.buyPostageAddressesForm.get('fromFirstName').setValue(this.user.firstName);
      this.buyPostageAddressesForm.get('fromFirstName').disable();

      this.buyPostageAddressesForm.get('fromLastName').setValue(this.user.lastName);
      this.buyPostageAddressesForm.get('fromLastName').disable();
      
      this.buyPostageAddressesForm.get('fromContactCountryCode').setValue(this.user.mobileCountryCode);
      this.buyPostageAddressesForm.get('fromContactCountryCode').disable();
      
      this.buyPostageAddressesForm.get('fromContactNum').setValue(this.user.mobileNo);
      this.buyPostageAddressesForm.get('fromContactNum').disable();
      
      this.buyPostageAddressesForm.get('fromAddressLine1').setValue(this.user.addressLine1);
      this.buyPostageAddressesForm.get('fromAddressLine1').disable();

      var postcode = this.postcodes.find(pc => pc.postcode == this.user.postcode);
      if(postcode){
        this.buyPostageAddressesForm.get('fromTownCity').setValue(postcode.city, { emitEvent: false });
        this.buyPostageAddressesForm.get('fromTownCity').disable();

        this.buyPostageAddressesForm.get('fromPostcode').setValue(postcode.postcode, { emitEvent: false });
        this.buyPostageAddressesForm.get('fromPostcode').disable();
      }
    }
    else{
      this.buyPostageAddressesForm.get('fromFirstName').enable();
      this.buyPostageAddressesForm.get('fromLastName').enable();
      this.buyPostageAddressesForm.get('fromContactCountryCode').enable();
      this.buyPostageAddressesForm.get('fromContactNum').enable();
      this.buyPostageAddressesForm.get('fromAddressLine1').enable();
      this.buyPostageAddressesForm.get('fromPostcode').enable();
      this.buyPostageAddressesForm.get('fromTownCity').enable();
      this.buyPostageAddressesForm.get('fromContactCountryCode').enable();
    }
  }

  public addressFieldClick(isToAddress: boolean) {
    this.addressService.getAll().subscribe((x) => {
      this.addresses = x;
      if(isToAddress){
        this.toFilteredAddresses = this.toAddressControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
          map(firstName => firstName ? this._filter(firstName) : this.addresses.slice()),
        )  
      }
      else{
        this.fromFilteredAddresses = this.fromAddressControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
          map(firstName => firstName ? this._filter(firstName) : this.addresses.slice()),
        )  
      }
    });
  }
  private _filter(value: string): Array<Address> {
    const filterValue = value.toLowerCase();

    return this.addresses.filter(option => `${option.firstName} ${option.lastName}, ${option.postcode}`.toLowerCase().includes(filterValue));
  }
  
  displayAddress(address: Address): string {
    return address && `${address.firstName} ${address.lastName}, ${address.postcode}` ? `${address.firstName} ${address.lastName}, ${address.postcode}` : '';
  }

  attemptedToProgress: boolean;

  noEmailOrAccount(){
    return !(this.isLoggedIn || this.emailForm.valid) && this.attemptedToProgress;
  }

}
