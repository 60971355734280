import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from '../../../send-receive/services/payment.service';

@Component({
  selector: 'app-customs-confirmation',
  templateUrl: './customs-confirmation.component.html',
  styleUrls: ['./customs-confirmation.component.scss']
})
export class CustomsConfirmationComponent implements OnInit {

  @Input()
  transactionRef: string;

  @Input()
  serviceTypeId: number;

  @Input()
  customsFormGroup: FormGroup;

  downloadingInvoice: boolean = false;

  downloadingReceipt: boolean = false;

  constructor(private paymentService: PaymentService, private translateService: TranslateService) { }

  invoiceError: string;

  ngOnInit(): void {
  }

  //TODO refactor below
  public downloadInvoice()
  {
    this.invoiceError = undefined;
    this.downloadingInvoice = true;
    this.paymentService.downloadInvoice(this.serviceTypeId, this.customsFormGroup.get("invoicePoNumber").value, this.customsFormGroup.get("refCode").value).subscribe(x => {

      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingInvoice = false;
    }, (y) => {
      this.downloadingInvoice = false;
      this.invoiceError = y.error;
    })
  }

  public downloadReceipt() {
    this.downloadingReceipt = true;
    this.paymentService.downloadReceipt(this.serviceTypeId, this.customsFormGroup.get("invoicePoNumber").value, this.customsFormGroup.get("refCode").value, this.translateService.currentLang).subscribe(x => {

      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingReceipt = false;
    }, (error) => {
      this.downloadingReceipt = false;
    })
  }

}
