import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { StaticContent } from '../../shared/static-content/entities/staticContent';
// import { LanguageService } from '../../shared/services/language.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticContentService } from '../../shared/services/static-content.service';
import { Page } from '../../shared/static-content/entities/page';
import { RoleService } from '../../account/services/role.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, AfterViewInit {

  public staticContent: StaticContent

  observer: MutationObserver;

  public modules: any;

  public mainImage: string;

  public imageUrlFormatted: boolean = false;

  public trackTraceInput: string;

  public pageHtml: string;

  public showHtmlModules: boolean;

  interval: any;

  public mainImageModule: any;

  public item1Icon: any;
  
  public item2Icon: any;

  public item3Icon: any;

  public role: string;

  public cdnPath: string;

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private roleService: RoleService,
    private staticContentService: StaticContentService,
    private elRef: ElementRef,
    private titleService: Title) { }

  ngOnInit(): void {

    var urlLang = this.route.snapshot.data.lang;
    this.cdnPath = this.staticContentService.cdn();

    if (!!urlLang) {

      // this.languageService.setLanguage(urlLang);
    }

    // this.staticContent = this.route.snapshot.data.staticContent;

    this.interval = setInterval(() => {
      this.staticContent = this.staticContentService.staticContent;
      if (!!this.staticContent) {
        this.setPageContentForLanguage();
        var axdFiles = this.staticContent.GlobalCss.split(";");
        axdFiles.forEach(x => {
          this.staticContentService.loadjscssfile(x, "css");
        });
        var jsAxdFiles = this.staticContent.GlobalJs.split(";");
        jsAxdFiles.forEach(x => {
          this.staticContentService.loadjscssfile(x, "js");
        });
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setPageContentForLanguage();
    });
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));                               
      });   
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }
  
  public onTrackTraceEnter(){
    this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/send-receive/track-trace`, this.trackTraceInput]);
  }

  private setPageContentForLanguage() {
    var languageSection;
    var privateSection;
    var homeCategory;
    var homepage;

    var tabTitle = this.staticContentService.getPageTabTitle();
    this.titleService.setTitle(tabTitle)

    var routeSplit = this.router.url.split("/");
    if (!!routeSplit && routeSplit.length > 2)
    {
      this.role = routeSplit[2];
    }

    if (!!this.staticContent && !!this.role) {
        languageSection = this.staticContent.Sections.find(x => x.Url == `/${this.translateService.currentLang}/`);
        if (!!languageSection) {
          privateSection = languageSection.SubSections.find(x => x.Url == `/${this.translateService.currentLang}/${this.role}/`);
          if (!!privateSection) {
            homeCategory = privateSection.Categories.find(x => x.Url == `/${this.translateService.currentLang}/${this.role}/home/`);
          }
          if (!!homeCategory && !!homeCategory.Pages) {
            homepage = homeCategory.Pages.find(x => x.Url == `/${this.translateService.currentLang}/${this.role}/home/home/`);
          }
        }

      if (!!homepage && !!homepage.Modules) {
        this.modules = homepage.Modules;

        for (var i = 0; i < this.modules.length; i++) {
          if (!!this.modules[i].Content) {
            this.modules[i].Content.Image = this.formatImageUrl(this.modules[i].Content)
          }
        }

        this.mainImageModule = this.modules.find(x => x.ModuleType == "psModule");

        if (!!this.mainImageModule) {
          this.mainImage = this.mainImageModule.Content.Image;
          this.item1Icon = `${this.cdnPath}${this.mainImageModule.Content.Item1Icon}`;
          this.item2Icon = `${this.cdnPath}${this.mainImageModule.Content.Item2Icon}`;
          this.item3Icon = `${this.cdnPath}${this.mainImageModule.Content.Item3Icon}`;
        }

        if (!!languageSection) {
          if (!!privateSection) {
            var helpInformationCategory = privateSection.Categories.find(x => x.Url == `/${this.translateService.currentLang}/${this.role}/help-information/`);
          }
          if (!!helpInformationCategory && !!helpInformationCategory.Pages) {
            var contactUsPage = helpInformationCategory.Pages.find(x => x.Url == `/${this.translateService.currentLang}/${this.role}/help-information/contact-us/`);
            this.setHtmlModulePages(contactUsPage);
          }
        }

      }
    }

    return languageSection;
  }

  formatImageUrl(content: any) {
    if (!!content.Image && content.Image.includes(`${this.cdnPath}`)) {
      return content.Image;
    }
    else if (!!content.Image) {
      return `${this.cdnPath}${content.Image}`;
    }
    return undefined;
  }

  public setHtmlModulePages(homepage: Page) {
    if (!!homepage.Css) {
      var cssFiles = homepage.Css.split(";");
      cssFiles.forEach(x => this.staticContentService.loadjscssfile(x, "css"));
    }

    //console.log(htmlPageString);

    if (!!homepage.Js) {
      homepage.Js = homepage.Js.replace('\r\n', '');
      var jsFiles = homepage.Js.split(";");
      jsFiles.forEach(x => this.staticContentService.loadjscssfile(x, "js"));
    }

    var htmlPageString: string = "";

    var htmlModules = homepage.Modules.filter(x => x.ModuleType == "moduleHtml");
    htmlModules.forEach(x => htmlPageString = htmlPageString.concat(x.Html));
    htmlPageString = this.decodeHtml(htmlPageString);

    var firstReg = /img src=\"/g;
    htmlPageString = htmlPageString.replace(firstReg, `img src=\"${this.cdnPath}/`);

    var secondReg = /img src='\//g;
    htmlPageString = htmlPageString.replace(secondReg, `img src='${this.cdnPath}/`);

    var thirdReg = /img src=\\\""/g;
    htmlPageString = htmlPageString.replace(thirdReg, `img src=\\\"${this.cdnPath}/`);

    var fourthReg = /background-image:url\('\//g;
    htmlPageString = htmlPageString.replace(fourthReg, `background-image:url('${this.cdnPath}/`);

    // Adjusted regular expression with a capture group for the full path
    var pdfReg = /href='(?!http)(\/media\/[^']+)'/g;
    htmlPageString = htmlPageString.replace(pdfReg, `href='${this.cdnPath}$1'`);

    // Adjusted regex for double quotes
    var pdfReg2 = /href="(?!http)(\/media\/[^"]+)"/g;
    htmlPageString = htmlPageString.replace(pdfReg2, `href="${this.cdnPath}$1"`);
    // htmlPageString = this.decodeHtml(htmlPageString);
    this.pageHtml = htmlPageString;

    this.showHtmlModules = true;
  }

  public decodeHtml(html: string) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
}
