<div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
<div class="container">
    <div class="row toolbar">
        <div class="col-lg-2 col-md-3 col-6 address-menu-item favourite-container">
            <button class="secondary-button padded no-right-border favourite-button" (click)="toggleFavourites()">
                <span class="favourite-icon"></span>
                <span>{{'address_book.favourites' | translate}}</span>
            </button>
        </div>
        <div class="col-lg-2 col-md-3 col-6 address-menu-item view-all-container">
            <button class="primary-button padded" (click)="showAllAddresses()">{{'address_book.view_all' | translate}}</button>
        </div>
        <div class="col-lg-8 col-md-6 col-12 search-container">
            <mat-form-field class="search-address-col" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'send_receive.send_receive.step_one.search_addresses' | translate}}" type="text" name="search"
                    [(ngModel)]="searchText" autocomplete="off" (keyup.enter)="updateSearchText()">
            </mat-form-field>
            <div class="address-menu-item">
                <button class="primary-button search-button" (click)="updateSearchText()">
                    <img class="search-icon" src="/assets/icons/address_book/search_white@3x.png" />
                </button>
            </div>
            <div class="address-menu-item">
                <button class="primary-button add-new-button" (click)="setAddNewAddress()">
                    <img class="search-icon" src="/assets/icons/address_book/add_address@3x.png" />
                </button>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div *ngIf="!showAddNewAddress && !isEditing">
        <app-view-all-addresses [searchText]="updatedSearchText" [viewOnlyFavourites]="viewOnlyFavourites"
            (selectedAddress)="editAddress($event)"></app-view-all-addresses>
    </div>
    <div *ngIf="showAddNewAddress && !isEditing" class="row new-address-container">
        <app-create-new-address class="full-width" [sendReceiveForm]="newAddressFormGroup" [newAddressBookEntry]="true"
            (successfullySaved)="setShowAddNewAddress($event)"></app-create-new-address>
    </div>
    <div *ngIf="isEditing" class="row new-address-container">
        <app-edit-address class="full-width" [addressToEdit]="addressToEdit" [sendReceiveForm]="newAddressFormGroup"
            (successfullySaved)="setShowAddNewAddress($event)"></app-edit-address>
    </div>
</div>
<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>