import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../account/entities/user';
import { StaticContentService } from '../../../shared/services/static-content.service';
import { PostageOrder } from '../../entities/postageOrder';
import { BuyPostageService } from '../../services/buy-postage.service';

@Component({
  selector: 'app-view-postage-order',
  templateUrl: './view-postage-order.component.html',
  styleUrls: ['./view-postage-order.component.scss']
})
export class ViewPostageOrderComponent implements OnInit {

  order: PostageOrder;

  userDetails: User;

  totalAmount: number;

  countryName: string;

  public pageTitle: string;

  interval: any;
  downloadingCDS: boolean = false;

  constructor(private route: ActivatedRoute, private translateService: TranslateService, private staticContentService: StaticContentService, private buyPostageService: BuyPostageService) { }

  ngOnInit(): void {

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.staticContentService.getPageTitle();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.staticContentService.getPageTitle();
    })


    this.order = this.route.snapshot.data.order;

    this.userDetails = this.route.snapshot.data.userDetails;

    var netAmounts = this.order.orderDetail.map(x => x.netAmount);

    this.totalAmount = netAmounts.reduce((a, b) => a + b, 0);

    this.getCountryName();
  }

  public getCountryName() {
    switch (this.translateService.currentLang) {
      case "en":
        this.countryName = `${this.userDetails.country.countryNameEN}`;
        break;
      case "fo":
        this.countryName = `${this.userDetails.country.countryNameFO}`;
        break;
    }
  }

  public downloadCDS() {
    this.downloadingCDS = true;
    this.buyPostageService.downloadCDS(this.order.barcode).subscribe(x => {
      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingCDS = false;

      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    }, (error) => {
      this.downloadingCDS = false;
      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    })
  }
}
