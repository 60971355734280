import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-awaiting-verification',
  templateUrl: './awaiting-verification.component.html',
  styleUrls: ['./awaiting-verification.component.scss']
})
export class AwaitingVerificationComponent implements OnInit {

  showEmailSentBody: boolean = false;

  constructor(private route: ActivatedRoute, private roleService: RoleService) { }

  ngOnInit(): void {
    
    if (this.route.snapshot.paramMap.get('email-sent') == "email-sent")
    {
      this.showEmailSentBody = true;
    }
  }

  isPrivateUser()
  {
    return this.roleService.isPrivateUser();
  }

}
