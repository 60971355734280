import { Country } from "./Country";

export class User {
    userId?: string;
    username?: string;
    title?: string;
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    townCity: string;
    postcode?: string;
    country?: Country;
    contactNumber?: number;
    password?: string;
    countryCode?: string;

    email?: string;
    mobileCountryCode: string;
    mobileNo: string;
    recipientName?: string;
    preferEmail?: boolean;
    preferSms?: boolean;
    specialInstructions: string;
    isSamleikin?: boolean;
    languageCode: string;
    isProfileComplete?: boolean;
    isVerified?: boolean;

}
