import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { last } from 'lodash';
import { Country } from './../../../../account/entities/Country';
import { Postage } from './../../../entities/postage';
import { CustomShipmentType } from './../../../../shared/entities/customShipmentType';
import { TranslationHelper } from './../../../../shared/helpers/translation-helper';
import { CountryGroup } from './../../../../shared/price-matrix/entities/CountryGroup';
import { TransportType } from './../../../../shared/price-matrix/entities/TransportType';
import { CustomsItemType } from './../../../entities/CustomsItemType';
import { CustomShipmentTypeService } from './../../../../shared/services/custom-shipment-types.service';
import { CountryService } from './../../../../shared/services/country.service';
import { BuyPostageItemTypesComponent } from '../buy-postage-item-types/buy-postage-item-types.component';
import { ErrorHelper } from '../../../../shared/helpers/error-helper';

@Component({
  selector: 'app-buy-postage-customs',
  templateUrl: './buy-postage-customs.component.html',
  styleUrls: ['./buy-postage-customs.component.scss']
})
export class BuyPostageCustomsComponent implements OnInit {

  @Input()
  buyPostageForm: FormGroup;
  @Input()
  buyPostageAddressesForm: FormGroup;
  @Input()
  buyPostageCustomsForm: FormGroup;
  
  @Input()
  fromTo: CountryGroup;
  @Input()
  size: TransportType;
  @Input()
  price: number;

  postageItem: Postage;

  loadingPayment: boolean = false;

  @Input()
  itemTypes: CustomsItemType[];

  weightLimit: number;
  weightIsKg: boolean;

  @Output()
  backButtonSelected: EventEmitter<any> = new EventEmitter();
  @Output()
  paymentEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild(BuyPostageItemTypesComponent) itemTypesComponent!: BuyPostageItemTypesComponent;

  public shipmentTypes: Array<CustomShipmentType>;
  selectedShipmentType: CustomShipmentType;
  otherSelected: boolean;

  constructor(
    private translate: TranslateService,
    private translateHelper: TranslationHelper,
    private customShipmentTypeService: CustomShipmentTypeService,
    private errorHelper: ErrorHelper) { }

  ngOnInit(): void {
    this.postageItem = {
      fromTo: this.translateHelper.getTranslation(this.fromTo.Name),
      weight: this.buyPostageForm.get('weight').value,
      sizeName: this.translateHelper.getTranslation(this.size.Name),
      sizeDescription: this.translateHelper.getTranslation(this.size.Description),
      price: this.price,
      reference: ""
    }

    var weightText : string = this.buyPostageForm.get('weight').value;
    var hyphenIndex = weightText.indexOf('-');
    if(hyphenIndex){
      weightText = weightText.substring(hyphenIndex + 1);
    }
    if(weightText.endsWith('kg')){
      weightText = weightText.substring(0, weightText.length - 2);
      this.weightIsKg = true;
    }
    else if(weightText.endsWith('g')){
      weightText = weightText.substring(0, weightText.length - 1)
    }
    this.weightLimit = parseInt(weightText);
    if(!this.weightIsKg){
      this.weightLimit = this.weightLimit / 1000;
    }

    this.customShipmentTypeService.getAll().subscribe(x => {
      this.shipmentTypes = x;
    });
    
  }

  public hasErrors(field) {
    var errors = this.buyPostageCustomsForm.get(field).errors;
    return !!errors;
  }

  public getError(field) {
    var errors = this.buyPostageCustomsForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  getAddressValue(fieldId){
    if(fieldId == 'fromContactName'){
      var first = this.buyPostageAddressesForm.get('fromFirstName')?.value;
      var second = this.buyPostageAddressesForm.get('fromLastName')?.value;
      return `${first} ${second}`;
    }
    else if(fieldId == 'toContactName'){
      var first = this.buyPostageAddressesForm.get('toFirstName')?.value;
      var second = this.buyPostageAddressesForm.get('toLastName')?.value;
      return `${first} ${second}`;
    } 
    else if(fieldId == 'toCountry'){
      var country = this.buyPostageAddressesForm.get('toCountry')?.value;
      switch (this.translate.currentLang) {
        case "en":
          return country.countryNameEN;
        case "fo":
          return country.countryNameFO;
      }
    } 
    return this.buyPostageAddressesForm.get(fieldId)?.value;
  }

  public getCustomsShipmentTypeName(shipmentType: CustomShipmentType) {
    switch (this.translate.currentLang) {
      case "en":
        return `${shipmentType.customsShipmentTypeNameEN}`;
      case "fo":
        return `${shipmentType.customsShipmentTypeNameFO}`;
    }
  }

  goToPreviousPage(){
    this.backButtonSelected.emit(true);
  }

  goToNextPage(){
    if(this.checkProceedDisabled() || this.loadingPayment){
      this.buyPostageCustomsForm.markAllAsTouched();
      return;
    }
    this.paymentEvent.emit([this.postageItem]);
  }

  getTotalWeight(){
    var weight = 0;
    this.itemTypes.forEach(element => {
      if(+element.netWeight){
        weight += (+element.netWeight);
      }
    });
    if(weight){
      return weight;
    }
    return 0;
  }
  getTotalWeightText(){
    return this.translateHelper.getNumberString(this.getTotalWeight());
  }

  getTotalValue(){
    var value = 0;
    this.itemTypes.forEach(element => {
      if(+element.value){
        value += element.value;
      }
    });
    if(value){
      return value;
    }
    return 0;
  }

  getTotalValueText(){
    return this.translateHelper.getNumberString(this.getTotalValue());
  }

  public onSelectCommerical(event: any, shipmentType: CustomShipmentType) {
    //If purchase / commerical
    if (shipmentType.customsShipmentTypeId == 999) {
      this.otherSelected = true;
      this.buyPostageCustomsForm.get('shipmentTypeOther').enable();
    }
    else{
      this.otherSelected = false;
      this.buyPostageCustomsForm.get('shipmentTypeOther').disable();
    }
    this.buyPostageCustomsForm.get('shipmentType').setValue(shipmentType.customsShipmentTypeId)
  }

  public checkPurchaseCommercial(shipmentType: CustomShipmentType) {
    var field = this.buyPostageCustomsForm.get('shipmentType');
    if (!!field) {
      if (field.value == shipmentType.customsShipmentTypeId) {
        return true;
      }
    }
    else if (shipmentType.customsShipmentTypeId == 1) {
      return true;
    }
    return undefined;
  }

  clearAll(){
    this.itemTypes = [new CustomsItemType()];
  }

  checkProceedDisabled(){
    var disabled = (!this.buyPostageCustomsForm.valid) || this.weightTooHigh();
    if(!this.itemTypesComponent.CheckValid()){
      disabled = true;
    }
    return disabled;
  }

  weightTooHigh(){
    return this.weightLimit < this.getTotalWeight();
  }

}
