import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendReceiveRoutingModule } from './send-receive-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { SendAndReceiveComponent } from './new-order/send-and-receive/send-and-receive.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SendAndReceiveAddressesComponent } from './new-order/send-and-receive/addresses/send-and-receive-addresses.component';
import { SendAndReceiveSpecificationComponent } from './new-order/send-and-receive/specification/send-and-receive-specification.component';
import { SendReceivePricesComponent } from './new-order/send-and-receive/specification/send-receive-prices/send-receive-prices.component';
import { OrderDetailsComponent } from './new-order/order-details/order-details.component';
import { SharedModule } from '../shared/shared.module';
import { SendReceiveConfirmationComponent } from './new-order/send-and-receive/send-receive-confirmation/send-receive-confirmation.component';
import { FromAccountAddressComponent } from './new-order/send-and-receive/addresses/account-address/from-account-address.component';
import { ToAccountAddressComponent } from './new-order/send-and-receive/addresses/account-address/to-account-address.component';
import { PostaDepotAddressComponent } from './new-order/send-and-receive/addresses/posta-depot-address/posta-depot-address.component';
import { FindPricesComponent } from './new-order/find-prices/find-prices/find-prices.component';
import { ViewPricesComponent } from './new-order/find-prices/view-prices/view-prices.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { BuyPostageComponent } from './buy-postage/buy-postage/buy-postage.component';
import { BuyPostageSecondaryComponent } from './buy-postage/buy-postage-secondary/buy-postage-secondary.component';
import { BuyPostageConfirmationComponent } from './buy-postage/buy-postage-confirmation/buy-postage-confirmation.component';
import { BuyPostageLandingComponent } from './buy-postage/buy-postage-landing/buy-postage-landing.component';
import { TrackAndTraceComponent } from './track-and-trace/track-and-trace.component';
import { ViewPostageOrderComponent } from './view-order/view-postage-order/view-postage-order.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { TestComponent } from './test/test.component';
import { BuyPostageAddressesComponent } from './buy-postage/international-goods/buy-postage-addresses/buy-postage-addresses.component';
import { BuyPostageCustomsComponent } from './buy-postage/international-goods/buy-postage-customs/buy-postage-customs.component';
import { BuyPostageItemTypesComponent } from './buy-postage/international-goods/buy-postage-item-types/buy-postage-item-types.component';
import { InlineLoginComponent } from './buy-postage/international-goods/inline-login/inline-login.component';
import { ItemTypeComponent } from './buy-postage/international-goods/buy-postage-item-types/item-type/item-type.component';
import { BuyPostageLoginComponent } from './buy-postage/buy-postage-login/buy-postage-login.component';



@NgModule({
  declarations: [
    SendAndReceiveSpecificationComponent,
    SendAndReceiveComponent,
    SendAndReceiveAddressesComponent,
    FromAccountAddressComponent,
    ToAccountAddressComponent,
    SendReceivePricesComponent,
    OrderDetailsComponent,
    SendReceiveConfirmationComponent,
    PostaDepotAddressComponent,
    FindPricesComponent,
    ViewPricesComponent,
    ViewOrderComponent,
    BuyPostageComponent,
    BuyPostageSecondaryComponent,
    BuyPostageConfirmationComponent,
    BuyPostageLandingComponent,
    TrackAndTraceComponent,
    ViewPostageOrderComponent,
    TestComponent,
    BuyPostageAddressesComponent,
    BuyPostageCustomsComponent,
    BuyPostageItemTypesComponent,
    InlineLoginComponent,
    ItemTypeComponent,
    BuyPostageLoginComponent
  ],
  imports: [
    CommonModule,
    SendReceiveRoutingModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatCardModule,
    MatProgressBarModule,
    SharedModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatProgressSpinnerModule
  ],
  exports: [
    TranslateModule
  ]
})
export class SendReceiveModule { }
