<div class="container">
    <div class="header-container">
        <div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
        <div class="row">
            <div class="col">
                <h2 class="header-grey secondary-title">{{'view_postage_order.posta_id' | translate}} {{order.orderReference}}</h2>
            </div>
            <div class="col-auto">
                <div class="print-label-button-container">
                    <button *ngIf="order.isCDSFiled && !!order.barcode" class="print-label-button primary-button"
                        [disabled]="downloadingCDS" (click)="downloadCDS()"
                        mat-button>{{'send_receive.send_receive.confirmation.download_cds' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="details-container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
                <div class="col-border" style="display: grid">
                    <h2 class="details-title">{{'view_postage_order.your_details_title' | translate}}</h2>
                    <span class="text-padding"><strong>{{'view_postage_order.contact_name' | translate}} </strong>{{userDetails.recipientName}}</span>
                    <span class="text-padding"><strong>{{'view_postage_order.address_line_1' | translate}} </strong>{{userDetails.addressLine1}}</span>
                    <!-- <span class="text-padding"><strong>{{'view_postage_order.address_line_2' | translate}} </strong>{{userDetails.addressLine2}}</span> -->
                    <span class="text-padding"><strong>{{'view_postage_order.postcode' | translate}} </strong>{{userDetails.postcode}}</span>
                    <span class="text-padding"><strong>{{'view_postage_order.country' | translate}} </strong>{{countryName}}</span>
                    <span class="text-padding"><strong>{{'view_postage_order.contact_number' | translate}} </strong>{{userDetails.mobileNo}}</span>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <div class="col-border">
                    <h2 class="details-title">{{'view_postage_order.service_title' | translate}}</h2>
                    <span class="text-padding">{{'view_postage_order.online_postage' | translate}}</span>
                    <!-- <br> -->
                    <!-- <button class="primary-button" style="margin-top: 10px;">View Instructions</button> -->
                </div>
            </div>
            <div *ngFor="let item of order.orderDetail; let i = index" class="col-lg-3 col-md-6 col-12">
                <div class="col-border" style="display: grid">
                    <h2 class="details-title">{{'view_postage_order.item_title' | translate}} {{i+1}}</h2>
                    <span><strong>{{'view_postage_order.to' | translate}}</strong></span>
                    <span class="text-padding">{{item.zoneText}}</span>
                    <span><strong>{{'view_postage_order.weight' | translate}}</strong></span>
                    <span class="text-padding">{{item.weightText}}</span>
                    <span><strong>{{'view_postage_order.size' | translate}}</strong></span>
                    <span class="text-padding">{{item.sizeCategoryText}}</span>
                    <span><strong>{{'view_postage_order.prize' | translate}}</strong></span>
                    <span class="text-padding">{{item.netAmount | currency: 'DKK':''}} DKK</span>
                    <span><strong>{{'view_postage_order.your_ref' | translate}}</strong></span>
                    <span *ngIf="!item.customerReference" class="text-padding">{{'view_postage_order.no_reference' | translate}}</span>
                    <span *ngIf="!!item.customerReference" class="text-padding">{{item.customerReference}}</span>
                    <span><strong>{{'view_postage_order.code_status' | translate}}</strong></span>
                    <span class="">{{item.postageCode}}</span>
                    <span *ngIf="item.isUsed" class="text-padding">{{'view_postage_order.used' | translate}}</span>
                    <span class="!item.isUsed" class="text-padding">{{'view_postage_order.not_used' | translate}}</span>
                    <span><strong>{{'view_postage_order.expiry' | translate}}</strong></span>
                    <span class="text-padding">{{order.expiryDate | date:'dd/MM/yyyy'}}</span>
                </div>
            </div>
        </div>
    </div>
    <h1 class="grey-text total-amount">{{'view_postage_order.total' | translate}} {{totalAmount | currency: 'DKK':''}} DKK</h1>
</div>