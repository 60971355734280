<div class="container">
    <!-- Header to be injected via static content -->
    <!-- <h1 class="sub-header-title">Account reference </h1> -->
</div>

<div class="container">
    <div class="row">
        <div class="col-md-3 col-6">
            <h3 class="vertical-align">{{ 'update_profile.my_details' | translate }}</h3>
        </div>
        <div class="col-lg-3 offset-lg-3 col-md-4 offset-md-1 hide-mobile"
            style="height: 40px; margin-top: 10px; margin-bottom: 10px;">
            <app-field-error-message *ngIf="success" icon=""
                errorMessage="{{ 'update_profile.save_successful' | translate }}"></app-field-error-message>
        </div>
        <div class="col-lg-3 col-md-4 col-6">
            <button class="primary-button full-width save-button" (click)="save()" [disabled]="saving">
                <div class="spinner">
                    {{ 'update_profile.save' | translate }}
                    <mat-spinner *ngIf="saving" class="mat-spin" diameter="15">
                    </mat-spinner>
                </div>
            </button>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-md-12">
                <div *ngIf="!isSamleikin && !isBusinessUser()" class="row error-message-container">
                    <div class="col-lg-9 col-md-8 col-12 align-center">
                        <img class="error-icon" src="/assets/icons/error@3x.png">
                        <h5 class="white align-center" style="margin: 0px;">{{
                            'update_profile.do_you_have_samleikin_body' | translate }}</h5>
                    </div>
                    <div class="col-lg-3 col-md-4 col-8 align-center">
                        <button class="migrate-button">  <a [href]="samleikinMigrateUrl" target="_self">{{ 'update_profile.samleikin_migrate_button_text' | translate }}</a></button>
                    </div>
                </div>
                <div *ngIf="isSamleikin && !profileComplete()" class="row error-message-container">
                    <div class="col-lg-9 col-md-8 col-12 align-center">
                        <img class="error-icon" src="/assets/icons/error@3x.png">
                        <h5 class="white align-center" style="margin: 0px;">{{ 'update_profile.complete_profile' | translate }}</h5>
                    </div>
                </div>
        </div>
    </div> -->

    <div class="details-container row">
        <div class="col-lg-6 col-md-12 col-12 details-col">
            <div class="row detail-row">
                <div class="col-md-3 col-12">
                    <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.first_name' | translate }}
                    </h4>
                </div>
                <div class="col-lg-9 col-md-7 col-12">
                    <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                        floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{ 'update_profile.first_name_placeholder' | translate }}"
                            id="firstName" name="firstName" formControlName="firstName" required>
                        <mat-error *ngIf="hasErrors('firstName')">
                            <app-field-error-message errorMessage="{{getError('firstName')}}"></app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row detail-row">
                <div class="col-md-3 col-12">
                    <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.last_name' | translate }}
                    </h4>
                </div>
                <div class="col-lg-9 col-md-7 col-12">
                    <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                        floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{ 'update_profile.last_name_placeholder' | translate }}"
                            id="lastName" name="lastName" formControlName="lastName" required>
                        <mat-error *ngIf="hasErrors('lastName')">
                            <app-field-error-message errorMessage="{{getError('lastName')}}"></app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row detail-row">
                <div class="col-md-3 col-12">
                    <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.email' | translate }}</h4>
                </div>
                <div class="col-lg-9 col-md-7 col-12">
                    <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                        floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{ 'update_profile.email_placeholder' | translate }}" id="email"
                            name="email" formControlName="email" required>
                        <mat-error *ngIf="hasErrors('email')">
                            <app-field-error-message errorMessage="{{getError('email')}}"></app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="isPrivateUser" class="row detail-row">
                <div class="col-md-3 col-12">
                    <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.address_line_1' |
                        translate }}</h4>
                </div>
                <div class="col-lg-9 col-md-7 col-12">
                    <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                        floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{ 'update_profile.address_line_1_placeholder' | translate }}"
                            id="addressLine1" name="addressLine1" formControlName="addressLine1" required>
                        <mat-error *ngIf="hasErrors('addressLine1')">
                            <app-field-error-message errorMessage="{{getError('addressLine1')}}">
                            </app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- <div *ngIf="isPrivateUser" class="row detail-row">
                <div class="col-md-3 col-12">
                    <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.address_line_2' |
                        translate }}</h4>
                </div>
                <div class="col-lg-9 col-md-7 col-12">
                    <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                        floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{ 'update_profile.address_line_2_placeholder' | translate }}"
                            id="addressLine2" name="addressLine2" formControlName="addressLine2">
                        <mat-error *ngIf="hasErrors('addressLine2')">
                            <app-field-error-message errorMessage="{{getError('addressLine2')}}">
                            </app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div> -->
            <div *ngIf="isPrivateUser" class="row detail-row">
                <div class="col-md-3 col-12">
                    <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.town_city' | translate }}
                    </h4>
                </div>
                <div class="col-lg-9 col-md-7 col-12">
                    <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                        floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{ 'update_profile.town_city_placeholder' | translate }}"
                            id="townCity" name="townCity" formControlName="townCity" required>
                        <mat-error *ngIf="hasErrors('townCity')">
                            <app-field-error-message errorMessage="{{getError('townCity')}}"></app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="isPrivateUser" class="row detail-row">
                <div class="col-md-3 col-12">
                    <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.postcode' | translate }}
                    </h4>
                </div>
                <div class="col-lg-9 col-md-7 col-12">
                    <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                        floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{ 'update_profile.postcode_placeholder' | translate }}"
                            id="postcode" name="postcode" formControlName="postcode" required>
                        <mat-error *ngIf="hasErrors('postcode')">
                            <app-field-error-message errorMessage="{{getError('postcode')}}"></app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="isPrivateUser" class="row detail-row">
                <div class="col-md-3 col-12">
                    <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.country' | translate }}
                    </h4>
                </div>
                <div class="col-lg-9 col-md-7 col-12">
                    <mat-form-field class="full-width align-form-field detail-form-field" floatLabel="never"
                        appearance="outline" [formGroup]="detailsForm">
                        <mat-select placeholder="{{ 'update_profile.country_placeholder' | translate }}"
                            formControlName="country" required>
                            <mat-option *ngFor="let country of countries" [value]="country">
                                {{getCountryNameForLanguage(country)}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasErrors('country')">
                            <app-field-error-message errorMessage="{{getError('country')}}"></app-field-error-message>
                        </mat-error>
                        <!-- <mat-error *ngIf="hasErrors('country')"><app-field-error-message errorMessage="{{getError('country')}}"></app-field-error-message></mat-error> -->
                    </mat-form-field>
                </div>
                <!-- <app-field-error-message *ngIf="hasErrors('country') && isTouched('country')" class="col-lg-6 col-md-4 address-error" errorMessage="{{getError('country')}}"></app-field-error-message> -->
            </div>
            <div class="row detail-row">
                <div class="col-md-3 col-12">
                    <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.mobile' | translate }}
                    </h4>
                </div>
                <div class="col-lg-9 col-md-7 col-12">
                    <mat-form-field class="country-code-form-field align-form-field detail-form-field"
                        [formGroup]="detailsForm" floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{ 'update_profile.mobile_countrycode_placeholder' | translate }}"
                            id="mobileCountryCode" name="mobileCountryCode" formControlName="mobileCountryCode"
                            required>
                        <mat-error *ngIf="hasErrors('mobileCountryCode')">
                            <app-field-error-message></app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="align-form-field detail-form-field mobile-number-form-field"
                        [formGroup]="detailsForm" floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{ 'update_profile.mobile_placeholder' | translate }}"
                            id="mobileNum" name="mobileNum" formControlName="mobileNum" required>
                        <mat-error *ngIf="hasErrors('mobileNum')">
                            <app-field-error-message errorMessage="{{getError('mobileNum')}}"></app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row detail-row">
                <div class="col-md-3 col-12">
                    <h4 class="dark-blue instructions-title">{{ 'update_profile.instructions' | translate }}</h4>
                </div>
                <div class="col-lg-9 col-md-7 col-12">
                    <mat-form-field [formGroup]="detailsForm" floatLabel="never"
                        class="align-form-field special-instructions-field">
                        <textarea class="text-area" rows="8" matInput
                            placeholder="{{ 'update_profile.instructions_placeholder' | translate }}" id="instructions"
                            name="instructions" formControlName="instructions"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div *ngIf="!!userProfile.company">
                <div class="row detail-row">
                    <div class="col-md-3 col-12">
                        <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.company_name' | translate }}</h4>
                    </div>
                    <div class="col-lg-9 col-md-7 col-12">
                        <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{ 'update_profile.company_name_placeholder' | translate }}"
                                id="company_name" name="company_name" formControlName="company_name" required>
                            <mat-error *ngIf="hasErrors('company_name')">
                                <app-field-error-message errorMessage="{{getError('company_name')}}">
                                </app-field-error-message>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row detail-row">
                    <div class="col-md-3 col-12">
                        <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.company_vtal' | translate }}</h4>
                    </div>
                    <div class="col-lg-9 col-md-7 col-12">
                        <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{ 'update_profile.company_vtal_placeholder' | translate }}"
                                id="company_vtal" name="company_vtal" formControlName="company_vtal" required>
                            <mat-error *ngIf="hasErrors('company_vtal')">
                                <app-field-error-message errorMessage="{{getError('company_vtal')}}">
                                </app-field-error-message>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row detail-row">
                    <div class="col-md-3 col-12">
                        <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.company_postcode' | translate }}</h4>
                    </div>
                    <div class="col-lg-9 col-md-7 col-12">
                        <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{ 'update_profile.company_postcode_placeholder' | translate }}"
                                id="company_postcode" name="company_postcode" formControlName="company_postcode" required>
                            <mat-error *ngIf="hasErrors('company_postcode')">
                                <app-field-error-message errorMessage="{{getError('company_postcode')}}">
                                </app-field-error-message>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row detail-row">
                    <div class="col-md-3 col-12">
                        <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.company_email' | translate }}</h4>
                    </div>
                    <div class="col-lg-9 col-md-7 col-12">
                        <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{ 'update_profile.company_email_placeholder' | translate }}"
                                id="company_email" name="company_email" formControlName="company_email" required>
                            <mat-error *ngIf="hasErrors('company_email')">
                                <app-field-error-message errorMessage="{{getError('company_email')}}">
                                </app-field-error-message>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row detail-row">
                    <div class="col-md-3 col-12">
                        <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.company_address1' | translate }}</h4>
                    </div>
                    <div class="col-lg-9 col-md-7 col-12">
                        <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{ 'update_profile.company_address1_placeholder' | translate }}"
                                id="company_address1" name="company_address1" formControlName="company_address1" required>
                            <mat-error *ngIf="hasErrors('company_address1')">
                                <app-field-error-message errorMessage="{{getError('company_address1')}}">
                                </app-field-error-message>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row detail-row">
                    <div class="col-md-3 col-12">
                        <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.company_address2' | translate }}</h4>
                    </div>
                    <div class="col-lg-9 col-md-7 col-12">
                        <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{ 'update_profile.company_address2_placeholder' | translate }}"
                                id="company_address2" name="company_address2" formControlName="company_address2" required>
                            <mat-error *ngIf="hasErrors('company_address2')">
                                <app-field-error-message errorMessage="{{getError('company_address2')}}">
                                </app-field-error-message>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row detail-row">
                    <div class="col-md-3 col-12">
                        <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.company_town_city' | translate }}</h4>
                    </div>
                    <div class="col-lg-9 col-md-7 col-12">
                        <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{ 'update_profile.company_town_city_placeholder' | translate }}"
                                id="company_town_city" name="company_town_city" formControlName="company_town_city" required>
                            <mat-error *ngIf="hasErrors('company_town_city')">
                                <app-field-error-message errorMessage="{{getError('company_town_city')}}">
                                </app-field-error-message>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row detail-row">
                    <div class="col-md-3 col-12">
                        <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.company_country' | translate }}</h4>
                    </div>
                    <div class="col-lg-9 col-md-7 col-12">
                        <mat-form-field class="full-width align-form-field detail-form-field" [formGroup]="detailsForm"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{ 'update_profile.company_country_placeholder' | translate }}"
                                id="company_country" name="company_country" formControlName="company_country" required>
                            <mat-error *ngIf="hasErrors('company_country')">
                                <app-field-error-message errorMessage="{{getError('company_country')}}">
                                </app-field-error-message>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row detail-row">
                    <div class="col-md-3 col-12">
                        <h4 class="dark-blue vertical-align detail-form-label">{{ 'update_profile.company_phone_number' | translate }}
                        </h4>
                    </div>
                    <div class="col-lg-9 col-md-7 col-12">
                        <mat-form-field class="country-code-form-field align-form-field detail-form-field"
                            [formGroup]="detailsForm" floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{ 'update_profile.company_phone_country_code_placeholder' | translate }}"
                                id="company_phone_country_code" name="company_phone_country_code" formControlName="company_phone_country_code"
                                required>
                            <mat-error *ngIf="hasErrors('company_phone_country_code')">
                                <app-field-error-message></app-field-error-message>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="align-form-field detail-form-field mobile-number-form-field"
                            [formGroup]="detailsForm" floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{ 'update_profile.company_phone_number_placeholder' | translate }}"
                                id="company_phone_number" name="company_phone_number" formControlName="company_phone_number" required>
                            <mat-error *ngIf="hasErrors('company_phone_number')">
                                <app-field-error-message errorMessage="{{getError('company_phone_number')}}"></app-field-error-message>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row detail-row">
                <div clas="col-lg-9 col-md-9 col-12" style="padding-left: 15px;">
                    <button class="primary-button full-width" [routerLink]="['../../change-password']">
                        {{ 'update_profile.change_password' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 notification-container">
            <h4 class="dark-blue notification-title">{{ 'update_profile.notification_preferences' | translate }}</h4>
            <div class="row">
                <div class="col-12 my-custom-input-label-box align-center"
                    [formGroup]="detailsForm">
                    <!-- checkbox needs styling -->
                    <mat-checkbox formControlName="registerForEmail">
                        <div>{{ 'update_profile.email_pref' | translate }}
                        </div>
                    </mat-checkbox>
                </div>
                <div class="col-12 my-custom-input-label-box align-center" style="padding-top: 30px;"
                    [formGroup]="detailsForm">
                    <!-- checkbox needs styling -->
                    <mat-checkbox formControlName="registerForSMS">
                        <div>{{ 'update_profile.sms_pref' | translate }}
                        </div>
                    </mat-checkbox>
                </div>
            </div>
            <button style="margin-top: 30px;" *ngIf="isBusinessUser()" class="primary-button full-width" [routerLink]="['../account-statement']">
                {{'update_profile.account_statement' | translate}}
            </button>
        </div>
    </div>
    <div class="container save-container">
        <div class="row">
            <div class="col-lg-3 offset-lg-6 col-md-4 offset-md-4 col-6">
                <app-field-error-message *ngIf="success" icon="" style="height: 40px;"
                    errorMessage="{{ 'update_profile.save_successful' | translate }}">
                </app-field-error-message>
                <app-field-error-message *ngIf="failed" icon="" style="height: 40px;"
                errorMessage="{{ 'update_profile.save_failed' | translate }}">
            </app-field-error-message>
            </div>
            <div class="col-lg-3 col-md-4 col-6">
                <button class="primary-button full-width" (click)="save()" [disabled]="saving">
                    <div class="spinner">
                        {{ 'update_profile.save' | translate }}
                        <mat-spinner *ngIf="saving" class="mat-spin" diameter="15">
                        </mat-spinner>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>