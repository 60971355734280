import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StaticContentService } from '../../shared/services/static-content.service';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  isBusinessUserType: boolean = false;

  interval: any;

  pageTitle: string;

  public pageModules: string;

  successfullyCreatedBusinessUser: boolean = false;

  successfullyCreatedPrivateUser: boolean = false;

  constructor(private route: ActivatedRoute,
    private staticContentService: StaticContentService,
    private translateService: TranslateService,
    private roleService: RoleService,
    private router: Router) { }

  ngOnInit(): void {

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
    })
    
    var role = this.route.snapshot.paramMap.get('role');
    if (!!role)
    {
      if (role == "business")
      {
        this.isBusinessUserType = true;
      }
    }

  }

  changeRegisterRoleToBusiness(businessUser: boolean)
  {
    if ((this.roleService.role() == "business" && businessUser) || (this.roleService.role() == "private" && !businessUser))
    {
      return;
    }

    if (businessUser)
    {
      this.router.navigate([`${this.translateService.currentLang}/business/register`]);
    }
    else{
      this.router.navigate([`${this.translateService.currentLang}/private/register`]);
    }
  }

  public getPageTitle()
  {
    return this.staticContentService.getPageTitle();
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }


  businessUserCreatedEvent(event: any)
  {
    this.successfullyCreatedBusinessUser = true;
  }

  privateUserCreatedEvent(event: any)
  {
    this.successfullyCreatedPrivateUser = true;
  }

}
