<div class="track-trace-mobile-parent">
    <div class="track-trace-mobile container">
        <div class="track-trace-container-mobile">
            <div class="first-line-track-trace">
                <h2 class="track-trace-text-mobile">{{'homepage.personal.track_trace' | translate}}</h2>
                <!-- <i class="track-trace-icon fa fa-map-marker"></i> -->
                <img class="track-trace-icon" src="{{cdnPath}}/images/m_track_trace.svg" />
            </div>
            <div class="track-trace-search-container">
                <mat-form-field class="track-trace-input" appearance="outline">
                    <input placeholder="{{'homepage.personal.enter_number' | translate}}" [(ngModel)]="trackTraceInput" type="text" name="trackTraceInput" (keyup.enter)="onTrackTraceEnter()" matInput>
                </mat-form-field>
                <button class="search-button" [routerLink]="['../send-receive/track-trace', trackTraceInput]">
                    <img class="" src="/assets/icons/search_arrow.png" />
                </button>
            </div>
        </div>
        <button class="secondary-button second-image-button-mobile">
            <h2 class="find-text">{{'homepage.personal.find' | translate}}</h2>
        </button>
        <div class="row third-image-buttons-container-mobile">
            <div class="button-container-mobile col-sm-4 col-4">
                <div *ngIf="!!mainImageModule" style="padding-right: 8px;">
                    <button class="secondary-button third-image-button-padded-mobile" [routerLink]="mainImageModule.Content.Item1Link.Url" (mouseover)="onMouseOver(1)" (mouseout)="onMouseOut(1)">
                        <div>
                            <img class="icon-button-mobile" [src]="item1Icon"/>
                        </div>
                        <h2 style="font-size: 15px;" >{{mainImageModule.Content.Item1Link.Title}}</h2>
                    </button>
                </div>
            </div>
            <div class="button-container-mobile col-sm-4 col-4">
                <div style="padding-right: 4px; padding-left: 4px;">
                    <button *ngIf="!!mainImageModule" class="secondary-button third-image-button-padded-mobile" [routerLink]="mainImageModule.Content.Item2Link.Url" (mouseover)="onMouseOver(2)" (mouseout)="onMouseOut(2)">
                        <div>
                            <img class="icon-button-mobile" [src]="item2Icon"/>
                        </div>
                        <h2 style="font-size: 15px;">{{mainImageModule.Content.Item2Link.Title}}</h2>
                    </button>
                </div>
            </div>
            <div class="button-container-mobile col-sm-4 col-4">
                <div style="padding-left: 8px;">
                    <button *ngIf="!!mainImageModule" class="secondary-button third-image-button-mobile" [routerLink]="mainImageModule.Content.Item3Link.Url" (mouseover)="onMouseOver(3)" (mouseout)="onMouseOut(3)">
                        <div>
                            <img class="icon-button-mobile" [src]="item3Icon">
                        </div>
                        <h2 style="font-size: 15px;">{{mainImageModule.Content.Item3Link.Title}}</h2>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container image-container-mobile">
    <img class="image-mobile" [src]="mainImage" />
</div>

<div *ngIf="!!mainImage">
    <div class="container image-container">
        <img class="main-image" [src]="mainImage" />
        <div class="layer">
    
        </div>
        <div class="button-container">
            <div class="container">
                <div class="float-right">
                    <div class="track-trace-container" style="margin-left: 15px;">
                        <div class="first-line-track-trace">
                            <h2 class="track-trace-text">{{'homepage.personal.track_trace' | translate}}</h2>
                            <!-- <i class="track-trace-icon fa fa-map-marker"></i> -->
                            <img class="track-trace-icon" src="{{cdnPath}}/images/m_track_trace.svg" />
    
                        </div>
                        <div class="track-trace-search-container">
                            <mat-form-field class="track-trace-input" appearance="outline">
                                <input placeholder="{{'homepage.personal.enter_number' | translate}}" [(ngModel)]="trackTraceInput" (keyup.enter)="onTrackTraceEnter()" matInput>
                            </mat-form-field>
                            <button class="search-button" [routerLink]="['../send-receive/track-trace', trackTraceInput]">
                                <img class="" src="/assets/icons/search_arrow.png" />
                            </button>
                        </div>
                    </div>
                    <div class="second-image-button" style="margin-left: 15px;">
                        <h2 class="find-text">{{'homepage.personal.find' | translate}}</h2>
                    </div>
                    <div *ngIf="!!mainImageModule" class="row third-image-buttons-container" style="margin-left: 15px;">
                        <button class="secondary-button third-image-button-padded" [routerLink]="mainImageModule.Content.Item1Link.Url" (mouseover)="onMouseOver(1)" (mouseout)="onMouseOut(1)">
                            <div>
                                <img class="icon-button" [src]="item1Icon" />
                            </div>
                            <span>{{mainImageModule.Content.Item1Link.Title}}</span>
                        </button>
                        <button class="secondary-button third-image-button-padded" [routerLink]="mainImageModule.Content.Item2Link.Url" (mouseover)="onMouseOver(2)" (mouseout)="onMouseOut(2)">
                            <div>
                                <img class="icon-button" [src]="item2Icon" />
                            </div>
                            {{mainImageModule.Content.Item2Link.Title}}
                        </button>
                        <button class="secondary-button third-image-button" [routerLink]="mainImageModule.Content.Item3Link.Url" (mouseover)="onMouseOver(3)" (mouseout)="onMouseOut(3)">
                            <div>
                                <img class="icon-button" [src]="item3Icon">
                            </div>
                            {{mainImageModule.Content.Item3Link.Title}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="showHtmlModules" [innerHTML]="pageHtml | safeHtml"></div>

<!-- <div class="second-image-mobile-container">
    <div class="container">
        <img class="image-mobile" src="/assets/images/homepage/homepage-image-2.png" />
    </div>
    <div class="container second-image-container-mobile" style="margin-bottom: 30px;">
        <div class="second-image-container-text-mobile">
            <h1 style="padding-bottom: 10px">{{'homepage.personal.my_posta' | translate}}</h1>
            <br>
            <h4>{{'homepage.personal.my_posta_body' | translate}}</h4>
            <div>
                <button class="feature-button white">{{'homepage.personal.login_register' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<div class="second-image-mobile-container">
    <div class="container">
        <img class="image-mobile" src="/assets/images/homepage/homepage-image-3.png" />
    </div>
    <div class="container second-image-container-mobile">
        <div class="second-image-container-text-mobile">
            <h1 style="padding-bottom: 10px">{{'homepage.personal.news_headline' | translate}}</h1>
            <br>
            <h4>{{'homepage.personal.news_headline_body' | translate}}</h4>
            <div>
                <button class="feature-button white">{{'homepage.personal.read_article' | translate}}</button>
            </div>
        </div>
    </div>
</div> -->

<div *ngFor="let module of modules">
    <div *ngIf="module.ModuleTypeId == 113" class="container send-text-parent-container">
        <h4 class="send-text-container">{{module.Content.Title}}<a *ngIf="module.Content.Link.Title"
                [routerLink]="['/send-receive/find-prices']">
                <br class="mobile-br">
                <span class="find-price-text">{{module.Content.Link.Title}}</span>
            </a></h4>
    </div>

    <div *ngIf="module.ModuleTypeId == 105" class="container secondary-image-container" [ngClass]="(module.Content.Layout == 'ImageText')? '' : 'reverse-flow'">
        <div class="col-lg-6 second-image">
            <img class="image" [src]="module.Content.Image" />
        </div>
        <div class="col-lg-6 second-image-container">
            <div class="padding-right-40">
                <div class="second-image-container-text">
                    <h1 style="padding-bottom: 20px">{{module.Content.Title}}</h1>
                    <h4 class="my-posta-tablet">{{module.Content.Text}}</h4>
                    <div>
                        <button *ngIf="module.Content.Link.Title" class="feature-button white">{{module.Content.Link.Title}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="module.ModuleTypeId == 107 && !!module.Content.Modules && module.Content.Modules.length > 2" class="container">
        <div class="find-out-more-container row">
            <div class="col col-lg-4 col-md-4 col-sm-4 col-4 find-out-more-col">
                <div class="border">
                    <img class="find-out-more-icon" src="/assets/icons/find_out_more/m_customs_calculator.png" />
                    <div class="find-out-more-body">
                        <h2 class="find-out-more-header-margin">{{module.Content.Modules[0].Content.Title}}</h2>
                        <div class="find-out-more-content">
                            {{module.Content.Modules[0].Content.Text}}
                        </div>
                    </div>
                    <button *ngIf="module.Content.Modules[0].Content.Link.Title" class="find-out-more-button padded primary-button">{{module.Content.Modules[0].Content.Link.Title}}</button>
                </div>
            </div>
            <div class="col col-lg-4 col-md-4 col-sm-4 col-4 find-out-more-col">
                <div class="border">
                    <img class="find-out-more-icon" src="/assets/icons/find_out_more/m_shopping_list.png" />
                    <div class="find-out-more-body">
                        <h2 class="find-out-more-header-margin">{{module.Content.Modules[1].Content.Title}}</h2>
                        <div class="find-out-more-content">
                            {{module.Content.Modules[1].Content.Text}}
                        </div>
                    </div>
                    <button *ngIf="module.Content.Modules[1].Content.Link.Title" class="find-out-more-button padded primary-button">{{module.Content.Modules[1].Content.Link.Title}}</button>
                </div>
            </div>
            <div class="col col-lg-4 col-md-4 col-sm-4 col-4 find-out-more-col">
                <div class="border">
                    <img class="find-out-more-icon" src="/assets/icons/find_out_more/m_pay_customs.png" />
                    <div class="find-out-more-body">
                        <h2 class="find-out-more-header-margin">{{module.Content.Modules[2].Content.Link.Title}}</h2>
                        <div class="find-out-more-content">
                            {{module.Content.Modules[2].Content.Text}}
                        </div>
                    </div>
                    <button *ngIf="module.Content.Modules[2].Content.Link.Title" class="find-out-more-button padded primary-button">{{module.Content.Modules[2].Content.Link.Title}}</button>
                </div>
            </div>
        </div>
    </div>
</div> 