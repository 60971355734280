import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Country } from './../../../../../account/entities/Country';
import { CustomsItemType } from './../../../../../send-receive/entities/CustomsItemType';
import { ErrorHelper } from './../../../../../shared/helpers/error-helper';
import { TranslationHelper } from './../../../../../shared/helpers/translation-helper';

@Component({
  selector: 'app-item-type',
  templateUrl: './item-type.component.html',
  styleUrls: ['./item-type.component.scss']
})
export class ItemTypeComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private errorHelper: ErrorHelper,
    private translate: TranslateService, private translateHelper: TranslationHelper) { }

  @Input()
  countries: Array<Country>;

  @Input()
  index: number;
  
  @Input()  itemType!: CustomsItemType;
  @Output() itemTypeChange = new EventEmitter<CustomsItemType>();
  @Output() delete = new EventEmitter();

  public CheckValidity(){
    this.itemTypeForm.markAllAsTouched();
    return this.itemTypeForm.valid;
  }

  itemTypeForm: FormGroup;

  public hasErrors(field) {
    var errors = this.itemTypeForm.get(field).errors;
    return !!errors;
  }

  public getError(field) {
    var errors = this.itemTypeForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  ngOnInit(): void {
    this.itemTypeForm = this.formBuilder.group({
      description: [this.itemType.description, [Validators.required, Validators.maxLength(100)]],
      quantity: [this.itemType.quantity, [Validators.required, Validators.min(1), ErrorHelper.integerOnlyValidator]],
      weight: [this.itemType.netWeight, [Validators.required, ErrorHelper.numberOnlyValidator]],
      value: [this.itemType.value, [Validators.required, ErrorHelper.numberOnlyValidator]],
      country: [this.itemType.countryOfOrigin, []],
      tariffNo: [this.itemType.hsTariffNo, []]
    });

    this.translate.onLangChange.subscribe(x => {
      this.translateHelper.convertNumberField(this.itemTypeForm.get(`weight`));
      this.translateHelper.convertNumberField(this.itemTypeForm.get(`value`));
    });

    this.itemTypeForm.get('description').valueChanges.subscribe((newValue) => this.itemType.description = newValue);
    this.itemTypeForm.get('quantity').valueChanges.subscribe((newValue) => this.itemType.quantity = newValue);
    this.itemTypeForm.get('weight').valueChanges.subscribe((newValue) => 
    {
      this.translateHelper.formatNumberField(this.itemTypeForm.get('weight'));
      var weight = this.translateHelper.getNumber(newValue);
      this.itemType.netWeight = +(this.itemType.isKg ? weight : weight / 1000);
      
    });
    this.itemTypeForm.get('value').valueChanges.subscribe((newValue) => 
    {
      this.translateHelper.formatNumberField(this.itemTypeForm.get('value'));
      this.itemType.value = this.translateHelper.getNumber(newValue);
    });
    this.itemTypeForm.get('country').valueChanges.subscribe((newValue) => this.itemType.countryOfOrigin = newValue);
    this.itemTypeForm.get('tariffNo').valueChanges.subscribe((newValue) => this.itemType.hsTariffNo = newValue);
  }
  
  public getCountryNameForLanguage(country: Country): string
  {
     switch (this.translate.currentLang) {
       case "en":
         return country.countryNameEN;
       case "fo":
         return country.countryNameFO;
     }
  }

  toggleKg(isKG: boolean){
    this.itemType.isKg = isKG;
    var weight = this.translateHelper.getNumber(this.itemTypeForm.get('weight').value);
    this.itemType.netWeight = +(this.itemType.isKg ? weight : weight / 1000);
  }

  public deleteType(){
    this.delete.emit();
  }
  

}
