<div class="container mobile-padding-top">
    <div class="row">
        <h2 class="col-lg-12 service-option-text">{{'send_receive.send_receive.service_options.title' | translate}}</h2>
    </div>
</div>

<div class="key-background-container">
    <div class="key-container container align-center">
        <div class="row parent-key-row">
            <div class="col-lg-3 col-12 align-center">
                <h2 class="white semi-bold key-text-padding">
                    {{'send_receive.send_receive.service_options.key' | translate}}</h2>
            </div>
            <div class="col-lg-3 col-12 align-center" style="display: inline-block; overflow: hidden;">
                <div class="sub-header-key">{{'send_receive.send_receive.service_options.drop_off_collection_title' |
                    translate}}</div>
                <div class="align-center" style="margin-top: 10px;">
                    <img class="parent-key-icon" src="/assets/icons/find-prices/key_drop_off_white.svg" />
                    <div>
                        <h4 class="white semi-bold key-title">
                            {{'send_receive.send_receive.service_options.drop_off_name' | translate}}</h4>
                        <span class="small-key-text">{{'send_receive.send_receive.service_options.drop_off_description'
                            | translate}}</span>
                    </div>
                </div>
                <div class="align-center" style="margin-top: 15px;">
                    <img class="second-key-icon" src="/assets/icons/find-prices/key_p_collection_white.svg" />
                    <div>
                        <h4 class="white semi-bold key-title">
                            {{'send_receive.send_receive.service_options.collection_name' | translate}}</h4>
                        <span
                            class="small-key-text">{{'send_receive.send_receive.service_options.collection_description'
                            | translate}}</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-12 align-center" style="display: inline-block; overflow: hidden;">
                <div class="sub-header-key">{{'send_receive.send_receive.service_options.delivery_title' |
                    translate}}</div>
                <div class="align-center" style="margin-top: 10px;">
                    <img class="parent-key-icon" src="/assets/icons/find-prices/Key_one_delivery_white.png" />
                    <div>
                        <h4 class="white semi-bold key-title">
                            {{'send_receive.send_receive.service_options.delivery_attempt' | translate}}</h4>
                        <span class="small-key-text">{{'send_receive.send_receive.service_options.collect_from_depot' |
                            translate}}</span>
                    </div>
                </div>
                <div class="align-center" style="margin-top: 15px;">
                    <img class="second-key-icon" src="/assets/icons/find-prices/Key_guaranteed_delivery_white.png" />
                    <div>
                        <h4 class="white semi-bold key-title">
                            {{'send_receive.send_receive.service_options.guaranteed_delivery' | translate}}</h4>
                        <span
                            class="small-key-text">{{'send_receive.send_receive.service_options.unlimited_attempts_collection'
                            | translate}}</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-12 align-center" style="display: inline-block; overflow: hidden;">
                <div class="sub-header-key">{{'send_receive.send_receive.service_options.track_title' |
                    translate}}</div>
                <div class="align-center" style="margin-top: 10px;">
                    <img class="parent-key-icon" src="/assets/icons/find-prices/key_track_trace_white.png" />
                    <div>
                        <h4 class="white semi-bold key-title">{{'send_receive.send_receive.service_options.track_trace'
                            | translate}}</h4>
                        <span
                            class="small-key-text">{{'send_receive.send_receive.service_options.track_trace_description'
                            | translate}}</span>
                    </div>
                </div>
                <div class="align-center" style="margin-top: 15px;">
                    <img class="second-key-icon" src="/assets/icons/find-prices/Key_signature_delivery_white.png" />
                    <div>
                        <h4 class="white semi-bold key-title">
                            {{'send_receive.send_receive.service_options.signature_on_receipt' | translate}}</h4>
                        <span
                            class="small-key-text">{{'send_receive.send_receive.service_options.signature_on_receipt_description'
                            | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="parent-container">
    <div class="container">
        <div class="price-row row">
            <div *ngIf="!serviceOptions || serviceOptions.length == 0">
                <h1>{{'send_receive.send_receive.service_options.no_service_options_found' | translate}}</h1>
            </div>
            <div *ngFor="let serviceOption of serviceOptions; let i = index;"
                [ngClass]="(i == savedServiceOptionIndex)? 'highlight-border' : ''"
                class="col-price col-xl-4 col-lg-6 col-md-6 col-12">
                <div class="service-option-container">
                    <div class="top-price-border">
                        <div class="row" style="padding-bottom: 10.62px;">
                            <div class="col-lg-9 col-md-9 col-9">
                                <img class="service-option-icon" src={{serviceOption.IconPath}} />
                            </div>
                            <h2 class="col-lg-3 col-md-3 col-3 price-option-end-text day-count-text">
                                {{serviceOption.DeliveryDays}}<h4 class="price-option-end-text">
                                    {{'send_receive.send_receive.service_options.days' | translate}}</h4>
                            </h2>
                        </div>
                        <div class="row">
                            <h2 class="col-lg-9 col-md-9 col-9 service-option-title grey">
                                {{getNameByLanguage(serviceOption.Name)}}</h2>
                        </div>
                        <div class="row">
                            <span *ngIf="!!serviceOption.Description" class="col-lg-9 col-md-9 col-9">
                                {{getNameByLanguage(serviceOption.Description)}}</span>
                        </div>
                        <div class="row">
                            <h3 class="col-lg-9 col-md-9 col-9 primary" style="font-weight: 600;" *ngIf="isDomestic">
                                {{serviceOption.Cost | numberFormat}}
                                {{'send_receive.send_receive.service_options.price_per_unit_text' |
                                translate}}</h3>
                            <h3 class="col-lg-9 col-md-9 col-9 primary" style="font-weight: 600;" *ngIf="!isDomestic">
                                {{serviceOption.Cost | numberFormat}} Kr.</h3>
                        </div>
                        <div class="row second-price-option-row">
                            <span *ngIf="serviceOption.VATCost > 0; else emptyRow"
                                class="col-lg-9 col-md-9 col-9">{{'send_receive.send_receive.service_options.include_vat' |
                                translate}} {{serviceOption.VATCost | numberFormat}} Kr.</span>
                                <ng-template #emptyRow><span>&nbsp;</span></ng-template>
                        </div>
                        <span *ngIf="!serviceOption.Description">
                            &nbsp;</span>
                        <div *ngIf="insuranceSelected && serviceOption.InsuranceCost > 0; else noInsurance">
                            <div class="row">
                                <div class="col-lg-12 col-md-7 col-7">{{'send_receive.send_receive.service_options.include'
                                    | translate}} {{serviceOption.InsuranceCost | numberFormat}}
                                    Kr.</div>
                            </div>
                        </div>
                        <ng-template #noInsurance><span class="row">&nbsp;</span></ng-template>
                        <div *ngIf="insuranceSelected && serviceOption.InsuranceCost > 0; else noInsurance2">
                            <div class="row">
                                <div class="col-lg-12 col-md-7 col-7">
                                    {{'send_receive.send_receive.service_options.insurance_cover' | translate}}</div>
                            </div>
                        </div>
                        <ng-template #noInsurance2><span class="row">&nbsp;</span></ng-template>
                    </div>
                    <div class="align-center">
                        <div class="key-icon-container" *ngFor="let property of serviceOption.Properties | slice:0:4">
                            <img class="service-key-icon" src={{property.IconPath}} />
                            <span class="key-icon-text">{{getIconTextByLanguage(property.Name)}}</span>
                        </div>
                        <div class="key-icon-container"
                            *ngIf="!!serviceOption.Properties && serviceOption.Properties.length < 3">
                            <img class="service-key-icon" src="/assets/icons/find-prices/key_service_null.svg" />
                            <span class="key-icon-text">{{'send_receive.send_receive.service_options.no_key_text_1'
                                | translate}}</span>
                        </div>
                        <div class="key-icon-container"
                            *ngIf="!!serviceOption.Properties && serviceOption.Properties.length < 4">
                            <img class="service-key-icon" src="/assets/icons/find-prices/key_service_null.svg" />
                            <span class="key-icon-text">{{'send_receive.send_receive.service_options.no_key_text_2'
                                | translate}}</span>
                        </div>
                    </div>
                    <div class="bottom-price-border display-block-mobile">
                        <div class="row linkItemButtonContainer">
                            <div *ngIf="!showPostOfficeButton(serviceOption.ProductType)" class="col-lg-12 col-12" style="padding-bottom: 15px;">
                                <button *ngIf="!!linkItem3" class="secondary-button padded full-width" style="height: 64px;"
                                (click)="openRouteInNewWindow(linkItem3.Url)">{{linkItem3.Title}}</button>
                            </div>
                            <div *ngIf="showPostOfficeButton(serviceOption.ProductType)" class="col-lg-6 col-12" style="padding-bottom: 15px;">
                                <button *ngIf="!!linkItem3" class="static-content-btn secondary-button padded full-width"
                                (click)="openRouteInNewWindow(linkItem3.Url)">{{linkItem3.Title}}</button>
                            </div>
                            <div *ngIf="showPostOfficeButton(serviceOption.ProductType)" class="col-lg-6 col-12" style="padding-bottom: 15px;">
                                <button *ngIf="!!linkItem4" class="static-content-btn secondary-button padded full-width"
                                (click)="openRouteInNewWindow(linkItem4.Url)">{{linkItem4.Title}}</button>
                            </div>
                        </div>
                        <button class="primary-button full-width padded full-width-button-mobile" mat-button
                            (click)="chooseServiceOption(serviceOption)">{{'send_receive.send_receive.service_options.proceed'
                            | translate}}</button>
                        <div class="item-link-container">
                            <div class="align-center" style="padding: 0px;">
                                <a *ngIf="!!linkItem1" href="" (click)="openRouteInNewWindow(linkItem1.Url)"
                                    class="terms-link" target="_blank">{{'send_receive.send_receive.service_options.terms' |
                                    translate}}</a>
                                <span *ngIf="insuranceSelected && serviceOption.InsuranceCost > 0">|</span>
                                <a href="" (click)="openRouteInNewWindow(linkItem2.Url)"
                                    *ngIf="insuranceSelected && !!linkItem2 && serviceOption.InsuranceCost > 0" class="insurance-link"
                                    target="_blank">{{'send_receive.send_receive.service_options.insurance' |
                                    translate}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>