<div class="container">
    <div class="row">
        <h2 class="col-lg-12 service-option-text">{{'send_receive.send_receive.service_options.title' | translate}}</h2>
    </div>
</div>

<div class="key-background-container">
    <div class="key-container container align-center">
        <div class="row parent-key-row">
            <!-- <div> -->
            <div class="col-lg-3 col-12 align-center">
                <!-- <div class="show-mobile key-arrow-down-container">
                    <img class="parent-key-icon show-mobile key-arrow-down" src="/assets/icons/arrows/arrow_white.png" />
                </div> -->
                <h2 class="white semi-bold key-text-padding" style="display: inline-block; ">
                    {{'send_receive.send_receive.service_options.key' | translate}}</h2>
            </div>
            <!-- </div> -->
            <div class="col-lg-3 col-12 align-center" style="display: inline-block; overflow: hidden;">
                <div class="sub-header-key">{{'send_receive.send_receive.service_options.drop_off_collection_title' |
                    translate}}</div>
                <div class="align-center" style="margin-top: 10px;">
                    <img class="parent-key-icon" src="/assets/icons/find-prices/key_drop_off_white.svg" />
                    <div>
                        <h4 class="white semi-bold key-title">
                            {{'send_receive.send_receive.service_options.drop_off_name' | translate}}</h4>
                        <span class="small-key-text">{{'send_receive.send_receive.service_options.drop_off_description'
                            | translate}}</span>
                    </div>
                </div>
                <div class="align-center" style="margin-top: 15px;">
                    <img class="second-key-icon" src="/assets/icons/find-prices/key_p_collection_white.svg" />
                    <div>
                        <h4 class="white semi-bold key-title">
                            {{'send_receive.send_receive.service_options.collection_name' | translate}}</h4>
                        <span
                            class="small-key-text">{{'send_receive.send_receive.service_options.collection_description'
                            | translate}}</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-12 align-center" style="display: inline-block; overflow: hidden;">
                <div class="sub-header-key">{{'send_receive.send_receive.service_options.delivery_title' |
                    translate}}</div>
                <div class="align-center" style="margin-top: 10px;">
                    <img class="parent-key-icon" src="/assets/icons/find-prices/Key_one_delivery_white.png" />
                    <div>
                        <h4 class="white semi-bold key-title">{{'send_receive.send_receive.service_options.delivery_attempt' | translate}}</h4>
                        <span class="small-key-text">{{'send_receive.send_receive.service_options.collect_from_depot' | translate}}</span>
                    </div>
                </div>
                <div class="align-center" style="margin-top: 15px;">
                    <img class="second-key-icon" src="/assets/icons/find-prices/Key_guaranteed_delivery_white.png" />
                    <div>
                        <h4 class="white semi-bold key-title">{{'send_receive.send_receive.service_options.guaranteed_delivery' | translate}}</h4>
                        <span class="small-key-text">{{'send_receive.send_receive.service_options.unlimited_attempts_collection' | translate}}</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-12 align-center" style="display: inline-block; overflow: hidden;">
                <div class="sub-header-key">{{'send_receive.send_receive.service_options.track_title' |
                    translate}}</div>
                <div class="align-center" style="margin-top: 10px;">
                    <img class="parent-key-icon" src="/assets/icons/find-prices/key_track_trace_white.png" />
                    <div>
                        <h4 class="white semi-bold key-title">{{'send_receive.send_receive.service_options.track_trace' | translate}}</h4>
                        <span class="small-key-text">{{'send_receive.send_receive.service_options.track_trace_description' | translate}}</span>
                    </div>
                </div>
                <div class="align-center" style="margin-top: 15px;">
                    <img class="second-key-icon" src="/assets/icons/find-prices/Key_signature_delivery_white.png" />
                    <div>
                        <h4 class="white semi-bold key-title">{{'send_receive.send_receive.service_options.signature_on_receipt' | translate}}</h4>
                        <span class="small-key-text">{{'send_receive.send_receive.service_options.signature_on_receipt_description' | translate}}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-auto col-md-4 col-12 align-center key-padding">
                        <img class="parent-key-icon" src="/assets/icons/find-prices/Key_one_delivery_white.png" />
                        <div>
                            <h4 class="white semi-bold key-title">{{'send_receive.send_receive.service_options.delivery_attempt' | translate}}</h4>
                            <span class="small-key-text">{{'send_receive.send_receive.service_options.collect_from_depot' | translate}}</span>
                        </div>
                    </div>
                    <div class="col-lg-auto col-md-4 col-12 align-center key-padding">
                        <img class="parent-key-icon" src="/assets/icons/find-prices/Key_guaranteed_delivery_white.png" />
                        <div>
                            <h4 class="white semi-bold key-title">{{'send_receive.send_receive.service_options.guaranteed_delivery' | translate}}</h4>
                            <span class="small-key-text">{{'send_receive.send_receive.service_options.collect_from_depot' | translate}}</span>
                        </div>
                    </div>
                    <div class="col-lg-auto col-md-4 col-12 align-center key-padding">
                        <img class="parent-key-icon" src="/assets/icons/find-prices/key_track_trace_white.png" />
                        <div>
                            <h4 class="white semi-bold key-title">{{'send_receive.send_receive.service_options.track_trace' | translate}}</h4>
                            <div>&nbsp;</div>
                        </div>
                    </div>
                    <div class="col-lg-auto col-md-4 col-12 align-center key-padding mobile-margin-bottom-signature">
                        <img class="parent-key-icon mobile-signature-icon-padding" src="/assets/icons/find-prices/Key_signature_delivery_white.png" />
                        <div>
                            <h4 class="white semi-bold key-title">{{'send_receive.send_receive.service_options.signature_on_receipt' | translate}}</h4>
                            <div class="small-key-text">{{'send_receive.send_receive.service_options.only_some_countries' | translate}}</div>
                            <div class="small-key-text">{{'send_receive.send_receive.service_options.check_domain' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-lg-auto col-md-4 col-12 align-center key-padding mobile-margin-fastest-service">
                        <img class="parent-key-icon mobile-fastest-service-padding" src="/assets/icons/find-prices/Key_fastest_service_white.png" />
                        <div>
                            <h4 class="white semi-bold key-title">{{'send_receive.send_receive.service_options.fastest_service' | translate}}</h4>
                            <span class="small-key-text">{{'send_receive.send_receive.service_options.require_faster' | translate}}</span>
                        </div>
                    </div> -->
        </div>
    </div>
</div>

<!-- <div class="parent-container">
    <div class="container">
        <div class="price-row row">
            <div *ngIf="!serviceOptions || serviceOptions.length == 0">
                <h1>No options found.</h1>
            </div>
            <div *ngFor="let serviceOption of serviceOptions" class="col-price col-xl-4 col-lg-6 col-md-6 col-12">
                <div class="top-price-border">
                    <div class="row">
                        <div class="col-lg-9 col-md-9">
                            <img class="service-option-icon" [src]=serviceOption.IconPath />
                        </div>
                        <h2 class="col-lg-3 col-md-3 price-option-end-text day-count-text">
                            {{serviceOption.DeliveryDays}}<h4 class="price-option-end-text">
                                {{'send_receive.send_receive.service_options.days' | translate}}</h4>
                        </h2>
                    </div>
                    <div class="row">
                        <h2 class="col-lg-9 service-option-title grey">{{getNameByLanguage(serviceOption.Name)}}</h2>
                    </div>
                    <div class="row second-price-option-row">
                        <h4 class="col-lg-9">{{'send_receive.send_receive.service_options.from' | translate}} Kr.
                            {{serviceOption.Cost | currency: 'DKK':''}}</h4>
                    </div>
                    <div *ngIf="!isLoggedIn">
                        <div class="row">
                            <div class="col-lg-9"><a href="">{{'send_receive.send_receive.service_options.registerLogin'
                                    | translate}}</a> {{'send_receive.find_prices.service_options.to' | translate}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-9">{{'send_receive.send_receive.service_options.myPosta' | translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-price-border">
                    <div class="row">
                        <button *ngIf="!!item3Link" class="secondary-button padded service-option-button full-width"
                            (click)="openRouteInNewWindow(item3Link.Url)">{{item3Link.Title}}</button>
                    </div>
                    <div class="row padding-top">
                        <button class="primary-button padded service-option-button full-width" mat-button
                            (click)="navigatetoSendAndReceive()">{{'send_receive.send_receive.service_options.proceed' |
                            translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="parent-container">
    <div class="container">
        <div class="price-row row">
            <div *ngIf="!serviceOptions || serviceOptions.length == 0">
                <h1>{{'send_receive.send_receive.service_options.no_service_options_found' | translate}}</h1>
            </div>
            <div *ngFor="let serviceOption of serviceOptions; let i = index;"
                class="col-price col-xl-4 col-lg-6 col-md-6 col-12">
                <div class="top-price-border">
                    <div class="row" style="padding-bottom: 10.62px;">
                        <div class="col-lg-9 col-md-9 col-9">
                            <img class="service-option-icon" src={{serviceOption.IconPath}} />
                        </div>
                        <h2 class="col-lg-3 col-md-3 col-3 price-option-end-text day-count-text">
                            {{serviceOption.DeliveryDays}}<h4 class="price-option-end-text">
                                {{'send_receive.send_receive.service_options.days' | translate}}</h4>
                        </h2>
                    </div>
                    <!-- <div class="row">
                        <h2 class="col-lg-9 col-md-9 col-9 service-option-title grey">
                            {{getNameByLanguage(serviceOption.Name)}}</h2>
                    </div>
                    <div class="row">
                        <h4 class="col-lg-9 col-md-9 col-9">{{serviceOption.Cost | priceFormat}} DKK</h4>
                    </div>
                    <div *ngIf="!isLoggedIn">
                        <div class="row">
                            <div class="col-lg-9"><a href="">{{'send_receive.send_receive.service_options.registerLogin'
                                    | translate}}</a> {{'send_receive.find_prices.service_options.to' | translate}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-9">{{'send_receive.send_receive.service_options.myPosta' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="row second-price-option-row" *ngIf="serviceOption.VATCost > 0">
                        <span class="col-lg-9 col-md-9 col-9">{{'send_receive.send_receive.service_options.include_vat' |
                            translate}} {{serviceOption.VATCost | priceFormat}} DKK</span>
                    </div> -->

                    <div class="row">
                        <h2 class="col-lg-9 col-md-9 col-9 service-option-title grey">
                            {{getNameByLanguage(serviceOption.Name)}}</h2>
                    </div>
                    <div class="row">
                        <span *ngIf="!!serviceOption.Description" class="col-lg-9 col-md-9 col-9">
                            {{getNameByLanguage(serviceOption.Description)}}</span>
                    </div>
                    <div class="row">
                        <h3 class="col-lg-9 col-md-9 col-9 primary" style="font-weight: 600;" *ngIf="toFromFO">
                            {{'send_receive.send_receive.service_options.from_price_title' |
                            translate}} {{serviceOption.Cost | numberFormat}}
                            {{'send_receive.send_receive.service_options.price_per_unit_text' |
                            translate}}</h3>
                        <h3 class="col-lg-9 col-md-9 col-9 primary" style="font-weight: 600;" *ngIf="!toFromFO">
                            {{'send_receive.send_receive.service_options.from_price_title' |
                            translate}} {{serviceOption.Cost | numberFormat}} Kr.</h3>
                    </div>
                    <div class="row second-price-option-row">
                        <span *ngIf="serviceOption.VATCost > 0; else emptyRow"
                            class="col-lg-9 col-md-9 col-9">{{'send_receive.send_receive.service_options.include_vat' |
                            translate}} {{serviceOption.VATCost | numberFormat}} Kr.</span>
                            <ng-template #emptyRow><span>&nbsp;</span></ng-template>
                    </div>
                    <span *ngIf="!serviceOption.Description">
                        &nbsp;</span>
                </div>
                <div class="align-center">
                    <div class="key-icon-container" *ngFor="let property of serviceOption.Properties | slice:0:4">
                        <img class="service-key-icon" src={{property.IconPath}} />
                        <span class="key-icon-text">{{getIconTextByLanguage(property.Name)}}</span>
                    </div>
                    <div class="key-icon-container"
                        *ngIf="!!serviceOption.Properties && serviceOption.Properties.length < 3">
                        <img class="service-key-icon" src="/assets/icons/find-prices/key_service_null.svg" />
                        <span class="key-icon-text">{{'send_receive.send_receive.service_options.no_key_text_1'
                            | translate}}</span>
                    </div>
                    <div class="key-icon-container"
                        *ngIf="!!serviceOption.Properties && serviceOption.Properties.length < 4">
                        <img class="service-key-icon" src="/assets/icons/find-prices/key_service_null.svg" />
                        <span class="key-icon-text">{{'send_receive.send_receive.service_options.no_key_text_2'
                            | translate}}</span>
                    </div>
                </div>
                <div class="bottom-price-border display-block-mobile">
                    <div class="row">
                        <div *ngIf="!showPostOfficeButton(serviceOption.ProductType)" class="col-lg-12 col-12">
                            <button *ngIf="!!item3Link" class="secondary-button padded full-width"
                            (click)="openRouteInNewWindow(item3Link.Url)">{{item3Link.Title}}</button>
                        </div>
                        <div *ngIf="showPostOfficeButton(serviceOption.ProductType)" class="col-lg-6 col-12">
                            <button *ngIf="!!item3Link" class="secondary-button padded full-width"
                            (click)="openRouteInNewWindow(item3Link.Url)">{{item3Link.Title}}</button>
                        </div>
                        <div *ngIf="showPostOfficeButton(serviceOption.ProductType)" class="col-lg-6 col-12">
                            <button *ngIf="!!item4Link" class="secondary-button padded full-width"
                            (click)="openRouteInNewWindow(item4Link.Url)">{{item4Link.Title}}</button>
                        </div>
                    </div>
                    <div class="row padding-top">
                        <button class="primary-button padded service-option-button full-width" mat-button
                            (click)="navigatetoSendAndReceive()">{{'send_receive.send_receive.service_options.proceed' |
                            translate}}</button>
                    </div>
                    <div class="item-link-container">
                        <div class="align-center" style="padding: 0px;">
                            <a href="" *ngIf="!!item1Link" [routerLink]="item1Link.Url" class="terms-link" target="_blank">{{item1Link.Title}}</a>
                            <span class="terms-insurance-divider" *ngIf="!!serviceOption.InsuranceCost && serviceOption.InsuranceCost > 0">|</span>
                            <a href=""  [routerLink]="item2Link.Url" target="_blank" *ngIf="!!item2Link && !!serviceOption.InsuranceCost && serviceOption.InsuranceCost > 0" class="insurance-link">{{item2Link.Title}}</a>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>