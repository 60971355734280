import { Component, ComponentDecorator, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../services/authentication.service';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;

  passwordSucessfullyReset: boolean = false;

  private code: string;

  passwordsDoNotMatch: boolean = false;

  passwordResetFailed: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private _formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private translateService: TranslateService,
    private roleService: RoleService) { }

  ngOnInit(): void {

    this.code = this.route.snapshot.paramMap.get('code');
    
    this.resetPasswordForm = this._formBuilder.group({
      specifyPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  public submit()
  {
    if (!this.resetPasswordForm.valid)
    {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }

    if (this.resetPasswordForm.get('specifyPassword').value !== this.resetPasswordForm.get('confirmPassword').value)
    {
      //throw passwords do not match error.
      return;
    }

    var forgottenInput: any = {
      newPassword: this.resetPasswordForm.get('confirmPassword').value,
      codeType: 1,
      code: this.code
    }

    //call endpoint
    this.authService.resetPasswordRequest(forgottenInput).subscribe(x => {
      if (x)
      {
        console.log(window.location.href);
        this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/login`])
      }
      else {
        this.passwordResetFailed = true;
      }
    });
  }

}
