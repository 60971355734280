import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class BrochureService {

    httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
    }

    constructor(private httpClient: HttpClient) {
    }

    // public saveToAddressBook(address: any): Observable<any> {
    //     return this.httpClient.post(`${environment.beraUrl}/api/AddressBook`, address)
    //         .pipe(map((data: any) => {
    //             return data;
    //         }));
    // }

    public getByFilter(filter: any): Observable<any> {
        return this.httpClient.post(`${environment.beraUrl}/api/Bera/brochures`, filter, this.httpOptions);

    }

    public getPDF(guid: string): Observable<any> {
        return this.httpClient.get(`${environment.beraUrl}/api/Bera/brochurepdf/${guid}`);
    }

    // public getPostcodes(): Observable<any> {
    //     return this.httpClient.get(`${environment.apiUrl}/api/AddressBook/Postcodes`);
    // }

    // public update(address: any): Observable<object>
    // {
    //   return this.httpClient.put(`${environment.apiUrl}/api/AddressBook`, address);
    // }

    // public favouriteAddress(addressId: string, favourite: boolean): Observable<object>
    // {
    //   return this.httpClient.put(`${environment.apiUrl}/api/AddressBook/${addressId}?IsFavourite=${favourite}`, null);
    // }

    // public delete(addressId: string): Observable<object>
    // {
    //     return this.httpClient.delete(`${environment.apiUrl}/api/AddressBook/${addressId}`);
    // }

    // this.http.get(`/Authentication/MinlykilLogin`, { responseType: 'text'}).subscribe( 
}