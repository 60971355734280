import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomShipmentType } from '../../shared/entities/customShipmentType';
import { Invoice } from '../../shared/entities/Invoice';
import { TranslationHelper } from '../../shared/helpers/translation-helper';
import { TransportType } from '../../shared/price-matrix/entities/TransportType';
import { Country } from '../../account/entities/Country';
import { User } from '../../account/entities/user';
import { UserService } from '../../account/services/user.service';
import { Order } from '../entities/Order';
import { SendReceiveService } from '../new-order/send-and-receive/services/send-receive.service';
import { RoleService } from '../../account/services/role.service';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss']
})
export class ViewOrderComponent implements OnInit {

  public userDetails: User

  public order: Order;

  public serviceOptionName: string;

  public insuranceCost: number;

  public letterName: string;

  public fromDepot: boolean = false;

  public toDepot: boolean = false;

  public countries: Array<Country>;

  public shipmentTypes: Array<CustomShipmentType>;

  public shipmentTypeName: string;

  public displayContactPosta: boolean = false;

  public uploadedFiles: Array<Invoice>;
  downloadingPrintLabel: boolean = false;
  downloadingCDS: boolean = false;

  constructor(private userAccountService: UserService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private roleService: RoleService,
    private translationHelper: TranslationHelper,
    private sendReceiveService: SendReceiveService) { }

  ngOnInit(): void {
    this.order = this.route.snapshot.data.order;

    if (!this.order.isPaid && !this.order.isDirectBooking) {
      if (this.roleService.isPrivateUser())
      {
        this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/send-receive/send-receive/${this.order.orderReference}`]);
        return;
      }
      this.displayContactPosta = true;
    }

    this.countries = this.route.snapshot.data.countries;
    this.shipmentTypes = this.route.snapshot.data.shipmentTypes;
    this.getShipmentTypeName();

    if (!!this.order.service) {
      var transportTypeJson = this.order.service.transportTypeJson;
      var transportType = JSON.parse(transportTypeJson);

      this.setServiceOptionName(transportType);
      if (this.order.insuranceSelected) {
        //This needs to be edited to take into the other factors such as value intervals etc
        this.insuranceCost = transportType.InsuranceCost;
        this.order.parcels
      }
    }

    this.uploadedFiles = this.order.invoices;

    this.userAccountService.getUserDetails().subscribe(x => {
      this.userDetails = x;
    });
  }

  public setServiceOptionName(transportType: TransportType) {
    switch (this.translateService.currentLang) {
      case "en":
        this.serviceOptionName = `${transportType.Name.EN}`;
        break;
      case "fo":
        this.serviceOptionName = `${transportType.Name.FO}`;
        break;
    }
  }


  public setLetterOptionName(transportType: TransportType) {
    switch (this.translateService.currentLang) {
      case "en":
        this.letterName = `${transportType.Name.EN}`;
        break;
      case "fo":
        this.letterName = `${transportType.Name.FO}`;
        break;
    }
  }

  public parcelsHasDimensions(): boolean {
    if (this.order.parcels[0].length > 0 && this.order.parcels[0].width > 0 && this.order.parcels[0].height > 0) {
      return true;
    }
    return false;
  }

  public getWeightMeasurement() {
    if (!!this.order.parcels && this.order.parcels.length > 0) {
      return "kg"
    }
    return "g";
  }

  public getCountryNameByCode(countryCode: string) {
    if (!!this.countries) {
      switch (this.translateService.currentLang) {
        case "en":
          return `${this.countries.find(x => x.countryCode == countryCode).countryNameEN}`;
        case "fo":
          return `${this.countries.find(x => x.countryCode == countryCode).countryNameFO}`;
      }
    }
  }

  public getShipmentTypeName() {
    if (!!this.shipmentTypes) {
      var shipmentTypeTranslation;
      switch (this.translateService.currentLang) {
        case "en":
          shipmentTypeTranslation = this.shipmentTypes.find(x => x.customsShipmentTypeId == this.order.customsShipmentTypeId);
          if (!!shipmentTypeTranslation) {
            return shipmentTypeTranslation.customsShipmentTypeNameEN;
          }
          break;
        case "fo":
          shipmentTypeTranslation = this.shipmentTypes.find(x => x.customsShipmentTypeId == this.order.customsShipmentTypeId);
          if (!!shipmentTypeTranslation) {
            return shipmentTypeTranslation.customsShipmentTypeNameFO;
          }
          break;
      }
    }
  }

  public downloadPrintLabel() {
    this.downloadingPrintLabel = true;
    this.sendReceiveService.downloadPrintLabel(this.order.orderReference).subscribe(x => {
      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingPrintLabel = false;

      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    }, (error) => {
      this.downloadingPrintLabel = false;
      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    })
  }

  public downloadCDS() {
    this.downloadingCDS = true;
    this.sendReceiveService.downloadCDS(this.order.barcode).subscribe(x => {
      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingCDS = false;

      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    }, (error) => {
      this.downloadingCDS = false;
      // this.downloadingPdfOrder = false;
      // this.downloadingReceipt = false;
    })
  }

  public getVolume() {
    var volume: number = 0;
    // var parcels = this.order.parcels;
    // if (!!parcels)
    // {
    //   for (let i = 0; i < parcels.length; i++) {
    //     volume += parcels[i].length*parcels[i].width*parcels[i].height;
    //   }
    // }
    // if (!!this.order.shipment)
    // {
    //   volume += this.order.shipment.length*this.order.shipment.width*this.order.shipment.height;
    // }
    volume = this.order.volume;
    return volume;
  }

  public getCountryName(country: Country) {
    return this.translationHelper.getCountryName(country);
  }

  public isDomestic() {
    return this.order.fromCountryCode == this.order.toCountryCode;
  }
}
