import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ShoppingListService } from '../services/shopping-list-service';
import { AuthenticationService } from '../../account/services/authentication.service';
import { ShoppingListsLoggedOut } from '../models/ShoppingListLoggedOut';

@Component({
  selector: 'app-new-shopping-list-dialog',
  templateUrl: './new-shopping-list-dialog.component.html',
  styleUrls: ['./new-shopping-list-dialog.component.scss']
})
export class NewShoppingListDialogComponent implements OnInit {

  public shoppingListName: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewShoppingListDialogComponent>,
    public shoppingListService: ShoppingListService,
    public authService: AuthenticationService) {}

    ngOnInit() {
        // will log the entire data object
        console.log(this.data)
      }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public onLoad(event: any): void {
  }

  public createNewShoppingList(): void {
    if (!this.shoppingListName || this.shoppingListName === '')
    {
      return;
      //TODO display an error to the user to they need to define a name.
    }

    if (this.authService.isLoggedIn())
    {
      this.shoppingListService.create(this.shoppingListName).subscribe(x => {
        this.dialogRef.close(x);
      })
    }
    else {
      var localShoppingLists = localStorage.getItem('shoppingLists');
      if (!!localShoppingLists)
      {
        var lists: ShoppingListsLoggedOut = JSON.parse(localShoppingLists);
        // if (lists.shoppingLists.map(x => x.name).includes(this.shoppingListName))
        // {
        //   return;
        // }
        if (!lists.shoppingLists)
        {
          lists.shoppingLists = [];
        }
        var newList = {name: this.shoppingListName, products: null};
        lists.shoppingLists.push(newList);
        localStorage.setItem('shoppingLists', JSON.stringify(lists));
        this.dialogRef.close(newList);
      }
      else {
        var newShoppingLists: ShoppingListsLoggedOut = {shoppingLists: []};
        var newList = {name: this.shoppingListName, products: null};

        //Set the all the current lists to active false
        newShoppingLists.shoppingLists.forEach(x => x.isActive = false);

        newShoppingLists.shoppingLists.push({name: this.shoppingListName, products: null, isActive: true});
        localStorage.setItem('shoppingLists', JSON.stringify(newShoppingLists));
        this.dialogRef.close(newList);
      }

    }
  }
}
