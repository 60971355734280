import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslationString } from '../../shared/price-matrix/entities/TranslationString';
import { Category } from '../entities/category';
import { CustomsEstimate } from '../entities/CustomsEstimate';
import { CustomsInput } from '../entities/CustomsInput';
import { Subcategory } from '../entities/subCategory';
import { CustomsService } from '../services/customs.service';
import { TranslationHelper } from '../../shared/helpers/translation-helper'
import { ScrollViewHelper } from '../../shared/helpers/scroll-view-helper'
import { CustomCalculatorExample } from '../entities/CustomCalculatorExample';
import { ErrorHelper } from '../../shared/helpers/error-helper';
import { StaticContentHelper } from '../../shared/helpers/static-content-helper';
import { StaticContentService } from '../../shared/services/static-content.service';

@Component({
  selector: 'app-customs-calculator',
  templateUrl: './customs-calculator.component.html',
  styleUrls: ['./customs-calculator.component.scss']
})
export class CustomsCalculatorComponent implements OnInit, AfterViewInit {

  public pageTitle: string;

  public pageModules: string;

  customsCalculatorForm: FormGroup;

  originValue: any;

  typeOfGoods: Array<Category> = [];

  examples: Array<CustomCalculatorExample> = [];

  typeOfGoodSelected: boolean = false;

  selectedTypeOfGood: Category;

  showCalculateEstimateButtons: boolean = false;

  selectedSubcategory: Subcategory;

  showEstimate: boolean = false;

  enterWeight: boolean = false;

  unitOfMeasurement: string;

  otherSelected: boolean = false;

  categoryFromTaksCode: string;

  customsEstimate: CustomsEstimate;

  showHsCodeLookupError: boolean = false;

  validHsCode: string;

  showOriginError: boolean = false;

  interval: any;
  observer: MutationObserver;

  constructor(private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private customsService: CustomsService,
    private translationHelper: TranslationHelper,
    private scrollViewHelper: ScrollViewHelper,
    private errorHelper: ErrorHelper,
    private staticContentService: StaticContentService,
    private elRef: ElementRef) { }

  ngOnInit(): void {

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
    })

    this.customsCalculatorForm = this._formBuilder.group({
      goodsValue: ['', [Validators.required, ErrorHelper.numberOnlyValidator]],
      restOfTheWorld: ['', Validators.required],
      eu: ['', Validators.required],
      weight: ['', [Validators.required, ErrorHelper.numberOnlyValidator]],
      taksCode: ['', Validators.required]
    });
    
    this.translateService.onLangChange.subscribe(x => {
      this.translationHelper.convertNumberField(this.customsCalculatorForm.get(`goodsValue`));
      this.translationHelper.convertNumberField(this.customsCalculatorForm.get(`weight`));
    });
    
    this.customsCalculatorForm.get(`goodsValue`).valueChanges.subscribe(newValue => {
      this.translationHelper.formatNumberField(this.customsCalculatorForm.get('goodsValue'));
    });
    this.customsCalculatorForm.get(`weight`).valueChanges.subscribe(newValue => {
      this.translationHelper.formatNumberField(this.customsCalculatorForm.get('weight'));
    });

    this.customsCalculatorForm.get('goodsValue').valueChanges.subscribe

    this.typeOfGoods = this.route.snapshot.data.customCalculator.Categories;
    this.typeOfGoods.forEach(x => x.IconPath = `${this.staticContentService.cdn()}/${x.IconPath}`)
    this.examples = this.route.snapshot.data.customCalculator.Examples;
    this.examples.forEach(x => x.ImagePath = `${this.staticContentService.cdn()}/${x.ImagePath}`)


    this.examples.forEach(example => {

      var input: CustomsInput = {
        tarriffNumber: example.TariffNo.toString(),
        value: 0,
        isTradeDeal: true,
        weight: 0,
      }

      this.customsService.calculateEstimate(input).subscribe(x => {
        example.VatPercent = x.vatPercent;
      })
    })

    this.customsCalculatorForm.get('weight').disable();
    this.customsCalculatorForm.get('taksCode').disable();
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));
      });
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }
  
  public getPageTitle()
  {
    return this.staticContentService.getPageTitle();
  }

  public hasErrors(field){
    var errors = this.customsCalculatorForm.get(field).errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.customsCalculatorForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public euSelected(event: any) {
    this.customsCalculatorForm.get('restOfTheWorld').setValidators(null);
  }

  public restOfTheWorldSelected(event: any) {
    this.customsCalculatorForm.get('eu').setValidators(null);
  }

  public showSubCategoriesForSelectedGood(category: Category) {

    if (category.Name.EN !== "Other") {
      this.typeOfGoodSelected = true;
    }
    else {
      this.enableOtherCategoryFields();
    }

    this.selectedTypeOfGood = category;
  }

  public resetTypeOfGoods() {
    this.typeOfGoodSelected = false;
    this.showCalculateEstimateButtons = false;
    this.selectedTypeOfGood = null;
    this.selectedSubcategory = null;
    this.showEstimate = false;
    this.enterWeight = false;
    this.otherSelected = false;
    this.categoryFromTaksCode = null;
    this.customsCalculatorForm.get('weight').disable();
    this.customsCalculatorForm.get('taksCode').disable();
  }

  public showEstimatesSection(subcategory: Subcategory) {
    this.showCalculateEstimateButtons = true;
    this.selectedSubcategory = subcategory;

    if (subcategory.WeightRequired) {
      this.enterWeight = true;
      this.customsCalculatorForm.get('weight').enable();
    }
    else {
      this.enterWeight = false;
      this.customsCalculatorForm.get('weight').reset();
      this.customsCalculatorForm.get('weight').disable();
    }

    this.showEstimate = false;
  }

  public calculateEstimate() {
    this.customsCalculatorForm.markAllAsTouched();

    if (this.customsCalculatorForm.invalid) {
      if (this.customsCalculatorForm.get("eu").invalid || this.customsCalculatorForm.get("restOfTheWorld").invalid) {
        this.showOriginError = true;
      }

      return;
    }

    this.showOriginError = false;

    var isTradeDeal: boolean = true;
    if (!!this.customsCalculatorForm.get("eu").value) {
      isTradeDeal = false;
    }

    var tariffNum: string;
    if (this.otherSelected) {
      tariffNum = this.validHsCode
    }
    else {
      tariffNum = this.selectedSubcategory.TariffNo.toString();
    }

    if (this.customsCalculatorForm.valid && (!!this.selectedSubcategory || !!this.categoryFromTaksCode)) {
      //TODO call service to get estimate

      var input: CustomsInput = {
        tarriffNumber: tariffNum,
        value: this.translationHelper.getNumber(this.customsCalculatorForm.get("goodsValue").value),
        isTradeDeal: isTradeDeal,
        weight: this.translationHelper.getNumber(this.customsCalculatorForm.get("weight").value),
      }

      this.customsService.calculateEstimate(input).subscribe(estimate => {
        this.customsEstimate = estimate;
        this.showEstimate = true;
      })
    }
  }

  public clearAll() {
    this.resetTypeOfGoods();
    this.customsCalculatorForm.reset();
  }

  public getCategoryFromTaksCode() {
    if (!!this.customsCalculatorForm.get('taksCode').value) {
      //TODO call service to get category from code
      this.customsService.getCategoryByHsNumber(this.customsCalculatorForm.get('taksCode').value)
        .subscribe(x => {
          this.categoryFromTaksCode = `${x.foCaption}, ${x.daExtendedCaption}`
          this.showCalculateEstimateButtons = true;
          this.showHsCodeLookupError = false;
          this.validHsCode = this.customsCalculatorForm.get('taksCode').value;
        },
          (err) => {
            this.showHsCodeLookupError = true;
            this.categoryFromTaksCode = "Invalid Taks code"
            this.customsCalculatorForm.get('taksCode').setErrors({'invalidCode': true});
          })
    }
  }

  public openTaksSite() {
    window.open("https://voruskra.taks.fo/en/Sections");
  }

  public getCurrentTranslation(translationString: TranslationString) {
    switch (this.translateService.currentLang) {
      case "en":
        return `${translationString.EN}`;
      case "fo":
        return `${translationString.FO}`;
    }
  }

  public languageIsFO(): boolean {
    switch (this.translateService.currentLang) {
      case "en":
        return false;
      case "fo":
        return true;
    }
  }

  public getUnitOfMeasurement() {
    if (!!this.selectedSubcategory) {
      return this.translationHelper.getTranslation(this.selectedSubcategory.UnitOfMeasurement);
    }
  }

  public getStringForLanguage(translationString: TranslationString) {
    return this.translationHelper.getTranslation(translationString);
  }

  public getNestedStringForLanguage(example: CustomCalculatorExample) {
    return this.translationHelper.getNestedTranslation(example.Body, example);
  }

  public getDuty()
  {
    return this.customsEstimate.gatt + this.customsEstimate.dutyValue + this.customsEstimate.dutyUnits;
  }

  public inputFieldsForExample(example: CustomCalculatorExample) {

    const parentCategory = this.typeOfGoods.find(x => x.Subcategories?.find(y => y.TariffNo == Number(example.TariffNo)));

    if (!!parentCategory) {

      const subcategory = parentCategory.Subcategories.find(x => x.TariffNo == Number(example.TariffNo));

      if (!!subcategory) {
        if (this.otherSelected) {
          this.clearAll();
        }

        this.showSubCategoriesForSelectedGood(parentCategory);

        var checkExist = setInterval(function () {
          let temp = document.getElementById(subcategory.TariffNo.toString())
          if (!!temp) {
            temp.click();
            document.getElementById("restOfTheWorld").click();
            temp.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
            clearInterval(checkExist);
          }
        }, 100);
      }
      else {
        this.clearAll();
        this.selectOtherCategory();
        document.getElementById("restOfTheWorld").click();
        this.customsCalculatorForm.get("taksCode").setValue(example.TariffNo);
        this.scrollViewHelper.scrollIntoViewAndCenter("taksCode");
      }
    }
    else {
      this.clearAll();
      this.selectOtherCategory();
      document.getElementById("restOfTheWorld").click();
      this.customsCalculatorForm.get("taksCode").setValue(example.TariffNo);
      this.scrollViewHelper.scrollIntoViewAndCenter("taksCode");
    }

  }

  private selectOtherCategory() {
    const otherCategory = this.typeOfGoods.find(x => x.Name.EN == "Other");
    this.showSubCategoriesForSelectedGood(otherCategory);
  }

  private enableOtherCategoryFields() {
    this.otherSelected = true;
    this.enterWeight = true;
    this.customsCalculatorForm.get('weight').enable();
    this.customsCalculatorForm.get('taksCode').enable();
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }
}
