import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CountryGroup } from '../../../shared/price-matrix/entities/CountryGroup';
import { ParcelRange } from '../../../shared/price-matrix/entities/parcelRange';
import { PriceCountryGroup } from '../../../shared/price-matrix/entities/pricedCountryGroup';
import { TranslationString } from '../../../shared/price-matrix/entities/TranslationString';
import { TransportType } from '../../../shared/price-matrix/entities/TransportType';
import { PriceMatrixService } from '../../../shared/price-matrix/services/price-matrix.service';
import { PostageItem } from '../../entities/postageItem';
import { PostageOrder } from '../../entities/postageOrder';
import { BuyPostageService } from '../../services/buy-postage.service'
import { TranslationHelper } from '../../../shared/helpers/translation-helper';
import { Postage } from '../../entities/postage';
import { PaymentService } from '../../services/payment.service';
import { interval } from 'rxjs/internal/observable/interval';
import { startWith } from 'rxjs/internal/operators/startWith';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { Property } from '../../../shared/price-matrix/entities/property';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment'
import { Pricing } from '../../../shared/price-matrix/entities/pricing';
import { IncoTermsGroup } from '../../../shared/price-matrix/entities/incoTermsGroup';
import { Guid } from '../../../shared/guid/guid';
import { StaticContentService } from '../../../shared/services/static-content.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BuyPostageSecondaryComponent } from '../buy-postage-secondary/buy-postage-secondary.component';
import { AuthenticationService } from '../../../account/services/authentication.service';
import { ErrorHelper } from '../../../shared/helpers/error-helper';
import { CustomsItemType } from '../../entities/CustomsItemType';
import { BuyPostageCustomsComponent } from '../international-goods/buy-postage-customs/buy-postage-customs.component';

@Component({
  selector: 'app-buy-postage',
  templateUrl: './buy-postage.component.html',
  styleUrls: ['./buy-postage.component.scss']
})
export class BuyPostageComponent implements OnInit, AfterViewInit {

  @ViewChild('paymentFrame') iframe: ElementRef;

  @ViewChild(BuyPostageSecondaryComponent) buyPostageSecondary!: BuyPostageSecondaryComponent;
  @ViewChild(BuyPostageCustomsComponent) buyPostageCustoms!: BuyPostageCustomsComponent;

  public pageTitle: string;

  public pageModules: string;

  intervalSet: boolean = false;

  buyPostageForm: FormGroup;
  buyPostageAddressesForm: FormGroup;
  buyPostageCustomsForm: FormGroup;
  emailForm: FormGroup;

  itemTypes: CustomsItemType[] = [new CustomsItemType()];

  weightBoundariesRawValues: Array<Number>;

  weightBoundaries: Array<string> = new Array<string>();

  chosenWeightBoundary: string;

  fromToValue: number;

  isGoods: boolean;

  sizeValue: number = 0;

  fromToOptionIndexSelected: number = 0;

  fromToOptions: Array<CountryGroup>;

  showSizeOptions: boolean = false;

  showWeightExceedsNotice: boolean = false;

  sizeOptions: Array<TransportType>;

  showSecondPage: boolean = false;

  showAddressesPage: boolean = false;

  showCustomsPage: boolean = false;

  showLoginPage: boolean = false;

  price: number = 0;

  pricePerItem: number;
  showPaymentScreen: boolean = false;

  private stopPolling = new Subject();

  public paymentSuccess: boolean = false;

  public showXLErrorMessage: boolean = false;

  public anonymousUserGuid: string;

  public postageConfirmationDetails: PostageOrder;
  orderRef: string;
  isDomestic: boolean = false;

  interval: any;
  showPaymentFailed: boolean = false;
  observer: MutationObserver;
  iframeURL: any;
  isLoggedIn: boolean;

  constructor(private priceMatrixService: PriceMatrixService,
    private buyPostageService: BuyPostageService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private translationHelper: TranslationHelper,
    private paymentService: PaymentService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private staticContentService: StaticContentService,
    private elRef: ElementRef,
    private _sanitizer: DomSanitizer,
    private authService: AuthenticationService,
    private errorHelper: ErrorHelper) { }

  ngOnInit(): void {

    this.isLoggedIn = this.authService.isLoggedIn()

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
    })

    this.buyPostageForm = this.formBuilder.group({
      fromTo: ['', Validators.required],
      weight: ['', Validators.required],
      size: ['', Validators.required],
      itemCount: [Validators.required, Validators.min(1)],
      agreeToTermsConditions: [''],
      isGoods: ['', Validators.required]
    });

    if(!this.isLoggedIn){
      this.emailForm = this.formBuilder.group({
        email: ['', [Validators.required, ErrorHelper.emailValidator]]
      })
    }

    this.buyPostageAddressesForm = this.formBuilder.group({
      fromFirstName: ['', Validators.required],
      fromLastName: ['', Validators.required],
      fromContactCountryCode: ['', ErrorHelper.countryCodeValidator],
      fromContactNum: ['', ErrorHelper.integerOnlyValidator],
      fromAddressLine1: ['', Validators.required],
      fromPostcode: ['', Validators.required],
      fromTownCity: ['', Validators.required],
      registerForEmail: [''],
      registerForSMS: [''],
      toFirstName: ['', Validators.required],
      toLastName: ['', Validators.required],
      toContactCountryCode: ['', ErrorHelper.countryCodeValidator],
      toContactNum: ['', ErrorHelper.integerOnlyValidator],
      toAddressLine1: ['', Validators.required],
      toAddressLine2: [''],
      toPostcode: ['', Validators.required],
      toTownCity: ['', Validators.required],
      toCountry: ['', Validators.required]
    });
    this.buyPostageAddressesForm.get(`fromContactCountryCode`).valueChanges.subscribe(newValue => {
      this.translationHelper.prependPlus(this.buyPostageAddressesForm.get('fromContactCountryCode'));
    });
    this.buyPostageAddressesForm.get(`toContactCountryCode`).valueChanges.subscribe(newValue => {
      this.translationHelper.prependPlus(this.buyPostageAddressesForm.get('toContactCountryCode'));
    });

    this.buyPostageCustomsForm = this.formBuilder.group({
      shipmentType: ['', Validators.required],
      shipmentTypeOther: ['', Validators.required]
    });

    this.buyPostageForm.get("itemCount").setValue("1");

    this.priceMatrixService.getBuyPostageCountrySchemes().subscribe(x => {
      this.fromToOptions = x;
      this.route.queryParams.subscribe(params => {

        const zoneId = params['zoneId'];
        if (!!zoneId) {
          var matchingZoneIndex = x.findIndex(x => x.Key == zoneId);
          this.fromToValue = matchingZoneIndex;

          this.fromToOptionIndexSelected = matchingZoneIndex;
          if (!!this.chosenWeightBoundary) {
            this.getSizeForOptions();
          }
        }
      });
    })

    // this.buyPostageService.getOrderConfirmation("SX10000041").subscribe(y => {
    //   this.postageConfirmationDetails = y;
    // });

    this.priceMatrixService.getPostageWeightBoundaries().subscribe(x => {
      this.weightBoundariesRawValues = x;
      for (let i = 0; i <= x.length; i++) {
        //first item so prefix with 0
        if (i == 0) {
          this.weightBoundaries.push(`0-${x[i]}g`);
        }
        else if (i == x.length) {
          this.weightBoundaries.push(`${x[i - 1] + 1}g +`)
        }
        else {
          this.weightBoundaries.push(`${x[i - 1] + 1}-${x[i]}g`)
        }
      }
      this.route.queryParams.subscribe(params => {

        const maxWeight = params['maxWeight'];
        if (!!maxWeight) {
          var matchingWeightIndex = x.findIndex(x => x == maxWeight);
          this.chosenWeightBoundary = this.weightBoundaries[matchingWeightIndex];
          this.setDefaultWeight();
        }
      });
    })

    this.route.queryParams.subscribe(params => {

      const itemCount = params['itemCount'];

      if (!!itemCount && itemCount) {
        this.buyPostageForm.get("itemCount").setValue(itemCount);
      }
      // if (!!isLargeShipment && isLargeShipment) {
      //   this.findPricesisLargeShipment = isLargeShipment;
      // }
      // if (!!numberOfPalettes && numberOfPalettes > 0) {
      //   this.numberOfPalettes = +numberOfPalettes;
      // }
    });
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));                               
      });   
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

  public hasErrors(field) {
    var errors = this.buyPostageForm?.get(field)?.errors;
    return !!errors;
  }

  public getError(field) {
    var errors = this.buyPostageForm?.get(field)?.errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public setWeight(event: any) {
    this.showXLErrorMessage = false;
    this.showWeightExceedsNotice = false;
    this.chosenWeightBoundary = event.value;
    if (!!this.chosenWeightBoundary) {
      this.getSizeForOptions();
    }
  }

  public setDefaultWeight() {
    this.showXLErrorMessage = false;
    this.showWeightExceedsNotice = false;
    if (!!this.chosenWeightBoundary) {
      this.getSizeForOptions();
    }
  }

  private getSizeForOptions() {
    var index = this.weightBoundaries.indexOf(this.chosenWeightBoundary);
    if (index == this.weightBoundariesRawValues.length) {
      //Show weight exceeds and use send receive
      this.showWeightExceedsNotice = true;
      this.showSizeOptions = false;
      this.sizeOptions = new Array<TransportType>();
      return;
    }
    var weightValue = this.weightBoundariesRawValues[index];

    this.priceMatrixService.getBuyPostageSizeOptions(this.fromToOptionIndexSelected, weightValue).subscribe(x => {
      this.route.queryParams.subscribe(params => {

        const transportTypeId = params['id'];

        if (!!transportTypeId) {
          this.sizeValue = Number(transportTypeId);
        }
      });

      this.sizeOptions = x;
      this.showSizeOptions = true;

      var nameTranslation: TranslationString = {
        EN: "XL",
        GL: "XL",
        FO: "XL",
        DA: "XL"
      }

      var extraLargeTransportType: TransportType = {
        Name: nameTranslation,
        Description: this.translateService.instant('buy_postage.extra_large_description'),
        Key: 999,
        DefaultExtras: new Array<Pricing>(),
        IncoTermsGroups: new Array<IncoTermsGroup>(),
        CountryGroups: new Array<PriceCountryGroup>(),
        Tag: "",
        BuyPostageIconPath: "/assets/icons/buy-postage/extraLarge@3x.png",
        Properties: new Array<Property>(),
        MinimumDeliveryDays: 0,
        MaximumDeliveryDays: 0,
        SpecialExtras: new Array<Pricing>(),
        IsAvailableForBulk: false,
        ProductTypeId: 0,
        BarcodePrefix: null,
      }

      this.sizeOptions.push(extraLargeTransportType);
      this.cd.detectChanges();
    })
  }

  public getTranslation(stringToBeTranslated: TranslationString) {
    if(stringToBeTranslated){
      switch (this.translateService.currentLang) {
        case "en":
          if(stringToBeTranslated.EN && stringToBeTranslated.EN != "undefined"){
            return `${stringToBeTranslated.EN}`;
          }
        case "fo":
          if(stringToBeTranslated.FO && stringToBeTranslated.FO != "undefined"){
            return `${stringToBeTranslated.FO}`;
          }
      }
    }
  }

  public destinationSelected(index: number) {
    this.fromToOptionIndexSelected = index;
    if (!!this.chosenWeightBoundary) {
      this.getSizeForOptions();
    }
  }

  public showSecondaryPage() {
    if (!this.buyPostageForm.valid) {
      this.buyPostageForm.markAllAsTouched();
      return;
    }
    if(this.buyPostageForm.get('isGoods').value === true && this.fromToOptionIndexSelected != 0){
      this.showAddressesPage = true;
      this.showLoginPage = false;
      this.showSecondPage = false;
    }
    else{
      this.isLoggedIn = this.authService.isLoggedIn();
      if(this.isLoggedIn || this.emailForm.valid){
        this.showSecondPage = true;
        this.showLoginPage = false;
        this.showAddressesPage = false;
      }
      else{
        this.showLoginPage = true;
        this.showSecondPage = false;
        this.showAddressesPage = false;
      }
    }
    window.scrollTo(0, 0);
  }

  public returnToAddressesPage(){
    this.showAddressesPage = true;
    this.showCustomsPage = false;
    this.showSecondPage = false;
    window.scrollTo(0, 0);
  }

  public proceedToCustomsPage(){
    if(!this.buyPostageAddressesForm.valid){
      this.buyPostageAddressesForm.markAllAsTouched();
      return;
    }
    this.showAddressesPage = false;
    this.showCustomsPage = true;
    window.scrollTo(0, 0);
  }

  public getItemPrice() {
    if (!this.buyPostageForm.valid) {
      return this.price;
    }

    var itemCount = this.buyPostageForm.get("itemCount").value;

    var index = this.weightBoundaries.indexOf(this.chosenWeightBoundary);
    var weightValue = this.weightBoundariesRawValues[index];

    var selectedTransportTypeIndex: number = this.buyPostageForm.get("size").value;
    var selectedTransportType: TransportType = this.sizeOptions[selectedTransportTypeIndex];

    if (selectedTransportType.Name.EN == "XL") {
      return;
    }

    var validCountryGroup: PriceCountryGroup = selectedTransportType.CountryGroups.find(x => x.LinkedGroupKey == this.fromToOptionIndexSelected);

    var basePrice: number = validCountryGroup.BasePriceWeight;

    var validParcelRange: ParcelRange = validCountryGroup.ParcelRanges.find(x => x.MinVolume < weightValue && x.MaxWeight >= weightValue);
    if (!!validParcelRange && validParcelRange.BaseCostOverride > 0) {
      this.pricePerItem = validParcelRange.BaseCostOverride;
      basePrice = validParcelRange.BaseCostOverride * itemCount;
    }

    this.price = basePrice;
    return this.price;
  }

  public paymentScreenEvent(items: Array<Postage>) {
    if(this.buyPostageSecondary){
      this.buyPostageSecondary.loadingPayment = true;
    }
    else if(this.buyPostageCustoms){
      this.buyPostageCustoms.loadingPayment = true;
    }
    var orderDetail = new Array<PostageItem>();
    var itemCount = items.length;

    if (this.fromToOptionIndexSelected == 0) {
      this.isDomestic = true;
    }

    for (let i = 0; i < itemCount; i++) {
      var weightIndex = this.weightBoundaries.indexOf(this.chosenWeightBoundary);

      var postageItem: PostageItem = {
        ZoneId: this.fromToOptionIndexSelected,
        ZoneText: this.translationHelper.getTranslation(this.fromToOptions[this.fromToOptionIndexSelected].Name),
        WeightId: weightIndex,
        WeightText: this.weightBoundaries[weightIndex],
        SizeCategoryId: Number(this.buyPostageForm.get("size").value),
        SizeCategoryText: this.translationHelper.getTranslation(this.sizeOptions[this.buyPostageForm.get('size').value].Name),
        Quantity: 1,
        UnitPrice: this.pricePerItem,
        netAmount: this.pricePerItem,
        GrossAmount: this.pricePerItem,
        CustomerReference: items[i].reference
      }

      orderDetail.push(postageItem);
    }

    this.anonymousUserGuid = Guid.newGuid();

    var transportType = this.sizeOptions[this.buyPostageForm.get('size').value]

    var isGoodsInternational = this.isGoods === true && this.fromToOptionIndexSelected != 0;

    var postageOrder: PostageOrder = {
      ananymousUserGuid: this.anonymousUserGuid,
      orderDetail: orderDetail,
      languageCode: this.translateService.currentLang,
      isGoodsInternational: isGoodsInternational,
      barcodePrefix: transportType.BarcodePrefix
    }

    if(isGoodsInternational){
      postageOrder.customsItemTypes = this.itemTypes;
      postageOrder.fromFirstName = this.buyPostageAddressesForm.get('fromFirstName').value;
      postageOrder.fromLastName = this.buyPostageAddressesForm.get('fromLastName').value;
      postageOrder.fromContactNumber = this.buyPostageAddressesForm.get('fromContactNum').value;
      postageOrder.fromLastName = this.buyPostageAddressesForm.get('fromLastName').value;
      postageOrder.fromAddressLine1 = this.buyPostageAddressesForm.get('fromAddressLine1').value;
      postageOrder.fromPostcode = this.buyPostageAddressesForm.get('fromPostcode').value;
      postageOrder.fromTownCity = this.buyPostageAddressesForm.get('fromTownCity').value;
      postageOrder.fromCountryCode = "FO"; //always FO for Buy Postage

      postageOrder.isPreferEmail = this.buyPostageAddressesForm.get('registerForEmail').value === true;
      postageOrder.isPreferSMS = this.buyPostageAddressesForm.get('registerForSMS').value === true;

      postageOrder.toFirstName = this.buyPostageAddressesForm.get('toFirstName').value;
      postageOrder.toLastName = this.buyPostageAddressesForm.get('toLastName').value;
      postageOrder.toContactNumber = this.buyPostageAddressesForm.get('toContactNum').value;
      postageOrder.toAddressLine1 = this.buyPostageAddressesForm.get('toAddressLine1').value;
      postageOrder.toAddressLine2 = this.buyPostageAddressesForm.get('toAddressLine2').value;
      postageOrder.toPostcode = this.buyPostageAddressesForm.get('toPostcode').value;
      postageOrder.toTownCity = this.buyPostageAddressesForm.get('toTownCity').value;
      postageOrder.toCountryCode = this.buyPostageAddressesForm.get('toCountry').value.countryCode;

      postageOrder.customsShipmentTypeId = this.buyPostageCustomsForm.get('shipmentType').value;
      postageOrder.customsOtherDescription = this.buyPostageCustomsForm.get('shipmentTypeOther').value;
    }

    this.buyPostageService.createPostageOrder(postageOrder).subscribe(x => {
      this.showPaymentScreen = true;
      this.isLoggedIn = this.authService.isLoggedIn()
      if (localStorage.getItem("role") !== "private" && this.authService.isLoggedIn()) {
        this.poll(x);
      }
      var endpoint = `${environment.dibsUrl}/payment/WebPay?locale=${this.translateService.currentLang}&systemType=5&serviceType=5&orderId=${x}&amount=${this.getItemPrice()}`;
      if(!this.isLoggedIn){
        endpoint = `${endpoint}&paidByEmail=${this.emailForm.get("email").value}`
      }

      this.paymentService.dibsPay(endpoint).subscribe(
        y => {
          this.iframeURL = this._sanitizer.bypassSecurityTrustResourceUrl(y)
          this.onFrameLoad(x);
        })
        this.buyPostageSecondary.loadingPayment = false;
    }, (error) => {
      this.buyPostageSecondary.loadingPayment = false;
    })
  }

  public onFrameLoad(orderRef: string) {
    if (!this.intervalSet) {
      this.intervalSet = true;
      interval(5000).pipe(
        startWith(0),
        switchMap(() => this.buyPostageService.getOrder(orderRef, this.anonymousUserGuid)),
        takeUntil(this.stopPolling)).subscribe(x => {
          if (x.isPaid) {

            //call confirmation endpoint
            this.buyPostageService.getOrderConfirmation(orderRef, this.anonymousUserGuid).subscribe(y => {
              y.isGoodsInternational = this.isGoods;
              this.postageConfirmationDetails = y;
              this.postageConfirmationDetails.barcode = x.barcode;
              this.postageConfirmationDetails.isCDSFiled = x.isCDSFiled;
            });

            this.stopPolling.next();
            document.getElementById("paymentFrame").remove();
            this.paymentSuccess = true;
            return;
          }
          if (x.attemptedPaymentYN) {
            //Throw an error saying paymnet has failed.
            this.showPaymentFailed = true;
            document.getElementById("paymentFrame").remove();
            this.stopPolling.next();
            this.paymentService.updatePaymentStatus({serviceTypeId: 5, orderReference: orderRef}).subscribe();
            return;
          }
        }), (err) => { this.showPaymentFailed =  true;
          console.log("Error getting order after payment: " + err) };
    }
  }

  public poll(orderRef: string) {
    if (!this.intervalSet) {
      this.intervalSet = true;
      interval(5000).pipe(
        startWith(0),
        switchMap(() => this.buyPostageService.getOrder(orderRef, this.anonymousUserGuid)),
        takeUntil(this.stopPolling)).subscribe(x => {
          if (!!document.getElementById("paymentFrame"))
          {
            document.getElementById("paymentFrame").remove();
          }
          if (x.isPaid) {

            //call confirmation endpoint
            this.buyPostageService.getOrderConfirmation(orderRef, this.anonymousUserGuid).subscribe(y => {
              this.postageConfirmationDetails = y;
              this.postageConfirmationDetails.barcode = x.barcode;
              this.postageConfirmationDetails.isCDSFiled = x.isCDSFiled;
            });

            this.stopPolling.next();
            this.paymentSuccess = true;
            return;
          }
          if (x.attemptedPaymentYN) {
            //Throw an error saying paymnet has failed.
            this.showPaymentFailed = true;
            this.paymentService.updatePaymentStatus({serviceTypeId: 5, orderReference: orderRef}).subscribe();
            this.stopPolling.next();
            return;
          }
        }), (err) => { this.showPaymentFailed =  true;
          console.log("Error getting order after payment: " + err) };
    }
  }

  public clearAll() {
    this.buyPostageForm.reset();
    this.showSizeOptions = false;
    this.buyPostageForm.get("itemCount").setValue("1");
    this.showWeightExceedsNotice = false;
    this.showXLErrorMessage = false;
  }

  public getIconForSize(transportTypeId: number) {
    return "/assets/icons/find-prices/Parcel_blue2@3x.png";
  }

  public showFirstScreen() {
    this.showSecondPage = false;
    this.showLoginPage = false;
    this.showAddressesPage = false;
    this.showCustomsPage = false;
    window.scrollTo(0, 0);
  }

  public checkIsNotXL(event: any) {
    var sizeIndex = this.buyPostageForm.get("size").value;

    if (this.sizeOptions[sizeIndex].Name.EN != "XL") {
      this.showXLErrorMessage = false;
      this.showSizeOptions = true;
      return;
    }

    this.showXLErrorMessage = true;
  }

  public navigateToSendReceive() {
    this.router.navigate([`${this.translateService.currentLang}/private/send-receive/send-receive`]);
  }

  public getPageTitle() {
    return this.staticContentService.getPageTitle();
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }

}
