import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { RegisterComponent } from "./register/register.component";
import { ForgottenPasswordComponent } from "./forgotten-password/forgotten-password.component";
import { VerificationResolver } from "./services/verification.resolver";
import { MobileVerificationResolver } from "./services/mobile-verification.resolver";
import { VerificationComponent } from "./verification/verification.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { PrivateLoginComponent } from "./login/private-login/private-login.component";
import { BusinessLoginComponent } from "./login/business-login/business-login.component";
import { UpdateProfileComponent } from "./update-profile/update-profile.component";
import { UserProfileResolver } from "./services/userProfile.resolver";
import { AwaitingVerificationComponent } from "./awaiting-verification/awaiting-verification.component";
import { AwaitingPostaApprovalComponent } from "./awaiting-posta-approval/awaiting-posta-approval.component";
import { AuthGuard } from "../shared/guards/auth.guard";
import { AccountStatementComponent } from "./account-statement/account-statement.component";

const routes: Routes = [
    { path: ':lang/private/login', component: PrivateLoginComponent},
    { path: ':lang/business/login', component: BusinessLoginComponent },
    { path: ':lang/:role/register', component: RegisterComponent},
    { path: ':lang/:role/awaiting-approval', component: AwaitingPostaApprovalComponent},
    { path: ':lang/:role/forgotten-password', component: ForgottenPasswordComponent},
    { path: ':lang/verify_account_email/:code', component: VerificationComponent, resolve: { verified: VerificationResolver }},
    { path: ':lang/verify_account_mobile/:code', component: VerificationComponent, resolve: { verified: MobileVerificationResolver }},
    { path: ':lang/:role/reset_password/:code', component: ResetPasswordComponent },
    { path: ':lang/:role/change-password', component: ChangePasswordComponent },
    { path: ':lang/:role/my-posta/update-profile', component: UpdateProfileComponent, resolve: { userProfile: UserProfileResolver }, canActivate: [AuthGuard] },
    { path: ':lang/:role/my-posta/account-statement', component: AccountStatementComponent, canActivate: [AuthGuard] },
    // { path: 'login/samleikin', component: SamleikinLoginComponent },
    // { path: 'migrate/samleikin', component: SamleikinMigrateComponent, canActivate: [AuthGuard] },
    { path: ':lang/:role/awaiting-verification/:email-sent', component: AwaitingVerificationComponent },
    { path: ':lang/:role/awaiting-verification', component: AwaitingVerificationComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule { }