export enum ServiceType {
    TrackTrace = 0,
    Proxy = 1,
    Parcel = 2,
    Customs = 3,
    Waybill = 4,
    BuyPostage = 5,
    LargeShipment = 6,
    Other = 255,
    Letter = 9
  }