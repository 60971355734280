import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Invoice } from '../entities/Invoice';
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class UploadFileService {
    constructor(private httpClient: HttpClient) {
    }

    public addInvoice(invoiceUpload: Invoice, orderReference: string) {

        return this.httpClient.put(`${environment.apiUrl}/api/SendReceiveOrder/AddRemoveInvoice?orderReference=${orderReference}&isRemove=false`, invoiceUpload);
    }

    public removeInvoice(invoiceUpload: Invoice, orderReference: string) {

        return this.httpClient.put(`${environment.apiUrl}/api/SendReceiveOrder/AddRemoveInvoice?orderReference=${orderReference}&isRemove=true`, invoiceUpload);
    }

    public uploadDocument(document: Invoice)
    {
        return this.httpClient.post(`${environment.apiUrl}/api/customs/upload`, document) as Observable<any>;
    }
}
