import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input, OnChanges, AfterViewInit } from '@angular/core';
import { UploadFileService } from '../services/upload-file.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Invoice } from '../entities/Invoice';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadDocument } from '../entities/uploadDocument';
import { StaticContentService } from '../services/static-content.service';

@Component({
  selector: 'app-file-upload-missing-documents',
  templateUrl: './file-upload-missing-documents.component.html',
  styleUrls: ['./file-upload-missing-documents.component.scss']
})
export class FileUploadMissingDocumentsComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild("fileUpload", {})
  fileUpload: ElementRef;
  files: Array<UploadDocument> = new Array<UploadDocument>();

  @Input()
  clearAllFiles: boolean;

  @Input()
  uploadedDocumentsFormGroup: FormGroup;

  @Input()
  customsFormGroup: FormGroup;

  @Input()
  showRequiredError: boolean = true;

  @Input()
  existingOrderInvoices: Array<UploadDocument>;

  public filesRemove: Array<UploadDocument> = new Array<UploadDocument>();

  public filesAdd: Array<UploadDocument> = new Array<UploadDocument>();

  @Output()
  filesToRemove: EventEmitter<Array<UploadDocument>> = new EventEmitter();

  @Output()
  filesToAdd: EventEmitter<Array<UploadDocument>> = new EventEmitter();

  public orderReference: string;

  public showUploadLaterMesssage: boolean = false;

  ImageBaseData: string | ArrayBuffer = null;

  @Output()
  public successfullyUploaded: EventEmitter<boolean> = new EventEmitter();

  public loading: boolean = false;

  public newFile: UploadDocument;

  public newCompanyName: string;

  public newDocumentFormGroup: FormGroup;

  public psModuleContent: any;

  item1Link: any;
  observer: MutationObserver;

  

  constructor(private uploadService: UploadFileService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private staticContentService: StaticContentService,
    private router: Router,
    private elRef: ElementRef) { }

  ngOnInit() {

    this.orderReference = this.route.snapshot.paramMap.get('orderReferenceId');
    this.newDocumentFormGroup = this.formBuilder.group({
      newCompanyName: ['', Validators.required],
    })

    this.psModuleContent = this.staticContentService.getPsModuleContent('send-receive', 'send-receive/send-receive');
    if (!!this.psModuleContent)
    {
      this.item1Link = this.psModuleContent.Item1Link;
    }

    //   if (!!this.existingOrderInvoices && this.existingOrderInvoices.length > 0)
    //   {
    //     this.files = this.existingOrderInvoices;
    //     var i: number = 0;
    //     for (i; i < this.existingOrderInvoices.length; i++)
    //     {
    //       // this.files[i].fileName = this.existingOrderInvoices[i].filename;
    //       this.uploadedDocumentsFormGroup.addControl(`fileCompanyName${i}`, new FormControl('', Validators.required));
    //       this.uploadedDocumentsFormGroup.get(`fileCompanyName${i}`).setValue(this.existingOrderInvoices[i].filename);
    //     }
    //     this.uploadedFiles.emit(this.files);
    //     if (!!this.uploadedDocumentsFormGroup.get('uploadedFiles'))
    //     {
    //       this.uploadedDocumentsFormGroup.get('uploadedFiles').enable();
    //       this.uploadedDocumentsFormGroup.get('uploadedFiles').setValue(this.files);
    //     }
    //   }
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));
      });
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

  public openRouteInNewWindow(route: string){
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  ngOnChanges() {
    if (this.clearAllFiles) {
      this.files = new Array();
    }
  }

  public onClick() {
    const fileUpload = this.fileUpload.nativeElement;


    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        const currentFilesIndex = this.files.length;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var currentUploadedFilesIndex = this.files.length - 1;
          this.uploadedDocumentsFormGroup.addControl(`fileCompanyName${currentUploadedFilesIndex + 1}`, new FormControl('', Validators.required));

          this.newFile = {
            // applicationId: 1,
            fileData: (reader.result as string).split(',')[1],
            // orderReference: this.customsFormGroup.get('invoicePoNumber').value,
            // refCode: this.customsFormGroup.get('refCode').value,
            filename: file.name,
            companyName: file.companyName
          }
        };
      }
    };
    fileUpload.click();
  }

  public addFileToQueue() {
    if(!!!this.newFile)
    {
      return;
    }

    this.newFile.companyName = this.newCompanyName;

    this.files.push(this.newFile);

    //catch potential error
    // this.uploadService.addInvoice(newFile, this.orderReference).subscribe();
    this.filesAdd.push(this.newFile)
    this.filesToAdd.emit(this.filesAdd);

    if (!!this.uploadedDocumentsFormGroup.get('uploadedFiles')) {
      this.uploadedDocumentsFormGroup.get('uploadedFiles').enable();
      this.uploadedDocumentsFormGroup.get('uploadedFiles').setValue(this.files);
    }

    this.newCompanyName = null;
    this.newFile = null;
  }

  public deleteFile(invoice: UploadDocument) {
    //catch potential error
    // this.uploadService.removeInvoice(invoice, this.orderReference).subscribe();

    this.filesRemove.push(invoice);
    this.filesToRemove.emit(this.filesRemove);

    this.files = this.files.filter(x => x !== invoice);
  }

  public proceed() {
    if (!!this.files && this.files.length > 0)
    {
      this.loading = true;

      var invoice: Invoice = {
        applicationId: 1,
        documentList: this.files,
        orderReference: this.customsFormGroup.get('invoicePoNumber').value,
        refCode: this.customsFormGroup.get('refCode').value,
      }
  
      this.uploadService.uploadDocument(invoice).subscribe(x => {
        this.successfullyUploaded.emit(true);
        this.loading = false;
      }, (error) => {
        this.loading = false;
      });
    }
  }
}
