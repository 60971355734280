import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, AfterViewInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AddressService } from '../../../../shared/services/address-service';
import { Address } from '../../../../account/entities/address';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from '../../../../account/services/user.service';
import { User } from '../../../../account/entities/user';
import { Order } from '../../../entities/Order';

@Component({
  selector: 'app-send-and-receive-addresses',
  templateUrl: './send-and-receive-addresses.component.html',
  styleUrls: ['./send-and-receive-addresses.component.scss']
})
export class SendAndReceiveAddressesComponent implements OnInit, AfterViewInit {


  @Input()
  sendReceiveForm: FormGroup

  @Input()
  specificationForm: FormGroup

  public fromDepotAddressChecked = false;
  public fromAccountAddressChecked = false;
  public fromNewAddressChecked = false;


  public toAccountAddressChecked = false;
  public toDepotAddressChecked = false;
  public toNewAddressChecked = false;

  public fromAccountDisabled: boolean = false;
  public fromDepotDisabled: boolean = false;
  public toAccountDisabled: boolean = false;
  public toDepotDisabled: boolean = false;

  public addToFavourite: boolean = false;

  public addresses: Array<Address> = new Array<Address>();

  public fromFilteredAddresses: Observable<Array<Address>>;

  public toFilteredAddresses: Observable<Array<Address>>;

  fromAddressControl = new FormControl();

  toAddressControl = new FormControl();

  public fromAddressSelected: Address;

  public toAddressSelected: Address;

  public currentUser: User;

  public postcodes: any;

  public fromOption: any;

  public toOption: any;
  
  public showNoSelectionError: boolean;

  @Input()
  order: Order

  @Output()
  fromDepot: EventEmitter<boolean> = new EventEmitter();

  @Output()
  toDepot: EventEmitter<boolean> = new EventEmitter();

  @Output()
  fromAccountAddress: EventEmitter<boolean> = new EventEmitter();

  @Output()
  toAccountAddress: EventEmitter<boolean> = new EventEmitter();

  @Output()
  nextClicked: EventEmitter<boolean> = new EventEmitter();
  
  addressesFooter: any;
  
  @Input()
  psModule: any;
  observer: MutationObserver;

  constructor(private addressService: AddressService, 
              private userAccountService: UserService,
              private elRef: ElementRef) { }

  ngOnInit(): void {
    this.getAddresses();
    this.getPostcodes();

    this.userAccountService.getUserDetails().subscribe(x => {
      this.currentUser = x;
    })


    if (!!this.order) {
      if (!!this.order.fromFirstName && !this.order.isFromAccountAddress) {
        this.fromOption = "fromNewAddress";
        this.fromNewAddressChecked = true;
      }
      else if (this.order.isFromAccountAddress) {
        this.fromOption = "fromAccount";
        this.onSelectFromAccountAddress(null);
      }


      if (!!this.order.toFirstName && !this.order.isToAccountAddress) {
        this.toOption = "toNewAddress";
        this.toNewAddressChecked = true;
      }
      else if (this.order.isToAccountAddress) {
        this.onSelectToAccountAddress(null);
        // this.checkToAccountAddressOption = true;
        this.toOption = "toAccount";

      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['psModule']){
      this.addressesFooter = this.psModule.AddressesFooter;
    }
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));                               
      });   
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

  public notSelectedCountry(){
    if(this.sendReceiveForm.get("fromCountry").valid || this.sendReceiveForm.get("toCountry").valid)
    {
      return false;
    }
    return true;
  }

  public onSelectFromAccountAddress(event: any) {
    this.showNoSelectionError = false;
    this.userAccountService.getUserDetails().subscribe(x => {
      this.currentUser = x;

      this.sendReceiveForm.get("fromTitle").enable();
      this.sendReceiveForm.get("fromFirstName").enable();
      this.sendReceiveForm.get("fromLastName").enable();
      this.sendReceiveForm.get("fromAddressLine1").enable();
      this.sendReceiveForm.get("fromAddressLine2").enable();
      this.sendReceiveForm.get("fromTownCity").enable();
      this.sendReceiveForm.get("fromPostcode").enable();
      this.sendReceiveForm.get("fromCountry").enable();
      this.sendReceiveForm.get("fromContactNumber").enable();
    
      this.sendReceiveForm.get("logisticsSenderName").reset();
      this.sendReceiveForm.get("logisticsSenderName").disable();
  
      this.sendReceiveForm.get("fromTitle").setValue(this.currentUser.title)
      this.sendReceiveForm.get("fromFirstName").setValue(this.currentUser.firstName);
      this.sendReceiveForm.get("fromLastName").setValue(this.currentUser.lastName);
      this.sendReceiveForm.get("fromAddressLine1").setValue(this.currentUser.addressLine1);
      this.sendReceiveForm.get("fromAddressLine2").setValue(this.currentUser.addressLine2);
      this.sendReceiveForm.get("fromTownCity").setValue(this.currentUser.townCity);
      this.sendReceiveForm.get("fromPostcode").setValue(this.currentUser.postcode);
      this.sendReceiveForm.get("fromCountry").setValue(this.currentUser.country);
      this.sendReceiveForm.get("fromContactNumber").setValue(this.currentUser.mobileNo);
  
      this.fromAccountDisabled = false;
  
      if (event !== "swap") {
        this.fromDepotAddressChecked = false;
        this.fromNewAddressChecked = false;
      }
  
      this.fromAccountAddressChecked = true;
      this.fromOption = "fromAccount";
      this.toAccountDisabled = true;
      this.fromDepotDisabled = false;
      this.toDepotDisabled = false;
  
      this.fromDepot.emit(false);
      this.fromAccountAddress.emit(true);
    })
  }

  public onSelectFromDepot(event: any) {
    this.showNoSelectionError = false;
    if (event !== "swap") {
      this.fromNewAddressChecked = false;
      this.fromAccountAddressChecked = false;
    }
    this.fromDepotAddressChecked = true;
    // this.fromAccountAddressChecked = false;
    // this.fromNewAddressChecked = false;
    this.fromOption = "fromDepot";

    // this.sendReceiveForm.get("fromCountry").setValue(this,countr)

    this.toDepotDisabled = true;
    if (!this.toAccountAddressChecked) {
      this.fromAccountDisabled = false;

    }
    this.toAccountDisabled = false;
    // this.sendReceiveForm.get("fromCountry").setValue("DK");

    this.sendReceiveForm.get("fromTitle").disable();
    this.sendReceiveForm.get("fromFirstName").disable();
    this.sendReceiveForm.get("fromLastName").disable();
    this.sendReceiveForm.get("fromAddressLine1").disable();
    this.sendReceiveForm.get("fromAddressLine2").disable();
    this.sendReceiveForm.get("fromTownCity").disable();
    this.sendReceiveForm.get("fromPostcode").disable();
    this.sendReceiveForm.get("fromCountry").disable();

    this.sendReceiveForm.get("logisticsSenderName").enable();

    this.fromDepot.emit(true);
    this.fromAccountAddress.emit(false);
  }

  public onSelectFromNewAddress(event: any) {
    this.showNoSelectionError = false;
    if (event !== "swap") {
      this.fromAccountAddressChecked = false;
      this.fromDepotAddressChecked = false;
    }

    this.fromNewAddressChecked = true;

    this.toAccountDisabled = false;
    this.toDepotDisabled = false;

    if (this.toAccountAddressChecked) {
      this.fromAccountDisabled = true;
    }
    if (this.toDepotAddressChecked) {
      this.fromDepotDisabled = true;
    }

    this.fromOption = "fromNewAddress";

    this.sendReceiveForm.get("fromTitle").enable();
    this.sendReceiveForm.get("fromFirstName").enable();
    this.sendReceiveForm.get("fromLastName").enable();
    this.sendReceiveForm.get("fromAddressLine1").enable();
    this.sendReceiveForm.get("fromAddressLine2").enable();
    this.sendReceiveForm.get("fromTownCity").enable();
    this.sendReceiveForm.get("fromPostcode").enable();
    this.sendReceiveForm.get("fromCountry").enable();
    this.sendReceiveForm.get("fromOptionalCompany").enable();
    this.sendReceiveForm.get("fromContactNumber").enable();
    this.sendReceiveForm.get("fromSpecialInstructions").enable();

    this.sendReceiveForm.get("logisticsSenderName").reset();
    this.sendReceiveForm.get("logisticsSenderName").disable();

    this.sendReceiveForm.get("fromTitle").reset();
    this.sendReceiveForm.get("fromFirstName").reset();
    this.sendReceiveForm.get("fromLastName").reset();
    this.sendReceiveForm.get("fromAddressLine1").reset();
    this.sendReceiveForm.get("fromAddressLine2").reset();
    this.sendReceiveForm.get("fromTownCity").reset();
    this.sendReceiveForm.get("fromPostcode").reset();
    this.sendReceiveForm.get("fromCountry").reset();
    this.sendReceiveForm.get("fromOptionalCompany").reset();
    this.sendReceiveForm.get("fromContactNumber").reset();
    this.sendReceiveForm.get("fromSpecialInstructions").reset();


    this.fromDepot.emit(false);
    this.fromAccountAddress.emit(false);
  }

  public onSelectToAccountAddress(event: any) {
    this.showNoSelectionError = false;

    this.userAccountService.getUserDetails().subscribe(x => {
      this.currentUser = x;

      this.sendReceiveForm.get("toTitle").enable();
      this.sendReceiveForm.get("toFirstName").enable();
      this.sendReceiveForm.get("toLastName").enable();
      this.sendReceiveForm.get("toAddressLine1").enable();
      this.sendReceiveForm.get("toAddressLine2").enable();
      this.sendReceiveForm.get("toTownCity").enable();
      this.sendReceiveForm.get("toPostcode").enable();
      this.sendReceiveForm.get("toCountry").enable();
  
      this.sendReceiveForm.get("toTitle").setValue(this.currentUser.title)
      this.sendReceiveForm.get("toFirstName").setValue(this.currentUser.firstName);
      this.sendReceiveForm.get("toLastName").setValue(this.currentUser.lastName);
      this.sendReceiveForm.get("toAddressLine1").setValue(this.currentUser.addressLine1);
      this.sendReceiveForm.get("toAddressLine2").setValue(this.currentUser.addressLine2);
      this.sendReceiveForm.get("toTownCity").setValue(this.currentUser.townCity);
      this.sendReceiveForm.get("toPostcode").setValue(this.currentUser.postcode);
      this.sendReceiveForm.get("toCountry").setValue(this.currentUser.country);
      if (!!this.currentUser.mobileNo) {
        this.sendReceiveForm.get("toContactNumber").enable();
        this.sendReceiveForm.get("toContactNumber").setValue(this.currentUser.mobileNo);
      }
  
      if (event !== "swap") {
        this.toDepotAddressChecked = false;
        this.toNewAddressChecked = false;
      }
  
      this.toAccountDisabled = false;
      this.toAccountAddressChecked = true;
      this.toOption = "toAccount";
  
      this.fromAccountDisabled = true;
      this.fromDepotDisabled = false;
      if (!this.fromDepotAddressChecked) {
        this.toDepotDisabled = false;
      }
  
      this.toDepot.emit(false);
      this.toAccountAddress.emit(true);
    })
  }

  public onSelectToDepot(event: any) {
    this.showNoSelectionError = false;
    if (event !== "swap") {
      this.toAccountAddressChecked = false;
      this.toNewAddressChecked = false;
    }
    this.toDepotAddressChecked = true;

    this.fromDepotDisabled = true;
    if (!this.fromAccountAddressChecked) {
      this.toAccountDisabled = false;

    }
    this.fromAccountDisabled = false;
    this.toOption = "toDepot";

    this.sendReceiveForm.get("toTitle").disable();
    this.sendReceiveForm.get("toFirstName").disable();
    this.sendReceiveForm.get("toLastName").disable();
    this.sendReceiveForm.get("toAddressLine1").disable();
    this.sendReceiveForm.get("toAddressLine2").disable();
    this.sendReceiveForm.get("toTownCity").disable();
    this.sendReceiveForm.get("toPostcode").disable();
    this.sendReceiveForm.get("toCountry").disable();

    this.toDepot.emit(true);
    this.toAccountAddress.emit(false);
  }

  private _filter(value: string): Array<Address> {
    const filterValue = value.toLowerCase();

    return this.addresses.filter(option => `${option.firstName} ${option.lastName}, ${option.postcode}`.toLowerCase().includes(filterValue));
  }

  displayFn(address: Address): string {
    return address && `${address.firstName} ${address.lastName}, ${address.postcode}` ? `${address.firstName} ${address.lastName}, ${address.postcode}` : '';
  }

  public onSelectToNewAddress(event: any) {
    this.showNoSelectionError = false;
    if (event !== "swap") {
      this.toAccountAddressChecked = false;
      this.toDepotAddressChecked = false;
    }
    this.toNewAddressChecked = true;

    this.fromAccountDisabled = false;
    this.fromDepotDisabled = false;
    if (this.fromAccountAddressChecked) {
      this.toAccountDisabled = true;
    }
    if (this.fromDepotAddressChecked) {
      this.toDepotDisabled = true;
    }

    this.toOption = "toNewAddress";

    this.sendReceiveForm.get("toTitle").enable();
    this.sendReceiveForm.get("toFirstName").enable();
    this.sendReceiveForm.get("toLastName").enable();
    this.sendReceiveForm.get("toAddressLine1").enable();
    this.sendReceiveForm.get("toAddressLine2").enable();
    this.sendReceiveForm.get("toTownCity").enable();
    this.sendReceiveForm.get("toPostcode").enable();
    this.sendReceiveForm.get("toCountry").enable();
    this.sendReceiveForm.get("toOptionalCompany").enable();
    this.sendReceiveForm.get("toContactNumber").enable();
    this.sendReceiveForm.get("toSpecialInstructions").enable();

    this.sendReceiveForm.get("toTitle").reset();
    this.sendReceiveForm.get("toFirstName").reset();
    this.sendReceiveForm.get("toLastName").reset();
    this.sendReceiveForm.get("toAddressLine1").reset();
    this.sendReceiveForm.get("toAddressLine2").reset();
    this.sendReceiveForm.get("toTownCity").reset();
    this.sendReceiveForm.get("toPostcode").reset();
    this.sendReceiveForm.get("toCountry").reset();
    this.sendReceiveForm.get("toOptionalCompany").reset();
    this.sendReceiveForm.get("toContactNumber").reset();
    this.sendReceiveForm.get("toSpecialInstructions").reset();
    this.toDepot.emit(false);
    this.toAccountAddress.emit(false);
  }

  public swapFromTo() {
    
    this.swapAddressFormFields();

    if (this.fromDepotAddressChecked || this.toDepotAddressChecked) {
      this.fromDepotAddressChecked = this.toDepotAddressChecked;
      this.toDepotAddressChecked = !this.fromDepotAddressChecked;
      if (this.fromDepotAddressChecked) {
        this.toDepot.emit(false);
        this.onSelectFromDepot("swap");
      }
      if (this.toDepotAddressChecked) {
        this.fromDepot.emit(false);
        this.onSelectToDepot("swap");
      }
    }

    if (this.fromAccountAddressChecked || this.toAccountAddressChecked) {
      this.fromAccountAddressChecked = this.toAccountAddressChecked;
      this.toAccountAddressChecked = !this.fromAccountAddressChecked;
      if (this.fromAccountAddressChecked) {
        this.onSelectFromAccountAddress("swap");
      }
      if (this.toAccountAddressChecked) {
        this.onSelectToAccountAddress("swap");
      }
    }

    if (this.fromNewAddressChecked || this.toNewAddressChecked) {

      //If both are from new address - do nothing for now
      if (this.fromNewAddressChecked && this.toNewAddressChecked) {
        return;
      }

      if (this.fromNewAddressChecked)
      {
        this.toOption = "toNewAddress";
        this.sendReceiveForm.get("toTitle").enable();
        this.sendReceiveForm.get("toFirstName").enable();
        this.sendReceiveForm.get("toLastName").enable();
        this.sendReceiveForm.get("toAddressLine1").enable();
        this.sendReceiveForm.get("toAddressLine2").enable();
        this.sendReceiveForm.get("toTownCity").enable();
        this.sendReceiveForm.get("toPostcode").enable();
        this.sendReceiveForm.get("toCountry").enable();
      }
      if (!this.fromNewAddressChecked)
      {
        this.fromOption = "fromNewAddress";
        this.sendReceiveForm.get("fromTitle").enable();
        this.sendReceiveForm.get("fromFirstName").enable();
        this.sendReceiveForm.get("fromLastName").enable();
        this.sendReceiveForm.get("fromAddressLine1").enable();
        this.sendReceiveForm.get("fromAddressLine2").enable();
        this.sendReceiveForm.get("fromTownCity").enable();
        this.sendReceiveForm.get("fromPostcode").enable();
        this.sendReceiveForm.get("fromCountry").enable();
      }
      this.fromNewAddressChecked = this.toNewAddressChecked;
      this.toNewAddressChecked = !this.fromNewAddressChecked;
    }
  }

  public addressFieldClick() {
    this.addressService.getAll().subscribe((x) => {
      this.addresses = x;

      this.fromFilteredAddresses = this.fromAddressControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
        map(firstName => firstName ? this._filter(firstName) : this.addresses.slice()),
      )

    this.toFilteredAddresses = this.toAddressControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
        map(firstName => firstName ? this._filter(firstName) : this.addresses.slice())
      );
    });
  }

  public getAddresses() {
    this.addressService.getAll().subscribe((x) => {
      this.addresses = x

      this.fromFilteredAddresses = this.fromAddressControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
        map(firstName => firstName ? this._filter(firstName) : this.addresses.slice()),
      )

    this.toFilteredAddresses = this.toAddressControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
        map(firstName => firstName ? this._filter(firstName) : this.addresses.slice())
      );
    });

    this.fromAddressControl
  }

  public getPostcodes() {
    this.addressService.getPostcodes().subscribe((x) => {
      this.postcodes = x
    });
  }

  public setFromSelectedAddress(address) {
    this.fromAddressSelected = address;
  }

  public setToSelectedAddress(address) {
    this.toAddressSelected = address;
  }

  public setToAddressFields() {
    if (!!this.toAddressSelected) {
      this.sendReceiveForm.get("toTitle").setValue(this.toAddressSelected.title);
      this.sendReceiveForm.get("toFirstName").setValue(this.toAddressSelected.firstName);
      this.sendReceiveForm.get("toLastName").setValue(this.toAddressSelected.lastName);
      this.sendReceiveForm.get("toOptionalCompany").setValue(this.toAddressSelected.companyName);
      this.sendReceiveForm.get("toAddressLine1").setValue(this.toAddressSelected.addressLine1);
      this.sendReceiveForm.get("toAddressLine2").setValue(this.toAddressSelected.addressLine2);
      this.sendReceiveForm.get("toTownCity").setValue(this.toAddressSelected.townCity);
      this.sendReceiveForm.get("toPostcode").setValue(this.toAddressSelected.postcode);
      this.sendReceiveForm.get("toCountry").setValue(this.toAddressSelected.country);
      this.sendReceiveForm.get("toContactNumber").setValue(this.toAddressSelected.contactNumber);
      this.sendReceiveForm.get("toSpecialInstructions").setValue(this.toAddressSelected.specialInstructions);
      this.addToFavourite = this.toAddressSelected.isFavourite;
    }
  }

  public setFromAddressFields() {
    if (!!this.fromAddressSelected) {
      this.sendReceiveForm.get("fromTitle").setValue(this.fromAddressSelected.title);
      this.sendReceiveForm.get("fromFirstName").setValue(this.fromAddressSelected.firstName);
      this.sendReceiveForm.get("fromLastName").setValue(this.fromAddressSelected.lastName);
      this.sendReceiveForm.get("fromOptionalCompany").setValue(this.fromAddressSelected.companyName);
      this.sendReceiveForm.get("fromAddressLine1").setValue(this.fromAddressSelected.addressLine1);
      this.sendReceiveForm.get("fromAddressLine2").setValue(this.fromAddressSelected.addressLine2);
      this.sendReceiveForm.get("fromTownCity").setValue(this.fromAddressSelected.townCity);
      this.sendReceiveForm.get("fromPostcode").setValue(this.fromAddressSelected.postcode);
      this.sendReceiveForm.get("fromCountry").setValue(this.fromAddressSelected.country);
      this.sendReceiveForm.get("fromContactNumber").setValue(this.fromAddressSelected.contactNumber);
      this.sendReceiveForm.get("fromSpecialInstructions").setValue(this.fromAddressSelected.specialInstructions);
    }
  }

  public swapAddressFormFields() {

    const toTitleValueBeforeChange = this.sendReceiveForm.get("toTitle").value;
    const toFirstNameValueBeforeChange = this.sendReceiveForm.get("toFirstName").value;
    const toLastNameValueBeforeChange = this.sendReceiveForm.get("toLastName").value;
    const toOptionalCompanyBeforeChange = this.sendReceiveForm.get("toOptionalCompany").value;
    const toAddressLine1BeforeChange = this.sendReceiveForm.get("toAddressLine1").value;
    const toAddressLine2BeforeChange = this.sendReceiveForm.get("toAddressLine2").value;
    const toTownCityBeforeChange = this.sendReceiveForm.get("toTownCity").value;
    const toPostcodeBeforeChange = this.sendReceiveForm.get("toPostcode").value;
    const toCountryBeforeChange = this.sendReceiveForm.get("toCountry").value;
    const toContactNumberBeforeChange = this.sendReceiveForm.get("toContactNumber").value;

    this.sendReceiveForm.get("toTitle").setValue(this.sendReceiveForm.get("fromTitle").value);
    this.sendReceiveForm.get("toFirstName").setValue(this.sendReceiveForm.get("fromFirstName").value);
    this.sendReceiveForm.get("toLastName").setValue(this.sendReceiveForm.get("fromLastName").value);
    this.sendReceiveForm.get("toOptionalCompany").setValue(this.sendReceiveForm.get("fromOptionalCompany").value);
    this.sendReceiveForm.get("toAddressLine1").setValue(this.sendReceiveForm.get("fromAddressLine1").value);
    this.sendReceiveForm.get("toAddressLine2").setValue(this.sendReceiveForm.get("fromAddressLine2").value);
    this.sendReceiveForm.get("toTownCity").setValue(this.sendReceiveForm.get("fromTownCity").value);
    this.sendReceiveForm.get("toPostcode").setValue(this.sendReceiveForm.get("fromPostcode").value);
    this.sendReceiveForm.get("toCountry").setValue(this.sendReceiveForm.get("fromCountry").value, {emitEvent: true});
    this.sendReceiveForm.get("toContactNumber").setValue(this.sendReceiveForm.get("fromContactNumber").value);

    this.sendReceiveForm.get("fromTitle").setValue(toTitleValueBeforeChange);
    this.sendReceiveForm.get("fromFirstName").setValue(toFirstNameValueBeforeChange);
    this.sendReceiveForm.get("fromLastName").setValue(toLastNameValueBeforeChange);
    this.sendReceiveForm.get("fromOptionalCompany").setValue(toOptionalCompanyBeforeChange);
    this.sendReceiveForm.get("fromAddressLine1").setValue(toAddressLine1BeforeChange);
    this.sendReceiveForm.get("fromAddressLine2").setValue(toAddressLine2BeforeChange);
    this.sendReceiveForm.get("fromTownCity").setValue(toTownCityBeforeChange);
    this.sendReceiveForm.get("fromPostcode").setValue(toPostcodeBeforeChange);
    this.sendReceiveForm.get("fromCountry").setValue(toCountryBeforeChange, {emitEvent: true});
    this.sendReceiveForm.get("fromContactNumber").setValue(toContactNumberBeforeChange);

    this.sendReceiveForm.markAsUntouched();
  }

  public markRequiredFieldsAsTouchedAndProceed() {
    this.sendReceiveForm.markAllAsTouched();
    
    var hasSender = this.fromAccountAddressChecked || this.fromDepotAddressChecked || this.fromNewAddressChecked;
    var hasRecipient = this.toAccountAddressChecked || this.toDepotAddressChecked || this.toNewAddressChecked;

    this.showNoSelectionError = !hasSender || !hasRecipient;
    
    if (this.sendReceiveForm.invalid) {
      return;
    }
    this.nextClicked.emit(true);
  }

  public clearAll() {
    this.fromAccountAddressChecked = false;
    this.fromDepotAddressChecked = false;
    this.fromNewAddressChecked = false;
    this.toAccountAddressChecked = false;
    this.toDepotAddressChecked = false;
    this.toNewAddressChecked = false;
    this.toOption = undefined;
    this.fromOption = undefined;

    this.fromDepot.emit(false);
    this.toDepot.emit(false);

    this.sendReceiveForm.get("fromTitle").enable();
    this.sendReceiveForm.get("fromFirstName").enable();
    this.sendReceiveForm.get("fromLastName").enable();
    this.sendReceiveForm.get("fromAddressLine1").enable();
    this.sendReceiveForm.get("fromAddressLine2").enable();
    this.sendReceiveForm.get("fromTownCity").enable();
    this.sendReceiveForm.get("fromPostcode").enable();
    this.sendReceiveForm.get("fromCountry").enable();
    this.sendReceiveForm.get("toTitle").enable();
    this.sendReceiveForm.get("toFirstName").enable();
    this.sendReceiveForm.get("toLastName").enable();
    this.sendReceiveForm.get("toAddressLine1").enable();
    this.sendReceiveForm.get("toAddressLine2").enable();
    this.sendReceiveForm.get("toTownCity").enable();
    this.sendReceiveForm.get("toPostcode").enable();
    this.sendReceiveForm.get("toCountry").enable();

    this.sendReceiveForm.reset();
  }
}