import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollViewHelper {

  constructor() { }

  scrollIntoViewAndCenter(id: string) {
    var checkExist = setInterval(function () {
      let temp = document.getElementById(id)
      if (!!temp) {
        temp.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
        clearInterval(checkExist);
      }
    }, 100);
  }
}
