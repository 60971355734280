<div class="container">
    <div *ngIf="loading">
        <div class="samleikin-login-container">
            <div class="samleikin-header">
                <h1>{{ 'samleikin.samleikin_logging_in' | translate }}</h1>
            </div>
            <mat-spinner style="margin: 0 auto" diameter="60"></mat-spinner>
        </div>
    </div>

    <div *ngIf="displayLoginFailed">
        <div class="container failed-samleikin-login-container">
            <div class="login-header-container">
                <h2 class="primary">
                    {{ 'samleikin.samleikin_login_unsuccesful' | translate }}
                </h2>
            </div>
            <div>
                {{ 'samleikin.samleikin_login_unsucessful_body' | translate }}
            </div>
        </div>
    </div>
</div>