import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { RoleService } from '../../account/services/role.service';

@Injectable({
  providedIn: 'root'
})
export class StaticContentHelper {

  //TODO needs refactoring
  constructor(private roleService: RoleService, private translateService: TranslateService, private router: Router) { }

}
