import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoppingServicesRoutingModule } from './shopping-services-routing.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { OffersProductsComponent } from './offers-products/offers-products.component';
import { BrochureDialogComponent } from './brochure-dialog/brochure-dialog.component';
import { NewShoppingListDialogComponent } from './new-shopping-list-dialog/new-shopping-list-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { MatIconModule } from '@angular/material/icon';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { GeneratedCodeDialogComponent } from './generated-code-dialog/generated-code-dialog/generated-code-dialog.component';
import { ViewShoppingListComponent } from './view-shopping-list/view-shopping-list.component';
import { ViewAllShoppingListsComponent } from './view-all-shopping-lists/view-all-shopping-lists.component';
import { ViewSharedShoppingListComponent } from './view-shared-shopping-list/view-shared-shopping-list.component';
import { ManualItemDialogComponent } from './manual-item-dialog/manual-item-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreateShoppingListsDialogComponent } from './create-shopping-lists-dialog/create-shopping-lists-dialog.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    OffersProductsComponent,
    BrochureDialogComponent,
    NewShoppingListDialogComponent,
    GeneratedCodeDialogComponent,
    ViewShoppingListComponent,
    ViewAllShoppingListsComponent,
    ViewSharedShoppingListComponent,
    ManualItemDialogComponent,
    CreateShoppingListsDialogComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    PdfJsViewerModule,
    ShoppingServicesRoutingModule,
    ClipboardModule,
    TranslateModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatCardModule,
    MatIconModule,
    MatProgressBarModule,
    SharedModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule
  ]
})
export class ShoppingServicesModule { }
