import { TranslationString } from '../../shared/price-matrix/entities/TranslationString';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import * as _ from "lodash";
import { Country } from '../../account/entities/Country';
import { stringify } from 'querystring';
import { isNumber } from 'lodash';
import { AbstractControl, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TranslationHelper {

  constructor(private translateService: TranslateService) {

  }

  public getNumber(object) : number {
    if(isNumber(object))
      return object;

    if(!!object){
      switch (this.translateService.currentLang){
        case "en":
          var convertedObject = object.toString().replace(/\,/g, '');
          return Number(convertedObject);
        case "fo":
          var convertedObject = object.toString().replace(/\./g, '').replace(',', '.');
          return Number(convertedObject);
        case "da":
          var convertedObject = object.toString().replace(/\./g, '').replace(',', '.');
          return Number(convertedObject);
        case "gl":
          var convertedObject = object.toString().replace(/\./g, '').replace(',', '.');
          return Number(convertedObject);
      }
    }
  }

  public convertNumberField(field: AbstractControl){
    if(!field?.value)
      return;
    
    var numberValue = field.value;
    //currentLang is the new (destination) language
    switch (this.translateService.currentLang){
      case "en":
        field.setValue(numberValue.toString().replace(/,/g, '.'), {emitEvent:false});
        break;
      case "fo":
      case "da":
      case "gl":
        field.setValue(numberValue.toString().replace(/\./g, ','), {emitEvent:false});
        break;
    }
  }

  public formatNumberField(field: AbstractControl){
    if(!field?.value)
      return;
    // switch (this.translateService.currentLang){
    //   case "en":
    //     if(field.value.toString().endsWith(".") || field.value.toString().endsWith("0"))
    //       return;
    //     break;
    //   case "fo":
    //   case "da":
    //   case "gl":
    //     if(field.value.toString().endsWith(",") || field.value.toString().endsWith("0"))
    //       return;
    //     break;
    // }

    field.setValue(this.getNumberString(field.value), {emitEvent:false})
  }

  public prependPlus(field: AbstractControl){
    if(!field?.value)
      return;
    if(field.value.toString().startsWith('+'))
      return;
    field.setValue(`+${ field.value }`)
  }

  public getNumString2DP(num) : string {
    return this.getNum(num, true);
  }

  public getNumberString(num) : string {
    return this.getNum(num, false);
  }

  public getNum(num, decimalPlaces: boolean) {
    if(!!num || num == 0){

      var strNum = num.toString();
      switch (this.translateService.currentLang){
        case "en":
          if(strNum.endsWith(".") || strNum.endsWith("0"))
            return strNum;
          break;
        case "fo":
        case "da":
        case "gl":
          if(strNum.endsWith(",") || strNum.endsWith("0"))
            return strNum;
          break;
      }

      if(!isNumber(num))
      {
        var convertedNum = this.getNumber(num)
        if(isNaN(convertedNum))
          return num;
        num = convertedNum;
      }

      if (decimalPlaces)
      {
        num = num.toFixed(2);
      }

      switch (this.translateService.currentLang){
        case "en":
          return num.toString();
        case "fo":
        case "da":
        case "gl":
          return num.toString().replace(/,/g, '').replace(/\./g, ',');
      }
    }
  }

  public getTranslation(object: TranslationString): string {
    if (!!object) {
      switch (this.translateService.currentLang) {
        case "en":
          return `${object.EN}`;
        case "fo":
          return `${object.FO}`;
        case "da":
          return `${object.DA}`;
        case "gl":
          return `${object.GL}`;
      }
    }
    return null;
  }

  public getNestedTranslation(stringToBeTranslated: TranslationString, object: any)
  {
    return this.getNestedString(this.getTranslation(stringToBeTranslated), object);
  }

  getNestedString(myString: string, object: any): string {

    let xmlPayloadTemplate = myString;

    let variableMap = { object: object };

    // use custom delimiter ${ }
    _.templateSettings.interpolate = /\${([\s\S]+?)}/g;

    let compiled = _.template( xmlPayloadTemplate );
    let xmlPayloadCompiled = compiled( variableMap );

    return xmlPayloadCompiled;
  }

  public getCountryName(country: Country)
  {
      switch (this.translateService.currentLang) {
        case "en":
          return country.countryNameEN;
        case "fo":
          return country.countryNameFO;
      }
  }
}