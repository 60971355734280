<div class="container">
    <div *ngFor="let postageItem of postageItems; let i = index" class="postage-item-container">
        <button *ngIf="postageItems.length > 1" class="delete-button" (click)="deleteItem(postageItem)"><img
                class="search-icon" src="/assets/icons/arrows/close@3x.png" />
        </button>
        <h3 class="item-header">{{'buy_postage.secondary_page.item' | translate}} {{i + 1}}</h3>
        <div class="row info-row">
            <div class="col-lg-2 col-md-2">
                <h5>{{'buy_postage.secondary_page.to' | translate}}</h5>
            </div>
            <div class="col-lg-9 col-md-9">
                {{postageItem.fromTo}}
            </div>
        </div>
        <div class="row info-row">
            <div class="col-lg-2 col-md-2">
                <h5>{{'buy_postage.secondary_page.weight' | translate}}</h5>
            </div>
            <div class="col-lg-9 col-md-9">
                {{postageItem.weight}}
            </div>
        </div>
        <div class="row info-row">
            <div class="col-lg-2 col-md-2">
                <h5>{{'buy_postage.secondary_page.size' | translate}}</h5>
            </div>
            <div class="col-lg-9 col-md-9">
                <strong>{{postageItem.sizeName}}</strong>: {{postageItem.sizeDescription}}
            </div>
        </div>
        <div class="row info-row">
            <div class="col-lg-2 col-md-2">
                <h5>{{'buy_postage.secondary_page.price' | translate}}</h5>
            </div>
            <div class="col-lg-9 col-md-9">
                {{postageItem.price | currency: 'DKK':''}} DKK
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-2 align-center">
                <h5>{{'buy_postage.secondary_page.your_ref' | translate}}</h5>
            </div>
            <div class="col-lg-4">
                <mat-form-field class="full-width" floatLabel="never" appearance="outline">
                    <input matInput placeholder="{{'buy_postage.secondary_page.your_ref_placeholder' | translate}}" id="reference{{i}}" name="reference{{i}}"
                        [(ngModel)]="postageItem.reference" required>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="price-container row">
        <div class="col-lg-2 col-md-2 align-center">
            <h5>{{'buy_postage.secondary_page.quantity' | translate}}</h5>
        </div>
        <div class="col-lg-3 col-md-auto">
            <div style="display: flex; padding-left: 0px; padding-right: 0px;">
                <button class="primary-button increment-button" (click)="decreaseItemCount()">-</button>
                <mat-form-field class="no-padding increment-input" [formGroup]="buyPostageForm" floatLabel="never" appearance="outline">
                    <input style="font-size: 16px !important" matInput id="itemCount" name="itemCount" formControlName="itemCount" (change)="itemCountChange()"  required>
                </mat-form-field>
                <button class="primary-button increment-button" (click)="incrementItemCount()">+</button>
            </div>
        </div>
        <div class="col" style="justify-content: flex-end; display: flex;">
            <h1 class="header-grey">{{'buy_postage.secondary_page.total' | translate}} {{totalPrice | currency: 'DKK':''}} DKK</h1>
        </div>
    </div>
    <div *ngIf="!domestic" [formGroup]="buyPostageForm" class="check-box-container">
        <mat-checkbox formControlName="agreeToTermsConditions">
            <div>{{'buy_postage.secondary_page.agree_to_terms_conditions' | translate}}</div>
        </mat-checkbox>
    </div>
    <div class="proceed-container">
        <div class="row">
            <div class="col-lg-3 col-3 hide-mobile">
                <button class="tertiary-button back-button" (click)="goToPreviousPage()">
                    <div class="full-width">
                        <img class="back-icon" src="/assets/icons/arrows/back_button_icon@3x.png" />
                        <span>{{'buy_postage.secondary_page.back' | translate}}</span>
                    </div>
                </button>
            </div>
            <div class="col-lg-3 offset-lg-6 col-sm-3 offset-sm-6 col-12 proceed-button-div" style="display: flex;">
                <button class="primary-button" [disabled]="!buyPostageForm.valid || loadingPayment" (click)="proceedToPayment()">{{'buy_postage.secondary_page.proceed' | translate}}</button>
            </div>
            <div class="col-12 show-mobile" style="padding-top: 20px;">
                <button class="tertiary-button back-button" (click)="goToPreviousPage()">
                    <div class="full-width">
                        <img class="back-icon" src="/assets/icons/arrows/back_button_icon@3x.png" />
                        <span>{{'buy_postage.secondary_page.back' | translate}}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>