import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../../../../account/services/user.service';
import { User } from '../../../../../account/entities/user';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-from-account-address',
  templateUrl: './from-account-address.component.html',
  styleUrls: ['./from-account-address.component.scss']
})
export class FromAccountAddressComponent implements OnInit {

  @Input()
  toFromAddressform: FormGroup

  @Input()
  public currentUser: User;

  public countryName: string;

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    this.getCountryName();
  }

  public getCountryName() {
    switch (this.translateService.currentLang) {
      case "en":
        this.countryName = `${this.currentUser.country.countryNameEN}`;
        break;
      case "fo":
        this.countryName = `${this.currentUser.country.countryNameFO}`;
        break;
    }
  }
}
