<div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
<div class="container">

   <div class="filter-container">
      <div class="row align-center filter-row">
         <div class="col-lg-auto col-md-6 col-6 filter-radio-container">
            <label class="align-center filter-radio-button">
               <span class="radio-button-spacing">
                  <input class="vertical-align-radio-button" type="radio" id="all-time-radio-button" name="all-time" (change)="checkAllTime()"
                     checked>
               </span>
               <h4 class="radio-text">{{'waybill_list.all_time' | translate}}</h4>
            </label>
         </div>
         <div class="col-lg-auto col-md-6 col-6 filter-radio-container">
            <label class="align-center filter-radio-button">
               <span class="radio-button-spacing">
                  <input class="vertical-align-radio-button" type="radio" id="specific-date-radio-button" name="all-time"
                     (change)="filterBySpecificDate = true">
               </span>
               <h4 class="radio-text">{{'waybill_list.specific_dates' | translate}}</h4>
            </label>
         </div>
         <div class="col-lg-auto col-md-4 col-5 align-center form-field-date-group from-date-group">
            <h4 class="from-label">{{'waybill_list.from' | translate}}</h4>
            <mat-form-field class="fill-container form-field-date-container" floatLabel="never" appearance="outline"
               [formGroup]="filterFormGroup">
               <input matInput placeholder="00/00/0000" matInput readonly [matDatepicker]="picker1" id="fromDate"
                  name="fromDate" formControlName="fromDate">
               <mat-datepicker #picker1></mat-datepicker>
               <img class="calendar-icon" src="/assets/icons/calendar@3x.png" (click)="picker1.open(); selectSpecificDate()" />
            </mat-form-field>
         </div>
         <div class="col-lg-auto col-md-4 col-5 align-center form-field-date-group to-date-group">
            <h4 class="to-label">{{'waybill_list.to' | translate}}:</h4>
            <mat-form-field class="fill-container form-field-date-container" floatLabel="never" appearance="outline"
               [formGroup]="filterFormGroup">
               <input matInput placeholder="00/00/0000" matInput readonly [matDatepicker]="picker2" id="toDate"
                  name="toDate" formControlName="toDate">
               <mat-datepicker #picker2></mat-datepicker>
               <img class="calendar-icon" src="/assets/icons/calendar@3x.png" (click)="picker2.open(); selectSpecificDate()" />
            </mat-form-field>
         </div>
         <div class="show-mobile col-2" style="padding-top: 40px;">
            <div class="address-menu-item">
               <button class="primary-button search-button" (click)="searchByFilter()">
                  <img class="search-icon" src="/assets/icons/address_book/search_white@3x.png" />
               </button>
            </div>
         </div>
         <div class="col-lg-auto col-md-4 col-12 align-center search-field-rows">
            <div class="hide-mobile address-menu-item">
               <button class="primary-button search-button" (click)="searchByFilter()">
                  <img class="search-icon" src="/assets/icons/address_book/search_white@3x.png" />
               </button>
            </div>
            <button class="secondary-button view-all-button" (click)="clearSearchFilters()">
               {{'waybill_list.view_all' | translate}}
            </button>
         </div>
      </div>
   </div>


   <div class="results-container">
      <div class="row table-header-row">
         <div class="col align-center">
            <h5>{{'waybill_list.shipmentDate' | translate}}</h5>
         </div>
         <div class="col no-padding align-center">
            <h5>{{'waybill_list.trackingNumber' | translate}}</h5>
         </div>
         <div class="col no-padding hide-mobile align-center">
            <h5>{{'waybill_list.name' | translate}}</h5>
         </div>
         <div class="col no-padding hide-tablet align-center">
            <h5>{{'waybill_list.serviceType' | translate}}</h5>
         </div>
         <div class="col no-padding align-center" style="padding-right: 0px;">
            <h5>{{'waybill_list.manifestNumber' | translate}}</h5>
         </div>
         <div class="col align-center no-padding">
            <h5>{{'waybill_list.shipmentType' | translate}}</h5>
         </div>
         <div class="col hide-mobile no-padding align-center">
            <h5>{{'waybill_list.importExportType' | translate}}</h5>
         </div>
      </div>
      <div *ngIf="!loading">
         <div *ngFor="let waybill of waybills" class="row order-row" (click)="getWaybillDetail(waybill)">
            <div class="col align-center" style="margin: 20px 0px 20px 0px;">
               {{waybill.shipmentDate | date:'dd/MM/yyyy'}}
            </div>
            <div class="col no-padding align-center">
               <a [routerLink]="trackTraceUrl(waybill.trackingNo)" target="_blank" (click)="handleEvent($event)">{{waybill.trackingNo}}</a>
            </div>
            <div class="col no-padding hide-mobile align-center">
               {{waybill.name}}
            </div>
            <div class="col no-padding hide-tablet align-center">
               {{getServiceType(waybill.serviceType) | translate}}
            </div>
            <div class="col no-padding align-center">
               {{waybill.manifestNo}}
            </div>
            <div class="col no-padding align-center">
               <img class="type-icon" [src]="getIcon(waybill.shipmentType)"/>
            </div>
            <div class="col no-padding hide-mobile align-center">
               {{getImportExportType(waybill.importExportServiceTypeCombine)}}
            </div>
            <!-- <div *ngIf="waybill.isPaid" class="col no-padding align-center">
               <button class="download-button" (click)="downloadReceipt(waybill)"><img class="download-icon" src="/assets/icons/orders/DownloadReceipt.png" /></button>
            </div> -->
         </div>
      </div>
      <!-- <div *ngIf="!loading && showViewMoreButton" class="view-more-container">
         <button class="primary-button view-more-button" (click)="viewMore()">{{'all_orders_page.view_more' | translate}}</button>
      </div> -->
      <div *ngIf="loading" class="spinner-container">
         <mat-spinner class="loading-spinner" diameter="60"></mat-spinner>
      </div>
   </div>
</div>
<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>