<div class="row option-container name-container">
    <div class="col-lg-12 col-md-12">
        <div *ngIf="!!currentUser.title" class="row field-row">
            <div class="col-lg-4 col-md-4 col-height">
                <h4 class="primary">{{'send_receive.send_receive.step_one.account_address.title' | translate}}</h4>
            </div>
            <div class="col-lg-8 col-md-8 col-height" [formGroup]="toFromAddressform">
                <input class="readonly-input" id="toTitle" name="toTitle" formControlName="toTitle" readonly
                value="currentUser.title">
            </div>
        </div>

        <div *ngIf="!!currentUser.firstName" class="row field-row">
            <div class="col-lg-4 col-md-4 col-height">
                <h4 class="primary">{{'send_receive.send_receive.step_one.account_address.first_name' | translate}}</h4>
            </div>
            <div class="col-lg-8 col-md-8 col-height" [formGroup]="toFromAddressform">
                <input class="readonly-input" id="toFirstName" name="toFirstName" formControlName="toFirstName"
                    readonly [value]="currentUser.firstName">
            </div>
        </div>

        <div *ngIf="!!currentUser.lastName" class="row field-row">
            <div class="col-lg-4 col-md-4 col-height">
                <h4 class="primary">{{'send_receive.send_receive.step_one.account_address.last_name' | translate}}</h4>
            </div>
            <div class="col-lg-8 col-md-8 col-height" [formGroup]="toFromAddressform">
                <input class="readonly-input" id="toLastName" name="toLastName" formControlName="toLastName"
                    readonly value="currentUser.lastName">
            </div>
        </div>
    </div>
</div>

<div class="row option-container">
    <div class="col-lg-12">
        <div *ngIf="!!currentUser.addressLine1" class="row field-row">
            <div class="col-lg-4 col-md-4 col-height">
                <h4 class="primary">{{'send_receive.send_receive.step_one.account_address.address_line_1' | translate}}</h4>
            </div>
            <div class="col-lg-8 col-md-8 col-height" [formGroup]="toFromAddressform">
                <input class="readonly-input" id="toAddressLine1" name="toAddressLine1"
                    formControlName="toAddressLine1" readonly value="currentUser.addressLine1">
            </div>
        </div>

        <!-- <div class="row field-row">
            <div class="col-lg-4 col-md-4 col-height">
                <h4 class="primary">{{'send_receive.send_receive.step_one.account_address.address_line_2' | translate}}</h4>
            </div>
            <div class="col-lg-8 col-md-8 col-height" [formGroup]="toFromAddressform">
                <input class="readonly-input" id="toAddressLine2" name="toAddressLine2"
                    formControlName="toAddressLine2" readonly value="currentUser.addressLine2">
            </div>
        </div> -->

        <div *ngIf="!!currentUser.townCity" class="row field-row">
            <div class="col-lg-4 col-md-4 col-height">
                <h4 class="primary">{{'send_receive.send_receive.step_one.account_address.town_city' | translate}}</h4>
            </div>
            <div class="col-lg-8 col-md-8 col-height" [formGroup]="toFromAddressform">
                <input class="readonly-input" id="toTownCity" name="toTownCity" formControlName="toTownCity"
                    readonly value="currentUser.townCity">
            </div>
        </div>

        <div *ngIf="!!currentUser.postcode" class="row field-row">
            <div class="col-lg-4 col-md-4 col-height">
                <h4 class="primary">{{'send_receive.send_receive.step_one.account_address.postcode' | translate}}</h4>
            </div>
            <div class="col-lg-8 col-md-8 col-height" [formGroup]="toFromAddressform">
                <input class="readonly-input" id="toPostcode" name="toPostcode" formControlName="toPostcode"
                    readonly value="currentUser.postcode">
            </div>
        </div>

        <div *ngIf="!!countryName" class="row field-row">
            <div class="col-lg-4 col-md-4 col-height">
                <h4 class="primary">{{'send_receive.send_receive.step_one.account_address.country' | translate}}</h4>
            </div>
            <div class="col-lg-8 col-md-8 col-height" [formGroup]="toFromAddressform">
                <input class="readonly-input" id="toCountry" name="toCountry" readonly
                    [value]="countryName">
            </div>
        </div>

        <div *ngIf="!!currentUser.mobileNo" class="row field-row">
            <div class="col-lg-4 col-md-4 col-height">
                <h4 class="primary">{{'send_receive.send_receive.step_one.account_address.contact_number' | translate}}</h4>
            </div>
            <div class="col-lg-8 col-md-8 col-height" [formGroup]="toFromAddressform">
                    <input class="readonly-input" id="toContactNumber" name="toContactNumber"
                     readonly
                    [value]="currentUser.mobileNo">
            </div>
        </div>

    </div>
</div>