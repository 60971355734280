<div class="row">
    <div class="col-6">
        <div>
            <label>
                <span style="padding-right: 5px;">
                    <input type="radio" name="loginType"
                    [value]="false"
                    [(ngModel)]="isGuest">
                </span>
                <span class="checkmark"></span>
                <h3 style="display: inline-block;">{{'buy_postage.existing_users_login' | translate}}</h3>
            </label>
        </div>
        

    </div>
    <div class="col-6">
        <div>
            <label>
                <span style="padding-right: 5px;">
                    <input type="radio" name="loginType"
                    [value]="true"
                    [(ngModel)]="isGuest">
                </span>
                <span class="checkmark"></span>
                <h3>{{'buy_postage.continue_as_guest' | translate }}</h3>
            </label>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-6 col-12">
        <div *ngIf="isGuest === false">
            <div class="description">
                <div>{{'buy_postage.existing_users_description' | translate}}</div>
            </div>
            <div class="row noPadding noMargin">
                <div class="col-4">
                    <button [ngClass]="role == 'private' ? 'primary-button' : 'tertiary-button'" (click)="role = 'private'">{{'buy_postage.personal_login' | translate}}</button>
                </div>
                <div class="col-4">
                    <button [ngClass]="role == 'private' ? 'tertiary-button' : 'primary-button'" (click)="role = 'business'">{{'buy_postage.business_login' | translate}}</button>
                </div>
            </div>
            <div class="login-container">
                <mat-form-field class="email-input full-width align-form-field" [formGroup]="passwordForm"
                        floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{ 'header.enter_email' | translate }}" id="email" name="email"
                            formControlName="email" required>
                        <mat-error *ngIf="hasErrors('email')">
                            <app-field-error-message errorMessage="{{getError('email')}}"></app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width align-form-field" [formGroup]="passwordForm" floatLabel="never"
                        appearance="outline">
                        <input type="password" matInput placeholder="{{ 'header.enter_password' | translate }}"
                            (keyup.enter)="login()" id="password" name="password" formControlName="password" required>
                        <mat-error *ngIf="hasErrors('password')">
                            <app-field-error-message errorMessage="{{getError('password')}}"></app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                    <div class="row button-row">
                        <div class="col-7 button-row">
                            <a routerLink="" (click)="navigateToForgottenPassword()" class="forgotten-password">{{
                                'header.forgotten_password' | translate }}</a>
                        </div>
                        <div class="col-5 button-row">
                            <button class="login-button primary-button full-width" type="submit" (click)="login()">
                                {{ 'header.login' | translate }}
                            </button>
                        </div>
                    </div>
            </div>
        </div>
        <div *ngIf="!(isGuest === false || isGuest === true)">
            <div class="description">
                <div>{{'buy_postage.existing_users_instruction' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-12">
        <div *ngIf="isGuest === true">
            <div class="description">
                <div>{{'buy_postage.guest_description' | translate}}</div>
            </div>
            <mat-form-field class="no-error-wrap float-never weight-input" floatLabel="never"
                appearance="outline" [formGroup]="buyPostageForm">
                <input matInput placeholder="{{'buy_postage.email_placeholder' | translate}}" id="email" name="email"
                    formControlName="email" required>
                <mat-error *ngIf="hasErrors('email')">
                    <app-field-error-message errorMessage="{{getError('email')}}">
                    </app-field-error-message>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
