import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { StaticContentHelper } from '../../helpers/static-content-helper';
import { StaticContentService } from '../../services/static-content.service';
import { StaticContent } from '../../static-content/entities/staticContent';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {

  // public showOnlineServicePanel: boolean = false;

  // public showPostaServices: boolean = false;

  // public showHelpInfo: boolean = false;

  // public showAboutPosta: boolean = false;

  public staticContent: StaticContent;

  public pageHtml: string;

  public interval: any;

  public role: string;
  observer: any;

  constructor(private staticContentService: StaticContentService, private translateService: TranslateService, private router: Router, private elRef: ElementRef) { }

  ngOnInit(): void {

    this.interval = setInterval(() => {
      var staticContentString = this.staticContentService.staticContent;
      this.staticContent = staticContentString;
      if (!!this.staticContent) {
        
        var axdFiles = this.staticContent.GlobalCss.split(";");
        axdFiles.forEach(x => {
          this.staticContentService.loadjscssfile(x, "css");
        });
        var jsAxdFiles = this.staticContent.GlobalJs.split(";");
        jsAxdFiles.forEach(x => {
          this.staticContentService.loadjscssfile(x, "js");
        });
        this.setFooterHtml();

        this.translateService.onLangChange.subscribe(x => {
          var axdFiles = this.staticContent.GlobalCss.split(";");
          axdFiles.forEach(x => {
            this.staticContentService.loadjscssfile(x, "css");
          });
          var jsAxdFiles = this.staticContent.GlobalJs.split(";");
          jsAxdFiles.forEach(x => {
            this.staticContentService.loadjscssfile(x, "js");
          });
          this.setFooterHtml();

        })
        clearInterval(this.interval);
      }
    }, 500);

    //This whole bit needs refactoring along with the header into one central role state control.
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((val) => {
      var splitPaths = this.router.url.split('/');
      if (!!splitPaths && splitPaths.length > 1) {
        //default to private if the path isn't known
        if (splitPaths[2] == "private" || splitPaths[2] == "business") {
          this.role = splitPaths[2];
          if (!!this.staticContent)
          {
            this.setFooterHtml();
            var jsAxdFiles = this.staticContent.GlobalJs.split(";");
            jsAxdFiles.forEach(x => {
              this.staticContentService.loadjscssfile(x, "js");
            });
          }
        }
        else {
          this.role = "private";
          if (!!this.staticContent)
          {
            this.setFooterHtml();
            var jsAxdFiles = this.staticContent.GlobalJs.split(";");
            jsAxdFiles.forEach(x => {
              this.staticContentService.loadjscssfile(x, "js");
            });
                    }
        }
      }
      else {
        this.role = "private";
        if (!!this.staticContent)
        {
          this.setFooterHtml();
          var jsAxdFiles = this.staticContent.GlobalJs.split(";");
          jsAxdFiles.forEach(x => {
            this.staticContentService.loadjscssfile(x, "js");
          });
        }
      }
      
    });
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));
      });
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

  public decodeHtml(html: string) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    // console.log(txt.value);
    return txt.value;
  }

  public setFooterHtml() {
    if (this.translateService.currentLang == "en") {
      if (!!this.staticContent && !!this.staticContent.Sections) {

      }
      var languageSection = this.staticContent.Sections.find(x => x.Url == "/en/");
      if (!!languageSection && !!languageSection.SubSections) {

        if (!!this.role) {
          if (this.role == "private") {
            var subSection = languageSection.SubSections.find(x => x.Url == "/en/private/");
            if (!!subSection && subSection.FooterHtml) {
              this.pageHtml = this.decodeHtml(subSection.FooterHtml);
            }
          }
          else if (this.role == "business") {
            var subSection = languageSection.SubSections.find(x => x.Url == "/en/business/");
            if (!!subSection && subSection.FooterHtml) {
              this.pageHtml = this.decodeHtml(subSection.FooterHtml);
            }
          }
          else {
            var subSection = languageSection.SubSections.find(x => x.Url == "/en/private/");
            if (!!subSection && subSection.FooterHtml) {
              this.pageHtml = this.decodeHtml(subSection.FooterHtml);
            }
          }
        }

      }
    }
    else if (this.translateService.currentLang == "fo") {
      var languageSection = this.staticContent.Sections.find(x => x.Url == "/fo/");
      if (!!languageSection && !!languageSection.SubSections) {
        if (!!this.role) {
          if (this.role == "private") {
            var subSection = languageSection.SubSections.find(x => x.Url == "/fo/private/");
            if (!!subSection && subSection.FooterHtml) {
              this.pageHtml = this.decodeHtml(subSection.FooterHtml);
            }
          }
          else if (this.role == "business") {
            var subSection = languageSection.SubSections.find(x => x.Url == "/fo/business/");
            if (!!subSection && subSection.FooterHtml) {
              this.pageHtml = this.decodeHtml(subSection.FooterHtml);
            }
          }
          else {
            var subSection = languageSection.SubSections.find(x => x.Url == "/fo/private/");
            if (!!subSection && subSection.FooterHtml) {
              this.pageHtml = this.decodeHtml(subSection.FooterHtml);
            }
          }
        }
      }
    }
  }

  // public toggleOnlineServices() {

  //   this.showOnlineServicePanel = !this.showOnlineServicePanel;
  // }

}
