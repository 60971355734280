import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CookieService {

    constructor() {
    }
    
    public getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    public deleteCookie(name) {
        this.setCookie(name, '', -1);
    }

    public setCookie(name: string, value: string, expireMinutes: number, path: string = '/') {
        let d:Date = new Date();
        d.setTime(d.getTime() + expireMinutes * 60 * 1000);
        let expires:string = `expires=${d.toUTCString()}`;
        let cpath:string = '/';
        document.cookie = `${name}=${value}; ${expires};path=/`;
    }
}