<div *ngIf="!isPaying" class="container">
    <div class="row">
        <div class="col-lg-4 info-container">
            <h2 class="header-title">{{'pay_customs.step_two_details_payment_page.email' | translate}}</h2>
            <span>{{userConfirmationFormGroup.get('email').value}}</span>
        </div>
        <div class="col-lg-4 info-container">
            <h2 class="header-title">{{'pay_customs.step_two_details_payment_page.customer_name' | translate}}</h2>
            <span>{{invoiceCustom.customerName}}</span>
        </div>
        <div class="col-lg-4 info-container">
            <h2 class="header-title">{{'pay_customs.step_two_details_payment_page.invoice_date' | translate}}</h2>
            <span>{{invoiceCustom.invoiceDate |  date:'dd/MM/yyyy'}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4 info-container">
            <h2 class="header-title">{{'pay_customs.step_two_details_payment_page.invoice_number' | translate}}</h2>
            <span>{{invoiceCustom.invoiceCustomNumber}}</span>
        </div>
        <div class="col-lg-4 info-container">
            <h2 class="header-title">{{'pay_customs.step_two_details_payment_page.reference_code' | translate}}</h2>
            <span>{{invoiceCustom.referenceCode}}</span>
        </div>
        <div class="col-lg-4 info-container">
            <h2 class="header-title">{{'pay_customs.step_two_details_payment_page.total' | translate}}</h2>
            <span>{{invoiceCustom.totalAmountIncVAT | currency: 'DKK':''}} DKK</span>
        </div>
    </div>
    <div *ngIf="!paymentSuccess" class="row">
        <div class="col-lg-12">
            <h1 class="total-amount">{{'pay_customs.step_two_details_payment_page.amount_due' | translate}}: {{invoiceCustom.outstandingAmount | currency: 'DKK':''}} DKK</h1>
            <button class="primary-button" (click)="payNow()">{{'pay_customs.step_two_details_payment_page.pay_now' | translate}}</button>
        </div>
    </div>
</div>

<div *ngIf="paymentCancelledOrFailed" class="container">
    <div class="paid-order-confirmation-container">
      <h2 class="posta-id">{{'pay_customs.step_two_details_payment_page.payment_failed' | translate}}: {{invoiceCustom.invoiceCustomNumber}}</h2>
      <div class="paid-order-body">
        {{'pay_customs.step_two_details_payment_page.payment_failed_body' | translate}}
      </div>
    </div>
  </div>

<div [ngClass]="(isPaying && !paymentSuccess && !loadingPayment)? 'visible' : 'hidden'" class="container">
    <iframe id="paymentFrame" #paymentFrame
      sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts"
      class="full-width frame" [src]="iframeURL"></iframe>
  </div>

  <div *ngIf="loadingPayment" class="container">
    <div class="loading-container">
      <mat-spinner class="primary" diameter="45"></mat-spinner>
    </div>
</div>
