import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AddressService } from '../../shared/services/address-service';
import { CountryService } from '../../shared/services/country.service';
import { Company } from '../entities/company';
import { CompanyAndUser } from "../entities/companyAndUser";
import { CompanyUser } from '../entities/companyUser';
import { Country } from '../entities/Country';
import { RegisterService } from '../services/register.service';
import { ErrorHelper } from '../../shared/helpers/error-helper';
import { TranslationHelper } from '../../shared/helpers/translation-helper';
import { StaticContentService } from '../../shared/services/static-content.service';

@Component({
  selector: 'app-business-register',
  templateUrl: './business-register.component.html',
  styleUrls: ['./business-register.component.scss']
})
export class BusinessRegisterComponent implements OnInit {

  businessFormGroup: FormGroup;

  existingSelected: boolean = false;

  newSelected: boolean = true;

  preferEmail: boolean = false;

  preferSMS: boolean = false;

  pageTitle: string;

  dontKnowAccountNumber: boolean = false;

  failedToCreate: boolean = false;

  showFormError: boolean = false;

  saving: boolean = false;

  @Output()
  successfullyCreatedUser: EventEmitter<boolean> = new EventEmitter();

  postcodes: Array<string> = new Array<string>();

  countries: Array<Country> = new Array<Country>();

  interval: any;

  constructor(private _formBuilder: FormBuilder,
    private translateService: TranslateService,
    private registerService: RegisterService,
    private addressService: AddressService,
    private countryService: CountryService,
    private errorHelper: ErrorHelper,
    private translateHelper: TranslationHelper) { }

  ngOnInit(): void {

    this.getPostcodes();

    this.businessFormGroup = this._formBuilder.group({
      accountNumber: ['', Validators.required],
      companyName: ['', Validators.required],
      countryCode: ['', [Validators.required, ErrorHelper.countryCodeValidator]],
      telephoneNum: ['', [Validators.required, ErrorHelper.integerOnlyValidator]],
      postcode: ['', Validators.required],
      vtalNumber: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6), ErrorHelper.integerOnlyValidator]],
      companyEmail: ['', [Validators.required, , ErrorHelper.emailValidator]],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      townCity: ['', Validators.required],
      invoicePostcode: [''],
      country: ['', Validators.required],
      specialInstructions: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      jobTitle: [''],
      asAbove: [''],
      emailAddress: ['', [Validators.required, ErrorHelper.emailValidator]],
      mobileCountryCode: ['', [Validators.required, ErrorHelper.countryCodeValidator]],
      mobileNum: ['', [Validators.required, ErrorHelper.integerOnlyValidator]],
      specifyPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {validators: this.passwordMatchValidator});

    this.businessFormGroup.get('countryCode').setValue('+298');
    this.businessFormGroup.get('mobileCountryCode').setValue('+298');

    this.businessFormGroup.get(`countryCode`).valueChanges.subscribe(newValue => {
      this.translateHelper.prependPlus(this.businessFormGroup.get('countryCode'));
    });
    this.businessFormGroup.get(`mobileCountryCode`).valueChanges.subscribe(newValue => {
      this.translateHelper.prependPlus(this.businessFormGroup.get('mobileCountryCode'));
    });
    this.businessFormGroup.get(`asAbove`).valueChanges.subscribe(newValue => {
      if(newValue == true)
        this.businessFormGroup.get(`emailAddress`).setValue(this.businessFormGroup.get(`companyEmail`).value);
      else
        this.businessFormGroup.get(`emailAddress`).setValue("");
    });

    this.countryService.getAll().subscribe(x => {this.countries = x;
      this.countries.sort(function(x,y){ return x.countryCode == "FO" ? -1 : y.countryCode == "FO" ? 1 : 0; });
      this.countries.sort(function(x,y){ return x.countryCode == "DK" ? -1 : y.countryCode == "DK" ? 1 : 0; });
      
      var countryCode = "FO"; //this.translateService.currentLang
      // if (this.translateService.currentLang == "en")
      // {
      //   countryCode = "gb";
      // }
      var country = this.countries.find(y => y.countryCode == countryCode.toUpperCase());
      this.businessFormGroup.get("country").setValue(country);

      // this.translateService.onLangChange.subscribe(x => {
        
      //   var countryCode = this.translateService.currentLang
      //   if (this.translateService.currentLang == "en")
      //   {
      //     countryCode = "gb";
      //   }
      //   var country = this.countries.find(y => y.countryCode == countryCode.toUpperCase());
      //   this.businessFormGroup.get("country").setValue(country);
      // })
    });

    this.businessFormGroup.get('accountNumber').disable();

  }

  public clearAll() {
    this.businessFormGroup.reset();
  }

  public isTouched(field){
    return this.businessFormGroup.get(field).touched
  }

  public toggleAccountNumber()
  {
    this.dontKnowAccountNumber = !this.dontKnowAccountNumber;
    if (this.dontKnowAccountNumber)
    {
      this.businessFormGroup.get("accountNumber").reset();
      this.businessFormGroup.get("accountNumber").disable();
    }
    else{
      this.businessFormGroup.get("enable").enable();
    }
  }

  public hasErrors(field){
    var errors = this.businessFormGroup.get(field).errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.businessFormGroup.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }
  
  passwordMatchValidator(g: FormGroup) {
    if(g.get('specifyPassword').value === g.get('confirmPassword').value){
      g.get('confirmPassword').setErrors(null);
      return null;
    }
    else{
      g.get('confirmPassword').setErrors({'mismatch': true});
      return {'mismatch': true};
    }
  }

  public enableCustomerAccountNumberFied(enable: boolean) {
    if (!enable) {
      this.businessFormGroup.get('accountNumber').disable();
      this.businessFormGroup.get('addressLine1').enable();
      this.businessFormGroup.get('companyEmail').enable();
      this.businessFormGroup.get('companyName').enable();
      this.businessFormGroup.get('countryCode').enable();
      this.businessFormGroup.get('invoicePostcode').enable();
      this.businessFormGroup.get('postcode').enable();
      this.businessFormGroup.get('telephoneNum').enable();
      this.businessFormGroup.get('townCity').enable();
      this.businessFormGroup.get('vtalNumber').enable();
    }
    else {
      this.businessFormGroup.get('accountNumber').enable();
      this.businessFormGroup.get('addressLine1').disable();
      this.businessFormGroup.get('companyEmail').disable();
      this.businessFormGroup.get('companyName').disable();
      this.businessFormGroup.get('countryCode').disable();
      this.businessFormGroup.get('invoicePostcode').disable();
      this.businessFormGroup.get('postcode').disable();
      this.businessFormGroup.get('telephoneNum').disable();
      this.businessFormGroup.get('townCity').disable();
      this.businessFormGroup.get('vtalNumber').disable();
    }
  }

  public proceed() {
    this.showFormError = false;
    if (!this.businessFormGroup.valid)
    {
      this.businessFormGroup.markAllAsTouched();
      this.showFormError = true;
      return;
    }

    if (this.businessFormGroup.get('specifyPassword').value !== this.businessFormGroup.get('confirmPassword').value)
    {
      //TODO raise password not matching error
      return;
    }

    this.saving = true;

    var company: Company = {
      isExisting: this.existingSelected,
      accountNumber: this.businessFormGroup.get('accountNumber').enabled ? this.businessFormGroup.get('accountNumber').value : null,
      companyName: this.businessFormGroup.get('companyName').value,
      phoneCountryCode: this.businessFormGroup.get('countryCode').value.replace('+', ''),
      addressLine1: this.businessFormGroup.get('addressLine1').value,
      addressLine2: this.businessFormGroup.get('addressLine2').value,
      townCity: this.businessFormGroup.get('townCity').value,
      countryCode: this.businessFormGroup.get('country').value.countryCode,
      phoneNo: this.businessFormGroup.get('telephoneNum').value,
      postcode: this.businessFormGroup.get('postcode').value.postcode,
      vtal: this.businessFormGroup.get('vtalNumber').value,
      email: this.businessFormGroup.get('companyEmail').value
    }

    var user: CompanyUser = {
      firstName: this.businessFormGroup.get('firstName').value,
      lastName: this.businessFormGroup.get('lastName').value,
      jobTitle: this.businessFormGroup.get('jobTitle').value,
      email: this.businessFormGroup.get('emailAddress').value,
      mobileCountryCode: this.businessFormGroup.get('mobileCountryCode').value.replace('+', ''),
      mobileNo: this.businessFormGroup.get('mobileNum').value,
      password: this.businessFormGroup.get('specifyPassword').value,
      preferEmail: this.preferEmail,
      preferSms: this.preferSMS,
      specialInstructions: this.businessFormGroup.get('specialInstructions').value,
      languageCode: this.translateService.currentLang
    }

    var companyAndUser: CompanyAndUser = {
      company: company,
      companyUser: user
    }

    this.registerService.registerCompanyAndUser(companyAndUser).subscribe(x => {
      this.successfullyCreatedUser.emit(true);
      this.failedToCreate = false;
      this.saving = false;
      window.scrollTo(0, 0);
    }, (error) => {
      this.failedToCreate = true;
      this.saving = false;
      if (!!error.error)
      {
        if (error.error.userEmailExists)
        {
          this.businessFormGroup.get('emailAddress').setErrors({emailTaken: true});
        }
        if (error.error.userMobileExists)
        {
          this.businessFormGroup.get('mobileNum').setErrors({mobileTaken: true});
        }
      }
    });
  }

  public getPostcodes() {
    this.addressService.getPostcodes().subscribe((x) => {
      this.postcodes = x
    });
  }

  public getCountryNameForLanguage(country: Country): string
  {
     switch (this.translateService.currentLang) {
       case "en":
         return country.countryNameEN;
       case "fo":
         return country.countryNameFO;
     }
  }

}
