<button class="primary-button upload-later" (click)="uploadLater()">
  {{'file_upload.upload_later' | translate}}
</button>
<button class="primary-button" (click)="onClick()">
  <img class="image" src="/assets/icons/arrows/arrow_white.png" /> {{'file_upload.upload' | translate}}
</button><input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
<div *ngFor="let file of files; let i = index">
  <div class="row file-upload-row align-center">
    <div class="col-lg-5 col-md-5 file-name">
      {{ file.fileName }}
    </div>
    <mat-form-field [formGroup]="orderDetailsForm" class="col-lg-5 col-md-5 input-field-height" floatLabel="never"
      appearance="outline">
      <input class="input-field" class="text-input" matInput placeholder="{{'file_upload.company_name_placeholder' | translate}}" [(ngModel)]="file.companyName"
        id="fileCompanyName{{i}}" name="fileCompanyName{{i}}" formControlName="fileCompanyName{{i}}">
        <!-- <mat-error id="companyNameRequired">Please provide a company name</mat-error> -->
    </mat-form-field>
    <div class="col-lg-2 col-md-2 col-2">
      <button class="delete-file-button" (click)="deleteFile(file)">
        <img class="delete-icon" src="/assets/icons/arrows/close@3x.png" />
      </button>
    </div>
  </div>
</div>
<mat-error *ngIf="showRequiredError" class="reminder-message" id="fileRequired"><app-field-error-message errorMessage="{{'file_upload.upload_later_error' | translate}}"></app-field-error-message></mat-error>
<div *ngIf="showUploadLaterMesssage" class="reminder-message">
  <strong>{{'file_upload.reminder' | translate}}</strong>
</div>