import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../entities/user';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CustomerAccount } from '../entities/customerAccount';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) {
    }

    public getUserDetails(): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/api/user/contactdetails`) as Observable<User>;
    }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    changePassword(changeInput: any) {
        return this.http.put(`${environment.apiUrl}/api/user`, changeInput)
    }

    public getIndividualUserProfile() {
        return this.http.get(`${environment.apiUrl}/api/user/iu_getprofile`);
    }

    public getBusinessUserProfile() {
        return this.http.get(`${environment.apiUrl}/api/user/bu_getprofile`);
    }

    public updateBusinessProfile(input) {
        return this.http.put(`${environment.apiUrl}/api/user/bu_updateprofile`, input);
    }

    public updateIndividualProfile(input) {
        return this.http.put(`${environment.apiUrl}/api/user/iu_updateprofile`, input);
    }

    public migrateToSamleikin(ptal: string)
    {
        return this.http.put(`${environment.apiUrl}/api/user/${ptal}/MigrateUser`, null);
    }

    public getCustomerAccounts(){
        return this.http.get(`${environment.apiUrl}/api/customerAccount`) as Observable<CustomerAccount[]>;
    }

    public getAccountStatement(customerAccountNo: string, fromDate?: string, toDate?: string){
        var url = `${environment.apiUrl}/api/customerAccount/statement/${customerAccountNo}`;
        if(fromDate){
            url = `${url}?fromDate=${fromDate}`
            if(toDate){
                url = `${url}&toDate=${toDate}`
            }
        }
        else{
            if(toDate){
                url = `${url}?toDate=${toDate}`
            }
        }
        return this.http.get(url, {responseType: 'text'});
    }
}