import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { TransportType } from '../entities/TransportType';
import { CountryGroup } from '../entities/CountryGroup';
import { FindServiceOptionDetails } from '../entities/FindServiceOptionDetails';
import { environment } from '../../../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class PriceMatrixService {

    constructor(private httpClient: HttpClient) {
    }

    public getAll(): Observable<any> {
        return this.httpClient.get(`${environment.apiUrl}/api/AddressBook`);
    }

    public getDefaultWeightIntervals(): Observable<any> {
        return this.httpClient.get(`${environment.apiUrl}/api/PriceMatrix/DefaultWeightIntervals`);
    }

    public getLetterSizes(): Observable<Array<TransportType>> {
        return this.httpClient.get(`${environment.apiUrl}/api/PriceMatrix/LetterSizes`) as Observable<Array<TransportType>>;
    }

    public getServiceOptions(serviceOptionDetails: FindServiceOptionDetails): Observable<Array<TransportType>> {
        return this.httpClient.post(`${environment.apiUrl}/api/PriceMatrix/ServiceOptions`, serviceOptionDetails) as Observable<Array<TransportType>>;
    }

    public getTaxPercentage(): Observable<number>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/PriceMatrix/TaxPercentage`) as Observable<number>;
    }

    public getBuyPostageWeightBoundaries(): Observable<Array<number>>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/FindPrices`) as Observable<Array<number>>
    }

    public getPostageWeightBoundaries(): Observable<Array<number>>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/PriceMatrix/BuyPostageWeights`) as Observable<Array<number>>
    }

    public getParcelWeightBoundaries(): Observable<Array<number>>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/PriceMatrix/ParcelWeights`) as Observable<Array<number>>
    }

    public getBuyPostageCountrySchemes(): Observable<Array<CountryGroup>>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/PriceMatrix/BuyPostageCountrySchemes`) as Observable<Array<CountryGroup>>
    }

    public getBuyPostageSizeOptions(countryIndex: number, weight: Number)
    {
        return this.httpClient.get(`${environment.apiUrl}/api/PriceMatrix/BuyPostageSizeOptions?countrySchemeId=${countryIndex}&weight=${weight}`) as Observable<Array<TransportType>>
    }
}