
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from '../../account/services/user.service';
import { User } from "../../shared/entities/user";

@Injectable({ providedIn: 'root' })
export class UserDetailsResolver implements Resolve<User> {

    constructor(private userService: UserService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>| Promise<any>|any {
        return this.userService.getUserDetails();
    }
}