<div class="main-body-container">
    <div class="row div-padding">
        <div class="col-md-4 col-12 align-center">
            <h2>{{'upload_missing_documentation.customs_uploads' | translate}}</h2> <!---->
            <label class="tooltipSource"><img class="information-icon"
                src="/assets/icons/i_information@3x.png" />
                <a tabindex="0"></a><span>{{'upload_missing_documentation.customs_uploads_hint' | translate}}</span></label>
        </div>
        <div class="col-md-8 align-center hide-mobile">
            <h2>{{'upload_missing_documentation.company_name' | translate}}</h2>
            <label class="tooltipSource"><img class="information-icon"
                src="/assets/icons/i_information@3x.png" />
                <a tabindex="0"></a><span>{{'upload_missing_documentation.company_name_hint' | translate}}</span></label>
        </div>
    </div>
    <hr class="no-margin-rule">
    <div class="">
        <div class="files" *ngFor="let file of files; let i = index">
            <div class="row file-upload-row align-center div-padding">
                
                <div class="col-md-4 col-12">
                    <img class="button-icon" src="/assets/icons/upload-missing.png" />
                    {{ file.filename }}
                </div>
                <div class="col-md-4 col-12" style="padding-left: 15px; padding-right: 15px;">
                    {{ file.companyName }}
                </div>
                <div class="col-md-4 col-12">
                    <button class="tertiary-button full-width" (click)="deleteFile(file)">
                        <div class="full-width">
                            <span class="button-text">{{'upload_missing_documentation.delete' | translate}}</span><!---->
                            <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
                        </div>
                        <!-- <img class="delete-icon" src="/assets/icons/arrows/close@3x.png" /> -->
                    </button>
                    <!-- <button class="show-tablet" (click)="deleteFile(file)">
                        <img class="delete-icon" src="/assets/icons/arrows/close@3x.png" />
                    </button> -->
                </div>
            </div>
            <hr>
        </div>
        <div class="row file-upload-row align-center div-padding">
            <div class="col-md-4 col-12 mob-stack-padding">
                <button class="primary-button" (click)="onClick()"> {{'upload_missing_documentation.select_file' | translate}} </button>
                <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
            </div>
            <div class="col-md-4 col-12 mob-stack-padding">
                <mat-form-field [formGroup]="newDocumentFormGroup" floatLabel="never" appearance="outline" style="display: unset;">
                        <input class="input-field" class="text-input" matInput placeholder="{{'upload_missing_documentation.specify_name' | translate}}"
                            [(ngModel)]="newCompanyName" id="newCompanyName" name="newCompanyName"
                            formControlName="newCompanyName" required>
                    </mat-form-field>
            </div>
            <div class="col-md-4 col-12 mob-stack-padding">
                <button class="dark-blue-button full-width" (click)="addFileToQueue()"> {{'upload_missing_documentation.submit' | translate}} </button>
            </div>
            <div class="col-md-4 col-12 mob-stack-padding requirements-container">
                <button class="tertiary-button full-width" (click)="openRouteInNewWindow(item1Link.Url)"> {{'upload_missing_documentation.requirements' | translate}} </button>
            </div>
        </div>
    </div>
</div>
<div class="row" style="padding-top: 30px;">
    <div class="col-lg-9 col-md-9">

    </div>
    <div class="col-lg-3 col-md-3 col-12">
        <button [disabled]="loading" class="primary-button " (click)="proceed()">
        <div class="spinner">
            <span [ngClass]="loading? 'margin-left' : ''">{{'upload_missing_documentation.proceed' | translate}}</span>
            <mat-spinner *ngIf="loading" style="margin-left: 15px;" diameter="15"></mat-spinner>
        </div>
    </button>
    </div>
    
</div>

<!-- <div style="padding-top: 20px;" class="row">
    <div class="col-lg-6 col-md-6 col-12">
        <button class="primary-button" (click)="onClick()">
            <img class="image" src="/assets/icons/arrows/arrow_white.png" /> {{'upload_missing_documentation.template' |
            translate}}
        </button>
    </div>
</div>
<div style="padding-top: 20px;" class="row">
    <div class="col-lg-3 col-md-3 col-6">
        <button class="primary-button"> {{'upload_missing_documentation.template' | translate}} </button>
    </div>
    <div class="col-lg-3 col-md-3 col-6">
        <button class="primary-button"> {{'upload_missing_documentation.requirements' | translate}} </button>
    </div>
</div>
<div style="padding-top: 20px;" class="row">
    <div class="col-lg-6 col-md-6 col-12">
        
    </div>
</div> -->
<!-- <mat-error *ngIf="showRequiredError" class="reminder-message" id="fileRequired">Please upload a file</mat-error> -->