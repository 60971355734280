import { ObserversModule } from "@angular/cdk/observers";
import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { FindValueSubscriber } from "rxjs/internal/operators/find";
import { AuthenticationService } from "../../account/services/authentication.service";
import { RoleService } from "../../account/services/role.service";
import { CookieService } from "../services/cookie.service";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private cookieService: CookieService,
        private translateService: TranslateService,
        private roleService: RoleService,
        private authenticationService: AuthenticationService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return new Observable<boolean>(obs => {
            var href = route.url;

            var userDetails = localStorage.getItem("userDetails");
            var loggedInUserType = localStorage.getItem("loginUserType");

            var isSamleikinUser = localStorage.getItem("isSamleikin");
            if (!!isSamleikinUser)
            {
                this.authenticationService.getSessionData().subscribe(x => {
                    if (!x || (!!x && !x.attributes))
                    {
                        obs.next(false);
                    }
                    obs.next(true);
                }, (error) => {
                    obs.next(false);
                })
            }
            else {
                const authorised = !!this.cookieService.getCookie('token') && !!userDetails
                if (authorised) {
                    // authorised so return true
                    obs.next(true);

                    if (!!userDetails)
                    {
                        var userDetailsJson = JSON.parse(userDetails);
                        if (!!userDetailsJson)
                        {
                            if (!userDetailsJson.isVerified)
                            {
                                this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/awaiting-verification`]);
                                obs.next(false);
                            }
            
                            if (!userDetailsJson.isPostaApproved && !!loggedInUserType && loggedInUserType != 'private')
                            {
                                this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/awaiting-approval`]);
                                obs.next(false);
                            }
                        }
                    }
                }
                else {
                    localStorage.removeItem("user");
                    localStorage.removeItem("isSamleikin");
                    var path = `?returnUrl=${href.join('/')}`
                    this.router.navigateByUrl(`${this.translateService.currentLang}/${this.roleService.role()}/login${path}`);
                }
                
                obs.next(false);
            }
        })
    }
}