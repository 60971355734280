<!-- <div class="container header-container">
    <h1 *ngIf="!postageConfirmationDetails" class="header-title">{{'buy_postage.header_title' | translate}}</h1>
    <div *ngIf="!!postageConfirmationDetails" class="header-title" style="display: flex;">
        <h1 class="header-grey">+ order >&nbsp;</h1>
        <h1>{{'buy_postage.header_title' | translate}}</h1>
    </div>
    <h2 *ngIf="!postageConfirmationDetails" class="header-grey buy-postage-subtitle hide-mobile">{{'buy_postage.specify_details' | translate}}</h2>
</div> -->
    <div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
<div *ngIf="!showSecondPage && !showAddressesPage && !showCustomsPage && !showLoginPage">
    <div class="container">
        <div class="send-receive-button-container">
            <button class="secondary-button send-receive-button" (click)="navigateToSendReceive()">{{'buy_postage.order_via_send_receive' | translate}}</button>
        </div>
        <!-- <div *ngIf="!isLoggedIn" class="container email-container">
            <div class="row">
                <div class="col-lg-2 col-sm-2 align-center field-title-width weight-title-container">
                    <h3 class="primary">{{'buy_postage.email' | translate}}</h3>
                    <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                        <a tabindex="0"></a><span>{{'buy_postage.email_tooltip' | translate}}</span></label>
                </div>
                <div class="weight-input-container col-lg-9 col-sm-9">
                    <mat-form-field class="no-error-wrap float-never weight-input" floatLabel="never"
                        appearance="outline" [formGroup]="buyPostageForm">
                        <input matInput placeholder="{{'buy_postage.email_placeholder' | translate}}" id="email" name="email"
                            formControlName="email" required>
                        <mat-error *ngIf="hasErrors('email')">
                            <app-field-error-message errorMessage="{{getError('email')}}">
                            </app-field-error-message>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div> -->
        <div class="from-to-container">
            <div class="row from-to-country-row">
                <div class="col-lg-2 col-sm-2 align-center field-title-width">
                    <h3 class="primary from-to-title">{{'buy_postage.to' | translate}}</h3>
                </div>
                <div class="col-lg-5 col-sm-5">
                    <div class="bullet-checkbox-container" [formGroup]="buyPostageForm">
                        <label>
                            <span class="span-radio-align">
                                <input class="vertical-align-radio-button" type="radio" name="fromTo" id="1" [value]="0"
                                [(ngModel)]="fromToValue" (change)="destinationSelected(0)" formControlName="fromTo">
                            </span>
                            <span *ngIf="!!fromToOptions"
                                class="radio-text" style="font-size: 16px;">{{getTranslation(fromToOptions[0].Name)}}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div *ngFor="let fromToOption of fromToOptions | slice:1; let i = index" class="row secondary-country-row">
                <div class="col-lg-2 col-sm-2 field-title-width"></div>
                <div class="col-lg-5 col-sm-5">
                    <div class="bullet-checkbox-container" [formGroup]="buyPostageForm">
                        <label>
                            <span class="span-radio-align">
                                <input class="vertical-align-radio-button" type="radio" name="fromTo" id="i + 2"
                                [(ngModel)]="fromToValue" [value]="i + 1" (change)="destinationSelected(i + 1)" formControlName="fromTo">
                            </span>
                            <span class="radio-text" style="font-size: 16px;">{{getTranslation(fromToOption.Name)}}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="container contents-container">
            <div class="row from-to-country-row">
                <div class="col-lg-2 col-sm-2 align-center field-title-width">
                    <h3 class="primary from-to-title">{{'buy_postage.contents' | translate}}</h3>
                </div>
                <div class="col-lg-5 col-sm-5">
                    <div class="bullet-checkbox-container" [formGroup]="buyPostageForm">
                        <label>
                            <span class="span-radio-align">
                                <input class="vertical-align-radio-button" type="radio" name="isGoods" [value]="false"
                                [(ngModel)]="isGoods" formControlName="isGoods">
                            </span>
                            <span *ngIf="!!fromToOptions"
                                class="radio-text" style="font-size: 16px;">{{'buy_postage.documents' | translate}}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row secondary-country-row">
                <div class="col-lg-2 col-sm-2 field-title-width"></div>
                <div class="col-lg-5 col-sm-5">
                    <div class="bullet-checkbox-container" [formGroup]="buyPostageForm">
                        <label>
                            <span class="span-radio-align">
                                <input class="vertical-align-radio-button" type="radio" name="isGoods"
                                [(ngModel)]="isGoods" [value]="true" formControlName="isGoods">
                            </span>
                            <span class="radio-text" style="font-size: 16px;">{{'buy_postage.goods' | translate}}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="container weight-container">
            <div class="row">
                <div class="col-lg-2 col-sm-2 align-center field-title-width weight-title-container">
                    <h3 class="primary">{{'buy_postage.weight' | translate}}</h3>
                    <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                        <a tabindex="0"></a><span>{{'buy_postage.select_weight_tooltip' | translate}}</span></label>
                </div>
                <div class="weight-input-container col-lg-9 col-sm-9">
                    <mat-form-field class="no-error-wrap float-never weight-input" floatLabel="never"
                        appearance="outline" [formGroup]="buyPostageForm">
                        <mat-select placeholder="{{'buy_postage.select_weight' | translate}}" [(ngModel)]="chosenWeightBoundary" disableOptionCentering panelClass="select-panel"
                            (selectionChange)="setWeight($event)" formControlName="weight" required>
                            <mat-option *ngFor="let weightBoundary of weightBoundaries" [value]="weightBoundary">
                                {{weightBoundary}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf="showSizeOptions" class="container size-container">
            <div class="row">
                <div class="col-lg-2 col-md-2 field-title-width" style="display: flex; padding-top: 16px">
                    <h3 class="primary">{{'buy_postage.size' | translate}}</h3>
                    <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                        <a tabindex="0"></a><span>{{'buy_postage.size_tooltip' | translate}}</span></label>
                </div>
                <div class="col-lg-10 col-md-10">
                    <div class="row size-options">
                        <div *ngFor="let sizeOption of sizeOptions; let i=index" class="col-lg-3 col-md-2 col-12 size-option-width"
                            style="white-space: initial" [formGroup]="buyPostageForm">
                            <img class="size-icons mobile-inline" [src]="sizeOption.BuyPostageIconPath" />
                            <label class="align-center mobile-inline">
                                <span class="span-radio-align-h3">
                                    <!-- Fix the below and don't hack it with a margin -->
                                    <input class="vertical-align-radio-button" style="margin-bottom: 3px;" (change)="checkIsNotXL($event)" type="radio" name="size" id="i"
                                        formControlName="size" [(ngModel)]="sizeValue" [value]="i">
                                </span>
                                <h3 class="radio-text mobile-inline" style="font-weight: bold;">{{getTranslation(sizeOption.Name)}}</h3>
                                <div class="show-mobile-ls" style="padding-top: 20px; font-size: 16px; padding-left: 15px;">{{getTranslation(sizeOption.Description)}}</div>
                            </label>
                            <div class="hide-mobile-ls" style="padding-top: 20px; font-size: 16px;">{{getTranslation(sizeOption.Description)}}</div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showSizeOptions && !showXLErrorMessage" class="container">
        <div class="total-container">
            <h1 class="header-grey buy-postage-total">{{'buy_postage.total' | translate}} {{getItemPrice() | currency: 'DKK':''}} DKK</h1>
        </div>
        <div class="quantity-container">
            <div class="row">
                <div class="col-lg-3 col-sm-2 hide-mobile">
                    <button class="tertiary-button clear-all-button" (click)="clearAll()">
                        <div class="full-width">
                            <span class="clear-all-icon-text">{{'buy_postage.clear_all' | translate}}</span>
                            <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
                        </div>
                    </button>
                </div>
                <button class="primary-button proceed-button col-lg-3 col-sm-3 offset-lg-6 offset-sm-7 hide-mobile"
                    (click)="showSecondaryPage()">{{'buy_postage.proceed' | translate}}</button>
            </div>
        </div>
        <div class="proceed-container row">
            <button class="primary-button proceed-button col-12 show-mobile clear-all-button" style="max-width: unset;"
                (click)="showSecondaryPage()">{{'buy_postage.proceed' | translate}}</button>
            <button class="col-12 show-mobile tertiary-button clear-all-button" (click)="clearAll()">
                <div class="full-width">
                    <span class="clear-all-icon-text">{{'buy_postage.clear_all' | translate}}</span>
                    <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png"/>
                </div>
            </button>
        </div>
    </div>
</div>

<app-buy-postage-login *ngIf="showLoginPage && !showPaymentScreen" [emailForm]="emailForm"
    (backButtonSelected)="showFirstScreen()" (proceedButtonSelected)="showSecondaryPage()">

</app-buy-postage-login>

<app-buy-postage-secondary *ngIf="showSecondPage && !showPaymentScreen" [buyPostageForm]="buyPostageForm"
    [fromTo]="fromToOptions[fromToOptionIndexSelected]" [selectedOptionIndex]="fromToOptionIndexSelected" [size]="sizeOptions[buyPostageForm.get('size').value]"
    [price]="pricePerItem" (paymentEvent)="paymentScreenEvent($event)" (backButtonSelected)="showFirstScreen()">
</app-buy-postage-secondary>

<app-buy-postage-addresses *ngIf="showAddressesPage && !showPaymentScreen" [buyPostageForm]="buyPostageForm" [buyPostageAddressesForm]="buyPostageAddressesForm" [emailForm]="emailForm"
    (proceedButtonSelected)="proceedToCustomsPage()" (backButtonSelected)="showFirstScreen()">
</app-buy-postage-addresses>

<app-buy-postage-customs *ngIf="showCustomsPage && !showPaymentScreen" [buyPostageForm]="buyPostageForm" [buyPostageAddressesForm]="buyPostageAddressesForm" [buyPostageCustomsForm]="buyPostageCustomsForm"
    [fromTo]="fromToOptions[fromToOptionIndexSelected]" [price]="pricePerItem" [size]="sizeOptions[buyPostageForm.get('size').value]" [itemTypes]="itemTypes"
    (backButtonSelected)="returnToAddressesPage()" (paymentEvent)="paymentScreenEvent($event)">
</app-buy-postage-customs>

<div class="container" *ngIf="showPaymentScreen && !showPaymentFailed">
    <iframe id="paymentFrame" #paymentFrame
        sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts" [src]="iframeURL"
        class="full-width frame"></iframe>
</div>

<div *ngIf="showWeightExceedsNotice" class="container">
    <app-error-message errorMessage="{{'buy_postage.max_weight_exceeded' | translate}}"></app-error-message>
    <div class="weight-exceeded-options-container">
        <button class="secondary-button proceed-button" style="margin-right: 20px;"
        [routerLink]="['../find-prices']">{{'buy_postage.find_prices' | translate}}</button>
        <button class="primary-button proceed-button"
        [routerLink]="['../send-receive/send-receive']">{{'buy_postage.order_online' | translate}}</button>
    </div>
</div>

<div *ngIf="showXLErrorMessage" class="container">
    <app-error-message errorMessage="{{'buy_postage.max_weight_exceeded' | translate}}"></app-error-message>
    <div class="weight-exceeded-options-container">
        <button class="secondary-button proceed-button" style="margin-right: 20px;"
        [routerLink]="['../find-prices']">{{'buy_postage.find_prices' | translate}}</button>
        <button class="primary-button proceed-button"
        [routerLink]="['../send-receive/send-receive']">{{'buy_postage.order_online' | translate}}</button>
    </div>
</div>

<div *ngIf="showPaymentFailed" class="container">
    <h2>{{'buy_postage.payment_failed' | translate}}</h2>
</div>

<app-buy-postage-confirmation *ngIf="!!postageConfirmationDetails"
    [orderConfirmationDetails]="postageConfirmationDetails" [isDomestic]="isDomestic" [isGoods]="isGoods" [anonymousUserGuid]="anonymousUserGuid"></app-buy-postage-confirmation>
    <div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div><!-- <app-buy-postage-confirmation *ngIf="!!postageConfirmationDetails" [orderConfirmationDetails]="postageConfirmationDetails"></app-buy-postage-confirmation> -->