<!-- <div *ngIf="!noSamleikin" class="container">
    <h2 class="text-align-middle">Register with Samleikin</h2>
    <button class="samleikin-link-button" [routerLink]="['../../../login/samleikin']"><img class="samleikin-img" src="/assets/icons/login/samleikin_logo.png"/></button>
    <div class="button-container">
        <button class="primary-button" (click)="noSamleikin=true;">I do not have Samleikin</button>
    </div>
</div> -->

<div *ngIf="noSamleikin" class="container">
    <div class="secondary-container">
        <h3 class="primary company-info-title">{{'private_register.my_details_title' | translate}}</h3>
        <div class="row">
            <div class="col-lg-6 col-12">
                <div class="row">
                    <div class="first-name-field col-md-6 col-12">
                        <h4 class="dark-blue title-mat-form-field">{{'private_register.first_name' | translate}}</h4>
                        <mat-form-field class="name-input-field align-form-field"
                            [formGroup]="privateFormGroup" floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{'private_register.first_name_placeholder' | translate}}" id="firstName" name="firstName"
                                formControlName="firstName" autocomplete="given-name" required>
                            <mat-error *ngIf="hasErrors('firstName')"><app-field-error-message errorMessage="{{getError('firstName')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="phone-field-top-padding last-name-field col-md-6 col-12">
                        <h4 class="dark-blue title-mat-form-field">{{'private_register.last_name' | translate}}</h4>
                        <mat-form-field class="name-input-field align-form-field"
                        [formGroup]="privateFormGroup" floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{'private_register.last_name_placeholder' | translate}}" id="lastName" name="lastName"
                            formControlName="lastName" autocomplete="family-name" required>
                            <mat-error *ngIf="hasErrors('lastName')"><app-field-error-message errorMessage="{{getError('lastName')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- <div class="tablet-field-top-padding job-title-field col-md-6 col-12">
                <h4 class="dark-blue title-mat-form-field">{{'private_register.job_title' | translate}}</h4>
                <mat-form-field class="name-input-field align-form-field" [formGroup]="privateFormGroup" floatLabel="never"
                        appearance="outline">
                        <input matInput placeholder="{{'private_register.job_title_placeholder' | translate}}" id="jobTitle" name="jobTitle"
                            formControlName="jobTitle" autocomplete="organization-title">
                    </mat-form-field>
            </div> -->
        <!-- </div> -->
    
        <!-- <div class="row" style="padding-top: 30px;"> -->
            <div class="col-md-6 col-12 user-email-field">
                <h4 class="dark-blue title-mat-form-field">{{'private_register.user_email' | translate}}</h4>
                <mat-form-field class="align-form-field full-width" [formGroup]="privateFormGroup" floatLabel="never"
                        appearance="outline">
                        <input matInput placeholder="{{'private_register.user_email_placeholder' | translate}}" id="emailAddress" name="emailAddress"
                            formControlName="emailAddress" autocomplete="email" required>
                            <mat-error *ngIf="hasErrors('emailAddress')"><app-field-error-message errorMessage="{{getError('emailAddress')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
            </div>
            <div class="col-md-6 col-12 user-mobile-field field-top-padding">
                <h4 class="dark-blue title-mat-form-field">{{'private_register.user_mobile_phone' | translate}}</h4>
                <mat-form-field class="country-code-form-field align-form-field" style="margin-right: 10px;"
                    [formGroup]="privateFormGroup" floatLabel="never" appearance="outline">
                    <input matInput placeholder="{{'private_register.user_mobile_country_code_placeholder' | translate}}" id="mobileCountryCode" name="mobileCountryCode" formControlName="mobileCountryCode" autocomplete="tel-country-code"
                        required>
                        <mat-error *ngIf="hasErrors('mobileCountryCode')"><app-field-error-message></app-field-error-message></mat-error>
                </mat-form-field>
                <mat-form-field class="mobile-number-form-field align-form-field" [formGroup]="privateFormGroup"
                    floatLabel="never" appearance="outline">
                    <input matInput placeholder="{{'private_register.user_mobile_number_placeholder' | translate}}" id="mobileNum" name="mobileNum" autocomplete="tel-national"
                        formControlName="mobileNum" required>
                        <mat-error *ngIf="hasErrors('mobileNum')"><app-field-error-message errorMessage="{{getError('mobileNum')}}"></app-field-error-message></mat-error>
                </mat-form-field>
            </div>
        </div>
    
    </div>

    <div class="secondary-container">
        <div class="align-center">
            <h3 class="primary company-info-title">{{'private_register.create_password_title' | translate}}</h3>
            <label class="tooltipSource tooltip-title"><img class="information-icon"
                    src="/assets/icons/i_information@3x.png" /><span>{{'private_register.password_tooltip' | translate}}</span></label>
        </div>
        <div class="row">
            <div class="col-md-6 col-12">
                <h4 class="dark-blue title-mat-form-field">{{'private_register.specify_password' | translate}}</h4>
                <div class="row">
                    <mat-form-field class="col-lg-12 align-form-field" style="padding-right: 10px;"
                        [formGroup]="privateFormGroup" floatLabel="never" appearance="outline">
                        <input type="password" matInput placeholder="{{'private_register.specify_password_placeholder' | translate}}" id="specifyPassword" name="specifyPassword"
                            formControlName="specifyPassword" autocomplete="new-password" required>
                            <mat-error *ngIf="hasErrors('specifyPassword')"><app-field-error-message errorMessage="{{getError('specifyPassword')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-6 col-12 phone-field-top-padding" style="padding-left: 10px">
                <h4 class="dark-blue title-mat-form-field">{{'private_register.confirm_password' | translate}}</h4>
                <div class="row">
                    <mat-form-field class="col-lg-12 align-form-field" [formGroup]="privateFormGroup" floatLabel="never"
                        appearance="outline">
                        <input type="password" matInput placeholder="{{'private_register.confirm_password_placeholder' | translate}}" id="confirmPassword" name="confirmPassword"
                            formControlName="confirmPassword" autocomplete="off" required>
                            <mat-error *ngIf="hasErrors('confirmPassword')"><app-field-error-message errorMessage="{{getError('confirmPassword')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="secondary-container">
        <h3 class="primary company-info-title">{{'private_register.my_address' | translate}}</h3>
        <div class="row">
            <div class="col-12" style="padding-right: 10px;">
                <div class="row">
                    <mat-form-field class="col-lg-6 col-md-8 col-12 align-form-field address-field" [formGroup]="privateFormGroup" floatLabel="never"
                        appearance="outline">
                        <input matInput placeholder="{{'private_register.address_line1_placeholder' | translate}}" id="addressLine1" name="addressLine1"
                            formControlName="addressLine1" autocomplete="address-line1" required>
                            <mat-error class="show-mobile" *ngIf="hasErrors('addressLine1')"><app-field-error-message errorMessage="{{getError('addressLine1')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                    <app-field-error-message *ngIf="hasErrors('addressLine1') && isTouched('addressLine1')" class="col-lg-6 col-md-4 address-error" errorMessage="{{getError('addressLine1')}}"></app-field-error-message>
                </div>
                <div class="row form-field-padding">
                    <mat-form-field class="col-lg-6 col-md-8 col-12 align-form-field address-field" [formGroup]="privateFormGroup" floatLabel="never"
                        appearance="outline">
                        <input matInput placeholder="{{'private_register.address_line2_placeholder' | translate}}" id="addressLine2" name="addressLine2"
                            formControlName="addressLine2" autocomplete="address-line2">
                    </mat-form-field>
                </div>
                <div class="row form-field-padding">
                    <mat-form-field class="col-lg-6 col-md-8 col-12 align-form-field address-field" [formGroup]="privateFormGroup" floatLabel="never"
                        appearance="outline">
                        <input matInput placeholder="{{'private_register.town_city_placeholder' | translate}}" id="townCity" name="townCity" formControlName="townCity" autocomplete="address-level2"
                            required>
                            <mat-error class="show-mobile" *ngIf="hasErrors('townCity')"><app-field-error-message errorMessage="{{getError('townCity')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                    <app-field-error-message *ngIf="hasErrors('townCity') && isTouched('townCity')" class="col-lg-6 col-md-4 address-error" errorMessage="{{getError('townCity')}}"></app-field-error-message>
                </div>
                <div class="row form-field-padding">
                    <mat-form-field class="col-lg-6 col-md-8 col-12 align-form-field address-field" [formGroup]="privateFormGroup" floatLabel="never"
                        appearance="outline">
                        <input matInput placeholder="{{'private_register.postcode_placeholder' | translate}}" id="postcode" name="postcode" autocomplete="postal-code"
                            formControlName="postcode" required>
                            <mat-error class="show-mobile" *ngIf="hasErrors('postcode')"><app-field-error-message errorMessage="{{getError('postcode')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                    <app-field-error-message *ngIf="hasErrors('postcode') && isTouched('postcode')" class="col-lg-6 col-md-4 address-error" errorMessage="{{getError('postcode')}}"></app-field-error-message>
                </div>
                <div class="row form-field-padding">
                    <mat-form-field class="col-lg-6 col-md-8 col-12 address-field" floatLabel="never" appearance="outline"
                        [formGroup]="privateFormGroup">
                        <mat-select placeholder="{{'private_register.country_placeholder' | translate}}" formControlName="country" required>
                            <mat-option *ngFor="let country of countries" [value]="country">{{getCountryNameForLanguage(country)}}</mat-option>
                        </mat-select>
                        <mat-error class="show-mobile" *ngIf="hasErrors('country')"><app-field-error-message errorMessage="{{getError('country')}}"></app-field-error-message></mat-error>
                    </mat-form-field>
                    <app-field-error-message *ngIf="hasErrors('country') && isTouched('country')" class="col-lg-6 col-md-4 address-error" errorMessage="{{getError('country')}}"></app-field-error-message>
                </div>
                <div class="row form-field-padding">
                    <mat-form-field [formGroup]="privateFormGroup" floatLabel="never"
                        class="col-lg-6 col-md-8 col-12 align-form-field special-instructions-field">
                        <textarea class="text-area" rows="8" matInput
                            placeholder="{{'private_register.special_instructions_placeholder' | translate}}"
                            id="specialInstructions" name="specialInstructions"
                            formControlName="specialInstructions"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="secondary-container">
        <h3 class="primary company-info-title">{{'private_register.notification_preferences' | translate}}</h3>
        <div class="row">
            <div class="col-12 my-custom-input-label-box align-center">
                <div class="align-center">
                    <input id="email-checkbox" (click)="preferEmail=!preferEmail" type="checkbox" name="email-checkbox" />
                    <label for="email-checkbox"></label>
                    <h4 class="checkbox-text">{{'private_register.notication_email' | translate}}</h4>
                </div>
            </div>
            <div class="col-12 my-custom-input-label-box align-center" style="padding-top: 30px;">
                <div class="align-center">
                    <input id="sms-checkbox" (click)="preferSMS=!preferSMS" type="checkbox" name="sms-checkbox" />
                    <label for="sms-checkbox"></label>
                    <h4 class="checkbox-text">{{'private_register.notification_sms' | translate}}</h4>
                </div>
            </div>
        </div>
    </div>
    <app-error-message *ngIf="failedToCreate" errorMessage="{{'private_register.failed_to_create_user' | translate}}">
    </app-error-message>

    <div class="row clear-all-proceed-row">
        <div class="col-12 mob-proceed-button">
            <button mat-button class="primary-button full-width" (click)="proceed()">{{'private_register.proceed' | translate}}</button>
        </div>
        <div class="col-lg-3 col-md-4 col-12 clear-button">
            <button class="tertiary-button full-width" (click)="clearAll()" mat-button>
                <div class="full-width">
                    <span class="clear-all-icon-text">{{'private_register.clear_all' | translate}}</span>
                    <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
                </div>
            </button>
        </div>
        <div class="col-lg-3 offset-lg-6 col-md-4 offset-md-4 col-12 proceed-button">
            <button mat-button class="primary-button full-width" (click)="proceed()" [disabled]="saving">
                <div class="spinner">
                    {{'private_register.proceed' | translate}}
                    <mat-spinner *ngIf="saving" class="mat-spin" diameter="15" style="margin-left: 10px;">
                    </mat-spinner>
                </div>
            </button>
        </div>
    </div>
    
</div>