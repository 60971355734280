import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../../../account/services/role.service';

@Component({
  selector: 'app-generated-code-dialog',
  templateUrl: './generated-code-dialog.component.html',
  styleUrls: ['./generated-code-dialog.component.scss']
})
export class GeneratedCodeDialogComponent implements OnInit {

  public shoppingListName: string;
  public showCopiedMessage: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GeneratedCodeDialogComponent>) {}

    ngOnInit() {
        // will log the entire data object
        this.shoppingListName = this.data.link;
      }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
