import { NgModule } from '@angular/core';
import { CommonModule, NumberFormatStyle } from '@angular/common';
import { HeaderPersonalComponent } from './header/header-personal/header-personal.component';
import { FooterComponent } from './footer/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { StaticContentPageComponent } from './static-content/static-content-page.component';
import { RouterModule } from '@angular/router';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedRoutingModule } from './shared-routing.module';
import { AddressBookComponent } from './address-book/address-book.component';
import { CreateNewAddressComponent } from './address-book/new-address/create-new-address.component';
import { ViewAllAddressesComponent } from './address-book/view-all-addresses/view-all-addresses.component';
import { EditAddressComponent } from './address-book/edit-address/edit-address.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FromNewAddressComponent } from './address-book/new-address/from-new-address.component';
import { ToNewAddressComponent } from './address-book/new-address/to-new-address.component';
import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';
import { ErrorMessageComponent } from './error/error-message/error-message.component';
import { OrdersSummaryComponent } from './my-posta/all-orders/orders-summary/orders-summary.component';
import { ViewAllOrdersComponent } from './my-posta/view-all-orders/view-all-orders.component';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { ToDateObjPipe } from './pipes/toDateObj.pipe';
import { PriceFormatPipe } from './pipes/priceFormat.pipe';
import { NumberFormatPipe } from './pipes/numberFormat.pipe';
import { FileUploadMissingDocumentsComponent } from './file-upload-missing-documents/file-upload-missing-documents.component';
import { FieldErrorMessageComponent } from './error/field-error-message/field-error-message.component';
import { LogoutDialogComponent } from './dialogs/logout-dialog/logout-dialog.component';
import { MatDialogModule } from '@angular/material/dialog'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NumberLocalizationPipe } from './pipes/numberLocalization.pipe';

@NgModule({
  declarations: [
    HeaderPersonalComponent,
    FooterComponent,
    StaticContentPageComponent,
    FileUploadComponent,
    AddressBookComponent,
    CreateNewAddressComponent,
    ViewAllAddressesComponent,
    EditAddressComponent,
    FromNewAddressComponent,
    ToNewAddressComponent,
    PageNotFoundComponent,
    ErrorMessageComponent,
    OrdersSummaryComponent,
    ViewAllOrdersComponent,
    SafeHtmlPipe, ToDateObjPipe, PriceFormatPipe, NumberFormatPipe, NumberLocalizationPipe,
    FileUploadMissingDocumentsComponent,
    FieldErrorMessageComponent,
    LogoutDialogComponent,
    ContactUsComponent
    ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    TranslateModule,
    MatMenuModule,
    RouterModule,
    MatProgressBarModule,
    MatCardModule,
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    Ng2SearchPipeModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatDialogModule
  ],
  exports: [
    HeaderPersonalComponent,
    FooterComponent,
    TranslateModule,
    StaticContentPageComponent,
    FileUploadComponent,
    FileUploadMissingDocumentsComponent,
    CreateNewAddressComponent,
    FromNewAddressComponent,
    ToNewAddressComponent,
    ErrorMessageComponent,
    FieldErrorMessageComponent,
    SafeHtmlPipe, ToDateObjPipe, PriceFormatPipe, NumberFormatPipe, NumberLocalizationPipe,
    LogoutDialogComponent
    ]
})
export class SharedModule { }