import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Order } from "../../../entities/Order";
import { SendReceiveService } from "./send-receive.service";

@Injectable({ providedIn: 'root' })
export class OrderResolver implements Resolve<Order> {

    constructor(private sendReceiveService: SendReceiveService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>| Promise<any>|any {
        return this.sendReceiveService.GetOrder(route.paramMap.get('orderReferenceId'));
    }
}