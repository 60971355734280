import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AddressService } from '../../services/address-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CountryService } from '../../services/country.service';
import { Country } from '../../../account/entities/Country';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHelper } from '../../helpers/error-helper';

@Component({
  selector: 'app-create-new-address',
  templateUrl: './create-new-address.component.html',
  styleUrls: ['./create-new-address.component.scss']
})
export class CreateNewAddressComponent implements OnInit {

  @Input()
  sendReceiveForm: FormGroup;

  @Input()
  postcodes: any;

  @Input()
  newAddressBookEntry: boolean;

  countries: Array<Country>;

  public postcodeSelected: any;

  public fromFilteredPostcodes: any;

  public isFO: boolean = false;

  @Output()
  successfullySaved: EventEmitter<boolean> = new EventEmitter();

  public addToFavourite: boolean = false;

  constructor(private newAddressService: AddressService, private snackbar: MatSnackBar, private countryService: CountryService, private translate: TranslateService, private errorHelper: ErrorHelper) { }

  ngOnInit(): void {
    this.countryService.getAll().subscribe(x => {
      this.countries = x;
      this.countries.sort(function (x, y) { return x.countryCode == "FO" ? -1 : y.countryCode == "FO" ? 1 : 0; });
      this.countries.sort(function (x, y) { return x.countryCode == "DK" ? -1 : y.countryCode == "DK" ? 1 : 0; });
    });

    this.newAddressService.getPostcodes().subscribe((x) => {
      this.postcodes = x
    });

    this.sendReceiveForm.get("toCountry").valueChanges.subscribe(
      value => {
        if (this.isFO == false && value.countryCode == "FO") {
          this.isFO = true;
        }
        else {
          this.isFO = false;
        }
      }
    );

    if (this.newAddressBookEntry) {
      this.sendReceiveForm.get("toTitle").reset();
      this.sendReceiveForm.get("toFirstName").reset();
      this.sendReceiveForm.get("toLastName").reset();
      this.sendReceiveForm.get("toOptionalCompany").reset();
      this.sendReceiveForm.get("toAddressLine1").reset();
      this.sendReceiveForm.get("toAddressLine2").reset();
      this.sendReceiveForm.get("toTownCity").reset();
      this.sendReceiveForm.get("toPostcode").reset();
      this.sendReceiveForm.get("toContactNumber").reset();
      this.sendReceiveForm.get("toEmail").reset();
      this.sendReceiveForm.get("toSpecialInstructions").reset();
    }
  }

  public hasErrors(field) {
    if (!!this.sendReceiveForm.get(field)) {
      var errors = this.sendReceiveForm.get(field).errors;
      return !!errors;
    }
    return null;
  }

  public getError(field) {
    var errors = this.sendReceiveForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public setFavourite() {
    this.addToFavourite = !this.addToFavourite;
  }

  public saveEntryToAddressBook() {
    this.sendReceiveForm.markAllAsTouched();
    if (this.sendReceiveForm.valid) {
      var formCountryValue: Country = this.sendReceiveForm.get("toCountry").value;
      const selectedCountry = this.countries.find(x => x.countryNameEN === formCountryValue.countryNameEN)
      if (!selectedCountry) {
        return;
      }

      const newAddress = {
        title: this.sendReceiveForm.get("toTitle").value,
        firstName: this.sendReceiveForm.get("toFirstName").value,
        lastName: this.sendReceiveForm.get("toLastName").value,
        companyName: this.sendReceiveForm.get("toOptionalCompany").value,
        addressLine1: this.sendReceiveForm.get("toAddressLine1").value,
        addressLine2: this.sendReceiveForm.get("toAddressLine2").value,
        townCity: this.sendReceiveForm.get("toTownCity").value,
        postcode: this.sendReceiveForm.get("toPostcode").value,
        countryCode: selectedCountry.countryCode,
        contactNumber: this.sendReceiveForm.get("toContactNumber").value,
        contactEmail: this.sendReceiveForm.get("toEmail").value,
        specialInstructions: this.sendReceiveForm.get("toSpecialInstructions").value,
        isFavourite: this.addToFavourite
      };

      this.newAddressService.saveToAddressBook(newAddress).subscribe(
        () => {
          // this.snackbar.open("Successfully saved address");
          this.successfullySaved.emit(true);
        }, (error) =>
        console.log(error));
    }
  }

  public formTouchedAndNoCountrySelected() {
    if (this.sendReceiveForm.get("toCountry").touched && !this.sendReceiveForm.get("toCountry").value) {
      return true;
    }
    return false;
  }

  public getCountryNameForLanguage(country: Country): string {
    switch (this.translate.currentLang) {
      case "en":
        return country.countryNameEN;
      case "fo":
        return country.countryNameFO;
    }
  }

  public setFromSelectedPostcode(postcode) {
    this.postcodeSelected = postcode;
    // this.sendReceiveForm.get("fromPostcode").setValue(postcode.postcode);
  }
  public setSelectedPostcodeFO(event) {
    var sourceControlName = event.source.ngControl.name;
    if (sourceControlName == "toTownCity") {
      var selectedPostcode = this.postcodes.find(pc => pc.city == event.value)
      this.sendReceiveForm.get("toPostcode").setValue(selectedPostcode.postcode);
    }
    else if (sourceControlName == "toPostcode") {
      var selectedPostcode = this.postcodes.find(pc => pc.postcode == event.value)
      this.sendReceiveForm.get("toTownCity").setValue(selectedPostcode.city);
    }
  }
}
