<div class="container header-container">
    <h1 class="new-order-title">{{'view_waybill.order' | translate}}&nbsp;</h1>
    <div class="print-label-button-container">
        <!-- <button *ngIf="!!order.orderReferenceOther && isDomestic()" class="print-label-button primary-button"
            [disabled]="downloadingPrintLabel" (click)="downloadPrintLabel()"
            mat-button>{{'send_receive.send_receive.confirmation.print_label' | translate}}</button>
        <button *ngIf="order.isCDSFiled && !!order.barcode" class="print-label-button primary-button"
            [disabled]="downloadingCDS" (click)="downloadCDS()"
            mat-button>{{'send_receive.send_receive.confirmation.download_cds' | translate}}</button> -->
    </div>
</div>
<div class="container parent-container">
    <div class="row-container">
        <div class="row">
            <div *ngIf="!!userDetails" class="col-lg-3 col-md-6 col-12">
                <h2 class="header">{{'view_waybill.your_details' | translate}}</h2>
                <p>{{userDetails.title}} {{userDetails.firstName}} {{userDetails.lastName}}</p>
                <p>{{userDetails.addressLine1}}</p>
                <!-- <p>{{userDetails.addressLine2}}</p> -->
                <p>{{userDetails.townCity}}</p>
                <p>{{userDetails.postcode}}</p>
                <p>{{getCountryName()}}</p>
                <span>{{userDetails.contactNumber}}</span>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <!-- Section -->
                <h2 class="header">{{'view_waybill.incoTerms' | translate}}</h2>
                <div style="padding-bottom: 30px">
                    <p>{{waybill.incoTerms}}</p>
                </div>
                <h2 class="header">{{'view_waybill.deliveryTerms' | translate}}</h2>
                <div style="padding-bottom: 30px">
                    <p>{{waybill.deliveryTerms}}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <h2 class="header">{{'view_waybill.mawb' | translate}}</h2>
                <div style="padding-bottom: 30px">
                    <p>{{waybill.mawb}}</p>
                </div>
                <h2 class="header">{{'view_waybill.quoteNo' | translate}}</h2>
                <div style="padding-bottom: 30px">
                    <p>{{waybill.quoteNo}}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <!-- Section -->
            </div>
        </div>
    </div>
    <div class="row-container">
        <div class="row">
            <div class="col-12">
                <h2 class="header">{{'view_waybill.lines' | translate}}</h2>
            </div>
            <div *ngFor="let line of waybill.shipmentLines" class="col-lg-3 col-md-6 col-12">
                <strong>{{'view_waybill.package_type' | translate}}</strong>
                <p>{{line.packageType}}</p>
                <strong>{{'view_waybill.volume' | translate}}</strong>
                <p>{{line.volume | numberLocalization }}m³</p>
                <strong>{{'view_waybill.weight' | translate}}</strong>
                <p>{{line.weight | numberLocalization}}kg</p>
            </div>
        </div>
    </div>
    <div class="row-container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
                <h2 class="header">{{'view_waybill.from' | translate}}</h2>
                <p>{{waybill.sender.name}}</p>
                <p>{{waybill.sender.addressLine1}}</p>
                <p>{{waybill.sender.city}}</p>
                <p>{{waybill.sender.postCode}}</p>
                <p>{{waybill.sender.countryName}}</p>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <h2 class="header">{{'view_waybill.to' | translate}}</h2>
                <p>{{waybill.receiver.name}}</p>
                <p>{{waybill.receiver.addressLine1}}</p>
                <p>{{waybill.receiver.city}}</p>
                <p>{{waybill.receiver.postCode}}</p>
                <p>{{waybill.receiver.countryName}}</p>
            </div>
        </div>
    </div>
    <div class="row-container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-12">
                <div>
                    <div *ngIf="!!waybill.shipmentDocuments">
                        <h2>{{'view_waybill.documents' | translate}}</h2>
                    </div>
                </div>
                <div *ngIf="!!waybill.shipmentDocuments">
                    <div *ngFor="let file of waybill.shipmentDocuments">
                        <div class="row" style="padding-bottom: 10px;">
                            <div class="col-lg-9 col-md-9 align-center">
                                <span>{{file.fileName}}</span>
                            </div>
                            <div class="col-lg-3 col-md-3 align-center">
                                <button class="primary-button border-white download-button" (click)="downloadDocument(file)">
                                    <div class="spinner">
                                        <span>{{'waybill.download' | translate}}</span>
                                        <mat-spinner *ngIf="file.isDownloading" class="spinner-object" diameter="15">
                                        </mat-spinner>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>