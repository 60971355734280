import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SamleikinCredentials } from '../../entities/samleikinCredentials';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-samleikin-migrate',
  templateUrl: './samleikin-migrate.component.html',
  styleUrls: ['./samleikin-migrate.component.scss']
})
export class SamleikinMigrateComponent implements OnInit {

  displayLoginFailed: boolean = false;

  displayLoginSuccessful: boolean = false;

  preAuthGuid: string;

  loading: boolean = true;

  constructor(private authService: AuthenticationService, private userService: UserService, private router: Router, private translateService: TranslateService) { }

  ngOnInit(): void {

    this.authService.getSessionData().subscribe(x => {

      if (!!x && !!x.attributes) {
        var idNum = x.attributes.find(y => y.name == "personalIdentityNumber");
        var firstName = x.attributes.find(y => y.name == "givenName");
        var lastName = x.attributes.find(y => y.name == "sn");

        if (!!idNum && !!firstName && !!lastName) {

          this.userService.migrateToSamleikin(idNum.values[0]).subscribe(x => {
            this.loading = false;

            this.router.navigate([`${this.translateService.currentLang}/private/home`]);
          }, (error) => {
            this.loading = false;
            this.displayLoginFailed = true;
          })
        }
      }
      else {
        this.loading = false;
        this.displayLoginFailed = true;
      }

    }, (error) => {
      this.loading = false;
      this.displayLoginFailed = true;
    });
  }

}
