import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Country } from '../../account/entities/Country';
import { environment } from '../../../environments/environment';
import { GenericOrder } from '../../shared/entities/genericOrder';

@Injectable({
    providedIn: 'root'
})
export class OrdersService {

    countries: Array<string> = new Array<string>();

    constructor(private httpClient: HttpClient) {
    }

    public getOrderList(query?: string): Observable<Array<GenericOrder>> {
        return this.httpClient.get(`${environment.apiUrl}/api/user/orders${query}`) as Observable<Array<GenericOrder>>;
    }
}