import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../account/entities/user';
import { RoleService } from '../../account/services/role.service';
import { UserService } from '../../account/services/user.service';
import { PaymentService } from '../../send-receive/services/payment.service';
import { TranslationHelper } from '../../shared/helpers/translation-helper';
import { ServiceType } from '../../shared/price-matrix/enums/ServiceType';
import { CCSDocument } from '../entities/CCSDocument';
import { CCSInvoice } from '../entities/CCSInvoice';
import { CCSSubmission } from '../entities/CCSSubmission';
import { CCSService } from '../services/ccs.service';

@Component({
  selector: 'app-ccsdetail',
  templateUrl: './ccsdetail.component.html',
  styleUrls: ['./ccsdetail.component.scss']
})
export class CCSDetailComponent implements OnInit {

  @ViewChild("fileUpload", {})
  fileUpload: ElementRef;

  public userDetails: User

  public ccsSubmission: CCSSubmission;
  public ccsInvoice: CCSInvoice;

  downloadingPrintLabel: boolean = false;
  downloadingCDS: boolean = false;
  countryName: string;

  iframeURL: any;
  intervalSet: boolean = false;
  private stopPolling = new Subject();
  public paymentSuccess: boolean = false;
  showPaymentScreen: boolean = false;
  showPaymentFailed: boolean = false;

  invoiceError: string;
  downloadingInvoice: boolean = false;
  downloadingReceipt: boolean = false;
  paymentCancelledOrFailed: boolean = false;

  constructor(private userAccountService: UserService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router,
    private roleService: RoleService,
    private translationHelper: TranslationHelper,
    private _sanitizer: DomSanitizer,
    private ccsService: CCSService) { }

  ngOnInit(): void {
    this.ccsSubmission = this.route.snapshot.data.ccsSubmission;
    this.ccsService.getCCSInvoice(this.ccsSubmission.ccsId, this.ccsSubmission.wisePONumber).subscribe(x => {
      this.ccsSubmission.customsAmount = x.totalAmount;
      this.ccsInvoice = x;
      //this.ccsSubmission.invoiceLines = x.invoiceLines;
    })
    this.userAccountService.getUserDetails().subscribe(x => {
      this.userDetails = x;
      this.getCountryName();
    });
  }

  public getCountryName() {
    switch (this.translateService.currentLang) {
      case "en":
        this.countryName = `${this.userDetails.country.countryNameEN}`;
        break;
      case "fo":
        this.countryName = `${this.userDetails.country.countryNameFO}`;
        break;
    }
  }

  public downloadDocument(file: CCSDocument){
    file.isDownloading = true;
    this.ccsService.downloadDocument(file.documentId).subscribe(x => {
      const byteArray = new Uint8Array(atob(x).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = file.filename;
      link.click();
      file.isDownloading = false;
    }, (error) => {
      file.isDownloading = false;
    })
  }

  public uploadClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          //Upload file
          this.ccsService.uploadDocument(this.ccsSubmission.ccsId, file.name, (reader.result as string).split(',')[1]).subscribe(x => {
            this.ccsSubmission.documentList.push(x);
          }, (error) => {

          })
        };
      }
    };
    fileUpload.click();
  }

  public pay(){
    this.ccsService.getCCSInvoice(this.ccsSubmission.ccsId, this.ccsSubmission.wisePONumber).subscribe(x => {
      if(x.totalAmount > 0){
        this.showPaymentScreen = true;
        var endpoint = `${environment.dibsUrl}/payment/WebPay?locale=${this.translateService.currentLang}&systemType=5&serviceType=${ServiceType.Customs}&orderId=${this.ccsSubmission.ccsId}&amount=${x.totalAmount}`;
        this.paymentService.dibsPay(endpoint).subscribe( y => {
          this.iframeURL = this._sanitizer.bypassSecurityTrustResourceUrl(y)
          this.onFrameLoad();
        })
      }
    })
    
  }

  public onFrameLoad() {
    if (!this.intervalSet) {
      this.intervalSet = true;
      interval(5000).pipe(
        startWith(0),
        //JT 2023 - CCS id is passed here to resolve an issue, despite passing PO number elsewhere
        switchMap(() => this.paymentService.getReceipt(ServiceType.Customs, this.ccsSubmission.ccsId)),
        takeUntil(this.stopPolling)).subscribe(x => {
          if (!!x)
          {
            if (!!x && !!x.paymentRequestHeader && (x.paymentRequestHeader.isPaid || x.paymentRequestHeader.isPaidButUpdateFailed)) {
              this.stopPolling.next();
              document.getElementById("paymentFrame").remove();
              this.paymentSuccess = true;
              return;
            }
            if (x.paymentRequestHeader.attemptedPaymentYN)
            {
              this.stopPolling.next();
              document.getElementById("paymentFrame").remove();
              this.paymentSuccess = true;
              this.paymentCancelledOrFailed = true;
              return;
            }
          }
        }), (err) => { 
          this.showPaymentScreen = false;
          document.getElementById("paymentFrame").remove();
          console.log("Error getting order after payment: " + err) 
        };
    }
  }

  public downloadInvoice()
  {
    this.invoiceError = undefined;
    this.downloadingInvoice = true;
    this.paymentService.downloadInvoice(ServiceType.Customs, this.ccsSubmission.wisePONumber, " ").subscribe(x => {

      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingInvoice = false;
    }, (y) => {
      this.downloadingInvoice = false;
      this.invoiceError = y.error;
    })
  }

  public downloadReceipt() {
    this.downloadingReceipt = true;
    //JT 2023 - CCS id is passed here to resolve an issue, despite passing PO number elsewhere
    this.paymentService.downloadReceipt(ServiceType.Customs, this.ccsSubmission.ccsId, " ", this.translateService.currentLang).subscribe(x => {

      const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      var link = document.createElement('a');
      link.href = url;
      link.download = x.fileName;
      link.click();
      this.downloadingReceipt = false;
    }, (error) => {
      this.downloadingReceipt = false;
    })
  }

}
