<div class="parent-container">
    <!-- <div class="specification-container note-container">
        <div class="note-text">{{'send_receive.send_receive.step_one.info' | translate}}</div>
    </div> -->
    <div class="parent-container">
        <div class="specification-container main-body-container">
            <div class="row full-width">
                <div class="swap-button-container">
                    <button class="swap-button" (click)="swapFromTo()">
                    </button>
                </div>
            </div>
            <div class="row full-width">
                <div class="col-lg-6">
                    <div class="border-bottom">
                        <h2>{{'send_receive.send_receive.step_one.from' | translate}}</h2>

                        <div class="from-options-container">

                            <div class="row field-row">
                                <div>
                                    <label>
                                        <span class="radio-button align-center">
                                            <input type="radio" name="from-address-option"
                                                (change)="onSelectFromAccountAddress($event)"
                                                value="fromAccount" [(ngModel)]="fromOption" [disabled]="fromAccountDisabled">
                                        </span>
                                        <span class="checkmark"></span>
                                        <h4 class="regular-font-weight">{{'send_receive.send_receive.step_one.account_address.account_address' | translate}}</h4>
                                    </label>
                                </div>
                            </div>

                            <div class="row field-row">
                                <label>
                                    <span class="radio-button align-center">
                                        <input type="radio" name="from-address-option"
                                            (change)="onSelectFromNewAddress($event)" value="fromNewAddress" [(ngModel)]="fromOption">
                                    </span>
                                    <span class="checkmark"></span>
                                    <h4 class="regular-font-weight">{{'send_receive.send_receive.step_one.new_or_from_address_book' | translate}}</h4>
                                </label>
                            </div>

                            <div class="row field-row">
                                <div>
                                    <label>
                                        <span class="radio-button align-center">
                                            <input type="radio" name="from-address-option"
                                                (change)="onSelectFromDepot($event)" value="fromDepot" [(ngModel)]="fromOption"
                                                [disabled]="fromDepotDisabled">
                                        </span>
                                        <span class="checkmark"></span>
                                        <h4 class="regular-font-weight">{{'send_receive.send_receive.step_one.depot' | translate}}</h4>
                                        <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                                            <a tabindex="0"></a><span>{{'send_receive.send_receive.step_one.depot_tooltip' | translate}}</span></label>
                                    </label>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                    <div *ngIf="fromAccountAddressChecked">
                        <app-from-account-address [toFromAddressform]="sendReceiveForm" [currentUser]="currentUser">
                        </app-from-account-address>
                    </div>
                    <div *ngIf="fromDepotAddressChecked">
                        <app-posta-depot-address [toFromAddressform]="sendReceiveForm" [isSender]="true"></app-posta-depot-address>
                    </div>
                    <div *ngIf="fromNewAddressChecked">
                        <app-from-new-address [sendReceiveForm]="sendReceiveForm" [specificationForm]="specificationForm" [order]="order" [postcodes]="postcodes"></app-from-new-address>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="border-bottom">
                        <h2 class="margin-top-mobile">{{'send_receive.send_receive.step_one.to' | translate}}</h2>

                        <div class="from-options-container">

                            <div class="row field-row">
                                <div>
                                    <label>
                                        <span class="radio-button align-center">
                                            <input type="radio" name="to-address-option"
                                                (change)="onSelectToAccountAddress($event)"
                                                value="toAccount" [(ngModel)]="toOption" [disabled]="toAccountDisabled">
                                        </span>
                                        <span class="checkmark"></span>
                                        <h4 class="regular-font-weight">{{'send_receive.send_receive.step_one.account_address.account_address' | translate}}</h4>
                                    </label>
                                </div>
                            </div>

                            <div class="row field-row">
                                <label>
                                    <span class="radio-button align-center">
                                        <input type="radio" name="to-address-option"
                                            (change)="onSelectToNewAddress($event)" value="toNewAddress" [(ngModel)]="toOption">
                                    </span>
                                    <span class="checkmark"></span>
                                    <h4 class="regular-font-weight">{{'send_receive.send_receive.step_one.new_or_from_address_book' | translate}}</h4>
                                </label>
                            </div>

                            <div class="row field-row">
                                <div>
                                    <label>
                                        <span class="radio-button align-center">
                                            <input type="radio" name="to-address-option"
                                                (change)="onSelectToDepot($event)" value="toDepot" [(ngModel)]="toOption"
                                                [disabled]="toDepotDisabled">
                                        </span>
                                        <span class="checkmark"></span>
                                        <h4 class="regular-font-weight">{{'send_receive.send_receive.step_one.depot' | translate}}</h4>
                                        <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
                                            <a tabindex="0"></a><span>{{'send_receive.send_receive.step_one.depot_tooltip' | translate}}</span></label>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="toNewAddressChecked" style="padding-left: 0">
                        <app-to-new-address [sendReceiveForm]="sendReceiveForm" [order]="order" [postcodes]="postcodes"></app-to-new-address>
                    </div>
                    <div *ngIf="toAccountAddressChecked">
                        <app-to-account-address [toFromAddressform]="sendReceiveForm" [currentUser]="currentUser">
                        </app-to-account-address>
                    </div>
                    <div *ngIf="toDepotAddressChecked">
                        <app-posta-depot-address [toFromAddressform]="sendReceiveForm"></app-posta-depot-address>
                    </div>
                </div>
            </div>
        </div>

        <div class="specification-container">
            <div class="row full-width">
                <app-error-message style="width: 100%;" *ngIf="showNoSelectionError" errorMessage="{{'send_receive.send_receive.step_one.no_addresses_selected' | translate}}"></app-error-message>
            </div>

            <div *ngIf="notSelectedCountry()" class="full-width">
                <div class="col-12" *ngIf="!!addressesFooter" [innerHtml]="addressesFooter | safeHtml"></div>
            </div>

            <div class="row button-container full-width">
                <div class="col-lg-6 col-md-6 col-sm-4">
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6 button-padding left-button">
                    <button class="tertiary-button button-width" mat-button (click)="clearAll()">
                        <div class="full-width">
                            <span class="button-text">{{'send_receive.send_receive.step_one.clear_all' | translate}}</span>
                            <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
                        </div>
                    </button>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-4 col-6 button-width right-button">
                    <button mat-button class="primary-button button-width"
                        (click)="markRequiredFieldsAsTouchedAndProceed()">{{'send_receive.send_receive.step_one.proceed' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>