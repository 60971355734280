<div *ngIf="isSender" class="row senderNameRow">
    <div class="col-lg-auto col-sm-auto align-center field-title-width weight-title-container">
        <h3 class="primary">{{'send_receive.send_receive.step_one.sender_name' | translate}}</h3>
        <label class="tooltipSource"><img class="information-icon" src="/assets/icons/i_information@3x.png" />
            <a tabindex="0"></a><span>{{'send_receive.send_receive.step_one.sender_name_tooltip' | translate}}</span></label>
    </div>
    <div class="col-lg col-sm">
        <mat-form-field class="no-error-wrap float-never" floatLabel="never" style="width: 100%;"
            appearance="outline" [formGroup]="toFromAddressform">
            <input matInput placeholder="{{'send_receive.send_receive.step_one.sender_name_placeholder' | translate}}" id="logisticsSenderName" name="logisticsSenderName"
                formControlName="logisticsSenderName" required>
            <mat-error *ngIf="hasErrors('logisticsSenderName')">
                <app-field-error-message errorMessage="{{getError('logisticsSenderName')}}">
                </app-field-error-message>
            </mat-error>
        </mat-form-field>
    </div>
</div>
<div class="row option-container">
    <div class="col-lg-12">
        <h3>{{'send_receive.send_receive.step_one.from_to_depot_header' | translate}}</h3>
        <ul>
            <li>{{'send_receive.send_receive.step_one.from_to_depot_description_one' | translate}}</li>
            <li>{{'send_receive.send_receive.step_one.from_to_depot_description_two' | translate}}</li>
        </ul>
        <div>{{'send_receive.send_receive.step_one.from_to_depot_nb' | translate}}</div>
    </div>
</div>