import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePersonalComponent } from './home/home-personal/home-personal.component';
import { StaticContentPageComponent } from './shared/static-content/static-content-page.component';
import { HomePageGuard } from './shared/guards/homepage.guard';


const routes: Routes = [
      { path: '', component: HomePersonalComponent, canActivate: [HomePageGuard] },
      { path: ':lang/:role/home', component: HomePersonalComponent },

      { path: 'business',   redirectTo: '/en/business/home', pathMatch: 'full' },
      { path: 'vinna',   redirectTo: '/fo/business/home', pathMatch: 'full' },
      { path: 'privat',   redirectTo: '/fo/private/home', pathMatch: 'full' },
      { path: 'pakkar',   redirectTo: '/fo/private/help-information/pakkar', pathMatch: 'full' },
      
      { path: '**', component: StaticContentPageComponent },


      // resolve: { staticContent: StaticContentResolver }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
