<div class="to-option-container">
    <div class="col-lg-12 col-md-12">
        <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput id="toTitle" placeholder="Title" name="toTitle"
                    formControlName="toTitle">
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-5  col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput id="toFirstName" placeholder="*First Name" name="toFirstName"
                    formControlName="toFirstName" required>
                    <mat-error *ngIf="hasErrors('toFirstName')"><app-field-error-message errorMessage="{{getError('toFirstName')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-5 col-md-7" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="*Last Name" id="toLastName" name="toLastName"
                    formControlName="toLastName" required>
                    <mat-error *ngIf="hasErrors('toLastName')"><app-field-error-message errorMessage="{{getError('toLastName')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="to-option-container">
    <div class="col-lg-12 col-md-12 form-field-vertical-spacing">

        <div class="row">

            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="Optional company name" id="toOptionalCompany"
                    name="toOptionalCompany" formControlName="toOptionalCompany">
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="*Address line 1" id="toAddressLine1"
                    name="toAddressLine1" formControlName="toAddressLine1" required>
                    <mat-error *ngIf="hasErrors('toAddressLine1')"><app-field-error-message errorMessage="{{getError('toAddressLine1')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <!-- <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="Address line 2" id="toAddressLine2"
                name="toAddressLine2" formControlName="toAddressLine2">
            </mat-form-field>
        </div> -->

        <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="*Town/City" id="toTownCity" name="toTownCity"
                    formControlName="toTownCity" required>
                    <mat-error *ngIf="hasErrors('toTownCity')"><app-field-error-message errorMessage="{{getError('toTownCity')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="*Postcode" id="toPostcode" name="toPostcode"
                    formControlName="toPostcode" required>
                    <mat-error *ngIf="hasErrors('toPostcode')"><app-field-error-message errorMessage="{{getError('toPostcode')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="mobile-padding col-lg-5 col-md-7 form-field-vertical-spacing" floatLabel="never" appearance="outline"
            [formGroup]="sendReceiveForm">
            <mat-select placeholder="Select country" disableOptionCentering panelClass="select-panel" formControlName="toCountry" required>
                <mat-option *ngFor="let country of countries" [value]="setCountryByCode(country)">{{getCountryNameForLanguage(country)}}</mat-option>
            </mat-select>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="Contact number" id="toContactNumber"
                    name="toContactNumber" formControlName="toContactNumber">
                    <mat-error *ngIf="hasErrors('toContactNumber')"><app-field-error-message errorMessage="{{getError('toContactNumber')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.email' | translate}}" id="toEmail"
                    name="toEmail" formControlName="toEmail">
                    <mat-error *ngIf="hasErrors('toEmail')"><app-field-error-message errorMessage="{{getError('toEmail')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-7 form-field-vertical-spacing">
                <mat-form-field floatLabel="never" [formGroup]="sendReceiveForm" class="special-instructions-container">
                    <textarea rows="5" matInput placeholder="Special instructions" id="toSpecialInstructions"
                        name="toSpecialInstructions" formControlName="toSpecialInstructions"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <button class="primary-button padded save-button" (click)="updateEntryToAddressBook()">Save to address
                book</button>
            <button class="tertiary-button padded delete-button" (click)="deleteAddress()">
                <div class="button-width">
                    <span class="button-text">Delete</span>
                    <div class="float-right"><img class="clear-all-icon"
                            src="/assets/icons/arrows/close_button_icon@3x.png" /></div>

                </div>
            </button>
            <div class="col-lg-5  col-md-7 align-center">
                <button class="transparent" (click)="setFavourite()">
                    <img *ngIf="addToFavourite" class="favourite-icon"
                        src="/assets/icons/address_book/favourite_selected@3x.png" />
                    <img *ngIf="!addToFavourite" class="favourite-icon"
                        src="/assets/icons/address_book/favourite@3x.png" />
                </button>
                <span class="checkbox-text-padding">Add to favourites</span>
            </div>
        </div>

        <!-- <div>
            <div class="row" style="padding-left: 15px">
                <button class="primary-button padded save-button" (click)="updateEntryToAddressBook()">Save to address
                    book</button>
                <button class="primary-button padded delete-button" (click)="deleteAddress()">Delete</button>
            </div>
        </div> -->
        <div class="row field-row">
            <div class="col-lg-12 col-md-12">
                Address data will only be saved for your personal use view
                <a href="">privacy policy</a>
            </div>
        </div>
    </div>
</div>