import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from '../../send-receive/services/payment.service';
import { ErrorHelper } from '../../shared/helpers/error-helper';
import { StaticContentHelper } from '../../shared/helpers/static-content-helper';
import { StaticContentService } from '../../shared/services/static-content.service';
import { CustomsRecord } from '../entities/CustomsRecord';

@Component({
  selector: 'app-upload-missing-documentation',
  templateUrl: './upload-missing-documentation.component.html',
  styleUrls: ['./upload-missing-documentation.component.scss']
})
export class UploadMissingDocumentationComponent implements OnInit, AfterViewInit {

  @ViewChild('stepper') stepper: MatStepper;

  public customsFormGroup: FormGroup;

  public uploadedDocumentsFormGroup: FormGroup;

  public paymentFormGroup: FormGroup;

  public customsRetrieved: boolean = false;
  
  public showUploadDocumentScreen: boolean = false;

  public transactionRef: string;

  public failedToGetOrder: boolean = false;

  public loading: boolean = false;

  public loadingProceedToPayment: boolean = false;

  public orderValidated: boolean;

  public showConfirmationScreen: boolean = false;

  public pageTitle: string;

  public pageModules: string;

  public image: string;

  interval : any;
  observer: MutationObserver;

  constructor(private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private errorHelper: ErrorHelper,
    private staticContentService: StaticContentService,
    private translateService: TranslateService,
    private elRef: ElementRef) { }

  ngOnInit(): void {

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        this.image = this.staticContentService.getImageForPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
    })

    this.orderValidated = this.route.snapshot.data.validOrder;

    if (!!this.orderValidated && this.orderValidated)
    {
      this.showUploadDocumentScreen = true;
    }

    this.customsFormGroup = this.formBuilder.group({
      invoicePoNumber: ['', Validators.required],
      refCode: ['', Validators.required],
    });

    this.uploadedDocumentsFormGroup = this.formBuilder.group({
      uploadedFiles: ['', Validators.required],
    })

    this.paymentFormGroup = this.formBuilder.group({
      hasPaid: ['', Validators.required],
    })

    this.customsFormGroup.get("invoicePoNumber").valueChanges.subscribe(x => {
      this.customsRetrieved = false;
    });

    this.customsFormGroup.get("refCode").valueChanges.subscribe(x => {
      this.customsRetrieved = false;
    });

    this.route.params.subscribe(params => {
      console.log(params['orderId']);
      console.log(params['refCode']);
      if (!!params['orderId'])
      {
        this.customsFormGroup.get("invoicePoNumber").setValue(params['orderId']);
      }
      if (!!params['refCode'])
      {
        this.customsFormGroup.get("refCode").setValue(params['refCode']);
      }
    })
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));                               
      });   
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }


  public getPageTitle()
  {
    return this.staticContentService.getPageTitle();
  }

  public hasErrors(field){
    var errors = this.customsFormGroup.get(field).errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.customsFormGroup.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public getOrder() {
    if (!this.customsFormGroup.valid)
    {
      this.customsFormGroup.markAllAsTouched();
      this.customsRetrieved = false;
      return;
    }

    this.failedToGetOrder = false;
    this.loading = true;

    this.paymentService.validate(7, this.customsFormGroup.get("invoicePoNumber").value, this.customsFormGroup.get("refCode").value).subscribe(x => {
      this.showUploadDocumentScreen = true;
    },(error) => {
      this.failedToGetOrder = true;
      this.loading = false;
    })
    
  }

  public showConfirmation(event: any)
  {
    this.showConfirmationScreen = true;
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.selectedIndex = 1;
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }

}
