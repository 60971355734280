<div class="to-option-container border-bottom" style="padding-bottom: 0px;">
    <div class="row">
        <div class="col-sm col-12 mobile-padding-right-30">
            <form>
                <mat-form-field style="width: 100%" floatLabel="never" appearance="outline">
                    <input class="text-input" (click)="addressFieldClick()" matInput placeholder="{{'send_receive.send_receive.step_one.search_addresses' | translate}}"
                        [formControl]="fromAddressControl" [matAutocomplete]="auto">
                </mat-form-field>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAddress"
                    (optionSelected)="setFromSelectedAddress($event.option.value)">
                    <mat-option *ngFor="let address of fromFilteredAddresses | async"
                        [value]="address">
                        {{address.firstName}} {{address.lastName}}, {{address.postcode}}
                    </mat-option>
                </mat-autocomplete>
            </form>
        </div>
        <div class="col-sm-auto col-12 mob-only-padding address-book-button-container mobile-padding-right-30">
            <button class="primary-button address-book-button"
                (click)="setFromAddressFields()">{{'send_receive.send_receive.step_one.confirm' | translate}}</button>
        </div>
        <div class="col-12 manage-addresses-link-container">
            <a target="_blank"style="font-weight: 500; color: var(--primary-colour);"  routerLink="../../my-posta/address-book">{{'send_receive.send_receive.step_one.manage_addresses' | translate}}</a>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr>
        </div>
        <div class="col-auto center-vertical">
            <p class="child-center-vertical" style="color: #a5a7a7;">{{'send_receive.send_receive.step_one.or_enter_new' | translate}}</p>
        </div>
        <div class="col">
            <hr>
        </div>
    </div>
    <div class="row" style="display: flex; justify-content: center; align-items: center;">
        <div class="col-12 select-country-container child-center-vertical">
            <h3>{{'send_receive.send_receive.step_one.select_country' | translate}}</h3>
        </div>
        <div class="col-md-auto col-12 child-center-vertical">
            <div>
                <label style="display: flex;">
                    <span class="radio-button align-center">
                        <input type="radio" name="from-fo"
                            (change)="onSelectFromFO($event)"
                            [value]="true" [(ngModel)]="fromFO" [disabled]="fromFODisabled">
                    </span>
                    <span class="checkmark"></span>
                    <h4 class="regular-font-weight">{{'send_receive.send_receive.step_one.faroe_islands' | translate}}</h4>
                </label>
            </div>
        </div>
        <div class="col-auto child-center-vertical">
            <h3>{{'send_receive.send_receive.step_one.or' | translate}}</h3>
        </div>
        <div class="col" style="margin-top: 28px;" [formGroup]="sendReceiveForm">
            <mat-form-field class="select-container float-never" floatLabel="never" appearance="outline" [formGroup]="sendReceiveForm">
                <mat-label>{{'address_book.address_details.country' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="select-panel" (selectionChange)="updateSpecificationForm($event)" formControlName="fromCountry" required>
                    <!-- <input (keyup)="onKey($event.target.value)"> -->
                    <mat-option *ngFor="let country of countries" [value]="setCountryByCode(country)">{{getCountryNameForLanguage(country)}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasErrors('fromCountry')"><app-field-error-message errorMessage="{{getError('fromCountry')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
<div *ngIf="hasSelectedCountry()" class="to-option-container border-bottom">
    <!-- <div class="col-lg-12 col-md-6 no-padding-left"> -->
        <!-- <div class="row">
            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput id="fromTitle" placeholder="{{'address_book.address_details.title' | translate}}" name="fromTitle"
                    formControlName="fromTitle">
                    <mat-error *ngIf="hasErrors('fromTitle')"><app-field-error-message errorMessage="{{getError('fromTitle')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div> -->

        <div class="row">
            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput id="fromFirstName" placeholder="{{'address_book.address_details.first_name' | translate}}" name="fromFirstName"
                    formControlName="fromFirstName" required>
                    <mat-error *ngIf="hasErrors('fromFirstName')"><app-field-error-message errorMessage="{{getError('fromFirstName')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-12 col-md-6" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.last_name' | translate}}" id="fromLastName" name="fromLastName"
                    formControlName="fromLastName" required>
                    <mat-error *ngIf="hasErrors('fromLastName')"><app-field-error-message errorMessage="{{getError('fromLastName')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>
    <!-- </div> -->
</div>
<div *ngIf="hasSelectedCountry()" class="to-option-container border-bottom">
    <!-- <div class="col-lg-12 col-md-12 no-padding-left"> -->

        <div class="row">

            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.company_name' | translate}}" id="fromOptionalCompany"
                    name="fromOptionalCompany" formControlName="fromOptionalCompany">
                    <mat-error *ngIf="hasErrors('fromOptionalCompany')"><app-field-error-message errorMessage="{{getError('fromOptionalCompany')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.address_line_1' | translate}}" id="fromAddressLine1"
                    name="fromAddressLine1" formControlName="fromAddressLine1" required>
                    <mat-error *ngIf="hasErrors('fromAddressLine1')"><app-field-error-message errorMessage="{{getError('fromAddressLine1')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <!-- <div class="row">
            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.address_line_2' | translate}}" id="fromAddressLine2"
                    name="fromAddressLine2" formControlName="fromAddressLine2">
                    <mat-error *ngIf="hasErrors('fromAddressLine2')"><app-field-error-message errorMessage="{{getError('fromAddressLine2')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div> -->

        <div *ngIf="fromFO" class="row">
            <mat-form-field class="col-lg-4 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <mat-select disableOptionCentering panelClass="select-panel" (selectionChange)="setSelectedPostcodeFO($event)" formControlName="fromPostcode" id="fromPostcode" name="fromPostcode" required
                    placeholder="{{'address_book.address_details.postcode' | translate}}">
                    <mat-option *ngFor="let postcode of postcodes" [value]="postcode.postcode">{{postcode.postcode}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasErrors('fromPostcode')"><app-field-error-message></app-field-error-message></mat-error>
            </mat-form-field>
            <mat-form-field class="col-lg-8 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <mat-select disableOptionCentering panelClass="select-panel" (selectionChange)="setSelectedPostcodeFO($event)" formControlName="fromTownCity" id="fromTownCity" name="fromTownCity" required
                placeholder="{{'address_book.address_details.town_city' | translate}}">
                    <mat-option *ngFor="let postcode of postcodes" [value]="postcode.city">{{postcode.city}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasErrors('fromTownCity')"><app-field-error-message errorMessage="{{getError('fromTownCity')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="!fromFO" class="row">
            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.town_city' | translate}}" id="fromoTownCity" name="fromTownCity"
                    formControlName="fromTownCity" required>
                    <mat-error *ngIf="hasErrors('fromTownCity')"><app-field-error-message errorMessage="{{getError('fromTownCity')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="!fromFO" class="row">
            <form class="col-lg-12 col-md-6 form-field-with-error-spacing">
                <mat-form-field style="width: 100%" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                    <input class="text-input" matInput placeholder="{{'address_book.address_details.postcode' | translate}}" id="fromPostcode" name="fromPostcode"
                        formControlName="fromPostcode" required>
                        <mat-error *ngIf="hasErrors('fromPostcode')"><app-field-error-message errorMessage="{{getError('fromPostcode')}}"></app-field-error-message></mat-error>
                </mat-form-field>
            </form>
        </div>

        <!-- <div class="row">
            <div class="col-lg-12 col-md-6" [formGroup]="sendReceiveForm">
                <mat-form-field class="select-container float-never" floatLabel="never" appearance="outline" [formGroup]="sendReceiveForm">
                <mat-label>{{'address_book.address_details.country' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="select-panel" (selectionChange)="updateSpecificationForm($event)" formControlName="fromCountry" required>
                    <mat-option *ngFor="let country of countries" [value]="setCountryByCode(country)">{{getCountryNameForLanguage(country)}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasErrors('fromCountry')"><app-field-error-message errorMessage="{{getError('fromCountry')}}"></app-field-error-message></mat-error>
            </mat-form-field>
            </div>
        </div> -->

        <div class="row">
            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput id="fromContactNumber"
                name="fromContactNumber" formControlName="fromContactNumber" placeholder="{{'address_book.address_details.contact_number' | translate}}" >
                <mat-error *ngIf="hasErrors('fromContactNumber')"><app-field-error-message errorMessage="{{getError('fromContactNumber')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field [formGroup]="sendReceiveForm" class="col-lg-12 col-md-6 form-field-with-error-spacing" floatLabel="never" appearance="outline">
                <input class="text-input" matInput formControlName="fromEmail" placeholder="{{'address_book.address_details.email' | translate}}">
                <mat-error *ngIf="hasErrors('fromEmail')"><app-field-error-message errorMessage="{{getError('fromEmail')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="newAddressBookEntry" class="row">
            <div class="col-lg-12 col-md-12 my-custom-input-label-box">
                <div class="align-center">
                    <input id="from-address-book-input" type="checkbox" name="from-address-book-input" />
                    <label for="from-address-book-input" (click)="toggleSave()"></label>
                    <span class="checkbox-text-padding">{{'address_book.address_details.save_to_address_book' | translate}}</span>
                    <input id="from-favourites-input" type="checkbox" name="from-favourites-input" />
                    <label for="from-favourites-input" (click)="toggleFavourite()"></label>
                    <span class="checkbox-text-padding">{{'address_book.address_details.add_to_favourites' | translate}}</span></div>
            </div>
        </div>
        <div *ngIf="!newAddressBookEntry" class="row center-vertical">
            <div class="col-xl col-lg-12 col-md-12 my-custom-input-label-box child-center-vertical">
                <div class="align-center">
                    <button [disabled]=saveDisabled class="transparent-button" style="margin-right: 20px;" (click)="saveEntryToAddressBook()" >
                        <div style="color: #000000;">
                            <img *ngIf="saveDisabled" class="button-icon" src="/assets/icons/send-and-receive/address_book-onclick.svg" />
                            <img *ngIf="!saveDisabled" class="button-icon" src="/assets/icons/send-and-receive/address_book.svg" />
                            {{'address_book.address_details.save_to_address_book' | translate}}*
                        </div>
                    </button>
                    <button class="transparent-button" (click)="toggleFavourite()" >
                        <div>
                            <img *ngIf="getFavouriteState()" class="button-icon" src="/assets/icons/send-and-receive/favourites_onclick.svg" />
                            <img *ngIf="!getFavouriteState()" class="button-icon" src="/assets/icons/send-and-receive/favourites.svg" />
                            {{'address_book.address_details.add_to_favourites' | translate}}
                        </div>
                    </button>
                    <!-- <input id="from-favourites-input" type="checkbox" name="from-favourites-input" />
                    <label for="from-favourites-input" (click)="toggleFavourite()"></label>
                    <span class="checkbox-text-padding">{{'address_book.address_details.add_to_favourites' | translate}}</span></div> -->
                </div>
            </div>
            <div class="col-lg-12 col-md-6 col-xl-auto child-center-vertical no-center-tablet">
                <button class="primary-button move-to-top-button" style="float: right;" (click)="scrollToTop()">{{'send_receive.send_receive.step_one.scroll_to_top' | translate}}</button>
            </div>
            <div *ngIf="newAddressBookEntry">
                <div class="row" style="padding-left: 15px">
                    <button class="transparent-button padded save-button" (click)="saveEntryToAddressBook()">{{'address_book.address_details.save_to_address_book' | translate}}</button>
                </div>
            </div>
            <div class="col-12 row field-row" style="padding-top: 10px;">
                <div class="col-lg-12 col-md-12">
                    *{{'address_book.address_details.note' | translate}}
                    <a href="">{{'address_book.address_details.privacy_policy' | translate}}</a>
                </div>
                <app-field-error-message *ngIf="saveDisabled" icon="" style="height: 40px;padding-top: 10px;"
                    errorMessage="{{ 'send_receive.address_successfully_saved' | translate }}">
                </app-field-error-message>
            </div>
    <!-- </div> -->
</div>