import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CustomerShipmentDetail } from "../entities/CustomerShipmentDetail";
import { WaybillService } from "./waybill.service";

@Injectable({ providedIn: 'root' })
export class WaybillOrderResolver implements Resolve<CustomerShipmentDetail> {

    constructor (private waybillService: WaybillService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>| Promise<any>|any {
        return this.waybillService.getCustomerShipment(route.paramMap.get('shipmentType'), route.paramMap.get('manifestNo'), route.paramMap.get('waybillNo'));
    }
}