import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../account/services/authentication.service";
import { RoleService } from "../../account/services/role.service";
import { CookieService } from "../services/cookie.service";

@Injectable({ providedIn: 'root' })
export class ProfileCompleteGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AuthenticationService,
        private cookieService: CookieService,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private roleService: RoleService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var userDetails = localStorage.getItem("userDetails");

        if (!!userDetails) {
            var userDetailsJson = JSON.parse(userDetails);
            if (!userDetailsJson.isProfileComplete) {
                this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/my-posta/update-profile`]);
                return false;
            }
        }

        return true;
    }
}