<div class="to-option-container">
    <div class="col-lg-12 col-md-12">
        <!-- <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput id="toTitle" placeholder="{{'address_book.address_details.title' | translate}}" name="toTitle"
                    formControlName="toTitle">
            </mat-form-field>
        </div> -->

        <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput id="toFirstName" placeholder="{{'address_book.address_details.first_name' | translate}}" name="toFirstName"
                    formControlName="toFirstName" required>
                    <mat-error *ngIf="hasErrors('toFirstName')"><app-field-error-message errorMessage="{{getError('toFirstName')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-5 col-md-7" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.last_name' | translate}}" id="toLastName" name="toLastName"
                    formControlName="toLastName" required>
                    <mat-error *ngIf="hasErrors('toLastName')"><app-field-error-message errorMessage="{{getError('toLastName')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="to-option-container">
    <div class="col-lg-12 col-md-12">

        <div class="row">

            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.company_name' | translate}}" id="toOptionalCompany"
                    name="toOptionalCompany" formControlName="toOptionalCompany">
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.address_line_1' | translate}}" id="toAddressLine1"
                    name="toAddressLine1" formControlName="toAddressLine1" required>
                    <mat-error *ngIf="hasErrors('toAddressLine1')"><app-field-error-message errorMessage="{{getError('toAddressLine1')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <!-- <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.address_line_2' | translate}}" id="toAddressLine2"
                name="toAddressLine2" formControlName="toAddressLine2">
            </mat-form-field>
        </div> -->

        <div class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing"
                floatLabel="never" appearance="outline" [formGroup]="sendReceiveForm">
                <mat-select placeholder="{{'address_book.address_details.country' | translate}}" formControlName="toCountry" required>
                    <mat-option *ngFor="let country of countries" [value]="country">{{getCountryNameForLanguage(country)}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasErrors('toCountry')"><app-field-error-message errorMessage="{{getError('toCountry')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="isFO" class="row">
            <mat-form-field class="col-lg-2 col-md-3 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <mat-select panelClass="select-panel" (selectionChange)="setSelectedPostcodeFO($event)" formControlName="toPostcode" id="toPostcode" name="toPostcode" required
                    placeholder="{{'address_book.address_details.postcode' | translate}}">
                    <mat-option *ngFor="let postcode of postcodes" [value]="postcode.postcode">{{postcode.postcode}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasErrors('toPostcode')"><app-field-error-message></app-field-error-message></mat-error>
            </mat-form-field>
            <mat-form-field class="col-lg-3 col-md-4 form-field-with-error-spacing" style="padding-right: 0px !important;" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <mat-select panelClass="select-panel" (selectionChange)="setSelectedPostcodeFO($event)" formControlName="toTownCity" id="toTownCity" name="toTownCity" required
                placeholder="{{'address_book.address_details.town_city' | translate}}">
                    <mat-option *ngFor="let postcode of postcodes" [value]="postcode.city">{{postcode.city}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasErrors('toTownCity')"><app-field-error-message errorMessage="{{getError('toTownCity')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="!isFO" class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.town_city' | translate}}" id="toTownCity" name="toTownCity"
                    formControlName="toTownCity" required>
                    <mat-error *ngIf="hasErrors('toTownCity')"><app-field-error-message errorMessage="{{getError('toTownCity')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="!isFO" class="row">
            <mat-form-field class="col-lg-5 col-md-7 form-field-vertical-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'address_book.address_details.postcode' | translate}}" id="toPostcode" name="toPostcode"
                    formControlName="toPostcode" required>
                    <mat-error *ngIf="hasErrors('toPostcode')"><app-field-error-message errorMessage="{{getError('toPostcode')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field [formGroup]="sendReceiveForm" class="col-lg-5 col-md-7 form-field-vertical-spacing" floatLabel="never" appearance="outline">
                <input class="text-input" matInput formControlName="toContactNumber" placeholder="{{'address_book.address_details.contact_number' | translate}}">
                <mat-error *ngIf="hasErrors('toContactNumber')"><app-field-error-message errorMessage="{{getError('toContactNumber')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field [formGroup]="sendReceiveForm" class="col-lg-5 col-md-7 form-field-vertical-spacing" floatLabel="never" appearance="outline">
                <input class="text-input" matInput formControlName="toEmail" placeholder="{{'address_book.address_details.email' | translate}}">
                <mat-error *ngIf="hasErrors('toEmail')"><app-field-error-message errorMessage="{{getError('toEmail')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-7">
                <mat-form-field [formGroup]="sendReceiveForm" floatLabel="never" class="special-instructions-container">
                    <textarea rows="5" matInput placeholder="{{'address_book.address_details.special_instructions' | translate}}" id="toSpecialInstructions"
                        name="toSpecialInstructions" formControlName="toSpecialInstructions"></textarea>
                </mat-form-field>
            </div>
        </div>



        <div class="row">
            <div *ngIf="newAddressBookEntry">
                <button class="primary-button padded save-button" (click)="saveEntryToAddressBook()">{{'address_book.address_details.save_to_address_book' | translate}}</button>
            </div>
            <div class="col-lg-5 col-md-7 align-center mobile-favourite-padding">
                <button class="transparent" (click)="setFavourite()">
                    <img *ngIf="addToFavourite" class="favourite-icon"
                        src="/assets/icons/address_book/favourite_selected@3x.png" />
                    <img *ngIf="!addToFavourite" class="favourite-icon"
                        src="/assets/icons/address_book/favourite@3x.png" />
                </button>
                <span>{{'address_book.address_details.add_to_favourites' | translate}}</span>
            </div>
        </div>
        <div class="row field-row">
            <div class="col-lg-12 col-md-12">
                {{'address_book.address_details.note' | translate}}
                <a href="">{{'address_book.address_details.privacy_policy' | translate}}</a>
            </div>
        </div>
    </div>
</div>