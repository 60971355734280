import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { HomePersonalComponent } from './home/home-personal/home-personal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SendReceiveModule } from './send-receive/send-receive.module';
import { ShoppingServicesModule } from './shopping-services/shopping-services.module';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { JwtInterceptor } from './shared/jwt.interceptor';
import { AccountModule } from './account/account.module';
import { AuthenticationService } from './account/services/authentication.service';
import { appInitializer } from './app.initializer';
import { ImportServicesModule } from './import-services/import-services.module';
import { CookieService } from './shared/services/cookie.service';
import { registerLocaleData } from '@angular/common';
import localeDk from '@angular/common/locales/fo-DK';
import { CustomTranslateLoader } from '../app/services/customTranslateLoader'
import { Routes, UrlSerializer } from '@angular/router';
import { BaseComponent } from '../app/base.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CustomUrlSerializer } from './custom-url-serializer';
import { WaybillModule } from './waybill/waybill.module';
import { CcsModule } from './ccs/ccs.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ToppyModule } from 'toppy';


registerLocaleData(localeDk);

// export function httpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, `${environment.apiUrl}/api/StaticContent/TranslationDictionary/`, "");
// }

export const routes: Routes = [
  {path:''}
]

@NgModule({
  declarations: [
    AppComponent,
    HomePersonalComponent,
    BaseComponent,
  ],
  imports: [
    BrowserModule,
    ToppyModule,
    NgbModule,
    NgbTooltipModule,
    Ng2SearchPipeModule,
    SendReceiveModule,
    WaybillModule,
    CcsModule,
    ImportServicesModule,
    AccountModule,
    //TODO restore this when releasing Bera
    ShoppingServicesModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            // useFactory: httpLoaderFactory,
            useClass: CustomTranslateLoader,
            deps: [HttpClient]
        }
    }),
    BrowserAnimationsModule,
    FormsModule,
    MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatFormFieldModule,
    MatIconModule, MatDatepickerModule, MatSidenavModule, MatCardModule,
    MatGridListModule, MatButtonToggleModule, MatInputModule, MatTableModule,
    MatPaginatorModule, MatSortModule, MatSelectModule, MatTreeModule, MatDialogModule,
    MatDividerModule, MatListModule, MatMenuModule, MatExpansionModule, MatSlideToggleModule,
    MatChipsModule, MatTooltipModule, MatRadioModule, MatMomentDateModule,
    ReactiveFormsModule, MatProgressSpinnerModule, MatBadgeModule, MatToolbarModule, MatSnackBarModule, SharedModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService, CookieService] },
    { provide: LOCALE_ID, useValue: "fo-DK" },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
}