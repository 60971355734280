<div class="container address-container">
    <div class="child-address-container" *ngFor="let address of addresses | filter:searchText">
        <div class="row address-details">
            <div class="">
                <div class="edit-icon" (click)="editAddress(address)"></div>
            </div>
            <div class="col-lg-10 col-md-10 col-10 address-details-col">
                <div>{{ address.firstName }} {{ address.lastName }}</div>
                <!-- <a [routerLink]="" (click)="editAddress(address)">{{ address.firstName }} {{ address.lastName }}</a> -->
                <div>{{ address.company }}</div>
                <div>{{ address.addressLine1 }}</div>
                <div>{{ address.postcode }}</div>
                <div>{{ address.country.countryNameEN }}</div>
                <div *ngIf="!!address.contactNumber">Tel: <a [routerLink]="">{{ address.contactNumber }}</a></div>
            </div>
        </div>
        <div class="row">
            <div *ngIf="address.isFavourite" class="favourite-icon" (click)="toggleFavourite(address)"></div>
            <div *ngIf="!address.isFavourite" class="not-favourite-icon" (click)="toggleFavourite(address)"></div>
            <div class="col-lg-10 col-md-10 col-10 align-center favourite-col">
                Favourite
            </div>
        </div>
    </div>
    <div *ngIf="!addresses || (!!addresses && addresses.length < 1)" class="empty-container">
    </div>
</div>