import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { CustomShipmentType } from '../entities/customShipmentType';
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class CustomShipmentTypeService {

    constructor(private httpClient: HttpClient) {
    }

    public getAll(): Observable<Array<CustomShipmentType>> {
        return this.httpClient.get(`${environment.apiUrl}/api/SendReceiveOrder/CustomsShipmentTypes`) as Observable<Array<CustomShipmentType>>;
    }
}