<div class="container">
    <div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
</div>

<div *ngIf="!successfullyCreatedBusinessUser && !successfullyCreatedPrivateUser" class="container content-container">
    <div class="user-type-container">
        <h3 class="primary">{{'user_register.user_type' | translate}}</h3>
        <div class="user-type-button-container">
            <div class="row">
                <div class="col-md-6 col-6" style="padding-right: 0px;">
                    <button [ngClass]="!isBusinessUserType ? 'primary-button' : 'tertiary-button'" class="full-width"
                        (click)="changeRegisterRoleToBusiness(false)">{{'user_register.private_user' | translate}}</button>
                </div>
                <div class="col-md-6 col-6" style="padding-left: 0px;">
                    <button [ngClass]="isBusinessUserType ? 'primary-button' : 'tertiary-button'" class="full-width"
                        (click)="changeRegisterRoleToBusiness(true)">{{'user_register.business_user' | translate}}</button>
                </div>
            </div>
        </div>
    </div>


    <div class="user-type-selected-container" *ngIf="isBusinessUserType; else isPrivateUser">
        <app-business-register (successfullyCreatedUser)="businessUserCreatedEvent($event)"></app-business-register>
    </div>
    <ng-template #isPrivateUser>
        <app-private-register (successfullyCreatedUser)="privateUserCreatedEvent($event)"></app-private-register>
    </ng-template>
</div>

<div *ngIf="successfullyCreatedBusinessUser" class="container">
    <div class="user-created-container">
        <h2 class="thank-you-title">{{'business_register.thank_you' | translate}}</h2>
        <div class="user-created-body">{{'business_register.user_submitted_body' | translate}}</div>
        <div>
            <button class="primary-button go-home-button" [routerLink]="['../home']">
                {{'business_register.go_to_home' | translate}}
            </button>
        </div>
    </div>
</div>

<div *ngIf="successfullyCreatedPrivateUser" class="container">
    <div class="user-created-container">
        <h2 class="thank-you-title">{{'private_register.thank_you' | translate}}</h2>
        <div class="user-created-body">{{'private_register.user_submitted_body' | translate}}</div>
        <div>
            <button class="primary-button go-home-button" [routerLink]="['../home']">
                {{'private_register.go_to_home' | translate}}
            </button>
        </div>
    </div>
</div>

<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>