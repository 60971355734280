<div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
<div class="container">

    <div class="content-container">
        <h2 *ngIf="!trackTraceItems && !loadingOrder && !showInvalidTrackingCode" class="enter-id-message">{{'track_trace.enter_id' | translate}}</h2>
            <h2 *ngIf="!trackTraceItems && !loadingOrder && showInvalidTrackingCode" class="enter-id-message">{{'track_trace.id_not_found_error' | translate}}</h2>
        <mat-spinner *ngIf="loadingOrder" class="spinner" diameter="50">
        </mat-spinner>
        <div class="row">
            <div class="col-lg-6 col-md-6 transaction-input">
                <mat-form-field class="no-padding" style="width:100%;" [formGroup]="transactionForm" floatLabel="never"
                    appearance="outline">
                    <input class="transaction-id-text" matInput id="transactionId" name="transactionId"
                        formControlName="transactionId" required>
                </mat-form-field>
                <button class="primary-button search-button" (click)="getOrderDetails()"><img class="search-icon"
                        src="/assets/icons/search_arrow.png" /></button>
            </div>
            <div *ngIf="!!trackTraceItems" class="col-lg-6 col-12">
                <div *ngFor="let childTrackTraceItem of trackTraceItems;let y = index" class="item-container">
                    <div class="item-header-container">
                        <button class="item-button" (click)="childTrackTraceItem.showItem=!childTrackTraceItem.showItem">
                            <img *ngIf="childTrackTraceItem.showItem" class="expand-icon" src="/assets/icons/track-trace/Item_Expanded.png" />
                            <img *ngIf="!childTrackTraceItem.showItem" class="hidden-icon" src="/assets/icons/track-trace/Item_Expanded.png" />
                            <h2 class="trackTraceItemHeader">{{'track_trace.item' | translate}} {{y+1}}</h2>
                        </button>
                    </div>
                    <div *ngIf="childTrackTraceItem.showItem" class="vl-spacing"></div>
                    <div *ngIf="childTrackTraceItem.showItem">
                        <div *ngFor="let trackTraceItem of childTrackTraceItem.items; let i = index">
                            <div *ngIf="isDeliveredItem(trackTraceItem[0].eventTypeId)">
                                <div class="dispatched-container">
                                    <div class="item-title">
                                        <img class="item-image" src="/assets/icons/track-trace/tt_11_delivered_120.png" />
                                        <h2 class="dark-blue item-title-text">{{'track_trace.delivered' | translate}}</h2>
                                    </div>
                                    <div *ngFor="let deliveredItem of trackTraceItem" class="main-vl-container">
                                        <div class="circle"></div>
                                        <div class="vl">
                                            <div class="date-time-transit">
                                                {{deliveredItem.eventDateTime | toDateObj | date:'dd/MM/yyyy, h:mm a'}}
                                                <br>
                                                <h5 style="margin-bottom: 0">{{deliveredItem.eventDescription}} {{getTransitItemLocationName(deliveredItem.locationName)}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(i+1) < childTrackTraceItem.items.length" class="vl-spacing"></div>
                            </div>
                            <div *ngIf="isTransitLocationUpdateItem(trackTraceItem[0].eventTypeId)">
                                <div class="transit-container">
                                    <div class="item-title">
                                        <img class="item-image" src="/assets/icons/track-trace/tt_05_location_120.png" />
                                        <h2 class="dark-blue" style="padding-top: 23px;">{{'track_trace.in_transit_location_update' | translate}}</h2>
                                    </div>
                                    <div *ngFor="let transitItem of trackTraceItem" class="main-vl-container">
                                        <div class="circle"></div>
                                        <div class="vl">
                                            <div class="date-time-transit">
                                                {{transitItem.eventDateTime | toDateObj | date:'dd/MM/yyyy, h:mm a'}}
                                                <br>
                                                <h5 style="margin-bottom: 0">{{transitItem.eventDescription}} {{getTransitItemLocationName(transitItem.locationName)}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(i+1) < childTrackTraceItem.items.length" class="vl-spacing"></div>
                            </div>
                            <div *ngIf="isDelayedNoActionRequired(trackTraceItem[0].eventTypeId)">
                                <div class="transit-container">
                                    <div class="item-title">
                                        <img class="item-image" src="/assets/icons/track-trace/tt_07_delay_120.png" />
                                        <h2 class="dark-blue" style="padding-top: 23px;">{{'track_trace.delayed' | translate}}</h2>
                                    </div>
                                    <div *ngFor="let transitItem of trackTraceItem" class="main-vl-container">
                                        <div class="circle"></div>
                                        <div class="vl">
                                            <div class="date-time-transit">
                                                {{transitItem.eventDateTime | toDateObj | date:'dd/MM/yyyy, h:mm a'}}
                                                <br>
                                                <h5 style="margin-bottom: 0">{{transitItem.eventDescription}} {{getTransitItemLocationName(transitItem.locationName)}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(i+1) < childTrackTraceItem.items.length" class="vl-spacing"></div>
                            </div>
                            <div *ngIf="isDelayedActionRequired(trackTraceItem[0].eventTypeId)">
                                <div class="delayed-required-container">
                                    <div class="item-title">
                                        <img class="item-image" src="/assets/icons/track-trace/tt_06_delay_action_rq_120.png" />
                                        <h2 class="white" style="padding-top: 23px;">{{'track_trace.delayed_action_required' | translate}}</h2>
                                    </div>
                                    <div *ngFor="let transitItem of trackTraceItem" class="main-vl-container">
                                        <div class="circle"></div>
                                        <div class="vl">
                                            <div class="date-time-delayed">
                                                {{transitItem.eventDateTime | toDateObj | date:'dd/MM/yyyy, h:mm a'}}
                                                <br>
                                                <h5 style="margin-bottom: 0; color: white;">{{transitItem.eventDescription}} {{getTransitItemLocationName(transitItem.locationName)}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(i+1) < childTrackTraceItem.items.length" class="vl-spacing"></div>
                            </div>
                            <div *ngIf="isOrderReceivedItem(trackTraceItem[0].eventTypeId)">
                                <div class="received-container">
                                    <div class="item-title">
                                        <img class="item-image" src="/assets/icons/track-trace/tt_03_received_120.png" />
                                        <h2 class="dark-blue" style="padding-top: 23px;">{{'track_trace.received_by_carrier' | translate}}</h2>
                                    </div>
                                    <div *ngFor="let receivedItem of trackTraceItem" class="main-vl-container">
                                        <div class="circle"></div>
                                        <div class="vl">
                                            <div class="date-time-transit">
                                                {{receivedItem.eventDateTime | toDateObj | date:'dd/MM/yyyy, h:mm a'}}
                                                <br>
                                                <h5 style="margin-bottom: 0">{{receivedItem.eventDescription}} {{getTransitItemLocationName(receivedItem.locationName)}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(i+1) < childTrackTraceItem.items.length" class="vl-spacing"></div>
                            </div>
                            <div *ngIf="isConfirmed(trackTraceItem[0].eventTypeId)">
                                <div class="received-container">
                                    <div class="item-title">
                                        <img class="item-image" src="/assets/icons/track-trace/tt_01_confirmed_120.png" />
                                        <h2 class="dark-blue" style="padding-top: 23px;">{{'track_trace.confirmed' | translate}}</h2>
                                    </div>
                                    <div *ngFor="let receivedItem of trackTraceItem" class="main-vl-container">
                                        <div class="circle"></div>
                                        <div class="vl">
                                            <div class="date-time-transit">
                                                {{receivedItem.eventDateTime | toDateObj | date:'dd/MM/yyyy, h:mm a'}}
                                                <br>
                                                <h5 style="margin-bottom: 0">{{receivedItem.eventDescription}} {{getTransitItemLocationName(receivedItem.locationName)}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(i+1) < childTrackTraceItem.items.length" class="vl-spacing"></div>
                            </div>
                            <div *ngIf="isCancelled(trackTraceItem[0].eventTypeId)">
                                <div class="received-container">
                                    <div class="item-title">
                                        <img class="item-image" src="/assets/icons/track-trace/tt_02_cancelled_120.png" />
                                        <h2 class="dark-blue" style="padding-top: 23px;">{{'track_trace.cancelled' | translate}}</h2>
                                    </div>
                                    <div *ngFor="let receivedItem of trackTraceItem" class="main-vl-container">
                                        <div class="circle"></div>
                                        <div class="vl">
                                            <div class="date-time-transit">
                                                {{receivedItem.eventDateTime | toDateObj | date:'dd/MM/yyyy, h:mm a'}}
                                                <br>
                                                <h5 style="margin-bottom: 0">{{receivedItem.eventDescription}} {{getTransitItemLocationName(receivedItem.locationName)}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(i+1) < childTrackTraceItem.items.length" class="vl-spacing"></div>
                            </div>
                            <div *ngIf="isAtemmptedDelivery(trackTraceItem[0].eventTypeId)">
                                <div class="received-container">
                                    <div class="item-title">
                                        <img class="item-image" src="/assets/icons/track-trace/tt_09_attempted_delivery_120.png" />
                                        <h2 class="dark-blue" style="padding-top: 23px;">{{'track_trace.attempted_delivery' | translate}}</h2>
                                    </div>
                                    <div *ngFor="let receivedItem of trackTraceItem" class="main-vl-container">
                                        <div class="circle"></div>
                                        <div class="vl">
                                            <div class="date-time-transit">
                                                {{receivedItem.eventDateTime | toDateObj | date:'dd/MM/yyyy, h:mm a'}}
                                                <br>
                                                <h5 style="margin-bottom: 0">{{receivedItem.eventDescription}} {{getTransitItemLocationName(receivedItem.locationName)}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(i+1) < childTrackTraceItem.items.length" class="vl-spacing"></div>
                            </div>
                            <div *ngIf="isReturnToSender(trackTraceItem[0].eventTypeId)">
                                <div class="received-container">
                                    <div class="item-title">
                                        <img class="item-image" src="/assets/icons/track-trace/tt_12_return_to_sender.png" />
                                        <h2 class="dark-blue" style="padding-top: 23px;">{{'track_trace.return_to_sender' | translate}}</h2>
                                    </div>
                                    <div *ngFor="let receivedItem of trackTraceItem" class="main-vl-container">
                                        <div class="circle"></div>
                                        <div class="vl">
                                            <div class="date-time-transit">
                                                {{receivedItem.eventDateTime | toDateObj | date:'dd/MM/yyyy, h:mm a'}}
                                                <br>
                                                <h5 style="margin-bottom: 0">{{receivedItem.eventDescription}} {{getTransitItemLocationName(receivedItem.locationName)}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(i+1) < childTrackTraceItem.items.length" class="vl-spacing"></div>
                            </div>
                            <div *ngIf="isUnableToDeliver(trackTraceItem[0].eventTypeId)">
                                <div class="received-container">
                                    <div class="item-title">
                                        <img class="item-image" src="/assets/icons/track-trace/tt_13_unable_to_deliver.png" />
                                        <h2 class="dark-blue" style="padding-top: 23px;">{{'track_trace.unable_to_deliver' | translate}}</h2>
                                    </div>
                                    <div *ngFor="let receivedItem of trackTraceItem" class="main-vl-container">
                                        <div class="circle"></div>
                                        <div class="vl">
                                            <div class="date-time-transit">
                                                {{receivedItem.eventDateTime | toDateObj | date:'dd/MM/yyyy, h:mm a'}}
                                                <br>
                                                <h5 style="margin-bottom: 0">{{receivedItem.eventDescription}} {{getTransitItemLocationName(receivedItem.locationName)}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(i+1) < childTrackTraceItem.items.length" class="vl-spacing"></div>
                            </div>
                            <div *ngIf="isReadyToCollect(trackTraceItem[0].eventTypeId)">
                                <div class="received-container">
                                    <div class="item-title">
                                        <img class="item-image" src="/assets/icons/track-trace/tt_10_ready_to_collect_120.png" />
                                        <h2 class="dark-blue" style="padding-top: 23px;">{{'track_trace.ready_to_collect' | translate}}</h2>
                                    </div>
                                    <div *ngFor="let receivedItem of trackTraceItem" class="main-vl-container">
                                        <div class="circle"></div>
                                        <div class="vl">
                                            <div class="date-time-transit">
                                                {{receivedItem.eventDateTime | toDateObj | date:'dd/MM/yyyy, h:mm a'}}
                                                <br>
                                                <h5 style="margin-bottom: 0">{{receivedItem.eventDescription}} {{getTransitItemLocationName(receivedItem.locationName)}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(i+1) < childTrackTraceItem.items.length" class="vl-spacing"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>

<!-- TODO replace with static content -->
<!-- <div class="container extras-container">
    <div class="row" style="padding-left: 15px;">
        <div class="col-lg-6 extra-container">
            <div class="extra-background">
                <img class="extra-image" src="/assets/images/buy-postage/find-prices@3x.png" />
                <div class="extra-text-container">
                    <h2 class="white extra-header" >Find the best send and
                        receive service for you</h2>
                        <span class="white extra-text">Promo text here about different services</span>
                        <br>
                        <h5 class="extra-link white" [routerLink]="['../../find-prices']">Find prices ></h5>
                </div>
            </div>
        </div>
        <div class="col-lg-6 extra-container">
            <div class="extra-background">
                <img class="extra-image" src="/assets/images/buy-postage/brochures@3x.png" />
                <div class="extra-text-container">
                    <h2 class="white extra-header" >Find special offers on your local shopping</h2>
                        <span class="white extra-text">View products, create and download your own shopping list from brochures</span>
                        <br>
                        <h5 class="extra-link white" [routerLink]="['../../find-prices']">Brochures ></h5>
                </div>
            </div>
        </div>
    </div>
</div> -->