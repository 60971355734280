import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RoleService } from "../../account/services/role.service";

@Injectable({ providedIn: 'root' })
export class HomePageGuard implements CanActivate {
    constructor(
        private router: Router,
        private translateService: TranslateService,
        private roleService: RoleService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/home`]);
        return false;
    }
}