<div class="container header-container hide-tablet">
    <h1 class="new-order-title">{{'view_ccs_submission.ccs_submission' | translate}}</h1>
    <div class="print-label-button-container">
        <button *ngIf="!!ccsSubmission.customsAmount && !showPaymentScreen && !paymentSuccess" class="primary-button"
            (click)="pay()"
            mat-button>{{'view_ccs_submission.pay' | translate}}</button>
        <button *ngIf="!ccsSubmission.customsAmount && !ccsInvoice?.isPaidButUpdateFailed" class="primary-button"
            (click)="downloadInvoice()"
            mat-button>{{'view_ccs_submission.download_invoice' | translate}}</button>
        <button *ngIf="!ccsSubmission.customsAmount" class="primary-button"
            (click)="downloadReceipt()"
            mat-button>{{'view_ccs_submission.download_receipt' | translate}}</button>
        <!-- <button *ngIf="order.isCDSFiled && !!order.barcode" class="print-label-button primary-button"
            [disabled]="downloadingCDS" (click)="downloadCDS()"
            mat-button>{{'send_receive.send_receive.confirmation.download_cds' | translate}}</button> -->
    </div>
</div>
<div class="container show-tablet" style="padding-top: 30px;">
    <div class="row">
        <div class="col-12">
            <h1 class="new-order-title">{{'view_ccs_submission.ccs_submission' | translate}}</h1>
        </div>
        <div class="col-12">
            <button *ngIf="!!ccsSubmission.customsAmount && !showPaymentScreen && !paymentSuccess" class="primary-button"
                (click)="pay()"
                mat-button>{{'view_ccs_submission.pay' | translate}}</button>
        </div>
        <div class="col-md-6 col-sm-12">
            <button *ngIf="!ccsSubmission.customsAmount && !ccsInvoice?.isPaidButUpdateFailed" class="primary-button"
                (click)="downloadInvoice()"
                mat-button>{{'view_ccs_submission.download_invoice' | translate}}</button>
        </div>
        <div class="col-md-6 col-sm-12">
            <button *ngIf="!ccsSubmission.customsAmount" class="primary-button second-header-button"
                (click)="downloadReceipt()"
                mat-button>{{'view_ccs_submission.download_receipt' | translate}}</button>
        </div>
        <!-- <button *ngIf="order.isCDSFiled && !!order.barcode" class="print-label-button primary-button"
            [disabled]="downloadingCDS" (click)="downloadCDS()"
            mat-button>{{'send_receive.send_receive.confirmation.download_cds' | translate}}</button> -->
    </div>
</div>


<div class="container" *ngIf="paymentSuccess && !paymentCancelledOrFailed">
    <app-ccsconfirmation [ccsSubmission]="ccsSubmission"></app-ccsconfirmation>
</div>
<div *ngIf="paymentCancelledOrFailed" class="container">
    <div class="paid-order-confirmation-container">
      <h2 class="posta-id">{{'view_ccs_submission.payment_failed' | translate}}: {{ccsSubmission.wisePONumber}}</h2>
      <div class="paid-order-body">
        {{'view_ccs_submission.payment_failed_body' | translate}}
      </div>
    </div>
</div>

<div class="container" *ngIf="showPaymentScreen && !showPaymentFailed && !paymentSuccess">
    <iframe id="paymentFrame" #paymentFrame
        sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts" [src]="iframeURL"
        class="full-width frame"></iframe>
</div>

<div class="container parent-container" *ngIf="!showPaymentScreen && !paymentSuccess">
    <div class="row-container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
                <h2 class="header">{{'view_ccs_submission.date' | translate}}</h2>
                <div style="padding-bottom: 30px">
                    <p>{{ccsSubmission.createDateTime | date:'dd/MM/yyyy, h:mm a'}}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <h2 class="header">{{'view_ccs_submission.amount' | translate}}</h2>
                <div style="padding-bottom: 30px">
                    <p>{{ccsSubmission.customsAmount | currency: 'DKK':''}} DKK</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <h2 class="header">{{'view_ccs_submission.colli' | translate}}</h2>
                <div style="padding-bottom: 30px">
                    <p>{{ccsSubmission.numberOfColli}}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <h2 class="header">{{'view_ccs_submission.wise_po_number' | translate}}</h2>
                <div style="padding-bottom: 30px">
                    <p>{{ccsSubmission.wisePONumber}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row-container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
                <h2 class="header">{{'view_ccs_submission.sender_details' | translate}}</h2>
                <div style="padding-bottom: 5px">
                    <p>{{ccsSubmission.senderName}}</p>
                </div>
                <div style="padding-bottom: 5px">
                    <p>{{ccsSubmission.senderCountry}}</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <h2 class="header">{{'view_ccs_submission.receiver_details' | translate}}</h2>
                <div style="padding-bottom: 5px">
                    <p>{{ccsSubmission.receiverName}}</p>
                </div>
                <div style="padding-bottom: 5px">
                    <p>{{ccsSubmission.receiverAddress}}</p>
                </div>
                <div style="padding-bottom: 5px">
                    <p>{{ccsSubmission.receiverPostcode}}</p>
                </div>
                <div style="padding-bottom: 5px">
                    <p>{{ccsSubmission.receiverVTAL}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row-container" *ngIf="ccsSubmission.invoiceLines && ccsSubmission.invoiceLines.length">
        <div class="row">
            <div class="col-12">
                <h2 class="header">{{'view_ccs_submission.invoice_lines' | translate}}</h2>
            </div>
            <div class="col-lg-3 col-md-6 col-12" *ngFor="let invoiceLine of ccsSubmission.invoiceLines">
                <div style="padding-bottom: 5px">
                    <h4>{{invoiceLine.description}}</h4>
                </div>
                <div style="padding-bottom: 5px">
                    <strong>{{'view_ccs_submission.sku_no' | translate}}</strong>
                    <p>{{invoiceLine.skuNo}}</p>
                </div>
                <div style="padding-bottom: 5px">
                    <strong>{{'view_ccs_submission.quantity' | translate}}</strong>
                    <p>{{invoiceLine.quantity | numberLocalization}} {{'view_ccs_submission.items' | translate}}</p>
                </div>
                <div style="padding-bottom: 5px">
                    <strong>{{'view_ccs_submission.gross_amount' | translate}}</strong>
                    <p>{{invoiceLine.grossAmount | currency: 'DKK':''}} DKK</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row-container">
        <div class="row">
            <div class="col-12">
                <h2 class="header">{{'view_ccs_submission.tariffs' | translate}}</h2>
            </div>
            <div class="col-lg-3 col-md-6 col-12" *ngFor="let tariff of ccsSubmission.tariffList">
                <div style="padding-bottom: 5px">
                    <h4>{{tariff.tariffNo}}</h4>
                </div>
                <div style="padding-bottom: 5px" *ngIf="!!tariff.description">
                    <strong>{{'view_ccs_submission.tariff_description' | translate}}</strong>
                    <p>{{tariff.description}}</p>
                </div>
                <div style="padding-bottom: 5px" *ngIf="!!tariff.countryOfOrigin">
                    <strong>{{'view_ccs_submission.country_of_origin' | translate}}</strong>
                    <p>{{tariff.countryOfOrigin}}</p>
                </div>
                <div style="padding-bottom: 5px" *ngIf="!!tariff.invoiceValue">
                    <strong>{{'view_ccs_submission.invoice_value' | translate}}</strong>
                    <p>{{tariff.invoiceValue | currency: 'DKK':''}} DKK</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row-container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-12">
                <div class="row" style="padding-bottom: 10px;">
                    <div class="col-lg-9 col-md-9 align-center" *ngIf="!!ccsSubmission.documentList">
                        <h2>{{'view_ccs_submission.documents' | translate}}</h2>
                    </div>
                    <div class="col-lg-3 col-md-3 align-center">
                        <button class="primary-button" (click)="uploadClick()"> {{'view_ccs_submission.upload' | translate}} </button>
                        <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
                    </div>
                </div>
                <div *ngIf="!!ccsSubmission.documentList">
                    <div *ngFor="let file of ccsSubmission.documentList">
                        <div class="row" style="padding-bottom: 10px;">
                            <div class="col-lg-5 col-md-5 align-center">
                                <span>{{file.filename}}</span>
                            </div>
                            <div class="col-lg-4 col-md-4 align-center">
                                {{file.uploadDateTime | date:'dd/MM/yyyy, h:mm a'}}
                            </div>
                            <div class="col-lg-3 col-md-3 align-center">
                                <button class="primary-button border-white download-button" (click)="downloadDocument(file)">
                                    <div class="spinner">
                                        <span>{{'view_ccs_submission.download' | translate}}</span>
                                        <mat-spinner *ngIf="file.isDownloading" class="spinner-object" diameter="15">
                                        </mat-spinner>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
