import { Component, OnInit } from '@angular/core';
import { StaticContentService } from './shared/services/static-content.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loading: boolean = false;
  constructor(private staticContentService: StaticContentService){}

  public ngOnInit() {

  this.loading = true;
  if (!this.staticContentService.staticContent) {
    this.setStaticContent();
  }
  else {
    this.staticContentService.getHash().subscribe(x => {
      if (this.staticContentService.staticContentHash != x) {
        this.setStaticContent();
      }
    });
  }
}

  private setStaticContent() {
    this.staticContentService.getStaticContent().subscribe(x => {
      this.staticContentService.setStaticContent(x);
      this.loading = false;
      // this.staticContentHelper.setCdn(x);
      this.staticContentService.setStaticContentHash(x.Hash);
    }, error => {
      var test = error;
    });
  }
}
