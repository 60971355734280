import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
    }

    constructor(private httpClient: HttpClient) {
    }

    public getAll(filter: any): Observable<any> {
        return this.httpClient.post(`${environment.beraUrl}/api/Bera/products`, filter, this.httpOptions);
    }

    public getAllCategories(languageCode?: string): Observable<any> {
        return this.httpClient.get(`${environment.beraUrl}/api/Bera/categories/${languageCode}`);
    }

    public setIsBought(filter): Observable<object>
    {
      return this.httpClient.put(`${environment.apiUrl}/api/Bera/slitemisbought`, filter);
    }
}