import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment'
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ShoppingListService {

    httpOptionsPlain = {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
          'Content-Type': 'text/plain'
        }),
        'responseType': 'text'
      };

    constructor(private httpClient: HttpClient, private translateService: TranslateService) {
    }

    // public saveToAddressBook(address: any): Observable<any> {
    //     return this.httpClient.post(`${environment.beraUrl}/api/AddressBook`, address)
    //         .pipe(map((data: any) => {
    //             return data;
    //         }));
    // }

    public getAll(): Observable<any> {
        return this.httpClient.get(`${environment.beraUrl}/api/Bera/sluserlists`);
    }

    public getSharedShoppingList(id: any): Observable<any> {
      return this.httpClient.get(`${environment.beraUrl}/api/Bera/slsharedproductlist/${id}/${this.translateService.currentLang}/true`);
    }

    public getProductsForListNoImage(listId: any): Observable<any> {
      return this.httpClient.get(`${environment.beraUrl}/api/Bera/slproductlist/${listId}/${this.translateService.currentLang}/false`);
    }

    public getProductsForListWithImage(listId: any): Observable<any> {
      return this.httpClient.get(`${environment.beraUrl}/api/Bera/slproductlist/${listId}/${this.translateService.currentLang}/true`);
    }

    public create(name: string): Observable<any> {
        return this.httpClient.post(`${environment.beraUrl}/api/Bera/slcreate/${name}`, null);
    }

    // public getPostcodes(): Observable<any> {
    //     return this.httpClient.get(`${environment.apiUrl}/api/AddressBook/Postcodes`);
    // }

    public shareList(filter: any): Observable<any>
    {
      return this.httpClient.put(`${environment.beraUrl}/api/Bera/slshare`, filter, {responseType: 'text'});
    }

    public setActiveList(listId: any): Observable<any>
    {
      return this.httpClient.put(`${environment.beraUrl}/api/Bera/slsetactive/${listId}`, null);
    }

    public addToShoppingList(body: any): Observable<any>
    {
        return this.httpClient.post(`${environment.beraUrl}/api/Bera/sladditem`, body);
    }

    public removeItemFromShoppingList(shoppingListId: any, productId: any): Observable<any>
    {
      return this.httpClient.delete(`${environment.beraUrl}/api/Bera/slremoveitem/${shoppingListId}/${productId}`);
    }
    
    public deleteShoppingList(shoppingListId: any): Observable<any>
    {
      return this.httpClient.delete(`${environment.beraUrl}/api/Bera/slremove/${shoppingListId}`);
    }

    public getProductImage(productId: any): Observable<any>
    {
      return this.httpClient.get(`${environment.beraUrl}/api/Bera/productimage/${productId}`, {responseType: 'text'});
    }

    // public favouriteAddress(addressId: string, favourite: boolean): Observable<object>
    // {
    //   return this.httpClient.put(`${environment.apiUrl}/api/AddressBook/${addressId}?IsFavourite=${favourite}`, null);
    // }

    // public delete(addressId: string): Observable<object>
    // {
    //     return this.httpClient.delete(`${environment.apiUrl}/api/AddressBook/${addressId}`);
    // }

    // this.http.get(`/Authentication/MinlykilLogin`, { responseType: 'text'}).subscribe( 
}