import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { UploadFileService } from '../services/upload-file.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SendReceiveOrderInvoice } from '../entities/sendReceiveOrderInvoice';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges {

  @ViewChild("fileUpload", { }) 
  fileUpload: ElementRef;
  files: Array<SendReceiveOrderInvoice> = new Array<SendReceiveOrderInvoice>();

  @Input()
  clearAllFiles : boolean;

  @Input()
  orderDetailsForm: FormGroup;

  @Output()
  uploadedFiles: EventEmitter<any> = new EventEmitter();

  @Input()
  showRequiredError: boolean = true;

  @Input()
  existingOrderInvoices: Array<SendReceiveOrderInvoice>;

  public filesRemove: Array<SendReceiveOrderInvoice> = new Array<SendReceiveOrderInvoice>();

  public filesAdd: Array<SendReceiveOrderInvoice> = new Array<SendReceiveOrderInvoice>();

  @Output()
  filesToRemove: EventEmitter<Array<SendReceiveOrderInvoice>> = new EventEmitter();

  @Output()
  filesToAdd: EventEmitter<Array<SendReceiveOrderInvoice>> = new EventEmitter();

  public orderReference: string;

  public showUploadLaterMesssage: boolean = false;

  ImageBaseData:string | ArrayBuffer=null;

  constructor(private uploadService: UploadFileService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.orderReference = this.route.snapshot.paramMap.get('orderReferenceId');

    if (!!this.existingOrderInvoices && this.existingOrderInvoices.length > 0)
    {
      this.files = this.existingOrderInvoices;
      var i: number = 0;
      for (i; i < this.existingOrderInvoices.length; i++)
      {
        // this.files[i].fileName = this.existingOrderInvoices[i].filename;
        this.orderDetailsForm.addControl(`fileCompanyName${i}`, new FormControl(''));
        this.orderDetailsForm.get(`fileCompanyName${i}`).setValue(this.existingOrderInvoices[i].fileName);
      }
      this.uploadedFiles.emit(this.files);
      if (!!this.orderDetailsForm.get('uploadedFiles'))
      {
        this.orderDetailsForm.get('uploadedFiles').enable();
        this.orderDetailsForm.get('uploadedFiles').setValue(this.files);
      }
    }
  }

  ngOnChanges() {
    if (this.clearAllFiles)
    {
      this.files = new Array();
      this.uploadedFiles.emit(this.files);
    }
  }

  // public uploadFile(file) {
  //   const formData = new FormData();
  //   formData.append('file', file.data);
  //   file.inProgress = true;
  //   this.uploadService.upload(formData).pipe(
  //     map(event => {
  //       switch (event.type) {
  //         case HttpEventType.UploadProgress:
  //           file.progress = Math.round(event.loaded * 100 / event.total);
  //           this.showUploadLaterMesssage = false;
  //           break;
  //         case HttpEventType.Response:
  //           this.showRequiredError = false;
  //           return event;
  //       }
  //     }),
  //     catchError((error: HttpErrorResponse) => {
  //       file.inProgress = false;
  //       return of(`${file.data.name} upload failed.`);
  //     })).subscribe((event: any) => {
  //       if (typeof (event) === 'object') {
  //       }
  //     });
  // }

  public onClick() {
    const fileUpload = this.fileUpload.nativeElement; 
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        const currentFilesIndex = this.files.length;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
           var newFile: SendReceiveOrderInvoice = {
            fileData: (reader.result as string).split(',')[1],
            orderReference: (currentFilesIndex + 1).toString(),
            fileName: file.name,
            companyName: file.companyName,
            applicationId: 0
          }
          this.files.push(newFile);

          //catch potential error
          // this.uploadService.addInvoice(newFile, this.orderReference).subscribe();
          this.filesAdd.push(newFile)
          this.filesToAdd.emit(this.filesAdd);

          this.orderDetailsForm.addControl(`fileCompanyName${currentFilesIndex}`, new FormControl(''));
          this.uploadedFiles.emit(this.files);
          if (!!this.orderDetailsForm.get('uploadedFiles'))
          {
            this.orderDetailsForm.get('uploadedFiles').enable();
            this.orderDetailsForm.get('uploadedFiles').setValue(this.files);
          }
        };
      }
    };
    fileUpload.click();
  }

  public deleteFile(invoice: SendReceiveOrderInvoice) {
    //catch potential error
    // this.uploadService.removeInvoice(invoice, this.orderReference).subscribe();

    this.filesRemove.push(invoice);
    this.filesToRemove.emit(this.filesRemove);

    this.files = this.files.filter(x => x !== invoice);
    this.uploadedFiles.emit(this.files);
  }

  // private uploadFiles() {
  //   this.fileUpload.nativeElement.value = '';
  //   this.files.forEach(file => {
  //     this.convertFileToBase64(file);
  //   });
  // }

  public isValid() {
  }

  public uploadLater() {
    this.showRequiredError = false;
    this.showUploadLaterMesssage = true;
    this.files = [];
    this.orderDetailsForm.get('uploadedFiles').setValue(null);
    this.orderDetailsForm.get('uploadedFiles').disable();
  }
}
