import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Country } from './../../../../account/entities/Country';
import { CustomsItemType } from './../../../entities/CustomsItemType';
import { CountryService } from './../../../../shared/services/country.service';
import { ItemTypeComponent } from './item-type/item-type.component'

@Component({
  selector: 'app-buy-postage-item-types',
  templateUrl: './buy-postage-item-types.component.html',
  styleUrls: ['./buy-postage-item-types.component.scss']
})
export class BuyPostageItemTypesComponent implements OnInit {

  _itemTypes: CustomsItemType[];
  @Output() itemTypesChange: EventEmitter<CustomsItemType[]> = new EventEmitter<CustomsItemType[]>();
  @Input() 
  set itemTypes(value : CustomsItemType[]) {
    this._itemTypes = value;
  } 
  get itemTypes() : CustomsItemType[]{
    return this._itemTypes;
  }

  @ViewChildren(ItemTypeComponent) itemTypeComponents!: QueryList<ItemTypeComponent>;

  public CheckValid(){
    var isValid = true;
    if(this.itemTypeComponents){
      this.itemTypeComponents.forEach(child => {
        isValid = isValid && child.CheckValidity();
      });
    }
    return isValid;
  }

  countries: Array<Country>;

  constructor(
    private translate: TranslateService,
    private countryService: CountryService,) { }

  ngOnInit(): void {
    this.countryService.getAll().subscribe(x => {
      this.countries = x;
      switch (this.translate.currentLang) {
        case "en":
          this.countries.sort((a, b) => a.countryNameEN.localeCompare(b.countryNameEN))
        case "fo":
          this.countries.sort((a, b) => a.countryNameFO.localeCompare(b.countryNameFO))
      }

      this.countries.sort(function(x,y){ return x.countryCode == "DK" ? -1 : y.countryCode == "DK" ? 1 : 0; });
      this.countries.sort(function(x,y){ return x.countryCode == "FO" ? -1 : y.countryCode == "FO" ? 1 : 0; });
   });
  }

  addItemType(){
    this.itemTypes.push(new CustomsItemType());
    this.itemTypesChange.emit(this.itemTypes);
  }

  deleteItemType(index){
    this.itemTypes.splice(index, 1);
  }

}
