import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ServiceType } from "../../shared/price-matrix/enums/ServiceType";
import { CCSDocument } from "../entities/CCSDocument";
import { CCSInvoice } from "../entities/CCSInvoice";
import { CCSSubmission } from "../entities/CCSSubmission";

@Injectable({
    providedIn: 'root'
})
export class CCSService{
    constructor(private httpClient: HttpClient) {
    }

    getCCSSubmission(ccsId, languageCode){
        return this.httpClient.get(`${environment.apiUrl}/api/Customs/detail/${ccsId}/${languageCode}`) as Observable<CCSSubmission>;
    }

    downloadDocument(documentId) {
        var body = {
            ServiceType: ServiceType.Customs,
            DocumentId: documentId
        }
        return this.httpClient.post(`${environment.apiUrl}/api/Customs/downloadDoc`, body, { responseType: 'text' }) as Observable<string>;
    }

    uploadDocument(orderId: number, fileName, documentData) {
        var body = {
            ServiceType: ServiceType.Customs,
            OrderId: orderId.toString(),
            FileName: fileName,
            DocumentData: documentData
        }
        return this.httpClient.post(`${environment.apiUrl}/api/Customs/uploadDoc`, body) as Observable<CCSDocument>;
    }

    getCCSInvoice(ccsId, wisePONumber){
        var url = `${environment.apiUrl}/api/Customs/invoicedetail/${ccsId}`
        if(wisePONumber){
            url = `${url}?poNumber=${wisePONumber}`;
        }
        return this.httpClient.get(url) as Observable<CCSInvoice>;
    }
}