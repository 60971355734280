import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StaticContentHelper } from '../../shared/helpers/static-content-helper';
import { StaticContentService } from '../../shared/services/static-content.service';

@Component({
  selector: 'app-pay-invoice',
  templateUrl: './pay-invoice.component.html',
  styleUrls: ['./base-pay-customs.component.scss']
})
export class PayInvoiceComponent implements OnInit {

  public image: string;

  interval: any;

  public pageTitle: string;

  public pageModules: string;

  constructor(private staticContentService: StaticContentService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        this.image = this.staticContentService.getImageForPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.image = this.staticContentService.getImageForPsModule();
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
    })
  }

  public getPageTitle()
  {
    return this.staticContentService.getPageTitle();
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }
}
