import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class VerificationResolver implements Resolve<any> {

    constructor (private authenticationService: AuthenticationService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>| Promise<any>|any {
        var verifyInput: any = {
            //3 is verifiy email
            CodeType: 3,
            Code: route.paramMap.get('code')
          }

        // return this.authenticationService.verifyAccount(verifyInput).pipe(
        //     map((x) => ( {any : x })),
        //     catchError((error))
        // )

        return this.authenticationService.verifyAccount(verifyInput).pipe(
            map((x) => ({x})),
            catchError((error) => {
                console.log("Invalid verification code provided");
              return of({ x: false});
            }),
          );
    }
}