<div class="container" style="padding-top: 30px;">
    Enter JSON:
    <textarea name="message" [(ngModel)]="jsonInput" class="full-width" rows="20" matInput></textarea>
    <button class="primary-button" style="margin-top: 20px;" (click)="getPrices()">Get Prices</button>

    <div style="margin-top: 30px;" *ngIf="!!serviceOptions">
        Total results: {{serviceOptions.length}}<br><br>

        <div class="row">
            <div class="col-lg-6 service-option" *ngFor="let serviceOption of serviceOptions; let i = index;">
                <span style="font-weight: 600;">Name: {{serviceOption.Name.EN}}</span><br><br>
                <div *ngIf="!!serviceOption.UsingWeightPivotPoint">Using Weight Pricing:
                    {{serviceOption.UsingWeightPivotPoint}}<br></div>
                <div *ngIf="!!serviceOption.BasePrice">TransportType base price: {{serviceOption.BasePrice}}<br></div>
                <div *ngIf="!!serviceOption.BaseCostOverride">TransportType base price override:
                    {{serviceOption.BaseCostOverride}}<br></div>
                    <div *ngIf="!!serviceOption.BasePriceVAT">TransportType base price VAT: {{serviceOption.BasePriceVAT}}<br></div>
                <div *ngIf="!!serviceOption.AdditionalVolumeCost">TransportType volume cost:
                    {{serviceOption.AdditionalVolumeCost}}<br></div>
                <div *ngIf="!!serviceOption.AdditionalWeightCost">TransportType weight cost:
                    {{serviceOption.AdditionalWeightCost}}<br></div>
                <div *ngIf="!!serviceOption.TotalBaseCost" style="font-weight: 600;">Base subtotal:
                    {{serviceOption.TotalBaseCost}}<br><br></div>

                <div *ngIf="!!serviceOption.IncoTermBasePrice">
                    <div *ngFor="let incotermBasePrice of serviceOption.IncoTermBasePrice; let y = index;">
                        <div>Incoterms item {{y+1}} base price: {{incotermBasePrice}}<br></div>
                        <div *ngIf="!!serviceOption.IncoTermValueCost[y]">Incoterms item {{y+1}} value price:
                            {{serviceOption.IncoTermValueCost[y]}}<br></div>
                        <div *ngIf="!!serviceOption.IncoTermWeightCost[y]">Incoterms item {{y+1}} weight price:
                            {{serviceOption.IncoTermWeightCost[y]}}<br></div>
                        <div *ngIf="!!serviceOption.IncoTermVolumeCost[y]">Incoterms item {{y+1}} volume price:
                            {{serviceOption.IncoTermVolumeCost[y]}}<br></div>
                        <div *ngIf="!!serviceOption.IncoTermVATCost[y]">Incoterms item {{y+1}} VAT cost: 
                                {{serviceOption.IncoTermVATCost[y]}}<br></div>
                                <br>
                    </div>
                    <div *ngIf="!!serviceOption.TotalIncoTermCost" style="font-weight: 600;">Incoterms subtotal:
                        {{serviceOption.TotalIncoTermCost}}<br><br></div>
                </div>

                <!-- <div *ngIf="!!serviceOption.IncoTermBasePrice">Incoterms base price: {{serviceOption.IncoTermBasePrice}}<br></div>
                <div *ngIf="!!serviceOption.IncoTermValueCost">Incoterms value price: {{serviceOption.IncoTermValueCost}}<br></div>
                <div *ngIf="!!serviceOption.IncoTermWeightCost">Incoterms weight price: {{serviceOption.IncoTermWeightCost}}<br></div>
                <div *ngIf="!!serviceOption.IncoTermVolumeCost">Incoterms volume price: {{serviceOption.IncoTermVolumeCost}}<br></div>
                <div *ngIf="!!serviceOption.TotalIncoTermCost">Incoterms subtotal: {{serviceOption.TotalIncoTermCost}}<br><br></div> -->

                <div *ngIf="!!serviceOption.InsuranceBasePrice">
                    <div *ngFor="let insuranceBasePrice of serviceOption.InsuranceBasePrice; let z = index;">
                        <div>Insurance item {{z+1}} base price: {{insuranceBasePrice}}<br></div>
                        <div *ngIf="!!serviceOption.InsuranceVAT && serviceOption.InsuranceVAT > 0">Insurance VAT Included: true<br></div>
                        <div *ngIf="!!serviceOption.InsuranceVAT && serviceOption.InsuranceVAT > 0">Insurance VAT Cost: 
                            {{serviceOption.InsuranceVAT}}<br></div>
                        <div *ngIf="!!serviceOption.InsuranceValueCost[z]">Insurance item {{z+1}} value price:
                            {{serviceOption.InsuranceValueCost[z]}}<br></div>
                        <div *ngIf="!!serviceOption.InsuranceWeightCost[z]">Insurance item {{z+1}} weight price:
                            {{serviceOption.InsuranceWeightCost[z]}}<br></div>
                        <div *ngIf="!!serviceOption.InsuranceVolumeCost[z]">Insurance item {{z+1}} volume price:
                            {{serviceOption.InsuranceVolumeCost[z]}}<br></div>
                    </div>
                    <div *ngIf="!!serviceOption.TotalInsuranceCost" style="font-weight: 600;">Insurance subtotal:
                        {{serviceOption.TotalInsuranceCost + serviceOption.InsuranceVAT}}<br><br></div>
                </div>

                <!-- <span *ngIf="!!serviceOption.InsuranceBasePrice" >Insurance base price: {{serviceOption.InsuranceBasePrice}}<br></span>
                <span *ngIf="!!serviceOption.InsuranceValueCost">Insurance value price: {{serviceOption.InsuranceValueCost}}<br></span>
                <span *ngIf="!!serviceOption.InsuranceWeightCost">Insurance weight price: {{serviceOption.InsuranceWeightCost}}<br></span>
                <span *ngIf="!!serviceOption.InsuranceVolumeCost">Insurance volume price: {{serviceOption.InsuranceVolumeCost}}<br></span>
                <span *ngIf="!!serviceOption.InsuranceCost">Insurance subtotal: {{serviceOption.InsuranceCost}}<br><br></span> -->

                <div *ngIf="serviceOption.IsDHL">NOTE: DHL prices are always rounded up to the nearest whole number<br></div>
                <span style="font-weight: 600;">Overall cost: {{serviceOption.Cost}}</span><br>
            </div>
        </div>
    </div>
</div>