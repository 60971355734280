import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CcsRoutingModule } from './ccs-routing.module';
import { CCSDetailComponent } from './ccsdetail/ccsdetail.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { CCSConfirmationComponent } from './ccsconfirmation/ccsconfirmation.component';


@NgModule({
  declarations: [CCSDetailComponent, CCSConfirmationComponent],
  imports: [
    CommonModule,
    CcsRoutingModule,
    TranslateModule,
    MatFormFieldModule,
    SharedModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule
  ]
})
export class CcsModule { }
