import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShoppingListService } from '../services/shopping-list-service';
import { AuthenticationService } from '../../account/services/authentication.service';
import { ShoppingList } from '../models/ShoppingList';
import { ShoppingListsLoggedOut } from '../models/ShoppingListLoggedOut';
import { ShoppingListProduct } from '../models/ShoppingListProduct';

@Component({
  selector: 'app-manual-item-dialog',
  templateUrl: './manual-item-dialog.component.html',
  styleUrls: ['./manual-item-dialog.component.scss']
})
export class ManualItemDialogComponent implements OnInit {

  public manualItemName: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public shoppingListService: ShoppingListService,
    public authService: AuthenticationService,
    public dialogRef: MatDialogRef<ManualItemDialogComponent>) {}

    ngOnInit() {
    }

  public createNewItem(): void {
    if (this.isLoggedIn())
    {
      const body = {
        "ShoppingListId": this.data.id,
        "ProductId": null,
        "ItemName": this.manualItemName
    }
  
      this.shoppingListService.addToShoppingList(body).subscribe(x => {
        console.log(x);
        this.dialogRef.close();
      });
    }
    else {
      var loggedOutLists = localStorage.getItem('shoppingLists');
      var allLoggedOutLists: ShoppingListsLoggedOut = JSON.parse(loggedOutLists);
  
      var list = allLoggedOutLists.shoppingLists.find(y => y.name === this.data.name);
      if (!list.products)
      {
        list.products = new Array<ShoppingListProduct>();
      }
      list.products.push({productName: this.manualItemName, retailerName: '', productId: null, isManualItem: true })

      var listIndex = allLoggedOutLists.shoppingLists.findIndex(y => y.name === this.data.name);
      allLoggedOutLists.shoppingLists[listIndex] = list;
      localStorage.setItem("shoppingLists", JSON.stringify(allLoggedOutLists));
      this.dialogRef.close();
    }
  }
  
  public isLoggedIn() {
    return this.authService.isLoggedIn();
  }
}
