import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { AddressService } from '../../services/address-service';
import { Address } from '../../../account/entities/address';

@Component({
  selector: 'app-view-all-addresses',
  templateUrl: './view-all-addresses.component.html',
  styleUrls: ['./view-all-addresses.component.scss']
})
export class ViewAllAddressesComponent implements OnInit, OnChanges {

  constructor(public addressService: AddressService) { }

  public addresses: Array<Address>;

  public allAddresses: Array<Address>;

  public updateAddress: boolean = false;

  @Input()
  searchText: string;

  @Output()
  public selectedAddress: EventEmitter<Address> = new EventEmitter();

  @Input()
  viewOnlyFavourites: boolean;

  ngOnInit(): void {
    this.addressService.getAll().subscribe((x) => {
      this.addresses = x
      this.allAddresses = x
      if (this.viewOnlyFavourites)
      {
        this.addresses = this.addresses.filter(x => x.isFavourite);
      }
      // this.emitAddresses.emit(this.allAddresses)
      console.log(this.addresses);
    });
  }

  ngOnChanges(): void {
    if (this.viewOnlyFavourites) {
      if (!this.addresses) {
        this.addressService.getAll().subscribe((x) => {
          this.addresses = x
          this.allAddresses = x
          // this.emitAddresses.emit(this.allAddresses)
          this.addresses = this.addresses.filter(x => x.isFavourite);
        });
      }
      else {
        this.addresses = this.addresses.filter(x => x.isFavourite);

      }
    }
    else {
      this.addresses = this.allAddresses;
    }
  }

  public editAddress(address: Address) {
    this.updateAddress = true;
    this.selectedAddress.emit(address);
  }

  public toggleFavourite(address: Address) {
    //TODO catch error
    this.addressService.favouriteAddress(address.addressId, !address.isFavourite)
    .subscribe((x) => 
    {
      address.isFavourite = !address.isFavourite;
    })
    {

    }
  }
}
