<div class="container main-container">
    <div class="confirmation-container">
        <div class="posta-id-container align-text-center">
            <img class="logo-size" src="{{cdnPath}}/images/logo_posta.svg" />
            <h1 class="header-grey-title">{{'buy_postage.confirmation_page.posta_id' | translate}} {{orderConfirmationDetails.orderReference}}</h1>
        </div>
        <div class="transact-details-container align-text-center">
            <div class="align-text-center transact-details">
                <strong class="header-grey">{{'buy_postage.confirmation_page.transaction_ref' | translate}}:&nbsp;</strong>
                <div class="header-grey">{{orderConfirmationDetails.transactionReference}}&nbsp;&nbsp;&nbsp;</div>
                <strong class="header-grey">{{'buy_postage.confirmation_page.order_date' | translate}}:&nbsp;</strong>
                <div class="header-grey">{{orderConfirmationDetails.createdDateTime | date:'dd/MM/yyyy'}}&nbsp;&nbsp;&nbsp;</div>
                <strong class="header-grey">{{'buy_postage.confirmation_page.expiry' | translate}}:&nbsp;</strong>
                <div class="header-grey">{{orderConfirmationDetails.expiryDate | date:'dd/MM/yyyy'}}&nbsp;&nbsp;&nbsp;
                </div>
            </div>
        </div>
        <!-- <div class="info-container">
            <div class="info-text-container align-center">
                <div class="white info-text">
                    {{'buy_postage.confirmation_page.use_code_instructions' | translate}}
                    <br><br>
                    {{'buy_postage.confirmation_page.view_codes_instructions' | translate}}<a class="info-text" [routerLink]=""
                        style="color: white; font-weight: bold;">{{'buy_postage.confirmation_page.orders' | translate}}</a>
                </div>
            </div>
            <img class="info-image" src="/assets/icons/buy-postage/envelope@3x.png">
        </div> -->
        <div *ngIf="orderConfirmationDetails.isCDSFiled" class="share-cn22-container">
            <div class="align-text-center" style="padding-bottom: 30px;">
                <h3 class="white" style="font-weight: 600;">{{'buy_postage.confirmation_page.custom_requirements' | translate}}</h3>
            </div>
            <h4 class="white" style="padding-bottom: 30px">{{'buy_postage.confirmation_page.need_custom_forms' | translate}}</h4>
            <div class="cn22-options">
                <button class="primary-button border-white download-button cn22-button" (click)="downloadCN22()">
                    <div class="spinner">
                        <span>{{'buy_postage.confirmation_page.download_cn22' | translate}}</span>
                        <mat-spinner *ngIf="downloadingCN22" class="spinner-object" diameter="15">
                        </mat-spinner>
                    </div>
                </button>
                <div class="align-center customs-email-form">
                    <mat-form-field class="email-input" floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{'buy_postage.confirmation_page.share_order' | translate}}" id="email-cn22" name="email-cn22"
                            [(ngModel)]="cn22Email" required>
                    </mat-form-field>
                    <button class="primary-button share-email-button border-white" (click)="sendCN22Email()">
                        <div class="spinner">
                            <img class="share-icon-size" src="/assets/icons/buy-postage/share@3x.png" />
                            <div class="email-button-text">{{'buy_postage.confirmation_page.share' | translate}}</div>
                            <mat-spinner *ngIf="sendingCN22Email" class="spinner-object" diameter="15">
                            </mat-spinner>
                        </div>
                    </button>
                </div>
            </div>
            <div class="row" style="padding: 15px 0px 15px 255px;">
                <h5 *ngIf="showEmailCN22Success" style="font-weight: bold; margin: 0px; color: white">{{'buy_postage.confirmation_page.order_sent_to' | translate}} {{emailCN22SentTo}}</h5>
                <div *ngIf="!showEmailCN22Success" style="height: 18px; width:100%; clear:both"></div>
            </div>
        </div>
        <div class="postage-code-container align-text-center"
            *ngFor="let postageItem of orderConfirmationDetails.postageItems; let i = index">
            <h4 class="grey-text" style="font-weight: bold;">{{'buy_postage.confirmation_page.item' | translate}} {{i+1}} {{'buy_postage.confirmation_page.postage_code' | translate}}</h4>
            <div>{{postageItem.customerReference}}</div>
            <br>
            <h1>{{postageItem.postageCode}}</h1>
        </div>
        <div class="share-order-container">
            <div class="share-order-buttons align-center">
                <button class="secondary-button download-button" (click)="downloadReceipt()">
                    <div class="spinner">
                        <span class="download-button-text">{{'buy_postage.confirmation_page.download_pdf_order' | translate}}</span>
                        <mat-spinner *ngIf="downloadingPdfOrder" class="spinner-object" diameter="15">
                        </mat-spinner>
                    </div>
                </button>
                <div class="align-center share-order-email">
                    <mat-form-field class="email-input" floatLabel="never" appearance="outline">
                        <input matInput placeholder="{{'buy_postage.confirmation_page.share_order' | translate}}" id="email" name="email" [(ngModel)]="email"
                            required>
                    </mat-form-field>
                    <button class="primary-button share-email-button" (click)="sendEmail()"><img class="share-icon-size"
                            src="/assets/icons/buy-postage/share@3x.png" />{{'buy_postage.confirmation_page.share' | translate}}</button>
                </div>
                <button *ngIf="!!item1Link" class="secondary-button show-tablet view-instructions-button" (click)="openRouteInNewWindow(item1Link.Url)">{{item1Link.Title}}</button>
            </div>
            <div class="align-center tablet-share-order-email">
                <mat-form-field class="email-input" floatLabel="never" appearance="outline">
                    <input matInput placeholder="{{'buy_postage.confirmation_page.share_order' | translate}}" id="email" name="email" [(ngModel)]="email"
                        required>
                </mat-form-field>
                <button class="primary-button share-email-button" (click)="sendEmail()"><img class="sha6re-icon-size"
                        src="/assets/icons/buy-postage/share@3x.png" /><div class="email-button-text">{{'buy_postage.confirmation_page.share' | translate}}</div></button>
            </div>
            <div class="row" style="padding: 15px 0px 15px 255px;">
                <h5 *ngIf="showEmailOrderSuccess" style="font-weight: bold; margin: 0px;">{{'buy_postage.confirmation_page.order_sent_to' | translate}} {{emailSentTo}}
                </h5>
                <div *ngIf="!showEmailOrderSuccess" style="height: 18px; width:100%; clear:both"></div>
            </div>
            <div class="row hide-tablet" *ngIf="!!item1Link">
                <div class="col-lg-12">
                    <button class="full-width secondary-button" (click)="openRouteInNewWindow(item1Link.Url)">{{item1Link.Title}}</button>
                </div>
            </div>
        </div>
        <div class="info-container">
            <div class="info-text-container align-center">
                <div class="white info-text">
                    {{'buy_postage.confirmation_page.use_code_instructions' | translate}}
                    <br><br>
                    {{'buy_postage.confirmation_page.view_codes_instructions' | translate}}<a class="info-text" [routerLink]="['../../my-posta/orders']"
                        style="color: white; font-weight: bold;">{{'buy_postage.confirmation_page.orders' | translate}}</a>
                </div>
            </div>
            <img class="info-image" src="/assets/icons/buy-postage/envelope@3x.png">
        </div>
    </div>
</div>