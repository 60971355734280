import { Property } from "../../shared/price-matrix/entities/property";
import { TranslationString } from "../../shared/price-matrix/entities/TranslationString";
import { ProductType } from "../../shared/price-matrix/enums/ProductType";


export class ServiceOption {
    Key: number;
    Name: TranslationString;
    Cost: number;
    VATCost: number;
    InsuranceCost?: number;
    DeliveryDays: string;
    Description: TranslationString;
    IconPath: string;
    Properties: Array<Property>;
    MinDeliveryDays: number;
    ProductType: ProductType;
    ManagedCustomCost?: number;
    ManagedCustomCostJson?: string;
    BarcodePrefix: string;
    isVatIncluded: boolean;
}

export class TestServiceOption {
    Key: number;
    Name: TranslationString;
    Cost: number;
    InsuranceCost?: number;
    DeliveryDays: string;
    Description: TranslationString;
    IconPath: string;
    KeyIconPaths: Array<string>;
    MinDeliveryDays: number;
    ProductType: ProductType;
    ManagedCustomCost?: number;
    ManagedCustomCostJson?: string;
    BarcodePrefix: string;
    UsingWeightPivotPoint: boolean;
    BaseIncludeVAT: boolean;
  BasePrice: number;
  BasePriceVAT: number = 0;
  BaseCostOverride: number;
  AdditionalVolumeCost: number;
  AdditionalWeightCost: any;
  IncoTermBasePrice: Array<number> = new Array<number>();
  IncoTermWeightCost: Array<number> = new Array<number>();
  IncoTermVATCost: Array<number> = new Array<number>();
  IncoTermVolumeCost: Array<number> = new Array<number>();
  IncoTermValueCost: Array<number> = new Array<number>();
  TotalIncoTermCost: number = 0;
  InsuranceWeightCost: Array<number> = new Array<number>();
  InsuranceValueCost: Array<number> = new Array<number>();
  InsuranceVolumeCost: Array<number> = new Array<number>();
  TotalInsuranceCost: number = 0;
  InsuranceVAT: number = 0;
  InsuranceBasePrice: Array<number> = new Array<number>();
  TotalBaseCost: number;
  IsDHL?: boolean;
}
