<div class="container" *ngIf="!!categories">
    <!-- <div class="col-lg-6 col-md-12 image-container">
        <img *ngIf="!!image" class="image" [src]="image" />
    </div> -->
    <h1 class="page-title">{{'bera.brochures_products.title' | translate}}</h1>
    <div class="offer-brochure-button-container">
        <div class="row">
            <div class="col-md-6 col-6" style="padding-right: 0px;">
                <button [ngClass]="isBrochuresSelected ? 'primary-button' : 'tertiary-button'" class="full-width"
                    (click)="onBrochureProductChange(true)">{{'bera.brochures_products.brochures' | translate}}</button>
            </div>
            <div class="col-md-6 col-6" style="padding-left: 0px;">
                <button [ngClass]="!isBrochuresSelected ? 'primary-button' : 'tertiary-button'" class="full-width"
                    (click)="onBrochureProductChange(false)">{{'bera.brochures_products.products' | translate}}</button>
            </div>
        </div>
    </div>

    <!-- <div *ngIf="pdfContent">
        <pdf-viewer [src]="pdfContent"
              style="width: 400px; height: 500px">
        </pdf-viewer>
    </div> -->

    <div class="row" style="margin: 0px;">
        <div class="col-lg-6 col-md-12 col-12 search-container">
            <mat-form-field class="search-address-col" floatLabel="never" appearance="outline">
                <input class="text-input" matInput placeholder="{{'bera.brochures.search_products' | translate}}"
                    type="text" name="search" [(ngModel)]="searchText" autocomplete="off"
                    (keyup.enter)="filterProductsByName()">
            </mat-form-field>
            <div class="address-menu-item">
                <button class="primary-button search-button" (click)="filterProductsByName()">
                    <img class="search-icon" src="/assets/icons/address_book/search_white@3x.png" />
                </button>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12 full-width" style="padding-right: 0px;">
            <div class="row filter-row">
                <div class="col-lg-8 col-md-8 col-8">
                    <mat-form-field class="full-width" floatLabel="never" appearance="outline" [formGroup]="offerProductForm">
                        <mat-select disableOptionCentering  placeholder="{{'bera.brochures.view_all_categories' | translate}}" (selectionChange)="updateByCategories($event)"
                            formControlName="category" autocomplete="category" panelClass="select-panel">
                            <mat-option *ngFor="let category of categories" [value]="category">{{category.categoryName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-4 col-4">
                    <button class="full-width tertiary-button view-all-btn" (click)="reset()">{{'bera.brochures.view_all' | translate}}</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!!brochures && isBrochuresSelected" class="row" style="padding: 0 15px">
        <div *ngFor="let brochure of brochures; let i = index" class="col-xl-6 col-lg-6 col-md-12 col-12 brochure-col-container">
            <div class="image-product-brochure-container">
                <div class="product-img-container" (click)="viewBrochure(brochure.thumbPath, i)">
                    <img class="brochure-img" [style.width.px]="brochure.width" [style.height.px]="brochure.height" src={{brochure.imageUrl}} />
                </div>
                <div class="d-flex">
                    <button class="tertiary-button brochure-button" (click)="viewBrochure(brochure.thumbPath, i)">{{'bera.brochures.view_brochure' | translate}}<mat-spinner *ngIf="loadingBrochure && selectedBrochureIndex == i" class="loading-spinner" diameter="15"></mat-spinner></button>
                </div>
            </div>
            <div class="product-info-container">
                <div>
                    <h2 class="product-title">{{brochure.publisherName}}</h2>
                    <div *ngIf="brochure.validStart || brochure.validEnd" class="available-until-title">{{'bera.brochures.offer_details' | translate}}</div>
                    <div *ngIf="brochure.validStart" class="offer-tag">{{'bera.brochures.from' | translate}}: {{brochure.validStart | date:'dd.MM.yy'}}</div>
                    <div *ngIf="brochure.validEnd" class="offer-tag">{{'bera.brochures.until' | translate}}: {{brochure.validEnd | date:'dd.MM.yy'}}</div>
                </div>
                <div class="flex-end">
                    <button class="primary-button secondary-brochure-button" (click)="onBrochureProductChange(false, brochure.brochureId)">{{'bera.brochures.view_products' | translate}}</button>
                </div>
            </div>
            <!-- <div class="d-flex secondary-button-container">
                <div>
                    <h2 class="product-title">{{brochure.publisherName}}</h2>
                    <div *ngIf="brochure.validStart || brochure.validEnd" class="available-until-title">Offer details</div>
                    <div *ngIf="brochure.validStart" class="offer-tag">From: {{brochure.validStart | date:'dd.MM.yy'}}</div>
                    <div *ngIf="brochure.validEnd" class="offer-tag">Until: {{brochure.validEnd | date:'dd.MM.yy'}}</div>
                </div>
                <button class="primary-button secondary-brochure-button" (click)="onBrochureProductChange(false, brochure.brochureId)">View Products</button>
            </div> -->
        </div>
    </div>

    <div *ngIf="!!products && !isBrochuresSelected" class="row" style="padding: 0 15px">
        <div *ngFor="let product of products; let i = index" class="col-xl-6 col-lg-6 col-md-12 col-12 brochure-col-container">
            <div class="image-product-brochure-container">
                <div class="product-img-container" (click)="viewBrochureByProduct(product.brochureId, product.pageIndex, i)">
                    <img class="product-img" [style.width.px]="product.width" [style.height.px]="product.height" src={{product.imageUrl}}/>
                </div>
                <div class="d-flex">
                    <button class="tertiary-button brochure-button" (click)="viewBrochureByProduct(product.brochureId, product.pageIndex, i)">{{'bera.brochures.view_brochure' | translate}}<mat-spinner *ngIf="loadingBrochure && selectedBrochureIndex == i" class="loading-spinner" diameter="15"></mat-spinner></button>
                </div>
            </div>
            <div class="product-info-container">
                <div>
                    <h2 class="product-title">{{product.name}}</h2>
                    <div *ngIf="product.price" class="price-title">{{'bera.brochures.price' | translate}}</div>
                    <div *ngIf="product.price" class="price-tag">Kr. {{product.price | currency: 'DKK':''}}</div>
                    <div class="link-container">
                        <span class="product-retailer-link">{{product.retailerName}}</span>
                        <!-- <a href="" class="product-retailer-link">{{product.retailerName}} ></a> -->
                    </div>
                    <div *ngIf="product.offerUntilDate" class="available-until-title">{{'bera.brochures.offer_details' | translate}}</div>
                    <div *ngIf="product.offerUntilDate" class="offer-tag">{{'bera.brochures.until' | translate}} {{product.offerUntilDate | date:'dd.MM.yy'}}</div>
                </div>
                <div class="flex-end">
                    <button *ngIf="!shoppingListIncludesItem(product.productId)" class="primary-button add-list-button" (click)="addToActiveList(product)"><img class="add-list-icon" style="margin-right: 10px;" src="/assets/icons/shopping-list/Add.png">{{'bera.brochures.add_to_list' | translate}}</button>
                    <button *ngIf="shoppingListIncludesItem(product.productId)" class="primary-button add-list-button" disabled="true"><img class="add-list-icon" style="margin-right: 10px;" src="/assets/icons/shopping-list/Add.png">{{'bera.brochures.added_to_list' | translate}}</button>

                </div>
            </div>
        </div>
    </div>
    <div *ngIf="(!products || products.length === 0) && !isBrochuresSelected && !loading" class="loading-title">
        <h2>{{'bera.products.no_products_found' | translate}}</h2>
    </div>
    <div *ngIf="(!brochures || brochures.length === 0) && isBrochuresSelected && !loading" class="loading-title">
        <h2>{{'bera.brochures.no_brochures_found' | translate}}</h2>
    </div>
    <!-- <div *ngIf="loading">
        <h2 class="loading-title">Loading...</h2>
    </div> -->
    <!-- <div *ngIf="loadingBrochure">
        <h2 class="loading-title">Loading brochure...</h2>
    </div> -->
    <div *ngIf="!hideViewMore" class="full-width view-more-container">
        <button class="tertiary-button full-width view-more-button" (click)="viewMore()">{{'bera.brochures.view_more' | translate}}<mat-spinner *ngIf="loading" class="loading-spinner" diameter="15"></mat-spinner></button>
    </div>
</div>
<div class="toolbar" *ngIf="isLoggedIn()">
    <div class="container toolbar-container">
        <div class="toolbar-contents">
            <img class="shopping-cart-icon" src="/assets/icons/shopping-list/shopping_cart.png">
            <span class="shopping-text">{{'bera.brochures.shopping_list' | translate}} <span class="shopping-count-text">({{(activeList?.itemsCount)}})</span></span>
            <mat-form-field class="shopping-list-dropdown" floatLabel="never" appearance="outline" [formGroup]="offerProductForm">
                <mat-select disableOptionCentering  placeholder="{{'shopping_services.offers_products.active_list_name' | translate}}"
                    formControlName="activeList" panelClass="shopping-list-select" (selectionChange)="updateActiveList($event)">
                    <mat-option *ngFor="let shoppingList of shoppingLists" [value]="shoppingList">{{shoppingList.shoppingListName}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" placement="top" ngbTooltip="{{'bera.toolbar.view_list' | translate}}" tooltipClass="tooltip-secondary" class="btn toolbar-button" (click)="navigateToViewList()"><img class="view-icon" src="/assets/icons/shopping-list/View.png"></button>
            <button type="button" placement="top" ngbTooltip="{{'bera.toolbar.share_list' | translate}}" tooltipClass="tooltip-secondary" class="toolbar-button toolbar-button-middle" (click)="createShareLink()"><img class="share-icon" src="/assets/icons/shopping-list/Share.png"></button>
            <button type="button" placement="top" ngbTooltip="{{'bera.toolbar.edit_lists' | translate}}" tooltipClass="tooltip-secondary" class="toolbar-button toolbar-button-last" (click)="navigateToEditLists()"><img class="edit-icon" src="/assets/icons/shopping-list/EditList.png"></button>
            <button type="button" placement="top" ngbTooltip="{{'bera.toolbar.add_list' | translate}}" tooltipClass="tooltip-secondary" class="toolbar-button toolbar-button-last" (click)="addNewShoppingList()"><img class="add-list-icon" src="/assets/icons/shopping-list/Add.png"></button>
        </div>
    </div>
</div>
<div class="toolbar" *ngIf="!isLoggedIn()">
    <div class="container toolbar-container">
        <div class="toolbar-contents">
            <img class="shopping-cart-icon" src="/assets/icons/shopping-list/shopping_cart.png">
            <span class="shopping-text">{{'bera.brochures.shopping_list' | translate}} <span class="shopping-count-text">({{!!activeListLoggedOut?.products ? activeListLoggedOut?.products.length : 0}})</span></span>
            <mat-form-field class="shopping-list-dropdown" floatLabel="never" appearance="outline" [formGroup]="offerProductForm">
                <mat-select disableOptionCentering placeholder="{{'shopping_services.offers_products.active_list_name' | translate}}"
                    formControlName="activeListLoggedOut" panelClass="shopping-list-select" (selectionChange)="updateActiveList($event)">
                    <mat-option *ngFor="let loggedList of loggedOutShoppingLists?.shoppingLists" [value]="loggedList">{{loggedList.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" placement="top" ngbTooltip="{{'bera.toolbar.view_list' | translate}}" tooltipClass="tooltip-secondary" class="btn toolbar-button" (click)="navigateToViewList()"><img class="view-icon" src="/assets/icons/shopping-list/View.png"></button>
            <button type="button" placement="top" ngbTooltip="{{'bera.toolbar.edit_lists' | translate}}" tooltipClass="tooltip-secondary" class="toolbar-button toolbar-button-middle" (click)="navigateToEditLists()"><img class="edit-icon" src="/assets/icons/shopping-list/EditList.png"></button>
            <button type="button" placement="top" ngbTooltip="{{'bera.toolbar.add_list' | translate}}" tooltipClass="tooltip-secondary" class="toolbar-button toolbar-button-last" (click)="addNewShoppingList()"><img class="add-list-icon" src="/assets/icons/shopping-list/Add.png"></button>
        </div>
    </div>
</div>