import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup } from '@angular/forms';
import { PriceMatrixService } from '../../../../../shared/price-matrix/services/price-matrix.service';
import { FindServiceOptionDetails } from '../../../../../shared/price-matrix/entities/FindServiceOptionDetails';
import { TransportType } from '../../../../../shared/price-matrix/entities/TransportType';
import { ServiceType } from '../../../../../shared/price-matrix/enums/ServiceType';
import { TranslationString } from '../../../../../shared/price-matrix/entities/TranslationString';
import { TranslateService } from '@ngx-translate/core';
import { SendReceiveService } from '../../services/send-receive.service';
import { Country } from '../../../../../account/entities/Country';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { OrderState } from '../../../../enums/OrderState';
import { Order } from '../../../../entities/Order';
import { OrderLetter } from '../../../../entities/OrderLetter';
import { OrderService } from '../../../../entities/OrderService';
import { Parcel } from '../../../../entities/parcel';
import { ServiceOption } from '../../../../entities/serviceOption';
import { InvoiceLine } from '../../../../entities/InvoiceLine';
import { IncoTerm } from '../../../../../shared/price-matrix/entities/incoTerm';
import { ProductType } from '../../../../../shared/price-matrix/enums/ProductType';
import { Pricing } from '../../../../../shared/price-matrix/entities/pricing';
import { IncoTermsGroup } from '../../../../../shared/price-matrix/entities/incoTermsGroup';
import { TranslationHelper } from '../../../../../shared/helpers/translation-helper';
import { StaticContentHelper } from '../../../../../shared/helpers/static-content-helper';
import { Router } from '@angular/router';
import { StaticContentService } from '../../../../../shared/services/static-content.service';
import { RoleService } from '../../../../../account/services/role.service';
import { Role } from '../../../../../shared/price-matrix/enums/Role';

@Component({
  selector: 'app-send-receive-prices',
  templateUrl: './send-receive-prices.component.html',
  styleUrls: ['./send-receive-prices.component.scss', '../send-receive-styles.scss']
})
export class SendReceivePricesComponent implements OnInit {

  public linkItem1: any;

  public linkItem2: any;
  public linkItem3: any;
  public linkItem4: any;

  @Input()
  sendReceiveForm: FormGroup;

  @Input()
  addressForm: FormGroup;

  @Input()
  isDomestic: boolean;

  @Input()
  public incoTerms: string;

  public serviceOptions: Array<ServiceOption> = new Array<ServiceOption>();

  @Input()
  insuranceSelected: boolean;

  @Input()
  totalVolume: number;

  @Input()
  productType: ProductType;

  @Input()
  fromDepot: boolean;

  @Input()
  toDepot: boolean;

  @Input()
  fromAccountAddress: boolean;

  @Input()
  toAccountAddress: boolean;

  @Input()
  parcels: Array<Parcel>;

  @Input()
  order: Order;

  interval: any;

  @Output()
  chosenServiceOption: EventEmitter<ServiceOption> = new EventEmitter();

  @Output()
  proceedToOrderDetailsScreen: EventEmitter<boolean> = new EventEmitter();

  @Output()
  proceedToConfirmationPage: EventEmitter<boolean> = new EventEmitter();

  @Output()
  quoteId: EventEmitter<string> = new EventEmitter();

  savedServiceOption: ServiceOption;

  savedServiceOptionIndex: number;
  vatCost: number = 0;

  constructor(private priceMatrixService: PriceMatrixService,
    private translateService: TranslateService,
    private translateHelper: TranslationHelper,
    private sendReceiveService: SendReceiveService,
    private staticContentService: StaticContentService,
    private roleService: RoleService,
    private router: Router) { }

  ngOnInit(): void {
    
    var vol = this.totalVolume;

    var quantity = this.sendReceiveForm.get("noOfItems").value;
    var isItems = this.sendReceiveForm.get("isItems").value;

    if (isItems != 'true')
    {
      vol = this.sendReceiveForm.get("noOfItems").value;
      quantity = 1;
    }

    var weight = this.translateHelper.getNumber(this.sendReceiveForm.get("totalWeight").value);

    if (this.sendReceiveForm.get("isKg").value == 'false')
    {
      weight /= 1000;
    }

    var isOneParcel: boolean = false;
    if (this.sendReceiveForm.get("noOfItems").value == 1)
    {
      isOneParcel = true;
    }
  
    if (this.isDomestic)
    {
      vol = this.totalVolume/+quantity;
    }

    var serviceOptionDetails: FindServiceOptionDetails = {
      FromCountry: this.fromDepot ? "DK" : this.addressForm.get("fromCountry").value.countryCode,
      ToCountry: this.toDepot ? "DK" : this.addressForm.get("toCountry").value.countryCode,
      FromPostcode: this.addressForm.get("fromPostcode").value,
      ToPostcode: this.addressForm.get("toPostcode").value,
      Volume: this.translateHelper.getNumber(vol),
      Weight: weight,
      Value: this.translateHelper.getNumber(this.sendReceiveForm.get("valueOfGoods").value),
      IsInsurance: this.insuranceSelected,
      ProductType: this.productType,
      IsOneParcel: isOneParcel,
      IncoTerms: this.incoTerms,
      Quantity: +quantity,
      Role: this.roleService.role()
    }

    this.priceMatrixService.getServiceOptions(serviceOptionDetails).subscribe(transportTypes => {
      //loop through transport types and set fields

      var i: number;
      for (i = 0; i < transportTypes.length; i++) {

        var managedCustomCost: number = undefined;
        var managedCustomCostJson: string = undefined;
        var insuranceCost: number = undefined;
        var insuranceExtra = undefined;
        var managedCustomExtra = undefined;
        var specialExtrasOverrides = undefined;
        if (!!transportTypes[i].CountryGroups)
        {
          specialExtrasOverrides = transportTypes[i].CountryGroups[0].SpecialExtrasOverrides;
        }

        if (!!transportTypes[i].SpecialExtras) {
          insuranceExtra = transportTypes[i].SpecialExtras.find(x => x.Key == "0");
          managedCustomExtra = transportTypes[i].SpecialExtras.find(x => x.Key == "1");
        }

        //Clean this up
        if (!!transportTypes[i].SpecialExtras || !!specialExtrasOverrides) {
          //use first country group as it's the only one returned from the backend

          if (!!specialExtrasOverrides) {
            var insuranceOverride = specialExtrasOverrides.find(x => x.Key == "0")
            if (!!insuranceOverride && this.insuranceSelected) {
              // insuranceCost = this.getSpecialExtraCost(insuranceOverride, serviceOptionDetails.Value, serviceOptionDetails.Weight, serviceOptionDetails.Volume);
              insuranceCost = this.getPricingForTransportType(new Array<Pricing>(insuranceOverride), false, transportTypes[i].VATPercentage, true)
            }
            else if (!!insuranceExtra && this.insuranceSelected) {
              // insuranceCost = this.getSpecialExtraCost(insuranceExtra, serviceOptionDetails.Value, serviceOptionDetails.Weight, serviceOptionDetails.Volume);
              insuranceCost = this.getPricingForTransportType(new Array<Pricing>(insuranceExtra), false, transportTypes[i].VATPercentage, true);
            }

            var managedCustomOverride = specialExtrasOverrides.find(x => x.Key == "1");
            if (!!managedCustomOverride) {
              // managedCustomCost = this.getSpecialExtraCost(managedCustomOverride, serviceOptionDetails.Value, serviceOptionDetails.Weight, serviceOptionDetails.Volume);
              managedCustomCost = this.getPricingForTransportType(new Array<Pricing>(managedCustomOverride), false, transportTypes[i].VATPercentage, false);
              managedCustomCostJson = JSON.stringify(managedCustomExtra);
            }
            else if (!!managedCustomExtra) {
              // managedCustomCost = this.getSpecialExtraCost(managedCustomExtra, serviceOptionDetails.Value, serviceOptionDetails.Weight, serviceOptionDetails.Volume);
              managedCustomCost = this.getPricingForTransportType(new Array<Pricing>(managedCustomExtra), false, transportTypes[i].VATPercentage, false);
              managedCustomCostJson = JSON.stringify(managedCustomExtra);
            }
          }
          else if (!!transportTypes[i].SpecialExtras) {

            if (!!managedCustomExtra) {
              managedCustomCost = this.getPricingForTransportType(new Array<Pricing>(managedCustomExtra), false, transportTypes[i].VATPercentage, false);
              managedCustomCostJson = JSON.stringify(managedCustomExtra);
            }

            if (!!insuranceExtra && this.insuranceSelected) {
              insuranceCost = this.getPricingForTransportType(new Array<Pricing>(insuranceExtra), false, transportTypes[i].VATPercentage, true);
            }
          }
        }

        //You can only buy 1 stamp per send receive order so having multiple parcels defined goes against this logic.
        // if ((transportTypes[i].ProductTypeId == ProductType.Postage || transportTypes[i].ProductTypeId == ProductType.Postage_Plus || transportTypes[i].ProductTypeId == ProductType.Parcel) && this.sendReceiveForm.get('noOfItems').value > 1) {
        //   continue;
        // }

        //If transport type has a country group then it's not domestic otherwise it is.
        var vatIncluded = !!transportTypes[i].CountryGroups ? transportTypes[i].CountryGroups[0].IncludeVAT : transportTypes[i].IncludeVAT;
        var minDays = !!transportTypes[i].MinimumDeliveryDays ? transportTypes[i].MinimumDeliveryDays : transportTypes[i].CountryGroups[0].MinimumDeliveryDays;
        var maxDays = !!transportTypes[i].MaximumDeliveryDays ? transportTypes[i].MaximumDeliveryDays : transportTypes[i].CountryGroups[0].MaximumDeliveryDays;

        var serviceOption = new ServiceOption();
        serviceOption.IconPath = transportTypes[i].IconPath;
        serviceOption.Name = transportTypes[i].Name;
        serviceOption.Cost = this.getCostForTransportType(transportTypes[i], insuranceCost);
        serviceOption.DeliveryDays = `${minDays} - ${maxDays}`;
        serviceOption.Description = transportTypes[i].Description;
        serviceOption.Properties = transportTypes[i].Properties;
        serviceOption.Key = transportTypes[i].Key;
        serviceOption.MinDeliveryDays = minDays;
        serviceOption.ProductType = transportTypes[i].ProductTypeId;
        serviceOption.InsuranceCost = insuranceCost ? +insuranceCost.toFixed(2) : 0;
        serviceOption.ManagedCustomCost = managedCustomCost;
        serviceOption.ManagedCustomCostJson = managedCustomCostJson;
        serviceOption.BarcodePrefix = transportTypes[i].BarcodePrefix;
        serviceOption.isVatIncluded = vatIncluded;
        serviceOption.VATCost = +this.vatCost.toFixed(2);

        this.serviceOptions.push(serviceOption);
        this.vatCost = 0;
      }

      if (!!this.order) {
        this.serviceOptions = this.serviceOptions.filter(x => Number(x.ProductType) == this.order.productTypeId);
        var orderServiceOption = this.serviceOptions.find(x => Number(x.ProductType) == this.order.productTypeId);
        if (!!orderServiceOption) {
          this.chosenServiceOption.emit(orderServiceOption);
        }

        var transportType = JSON.parse(this.order.service.transportTypeJson);

        this.savedServiceOption = this.serviceOptions.find(x => x.Name.EN == transportType.Name.EN);

        if (!!this.savedServiceOption) {
          this.savedServiceOptionIndex = this.serviceOptions.indexOf(this.savedServiceOption);
          console.log(`Found matching service option:${this.savedServiceOption.Name.EN}`);

        }
      }
    })

    var psModule = this.staticContentService.getPsModuleContent('send-receive', 'send-receive/send-receive');
    if (!!psModule) {
      this.linkItem1 = psModule.Item1Link;
      this.linkItem2 = psModule.Item2Link;
      this.linkItem3 = psModule.Item6Link;
      this.linkItem4 = psModule.Item7Link;
    }

    this.interval = setInterval(() => {
      var el = document.getElementsByClassName("key-background-container");
      if (!!el && el.length > 0)
      {
        el[0].scrollIntoView(true);
        clearInterval(this.interval);
      }
    }, 200);
  }

  public isToFromFo() {
    if (this.fromDepot || this.toDepot) {
      //Depot is DK, so won't be from FO
      return false;
    }

    var fromCountryControl = this.addressForm.get('fromCountry');
    var toCountryControl = this.addressForm.get('toCountry');

    if ((!!fromCountryControl && !!fromCountryControl.value && (fromCountryControl.value.countryCode == "FO"
      || fromCountryControl.value == "Faroe Islands")) && (!!toCountryControl && !!toCountryControl.value && (toCountryControl.value.countryCode == "FO"
        || toCountryControl.value == "Faroe Islands"))) {
          return true;
    }

    return false;
  }

  public isInternationalGoods(){
    var valueControl = this.sendReceiveForm.get('valueOfGoods');
    if(!!valueControl && !!valueControl.value && valueControl.value <= 0){
      //No value, so not goods
      return false;
    }

    var fromCountryControl = this.addressForm.get('fromCountry');
    if(!!fromCountryControl && !!fromCountryControl.value && fromCountryControl.value.countryCode == "FO" || fromCountryControl.value == "Faroe Islands"){
      if(this.toDepot) {
        //Depot is DK, so won't be to FO
        return true;
      }
      var toCountryControl = this.addressForm.get('toCountry');
      if(toCountryControl && toCountryControl.value && toCountryControl.value.countryCode != "FO" && toCountryControl.value != "Faroe Islands"){
        return true;
      }
    }
  }

  public chooseServiceOption(serviceOption: ServiceOption) {
    this.chosenServiceOption.emit(serviceOption);
    if ((serviceOption.ProductType == 4 && !this.isInternationalGoods()) || serviceOption.ProductType == 8 || serviceOption.ProductType == 10 && this.isToFromFo()) {
      this.proceedToConfirmationPage.emit(true);
      this.proceedToOrderDetailsScreen.emit(false);
      document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    else {
      this.proceedToOrderDetailsScreen.emit(true);
      document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }

  public getDeliveryDays(minDays: any, maxDays: any): string {
    return `${minDays} - ${maxDays}`;
  }

  public getIconTextByLanguage(translationName: TranslationString)
  {
    switch (this.translateService.currentLang) {
      case "en":
        return translationName.EN;
      case "fo":
        return translationName.FO;
    }
  }

  public getNameByLanguage(transportTypeName: TranslationString): string {
    if (!!transportTypeName)
    {
      switch (this.translateService.currentLang) {
        case "en":
          return transportTypeName.EN;
        case "fo":
          return transportTypeName.FO;
      }
    }
  }


  public getSpecialExtraCost(extra: Pricing, inputValue?: number, inputWeight?: number, inputVolume?: number): number {
    var extraCost = extra.BasePrice;
    if (extra.ValueInterval > 0 && extra.PricePerValueInterval > 0) {
      if (extra.StartValue > 0 && inputValue > extra.StartValue) {
        extraCost += Math.ceil((inputValue - extra.StartValue) / extra.ValueInterval) * extra.PricePerValueInterval;
      }
      else if (extra.StartValue < 0) {
        extraCost += Math.ceil(inputValue / extra.ValueInterval) * extra.PricePerValueInterval;
      }
    }

    if (extra.WeightInterval > 0 && extra.PricePerWeightInterval > 0) {
      if (extra.StartWeight > 0 && inputWeight > extra.StartWeight) {
        extraCost += Math.ceil((inputWeight - extra.StartWeight) / extra.WeightInterval) * extra.PricePerWeightInterval;
      }
      else if (extra.StartWeight < 0) {
        extraCost += Math.ceil(inputWeight / extra.WeightInterval) * extra.PricePerWeightInterval;
      }
    }

    if (extra.VolumeInterval > 0 && extra.PricePerVolumeInterval > 0) {
      if (extra.StartVolume > 0 && inputVolume > extra.StartVolume) {
        extraCost += Math.ceil((inputVolume - extra.StartVolume) / extra.VolumeInterval) * extra.PricePerVolumeInterval;
      }
      else if (extra.StartVolume < 0) {
        extraCost += Math.ceil(inputVolume / extra.VolumeInterval) * extra.PricePerVolumeInterval;
      }
    }
    if (!extraCost) {
      return 0;
    }
    return +extraCost.toFixed(2);
  }

  public saveQuote(serviceOptionSelected: ServiceOption) {
    var orderService: OrderService = {
      transportTypeId: serviceOptionSelected.Key,
      transportTypeJson: null
    }

    var weight = this.translateHelper.getNumber(this.sendReceiveForm.get("totalWeight").value);

    var letter: OrderLetter
    var parcel: Parcel
    const letterFormControl = this.sendReceiveForm.get("letterSize");
    if (!!letterFormControl && !!letterFormControl.value) {
      if (serviceOptionSelected.ProductType == ProductType.Postage) {
        const letterTransportType = letterFormControl.value as TransportType;
        letter = {
          transportTypeId: letterTransportType.Key,
          transportTypeJson: null,
          transportTypeName: letterTransportType.Name.EN
        }
        //if letter then weight is defined in grams, divide by 1000 to pass to backend in kg
        weight /= 1000;
      }
      else if (serviceOptionSelected.ProductType == ProductType.Parcel) {
        //letter option selected but it's being sent as a parcel therefore save as parcel instead.
        parcel = {
          parcelNo: 1
        }
        this.parcels = new Array<Parcel>(parcel);
      }
    }

    //In the future this may be more than one but for now it's just one
    var invoiceLine: InvoiceLine = {
      sequenceNo: 0,
      quantity: 1,
      unitPrice: serviceOptionSelected.Cost,
      netAmount: serviceOptionSelected.Cost,
      grossAmount: serviceOptionSelected.Cost //*this._route.snapshot.data.order;
    }


    var order: Order = {
      orderStateId: OrderState.Quote as number,
      productTypeId: serviceOptionSelected.ProductType,
      fromTitle: this.addressForm.get("fromTitle").value,
      fromFirstName: this.addressForm.get("fromFirstName").value,
      fromLastName: this.addressForm.get("fromLastName").value,
      fromCompanyName: this.addressForm.get("fromOptionalCompany").value,
      fromAddressLine1: this.addressForm.get("fromAddressLine1").value,
      fromAddressLine2: this.addressForm.get("fromAddressLine2").value,
      fromTownCity: this.addressForm.get("fromTownCity").value,
      fromPostcode: this.addressForm.get("fromPostcode").value,
      fromCountryCode: (this.addressForm.get("fromCountry").value as Country).countryCode,
      fromContactNumber: this.addressForm.get("fromContactNumber").value,
      fromContactEmail: this.addressForm.get("fromEmail").value,
      fromSpecialInstructions: this.addressForm.get("fromSpecialInstructions").value,
      toTitle: this.addressForm.get("toTitle").value,
      toFirstName: this.addressForm.get("toFirstName").value,
      toLastName: this.addressForm.get("toLastName").value,
      toCompanyName: this.addressForm.get("toOptionalCompany").value,
      toAddressLine1: this.addressForm.get("toAddressLine1").value,
      toAddressLine2: this.addressForm.get("toAddressLine2").value,
      toTownCity: this.addressForm.get("toTownCity").value,
      toPostcode: this.addressForm.get("toPostcode").value,
      toCountryCode: (this.addressForm.get("toCountry").value as Country).countryCode,
      toContactNumber: this.addressForm.get("toContactNumber").value,
      toContactEmail: this.addressForm.get("toEmail").value,
      toSpecialInstructions: this.addressForm.get("toSpecialInstructions").value,
      senderNamePostaDepot: this.addressForm.get("logisticsSenderName").value,
      isFromPostaDepot: this.fromDepot,
      isToPostaDepot: this.toDepot,
      isFromAccountAddress: this.fromAccountAddress,
      isToAccountAddress: this.toAccountAddress,
      insuranceSelected: this.insuranceSelected,
      customerReference: this.sendReceiveForm.get("referenceNumber").value,
      weight: weight,
      valueOfGoods: this.translateHelper.getNumber(this.sendReceiveForm.get("valueOfGoods").value),
      volume: this.translateHelper.getNumber(this.totalVolume),
      service: orderService,
      parcels: this.parcels,
      invoices: null,
      attemptedPaymentYN: false,
      invoiceLines: [invoiceLine],
      barcodePrefix: serviceOptionSelected.BarcodePrefix,
      isVatIncluded: serviceOptionSelected.isVatIncluded,
      languageCode: this.translateService.currentLang,
      customsItemTypes: null
    }

    //TODO catch error
    this.sendReceiveService.CreateOrder(order).subscribe(x => {
      this.quoteId.emit(x);
    });
  }

  private getPricingForTransportType(pricings: Array<Pricing>, isIncoTerms: boolean, vat: number, addVatCost: boolean): number {
    if (!pricings) {
      return 0;
    }

    var inputWeight = this.translateHelper.getNumber(this.sendReceiveForm.get("totalWeight").value);
    const letterFormControl = this.sendReceiveForm.get("letterSize");
    if (!!letterFormControl && !!letterFormControl.value) {
      inputWeight /= 1000;
    }
    var inputVolume = this.translateHelper.getNumber(this.totalVolume);
    var inputValue = this.translateHelper.getNumber(this.sendReceiveForm.get("valueOfGoods").value);
    var totalCost = 0;

    for (var i = 0; i < pricings.length; i++) {
      var weightCost = 0;
      var valueCost = 0;
      var volumeCost = 0;

      if (pricings[i].BasePriceOnly) {
        if (pricings[i].IncludeVAT)
        {
          var vatToAdd = pricings[i].BasePrice*vat;
          if (addVatCost)
          {
            this.vatCost += vatToAdd;
          }
          totalCost += vatToAdd;
        }
        else {
          totalCost += pricings[i].BasePrice
        }
        continue;
      }

      if (!pricings[i].Fragmented) {
        if (inputWeight >= pricings[i].StartWeight && pricings[i].WeightInterval > 0 && pricings[i].PricePerWeightInterval > 0) {
          var weightDif = inputWeight - pricings[i].StartWeight;
          if (inputWeight == pricings[i].StartWeight) {
            weightDif = 1;
          }
          var absValWeightIntervals = Math.ceil(weightDif / pricings[i].WeightInterval);
          //check if it's a whole number after being divided
          // if (weightDif / pricings[i].WeightInterval - Math.floor(weightDif / pricings[i].WeightInterval) == 0 && inputWeight - pricings[i].StartWeight != 0) {
          //   absValWeightIntervals += 1;
          // }
          weightCost = absValWeightIntervals * pricings[i].PricePerWeightInterval;
        }
        if (inputVolume >= pricings[i].StartVolume && pricings[i].VolumeInterval > 0 && pricings[i].PricePerVolumeInterval > 0) {
          var volumeDif = inputVolume - pricings[i].StartVolume;
          if (inputVolume == pricings[i].StartVolume) {
            volumeDif = 1;
          }
          var absValVolumeIntervals = Math.ceil(volumeDif / pricings[i].VolumeInterval);
          //check if it's a whole number after being divided
          // if (volumeDif / pricings[i].VolumeInterval - Math.floor(volumeDif / pricings[i].VolumeInterval) == 0 && inputVolume - pricings[i].StartVolume != 0) {
          //   absValVolumeIntervals += 1;
          // }
          volumeCost = absValVolumeIntervals * pricings[i].PricePerVolumeInterval;
        }
        if (inputValue >= pricings[i].StartValue && pricings[i].ValueInterval > 0 && pricings[i].PricePerValueInterval > 0) {
          var valueDif = inputValue - pricings[i].StartValue;
          if (inputValue == pricings[i].StartValue) {
            valueDif = 1;
          }
          var absValValueIntervals = Math.ceil(valueDif / pricings[i].ValueInterval);
          //check if it's a whole number after being divided
          // if (valueDif / pricings[i].ValueInterval - Math.floor(valueDif / pricings[i].ValueInterval) == 0 && inputValue - pricings[i].StartValue != 0) {
          //   absValValueIntervals += 1;
          // }
          valueCost = absValValueIntervals * pricings[i].PricePerValueInterval;
        }
        var costToAdd = pricings[i].BasePrice + weightCost + valueCost + volumeCost;
        if (pricings[i].IncludeVAT)
        {
          if (addVatCost)
          {
            this.vatCost += costToAdd*vat;
          }
          costToAdd *= (1+vat);
        }
        totalCost += costToAdd;
      }
      else {
        if (inputWeight >= pricings[i].StartWeight && pricings[i].WeightInterval > 0 && pricings[i].PricePerWeightInterval > 0) {
          var weightDif = inputWeight - pricings[i].StartWeight;
          if (isIncoTerms) {
            weightDif += 1;
          }
          var absValWeightIntervals = weightDif / pricings[i].WeightInterval;
          weightCost = absValWeightIntervals * pricings[i].PricePerWeightInterval;
        }
        if (inputVolume >= pricings[i].StartVolume && pricings[i].VolumeInterval > 0 && pricings[i].PricePerVolumeInterval > 0) {
          var volumeDif = inputVolume - pricings[i].StartVolume;
          if (isIncoTerms) {
            volumeDif += 1;
          }
          var absValVolumeIntervals = volumeDif / pricings[i].VolumeInterval;
          volumeCost = absValVolumeIntervals * pricings[i].PricePerVolumeInterval;
        }
        if (inputValue >= pricings[i].StartValue && pricings[i].ValueInterval > 0 && pricings[i].PricePerValueInterval > 0) {
          var valueDif = inputValue - pricings[i].StartValue;
          if (isIncoTerms) {
            valueDif += 1;
          }
          var absValValueIntervals = valueDif / pricings[i].ValueInterval;
          valueCost = absValValueIntervals * pricings[i].PricePerValueInterval;
        }
        var costToAdd = pricings[i].BasePrice + weightCost + valueCost + volumeCost;
        if (pricings[i].IncludeVAT)
        {
          if (addVatCost)
          {
            this.vatCost += costToAdd*vat;
          }
          costToAdd *= (1+vat);
        }
        totalCost += costToAdd;
      }
    }
    return totalCost;

  }

  private getCostForTransportType(transportType: TransportType, insuranceCost: number): number {
    //From the backend the country group should only return one item so just look at the first item
    var totalCost: number = 0;
    var incoTermCostSet: boolean = false;
    if (!!transportType.CountryGroups && transportType.CountryGroups.length > 0) {
      const countryGroup = transportType.CountryGroups[0];
      //If has base price set it to that otherwise look for the specific parcel range and use the base cost override

      var inputWeight = this.translateHelper.getNumber(this.sendReceiveForm.get("totalWeight").value);
      if (!!transportType.BoundedWeightValue)
      {
        inputWeight = transportType.BoundedWeightValue;
      }
      else if (this.sendReceiveForm.get("isKg").value == 'false')
      {
        inputWeight /= 1000;
      }

      var useWeight = ((inputWeight / this.totalVolume) / 1000) > countryGroup.FreightPivotPoint;

      if (countryGroup.BasePriceWeight !== 0 && useWeight) {
        totalCost = countryGroup.BasePriceWeight;
      }
      else if (countryGroup.BasePriceVolume !== 0) {
        totalCost = countryGroup.BasePriceVolume;
      }

      if (!!countryGroup.ParcelRanges) {
        var i: number;
        for (i = 0; i < countryGroup.ParcelRanges.length; i++) {
          const currentParcelRange = countryGroup.ParcelRanges[i];
          if (inputWeight >= currentParcelRange.MinWeight
            && inputWeight <= currentParcelRange.MaxWeight
            && this.translateHelper.getNumber(this.totalVolume) >= currentParcelRange.MinVolume
            && (this.translateHelper.getNumber(this.totalVolume) <= currentParcelRange.MaxVolume || currentParcelRange.MaxVolume == 0)) {
            //Valid parcel range found, now assign the override cost if it's still 0
            if (!!currentParcelRange.BaseCostOverride && currentParcelRange.BaseCostOverride > 0) {
              totalCost = currentParcelRange.BaseCostOverride;
            }

            if (countryGroup.FreightPivotPoint > -1) {

              // //we add the weight on at the start and use that as the default but subtract it here if we now want to use volume weight.
              // if (!useWeight && countryGroup.BasePriceWeight !== 0) {
              //   costForParcelRange -= countryGroup.BasePriceWeight;
              //   costForParcelRange += countryGroup.BasePriceVolume;
              // }
              if (!currentParcelRange.Fragmented) {
                if (useWeight) {
                  //Add weight unit costs
                  const weightRoundedUp = Math.ceil(inputWeight);
                  const additionalWeightCost = weightRoundedUp * currentParcelRange.CostPerUnitWeight;
                  totalCost += additionalWeightCost;
                }
                else {
                  //Add volume unit costs
                  const volumeRoundedUp = Math.ceil(this.totalVolume);
                  const additionaVolumeCost = volumeRoundedUp * currentParcelRange.CostPerUnitVolume;
                  totalCost += additionaVolumeCost;
                }
              }
              else {
                if (useWeight) {
                  //Add weight unit costs
                  const additionalWeightCost = inputWeight * currentParcelRange.CostPerUnitWeight;
                  totalCost += additionalWeightCost;
                }
                else {
                  //Add volume unit costs
                  const additionaVolumeCost = this.totalVolume * currentParcelRange.CostPerUnitVolume;
                  totalCost += additionaVolumeCost;
                }
              }
            }
            else {
              if (!currentParcelRange.Fragmented) {
                //Add weight unit costs
                const weightRoundedUp = Math.ceil(inputWeight);
                const additionalWeightCost = weightRoundedUp * currentParcelRange.CostPerUnitWeight;
                totalCost += additionalWeightCost;

                //Add volume unit costs
                const volumeRoundedUp = Math.ceil(this.totalVolume);
                const additionaVolumeCost = volumeRoundedUp * currentParcelRange.CostPerUnitVolume;
                totalCost += additionaVolumeCost;
              }
              else {
                //Add weight unit costs
                const additionalWeightCost = inputWeight * currentParcelRange.CostPerUnitWeight;
                totalCost += additionalWeightCost;

                //Add volume unit costs
                const additionaVolumeCost = this.totalVolume * currentParcelRange.CostPerUnitVolume;
                totalCost += additionaVolumeCost;
              }
            }
            break;
          }

        }

        //At this point we have the totalcost not including incoterms so now work out if VAT needs to be added on.
        //This needs to be done seperately to incoterms as they have VATIncluded as its own flag
        if (countryGroup.IncludeVAT || transportType.IncludeVAT)
        {
          this.vatCost += totalCost*transportType.VATPercentage;
          totalCost *= (1+transportType.VATPercentage);
        }

        //We assume if a single incoterm is defined for a transport type then VAT is included
        if (!!transportType.IncoTerm && !!transportType.IncoTerm.BasePrice) {
          this.vatCost += transportType.IncoTerm.BasePrice*transportType.VATPercentage;
          totalCost += transportType.IncoTerm.BasePrice;
        }
      }

      if (!!countryGroup.IncoTermsOverrides && countryGroup.IncoTermsOverrides.length > 0) {
        var incoTermCostSet = true;
        if (!!countryGroup.IncoTermsOverrides[0])
        {
          totalCost += this.getPricingForTransportType(countryGroup.IncoTermsOverrides[0].IncoTerms, true, transportType.VATPercentage, true);
        }
      }
    }
    else {
      //if no country groups then it's domestic transport or proxy shopping
      if (!!transportType.ParcelRanges) {
        var i: number;
        var inputWeight = this.translateHelper.getNumber(this.sendReceiveForm.get("totalWeight").value);
        if (this.sendReceiveForm.get("isKg").value == 'false')
        {
          inputWeight /= 1000;
        }
        for (i = 0; i < transportType.ParcelRanges.length; i++) {
          const currentParcelRange = transportType.ParcelRanges[i];
          if (inputWeight >= currentParcelRange.MinWeight
            && inputWeight <= currentParcelRange.MaxWeight) {
            //Valid parcel range found, now assign the override cost if it's still 0
            if (totalCost == 0) {
              totalCost = currentParcelRange.BaseCostOverride;
            }

            //Add weight unit costs
            const weightRoundedUp = Math.ceil(inputWeight);
            const additionalWeightCost = weightRoundedUp * currentParcelRange.CostPerUnitWeight;
            totalCost += additionalWeightCost;
          }
        }
      }
    }

    if (transportType.IncludeVAT)
    {
      this.vatCost += transportType.VATPercentage*totalCost;
      totalCost *= (1+transportType.VATPercentage);
    }

    if (!incoTermCostSet && !!transportType.IncoTermsGroups && transportType.IncoTermsGroups.length > 0) {
      //The service will only ever return the single valid incoterm for the transport type.
      if (!!transportType.IncoTermsGroups[0])
      {
        totalCost += this.getPricingForTransportType(transportType.IncoTermsGroups[0].IncoTerms, true, transportType.VATPercentage, true);
      }
    }

    if (!!insuranceCost) {
      totalCost += insuranceCost;
    }

    if (!!transportType.IsDHL && transportType.IsDHL)
    {
      totalCost = Math.ceil(totalCost);
    }
    
    return +totalCost.toFixed(2);
  }

  public openRouteInNewWindow(route: string){
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

  public showPostOfficeButton(productType: ProductType)
  {
    if (productType == ProductType.Air_Freight || productType == ProductType.Sea_Freight || productType == ProductType.DomesticTransport)
    {
      return false;
    }
    return true;
  }
}