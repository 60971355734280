<div class="container">
    <div class="awaiting-verification-container">
        <h2 class="awaiting-verification-title">{{'awaiting_verification.title' | translate}}</h2>
        <div *ngIf="!showEmailSentBody">{{'awaiting_verification.body' | translate}}</div>
        <div *ngIf="showEmailSentBody">{{'awaiting_verification.email_sent_body' | translate}}</div>
    </div>
</div>
<!-- <div class="container">
    <div class="awaiting-verification-container">
        <h2 *ngIf="isPrivateUser; else business" class="awaiting-verification-title">{{'awaiting_verification.private_user_title' | translate}}</h2>
        <ng-template #business>
            <h2 class="awaiting-verification-title">{{'awaiting_verification.business_user_title' | translate}}</h2>
        </ng-template>
        <div *ngIf="!showEmailSentBody && !isPrivateUser()">{{'awaiting_verification.business_user_body' | translate}}</div>
        <div *ngIf="!showEmailSentBody && isPrivateUser">{{'awaiting_verification.private_user_body' | translate}}</div>
        <div *ngIf="showEmailSentBody">{{'awaiting_verification.email_sent_body' | translate}}</div>
    </div>
</div> -->