import { Component, OnInit, HostListener, Input, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../account/entities/user';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../../../account/services/authentication.service';
import { CookieService } from '../../services/cookie.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';
import { StaticContentService } from '../../services/static-content.service';
import { MenuCategory } from '../../static-content/entities/menuCategory';
import { Page } from '../../static-content/entities/page';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorHelper } from '../../helpers/error-helper';
import { RoleService } from '../../../account/services/role.service';
import { MatDialog } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../../dialogs/logout-dialog/logout-dialog.component';
import { StaticContentHelper } from '../../helpers/static-content-helper';
import { CreateShoppingListsDialogComponent } from '../../../shopping-services/create-shopping-lists-dialog/create-shopping-lists-dialog.component';

@Component({
  selector: 'app-header-personal',
  templateUrl: './header-personal.component.html',
  styleUrls: ['./header-personal.component.scss']
})
export class HeaderPersonalComponent implements OnInit {

  public passwordForm: FormGroup;

  public currentFlagIcon: string;

  public menu: any;

  public showMenu1: boolean = false;

  public showMenu2: boolean = false;

  public showMenu3: boolean = false;

  public showMenu4: boolean = false;

  public showMenu5: boolean = false;

  public isLoggedIn: boolean = false;

  public showSearch: boolean = false;

  public showMobileMenuOptions: boolean = false;

  public categories: Array<MenuCategory>;

  public subCategories: Map<string, Array<Page>> = new Map();

  public pages: Array<Page>;

  public interval: any;

  public role: string;

  public currentLang: string;

  public setRole: string;

  public showTabletView: boolean = false;

  public showResponsiveMyPosta : boolean = true;

  public cdnPath: string;

  @Input()
  user: User;

  constructor(
    private _formBuilder: FormBuilder,
    private errorHelper: ErrorHelper,
    private router: Router,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private http: HttpClient,
    private staticContentService: StaticContentService,
    private staticContentHelper: StaticContentHelper,
    private roleService: RoleService,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog) { }

  @HostListener('document:click', ['$event'])
  clickout(event) {

    if (event.target.className.includes("sub-title-login") || event.target.className.includes("mat-form-field") ||event.target.className === "login-button primary-button full-width" || event.target.className.includes("login-menu-option-container")) {
      return;
    }

    if ((event.target.className === "category0 header-button" || event.target.className === "menu-header-text0" && this.categories[0].Pages != undefined)) {
      this.filterPages(0);
      this.setSubCategories();

      this.showMenu1 = true
    }
    else if (event.target.className === "category0 tablet-header-button" || event.target.className === "menu-header-text0 tablet-header-header-title" || event.target.className === "drop-down-arrow")
    {
      //do nothing
    }
    else {
      this.showMenu1 = false;
    }

    if ((event.target.className.includes("header1") || event.target.className === "category1 header-button" || event.target.className === "menu-header-text1" && this.categories[1].Pages != undefined)) {
        this.showMenu2 = true;
        this.filterPages(1);
        this.setSubCategories();
    }
    else if (event.target.className === "category1 tablet-header-button" || event.target.className === "menu-header-text1 tablet-header-header-title" || event.target.className === "drop-down-arrow")
    {
      //do nothing
    }
    else {
      this.showMenu2 = false;
    }

    if ((event.target.className.includes("header2") || event.target.className === "category2 header-button" || event.target.className === "menu-header-text2"
     && this.categories[2].Pages != undefined)) {
      this.filterPages(2);
      this.setSubCategories();
      this.showMenu3 = true
    }
    else if (event.target.className === "category2 tablet-header-button" || event.target.className === "menu-header-text2 tablet-header-header-title" || event.target.className === "drop-down-arrow")
    {
      //do nothing
    }
    else {
      this.showMenu3 = false;
    }

    if ((event.target.className.includes("header3") || event.target.className === "category3 header-button" || event.target.className === "menu-header-text3" && this.categories[3].Pages != undefined)) {
      this.filterPages(3);
      this.setSubCategories();
      this.showMenu4 = true
    }
    else if (event.target.className === "category3 tablet-header-button" || event.target.className === "menu-header-text3 tablet-header-header-title" || event.target.className === "drop-down-arrow")
    {
      //do nothing
    }
    else {
      this.showMenu4 = false;
    }

    if ((event.target.className.includes("header4") || event.target.className.includes("category4 header-button") || event.target.className === "menu-header-text4 primary" || event.target.className.includes("user-icon") || event.target.className === "category4 header-button" && this.categories[4].Pages != undefined)) {
      // this.showMenu5 = !this.showMenu5;
      // if(this.showMenu5){
      //   this.filterPages(4);      
      //   this.setSubCategories();
      // }
    }
    else if (event.target.className === "category4 tablet-header-button" || event.target.className === "menu-header-text4 tablet-header-header-title" || event.target.className === "drop-down-arrow")
    {
      //do nothing
    }
    else {
      this.showMenu5 = false;
    }
  }

  private setSubCategories() {
    this.subCategories = new Map();

    for (let i = 0; i < this.pages.length; i++) {
      if (!!this.subCategories.get(this.pages[i].MenuGroup)) {
        this.subCategories.get(this.pages[i].MenuGroup).push(this.pages[i]);
      }
      else if (this.pages[i].MenuGroup != "") {
        this.subCategories.set(this.pages[i].MenuGroup, [this.pages[i]]);
      }
    }
  }

  public hasErrors(field) {
    var errors = this.passwordForm.get(field).errors;
    return !!errors;
  }

  public isPrivateRole() {
    return this.role == "private";
  }

  public getError(field) {
    var errors = this.passwordForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  ngOnInit(): void {

    this.cdnPath = this.staticContentService.cdn();

    this.roleService.roleSubject.subscribe(x => {
      this.role = x;
      this.getStaticMenuForLanguage();
      this.isLoggedIn = !!this.cookieService.getCookie('token');
    })

    this.passwordForm = this._formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });


    //this.getStaticMenuForLanguage();

    if (this.translate.currentLang == 'en') {
      this.currentFlagIcon = "/assets/icons/menu_header/h_english@3x.png";
    }
    else if (this.translate.currentLang == 'fo') {
      this.currentFlagIcon = "/assets/icons/menu_header/h_faroese@3x.png";
    }

    this.translate.onLangChange.subscribe(x => {
      this.staticContentService.getTranslationDictionary(x.lang).subscribe();
      this.getStaticMenuForLanguage();
    });

    var currentUser = this.authenticationService.getUser();
    this.isLoggedIn = !!currentUser && !!this.cookieService.getCookie('token');
  }

  public switchLanguage() {
    if (this.translate.currentLang == 'en') {
      this.translate.setDefaultLang('fo');
      localStorage.setItem("language", "fo");
      this.currentFlagIcon = "/assets/icons/menu_header/h_faroese@3x.png";
      this.translate.use('fo');
    }
    else {
      this.translate.setDefaultLang('en');
      localStorage.setItem("language", "en");
      this.currentFlagIcon = "/assets/icons/menu_header/h_english@3x.png";
      this.translate.use('en');
    }
  }

  public hasItemsWithIcons(value: Array<Page>) {
    return value.filter(x => x.MenuIcon != '').length > 0;
  }

  public toggleMenuForCategory(index: number) {
    switch (index) {
      case 0:
        this.showMenu1 = !this.showMenu1;
        if (this.showMenu1)
        {
          this.filterPages(0);
          this.setSubCategories();
        }
        break;
      case 1:
        this.showMenu2 = !this.showMenu2;
        if (this.showMenu2)
        {
          this.filterPages(1);
          this.setSubCategories();
        }
        break;
      case 2:
        this.showMenu3 = !this.showMenu3;
        if (this.showMenu3)
        {
          this.filterPages(2);
          this.setSubCategories();
        }
        break;
      case 3:
        this.showMenu4 = !this.showMenu4;
        if (this.showMenu4)
        {
          this.filterPages(3);
          this.setSubCategories();
        }
        break;
      case 4:
        this.showMenu5 = !this.showMenu5;
        if (this.showMenu5)
        {
          this.filterPages(4);
          this.setSubCategories();
        }
        break;
    }
  }

  public getStaticMenuForLanguage() {

    var privateRole = "private";
    var business = "business";

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;

      var roleUnkown = this.role != privateRole && this.role != business;

      if (!!staticContent && (this.currentLang !== this.translate.currentLang || this.role != this.setRole || roleUnkown)) {

        if (this.role == privateRole || roleUnkown) {
          if (this.translate.currentLang == "en") {
            var languageSection = staticContent.Sections.find(x => x.Url == "/en/");
            if (!!languageSection && !!languageSection.SubSections) {
              var subSection = languageSection.SubSections.find(x => x.Url == "/en/private/");
              if (!!subSection && subSection.Categories) {
                this.categories = subSection.Categories.filter(x => x.Type == "category" && x.HideFromMenu == false);
                this.currentLang = this.translate.currentLang;
                this.setRole = privateRole;
              }
            }
          }
          else if (this.translate.currentLang == "fo") {
            var languageSection = staticContent.Sections.find(x => x.Url == "/fo/");
            if (!!languageSection && !!languageSection.SubSections) {
              var subSection = languageSection.SubSections.find(x => x.Url == "/fo/private/");
              if (!!subSection && subSection.Categories) {
                this.categories = subSection.Categories.filter(x => x.Type == "category" && x.HideFromMenu == false);
                this.currentLang = this.translate.currentLang
                this.setRole = privateRole;
              }
            }
          }
        }
        if (this.role == business) {
          if (this.translate.currentLang == "en") {
            var languageSection = staticContent.Sections.find(x => x.Url == "/en/");
            if (!!languageSection && !!languageSection.SubSections) {
              var subSection = languageSection.SubSections.find(x => x.Url == "/en/business/");
              if (!!subSection && subSection.Categories) {
                this.categories = subSection.Categories.filter(x => x.Type == "category" && x.HideFromMenu == false);
                this.currentLang = this.translate.currentLang
                this.setRole = business;
              }
            }
          }
          else if (this.translate.currentLang == "fo") {
            var languageSection = staticContent.Sections.find(x => x.Url == "/fo/");
            if (!!languageSection && !!languageSection.SubSections) {
              var subSection = languageSection.SubSections.find(x => x.Url == "/fo/business/");
              if (!!subSection && subSection.Categories) {
                this.categories = subSection.Categories.filter(x => x.Type == "category" && x.HideFromMenu == false);
                this.currentLang = this.translate.currentLang;
                this.setRole = business;
              }
            }
          }
        }
        clearInterval(this.interval);
      }
      if(!!this.currentLang && !!this.role && !!this.categories){
        clearInterval(this.interval);
      }
    }, 500);

  }

  public navigateToPersonal() {
    var loginUserType = localStorage.getItem("loginUserType");
    if (!!loginUserType && loginUserType != "private")
    {
      const dialogRef = this.dialog.open(LogoutDialogComponent, {
        width: '250px',
        data: null
      });
  
      dialogRef.afterClosed().subscribe(result => {
      });
      return;
    }

    this.router.navigate([`${this.translate.currentLang}/private/home`]);
    this.role = "private";
  }

  public navigateToBusiness() {
    var loginUserType = localStorage.getItem("loginUserType");
    if (!!loginUserType && loginUserType != "business")
    {
      const dialogRef = this.dialog.open(LogoutDialogComponent, {
        width: '250px',
      });
  
      dialogRef.afterClosed().subscribe(result => {
      });
      return;
    }
    this.router.navigate([`${this.translate.currentLang}/business/home`]);
    this.role = "business";
  }

  public navigateToRegister() {
    this.router.navigate([`${this.translate.currentLang}/${this.role}/register`]);
  }

  public navigateToLogin() {
    var reverseRole: string;
    if (this.role == "private") {
      reverseRole = "business"
    }
    if (this.role == "business") {
      reverseRole = "private"
    }
    this.router.navigate([`${this.translate.currentLang}/${reverseRole}/login`]);
  }

  public navigateToForgottenPassword()
  {
    this.router.navigate([`${this.translate.currentLang}/${this.role}/forgotten-password`]);
  }
  
  public navigateToSamleikin()
  {
    this.router.navigateByUrl('/login/samleikin').then(() => {
      window.location.reload();
    });
  }


  public openMinlykilLogin() {
    //TODO add below line back in
    // this.router.navigate(['/login']);

    // this.http.get<any>(`/Authentication/MinlykilLogin`).subscribe
    // ( x => { console.log(x) }, 
    // error => window.open("https://demo.lykil.fo/ui/login/to/posta-sg/z1zas55xbwftfirmw1xu2qjk", "_self"))

    this.http.get(`/Authentication/MinlykilLogin`, { responseType: 'text' }).subscribe(
      x => {
        window.location.href = x;
        this.router.navigate([`/login`]);
      },
      error => console.log(error))
  }

  public logout() {
    //TODO catch error
    this.authenticationService.logout().subscribe(x => {
      this.isLoggedIn = false;
      //Catch error here as well
      this.authenticationService.logoutShibboleth().subscribe(y => {

      }, (shibError) => {
        
      })
    }, (error) => {
      //Ideally we shouldn't call this unless it's actually a Shibboleth User
      this.authenticationService.logoutShibboleth().subscribe();
    });
  }

  public search() {
    //do global search instead if true
    if (this.showSearch) {

    }
    else {
      this.showSearch = true;
    }
  }

  public closeSearch() {
    this.showSearch = false;
  }

  public isMyPostaHeader(category: MenuCategory) {
    return category.Url == "/en/private/my-posta/" || category.Url == "/fo/private/my-posta/" || category.Url == "/en/business/my-posta/" || category.Url == "/fo/business/my-posta/"
  }

  public login() {
    if (!this.passwordForm.valid) {
      this.passwordForm.markAllAsTouched();
      return;
    }

    if (this.role == "private") {
      this.authenticationService.login(this.passwordForm.get("email").value, this.passwordForm.get("password").value, 2, this.translate.currentLang).subscribe
        (x => {
          this.showMenu5 = false;
          this.passwordForm.reset();
          var userDetails = JSON.parse(x.toString());
          if (!userDetails.isVerified) {
            if (userDetails.isEmailVerificationMessageJustBeenSent)
            {
              this.router.navigate([`${this.translate.currentLang}/${this.role}/awaiting-verification/email-sent`]);
              return;
            }
            this.router.navigate([`${this.translate.currentLang}/${this.role}/awaiting-verification/`]);
            return;
          }
          if (!userDetails.isProfileComplete) {
            this.router.navigate([`${this.translate.currentLang}/${this.role}/update-profile`]);
            return;
          }
        
          this.createShoppingLists();
          
          this.router.navigate([`${this.translate.currentLang}/private/home`]); this.isLoggedIn = true; this.showMenu5 = false; this.passwordForm.reset();
        }, (error) => {
          this.passwordForm.get("password").setErrors({ 'incorrectCredentials': true })
        })
    }

    if (this.role == "business") {
      this.authenticationService.login(this.passwordForm.get("email").value, this.passwordForm.get("password").value, 1, this.translate.currentLang).subscribe
        (x => {
          this.showMenu5 = false;
          this.passwordForm.reset();
          var userDetails = JSON.parse(x.toString());
          if (!userDetails.isProfileComplete) {
            this.router.navigate([`${this.translate.currentLang}/business/my-posta/update-profile`]);
            return;
          }
          this.router.navigate([`${this.translate.currentLang}/business/home`]); this.isLoggedIn = true; this.showMenu5 = false; this.passwordForm.reset();
        }, (error) => {
          if (error.status == 403)
          {
            this.showMenu5 = false;
            this.router.navigate([`${this.translate.currentLang}/business/awaiting-approval`]);

            return;
          }
          this.passwordForm.get("password").setErrors({ 'incorrectCredentials': true })
        });
    }
  }

  filterPages(index: number)
  {
    this.pages = this.categories[index].Pages.filter(x => x.HideFromMenu == false);
    if (!this.isLoggedIn)
    {
      this.pages = this.pages.filter(x => x.LoginRequired == false);
    }
  }

  public createShoppingLists()
  {
  var loggedOutLists = localStorage.getItem('shoppingLists');
    if (!!loggedOutLists) {
      var shoppingListsObj = JSON.parse(loggedOutLists);

      const dialogRef = this.dialog.open(CreateShoppingListsDialogComponent, {
        height: '240px',
        width: '580px',
        data: {
          lists: shoppingListsObj,
        }
      });
    }
  }
}