import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AddressService } from '../../services/address-service';
import { CountryService } from '../../services/country.service';
import { Country } from '../../../account/entities/Country';
import { TranslateService } from '@ngx-translate/core';
import { Order } from '../../../send-receive/entities/Order';
import { map, startWith } from 'rxjs/operators';
import { ErrorHelper } from '../../../shared/helpers/error-helper';
import { Address } from '../../../account/entities/address';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-to-new-address',
  templateUrl: './to-new-address.component.html',
  styleUrls: ['./to-new-address.component.scss']
})
export class ToNewAddressComponent implements OnInit  {

  @Input()
  sendReceiveForm: FormGroup;

  @Input()
  specificationForm: FormGroup;

  @Input()
  newAddressBookEntry: boolean;

  countries: Array<Country>;

  @Output()
  successfullySaved: EventEmitter<boolean> = new EventEmitter();

  @Input()
  order: Order;

  @Input()
  postcodes: any;

  toAddressControl = new FormControl();

  public toFilteredAddresses: Observable<Array<Address>>;

  public addresses: Array<Address> = new Array<Address>();

  public toAddressSelected: Address;

  public addToFavourite: boolean = false;

  public postcodeSelected: any;

  public toFilteredPostcodes: any;
  saveDisabled: boolean = false;

  public toFO: boolean;
  public toFODisabled: boolean;

  constructor(private newAddressService: AddressService,
private countryService: CountryService,
private translate: TranslateService,
private errorHelper: ErrorHelper,
private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.toFilteredPostcodes = this.sendReceiveForm.get("toPostcode").valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : `${value.postcode}, ${value.city}`, this.cdr.detectChanges()),
      map(postcode => postcode ? this._filterPostcode(postcode) : this.postcodes.slice(), this.cdr.detectChanges())
    );

    this.sendReceiveForm.get("toCountry").valueChanges.subscribe(
      value => {
        if(this.toFO == false && value.countryCode == "FO"){
          // this.sendReceiveForm.get("toPostcode").setValue(null);
          // this.sendReceiveForm.get("toTownCity").setValue(null);
          this.toFO = true;
        }
        else if (value.countryCode != "FO"){
          this.toFO = false;
        }
      }
        
    );

    this.countryService.getAll().subscribe(x => {
      this.countries = x
      switch (this.translate.currentLang) {
        case "en":
          this.countries.sort((a, b) => a.countryNameEN.localeCompare(b.countryNameEN))
        case "fo":
          this.countries.sort((a, b) => a.countryNameFO.localeCompare(b.countryNameFO))
      }
      this.countries.sort(function(x,y){ return x.countryCode == "FO" ? -1 : y.countryCode == "FO" ? 1 : 0; });
      this.countries.sort(function(x,y){ return x.countryCode == "DK" ? -1 : y.countryCode == "DK" ? 1 : 0; });

      //If viewing/editing order then set country control to matching country code
      if (!!this.order)
      {
        this.sendReceiveForm.get("toCountry").setValue(this.countries.find(x => x.countryCode == this.order.toCountryCode));
      }
    });

    if (this.newAddressBookEntry) {
      this.sendReceiveForm.get("toTitle").reset();
      this.sendReceiveForm.get("toFirstName").reset();
      this.sendReceiveForm.get("toLastName").reset();
      this.sendReceiveForm.get("toOptionalCompany").reset();
      this.sendReceiveForm.get("toAddressLine1").reset();
      this.sendReceiveForm.get("toAddressLine2").reset();
      this.sendReceiveForm.get("toTownCity").reset();
      this.sendReceiveForm.get("toPostcode").reset();
      this.sendReceiveForm.get("toContactNumber").reset();
      this.sendReceiveForm.get("toEmail").reset();
      this.sendReceiveForm.get("toSpecialInstructions").reset();
    }

    //Set address details from order if exists
    if (!!this.order) {
      this.sendReceiveForm.get("toTitle").setValue(this.order.toTitle);
      this.sendReceiveForm.get("toFirstName").setValue(this.order.toFirstName);
      this.sendReceiveForm.get("toLastName").setValue(this.order.toLastName);
      this.sendReceiveForm.get("toOptionalCompany").setValue(this.order.toCompanyName);
      this.sendReceiveForm.get("toAddressLine1").setValue(this.order.toAddressLine1);
      this.sendReceiveForm.get("toAddressLine2").setValue(this.order.toAddressLine2);
      this.sendReceiveForm.get("toTownCity").setValue(this.order.toTownCity);
      this.sendReceiveForm.get("toPostcode").setValue(this.order.toPostcode);
      //this.sendReceiveForm.get("fromCountry").setValue(this.order.toCountryCode);
      this.sendReceiveForm.get("toContactNumber").setValue(this.order.toContactNumber);
      this.sendReceiveForm.get("toEmail").setValue(this.order.toContactEmail);
      this.sendReceiveForm.get("toSpecialInstructions").setValue(this.order.toSpecialInstructions);
    }

    this.cdr.detectChanges()

    this.sendReceiveForm.get("toTitle").valueChanges.subscribe(x => {
      this.saveDisabled = false;
    })
    this.sendReceiveForm.get("toFirstName").valueChanges.subscribe(x => {
      this.saveDisabled = false;
    })
    this.sendReceiveForm.get("toLastName").valueChanges.subscribe(x => {
      this.saveDisabled = false;
    })
    this.sendReceiveForm.get("toOptionalCompany").valueChanges.subscribe(x => {
      this.saveDisabled = false;
    })
    this.sendReceiveForm.get("toAddressLine1").valueChanges.subscribe(x => {
      this.saveDisabled = false;
    })
    this.sendReceiveForm.get("toAddressLine2").valueChanges.subscribe(x => {
      this.saveDisabled = false;
    })
    this.sendReceiveForm.get("toTownCity").valueChanges.subscribe(x => {
      this.saveDisabled = false;
    })
    this.sendReceiveForm.get("toPostcode").valueChanges.subscribe(x => {
      this.saveDisabled = false;
    })
    this.sendReceiveForm.get("toContactNumber").valueChanges.subscribe(x => {
      this.saveDisabled = false;
    })
    this.sendReceiveForm.get("toEmail").valueChanges.subscribe(x => {
      this.saveDisabled = false;
    })
    this.sendReceiveForm.get("toSpecialInstructions").valueChanges.subscribe(x => {
      this.saveDisabled = false;
    })
  }

//   ngAfterContentChecked() : void {
//     this.cdr.detectChanges();
// }

  public setFavouriteChecked(checked: boolean) {
    this.addToFavourite = checked;
  }

  public hasSelectedCountry(){
    if(this.sendReceiveForm.get("toCountry").valid)
    {
      return true;
    }
    return false;
  }

  public addressFieldClick() {
    this.newAddressService.getAll().subscribe((x) => {
      this.addresses = x;
  
      this.toFilteredAddresses = this.toAddressControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
        map(firstName => firstName ? this._filter(firstName) : this.addresses.slice()),
      )  
    });
  }
  
  public getAddresses() {
    this.newAddressService.getAll().subscribe((x) => {
      this.addresses = x
  
      this.toFilteredAddresses = this.toAddressControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : `${value.firstName} ${value.lastName}, ${value.postcode}`),
        map(firstName => firstName ? this._filter(firstName) : this.addresses.slice()),
      )
    });
  
    this.toAddressControl
  }
  
  public settoSelectedAddress(address) {
    this.toAddressSelected = address;
  }
  
  public settoAddressFields() {
    if (!!this.toAddressSelected) {
      if(this.toAddressSelected.country.countryCode == "FO"){
        this.toFO = true;
        var selectedPostcode = this.postcodes.find(pc => pc.postcode == this.toAddressSelected.postcode || pc.city == this.toAddressSelected.townCity)
        if(!!selectedPostcode)
        {
          this.sendReceiveForm.get("toTownCity").setValue(selectedPostcode.city);
          this.sendReceiveForm.get("toPostcode").setValue(selectedPostcode.postcode);
        }
      }
      else{
        this.toFO = false;
        this.sendReceiveForm.get("toTownCity").setValue(this.toAddressSelected.townCity);
        this.sendReceiveForm.get("toPostcode").setValue(this.toAddressSelected.postcode);
      }
      this.sendReceiveForm.get("toTitle").setValue(this.toAddressSelected.title);
      this.sendReceiveForm.get("toFirstName").setValue(this.toAddressSelected.firstName);
      this.sendReceiveForm.get("toLastName").setValue(this.toAddressSelected.lastName);
      this.sendReceiveForm.get("toOptionalCompany").setValue(this.toAddressSelected.companyName);
      this.sendReceiveForm.get("toAddressLine1").setValue(this.toAddressSelected.addressLine1);
      this.sendReceiveForm.get("toAddressLine2").setValue(this.toAddressSelected.addressLine2);
      this.sendReceiveForm.get("toCountry").setValue(this.toAddressSelected.country);
      this.sendReceiveForm.get("toContactNumber").setValue(this.toAddressSelected.contactNumber);
      this.sendReceiveForm.get("toEmail").setValue(this.toAddressSelected.contactEmail);
      this.sendReceiveForm.get("toSpecialInstructions").setValue(this.toAddressSelected.specialInstructions);
    }
  }
  
  public onSelecttoFO(event){
    if(event.returnValue){
      var fo = this.countries.find(country => country.countryCode == "FO")
      this.sendReceiveForm.get("toCountry").setValue(fo);
    }
  }
  
  
  public hasErrors(field){
    var errors = this.sendReceiveForm.get(field).errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.sendReceiveForm.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  displayFn(postcode: any): string {
    return postcode && `${postcode}` ? `${postcode}` : '';
  }

  displayAddress(address: Address): string {
    return address && `${address.firstName} ${address.lastName}, ${address.postcode}` ? `${address.firstName} ${address.lastName}, ${address.postcode}` : '';
  }

  public setToSelectedPostcode(postcode)
  {
    this.postcodeSelected = postcode;
    // this.sendReceiveForm.get("fromPostcode").setValue(postcode.postcode);
  }

  private _filterPostcode(value: string): Array<any> {
    const filterValue = value.toLowerCase();

    return this.postcodes.filter(option => `${option.postcode}, ${option.city}`.toLowerCase().includes(filterValue));
  }

  private _filter(value: string): Array<Address> {
    const filterValue = value.toLowerCase();

    return this.addresses.filter(option => `${option.firstName} ${option.lastName}, ${option.postcode}`.toLowerCase().includes(filterValue));
  }

  public saveEntryToAddressBook() {
    this.sendReceiveForm.markAllAsTouched();
    this.saveDisabled = false;

    var formValid =  this.sendReceiveForm.get("toTitle").valid
    && this.sendReceiveForm.get("toFirstName").valid
    && this.sendReceiveForm.get("toLastName").valid
    && this.sendReceiveForm.get("toOptionalCompany").valid
    && this.sendReceiveForm.get("toAddressLine1").valid
    && this.sendReceiveForm.get("toAddressLine2").valid
    && this.sendReceiveForm.get("toTownCity").valid
    && this.sendReceiveForm.get("toPostcode").valid
    && this.sendReceiveForm.get("toContactNumber").valid
    && this.sendReceiveForm.get("toEmail").valid
    && this.sendReceiveForm.get("toSpecialInstructions").valid

    if (formValid && !this.saveDisabled) {
      var postcodeValue = this.sendReceiveForm.get("toPostcode").value;
      var townCityValue = this.sendReceiveForm.get("toTownCity").value;
      const newAddress = {
        title: this.sendReceiveForm.get("toTitle").value,
        firstName: this.sendReceiveForm.get("toFirstName").value,
        lastName: this.sendReceiveForm.get("toLastName").value,
        company: this.sendReceiveForm.get("toOptionalCompany").value,
        addressLine1: this.sendReceiveForm.get("toAddressLine1").value,
        addressLine2: this.sendReceiveForm.get("toAddressLine2").value,
        townCity: this.sendReceiveForm.get("toTownCity").value,
        postcode: this.sendReceiveForm.get("toPostcode").value,
        country: this.sendReceiveForm.get("toCountry").value,
        countryCode: this.sendReceiveForm.get("toCountry").value.countryCode,
        contactNumber: this.sendReceiveForm.get("toContactNumber").value,
        email: this.sendReceiveForm.get("toEmail").value,
        specialInstructions: this.sendReceiveForm.get("toSpecialInstructions").value,
        isFavourite: this.addToFavourite
      };

      this.newAddressService.saveToAddressBook(newAddress).subscribe(
        () => {
          // this.snackbar.open("Successfully saved address");
          this.saveDisabled = true;

          this.successfullySaved.emit(true);
        }, (error) =>
        console.log(error));
    }
  }

  public formTouchedAndNoCountrySelected() {
    if (this.sendReceiveForm.get("toCountry").touched && !this.sendReceiveForm.get("toCountry").value) {
      return true;
    }
    return false;
  }

  public getCountryNameForLanguage(country: Country): string {
    switch (this.translate.currentLang) {
      case "en":
        return country.countryNameEN;
      case "fo":
        return country.countryNameFO;
    }
  }

  public setCountryByCode(country: Country): Country {
    var countryControl: Country = this.sendReceiveForm.get("toCountry").value;
    if (!!countryControl) {
      var countryCodeByControl = countryControl.countryCode;

      if (country.countryCode == countryCodeByControl) {
        this.sendReceiveForm.get("toCountry").setValue(country, {emitEvent: false});
        if(country.countryCode == "FO")
        {
          this.toFO = true;
        }
        else{
          this.toFO = false;
        }
      }
    }
    return country;
  }

  public updateSpecificationForm(event: any)
    {
      if (!!event && event.value.countryCode != "FO")
      {
        this.toFO = false;
        var previousPostcode = this.sendReceiveForm.get("toPostcode").value;
        if(!!previousPostcode.postcode){
          this.sendReceiveForm.get("toPostcode").setValue(previousPostcode.postcode);
          this.sendReceiveForm.get("toTownCity").setValue(previousPostcode.city);
        }
      }
      else{
        this.toFO = true;
        var previousPostcode = this.sendReceiveForm.get("toPostcode").value;
        var previousCity = this.sendReceiveForm.get("toTownCity").value;
        var selectedPostcode = this.postcodes.find(pc => pc.postcode == previousPostcode || pc.city == previousCity)
        if(!!selectedPostcode)
        {
          this.sendReceiveForm.get("toTownCity").setValue(selectedPostcode.city);
          this.sendReceiveForm.get("toPostcode").setValue(selectedPostcode.postcode);
        }
      }
    }

    public setSelectedPostcodeFO(event)
    {
      var sourceControlName = event.source.ngControl.name;
      if(sourceControlName == "toTownCity"){
        var selectedPostcode = this.postcodes.find(pc => pc.city == event.value)
        this.sendReceiveForm.get("toPostcode").setValue(selectedPostcode.postcode);
      }
      else if(sourceControlName == "toPostcode"){
        var selectedPostcode = this.postcodes.find(pc => pc.postcode == event.value)
        if(!!selectedPostcode)
        {
          this.sendReceiveForm.get("toTownCity").setValue(selectedPostcode.city);
        }
      }
    }

  public toggleSave()
  { 
    var saveAddressControl = this.sendReceiveForm.get("toSaveAddress");
    !!saveAddressControl.value ? saveAddressControl.reset() : saveAddressControl.setValue("true");
  }

  public toggleFavourite()
  {
    var favouriteControl = this.sendReceiveForm.get("toIsFavouriteAddress")
    !!favouriteControl.value ? favouriteControl.reset() : favouriteControl.setValue("true");
  }

  public getFavouriteState(){
    var favouriteControl = this.sendReceiveForm.get("toIsFavouriteAddress")
    return favouriteControl.value;
  }

}
