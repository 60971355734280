import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHelper {

  constructor(private translateService: TranslateService) { }

  getErrorMessage(errors) {
    if(errors.required)
    {
      return this.translateService.instant("error.required");
    }
    if(errors.mismatch){
      return this.translateService.instant("error.password_mismatch");
    }
    if(errors.emailValidator){
      return this.translateService.instant("error.email_validator");
    }
    if(errors.email){
      return this.translateService.instant("error.invalid_email");
    }
    if(errors.greaterThanZeroValidator){
      // var param = errors.min.min;
      return this.translateService.instant("error.require_greater_than_zero");
    }
    if(errors.max){
      var param = errors.max.max;
      return this.translateService.instant("error.value_too_high", { value1: param });
    }
    if(errors.numberOnlyValidator){
        return this.translateService.instant("error.not_numeric")
    }
    if(errors.integerOnlyValidator){
        return this.translateService.instant("error.not_integer")
    }
    return this.translateService.instant(`error.${Object.keys(errors)[0]}`);
  }

  public static emailValidator(c: FormControl) {
    let EMAIL_REGEXP = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/

    return EMAIL_REGEXP.test(c.value) ? null : {
      emailValidator: true
    };
  }
  public static nullableEmailValidator(c: FormControl) {
    if(!c.value)
    {
      return null;
    }
    
    let EMAIL_REGEXP = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/

    return EMAIL_REGEXP.test(c.value) ? null : {
      emailValidator: true
    };
  }

  public static integerOnlyValidator(c: FormControl) {
    let NUM_REGEXP = /^\d+$/
  
    return NUM_REGEXP.test(c.value) ? null : {
      integerOnlyValidator: true
    };
  }

  public static countryCodeValidator(c: FormControl) {
    let COUNTRY_CODE_REG = /^(\+?\d{1,3}|\d{1,4})$/

    return COUNTRY_CODE_REG.test(c.value) ? null : {
      countryCodeValidator: true
    };
  }

  public static numberOnlyValidator(c: FormControl) {
    let NUM_REGEXP = /^\d+$|^\d+[.,]\d+$/
  
    return NUM_REGEXP.test(c.value) ? null : {
      numberOnlyValidator: true
    };
  }

  public static greaterThanZeroValidator(c: FormControl) {
    let NUM_REGEXP = /^(0*[1-9][0-9]*([.,][0-9]+)?|0+[.,][0-9]*[1-9][0-9]*)$/
  
    return NUM_REGEXP.test(c.value) ? null : {
      greaterThanZeroValidator: true
    };
  }
}
