<div class="container">
    <div *ngIf="!passwordResetRequested; else showRequestConfirmation" class="reset-password-container">
        <h2 *ngIf="!isPrivate" class="reset-password-title">{{'reset_password.business_title' | translate}}</h2>
        <h2 *ngIf="isPrivate" class="reset-password-title">{{'reset_password.personal_title' | translate}}</h2>
        <h4 style="padding-bottom: 10px">{{'reset_password.enter_email' | translate}}</h4>
        <div class="row form-field-padding">
            <mat-form-field class="col-lg-12 align-form-field" [formGroup]="resetPasswordForm" floatLabel="never"
                appearance="outline">
                <input matInput placeholder="{{'reset_password.enter_email_placeholder' | translate}}" id="email" name="email" formControlName="email" required>
                <mat-error *ngIf="hasErrors('email')"><app-field-error-message errorMessage="{{getError('email')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>
        <div class="submit-button-container">
            <button class="primary-button submit-button" (click)="submit()">
                {{'reset_password.submit' | translate}}
            </button>
        </div>
    </div>

    <ng-template #showRequestConfirmation>
        <div class="reset-password-container">
            <div *ngIf="isPostaApproved">
                <h2 class="password-reset-requested-title">{{'reset_password.reset_requested_title' | translate}}</h2>
                <div>{{'reset_password.reset_requested_body' | translate}}</div>
                <div class="button-container">
                    <div class="flex-item">
                        <button class="primary-button go-home-button" [routerLink]="['../forgotten-password']">
                            {{'reset_password.reset_password_button' | translate}}
                        </button>
                    </div>
                    <div class="flex-item">
                        <button class="primary-button go-home-button" [routerLink]="['../home']">
                            {{'reset_password.go_home_button' | translate}}
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="!isPostaApproved">
                <h2 class="password-reset-requested-title">{{'reset_password.not_posta_approved_title' | translate}}</h2>
                <div>{{'reset_password.not_posta_approved_body' | translate}}</div>
                <div class="button-container">
                    <div class="flex-item">
                        <button class="primary-button go-home-button" [routerLink]="['../forgotten-password']">
                            {{'reset_password.reset_password_button' | translate}}
                        </button>
                    </div>
                    <div class="flex-item">
                        <button class="primary-button go-home-button" [routerLink]="['../home']">
                            {{'reset_password.go_home_button' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>