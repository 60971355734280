import { Component, OnInit } from '@angular/core';
import { TranslationHelper } from '../../shared/helpers/translation-helper';
import { FindServiceOptionDetails } from '../../shared/price-matrix/entities/FindServiceOptionDetails';
import { Pricing } from '../../shared/price-matrix/entities/pricing';
import { TransportType } from '../../shared/price-matrix/entities/TransportType';
import { ProductType } from '../../shared/price-matrix/enums/ProductType';
import { PriceMatrixService } from '../../shared/price-matrix/services/price-matrix.service';
import { ServiceOption, TestServiceOption } from '../entities/serviceOption';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  public jsonInput: string;

  public serviceOptionDetails: FindServiceOptionDetails;

  public serviceOptions: Array<TestServiceOption> = new Array<TestServiceOption>();

  insuranceVat: number;

  constructor(private priceMatrixService: PriceMatrixService, private translateHelper: TranslationHelper) { }

  ngOnInit(): void {
  }

  getPrices() {
    this.serviceOptions = new Array<TestServiceOption>();
    this.serviceOptionDetails = JSON.parse(this.jsonInput);
    console.log(`Parsed object: ${this.serviceOptionDetails}`);

    this.priceMatrixService.getServiceOptions(this.serviceOptionDetails).subscribe(transportTypes => {
      //loop through transport types and set fields

      var i: number;
      for (i = 0; i < transportTypes.length; i++) {

        this.insuranceVat = 0;

        var managedCustomCost: number;
        var managedCustomCostJson: string;
        var insuranceCost: number;
        var insuranceExtra;
        var managedCustomExtra;
        var specialExtrasOverrides;
        if (!!transportTypes[i].CountryGroups) {
          specialExtrasOverrides = transportTypes[i].CountryGroups[0].SpecialExtrasOverrides;
        }

        if (!!transportTypes[i].SpecialExtras) {
          insuranceExtra = transportTypes[i].SpecialExtras.find(x => x.Key == "0");
          managedCustomExtra = transportTypes[i].SpecialExtras.find(x => x.Key == "1");
        }

        var serviceOption = new TestServiceOption();

        //Clean this up
        if (!!transportTypes[i].SpecialExtras || !!specialExtrasOverrides) {
          //use first country group as it's the only one returned from the backend

          if (!!specialExtrasOverrides) {
            var insuranceOverride = specialExtrasOverrides.find(x => x.Key == "0")
            if (!!insuranceOverride && this.serviceOptionDetails.IsInsurance) {
              insuranceCost = this.getPricingForTransportType(new Array<Pricing>(insuranceOverride), false, serviceOption, transportTypes[i].VATPercentage);
              if (insuranceOverride.IncludeVAT)
              {
                this.insuranceVat = insuranceCost*transportTypes[i].VATPercentage;
                insuranceCost *= 1+transportTypes[i].VATPercentage;
              }
            }
            else if (!!insuranceExtra && this.serviceOptionDetails.IsInsurance) {
              insuranceCost = this.getPricingForTransportType(new Array<Pricing>(insuranceExtra), false, serviceOption, transportTypes[i].VATPercentage);
              if (insuranceExtra.IncludeVAT)
              {
                this.insuranceVat = insuranceCost*transportTypes[i].VATPercentage;
                insuranceCost *= 1+transportTypes[i].VATPercentage;
              }
            }
          }
          else if (!!transportTypes[i].SpecialExtras) {

            if (!!insuranceExtra && this.serviceOptionDetails.IsInsurance) {
              insuranceCost = this.getPricingForTransportType(new Array<Pricing>(insuranceExtra), false, serviceOption, transportTypes[i].VATPercentage);
              if (insuranceExtra.IncludeVAT)
              {
                this.insuranceVat = insuranceCost*transportTypes[i].VATPercentage;
                insuranceCost *= 1+transportTypes[i].VATPercentage;
              }
            }
          }
        }

        //You can only buy 1 stamp per send receive order so having multiple parcels defined goes against this logic.
        var isOneItem = this.serviceOptionDetails.IsOneParcel;
        if (transportTypes[i].ProductTypeId == ProductType.Postage && !isOneItem) {
          continue;
        }

        var minDays = !!transportTypes[i].MinimumDeliveryDays ? transportTypes[i].MinimumDeliveryDays : transportTypes[i].CountryGroups[0].MinimumDeliveryDays;
        var maxDays = !!transportTypes[i].MaximumDeliveryDays ? transportTypes[i].MaximumDeliveryDays : transportTypes[i].CountryGroups[0].MaximumDeliveryDays;

        serviceOption.IconPath = transportTypes[i].IconPath;
        serviceOption.Name = transportTypes[i].Name;
        serviceOption.Cost = this.getCostForTransportType(transportTypes[i], insuranceCost, serviceOption);
        serviceOption.DeliveryDays = `${minDays} - ${maxDays}`;
        serviceOption.Description = transportTypes[i].Description;
        serviceOption.KeyIconPaths = transportTypes[i].Properties.map(x => x.IconPath);
        serviceOption.Key = transportTypes[i].Key;
        serviceOption.MinDeliveryDays = minDays;
        serviceOption.ProductType = transportTypes[i].ProductTypeId;
        serviceOption.InsuranceCost = insuranceCost;
        serviceOption.ManagedCustomCost = managedCustomCost;
        serviceOption.ManagedCustomCostJson = managedCustomCostJson;
        serviceOption.BarcodePrefix = transportTypes[i].BarcodePrefix
        serviceOption.InsuranceVAT = this.insuranceVat;

        this.serviceOptions.push(serviceOption);
      }
      console.log(this.serviceOptions);
    })
  }

  private getCostForTransportType(transportType: TransportType, insuranceCost: number, serviceOption: TestServiceOption): number {
    //From the backend the country group should only return one item so just look at the first item
    var totalCost: number = 0;
    var incoTermCostSet: boolean = false;
    if (!!transportType.CountryGroups && transportType.CountryGroups.length > 0) {
      const countryGroup = transportType.CountryGroups[0];
      //If has base price set it to that otherwise look for the specific parcel range and use the base cost override

      var inputWeight = this.translateHelper.getNumber(this.serviceOptionDetails.Weight);
      if (!!transportType.BoundedWeightValue)
      {
        inputWeight = transportType.BoundedWeightValue;
      }
      var letterSize = this.serviceOptionDetails.LetterSizeTransportTypeId;
      if (!!letterSize && letterSize > 0) {
        inputWeight /= 1000;
      }
      var useWeight = ((inputWeight / this.serviceOptionDetails.Volume) / 1000) > countryGroup.FreightPivotPoint;

      serviceOption.UsingWeightPivotPoint = useWeight;

      if (countryGroup.BasePriceWeight !== 0 && useWeight) {
        totalCost = countryGroup.BasePriceWeight;
        serviceOption.BasePrice = totalCost;
      }
      else if (countryGroup.BasePriceVolume !== 0) {
        totalCost = countryGroup.BasePriceVolume;
        serviceOption.BasePrice = totalCost;
      }

      if (!!countryGroup.ParcelRanges) {
        var i: number;
        for (i = 0; i < countryGroup.ParcelRanges.length; i++) {
          const currentParcelRange = countryGroup.ParcelRanges[i];
          if (inputWeight >= currentParcelRange.MinWeight
            && inputWeight <= currentParcelRange.MaxWeight
            && this.translateHelper.getNumber(this.serviceOptionDetails.Volume) >= currentParcelRange.MinVolume
            && (this.translateHelper.getNumber(this.serviceOptionDetails.Volume) <= currentParcelRange.MaxVolume || currentParcelRange.MaxVolume == 0)) {
            //Valid parcel range found, now assign the override cost if it's still 0
            if (!!currentParcelRange.BaseCostOverride && currentParcelRange.BaseCostOverride > 0) {
              totalCost = currentParcelRange.BaseCostOverride;
              serviceOption.BasePrice = undefined;
              serviceOption.BaseCostOverride = currentParcelRange.BaseCostOverride;
            }

            if (countryGroup.FreightPivotPoint > -1) {

              if (!currentParcelRange.Fragmented) {
                if (useWeight) {
                  //Add weight unit costs
                  const weightRoundedUp = Math.ceil(inputWeight);
                  const additionalWeightCost = weightRoundedUp * currentParcelRange.CostPerUnitWeight;
                  totalCost += additionalWeightCost;
                  serviceOption.AdditionalWeightCost = additionalWeightCost;
                }
                else {
                  //Add volume unit costs
                  const volumeRoundedUp = Math.ceil(this.serviceOptionDetails.Volume);
                  const additionaVolumeCost = volumeRoundedUp * currentParcelRange.CostPerUnitVolume;
                  totalCost += additionaVolumeCost;
                  serviceOption.AdditionalVolumeCost = additionaVolumeCost;
                }
              }
              else {
                if (useWeight) {
                  //Add weight unit costs
                  const additionalWeightCost = inputWeight * currentParcelRange.CostPerUnitWeight;
                  totalCost += additionalWeightCost;
                  serviceOption.AdditionalWeightCost = additionalWeightCost;
                }
                else {
                  //Add volume unit costs
                  const additionaVolumeCost = this.serviceOptionDetails.Volume * currentParcelRange.CostPerUnitVolume;
                  totalCost += additionaVolumeCost;
                  serviceOption.AdditionalVolumeCost = additionaVolumeCost;
                }
              }
            }
            else {
              if (!currentParcelRange.Fragmented) {
                //Add weight unit costs
                const weightRoundedUp = Math.ceil(inputWeight);
                const additionalWeightCost = weightRoundedUp * currentParcelRange.CostPerUnitWeight;
                totalCost += additionalWeightCost;
                serviceOption.AdditionalWeightCost = additionalWeightCost;

                //Add volume unit costs
                const volumeRoundedUp = Math.ceil(this.serviceOptionDetails.Volume);
                const additionaVolumeCost = volumeRoundedUp * currentParcelRange.CostPerUnitVolume;
                totalCost += additionaVolumeCost;
                serviceOption.AdditionalVolumeCost = additionaVolumeCost;
              }
              else {
                //Add weight unit costs
                const additionalWeightCost = inputWeight * currentParcelRange.CostPerUnitWeight;
                totalCost += additionalWeightCost;
                serviceOption.AdditionalWeightCost = additionalWeightCost;

                //Add volume unit costs
                const additionaVolumeCost = this.serviceOptionDetails.Volume * currentParcelRange.CostPerUnitVolume;
                totalCost += additionaVolumeCost;
                serviceOption.AdditionalVolumeCost = additionaVolumeCost;
              }
            }
            break;
          }
        }
        
        if (!!transportType.IsDHL && transportType.IsDHL)
        {
          // totalCost -= serviceOption.AdditionalWeightCost;
          // serviceOption.AdditionalWeightCost = Math.ceil(serviceOption.AdditionalWeightCost);
          // totalCost += serviceOption.AdditionalWeightCost
          totalCost = Math.ceil(totalCost);
          serviceOption.IsDHL = true;
        }
        if (countryGroup.IncludeVAT)
        {
          serviceOption.BasePriceVAT = totalCost*transportType.VATPercentage;
        }
        totalCost += serviceOption.BasePriceVAT
        serviceOption.TotalBaseCost = totalCost;
        serviceOption.BaseIncludeVAT = countryGroup.IncludeVAT;


        if (!!transportType.IncoTerm && !!transportType.IncoTerm.BasePrice) {
          totalCost += transportType.IncoTerm.BasePrice;
          serviceOption.IncoTermBasePrice.push(transportType.IncoTerm.BasePrice);
        }
      }

      if (!!countryGroup.IncoTermsOverrides && countryGroup.IncoTermsOverrides.length > 0) {
        var incoTermCostSet = true;
        if (!!countryGroup.IncoTermsOverrides[0]) {
          totalCost += this.getPricingForTransportType(countryGroup.IncoTermsOverrides[0].IncoTerms, true, serviceOption, transportType.VATPercentage);
        }
      }
    }
    else {
      //if no country groups then it's domestic transport or proxy shopping
      if (!!transportType.ParcelRanges) {
        var i: number;
        var inputWeight = this.translateHelper.getNumber(this.serviceOptionDetails.Weight);

        var letterSize = this.serviceOptionDetails.LetterSizeTransportTypeId;
        if (!!letterSize && letterSize > 0) {
          inputWeight /= 1000;
        }
        for (i = 0; i < transportType.ParcelRanges.length; i++) {
          const currentParcelRange = transportType.ParcelRanges[i];
          if (inputWeight >= currentParcelRange.MinWeight
            && inputWeight <= currentParcelRange.MaxWeight) {
            //Valid parcel range found, now assign the override cost if it's still 0
            if (totalCost == 0) {
              totalCost = currentParcelRange.BaseCostOverride;
            }

            //Add weight unit costs
            const weightRoundedUp = Math.ceil(inputWeight);
            const additionalWeightCost = weightRoundedUp * currentParcelRange.CostPerUnitWeight;
            totalCost += additionalWeightCost;
          }
        }
        serviceOption.TotalBaseCost = totalCost;
      }
    }

    if (!incoTermCostSet && !!transportType.IncoTermsGroups && transportType.IncoTermsGroups.length > 0) {
      //The service will only ever return the single valid incoterm for the transport type.
      if (!!transportType.IncoTermsGroups[0]) {
        totalCost += this.getPricingForTransportType(transportType.IncoTermsGroups[0].IncoTerms, true, serviceOption, transportType.VATPercentage);
      }
    }

    if (!!insuranceCost) {
      totalCost += insuranceCost;
    }
    return +totalCost.toFixed(2);
  }

  private getPricingForTransportType(pricings: Array<Pricing>, isIncoTerms: boolean, serviceOption: TestServiceOption, vatPercentage: number): number {
    if (!pricings) {
      return 0;
    }

    var inputWeight = this.translateHelper.getNumber(this.serviceOptionDetails.Weight);
    var letterSize = this.serviceOptionDetails.LetterSizeTransportTypeId;
    if (!!letterSize && letterSize > 0) {
      inputWeight /= 1000;
    }
    var inputVolume = this.translateHelper.getNumber(this.serviceOptionDetails.Volume);
    var inputValue = this.translateHelper.getNumber(this.serviceOptionDetails.Value);
    var totalCost = 0;

    for (var i = 0; i < pricings.length; i++) {
      var weightCost = 0;
      var valueCost = 0;
      var volumeCost = 0;

      if (pricings[i].BasePriceOnly && isIncoTerms) {
        totalCost += pricings[i].BasePrice;
        serviceOption.TotalIncoTermCost += +pricings[i].BasePrice;
        serviceOption.IncoTermBasePrice.push(pricings[i].BasePrice);
        continue;
      }
      else if (pricings[i].BasePriceOnly) {
        totalCost += pricings[i].BasePrice;
        serviceOption.TotalInsuranceCost = pricings[i].BasePrice;
        serviceOption.InsuranceBasePrice.push(pricings[i].BasePrice);
        continue;
      }

      if (isIncoTerms) {
        if (!pricings[i].Fragmented) {
          if (inputWeight >= pricings[i].StartWeight && pricings[i].WeightInterval > 0 && pricings[i].PricePerWeightInterval > 0) {
            var weightDif = inputWeight - pricings[i].StartWeight;
            if (inputWeight == pricings[i].StartWeight) {
              weightDif = 1;
            }
            var absValWeightIntervals = Math.ceil(weightDif / pricings[i].WeightInterval);
            //check if it's a whole number after being divided
            // if (weightDif / pricings[i].WeightInterval - Math.floor(weightDif / pricings[i].WeightInterval) == 0 && inputWeight - pricings[i].StartWeight != 0) {
            //   absValWeightIntervals += 1;
            // }
            weightCost = absValWeightIntervals * pricings[i].PricePerWeightInterval;
            serviceOption.IncoTermWeightCost.push(absValWeightIntervals * pricings[i].PricePerWeightInterval);
          }
          else {
            serviceOption.IncoTermWeightCost.push(0);
          }
          if (inputVolume >= pricings[i].StartVolume && pricings[i].VolumeInterval > 0 && pricings[i].PricePerVolumeInterval > 0) {
            var volumeDif = inputVolume - pricings[i].StartVolume;
            if (inputVolume == pricings[i].StartVolume) {
              volumeDif = 1;
            }
            var absValVolumeIntervals = Math.ceil(volumeDif / pricings[i].VolumeInterval);
            //check if it's a whole number after being divided
            // if (volumeDif / pricings[i].VolumeInterval - Math.floor(volumeDif / pricings[i].VolumeInterval) == 0 && inputVolume - pricings[i].StartVolume != 0) {
            //   absValVolumeIntervals += 1;
            // }
            volumeCost = absValVolumeIntervals * pricings[i].PricePerVolumeInterval;
            serviceOption.IncoTermVolumeCost.push(absValVolumeIntervals * pricings[i].PricePerVolumeInterval);
          }
          else {
            serviceOption.IncoTermVolumeCost.push(0);
          }
          if (inputValue >= pricings[i].StartValue && pricings[i].ValueInterval > 0 && pricings[i].PricePerValueInterval > 0) {
            var valueDif = inputValue - pricings[i].StartValue;
            if (inputValue == pricings[i].StartValue) {
              valueDif = 1;
            }
            var absValValueIntervals = Math.ceil(valueDif / pricings[i].ValueInterval);
            //check if it's a whole number after being divided
            // if (valueDif / pricings[i].ValueInterval - Math.floor(valueDif / pricings[i].ValueInterval) == 0 && inputValue - pricings[i].StartValue != 0) {
            //   absValValueIntervals += 1;
            // }
            valueCost = absValValueIntervals * pricings[i].PricePerValueInterval;
            serviceOption.IncoTermValueCost.push(absValValueIntervals * pricings[i].PricePerValueInterval);
          }
          else {
            serviceOption.IncoTermValueCost.push(0);
          }
          if (pricings[i].IncludeVAT)
          {
            serviceOption.IncoTermVATCost.push((pricings[i].BasePrice + weightCost + valueCost + volumeCost)*vatPercentage);
            totalCost += (pricings[i].BasePrice + weightCost + valueCost + volumeCost)*(1+vatPercentage);
            serviceOption.TotalIncoTermCost += (+pricings[i].BasePrice + +weightCost + +valueCost + +volumeCost)*(1+vatPercentage);
          }
          else {
            totalCost += pricings[i].BasePrice + weightCost + valueCost + volumeCost;
            serviceOption.TotalIncoTermCost += +pricings[i].BasePrice + +weightCost + +valueCost + +volumeCost;
          }
          serviceOption.IncoTermBasePrice.push(pricings[i].BasePrice);
        }
        else {
          if (inputWeight >= pricings[i].StartWeight && pricings[i].WeightInterval > 0 && pricings[i].PricePerWeightInterval > 0) {
            var weightDif = inputWeight - pricings[i].StartWeight;
            if (isIncoTerms) {
              weightDif += 1;
            }
            var absValWeightIntervals = weightDif / pricings[i].WeightInterval;
            weightCost = absValWeightIntervals * pricings[i].PricePerWeightInterval;
            serviceOption.IncoTermWeightCost.push(absValWeightIntervals * pricings[i].PricePerWeightInterval);
          }
          else {
            serviceOption.IncoTermWeightCost.push(0);
          }
          if (inputVolume >= pricings[i].StartVolume && pricings[i].VolumeInterval > 0 && pricings[i].PricePerVolumeInterval > 0) {
            var volumeDif = inputVolume - pricings[i].StartVolume;
            if (isIncoTerms) {
              volumeDif += 1;
            }
            var absValVolumeIntervals = volumeDif / pricings[i].VolumeInterval;
            volumeCost = absValVolumeIntervals * pricings[i].PricePerVolumeInterval;
            serviceOption.IncoTermVolumeCost.push(absValVolumeIntervals * pricings[i].PricePerVolumeInterval);
          }
          else {
            serviceOption.IncoTermVolumeCost.push(0);
          }
          if (inputValue >= pricings[i].StartValue && pricings[i].ValueInterval > 0 && pricings[i].PricePerValueInterval > 0) {
            var valueDif = inputValue - pricings[i].StartValue;
            if (isIncoTerms) {
              valueDif += 1;
            }
            var absValValueIntervals = valueDif / pricings[i].ValueInterval;
            valueCost = absValValueIntervals * pricings[i].PricePerValueInterval;
            serviceOption.IncoTermValueCost.push(absValValueIntervals * pricings[i].PricePerValueInterval);
          }
          else {
            serviceOption.IncoTermValueCost.push(0);
          }
          if (pricings[i].IncludeVAT)
          {
            serviceOption.IncoTermVATCost.push((pricings[i].BasePrice + weightCost + valueCost + volumeCost)*vatPercentage);
            totalCost += (pricings[i].BasePrice + weightCost + valueCost + volumeCost)*(1+vatPercentage);
            serviceOption.TotalIncoTermCost += (+pricings[i].BasePrice + +weightCost + +valueCost + +volumeCost)*(1+vatPercentage);
          }
          else {
            totalCost += pricings[i].BasePrice + weightCost + valueCost + volumeCost;
            serviceOption.TotalIncoTermCost += +pricings[i].BasePrice + +weightCost + +valueCost + +volumeCost;
          }
          serviceOption.IncoTermBasePrice.push(pricings[i].BasePrice);
        }
      }
      else {
        if (!pricings[i].Fragmented) {
          if (inputWeight >= pricings[i].StartWeight && pricings[i].WeightInterval > 0 && pricings[i].PricePerWeightInterval > 0) {
            var weightDif = inputWeight - pricings[i].StartWeight;
            if (inputWeight == pricings[i].StartWeight) {
              weightDif = 1;
            }
            var absValWeightIntervals = Math.ceil(weightDif / pricings[i].WeightInterval);
            //check if it's a whole number after being divided
            // if (weightDif / pricings[i].WeightInterval - Math.floor(weightDif / pricings[i].WeightInterval) == 0 && inputWeight - pricings[i].StartWeight != 0) {
            //   absValWeightIntervals += 1;
            // }
            weightCost = absValWeightIntervals * pricings[i].PricePerWeightInterval;
            serviceOption.InsuranceWeightCost.push(absValWeightIntervals * pricings[i].PricePerWeightInterval);
          }
          else {
            serviceOption.InsuranceWeightCost.push(0);
          }
          if (inputVolume >= pricings[i].StartVolume && pricings[i].VolumeInterval > 0 && pricings[i].PricePerVolumeInterval > 0) {
            var volumeDif = inputVolume - pricings[i].StartVolume;
            if (inputVolume == pricings[i].StartVolume) {
              volumeDif = 1;
            }
            var absValVolumeIntervals = Math.ceil(volumeDif / pricings[i].VolumeInterval);
            //check if it's a whole number after being divided
            // if (volumeDif / pricings[i].VolumeInterval - Math.floor(volumeDif / pricings[i].VolumeInterval) == 0 && inputVolume - pricings[i].StartVolume != 0) {
            //   absValVolumeIntervals += 1;
            // }
            volumeCost = absValVolumeIntervals * pricings[i].PricePerVolumeInterval;
            serviceOption.InsuranceVolumeCost.push(absValVolumeIntervals * pricings[i].PricePerVolumeInterval);
          }
          else {
            serviceOption.InsuranceVolumeCost.push(0);
          }
          if (inputValue >= pricings[i].StartValue && pricings[i].ValueInterval > 0 && pricings[i].PricePerValueInterval > 0) {
            var valueDif = inputValue - pricings[i].StartValue;
            if (inputValue == pricings[i].StartValue) {
              valueDif = 1;
            }
            var absValValueIntervals = Math.ceil(valueDif / pricings[i].ValueInterval);
            //check if it's a whole number after being divided
            // if (valueDif / pricings[i].ValueInterval - Math.floor(valueDif / pricings[i].ValueInterval) == 0 && inputValue - pricings[i].StartValue != 0) {
            //   absValValueIntervals += 1;
            // }
            valueCost = absValValueIntervals * pricings[i].PricePerValueInterval;
            serviceOption.InsuranceValueCost.push(absValValueIntervals * pricings[i].PricePerValueInterval);
          }
          else {
            serviceOption.InsuranceValueCost.push(0);
          }
          totalCost += pricings[i].BasePrice + weightCost + valueCost + volumeCost;
          serviceOption.InsuranceBasePrice.push(pricings[i].BasePrice + weightCost + valueCost + volumeCost);
          serviceOption.TotalInsuranceCost = pricings[i].BasePrice + weightCost + valueCost + volumeCost;
        }
        else {
          if (inputWeight >= pricings[i].StartWeight && pricings[i].WeightInterval > 0 && pricings[i].PricePerWeightInterval > 0) {
            var weightDif = inputWeight - pricings[i].StartWeight;
            if (isIncoTerms) {
              weightDif += 1;
            }
            var absValWeightIntervals = weightDif / pricings[i].WeightInterval;
            weightCost = absValWeightIntervals * pricings[i].PricePerWeightInterval;
            serviceOption.InsuranceWeightCost.push(absValWeightIntervals * pricings[i].PricePerWeightInterval);
          }
          else {
            serviceOption.InsuranceWeightCost.push(0);
          }
          if (inputVolume >= pricings[i].StartVolume && pricings[i].VolumeInterval > 0 && pricings[i].PricePerVolumeInterval > 0) {
            var volumeDif = inputVolume - pricings[i].StartVolume;
            if (isIncoTerms) {
              volumeDif += 1;
            }
            var absValVolumeIntervals = volumeDif / pricings[i].VolumeInterval;
            volumeCost = absValVolumeIntervals * pricings[i].PricePerVolumeInterval;
            serviceOption.InsuranceVolumeCost.push(absValVolumeIntervals * pricings[i].PricePerVolumeInterval);
          }
          else {
            serviceOption.InsuranceVolumeCost.push(0);
          }
          if (inputValue >= pricings[i].StartValue && pricings[i].ValueInterval > 0 && pricings[i].PricePerValueInterval > 0) {
            var valueDif = inputValue - pricings[i].StartValue;
            if (isIncoTerms) {
              valueDif += 1;
            }
            var absValValueIntervals = valueDif / pricings[i].ValueInterval;
            valueCost = absValValueIntervals * pricings[i].PricePerValueInterval;
            serviceOption.InsuranceValueCost.push(absValValueIntervals * pricings[i].PricePerValueInterval);
          }
          else {
            serviceOption.InsuranceValueCost.push(0);
          }
          totalCost += pricings[i].BasePrice + weightCost + valueCost + volumeCost;
          serviceOption.InsuranceBasePrice.push(pricings[i].BasePrice + weightCost + valueCost + volumeCost);
          serviceOption.TotalInsuranceCost = pricings[i].BasePrice + weightCost + valueCost + volumeCost;
        }
      }
    }
    return totalCost;

  }


}