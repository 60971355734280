import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomerAccount } from '../entities/customerAccount';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.scss']
})
export class AccountStatementComponent implements OnInit {

  customerAccounts: CustomerAccount[];
  showAccountSelect: boolean = false;

  selectedAccount: CustomerAccount;

  downloading: boolean;
  
  filterFormGroup: FormGroup;

  downloadFailed: boolean;

  constructor(private userService: UserService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    var firstDayOfLastMonth = new Date();
    firstDayOfLastMonth.setDate(0);
    firstDayOfLastMonth.setDate(1);
    var lastDayOfLastMonth = new Date();
    lastDayOfLastMonth.setDate(0);

    this.filterFormGroup = this.formBuilder.group({
      account: ['', Validators.required],
      fromDate: [firstDayOfLastMonth],
      toDate: [lastDayOfLastMonth],
    });
    this.userService.getCustomerAccounts().subscribe(x => {
      this.customerAccounts = x;
      if(this.customerAccounts.length != 1){
        this.showAccountSelect = true;
      }
    })
  }

  downloadStatement(){
    this.downloading = true;
    var fromDateField = this.filterFormGroup.get('fromDate');
    var toDateField = this.filterFormGroup.get('toDate');
    if(fromDateField && fromDateField.value){
      try{
        var fromDate = fromDateField.value.format("YYYY-MM-DD");
      }
      catch (e) {
        //Assume date is default (ts date object instead of moment)
        var fromDate = fromDateField.value.toISOString().split('T')[0]
      }
    }
    if(toDateField && toDateField.value){
      try{
        var toDate = toDateField.value.format("YYYY-MM-DD");
      }
      catch (e) {
        //Assume date is default (ts date object instead of moment)
        var toDate = toDateField.value.toISOString().split('T')[0]
      }
    }
    if(this.customerAccounts.length == 1){
      this.selectedAccount = this.customerAccounts[0];
    }
    this.userService.getAccountStatement(this.selectedAccount.accountNo, fromDate, toDate).subscribe(x => {
      if(x){
        const byteArray = new Uint8Array(atob(x).split('').map(char => char.charCodeAt(0)));
        const blob = new Blob([byteArray], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        // window.open(url);
        var link = document.createElement('a');
        link.href = url;
        link.download = "Account Statement";
        link.click();
      }
      else{
        this.downloadFailed = true;
      }
      this.downloading = false;
    }, (error) => {
      this.downloading = false;
      this.downloadFailed = true;
    })
  }

}
