<div class="track-trace-mobile-parent">
    <div class="track-trace-mobile container">
        <div class="track-trace-container-mobile">
            <div class="first-line-track-trace">
                <h2 class="track-trace-text-mobile">{{'homepage.personal.track_trace' | translate}}</h2>
                <!-- <i class="track-trace-icon fa fa-map-marker"></i> -->
                <img class="track-trace-icon" src="{{cdnPath}}/images/m_track_trace.svg" />
            </div>
            <div class="track-trace-search-container">
                <mat-form-field class="track-trace-input" appearance="outline">
                    <input placeholder="{{'homepage.personal.enter_number' | translate}}" [(ngModel)]="trackTraceInput" type="text" name="trackTraceInput" (keyup.enter)="onTrackTraceEnter()" matInput>
                </mat-form-field>
                <button class="search-button" [routerLink]="['../../send-receive/track-trace', trackTraceInput]">
                    <img class="" src="/assets/icons/search_arrow.png" />
                </button>
            </div>
        </div>
        <button class="secondary-button second-image-button-mobile">
            <h2 class="find-text">{{'homepage.personal.find' | translate}}</h2>
        </button>
        <div class="row third-image-buttons-container-mobile">
            <div class="button-container-mobile col-sm-4 col-4">
                <div *ngIf="!!mainImageModule" style="padding-right: 8px;">
                    <button class="secondary-button third-image-button-padded-mobile" [routerLink]="mainImageModule.Content.Item1Link.Url">
                        <div>
                            <img class="icon-button-mobile" [src]="item1Icon" />
                        </div>
                        <h2>{{mainImageModule.Content.Item1Link.Title}}</h2>
                    </button>
                </div>
            </div>
            <div class="button-container-mobile col-sm-4 col-4">
                <div style="padding-right: 4px; padding-left: 4px;">
                    <button *ngIf="!!mainImageModule" class="secondary-button third-image-button-padded-mobile" [routerLink]="mainImageModule.Content.Item2Link.Url">
                        <div>
                            <img class="icon-button-mobile" [src]="item2Icon" />
                        </div>
                        <h2>{{mainImageModule.Content.Item2Link.Title}}</h2>
                    </button>
                </div>
            </div>
            <div class="button-container-mobile col-sm-4 col-4">
                <div style="padding-left: 8px;">
                    <button *ngIf="!!mainImageModule" class="secondary-button third-image-button-mobile" [routerLink]="mainImageModule.Content.Item3Link.Url">
                        <div>
                            <img class="icon-button-mobile" [src]="item3Icon">
                        </div>
                        <h2>{{mainImageModule.Content.Item3Link.Title}}</h2>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container image-container-mobile">
    <img class="image-mobile" [src]="mainImage" />
</div>

<div class="background-grey" *ngIf="!!mainImage">
    <div class="container image-container">
        <img class="main-image" [src]="mainImage" />
        <div class="layer">
    
        </div>
        <div class="button-container">
            <div class="container">
                <div class="float-right">
                    <div class="track-trace-container" style="margin-left: 15px;">
                        <div class="first-line-track-trace">
                            <h2 class="track-trace-text">{{'homepage.personal.track_trace' | translate}}</h2>
                            <!-- <i class="track-trace-icon fa fa-map-marker"></i> -->
                            <img class="track-trace-icon" src="{{cdnPath}}/images/m_track_trace.svg" />
    
                        </div>
                        <div class="track-trace-search-container">
                            <mat-form-field class="track-trace-input" appearance="outline">
                                <input placeholder="{{'homepage.personal.enter_number' | translate}}" [(ngModel)]="trackTraceInput" (keyup.enter)="onTrackTraceEnter()" matInput>
                            </mat-form-field>
                            <button class="search-button" [routerLink]="['../send-receive/track-trace', trackTraceInput]">
                                <img class="" src="/assets/icons/search_arrow.png" />
                            </button>
                        </div>
                    </div>
                    <div class="second-image-button" style="margin-left: 15px;">
                        <h2 class="find-text">{{'homepage.personal.find' | translate}}</h2>
                    </div>
                    <div *ngIf="!!mainImageModule" class="row third-image-buttons-container" style="margin-left: 15px;">
                        <button class="secondary-button third-image-button-padded" [routerLink]="mainImageModule.Content.Item1Link.Url">
                            <div>
                                <img class="icon-button" [src]="item1Icon" />
                            </div>
                            <span>{{mainImageModule.Content.Item1Link.Title}}</span>
                        </button>
                        <button class="secondary-button third-image-button-padded" [routerLink]="mainImageModule.Content.Item2Link.Url">
                            <div>
                                <img class="icon-button" [src]="item2Icon" />
                            </div>
                            {{mainImageModule.Content.Item2Link.Title}}
                        </button>
                        <button class="secondary-button third-image-button" [routerLink]="mainImageModule.Content.Item3Link.Url">
                            <div>
                                <img class="icon-button" [src]="item3Icon">
                            </div>
                            {{mainImageModule.Content.Item3Link.Title}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showHtmlModules" [innerHTML]="pageHtml | safeHtml"></div>
