import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShoppingListService } from '../services/shopping-list-service';
import { ShoppingListsLoggedOut } from '../models/ShoppingListLoggedOut';
import { ShoppingList } from '../models/ShoppingList';

@Component({
  selector: 'app-view-shared-shopping-list',
  templateUrl: './view-shared-shopping-list.component.html',
  styleUrls: ['./view-shared-shopping-list.component.scss']
})
export class ViewSharedShoppingListComponent implements OnInit {

  shoppingList: any;
  isLoading: boolean= false;
  public groupedProductsByRetailer: Array<any> = []

  constructor(private _route: ActivatedRoute, private shoppingListService: ShoppingListService) { }

  ngOnInit(): void {
    this.isLoading = true;
    const sharedLinkId = this._route.snapshot.paramMap.get('sharedId');
    this.shoppingListService.getSharedShoppingList(sharedLinkId).subscribe(x => {
      this.shoppingList = x;
      x.products.forEach(element => {
        if (element.imageData) {
          element.imageUrl = 'data:image/jpeg;base64,' + element.imageData;
          let img = new Image();

          img.src = element.imageUrl;
          img.onload = () => {
            var ratio = Math.min(80 / img.naturalWidth, 106 / img.naturalHeight);
            element.width = img.naturalWidth * ratio;
            element.height = img.naturalHeight * ratio;
          }
        }
        else {
          element.imageUrl = "/assets/icons/shopping-list/No-Image-Placeholder.png";
        }
      });
      const grouped = this.groupBy(x.products, 'retailerName');
      for (let retailer of Object.entries(grouped)) {
        this.groupedProductsByRetailer.push(retailer);
      }
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    });
  }

  private groupBy(xs, prop) {
    var grouped = {};
    for (var i = 0; i < xs.length; i++) {
      var p = xs[i][prop];
      if (!grouped[p]) { grouped[p] = []; }
      grouped[p].push(xs[i]);
    }
    return grouped;
  }

}
