<div class="container">
    <div class="login-container" *ngIf="!isLoggedIn">
        <app-inline-login [buyPostageForm]="emailForm" (onLoggedIn)="onLoggedIn($event)"></app-inline-login>
    </div>
    <div class="addresses-container">
        <div class="row">
            <div class="col-lg-6 col-12">
                <div class="address-title-container">
                    <h3 class="primary">{{'buy_postage.from' | translate}}</h3>
                </div>
                <div class="address-section logged-in-section" *ngIf="isLoggedIn">
                    <div class="row field-row noMargin">
                        <div>
                            <label>
                                <span style="padding-right: 5px;">
                                    <input type="radio" name="fromAddress"
                                    [value]="true"
                                    (change)="accountAddressClick()"
                                    [(ngModel)]="isAccountAddress">
                                </span>
                                <span class="checkmark"></span>
                                <h4 style="display: inline-block;">{{'send_receive.send_receive.step_one.account_address.account_address' | translate}}</h4>
                            </label>
                        </div>
                    </div>
                    <div class="row field-row noMargin">
                        <div>
                            <label>
                                <span style="padding-right: 5px;">
                                    <input type="radio" name="fromAddress"
                                    [value]="false"
                                    (change)="accountAddressClick()"
                                    [(ngModel)]="isAccountAddress">
                                </span>
                                <span class="checkmark"></span>
                                <h4 style="display: inline-block;">{{'send_receive.send_receive.step_one.new_or_from_address_book' | translate}}</h4>
                            </label>
                        </div>
                    </div>
                    <div class="row noMargin" *ngIf="!isAccountAddress">
                        <div class="col-sm col-12 noPadding">
                            <form>
                                <mat-form-field style="width: 100%" floatLabel="never" appearance="outline">
                                    <input class="text-input" (click)="addressFieldClick(false)" matInput placeholder="{{'send_receive.send_receive.step_one.search_addresses' | translate}}"
                                        [formControl]="fromAddressControl" [matAutocomplete]="auto">
                                </mat-form-field>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAddress"
                                    (optionSelected)="setFromSelectedAddress($event.option.value)">
                                    <mat-option *ngFor="let address of fromFilteredAddresses | async"
                                        [value]="address">
                                        {{address.firstName}} {{address.lastName}}, {{address.postcode}}
                                    </mat-option>
                                </mat-autocomplete>
                            </form>
                        </div>
                        <div class="col-sm-auto col-12 noPadding address-book-button-container">
                            <button class="primary-button address-book-button"
                                (click)="setFromAddressFields()">{{'send_receive.send_receive.step_one.confirm' | translate}}</button>
                        </div>
                        <div class="col-12 manage-addresses-link-container">
                            <a target="_blank" style="font-weight: 500; color: var(--primary-colour);" routerLink="../../my-posta/address-book">{{'send_receive.send_receive.step_one.manage_addresses' | translate}}</a>
                        </div>
                    </div>
                </div>
                <div class="address-section" #fromName>
                    <div class="row">
                        <mat-form-field class="col-12 form-field-with-error-spacing" [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <input class="text-input" matInput id="fromFirstName" placeholder="{{'address_book.address_details.first_name' | translate}}" name="fromFirstName"
                                formControlName="fromFirstName" required>
                                <mat-error *ngIf="hasErrors('fromFirstName')"><app-field-error-message errorMessage="{{getError('fromFirstName')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
            
                    <div class="row">
                        <mat-form-field class="col-12" [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <input class="text-input" matInput placeholder="{{'address_book.address_details.last_name' | translate}}" id="fromLastName" name="fromLastName"
                                formControlName="fromLastName" required>
                                <mat-error *ngIf="hasErrors('fromLastName')"><app-field-error-message errorMessage="{{getError('fromLastName')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="address-section row" #fromPhone>
                    <div class="col-auto noPadding">
                        <mat-form-field class="country-code-form-field align-form-field" style="margin-right: 10px;"
                            [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{'buy_postage.contact_number_country_code_placeholder' | translate}}" id="fromContactCountryCode" name="fromContactCountryCode" formControlName="fromContactCountryCode" autocomplete="tel-country-code"
                                required>
                                <mat-error *ngIf="hasErrors('fromContactCountryCode')"><app-field-error-message></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col noPadding">
                        <mat-form-field class="telephone-form-field align-form-field" [formGroup]="buyPostageAddressesForm"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{'buyPostage.contact_number_placeholder' | translate}}" id="fromContactNum" name="fromContactNum" autocomplete="tel-national"
                                formControlName="fromContactNum" required>
                                <mat-error *ngIf="hasErrors('fromContactNum')"><app-field-error-message errorMessage="{{getError('fromContactNum')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="address-section noBorder" #fromAddress>
                    <div class="row">
                        <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <input class="text-input" matInput placeholder="{{'address_book.address_details.address_line_1' | translate}}" id="fromAddressLine1"
                                name="fromAddressLine1" formControlName="fromAddressLine1" required>
                                <mat-error *ngIf="hasErrors('fromAddressLine1')"><app-field-error-message errorMessage="{{getError('fromAddressLine1')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
            
                    <div class="row">
                        <mat-form-field class="col-lg-4 col-md-6 form-field-with-error-spacing" [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <mat-select disableOptionCentering panelClass="select-panel" (selectionChange)="setSelectedPostcodeFO($event)" formControlName="fromPostcode" id="fromPostcode" name="fromPostcode" required
                                placeholder="{{'address_book.address_details.postcode' | translate}}">
                                <mat-option *ngFor="let postcode of postcodes" [value]="postcode.postcode">{{postcode.postcode}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="hasErrors('fromPostcode')"><app-field-error-message></app-field-error-message></mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-lg-8 col-md-6 form-field-with-error-spacing" [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <mat-select disableOptionCentering panelClass="select-panel" (selectionChange)="setSelectedPostcodeFO($event)" formControlName="fromTownCity" id="fromTownCity" name="fromTownCity" required
                            placeholder="{{'address_book.address_details.town_city' | translate}}">
                                <mat-option *ngFor="let postcode of postcodes" [value]="postcode.city">{{postcode.city}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="hasErrors('fromTownCity')"><app-field-error-message errorMessage="{{getError('fromTownCity')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div class="row">
                        <mat-form-field class="col-lg-12" floatLabel="never" appearance="outline">
                            <input class="text-input" matInput value="{{'buy_postage.faroe_islands' | translate}}" disabled>
                        </mat-form-field>
                    </div>
                </div>
                <div class="notification-preferences">
                    <h4 class="primary">{{'buy_postage.notification_preferences' | translate}}</h4>
                    <div class="row">
                        <div class="col-12 my-custom-input-label-box align-center"
                            [formGroup]="buyPostageAddressesForm">
                            <!-- checkbox needs styling -->
                            <mat-checkbox formControlName="registerForEmail">
                                <div>{{ 'buy_postage.email_pref' | translate }}
                                </div>
                            </mat-checkbox>
                        </div>
                        <div class="col-12 my-custom-input-label-box align-center"
                            [formGroup]="buyPostageAddressesForm">
                            <!-- checkbox needs styling -->
                            <mat-checkbox formControlName="registerForSMS">
                                <div>{{ 'buy_postage.sms_pref' | translate }}
                                </div>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 toAddress">
                <div class="address-title-container">
                    <h3 class="primary">{{'buy_postage.to' | translate}}</h3>
                </div>
                <div class="address-section logged-in-section" *ngIf="isLoggedIn">
                    <div class="row field-row noMargin">
                        <div>
                            <label>
                                <span style="padding-right: 5px;">
                                    <input type="radio" name="toAddress"
                                    [value]="true"
                                    [(ngModel)]="isNewAddress">
                                </span>
                                <span class="checkmark"></span>
                                <h4 style="display: inline-block;">{{'buy_postage.addresses.new' | translate}}</h4>
                            </label>
                        </div>
                    </div>
                    <div class="row field-row noMargin">
                        <div>
                            <label>
                                <span style="padding-right: 5px;">
                                    <input type="radio" name="toAddress"
                                    [value]="false"
                                    [(ngModel)]="isNewAddress">
                                </span>
                                <span class="checkmark"></span>
                                <h4 style="display: inline-block;">{{'buy_postage.addresses.from_address_book' | translate}}</h4>
                            </label>
                        </div>
                    </div>
                    <div class="row noMargin" *ngIf="!isNewAddress">
                        <div class="col-sm col-12 noPadding">
                            <form>
                                <mat-form-field style="width: 100%" floatLabel="never" appearance="outline">
                                    <input class="text-input" (click)="addressFieldClick(true)" matInput placeholder="{{'send_receive.send_receive.step_one.search_addresses' | translate}}"
                                        [formControl]="toAddressControl" [matAutocomplete]="auto">
                                </mat-form-field>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAddress"
                                    (optionSelected)="setToSelectedAddress($event.option.value)">
                                    <mat-option *ngFor="let address of toFilteredAddresses | async"
                                        [value]="address">
                                        {{address.firstName}} {{address.lastName}}, {{address.postcode}}
                                    </mat-option>
                                </mat-autocomplete>
                            </form>
                        </div>
                        <div class="col-sm-auto col-12 noPadding address-book-button-container">
                            <button class="primary-button address-book-button"
                                (click)="setToAddressFields()">{{'send_receive.send_receive.step_one.confirm' | translate}}</button>
                        </div>
                        <div class="col-12 manage-addresses-link-container">
                            <a target="_blank" style="font-weight: 500; color: var(--primary-colour);" routerLink="../../my-posta/address-book">{{'send_receive.send_receive.step_one.manage_addresses' | translate}}</a>
                        </div>
                    </div>
                </div>
                <div class="address-section" #toName>
                    <div class="row">
                        <mat-form-field class="col-12 form-field-with-error-spacing" [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <input class="text-input" matInput id="toFirstName" placeholder="{{'address_book.address_details.first_name' | translate}}" name="toFirstName"
                                formControlName="toFirstName" required>
                                <mat-error *ngIf="hasErrors('toFirstName')"><app-field-error-message errorMessage="{{getError('toFirstName')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
            
                    <div class="row">
                        <mat-form-field class="col-12" [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <input class="text-input" matInput placeholder="{{'address_book.address_details.last_name' | translate}}" id="toLastName" name="toLastName"
                                formControlName="toLastName" required>
                                <mat-error *ngIf="hasErrors('toLastName')"><app-field-error-message errorMessage="{{getError('toLastName')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="address-section row" #toPhone>
                    <div class="col-auto noPadding">
                        <mat-form-field class="country-code-form-field align-form-field" style="margin-right: 10px;"
                            [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{'buy_postage.contact_number_country_code_placeholder' | translate}}" id="toContactCountryCode" name="toContactCountryCode" formControlName="toContactCountryCode" autocomplete="tel-country-code"
                                required>
                                <mat-error *ngIf="hasErrors('toContactCountryCode')"><app-field-error-message></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col noPadding">
                        <mat-form-field class="telephone-form-field align-form-field" [formGroup]="buyPostageAddressesForm"
                            floatLabel="never" appearance="outline">
                            <input matInput placeholder="{{'buyPostage.contact_number_placeholder' | translate}}" id="toContactNum" name="toContactNum" autocomplete="tel-national"
                                formControlName="toContactNum" required>
                                <mat-error *ngIf="hasErrors('toContactNum')"><app-field-error-message errorMessage="{{getError('toContactNum')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="address-section" #toAddress>
                    <div class="row">
                        <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <input class="text-input" matInput placeholder="{{'address_book.address_details.address_line_1' | translate}}" id="toAddressLine1"
                                name="toAddressLine1" formControlName="toAddressLine1" required>
                                <mat-error *ngIf="hasErrors('toAddressLine1')"><app-field-error-message errorMessage="{{getError('toAddressLine1')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <input class="text-input" matInput placeholder="{{'address_book.address_details.address_line_2' | translate}}" id="toAddressLine2"
                                name="toAddressLine2" formControlName="toAddressLine2">
                                <mat-error *ngIf="hasErrors('toAddressLine2')"><app-field-error-message errorMessage="{{getError('toAddressLine2')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
            
                    <div class="row">
                        <mat-form-field class="col-lg-4 col-md-6 form-field-with-error-spacing" [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <input class="text-input" matInput placeholder="{{'address_book.address_details.postcode' | translate}}" id="toPostcode" name="toPostcode"
                                formControlName="toPostcode" required>
                            <mat-error *ngIf="hasErrors('toPostcode')"><app-field-error-message></app-field-error-message></mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-lg-8 col-md-6 form-field-with-error-spacing" [formGroup]="buyPostageAddressesForm" floatLabel="never" appearance="outline">
                            <input class="text-input" matInput placeholder="{{'address_book.address_details.town_city' | translate}}" id="toTownCity" name="toTownCity"
                                formControlName="toTownCity" required>
                            <mat-error *ngIf="hasErrors('toTownCity')"><app-field-error-message errorMessage="{{getError('toTownCity')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>

                    <div class="row">
                        <mat-form-field class="col-lg-12" floatLabel="never" appearance="outline" [formGroup]="buyPostageAddressesForm">
                            <mat-select disableOptionCentering panelClass="select-panel" formControlName="toCountry" placeholder="{{'address_book.address_details.country' | translate}}"  required>
                                <mat-option *ngFor="let country of countries" [value]="setCountryByCode(country)">{{getCountryNameForLanguage(country)}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="hasErrors('toCountry')"><app-field-error-message errorMessage="{{getError('toCountry')}}"></app-field-error-message></mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="address-section noBorder">
                    *{{'buy_postage.privacy_policy_label' | translate}} <a [routerLink]="getPrivacyLink()">{{'buy_postage.privacy_policy_link' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
    <app-error-message *ngIf="noEmailOrAccount()" errorMessage="{{'buy_postage.login_or_email_required' | translate}}"></app-error-message>
    
    <div class="proceed-container">
        <div class="row">
            <div class="col-lg-3 col-3 hide-mobile">
                <button class="tertiary-button back-button" (click)="goToPreviousPage()">
                    <div class="full-width">
                        <img class="back-icon" src="/assets/icons/arrows/back_button_icon@3x.png" />
                        <span>{{'buy_postage.secondary_page.back' | translate}}</span>
                    </div>
                </button>
            </div>
            <div class="col-lg-3 col-3 hide-mobile">
                <button class="tertiary-button clear-all-button" (click)="clearAll()">
                    <div class="full-width">
                        <span class="clear-all-icon-text">{{'buy_postage.clear_all' | translate}}</span>
                        <img class="clear-all-icon" src="/assets/icons/arrows/close_button_icon@3x.png" />
                    </div>
                </button>
            </div>
            <div class="col-lg-3 offset-lg-3 col-sm-3 offset-sm-3 col-12 proceed-button-div" style="display: flex;">
                <button class="primary-button" (click)="goToNextPage()">{{'buy_postage.secondary_page.proceed' | translate}}</button>
            </div>
            <div class="col-12 show-mobile" style="padding-top: 20px;">
                <button class="tertiary-button back-button" (click)="goToPreviousPage()">
                    <div class="full-width">
                        <img class="back-icon" src="/assets/icons/arrows/back_button_icon@3x.png" />
                        <span>{{'buy_postage.secondary_page.back' | translate}}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>