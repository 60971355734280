import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../../../account/services/role.service';
import { DownloadOutput } from '../../../send-receive/entities/DownloadOutput';
import { SendReceiveService } from '../../../send-receive/new-order/send-and-receive/services/send-receive.service';
import { BuyPostageService } from '../../../send-receive/services/buy-postage.service';
import { GenericOrder } from '../../entities/genericOrder';
import { StaticContentHelper } from '../../helpers/static-content-helper';
import { OrdersService } from "../../services/orders.service";
import { StaticContentService } from '../../services/static-content.service';


@Component({
  selector: 'app-view-all-orders',
  templateUrl: './view-all-orders.component.html',
  styleUrls: ['./view-all-orders.component.scss']
})
export class ViewAllOrdersComponent implements OnInit {

  public pageTitle: string;

  public pageModules: string;

  orders: Array<GenericOrder> = new Array<GenericOrder>();

  selectedStatus: string = "All";

  selectedStatusIndex: number = 0;

  statuses: Array<string> = ["All", "All live orders", "All completed orders"]

  loading: boolean = false;

  showViewMoreButton: boolean = false;

  viewMoreMultiplier: number = 1;

  filterBySpecificDate: boolean = false;

  filterFormGroup: FormGroup;

  filterQueryString: string;

  deliveredIds = [3,4,5];

  attemptedDeliveryIds = [6,7,14,34,35];

  returnToSenderIds = [8,10,85];

  inTransitLocationUpdateIds = [38,39,40,41,43,44,45,46,47,48,49,50,52,53,54,55,56,57,58,59,60,61,62,
  64,65,66,67,68,69,70,71,72,75,76,77,78,84,86,87,88,89,90,91,94,97,113,117];

  delayedNoActionIds = [12,13,15,22,23,24,63,73,108,114];

  unableToDeliveryIds = [9,11,16,17,18,20,21,25,26,28,29,32,33,51,98,99,104,105,107,112,118];

  delayedActionIds = [19,30,31,42,74,79,80,81,82,83,95,96,100,101,102,103,106,109,110,111,115,116];

  confirmedEventTypeIds = [1,92];

  readyToCollectIds = [27];

  cancelledEventTypeIds = [2, 93];

  receivedIds = [37];

  interval: any;

  constructor(private ordersService: OrdersService, private formBuilder: FormBuilder,
    private router: ActivatedRoute, private routerLink: Router, private sendReceiveService: SendReceiveService,
    private translateService: TranslateService, private buyPostageService: BuyPostageService, private staticContentService: StaticContentService, private roleService: RoleService) { }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
    })

    this.router.queryParams.subscribe(params => {

      const status = params['status'];
      if (!!status) {
        this.selectedStatusIndex = Number(status);
        this.selectedStatus = this.statuses[status];
      }
    });

    this.loading = true;
    this.ordersService.getOrderList(`?status=${this.selectedStatusIndex}&skipCount=0&takeCount=10`).subscribe(x => {
      this.orders = x;
      if (this.orders.length == 10) {
        this.showViewMoreButton = true;
      }
      this.loading = false;
    }, (error => {
      this.loading = false;
    }));

    this.filterFormGroup = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
      search: [''],
    });

  }

  public getPageTitle()
  {
    return this.staticContentService.getPageTitle();
  }

  public getIcon(eventTypeId: number)
  {
    if (this.deliveredIds.includes(eventTypeId))
    {
      return "/assets/icons/track-trace/tt_11_delivered_120.png";
    }
    else if (this.attemptedDeliveryIds.includes(eventTypeId))
    {
      return "/assets/icons/track-trace/tt_09_attempted_delivery_120.png";
    }
    else if (this.returnToSenderIds.includes(eventTypeId))
    {
      return "/assets/icons/track-trace/tt_12_return_to_sender.png";
    }
    else if (this.inTransitLocationUpdateIds.includes(eventTypeId))
    {
      return "/assets/icons/track-trace/tt_05_location_120.png";
    }
    else if (this.delayedNoActionIds.includes(eventTypeId))
    {
      return "/assets/icons/track-trace/tt_07_delay_120.png";
    }
    else if (this.unableToDeliveryIds.includes(eventTypeId))
    {
      return "/assets/icons/track-trace/tt_13_unable_to_deliver.png";
    }
    else if (this.delayedActionIds.includes(eventTypeId))
    {
      return "/assets/icons/track-trace/tt_06_delay_action_rq_120.png";
    }
    else if (this.confirmedEventTypeIds.includes(eventTypeId))
    {
      return "/assets/icons/track-trace/tt_01_confirmed_120.png";
    }
    else if (this.readyToCollectIds.includes(eventTypeId))
    {
      return "/assets/icons/track-trace/tt_10_ready_to_collect_120.png";
    }
    else if (this.cancelledEventTypeIds.includes(eventTypeId))
    {
      return "/assets/icons/track-trace/tt_02_cancelled_120.png";
    }
    else if (this.receivedIds.includes(eventTypeId))
    {
      return "/assets/icons/track-trace/tt_03_received_120.png";
    }
  }

  public getOrdersByStatus(event: string) {
    switch (event) {
      case "All": {
        this.selectedStatus = event;
        this.selectedStatusIndex = 0;
        this.viewMoreMultiplier = 1;
        this.searchByFilter();
        break;
      }
      case "All live orders": {
        this.selectedStatus = event;
        this.selectedStatusIndex = 1;
        this.viewMoreMultiplier = 1;
        this.searchByFilter();
        break;
      }
      case "All completed orders": {
        this.selectedStatus = event;
        this.selectedStatusIndex = 2;
        this.viewMoreMultiplier = 1;
        this.searchByFilter();
        break;
      }
    }
  }

  public viewMore() {
    this.viewMoreMultiplier += 1;
    console.log(this.selectedStatusIndex);

    switch (this.selectedStatusIndex) {
      case 0: {
        this.getOrderByParams(`?status=0&skipCount=0&takeCount=${this.viewMoreMultiplier * 10}`)
        break;
      }
      case 1: {
        this.getOrderByParams(`?status=1&skipCount=0&takeCount=${this.viewMoreMultiplier * 10}`)
        break;
      }
      case 2: {
        this.getOrderByParams(`?status=2&skipCount=0&takeCount=${this.viewMoreMultiplier * 10}`)
        break;
      }
    }
  }

  private getOrderByParams(params: string) {
    this.loading = true;
    this.ordersService.getOrderList(params).subscribe(x => {
      this.orders = x;
      if (this.orders.length == (this.viewMoreMultiplier * 10)) {
        this.showViewMoreButton = true;
      }
      else {
        this.showViewMoreButton = false;
      }
      this.loading = false;
    }, (error => {
      this.loading = false;
    }));
  }

  public searchByFilter() {
    var queryString: string = `?skipCount=0&takeCount=${this.viewMoreMultiplier * 10}`;
    var search = this.filterFormGroup.get('search');

    queryString = queryString.concat(`&status=${this.selectedStatusIndex}`)

    if (this.filterBySpecificDate) {
      var fromDate = this.filterFormGroup.get('fromDate');
      var toDate = this.filterFormGroup.get('toDate');
      if (!!fromDate && !!fromDate.value) {
        queryString = queryString.concat(`&fromDate=${fromDate.value.format("YYYY-MM-DD")}`)
      }
      if (!!toDate && toDate.value) {
        queryString = queryString.concat(`&toDate=${toDate.value.format("YYYY-MM-DD")}`)
      }
    }

    if (!!search && !!search.value) {
      queryString = queryString.concat(`&searchString=${search.value}`)
    }
    this.filterQueryString = queryString;

    this.getOrderByParams(this.filterQueryString);
  }

  public checkAllTime() {
    this.filterBySpecificDate = false;
    this.filterFormGroup.get('fromDate').reset();
    this.filterFormGroup.get('toDate').reset();
  }

  public clearSearchFilters() {
    var allTimeButton = document.getElementById("all-time-radio-button") as HTMLInputElement;
    if (!!allTimeButton) {
      this.filterBySpecificDate = false;
      allTimeButton.checked = true;
    }

    this.filterFormGroup.reset();
    this.selectedStatus = "All"
    this.getOrdersByStatus("All");

  }

  public selectSpecificDate() {
    var specificDateButton = document.getElementById("specific-date-radio-button") as HTMLInputElement;
    if (!!specificDateButton) {
      specificDateButton.checked = true;
      this.filterBySpecificDate = true;
    }

  }

  public routeToOrder(order: GenericOrder) {
    //send receive order
    if (order.serviceTypeId == 2 || order.serviceTypeId == 6 || order.serviceTypeId == 9) {
      return `../send-receive/${order.orderReference}`;
    }
    else if (order.serviceTypeId == 5) {
      return `../buy-postage/${order.orderReference}`;
    }
    else {
      return null;
    }
  }

  public downloadReceipt(order: GenericOrder) {
    //send receive order
    if (order.serviceTypeId == 2 || order.serviceTypeId == 6 || order.serviceTypeId == 9) {
      // this.downloadingPdfOrder = true;
      // if (order.serviceTypeId == 5) {
      //   this.serviceType = ServiceType.Letter
      // }

      this.sendReceiveService.downloadReceipt(order.serviceTypeId, order.orderReference, this.translateService.currentLang).subscribe(x => {
        this.createPdfDownload(x);
      }, (error) => {
      })
    } //buy postage
    else if (order.serviceTypeId == 5) {
      this.buyPostageService.downloadReceipt(order.orderReference, this.translateService.currentLang).subscribe(x => {
        this.createPdfDownload(x);
        // this.downloadingReceipt = false;
      }, (error) => {
        // this.downloadingReceipt = false;
      })
    }
    else {
      return null;
    }
  }

  private createPdfDownload(x: DownloadOutput) {
    const byteArray = new Uint8Array(atob(x.content).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    // window.open(url);
    var link = document.createElement('a');
    link.href = url;
    link.download = x.fileName;
    link.click();
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }

  // openTrackTrace(orderRef: string)
  // {
  //   const url = this.routerLink.serializeUrl(
  //     this.routerLink.createUrlTree([`${this.translateService.currentLang}/${this.roleService.role()}/send-receive/track-trace/${orderRef}`])
  //   );

  //   window.open(url, '_blank');
  // }

    openTrackTrace(orderRef: string)
  {
    return `../../../send-receive/track-trace/${orderRef}`;
  }
}
