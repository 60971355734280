import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslationHelper } from '../../../shared/helpers/translation-helper';
import { TranslationString } from '../../../shared/price-matrix/entities/TranslationString';
import { TransportType } from '../../../shared/price-matrix/entities/TransportType';
import { PriceMatrixService } from '../../../shared/price-matrix/services/price-matrix.service';
import { StaticContentHelper } from '../../../shared/helpers/static-content-helper';
import { StaticContentService } from '../../../shared/services/static-content.service';

@Component({
  selector: 'app-buy-postage-landing',
  templateUrl: './buy-postage-landing.component.html',
  styleUrls: ['./buy-postage-landing.component.scss']
})
export class BuyPostageLandingComponent implements OnInit {

  constructor(private priceMatrixService: PriceMatrixService,
    private translationHelper: TranslationHelper,
    private formBuilder: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private staticContentService: StaticContentService,
    private translateService: TranslateService) { }

  interval: any;

  image: string;

  quickBuyDomesticForm: FormGroup;

  quickBuyInternationalForm: FormGroup;

  transportType: TransportType;

  maxDomesticWeight: number = 0;

  maxInternationalWeight: number = 0;

  domesticPricePerUnit: number = 0;

  internationalPricePerUnit: number = 0;

  public pageTitle: string;

  public pageModules: string;

  ngOnInit(): void {

    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        this.image = this.staticContentService.getImageForPsModule();
        // this.image = this.staticContentHelper.getImageForPsModule('import/pay-invoice');
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
      this.image = this.staticContentService.getImageForPsModule();
    })

    this.quickBuyDomesticForm = this.formBuilder.group({
      quantity: ['', Validators.required]
    });

    this.quickBuyInternationalForm = this.formBuilder.group({
      internationalQuantity: ['', Validators.required]
    });

    this.quickBuyDomesticForm.get("quantity").setValue(1);
    this.quickBuyInternationalForm.get("internationalQuantity").setValue(1);

    this.priceMatrixService.getLetterSizes().subscribe(x => {
      this.transportType = x[0];

      var domesticOption = this.transportType.CountryGroups.find(x => x.LinkedGroupKey == 0);
      var maxDomesticWeights = domesticOption.ParcelRanges.map(x => x.MaxWeight);
      this.maxDomesticWeight = Math.max.apply(null, maxDomesticWeights);

      this.domesticPricePerUnit = domesticOption.ParcelRanges.find(x => x.MaxWeight == this.maxDomesticWeight).BaseCostOverride;

      var international = this.transportType.CountryGroups.find(x => x.LinkedGroupKey == 1);
      var maxInternationalWeights = international.ParcelRanges.map(x => x.MaxWeight);
      this.maxInternationalWeight = Math.max.apply(null, maxInternationalWeights);

      this.internationalPricePerUnit = international.ParcelRanges.find(x => x.MaxWeight == this.maxDomesticWeight).BaseCostOverride;
    })
  }

  public getPageTitle()
  {
    return this.staticContentService.getPageTitle();
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }

  public getTranslation(translationString: TranslationString) {
    return this.translationHelper.getTranslation(translationString);
  }

  public incrementDomesticItemCount() {
    var itemCount = this.quickBuyDomesticForm.get("quantity").value;
    this.quickBuyDomesticForm.get("quantity").setValue(Number(itemCount) + 1);
  }

  public decreaseDomesticItemCount() {
    var itemCount = this.quickBuyDomesticForm.get("quantity").value;

    if (itemCount == 0) {
      return;
    }
    this.quickBuyDomesticForm.get("quantity").setValue(Number(itemCount) - 1);
  }

  public incrementInternationalItemCount() {
    var itemCount = this.quickBuyInternationalForm.get("internationalQuantity").value;
    this.quickBuyInternationalForm.get("internationalQuantity").setValue(Number(itemCount) + 1);
  }

  public decreaseInternationalItemCount() {
    var itemCount = this.quickBuyInternationalForm.get("internationalQuantity").value;

    if (itemCount == 0) {
      return;
    }

    this.quickBuyInternationalForm.get("internationalQuantity").setValue(Number(itemCount) - 1);
  }

  public getInternationalPrice() {
    return this.internationalPricePerUnit * this.quickBuyInternationalForm.get("internationalQuantity").value;
  }

  public getDomesticPrice() {
    return this.domesticPricePerUnit * this.quickBuyDomesticForm.get("quantity").value;
  }

  public navigatetoBuyPostage(isDomestic: boolean) {

    if ((isDomestic && !this.quickBuyDomesticForm.valid) || (!isDomestic && !this.quickBuyInternationalForm.valid)) {
      return;
    }

    var itemCount = isDomestic ? this.quickBuyDomesticForm.get("quantity").value : this.quickBuyInternationalForm.get("internationalQuantity").value;
    var zoneId = isDomestic ? 0 : 1;
    var transportTypeKey = this.transportType.Key
    var maxWeight = isDomestic ? this.maxDomesticWeight : this.maxInternationalWeight;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "itemCount": itemCount,
        "zoneId": zoneId,
        "id": transportTypeKey,
        "maxWeight": maxWeight
      },
    };

    this.router.navigate([`../${this.translate.currentLang}/private/send-receive/buy-postage`], navigationExtras);
  }

}
