import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../../account/services/role.service';
import { StaticContentService } from '../../shared/services/static-content.service';
import { CustomerShipment } from '../entities/CustomerShipment';
import { ImportExportServiceType } from '../entities/ImportExportServiceType';
import { WaybillService } from '../services/waybill.service';

@Component({
  selector: 'app-waybill-list',
  templateUrl: './waybill-list.component.html',
  styleUrls: ['./waybill-list.component.scss']
})
export class WaybillListComponent implements OnInit {

  public pageTitle: string;

  public pageModules: string;

  waybills: Array<CustomerShipment> = new Array<CustomerShipment>();

  loading: boolean = false;

  showViewMoreButton: boolean = false;

  viewMoreMultiplier: number = 1;

  filterBySpecificDate: boolean = false;

  filterFormGroup: FormGroup;

  //filterQueryString: string;

  interval: any;

  constructor(private waybillService: WaybillService, private formBuilder: FormBuilder,
    private router: ActivatedRoute, private routerLink: Router,
    private translateService: TranslateService, private staticContentService: StaticContentService, private roleService: RoleService) { }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
    })

    this.loading = true;
    this.filterFormGroup = this.formBuilder.group({
      fromDate: [''],
      toDate: [''],
    });
    this.waybillService.getCustomerShipmentList().subscribe(x => {
      this.onRecieveCustomerShipmentList(x);
    }, (error => {
      this.loading = false;
    }));


  }

  onRecieveCustomerShipmentList(shipments: CustomerShipment[]){
    
    this.waybills = shipments;
    if (this.waybills.length == 10) {
      this.showViewMoreButton = true;
    }
    this.loading = false;
  }

  public getPageTitle()
  {
    return this.staticContentService.getPageTitle();
  }

  public getIcon(typeName: string)
  {
    var translationRequest = `waybill_list.${typeName.toLowerCase()}_icon`;
    var iconPath = this.translateService.instant(translationRequest);

    //If key is not matched (output equals input), use manual fallback icon
    if(iconPath == translationRequest){
      switch (typeName) {
        case "AIRFREIGHT":
          //return "/assets/icons/find-prices/air_freight.svg";
          return "/assets/icons/find-prices/Air_Freight.png";
        case "SEAFREIGHT":
          // return "/assets/icons/find-prices/sea_freight.svg";
          return "/assets/icons/find-prices/Sea_Freight.png";
        case "TRUCKING":
          // return "/assets/icons/find-prices/domestic.svg";
          return "/assets/icons/find-prices/Domestic.png";
        case "EXPRESS":
          // return "/assets/icons/find-prices/surface_mail_new.svg";
          return "/assets/icons/find-prices/Surface_Mail.png";
        default:
          break;
      }
    }
    
    return iconPath;
  }

  getImportExportType(type: ImportExportServiceType){
    switch (type) {
      case ImportExportServiceType.AirExport:
        return this.translateService.instant("waybill_list.air_export");
      case ImportExportServiceType.AirImport:
        return this.translateService.instant("waybill_list.air_import");
      case ImportExportServiceType.ExpressExport:
        return this.translateService.instant("waybill_list.express_export");
      case ImportExportServiceType.ExpressImport:
        return this.translateService.instant("waybill_list.express_import");
      case ImportExportServiceType.SeaExport:
        return this.translateService.instant("waybill_list.sea_export");
      case ImportExportServiceType.SeaImport:
        return this.translateService.instant("waybill_list.sea_import");
      default:
        break;
    }
  }

  getServiceType(type: string){
    switch (type) {
      case "Sea Freight":
        return "waybill_list.sea_freight";
      case "Air Freight":
        return "waybill_list.air_freight";
      case "Express Freight":
        return "waybill_list.express_freight";
    
      default:
        break;
    }
  }

  public viewMore() {
    this.viewMoreMultiplier += 1;
    //WBTODO Should waybills paginate?
  }

  public searchByFilter() {
    var fromDateParam;
    var toDateParam;
    var fromDate = this.filterFormGroup.get('fromDate');
    var toDate = this.filterFormGroup.get('toDate');
    if (fromDate && fromDate.value) {
      fromDateParam = fromDate.value.toJSON();
    }
    if (toDate && toDate.value) {
      toDateParam = toDate.value.toJSON();
    }

    this.waybillService.getCustomerShipmentList(fromDateParam, toDateParam).subscribe(x => {
      this.onRecieveCustomerShipmentList(x);
    }, (error => {
      this.loading = false;
    }));
  }

  public checkAllTime() {
    this.filterBySpecificDate = false;
    this.filterFormGroup.get('fromDate').reset();
    this.filterFormGroup.get('toDate').reset();
    this.waybillService.getCustomerShipmentList(null, null).subscribe(x => {
      this.onRecieveCustomerShipmentList(x);
    }, (error => {
      this.loading = false;
    }));
  }

  public clearSearchFilters() {
    var allTimeButton = document.getElementById("all-time-radio-button") as HTMLInputElement;
    if (!!allTimeButton) {
      this.filterBySpecificDate = false;
      allTimeButton.checked = true;
    }

    this.filterFormGroup.reset();
    this.waybillService.getCustomerShipmentList(null, null).subscribe(x => {
      this.onRecieveCustomerShipmentList(x);
    }, (error => {
      this.loading = false;
    }));

  }

  public selectSpecificDate() {
    var specificDateButton = document.getElementById("specific-date-radio-button") as HTMLInputElement;
    if (!!specificDateButton) {
      specificDateButton.checked = true;
      this.filterBySpecificDate = true;
    }

  }

  public getWaybillDetail(waybill: CustomerShipment) {
    this.routerLink.navigate([`./${waybill.shipmentType}/${waybill.manifestNo}/${waybill.waybillNo}`], {relativeTo: this.router })
  }

  
  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }

  trackTraceUrl(orderRef: string){
    const url = this.routerLink.serializeUrl(
      this.routerLink.createUrlTree([`../send-receive/track-trace/${orderRef}`], {relativeTo: this.router })
    );
    return url;
  }
  
  handleEvent(event) {
    event.stopPropagation();
  }

}
