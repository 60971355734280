export enum ProductType {
  DomesticTransport = 1,
  Sea_Freight = 2,    //  in use
  Air_Freight = 3,    //  in use
  Postage = 4,    //  in use; satmps
  //Parcel = 2, // Possibly replaced by Sea_Parcel and Air_Parcel
  //Trucking = 3, // Same as DomesticTransport; so leave it as unused
  DHL = 5,        //  in use
  Sea_Parcel = 6, //  in use
  Air_Parcel = 7, //  in use
  Postage_Plus = 8, // Not in use; just a placeholder
  FedEx = 9,  // Not in use and doesn't exist as on today
  Parcel = 10
  }