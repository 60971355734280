import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddressBookComponent } from './address-book/address-book.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AuthGuard } from './guards/auth.guard';
import { OrdersSummaryComponent } from './my-posta/all-orders/orders-summary/orders-summary.component';
import { ViewAllOrdersComponent } from './my-posta/view-all-orders/view-all-orders.component';

const routes: Routes = [
    { path: ':lang/:role/my-posta/address-book', component: AddressBookComponent, canActivate: [AuthGuard]},
    { path: ':lang/:role/my-posta/orders', component: OrdersSummaryComponent, canActivate: [AuthGuard]},
    { path: ':lang/:role/my-posta/orders/view-all', component: ViewAllOrdersComponent, canActivate: [AuthGuard]},
    { path: ':lang/:role/help-information/contact-us', component: ContactUsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
