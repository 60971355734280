import { Invoice } from "../../shared/entities/Invoice";
import { OrderService } from "./OrderService";
import { Parcel } from "./parcel";
import { InvoiceLine } from "./InvoiceLine";
import { OrderLetter } from "./OrderLetter";
import { CustomsItemType } from "./CustomsItemType";

export class Order {
    orderStateId: number;
    orderReference?: string;
    customsShipmentTypeId?: number;
    productTypeId: number;
    isPaid?: boolean;
    externalId?: string;
    isDirectBooking?: boolean;
    trackingCode?: string;
    paymentCodes?: Array<string>;

    fromTitle: string;
    fromFirstName: string;
    fromLastName: string;
    fromCompanyName: string;
    fromAddressLine1: string;
    fromAddressLine2: string;
    fromTownCity: string;
    fromPostcode: string;
    fromCountryCode: string;
    fromContactNumber: string;
    fromContactEmail: string;
    fromSpecialInstructions: string;

    toTitle: string;
    toFirstName: string;
    toLastName: string;
    toCompanyName: string;
    toAddressLine1: string;
    toAddressLine2: string;
    toTownCity: string;
    toPostcode: string;
    toCountryCode: string;
    toContactNumber: string;
    toContactEmail: string;
    toSpecialInstructions: string;

    senderNamePostaDepot: string;
    isFromPostaDepot: boolean;
    isToPostaDepot: boolean;
    isFromAccountAddress: boolean;
    isToAccountAddress: boolean;
    insuranceSelected: boolean;

    customerReference: string;
    weight: number;
    valueOfGoods: number;
    volume: number;
    collectionDate?: Date;
    definitionOfGoods?: string;
    comments?: string;
    domesticPackageContents?: string;
    
    estimatedDeliveryDate?: Date;
    isInvoiceRequired?: boolean;
    totalCost?: number;
    customsClearanceExtraJson?: string;
    incoTermsCode?: string;

    service: OrderService;
    parcels: Array<Parcel>;
    letters?: Array<OrderLetter>;
    invoices?: Array<Invoice>;
    invoiceLines?: Array<InvoiceLine>;

    attemptedPaymentYN: boolean;
    barcodePrefix: string;
    isCreateTrackTraceRegistration?: boolean;
    isVatIncluded: boolean;
    vatAmount?: number;

    languageCode: string;
    orderReferenceOther?: string;

    isCDSFiled?: boolean;
    barcode?: string;

    customsItemTypes: CustomsItemType[]
}