<div *ngIf="successfulVerification; else showError" class="container">
    <div class="content-container">
        <h2 class="verified-title">{{'account_verification.verified_title' | translate }}</h2>
        <div>{{'account_verification.verified_body' | translate }}</div>
        <div>
            <!-- This may have to change in the future as we currently only verifiy private users we can hardcode -->
            <button class="primary-button go-home-button" [routerLink]="['../../private/login']">
                {{'account_verification.login' | translate }}
            </button>
        </div>
    </div>
</div>
<ng-template #showError>
    <div class="container">
        <div class="content-container">
            <h2 class="verified-title">{{'account_verification.invalid_code_title' | translate }}</h2>
            <div>{{'account_verification.invalid_code_body' | translate }}</div>
        </div>
    </div>
</ng-template>