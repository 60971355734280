import { Pipe, PipeTransform } from '@angular/core';
import { TranslationHelper } from '../helpers/translation-helper'

@Pipe({
  name: 'numberFormat'
})

export class NumberFormatPipe implements PipeTransform {
    constructor(private translateHelper: TranslationHelper) {}
    transform(value: any): any {
            return this.translateHelper.getNumString2DP(value);
    }

}