import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorHelper } from 'src/app/shared/helpers/error-helper';

@Component({
  selector: 'app-posta-depot-address',
  templateUrl: './posta-depot-address.component.html',
  styleUrls: ['./posta-depot-address.component.scss']
})
export class PostaDepotAddressComponent implements OnInit {

  @Input()
  toFromAddressform: FormGroup

  @Input()
  isSender: boolean

  constructor(private errorHelper: ErrorHelper) { }

  ngOnInit(): void {
  }

  public hasErrors(field) {
    var errors = this.toFromAddressform.get(field).errors;
    return !!errors;
  }

  public getError(field) {
    var errors = this.toFromAddressform.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

}
