import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserService } from '../../../../account/services/user.service';
import { User } from '../../../../account/entities/user';
import { IncoTermsService } from '../../../../shared/services/inco-terms.service';
import { Country } from '../../../../account/entities/Country';
import { IncoTermsInput } from '../../../../shared/entities/IncoTermsInput';
import { TransportType } from '../../../../shared/price-matrix/entities/TransportType';
import { TranslateService } from '@ngx-translate/core';
import { CustomShipmentType } from '../../../../shared/entities/customShipmentType';
import { ActivatedRoute, Router } from '@angular/router';
import { SendReceiveService } from '../services/send-receive.service';
import { Address } from '../../../../account/entities/address';
import { CountryService } from '../../../../shared/services/country.service';
import { Parcel } from '../../../entities/parcel';
import { ServiceOption } from '../../../entities/serviceOption';
import { Shipment } from '../../../entities/shipment';
import { StaticContentHelper } from '../../../../shared/helpers/static-content-helper';
import { StaticContentService } from '../../../../shared/services/static-content.service';

@Component({
  selector: 'app-send-receive-confirmation',
  templateUrl: './send-receive-confirmation.component.html',
  styleUrls: ['./send-receive-confirmation.component.scss']
})
export class SendReceiveConfirmationComponent implements OnInit, OnChanges {

  @Input()
  addressForm: FormGroup

  @Input()
  specificationForm: FormGroup

  @Input()
  orderDetailsForm: FormGroup

  @Input()
  confirmationForm: FormGroup

  @Input()
  serviceOption: ServiceOption

  @Input()
  items: Array<Parcel>;

  @Input()
  shipmentType: CustomShipmentType;

  @Input()
  uploadedFiles: any;

  @Input()
  insuranceSelected: boolean;

  @Input()
  parcelVolume: number;

  @Input()
  fromDepot: boolean;

  @Input()
  toDepot: boolean;

  @Input()
  fromAccountAddress: boolean;

  @Input()
  toAccountAddress: boolean;

  @Input()
  incoTerms: string;

  @Input()
  customsClearanceSelected: boolean;

  @Input()
  psModule: any;

  @Input()
  totalCost: number;

  @Input()
  isDomestic: boolean;

  depotAddress: Address;

  userDetails: User;

  userProfile: any;

  countries: Array<Country> = new Array<Country>();

  public psModuleContent: any;

  item1Link: any;

  item2Link: any;

  item3Link: any;

  getFromName(){
    if(this.fromAccountAddress && localStorage.getItem("role") != "private" && this.userProfile.company){
      return this.userProfile.company.companyName;
    }
    else{
      return `${this.addressForm.get('fromFirstName').value} ${this.addressForm.get('fromLastName').value}`
    }
  }

  constructor(
    private userAccountService: UserService,
    private translateService: TranslateService,
    private sendReceiveService: SendReceiveService,
    private countryService: CountryService,
    private staticContentService: StaticContentService,
    private router: Router,
    private userService: UserService) { }

  ngOnInit(): void {
    this.userAccountService.getUserDetails().subscribe(x => {
      this.userDetails = x;
    })

    var role = localStorage.getItem("role");
    if (role == "private")
    {
      this.userService.getIndividualUserProfile().subscribe(x =>{
        this.userProfile = x;
      })
    }
    else{
      this.userService.getBusinessUserProfile().subscribe(x =>{
        this.userProfile = x;
      })
    }

    this.countryService.getAll().subscribe(x => {
      this.countries = x;
      // this.countries.sort(function(x,y){ return x.countryCode == "FO" ? -1 : y.countryCode == "FO" ? 1 : 0; });
      // this.countries.sort(function(x,y){ return x.countryCode == "DK" ? -1 : y.countryCode == "DK" ? 1 : 0; });
    })

    this.psModuleContent = this.staticContentService.getPsModuleContent('send-receive', 'send-receive/send-receive');
    if (!!this.psModuleContent) {
      this.item1Link = this.psModuleContent.Item5Link;
      this.item2Link = this.psModuleContent.Item1Link; //Item6Link;
      this.item3Link = this.psModuleContent.Item2Link; //Item7Link;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("current items" + this.items);
    if (this.fromDepot) {
      this.getDepotAddress();
    }
    if (changes['psModule']) {
      this.item1Link = this.psModule.Item5Link;
      this.item2Link = this.psModule.Item1Link; //Item6Link;
      this.item3Link = this.psModule.Item2Link; //Item7Link;
    }
  }

  public getLetterSizeName(): string {
    var letterSizeControl = this.specificationForm.get("letterSize");
    if (!!letterSizeControl && !!letterSizeControl.value) {
      var letterSizeControlValue: TransportType = letterSizeControl.value;
      switch (this.translateService.currentLang) {
        case "en":
          return `${letterSizeControlValue.Name.EN}`;
        case "fo":
          return `${letterSizeControlValue.Name.FO}`;
      }
    }
  }

  public getDepotAddress() {
    if (!!this.serviceOption) {
      if (this.serviceOption.Name.EN.toLocaleLowerCase().includes('sea')) {
        this.sendReceiveService.getDepotAddress(true).subscribe(x => {
          this.depotAddress = x;
        })
      }
      else {
        this.sendReceiveService.getDepotAddress(false).subscribe(x => {
          this.depotAddress = x;
        })
      }
    }
  }

  public getCountryName(controlFormValue: Country) {
    if (!!controlFormValue) {
      switch (this.translateService.currentLang) {
        case "en":
          return `${controlFormValue.countryNameEN}`;
        case "fo":
          return `${controlFormValue.countryNameFO}`;
      }
    }
  }

  public getCountryNameByCode(countryCode: string) {
    var country = this.countries.find(x => x.countryCode == countryCode);

    if (!!country) {
      switch (this.translateService.currentLang) {
        case "en":
          return country.countryNameEN;
        case "fo":
          return country.countryNameFO;
      }
    }
  }

  public getCostPerItem(): number {
    if (!!this.serviceOption)
    {
      if (!!this.serviceOption.ManagedCustomCost && this.customsClearanceSelected)
      {
        return (this.serviceOption.ManagedCustomCost + this.serviceOption.Cost);
      }
      return this.serviceOption.Cost;
    }
    return 0;
  }

  public getVATCost(): number {
    if (!!this.serviceOption)
    {
      if (this.isDomestic) {
        return this.serviceOption.VATCost * this.items.length;
      }
      return this.serviceOption.VATCost;
    }
  }

  public deleteFile(file: any) {
    this.uploadedFiles = this.uploadedFiles.filter(x => x !== file);
  }

  public getCollectionDate() {
    if (!this.orderDetailsForm.get('collectionDate').value)
    {
      return 
    }
    const date = new Date(this.orderDetailsForm.get('collectionDate').value).toLocaleString('en-GB', { timeZone: 'UTC' });
    return date.split(',')[0];
  }

  public getEstimatedDeliveryDate() {
    var dateString: string = "";

    if (!!this.serviceOption) {
      var estimatedDeliveryDays = this.serviceOption.MinDeliveryDays + 1;

      if (!!this.orderDetailsForm.get('collectionDate').value)
      {
        var date = new Date(this.orderDetailsForm.get('collectionDate').value)
        date = this.addDays(date, estimatedDeliveryDays);
  
        dateString = date.toLocaleString('en-GB', { timeZone: 'UTC' });
      }
      else {
        var todaysDate = new Date();
        date = this.addDays(todaysDate, estimatedDeliveryDays);
        dateString = date.toLocaleString('en-GB', { timeZone: 'UTC' });
      }
      return dateString.split(',')[0];
    }
  }

  public getVolume() {
    var volume: number = 0;
    if (!!this.items) {
      for (let i = 0; i < this.items.length; i++) {
        volume += this.items[i].length * this.items[i].width * this.items[i].height;
      }
    }
    if (!!this.parcelVolume) {
      volume = this.parcelVolume;
    }
    if (!!this.specificationForm.get("totalParcelVolume") && !!this.specificationForm.get("totalParcelVolume").value) {
      volume = this.specificationForm.get("totalParcelVolume").value;
    }
    return volume;

  }

  public getWeightMeasurement() {
    if(!!this.specificationForm.get('isKg') && this.specificationForm.get('isKg').value == "false"){
      return "g";
    }
    if (!!this.items && this.items.length > 0) {
      return "kg"
    }
    return "g";
  }

  public getServiceOptionName(): string {
    if (!!this.serviceOption) {
      switch (this.translateService.currentLang) {
        case "en":
          return `${this.serviceOption.Name.EN}`;
        case "fo":
          return `${this.serviceOption.Name.FO}`;
      }
    }
  }

  private addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  public getCustomsShipmentTypeName(shipmentType: CustomShipmentType) {
    switch (this.translateService.currentLang) {
      case "en":
        return `${this.shipmentType.customsShipmentTypeNameEN}`;
      case "fo":
        return `${this.shipmentType.customsShipmentTypeNameFO}`;
    }
  }

  public openRouteInNewWindow(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }
}