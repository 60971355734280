<div class="paid-order-confirmation-container">
    <h2 class="thank-you-title">{{'ccs_payment_confirmation.step_three_confirmation_page.thank_you_for_payment' | translate}}</h2>
    <h3 class="transaction-ref">{{'pay_customs.step_three_confirmation_page.po_number' | translate}}: {{ccsSubmission.wisePONumber}}</h3>
    <div class="paid-order-button-container">
        <button [disabled]="downloadingInvoice" class="primary-button paid-order-button" mat-button (click)="downloadInvoice()">
            <div class="spinner">
                <span [ngClass]="downloadingInvoice? 'margin-left-spinner-text' : ''">{{'ccs_payment_confirmation.download_invoice' | translate}}</span>
                <mat-spinner *ngIf="downloadingInvoice" style="margin-left: 15px;" diameter="15">
                </mat-spinner>
            </div>
        </button>
        <button [disabled]="downloadingReceipt" class="primary-button paid-order-button" mat-button (click)="downloadReceipt()">
            <div class="spinner">
                <span [ngClass]="downloadingReceipt? 'margin-left-spinner-text' : ''">{{'ccs_payment_confirmation.download_receipt' | translate}}</span>
                <mat-spinner *ngIf="downloadingReceipt" style="margin-left: 15px;" diameter="15">
                </mat-spinner>
            </div>
        </button>
    </div>
    <app-error-message *ngIf="!!invoiceError" errorMessage="{{'ccs_payment_confirmation.contact_posta' | translate}} {{invoiceError}}"></app-error-message>
</div>