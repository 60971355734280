import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentRequest } from '.././entities/PaymentRequest'
import { DownloadOutput } from '.././entities/DownloadOutput'
import { PaymentInfo } from '.././entities/PaymentInfo'
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: 'root'
})
//TODO change this PayCustomsService
export class PaymentService {

    constructor(private httpClient: HttpClient) {
    }

    public validate(paymentType: number, invoicePoNumber: string, refCode: string): Observable<boolean> {
        return this.httpClient.get(`${environment.apiUrl}/api/Payment/${paymentType}/${invoicePoNumber}/${refCode}/validate`) as Observable<boolean>;
    }

    public GetPaymentInfo(paymentType: number, invoicePoNumber: string, refCode: string): Observable<PaymentInfo> {
        return this.httpClient.get(`${environment.apiUrl}/api/Payment/${paymentType}/${invoicePoNumber}/${refCode}`) as Observable<PaymentInfo>;
    }

    public dibsPay(url: string): Observable<any> {
        return this.httpClient.get(url);
    }

    public getReceipt(paymentType: number, invoicePoNumber): Observable<PaymentRequest> {
        return this.httpClient.get(`${environment.apiUrl}/api/payment/${paymentType}/${invoicePoNumber}/GetReceipt`) as Observable<PaymentRequest>
    }
    
    public downloadInvoice(paymentType: number, invoicePoNumber: string, refCode: string): Observable<DownloadOutput> {
        return this.httpClient.get(`${environment.apiUrl}/api/Payment/${paymentType}/${invoicePoNumber}/${refCode}/DownloadInvoice`) as Observable<DownloadOutput>;
    }

    public downloadReceipt(paymentType: number, invoicePoNumber, refCode: string, languageCode: string): Observable<DownloadOutput> {
        return this.httpClient.get(`${environment.apiUrl}/api/Payment/${paymentType}/${invoicePoNumber}/${refCode}/${languageCode}/DownloadReceipt`) as Observable<DownloadOutput>;
    }

    public updatePaymentStatus(statusUpdateObj: any)
    {
        return this.httpClient.put(`${environment.apiUrl}/api/Payment/`, statusUpdateObj) as Observable<any>;
    }
}
