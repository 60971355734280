<div class="to-option-container border-bottom" style="padding-bottom: 0px;">
    <div class="row">
        <div class="col-sm col-12 mobile-padding-right-30">
            <form>
                <mat-form-field style="width: 100%" floatLabel="never" appearance="outline">
                    <input class="text-input" (click)="addressFieldClick()" matInput placeholder="{{'send_receive.send_receive.step_one.search_addresses' | translate}}"
                        [formControl]="toAddressControl" [matAutocomplete]="auto">
                </mat-form-field>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAddress"
                    (optionSelected)="settoSelectedAddress($event.option.value)">
                    <mat-option *ngFor="let address of toFilteredAddresses | async"
                        [value]="address">
                        {{address.firstName}} {{address.lastName}}, {{address.postcode}}
                    </mat-option>
                </mat-autocomplete>
            </form>
        </div>
        <div class="col-sm-auto col-12 mob-only-padding address-book-button-container mobile-padding-right-30">
            <button class="primary-button address-book-button"
                (click)="settoAddressFields()">{{'send_receive.send_receive.step_one.confirm' | translate}}</button>
        </div>
        <div class="col-12 manage-addresses-link-container">
            <a target="_blank" style="font-weight: 500; color: var(--primary-colour);" routerLink="../../my-posta/address-book">{{'send_receive.send_receive.step_one.manage_addresses' | translate}}</a>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr>
        </div>
        <div class="col-auto center-vertical">
            <p class="child-center-vertical" style="color: #a5a7a7;">{{'send_receive.send_receive.step_one.or_enter_new' | translate}}</p><!---->
        </div>
        <div class="col">
            <hr>
        </div>
    </div>
    <div class="row" style="display: flex; justify-content: center; align-items: center;">
        <div class="col-12 select-country-container child-center-vertical">
            <h3>{{'send_receive.send_receive.step_one.select_country' | translate}}</h3>
        </div>
        <div class="col-md-auto col-12 child-center-vertical">
            <div>
                <label style="display: flex;">
                    <span class="radio-button align-center">
                        <input type="radio" name="to-fo"
                            (change)="onSelecttoFO($event)"
                            [value]="true" [(ngModel)]="toFO" [disabled]="toFODisabled">
                    </span>
                    <span class="checkmark"></span>
                    <h4 class="regular-font-weight">{{'send_receive.send_receive.step_one.faroe_islands' | translate}}</h4> <!---->
                </label>
            </div>
        </div>
        <div class="col-auto child-center-vertical">
            <h3>{{'send_receive.send_receive.step_one.or' | translate}}</h3> <!---->
        </div>
        <div class="col" style="margin-top: 28px;" [formGroup]="sendReceiveForm">
            <mat-form-field class="select-container float-never" floatLabel="never" appearance="outline" [formGroup]="sendReceiveForm">
                <mat-label>{{'address_book.address_details.country' | translate}}</mat-label>
                <mat-select disableOptionCentering panelClass="select-panel" (selectionChange)="updateSpecificationForm($event)" formControlName="toCountry" required>
                    <mat-option *ngFor="let country of countries" [value]="setCountryByCode(country)">{{getCountryNameForLanguage(country)}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasErrors('toCountry')"><app-field-error-message errorMessage="{{getError('toCountry')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
<div *ngIf="hasSelectedCountry()" class="to-option-container border-bottom">
        <!-- <div class="row">
            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm"
                floatLabel="never" appearance="outline">
                <input class="text-input" matInput id="toTitle"
                    placeholder="{{'address_book.address_details.title' | translate}}" name="toTitle"
                    formControlName="toTitle">
                    <mat-error *ngIf="hasErrors('toTitle')"><app-field-error-message errorMessage="{{getError('toTitle')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div> -->

        <div class="row">
            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm"
                floatLabel="never" appearance="outline">
                <input class="text-input" matInput id="toFirstName"
                    placeholder="{{'address_book.address_details.first_name' | translate}}" name="toFirstName"
                    formControlName="toFirstName" required>
                    <mat-error *ngIf="hasErrors('toFirstName')"><app-field-error-message errorMessage="{{getError('toFirstName')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-12 col-md-6" [formGroup]="sendReceiveForm" floatLabel="never"
                appearance="outline">
                <input class="text-input" matInput
                    placeholder="{{'address_book.address_details.last_name' | translate}}" id="toLastName"
                    name="toLastName" formControlName="toLastName" required>
                    <mat-error *ngIf="hasErrors('toLastName')"><app-field-error-message errorMessage="{{getError('toLastName')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>
</div>
<div *ngIf="hasSelectedCountry()" class="to-option-container border-bottom">
    <!-- <div class="col-lg-12 col-md-12" style="padding-left: 0px;"> -->

        <div class="row">

            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm"
                floatLabel="never" appearance="outline">
                <input class="text-input" matInput
                    placeholder="{{'address_book.address_details.company_name' | translate}}" id="toOptionalCompany"
                    name="toOptionalCompany" formControlName="toOptionalCompany">
                    <mat-error *ngIf="hasErrors('toOptionalCompany')"><app-field-error-message errorMessage="{{getError('toOptionalCompany')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm"
                floatLabel="never" appearance="outline">
                <input class="text-input" matInput
                    placeholder="{{'address_book.address_details.address_line_1' | translate}}" id="toAddressLine1"
                    name="toAddressLine1" formControlName="toAddressLine1" required>
                    <mat-error *ngIf="hasErrors('toAddressLine1')"><app-field-error-message errorMessage="{{getError('toAddressLine1')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <!-- <div class="row">
            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm"
                floatLabel="never" appearance="outline">
                <input class="text-input" matInput
                    placeholder="{{'address_book.address_details.address_line_2' | translate}}" id="toAddressLine2"
                    name="toAddressLine2" formControlName="toAddressLine2">
                    <mat-error *ngIf="hasErrors('toAddressLine2')"><app-field-error-message errorMessage="{{getError('toAddressLine2')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div> -->

        <div *ngIf="toFO" class="row">
            <mat-form-field class="col-lg-4 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <mat-select disableOptionCentering panelClass="select-panel" (selectionChange)="setSelectedPostcodeFO($event)" formControlName="toPostcode" id="toPostcode" name="toPostcode" required
                    placeholder="{{'address_book.address_details.postcode' | translate}}">
                    <mat-option *ngFor="let postcode of postcodes" [value]="postcode.postcode">{{postcode.postcode}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasErrors('toPostcode')"><app-field-error-message></app-field-error-message></mat-error>
            </mat-form-field>
            <mat-form-field class="col-lg-8 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm" floatLabel="never" appearance="outline">
                <mat-select disableOptionCentering panelClass="select-panel" (selectionChange)="setSelectedPostcodeFO($event)" formControlName="toTownCity" id="toTownCity" name="toTownCity" required
                placeholder="{{'address_book.address_details.town_city' | translate}}">
                    <mat-option *ngFor="let postcode of postcodes" [value]="postcode.city">{{postcode.city}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasErrors('toTownCity')"><app-field-error-message errorMessage="{{getError('toTownCity')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="!toFO" class="row">
            <mat-form-field class="col-lg-12 col-md-6 form-field-with-error-spacing" [formGroup]="sendReceiveForm"
                floatLabel="never" appearance="outline">
                <input class="text-input" matInput
                    placeholder="{{'address_book.address_details.town_city' | translate}}" id="toTownCity"
                    name="toTownCity" formControlName="toTownCity" required>
                    <mat-error *ngIf="hasErrors('toTownCity')"><app-field-error-message errorMessage="{{getError('toTownCity')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="!toFO" class="row">
            <form class="col-lg-12 col-md-6 form-field-with-error-spacing">
                <mat-form-field style="width: 100%" [formGroup]="sendReceiveForm" floatLabel="never"
                    appearance="outline">
                    <input class="text-input" matInput
                        placeholder="{{'address_book.address_details.postcode' | translate}}" id="toPostcode"
                        name="toPostcode" formControlName="toPostcode" required>
                        <mat-error *ngIf="hasErrors('toPostcode')"><app-field-error-message errorMessage="{{getError('toPostcode')}}"></app-field-error-message></mat-error>
                </mat-form-field>
            </form>
        </div>

        <!-- <div class="row">
            <div class="col-lg-12 col-md-6"
                [formGroup]="sendReceiveForm">
                <mat-form-field class="select-container float-never" floatLabel="never" appearance="outline"
                    [formGroup]="sendReceiveForm">
                    <mat-label>{{'address_book.address_details.country' | translate}}</mat-label>
                    <mat-select disableOptionCentering panelClass="select-panel" formControlName="toCountry" required>
                        <mat-option *ngFor="let country of countries" [value]="setCountryByCode(country)">
                            {{getCountryNameForLanguage(country)}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasErrors('toCountry')"><app-field-error-message errorMessage="{{getError('toCountry')}}"></app-field-error-message></mat-error>
                </mat-form-field>
            </div>
        </div> -->

        <div class="row">
            <mat-form-field [formGroup]="sendReceiveForm" class="col-lg-12 col-md-6 form-field-with-error-spacing"
                floatLabel="never" appearance="outline">
                <input class="text-input" matInput formControlName="toContactNumber"
                    placeholder="{{'address_book.address_details.contact_number' | translate}}">
                    <mat-error *ngIf="hasErrors('toContactNumber')"><app-field-error-message errorMessage="{{getError('toContactNumber')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field [formGroup]="sendReceiveForm" class="col-lg-12 col-md-6 form-field-with-error-spacing" floatLabel="never" appearance="outline">
                <input class="text-input" matInput formControlName="toEmail" placeholder="{{'address_book.address_details.email' | translate}}">
                <mat-error *ngIf="hasErrors('toEmail')"><app-field-error-message errorMessage="{{getError('toEmail')}}"></app-field-error-message></mat-error>
            </mat-form-field>
        </div>



        <div *ngIf="newAddressBookEntry" class="row">
            <div class="col-lg-12 col-md-10 my-custom-input-label-box">
                <div class="align-center">
                    <input [disabled]="saveDisabled" id="to-address-book-input" type="checkbox" name="to-address-book-input" />
                    <label for="to-address-book-input" (click)="toggleSave()"></label>
                    <span class="checkbox-text-padding">{{'address_book.address_details.save_to_address_book' |
                        translate}}</span>
                    <input id="to-favourites-input" type="checkbox" name="to-favourites-input" />
                    <label for="to-favourites-input" (click)="toggleFavourite()"></label>
                    <span class="checkbox-text-padding">{{'address_book.address_details.add_to_favourites' |
                        translate}}</span>
                </div>
            </div>
        </div>

        <div *ngIf="!newAddressBookEntry" class="row center-vertical">
            <div class="col-lg-12 col-md-12 my-custom-input-label-box child-center-vertical">
                <div class="align-center">
                    <button [disabled]=saveDisabled class="transparent-button" style="margin-right: 20px;" (click)="saveEntryToAddressBook()" >
                        <div style="color: #000000;">
                            <img *ngIf="saveDisabled" class="button-icon" src="/assets/icons/send-and-receive/address_book-onclick.svg" />
                            <img *ngIf="!saveDisabled" class="button-icon" src="/assets/icons/send-and-receive/address_book.svg" />
                            {{'address_book.address_details.save_to_address_book' | translate}}*
                        </div>
                    </button>
                    <button class="transparent-button" (click)="toggleFavourite()" >
                        <div>
                            <img *ngIf="getFavouriteState()" class="button-icon" src="/assets/icons/send-and-receive/favourites_onclick.svg" />
                            <img *ngIf="!getFavouriteState()" class="button-icon" src="/assets/icons/send-and-receive/favourites.svg" />
                            {{'address_book.address_details.add_to_favourites' | translate}}
                        </div>
                    </button>
                    <!-- <input id="to-favourites-input" type="checkbox" name="to-favourites-input" />
                    <label for="to-favourites-input" (click)="toggleFavourite()"></label>
                    <span class="checkbox-text-padding">{{'address_book.address_details.add_to_favourites' | translate}}</span></div> -->
                </div>
            </div>
            <div *ngIf="newAddressBookEntry">
                <div class="row" style="padding-left: 15px">
                    <button class="transparent-button padded save-button" (click)="saveEntryToAddressBook()">{{'address_book.address_details.save_to_address_book' | translate}}</button>
                </div>
            </div>
            <div class="col-12 row field-row" style="padding-top: 10px;">
                <div class="col-lg-12 col-md-12">
                    *{{'address_book.address_details.note' | translate}}
                    <a href="">{{'address_book.address_details.privacy_policy' | translate}}</a>
                </div>
                <app-field-error-message *ngIf="saveDisabled" icon="" style="height: 40px;padding-top: 10px;"
                    errorMessage="{{ 'send_receive.address_successfully_saved' | translate }}">
                </app-field-error-message>
        </div>
    <!-- </div> -->
</div>