import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountRoutingModule } from './account-routing.module';
import { RegisterComponent } from './register/register.component';
import { BusinessRegisterComponent } from './register/business-register.component';
import { PrivateRegisterComponent } from './register/private-register.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { AwaitingPostaApprovalComponent } from './awaiting-posta-approval/awaiting-posta-approval.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { VerificationComponent } from './verification/verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PrivateLoginComponent } from './login/private-login/private-login.component';
import { BusinessLoginComponent } from './login/business-login/business-login.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { SamleikinLoginComponent } from './login/samleikin-login/samleikin-login.component';
import { AwaitingVerificationComponent } from './awaiting-verification/awaiting-verification.component';
import { SamleikinMigrateComponent } from './login/samleikin-migrate/samleikin-migrate.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { AccountStatementComponent } from './account-statement/account-statement.component';



@NgModule({
  declarations:
    [
      RegisterComponent,
      BusinessRegisterComponent,
      PrivateRegisterComponent,
      AwaitingPostaApprovalComponent,
      ForgottenPasswordComponent,
      VerificationComponent,
      ResetPasswordComponent,
      ChangePasswordComponent,
      PrivateLoginComponent,
      BusinessLoginComponent,
      UpdateProfileComponent,
      SamleikinLoginComponent,
      AwaitingVerificationComponent,
      SamleikinMigrateComponent,
      AccountStatementComponent,
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AccountRoutingModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatCardModule,
    MatProgressBarModule,
    SharedModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatProgressSpinnerModule
  ]
})
export class AccountModule { }
