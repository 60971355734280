import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../entities/user";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { CookieService } from "../../shared/services/cookie.service";
import { TranslateService } from "@ngx-translate/core";
import { SamleikinSession } from "../entities/samleikinSession";
import { SamleikinCredentials } from "../entities/samleikinCredentials";

@Injectable({ providedIn: 'root' })
export class RoleService {
    public roleSubject = new BehaviorSubject(localStorage.getItem('role'));

    constructor()
    {
    }

    setRole(role: any) {
        localStorage.setItem('role', role);
        this.roleSubject.next(localStorage.getItem('role'));
    }

    role() {
        if (!!localStorage.getItem('role'))
        {
            return localStorage.getItem('role').toString();
        }
        return 'private';
    }

    isPrivateUser(): boolean
    {
        if (!!localStorage.getItem('role'))
        {
            return localStorage.getItem('role') === 'private';
        }
        return false;
    }
}