import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SamleikinCredentials } from '../../entities/samleikinCredentials';
import { User } from '../../entities/user';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-samleikin-login',
  templateUrl: './samleikin-login.component.html',
  styleUrls: ['./samleikin-login.component.scss']
})
export class SamleikinLoginComponent implements OnInit {

  displayLoginFailed: boolean = false;

  displayLoginSuccessful: boolean = false;

  preAuthGuid: string;

  loading: boolean = true;

  constructor(private authService: AuthenticationService, private router: Router, private translateService: TranslateService) { }

  ngOnInit(): void {

    this.authService.getSessionData().subscribe(x => {

      if (!!x && !!x.attributes)
      {
        var idNum = x.attributes.find(y => y.name == "personalIdentityNumber");
        var firstName = x.attributes.find(y => y.name == "givenName");
        var lastName = x.attributes.find(y => y.name == "sn");

        if (!!idNum && !!firstName && !!lastName)
        {
          this.authService.getPreAuthSamleikin().subscribe(y => {
            var user: SamleikinCredentials = {
              firstName: firstName.values[0],
              lastName: lastName.values[0],
              preAuthGuid: y,
              ptal: idNum.values[0],
              isSamleikin: true
            }

            this.authService.samleikinLogin(user).subscribe(z => {
              console.log("Successfully logged in with samleikin");
              this.loading = false;
              if (!z.isProfileComplete)
              {
                this.router.navigate([`${this.translateService.currentLang}/private/my-posta/update-profile`]);
              }
              else {
                this.router.navigate([`${this.translateService.currentLang}/private/home`]);
              }
            }, (error) => {
              this.loading = false;
              this.displayLoginFailed = true;
            })
          });
        }
      }
      else {
        this.loading = false;
      }

    }, (error) => {
      this.loading = false;
      this.displayLoginFailed = true;
    });
  }

}
