export class CustomsItemType{
    itemTypeId: number;
    itemId: number;
    description: string;
    quantity: number;
    netWeight: number;
    value: number;
    countryOfOrigin: string;
    hsTariffNo: string;
    isKg: boolean;
}