import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { AuthenticationService } from "../account/services/authentication.service";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { CookieService } from "./services/cookie.service";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private accountService: AuthenticationService, private cookieService: CookieService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isLoggedIn = this.accountService.isLoggedIn();
        const isApiUrl = request.url.startsWith(environment.apiUrl) || request.url.startsWith(environment.dibsUrl) || request.url.startsWith("/api") || request.url.startsWith("/payment") || request.url.startsWith(environment.beraUrl)
        if (isLoggedIn && isApiUrl) {
            // console.log(this.cookieService.getCookie('token'));
            request = request.clone({
                setHeaders: {  
                    Authorization: `Bearer ${this.cookieService.getCookie('token')}`
                    //Authorization: `Bearer ab/Z/7f4Lr+fabm+477up1D9oX61aisOA6kJ7zPuAszRlfX2viA5h+9MSXrsV63lnWnSizVEAYLDcP3rdmFWzxM+cixvP9nfVGXNK0vawEw1hZXWaPl9d07sHKVeJzITl+NdLOaX0fDQcznVbB9+29rTqPJhWyWk/PAJ8hPhdLg=`
                }
            });
        }
        // else if (request.url.includes("/DownloadReceipt"))
        // {
        //     request = request.clone({
        //         setHeaders: {  
        //             Authorization: `Bearer ${this.cookieService.getCookie('tempToken')}`
        //         }
        //     });
        // }

        return next.handle(request).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if(evt.body && !!request.headers.get('Authorization'))
                    {
                        var authCookie = this.cookieService.getCookie('token')
                        this.cookieService.deleteCookie(authCookie);
                        this.cookieService.setCookie("token", authCookie, 1440);
                    }
                }
            }));
    }
}