import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from '../../send-receive/services/payment.service';
import { ErrorHelper } from '../../shared/helpers/error-helper';
import { InvoiceCustom } from '.././entities/InvoiceCustom';

@Component({
  selector: 'app-base-pay-customs',
  templateUrl: './base-pay-customs.component.html',
  styleUrls: ['./base-pay-customs.component.scss']
})
export class BasePayCustomsComponent implements OnInit, AfterViewInit {

  @Input()
  image: string;

  @ViewChild('stepper') stepper: MatStepper;

  public customsFormGroup: FormGroup;

  public userConfirmationFormGroup: FormGroup;

  public paymentFormGroup: FormGroup;

  @Input()
  public numberInputFieldName: string;

  @Input()
  public serviceTypeId: number;

  @Input()
  public inputFieldNamePlaceholder: string;

  public customsRetrieved: boolean = false;
  
  public invoiceCustom: InvoiceCustom;

  public showPaymentScreen: boolean = false;

  public transactionRef: string;

  public failedToGetAmount: boolean = false;

  public loading: boolean = false;

  public loadingProceedToPayment: boolean = false;
  observer: MutationObserver;

  constructor(private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private errorHelper: ErrorHelper,
    private ref: ChangeDetectorRef,
    private elRef: ElementRef) { }

  ngOnInit(): void {

    this.customsFormGroup = this.formBuilder.group({
      invoicePoNumber: ['', Validators.required],
      refCode: ['', Validators.required],
    });

    this.userConfirmationFormGroup = this.formBuilder.group({
      email: ['', Validators.required],
      // password: ['', Validators.required],
      agreeToUseEmail: [false, Validators.requiredTrue],
      // hasPostaAccount: ['', Validators.required],
      // noPostaAccount: ['', Validators.required],
    })

    this.paymentFormGroup = this.formBuilder.group({
      hasPaid: ['', Validators.required],
    })

    //this.userConfirmationFormGroup.get("password").disable();

    this.customsFormGroup.get("invoicePoNumber").valueChanges.subscribe(x => {
      this.customsRetrieved = false;
    });

    this.customsFormGroup.get("refCode").valueChanges.subscribe(x => {
      this.customsRetrieved = false;
    });

    this.ref.detectChanges();
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));
      });
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

  public hasErrors(field){
    var errors = this.customsFormGroup.get(field).errors;
    return !!errors;
  }

  public getError(field){
    var errors = this.customsFormGroup.get(field).errors
    return this.errorHelper.getErrorMessage(errors);
  }

  public getCost() {
    if (!this.customsFormGroup.valid)
    {
      this.customsFormGroup.markAllAsTouched();
      this.customsRetrieved = false;
      return;
    }

    this.failedToGetAmount = false;
    this.loading = true;

    this.paymentService.validate(this.serviceTypeId, this.customsFormGroup.get("invoicePoNumber").value, this.customsFormGroup.get("refCode").value).subscribe(x => {
      this.getPaymentAmount();
    },(error) => {
      this.failedToGetAmount = true;
      this.loading = false;
    })
    
  }

  private getPaymentAmount() {
    this.paymentService.GetPaymentInfo(this.serviceTypeId, this.customsFormGroup.get("invoicePoNumber").value, this.customsFormGroup.get("refCode").value).subscribe(x => {

      var invoiceCustom: InvoiceCustom = {
        invoiceCustomNumber: this.customsFormGroup.get("invoicePoNumber").value,
        referenceCode: this.customsFormGroup.get("refCode").value,
        totalAmountIncVAT: x.totalAmountIncVAT,
        serviceTypeId: this.serviceTypeId,
        customerNumber: x.customerNumber,
        customerName: x.customerName,
        invoiceDate: x.invoiceDate,
        totalAmountExcVAT: x.totalAmountExcVAT,
        vatAmount: x.vatAmount,
        outstandingAmount: x.outstandingAmount,
        isPaidButUpdateFailed: x.isPaidButUpdateFailed
      };

      this.invoiceCustom = invoiceCustom;
      this.customsRetrieved = true;
      this.loading = false;
    }, (error) => {
      this.failedToGetAmount = true;
      this.loading = false;
    });
  }

  public proceedToPayment() {
    this.loadingProceedToPayment = true;

    //make service call again to get price before moving on
    this.paymentService.GetPaymentInfo(this.invoiceCustom.serviceTypeId, this.invoiceCustom.invoiceCustomNumber, this.invoiceCustom.referenceCode)
    .subscribe(x => {
      this.invoiceCustom.totalAmountIncVAT = x.totalAmountIncVAT;
      this.showPaymentScreen = true;
      this.loadingProceedToPayment = false;
    })
  }

  public openDetailsAndPaymentStep() {
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.selectedIndex = 1;

    //Email won't have been set to the object, set it now.
    this.invoiceCustom.email = this.userConfirmationFormGroup.get("email").value;
  }

  public orderPaidForEvent(event: string) {

        this.transactionRef = event;
        // complete the current step
        this.stepper.selected.completed = true;
        // move to next step
        this.stepper.selectedIndex = 2;
  }
}