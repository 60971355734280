import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  isVerified: boolean = false;

  successfulVerification: boolean = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    if (this.route.snapshot.data.verified.x)
    {
      this.successfulVerification = true;
    }

  }

}
