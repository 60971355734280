import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PostageOrder } from '../entities/postageOrder';
import { PostageConfirmation } from '../entities/postageConfirmation';
import { DownloadOutput } from '../entities/DownloadOutput';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
//TODO change this PayCustomsService
export class BuyPostageService {

    constructor(private httpClient: HttpClient) {
    }

    public createPostageOrder(buyPostageUpsert: PostageOrder): Observable<string> {
        return this.httpClient.post(`${environment.apiUrl}/api/BuyPostage`, buyPostageUpsert, { responseType: 'text' }) as Observable<string>;
    }

    public getOrder(orderRef: string, userId?: string): Observable<PostageOrder> {
        if (!!userId)
        {
            return this.httpClient.get(`${environment.apiUrl}/api/BuyPostage?orderReference=${orderRef}&creatorGuid=${userId}`) as Observable<PostageOrder>;
        }
        return this.httpClient.get(`${environment.apiUrl}/api/BuyPostage?orderReference=${orderRef}`) as Observable<PostageOrder>;
    }

    public getOrderConfirmation(orderRef: string, userId?: string): Observable<PostageConfirmation> 
    {
        return this.httpClient.get(`${environment.apiUrl}/api/BuyPostage/OrderConfirmation?orderRef=${orderRef}&creatorGuid=${userId}`) as Observable<PostageConfirmation>;
    }

    // public getOrderConfirmation(orderRef: string): Observable<PostageConfirmation> {
    //     return this.httpClient.get(`/api/BuyPostage/OrderConfirmation?orderRef=ST10000007`) as Observable<PostageConfirmation>;
    // }

    public downloadReceipt(orderRef: string, langCode: string, creatorGuid?: string): Observable<DownloadOutput>
    {
        if(creatorGuid){
            return this.httpClient.get(`${environment.apiUrl}/api/BuyPostage/${orderRef}/${langCode}/${creatorGuid}/DownloadReceipt`) as Observable<DownloadOutput>;
        }
        else{
            //Random GUID to be accepted by service - GUID is not used for logged in users
            return this.httpClient.get(`${environment.apiUrl}/api/BuyPostage/${orderRef}/${langCode}/eec1179f-a913-471b-8590-d77d599c6ebf/DownloadReceipt`) as Observable<DownloadOutput>;
        }
    }

    public downloadCN22(orderRef: string): Observable<DownloadOutput>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/BuyPostage/${orderRef}/DownloadCN22`) as Observable<DownloadOutput>;
    }

    public emailOrder(orderRef: string, langCode: string, toEmail: string): Observable<boolean>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/BuyPostage/${orderRef}/${langCode}/${toEmail}/EmailOrder`) as Observable<boolean>
    }

    public emailCN22(toEmail: string): Observable<boolean>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/BuyPostage/${toEmail}/EmailCN22`) as Observable<boolean>
    }

    public downloadCDS(orderBarcode: string): Observable<DownloadOutput>
    {
        return this.httpClient.get(`${environment.apiUrl}/api/SendReceiveOrder/CDSPrint?barCode=${orderBarcode}`) as Observable<DownloadOutput>;
    }
}
