<div *ngIf="!!pageTitle" [innerHtml]="pageTitle | safeHtml"></div>
<div class="container main-image-text-section">
    <div class="row mob-clear-margin">
        <div class="col-lg-6 col-md-6 col-12 mob-no-padding" style="padding-right: 0">
            <img class="main-image" [src]="image">
        </div>
        <div class="col-lg-6 col-md-6 col-12 align-center specify-col">
            <div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <h1 class="white main-section-title" style="padding-bottom: 20px;">{{'buy_postage.landing_page.specify' | translate}}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <h3 class="white" style="padding-bottom: 30px;">{{'buy_postage.landing_page.all_options' | translate}}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <button class="feature-button select-postage-button white" [routerLink]="['../buy-postage']">{{'buy_postage.landing_page.select_postage' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container quick-buy-container">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-12 left-quick-buy-column">
            <div class="quick-buy-section">
                <div class="row" style="margin-left: 0px; margin-right: 0px;">
                    <div class="col-lg-6 col-md-6 col-6 quick-buy-image-col">
                        <img class="quick-buy-image" src="/assets/images/buy-postage/domestic-quick-buy@3x.png">
                        <h2 class="quick-buy-price">{{getDomesticPrice() | currency: 'DKK':''}}Kr.</h2>
                    </div>
                    <div class="col-lg-6 col-md-6 col-6 quick-buy-text-container">
                        <div class="quick-buy-text-body">
                            <h3 class="white quick-buy-title">{{'buy_postage.landing_page.domestic_post' | translate}} ({{getTranslation(transportType.Name)}})
                            </h3>
                            <h4 class="white quick-buy-body">{{'buy_postage.landing_page.max' | translate}}: {{maxDomesticWeight}}g</h4>
                            <h4 class="white quick-buy-body">{{getTranslation(transportType.Description)}}</h4>
                        </div>
                        <div class="increment-button-container">
                            <button class="primary-button increment-button"
                                (click)="decreaseDomesticItemCount()">-</button>
                            <mat-form-field class="no-padding increment-input" [formGroup]="quickBuyDomesticForm"
                                floatLabel="never" appearance="outline">
                                <input style="font-size: 16px !important" matInput id="quantity" name="quantity"
                                    formControlName="quantity" required>
                            </mat-form-field>
                            <button class="primary-button increment-button"
                                (click)="incrementDomesticItemCount()">+</button>
                        </div>
                        <button class="feature-button white hide-tablet" (click)="navigatetoBuyPostage(true)">{{'buy_postage.landing_page.quickbuy' | translate}}</button>
                    </div>
                </div>
            </div>
            <button class="tertiary-button show-tablet col-md-12 col-12 quick-buy-button" (click)="navigatetoBuyPostage(true)">{{'buy_postage.landing_page.quickbuy' | translate}}</button>
        </div>
        <div class="col-lg-6 col-md-6 col-12 right-quick-buy-column">
            <div class="quick-buy-section">
                <div class="row" style="margin-left: 0px; margin-right: 0px;">
                    <div class="col-lg-6 col-md-6 col-6 quick-buy-image-col">
                        <img class="quick-buy-image" src="/assets/images/buy-postage/international-quick-buy@3x.png">
                        <h2 class="quick-buy-price">{{getInternationalPrice() | currency: 'DKK':''}}Kr.</h2>
                    </div>
                    <div class="col-lg-6 col-md-6 col-6 quick-buy-text-container">
                        <div class="quick-buy-text-body">
                            <h3 class="white quick-buy-title">{{'buy_postage.landing_page.international_post' | translate}}
                                ({{getTranslation(transportType.Name)}})</h3>
                            <h4 class="white quick-buy-body">{{'buy_postage.landing_page.max' | translate}}: {{maxInternationalWeight}}g</h4>
                            <h4 class="white quick-buy-body">{{getTranslation(transportType.Description)}}</h4>
                        </div>
                        <div class="increment-button-container">
                            <button class="primary-button increment-button"
                                (click)="decreaseInternationalItemCount()">-</button>
                            <mat-form-field class="no-padding increment-input" [formGroup]="quickBuyInternationalForm"
                                floatLabel="never" appearance="outline">
                                <input style="font-size: 16px !important" matInput id="internationalQuantity"
                                    name="internationalQuantity" formControlName="internationalQuantity" required>
                            </mat-form-field>
                            <button class="primary-button increment-button"
                                (click)="incrementInternationalItemCount()">+</button>
                        </div>
                        <button class="feature-button white hide-tablet" (click)="navigatetoBuyPostage(false)">{{'buy_postage.landing_page.quickbuy' | translate}}</button>
                    </div>
                </div>
            </div>
            <button class="tertiary-button show-tablet col-md-12 col-12 quick-buy-button" (click)="navigatetoBuyPostage(false)">{{'buy_postage.landing_page.quickbuy' | translate}}</button>
        </div>
    </div>
</div>
<div *ngIf="!!pageModules" [innerHtml]="pageModules | safeHtml"></div>