import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Address } from '../../account/entities/address';
import { ErrorHelper } from '../helpers/error-helper';
import { StaticContentHelper } from '../helpers/static-content-helper';
import { StaticContentService } from '../services/static-content.service';

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss']
})
export class AddressBookComponent implements OnInit {

  public showAddNewAddress: boolean = false;

  public newAddressFormGroup: FormGroup;

  public viewOnlyFavourites: boolean = false;

  public isEditing: boolean = false;

  public addressToEdit: Address;

  // public addresses: Array<Address>;

  public searchText: string;
  
  public updatedSearchText: string;

  public pageTitle: string;

  public pageModules: string;

  interval: any;

  constructor(private _formBuilder: FormBuilder, private staticContentService: StaticContentService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      var staticContent = this.staticContentService.staticContent;
      if (!!staticContent) {
        this.staticContentService.loadAllCssJs();
        this.pageTitle = this.getPageTitle();
        this.pageModules = this.getHtmlAfterPsModule();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((x) => {
      this.staticContentService.loadAllCssJs();
      this.pageTitle = this.getPageTitle();
      this.pageModules = this.getHtmlAfterPsModule();
    })

    this.newAddressFormGroup = this._formBuilder.group({
      toTitle: [''],
      toFirstName: ['', Validators.required],
      toLastName: ['', Validators.required],
      toOptionalCompany: [''],
      toAddressLine1: ['', Validators.required],
      toAddressLine2: [''],
      toTownCity: ['', Validators.required],
      toPostcode: ['', Validators.required],
      toContactNumber: ['', ErrorHelper.integerOnlyValidator],
      toEmail: ['', ErrorHelper.nullableEmailValidator],
      toCountry: ['', Validators.required],
      toSpecialInstructions: [''],
    });
  }

  public getPageTitle()
  {
    return this.staticContentService.getPageTitle();
  }

  setShowAddNewAddress(savedAddressSuccessfully: boolean) {
    if(savedAddressSuccessfully) {
      this.showAddNewAddress = false;
      this.isEditing = false;
    }
  }
  public toggleFavourites() {
    this.viewOnlyFavourites = true;
    this.isEditing = false;
    this.showAddNewAddress = false;
  }

  public editAddress(address: Address) {
    this.isEditing = true;
    this.addressToEdit = address;
    this.showAddNewAddress = false;
  }

  public setAddNewAddress() {
    this.isEditing = false;
    this.showAddNewAddress = true;
  }

  public updateSearchText() {
    this.updatedSearchText = this.searchText;
  }

  public showAllAddresses() {
    this.viewOnlyFavourites = false;
    this.isEditing = false;
    this.showAddNewAddress = false;
    this.searchText = "";
    this.updatedSearchText = "";
  }

  public getHtmlAfterPsModule() {
    return this.staticContentService.getHtmlBelowPsModule();
  }
}
