import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomsCalculator } from '../entities/customsCalculator';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    constructor(private httpClient: HttpClient) {
    }

    public getAll(): Observable<CustomsCalculator> {
        return this.httpClient.get(`${environment.apiUrl}/api/customs/categories`).pipe(map((data: any) => {
            return data;
        }));
    }
}