import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader  {

    constructor(private http: HttpClient) {}

    getTranslation(lang: String): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/StaticContent/TranslationDictionary/' + lang).pipe(catchError(_ => this.http.get(`/assets/i18n/en.json`)))
    }
}